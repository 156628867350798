import { CheckCircleOutlined, EditOutlined, StopOutlined } from "@ant-design/icons";
import { PageHeader, Space, Popconfirm, message } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { TableItem, ButtonItem } from "../../../Components/Items";
import AgregarRemolque from "./AgregarRemolque/AgregarRemolque";
import EditarRemolque from "./EditarRemolque/EditarRemolque";

class Remolques extends Component {
    state = {
        todasRemolques: [],
        modalAgregarRemolque: false,
        modalEditarRemolque: false,
        modalEditarRemolqueId: null,
        loading: false
    }
    componentDidMount() {
        this.consultarRemolques();
    }

    consultarRemolques() {
        this.setState({ loading: true })
        axios
            .post(
                "remolques/selectTodosRemolques",
                { empresaId: this.props.empresaId },
                {
                    headers: this.props.headersToken,
                }
            )
            .then((res) => {
                this.setState({ loading: false })
                if (res.data.success === 2) {
                    this.setState({
                        todasRemolques: res.data.remolques.map(value => {
                            return { ...value, subtipoRemolque: (value.subtiporemolquesat.Clave + ' - ' + value.subtiporemolquesat.Descripcion), accion: { _id: value._id, estado: value.estado } }
                        }),
                    });
                }
            });
    }

    deshabilitarRemolque(value) {
        axios
            .post(
                "remolques/deshabilitarRemolque",
                { remolqueId: value, empresaId: this.props.empresaId },
                { headers: this.props.headersToken }
            )
            .then((res) => {
                if (res.data.success === 2) {
                    message.success("Remolque deshabilitado");
                } else {
                    message.error("Sucedio un error y no se pudo deshabilitar");
                }
                this.consultarRemolques();
            });
    }
    habilitarRemolque(value) {
        axios
            .post(
                "remolques/habilitarRemolque",
                { remolqueId: value, empresaId: this.props.empresaId },
                { headers: this.props.headersToken }
            )
            .then((res) => {
                if (res.data.success === 2) {
                    message.success("Remolque habilitado");
                } else {
                    message.error("Sucedio un error y no se pudo habilitar");
                }
                this.consultarRemolques();
            });
    }

    abrirAgregarRemolque() {
        this.setState({ modalAgregarRemolque: true });
    }
    cerrarAgregarRemolque() {
        this.setState({ modalAgregarRemolque: false });
        this.consultarRemolques();
    }
    abrirEditarRemolque(id) {
        this.setState({ modalEditarRemolque: true, modalEditarRemolqueId: id });
    }
    cerrarEditarRemolque() {
        this.setState({ modalEditarRemolque: false });
        this.consultarRemolques();
    }
    render() {
        const columns = [
            {
                title: "Descripcion",
                dataIndex: "descripcion",
                key: "descripcion",
                sorter: (a, b) => {
                    if (a.descripcion < b.descripcion) {
                        return -1;
                    }
                    if (a.descripcion > b.descripcion) {
                        return 1;
                    }
                    return 0;
                },
                buscar: "descripcion",
            },
            {
                title: "Placa",
                dataIndex: "placa",
                key: "placa",
                sorter: (a, b) => {
                    if (a.placa < b.placa) {
                        return -1;
                    }
                    if (a.placa > b.placa) {
                        return 1;
                    }
                    return 0;
                },
                buscar: "placa",
            },
            {
                title: "Subtipo Remolque",
                dataIndex: "subtipoRemolque",
                key: "subtipoRemolque",
                sorter: (a, b) => {
                    if (a.subtipoRemolque < b.subtipoRemolque) {
                        return -1;
                    }
                    if (a.subtipoRemolque > b.subtipoRemolque) {
                        return 1;
                    }
                    return 0;
                },
                buscar: "subtipoRemolque",
            },
            {
                title: "Accion",
                dataIndex: "accion",
                key: "accion",
                render: (value) => (
                    <Space size="middle">
                        {this.props.permisoEditarRemolque &&
                        <EditOutlined
                            onClick={() => this.abrirEditarRemolque(value._id)}
                            style={{ color: "blue" }}
                        />}
                        {this.props.permisoDeshabilitarRemolque &&
                        <>
                            {value.estado === 1 ? (
                                <Popconfirm
                                    title="Seguro que quieres deshabilitar este remolque"
                                    onConfirm={() => this.deshabilitarRemolque(value._id)}
                                    okText="Si"
                                    cancelText="No"
                                >
                                    <CheckCircleOutlined style={{ color: "green" }} />
                                </Popconfirm>
                            ) : (
                                <Popconfirm
                                    title="Seguro que quieres habilitar este remolque"
                                    onConfirm={() => this.habilitarRemolque(value._id)}
                                    okText="Si"
                                    cancelText="No"
                                >
                                    <StopOutlined style={{ color: "red" }} />
                                </Popconfirm>
                            )}
                        </>}
                    </Space>
                ),
            },
        ]
        return (
            <>
                <PageHeader
                    title="Remolques"
                    style={{
                        border: "1px solid rgb(235, 237, 240)",
                        backgroundColor: "white",
                        marginBottom: 10,
                    }}
                    extra={ this.props.permisoAgregarRemolque &&
                        <ButtonItem
                            type="primary"
                            key="agregarRemolque"
                            onClick={this.abrirAgregarRemolque.bind(this)}
                        >
                            Agregar Remolque
                        </ButtonItem>
                    }
                />
                <TableItem 
                    loading={this.state.loading} 
                    size='small' 
                    dataSource={this.state.todasRemolques} 
                    columns={columns} 
                    onRow={(record) => ({
                        onDoubleClick: () => this.abrirEditarRemolque(record._id),
                      })}
                />
                    <AgregarRemolque
                        visible={this.state.modalAgregarRemolque}
                        cerrarAgregarRemolque={this.cerrarAgregarRemolque.bind(
                            this
                        )}
                    />
                    <EditarRemolque
                        visible={this.state.modalEditarRemolque}
                        id={this.state.modalEditarRemolqueId}
                        cerrarEditarRemolque={this.cerrarEditarRemolque.bind(this)}
                    />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        headersToken: state.user.headersToken,
        empresaId: state.empresa.id,
        permisoAgregarRemolque:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "675096a4798d4e0043687255"
      )?.activado,
      permisoEditarRemolque:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "675096cb798d4e0043687256"
      )?.activado,
      permisoDeshabilitarRemolque:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "675096f0798d4e0043687257"
      )?.activado,
    };
};

export default connect(mapStateToProps)(Remolques);