import moment from 'moment'
import 'moment/locale/es'
import {cuentaMask, dineroDisabledMask} from '../Components/Items'

moment.locale('es')

const reporteDeEstadoDeCuentaPdf = (values) => {
    const empresa = values.empresa
    const cuentaContable = values.cuentaContable
    const cargosAbonos = values.cargosAbonos
    const cargosAbonosTable = cargosAbonos.map(valueCA => {
        return [
            {style: 'textoTabla', text: moment(valueCA.fechaRegistro).locale('es').format('L'), alignment: 'center'}, 
            {text: valueCA.concepto, style: 'textoTabla', alignment: 'left'}, 
            {text: valueCA.numPoliza, style: 'textoTabla', alignment: 'center'}, 
            {text: valueCA.cargo !== '-' ? dineroDisabledMask(valueCA.cargo) : valueCA.cargo, style: 'textoTabla', alignment: 'right'}, 
            {text: valueCA.abono !== '-' ? dineroDisabledMask(valueCA.abono) : valueCA.abono, style: 'textoTabla', alignment: 'right'},
            {text: dineroDisabledMask(valueCA.saldo), style: 'textoTabla', alignment: 'right'}, 
        ]
    })

    let filtrados = []

    if(values.filtrarProyecto === 1){
        let textoFiltrado = ''
        values.proyectos.map((valueProyectos) => {
            textoFiltrado += `${valueProyectos.nombre}\n`
            return null
        })
        filtrados.push([
            {text: 'Proyectos:', style: 'subheader'},
            
            {text: textoFiltrado, style: 'textoChico'}
          ])
    }

    if(values.filtrarProyecto === 2){
        filtrados.push([
            {text: 'Unidad de Negocio:', style: 'subheader'},
            
            {text: `${cuentaMask(
                values.unidadDeNegocio.nivel1 + values.unidadDeNegocio.nivel2 + values.unidadDeNegocio.nivel3 + values.unidadDeNegocio.nivel4 +values.unidadDeNegocio.nivel5
              ) +
                " - " +
                values.unidadDeNegocio.nombre}`, style: 'textoChico'}
          ])
    }

    if(filtrados.length === 0){
        filtrados.push([
            {text: 'No tiene filtrados adicionales', style: 'subheader'},

            {text: '', style: 'textoChico'}
      ] )
    }

    var dd = {
        content: [
            {
                style: 'tableExample',
                table: {
                    widths: [ '18%', '52%', '30%'],
                    layout: {
                        defaultBorder: false,
                    },
                    body: [
                        [
                            {
                                border: [false, false, false, false],
                                image: 'logo',
                                fit: [80, 80] 
                            },
                            {
                                border: [false, false, false, false],
                                table: {
                                  headerRows: 1,
                                  widths: ["100%"],
                
                                  body: [
                                    [
                                        { 
                                        text: empresa.persona ? empresa.nombre : empresa.nombre+' '+empresa.apellidoPaterno+' '+empresa.apellidoMaterno, 
                                        style: "tableHeader" 
                                        }
                                    ],
                                    [
                                      {
                                        text: "R.F.C: "+empresa.rfc,
                                        style: "textoChico",
                                        alignment: "center",
                                      },
                                    ],
                                    [
                                        {
                                          text: empresa.calle+' '+
                                          empresa.numeroCalle+' '+
                                          (empresa.numeroInterior ? empresa.numeroInterior : '')
                                          ,
                                          style: "textoChico",
                                          alignment: "center",
                                        },
                                      ],
                                    [
                                        {
                                          text: empresa.colonia.tipoAsentamiento+' '+
                                          empresa.colonia.nombre+' C.P. '+
                                          empresa.colonia.cp+' '+
                                          empresa.municipio.nombre+' '+
                                          empresa.estado.abreviatura+' '+
                                          empresa.pais.abreviatura+' '
                                          ,
                                          style: "textoChico",
                                          alignment: "center",
                                        },
                                      ],
                                  ],
                                },
                                layout: "noBorders",
                              },
                            {
                                border: [false, false, false, false],
                                    table: {
                                    headerRows: 1,
                                    widths: ['100%'],
                                
                                    body: [
                                        [{text: 'Fehca de Impresión:', style: 'subheader', alignment: 'center'}],
                                        [{text: moment().locale('es').format('ll'), style: 'textoChico', alignment: 'center'}],
                                    ]
                                },
                                layout: 'noBorders'
                            },
                        ]
    
                    ]
                },
            },
            {
                style: 'tableExample',
                table: {
                    widths: ['60%', '40%'],
                    layout: {
                                        defaultBorder: false,
                                    },
                    
                    body: [
                            [
                                {
                                table: {
                                    widths: ['25%', '50%'],
                                    layout: {
                                        defaultBorder: false,
                                    },
                                    body: [
                                        [
                                            {text: 'Cuenta Contable:', style: 'subheader',}, {text: cuentaMask(cuentaContable.nivel1+cuentaContable.nivel2+cuentaContable.nivel3+cuentaContable.nivel4+cuentaContable.nivel5), style: 'textoChico'}
                                        ],
                                        [
                                            {text: 'Nombre de la Cuenta:', style: 'subheader'}, {text: cuentaContable.descripcion, style: 'textoChico'}
                                        ],
                                    ]
                                },
                                layout: 'noBorders'
                                
                            }, 
                            
                            
                            
                            {
                                table: {
                                    widths: ['35%', '50%'],
                                    layout: {
                                        defaultBorder: false,
                                    },
                                    body: [
                                        [
                                            {text: 'Fecha Inicial:', style: 'subheader',}, {text: moment(values.fechaInicial).locale('es').format('LL'), style: 'textoChico', alignment: 'center'}
                                        ],
                                        [
                                            {text: 'Fecha Final:', style: 'subheader'}, {text: moment(values.fechaFinal).locale('es').format('LL'), style: 'textoChico', alignment: 'center'}
                                        ],
                                    ]
                                },
                                layout: 'noBorders'
                            }
                          ]
                       ]
                },
                            layout: {
                    hLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.body.length) ? 1 : 1;
                    },
                    vLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.widths.length) ? 1 : 1;
                    },
                    hLineColor: function (i, node) {
                        return '#adaaaa';
                    },
                    vLineColor: function (i, node) {
                        return '#adaaaa';
                    },
                    hLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.body.length) {
                            return null;
                        }
                        return {dash: {length: 2, space: 1}};
                    },
                    vLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.widths.length) {
                            return null;
                        }
                        return {dash: {length: 2}};
                    },
                    // paddingLeft: function(i, node) { return 4; },
                    // paddingRight: function(i, node) { return 4; },
                    // paddingTop: function(i, node) { return 2; },
                    // paddingBottom: function(i, node) { return 2; },
                    // fillColor: function (i, node) { return null; }
                }
                
            },
            {text: 'Datos de filtrado:'},
            {
                style: 'tableExample',
                table: {
                    widths: ['20%', '80%'],
                    layout: {
                                        defaultBorder: false,
                                    },
                    
                    body: filtrados,
                },
                            layout: {
                    hLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.body.length) ? 1 : 1;
                    },
                    vLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.widths.length) ? 1 : 1;
                    },
                    hLineColor: function (i, node) {
                        return '#adaaaa';
                    },
                    vLineColor: function (i, node) {
                        return '#adaaaa';
                    },
                    hLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.body.length) {
                            return null;
                        }
                        return {dash: {length: 2, space: 1}};
                    },
                    vLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.widths.length) {
                            return null;
                        }
                        return {dash: {length: 2}};
                    },
                    // paddingLeft: function(i, node) { return 4; },
                    // paddingRight: function(i, node) { return 4; },
                    // paddingTop: function(i, node) { return 2; },
                    // paddingBottom: function(i, node) { return 2; },
                    // fillColor: function (i, node) { return null; }
                }
                
            },
            
            {
                style: 'tableExample',
                table: {
                    widths: ['10%', '35%', '15%', '13%', '13%', '14%'],
                    layout: {
                        defaultBorder: true,
                    },
                    body: [
                        [
                          {text: 'Fecha', style: 'tituloTabla'},
                          {text: 'Concepto', style: 'tituloTabla'},
                          {text: 'Poliza', style: 'tituloTabla'},
                          {text: 'Cargo', style: 'tituloTabla'},
                          {text: 'Abono', style: 'tituloTabla'},
                          {text: 'Saldo', style: 'tituloTabla'},
                        ],
                        ...cargosAbonosTable
                    ]
                },
                layout: {
                    fillColor: (rowIndex, node, columnIndex) => {
                        return rowIndex % 2 === 0 ? "#e8e8e8" : "#ffffff";
                    },
                    hLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.body.length) ? 1 : 1;
                    },
                    vLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.widths.length) ? 1 : 1;
                    },
                    hLineColor: function (i, node) {
                        return '#adaaaa';
                    },
                    vLineColor: function (i, node) {
                        return '#adaaaa';
                    },
                    hLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.body.length) {
                            return null;
                        }
                        return {dash: {length: 2, space: 1}};
                    },
                    vLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.widths.length) {
                            return null;
                        }
                        return {dash: {length: 2}};
                    },
                }
            },
        ],
        images: {
            logo: empresa.logo.base64Completa,
        },
        styles: {
            header: {
                fontSize: 9,
                bold: true,
            },
            subheader: {
                fontSize: 8,
                bold: true,
            },
            tableExample: {
                margin: [0, 5, 0, 5]
            },
            tablaSinMargen: {
                margin: [0, 0, 0, 0]
            },
            tableHeader: {
                bold: true,
                fontSize: 12,
                color: 'black',
                alignment: 'center'
            },
            textoChico:{
                fontSize: 8
            },
            tituloTabla:{
                fontSize: 8,
                bold: true, 
                fillColor: '#cccccc', 
                style: 'subheader', 
                alignment: 'center',
                border: true
            },
            textoTabla: {
                fontSize: 8,
                italics: false
            }
        },
        pageSize: 'LETTER',
        pageMargins: [ 20, 20, 20, 20 ],
    }
  return dd;
};


export default reporteDeEstadoDeCuentaPdf;
