import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { ButtonItem, InputItem, ModalItem } from "../../../../../Components/Items";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import cancelarVentaPdf from "../../../../../Pdf/cancelarVentaPdf";
import { DownloadOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Col, Form, Form as FormInitial, message, Row, Space } from "antd";
import FormItem from "antd/lib/form/FormItem";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class ModalCancelarPdf extends Component {
  formRef = React.createRef();

  state = {
    loading: false,
    pdf: null,
    error: null, // Manejo de errores
  };

  componentDidUpdate(prevProps) {
    // Solo ejecutar cuando el modal se abre
    if (this.props.visible && this.props.visible !== prevProps.visible) {
      this.consultarTodo();
    }
  }

  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "ventas/cancelarVentaPdf",
        { ventaId: this.props.ventaId, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        const pdf = pdfMake.createPdf(cancelarVentaPdf(res.data));
        this.setState(
          {
            loading: false,
            pdf: pdf,
          },
          () => {
            pdf.open({}, window.frames["cancelarVentaPdf"]);
          }
        );
      })
      .catch((err) => {
        console.error(err);
        this.setState({ loading: false });
      });
  }

  cerrarModalPdf() {
    if (this.formRef.current) {
      this.formRef.current.resetFields(); // Reinicia los campos del formulario
    }
    this.setState({ pdf: null, error: null }); // Limpia el estado
    this.props.cerrarModalPdf(); // Llama al método recibido por props para cerrar el modal
  }

  onFinish = async (values) => {
    this.setState({ loading: true, error: null }); // Establecer estado de carga y limpiar errores previos
    try {
      await axios.post(
        "ventas/enviarCancelacionVenta",
        {
          ventaId: this.props.ventaId,
          empresaId: this.props.empresaId,
          ...values
        },
        { headers: this.props.headersToken }
      ).then((res) => {
        this.setState({ loading: false });
        if (res.data.succes === 2) {
          message.success("Cancelacion enviada");

          this.cerrarModalPdf();
        } else if (res.data.succes === 1) {
          message.error("No se pudo enviar la cancelacion a ese correo");
        } else {
          message.error("Sucedió un error y no se pudo enviar");
        }
      })
      this.cerrarModalPdf();
    } catch (err) {
      console.log(err)
      console.error(err);
      this.setState({
        error: "Ocurrió un error al enviar el correo. Intenta nuevamente.",
      });
    } finally {
      this.setState({ loading: false }); // Finaliza el estado de carga
    }
  };

  async descargarCancelacion(value) {

    try {
      const res = await axios.post(
        "ventas/cancelarVentaPdf",
        { ventaId: this.props.ventaId, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      );

      let nombreArchivo

      if(res.data.factura){
        const dataSat = res.data?.data
        nombreArchivo = (dataSat.facturaSerie ? dataSat.facturaSerie : 'F') + ' - ' + dataSat.facturaNumero + ' ( ' + res.data.cliente.rfc + res.data.cliente.nombre + " " + res.data.cliente.apellidoPaterno + " " + res.data.cliente.apellidoMaterno + ' )'
      } else{
        const dataSat = res.data?.data
        nombreArchivo = (dataSat.remisionSerie ? dataSat.remisionSerie : 'F') + ' - ' + dataSat.remisionNumero + ' ( ' + res.data.cliente.rfc + res.data.cliente.nombre + " " + res.data.cliente.apellidoPaterno + " " + res.data.cliente.apellidoMaterno + ' )'
      }

      if(res.data?.data?.cancelacion?.acuse){
        var xmltext = res.data?.data?.cancelacion?.acuse;
  
  
        var filename = nombreArchivo + ".xml";
        var pom = document.createElement("a");
        var bb = new Blob([xmltext], { type: "text/plain" });
  
        pom.setAttribute("href", window.URL.createObjectURL(bb));
        pom.setAttribute("download", filename);
  
        pom.dataset.downloadurl = ["text/plain", pom.download, pom.href].join(
          ":"
        );
        pom.draggable = true;
        pom.classList.add("dragout");
  
        pom.click();

      }


      pdfMake.createPdf(cancelarVentaPdf(res.data)).download("Cancelacion.pdf");;
    } catch (error) {
      console.error("Error al consultar los datos:", error);
      this.setState({ loading: false });
    }
  }


  render() {
    return (
      <ModalItem
        title="Cancelación de Venta"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarModalPdf.bind(this)} // Cierra el modal correctamente
        paddingTop
      >
        <Form
          ref={this.formRef}
          name="mandarCorreo"
          layout
          width={700}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
        >
          <FormInitial.List
            name="correosEnvio"
            initialValue={[{ correo: '' }]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ name }, arrayKey) => (
                  <Row key={name}>
                    <Col span={22}>
                      <FormItem
                        name={[name, "correo"]}
                        fieldKey={[arrayKey, "correo"]}
                        label="Correo"
                        required
                        margin
                        email
                        pattern
                      >
                        <InputItem style={{ width: '100%' }} placeholder="Correo" />
                      </FormItem>
                    </Col>
                    <Col span={2}>
                      <div style={{ textAlign: 'center' }}>
                        <MinusCircleOutlined
                          onClick={() => remove(name)}
                        />
                      </div>
                    </Col>
                  </Row>
                ))}
                {fields.length < 5 ? (
                  <div style={{ textAlign: 'center', marginTop: '5px' }}>
                    <ButtonItem
                      type="primary"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    >
                      Agregar
                    </ButtonItem>
                  </div>
                ) : null}
              </>
            )}
          </FormInitial.List>
          <div style={{ display: "flex", justifyContent: "right", marginTop: 20, marginBottom: 10 }}>
            <Space>
              <ButtonItem
                icon={<DownloadOutlined />}
                blanco={true}
                onClick={() => this.descargarCancelacion()}
              >
                Descargar
              </ButtonItem>
              <ButtonItem
                type="primary"
                htmlType="submit"
                style={{ display: "block", margin: "0 0 0 auto" }}
              >
                Mandar Correo
              </ButtonItem>
              <ButtonItem
                type="primary"
                danger
                onClick={this.cerrarModalPdf.bind(this)} // Removido el bind aquí ya que fue enlazado en el constructor
                style={{ display: "block", margin: "0 0 0 auto" }}
              >
                Cancelar
              </ButtonItem>
            </Space>
          </div>
        </Form>
        {this.state.error ? ( // Muestra el error si ocurre
          <div style={{ color: "red", textAlign: "center" }}>
            {this.state.error}
          </div>
        ) : (
          <iframe
            title="Cancelar Venta PDF"
            style={{ width: "100%", height: "100vh" }}
            id="cancelarVentaPdf"
            name="cancelarVentaPdf"
          />
        )}
      </ModalItem>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(ModalCancelarPdf);