import React, { Component } from "react";
import {
    message,
    PageHeader,
    Spin,
    Row,
    Col,
    Select,
    Form as FormInitial,
    notification,
    Checkbox,
} from "antd";
import {
    dineroDisabledMask,
    Form,
    FormItem,
    InputNumberItem,
    SelectItem,
    round2Decimals,
    ModalItem,
    ButtonItem,
    NotificacionPolizas,
    round6Decimals,
    HeaderItem,
    BoxItem,
    DatePickerItem,
    SwitchItem,
    ErrorItem,
    TextAreaItem,
} from "../../../../Components/Items";
import axios from "axios";
import moment from "moment";
import "moment/locale/es";
import { connect } from "react-redux";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import notaDeCreditoVentaFPdf from "../../../../Pdf/notadeCreditoVentaFPdf";
import notaDeCreditoVentaRPdf from "../../../../Pdf/notaDeCreditoVentaRPdf";
import MandarCorreoNotaDeCreditoVenta from "../MandarCorreoNotaDeCreditoVenta/MandarCorreoNotaDeCreditoVenta";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const { Option } = Select;

moment.locale("es");

class AgregarNotaDeCreditoVenta extends Component {
    formRef = React.createRef();
    state = {
        loading: false,
        objetoProveedores: null,
        todasProveedores: null,
        objetoProyectos: null,
        todasProyectos: null,
        objetoBancos: null,
        todasBancos: null,
        objetoInsumos: (
            <Option disabled value={0} key={0}>
                Seleccione un proveedor primero
            </Option>
        ),
        todasInsumos: [],
        objetoMonedas: null,
        todasMonedas: null,
        impuestos: [],
        modalConfirmacion: false,
        valuesForm: null,
        ventaId: this.props.location.state.ventaId,
        datosVenta: null,
        tipoDeCambioVenta: 0,
        totalVenta: 0,
        totalPagarVenta: 0,
        subtotalVenta: 0,
        arrayImpuestosVenta: [],
        arrayRetencionesVenta: [],
        modalCorreo: false,
        modalCorreoId: null,
    };
    async componentDidMount() {
        this.setState({ loading: true });
        await this.consultarTodo()
        if (this.props?.location?.state?.fileName) {
            this.leerXmlData(this.props.location.state)
        }
        this.setState({ loading: false });
    }

    promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));

    async consultarTodo() {

        this.setState({ loading: true });
        await axios
            .post(
                "notasDeCreditoVenta/selectDatosParaNotaDeCredito",
                { empresaId: this.props.empresaId, ventaId: this.state.ventaId },
                {
                    headers: this.props.headersToken,
                }
            )
            .then((res) => {
                if (res.data.success === 2) {
                    let total = 0;
                    let subtotal = 0;
                    let arrayImpuestos = [];
                    let arrayRetenciones = [];
                    let cuentasCliente = 0;
                    this.formRef.current.setFieldsValue({
                        ...res.data.venta,
                        formaDePagoId: "6125149afe07662acc100d64",
                        tipoDeCambioVenta: res.data.venta.tipoDeCambio,
                        factura: res.data.venta.factura ? 1 : 2,
                        insumos: res.data.venta.insumos.map((valueInsumo) => {
                            let clienteId = res.data.venta.clienteId;
                            let tipoDeCambio = 1
                            const insumo = res.data.insumosVenta.find(
                                (valueInsumos) => valueInsumos._id === valueInsumo.insumoId
                            );
                            const precioUnitario = valueInsumo.precioUnitario - (valueInsumo.precioRebajado || 0);
                            const cantidad = valueInsumo.cantidad - (valueInsumo.devuelto || 0);
                            const descuento = valueInsumo.descuento || 0;

                            if (precioUnitario && cantidad && insumo) {
                                //1
                                const subtotal = round2Decimals(cantidad * precioUnitario);
                                //2
                                const totalDescuento = round2Decimals(subtotal * (descuento / 100));
                                //3
                                const totalEntrada = round2Decimals(subtotal - totalDescuento);
                                valueInsumo.subtotal = totalEntrada;

                                let totalImpuestos = 0;
                                let arrayImpuestos = insumo.impuestos.map(
                                    //4
                                    (valueImpuesto) => {
                                        let totalImpuesto;
                                        let baseImpuesto;
                                        let montoImpuesto;
                                        if (valueImpuesto.tasaCuota === 1) {
                                            totalImpuesto = round6Decimals(
                                                totalEntrada * (valueImpuesto.monto / 100)
                                            );
                                            baseImpuesto = totalEntrada
                                            montoImpuesto = round6Decimals(valueImpuesto.monto / 100)
                                        } else if (valueImpuesto.tasaCuota === 2) {
                                            totalImpuesto = round6Decimals(
                                                (totalEntrada + totalImpuestos) * (valueImpuesto.monto / 100)
                                            );
                                            baseImpuesto = (totalEntrada + totalImpuestos)
                                            montoImpuesto = round6Decimals(valueImpuesto.monto / 100)
                                        } else if (valueImpuesto.tasaCuota === 3) {
                                            totalImpuesto = round6Decimals((cantidad * valueImpuesto.monto) / tipoDeCambio);
                                            baseImpuesto = cantidad
                                            montoImpuesto = round6Decimals(valueImpuesto.monto / tipoDeCambio)
                                        } else if (valueImpuesto.tasaCuota === 5) {
                                            totalImpuesto = 0
                                            baseImpuesto = totalEntrada
                                            montoImpuesto = 0
                                        }
                                        totalImpuestos += totalImpuesto;
                                        return {
                                            tasaCuota: valueImpuesto.tasaCuota,
                                            impuesto: valueImpuesto.impuesto,
                                            montoImpuesto: montoImpuesto,
                                            local: valueImpuesto.local,
                                            baseImpuesto: baseImpuesto,
                                            descripcionFactura: valueImpuesto.descripcionFactura,
                                            totalImpuestos: totalImpuesto,
                                        };
                                    }
                                );
                                valueInsumo.impuestos = totalImpuestos;
                                valueInsumo.arrayImpuestos = arrayImpuestos;
                                //5
                                valueInsumo.total = round2Decimals(
                                    valueInsumo.subtotal + totalImpuestos
                                );

                                if (clienteId) {
                                    //Consultar cliente

                                    const cliente = res.data.clientes.find(
                                        (valueP) => valueP._id === clienteId
                                    );
                                    //6
                                    let totalRetenciones = 0;
                                    let arrayRetenciones = []
                                    cliente.retenciones.map((valueRetencion) => {
                                        //7
                                        let totalRetencion;
                                        let baseRetencion;
                                        if (valueRetencion.tasaCuota === 1) {
                                            totalRetencion = round6Decimals(totalEntrada * (valueRetencion.monto / 100));
                                            baseRetencion = totalEntrada
                                        } else if (valueRetencion.tasaCuota === 2) {
                                            totalRetencion =
                                                round6Decimals((totalEntrada + totalRetenciones) * (valueRetencion.monto / 100));
                                            baseRetencion = (totalEntrada + totalImpuestos)
                                        }
                                        totalRetenciones += totalRetencion;
                                        arrayRetenciones.push({
                                            tasaCuota: valueRetencion.tasaCuota,
                                            impuesto: valueRetencion.impuesto,
                                            montoRetencion: valueRetencion.monto,
                                            local: valueRetencion.local,
                                            baseRetencion: baseRetencion,
                                            descripcionFactura: valueRetencion.descripcionFactura,
                                            totalRetenciones: totalRetencion,
                                        });
                                        return null
                                    })
                                    insumo.retenciones.map((valueRetencion) => {
                                        if ((valueRetencion.aplicaVenta === 1 && cliente.persona === false) || (valueRetencion.aplicaVenta === 2 && cliente.persona === true) || valueRetencion.aplicaVenta === 3) {
                                            //7
                                            let totalRetencion;
                                            let baseRetencion;
                                            if (valueRetencion.tasaCuota === 1) {
                                                totalRetencion = round6Decimals(totalEntrada * (valueRetencion.monto / 100));
                                                baseRetencion = totalEntrada
                                            } else if (valueRetencion.tasaCuota === 2) {
                                                totalRetencion =
                                                    round6Decimals((totalEntrada + totalRetenciones) * (valueRetencion.monto / 100));
                                                baseRetencion = (totalEntrada + totalImpuestos)
                                            }
                                            totalRetenciones += totalRetencion;
                                            arrayRetenciones.push({
                                                tasaCuota: valueRetencion.tasaCuota,
                                                impuesto: valueRetencion.impuesto,
                                                montoRetencion: valueRetencion.monto,
                                                local: valueRetencion.local,
                                                baseRetencion: baseRetencion,
                                                descripcionFactura: valueRetencion.descripcionFactura,
                                                totalRetenciones: totalRetencion,
                                            });
                                        }
                                        return null
                                    })
                                    valueInsumo.retenciones = totalRetenciones;
                                    valueInsumo.arrayRetenciones = arrayRetenciones;
                                }

                            }

                            subtotal += valueInsumo.subtotal;
                            total += valueInsumo.total;

                            if (valueInsumo.arrayImpuestos) {

                                valueInsumo?.arrayImpuestos?.map((valueImpuestos) => {
                                    let index = arrayImpuestos.findIndex(
                                        (valueImpuestosAnterios) =>
                                            valueImpuestosAnterios.descripcionFactura ===
                                            valueImpuestos.descripcionFactura
                                            &&
                                            valueImpuestosAnterios.tasaCuota ===
                                            valueImpuestos.tasaCuota
                                            &&
                                            valueImpuestosAnterios.impuesto ===
                                            valueImpuestos.impuesto
                                    );
                                    if (index !== -1) {
                                        let newItem = {
                                            totalImpuestos:
                                                arrayImpuestos[index].totalImpuestos +
                                                valueImpuestos.totalImpuestos,
                                            baseImpuesto:
                                                arrayImpuestos[index].baseImpuesto +
                                                valueImpuestos.baseImpuesto,
                                            descripcionFactura:
                                                valueImpuestos.descripcionFactura,
                                            tasaCuota:
                                                valueImpuestos.tasaCuota,
                                            impuesto:
                                                valueImpuestos.impuesto,
                                            montoImpuesto:
                                                valueImpuestos.montoImpuesto,
                                            local: valueImpuestos.local,
                                        };
                                        arrayImpuestos.splice(index, 1, newItem);
                                    } else {
                                        arrayImpuestos.push({
                                            tasaCuota: valueImpuestos.tasaCuota,
                                            local: valueImpuestos.local,
                                            impuesto: valueImpuestos.impuesto,
                                            montoImpuesto: valueImpuestos.montoImpuesto,
                                            baseImpuesto: valueImpuestos.baseImpuesto,
                                            descripcionFactura: valueImpuestos.descripcionFactura,
                                            totalImpuestos: valueImpuestos.totalImpuestos,
                                        });
                                    }
                                    return null;
                                });
                            }
                            if (valueInsumo.arrayRetenciones) {
                                valueInsumo.arrayRetenciones.map((valueRetenciones) => {
                                    let index = arrayRetenciones.findIndex(
                                        (valueRetencionesAnterios) =>
                                            valueRetencionesAnterios.descripcionFactura ===
                                            valueRetenciones.descripcionFactura
                                            &&
                                            valueRetencionesAnterios.tasaCuota ===
                                            valueRetenciones.tasaCuota
                                            &&
                                            valueRetencionesAnterios.impuesto ===
                                            valueRetenciones.impuesto
                                    );
                                    cuentasCliente += round2Decimals(valueRetenciones.totalRetenciones);
                                    if (index !== -1) {
                                        let newItem = {
                                            totalRetenciones:
                                                arrayRetenciones[index].totalRetenciones +
                                                valueRetenciones.totalRetenciones,
                                            baseRetencion:
                                                arrayRetenciones[index].baseRetencion +
                                                valueRetenciones.baseRetencion,
                                            descripcionFactura:
                                                valueRetenciones.descripcionFactura,
                                            tasaCuota:
                                                valueRetenciones.tasaCuota,
                                            impuesto:
                                                valueRetenciones.impuesto,
                                            montoRetencion:
                                                valueRetenciones.montoRetencion,
                                            local: valueRetenciones.local,
                                        };
                                        arrayRetenciones.splice(index, 1, newItem);
                                    } else {
                                        arrayRetenciones.push({
                                            tasaCuota: valueRetenciones.tasaCuota,
                                            impuesto: valueRetenciones.impuesto,
                                            montoRetencion: valueRetenciones.montoRetencion,
                                            baseRetencion: valueRetenciones.baseRetencion,
                                            local: valueRetenciones.local,
                                            descripcionFactura: valueRetenciones.descripcionFactura,
                                            totalRetenciones: valueRetenciones.totalRetenciones,
                                        });
                                    }
                                    return null;
                                });
                            }

                            return {
                                ...valueInsumo,
                                precioUnitario: valueInsumo.precioUnitario - (valueInsumo.precioRebajado || 0),
                                cantidad: valueInsumo.cantidad - (valueInsumo.devuelto || 0),
                            }
                        })
                    });
                    this.setState({
                        factura: res.data.venta.factura ? 1 : 2,
                        datosVenta: res.data.venta,
                        tipoDeCambioVenta: res.data.venta.tipoDeCambio,
                        totalVenta: total,
                        totalPagarVenta: round2Decimals(total - cuentasCliente),
                        subtotalVenta: subtotal,
                        arrayImpuestosVenta: arrayImpuestos,
                        arrayRetencionesVenta: arrayRetenciones,
                        todasClientes: res.data.clientes,
                        todasProyectos: res.data.proyectos,
                        todasPrecios: res.data.precios,
                        todasFormasDePago: res.data.formasDePago,
                        todasUsosDeCFDI: res.data.usosDeCFDI,
                        todasBancos: res.data.bancosYCajas,
                        todasInsumos: res.data.insumosVenta,
                        todasUsuarios: res.data.usuariosEmpresa,
                        todasMonedas: res.data.monedas,
                        venderSinInventario: res.data.venderSinInventario,
                        objetoClientes: res.data.clientes.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.stringBuscar}
                                </Option>
                            );
                        }),
                        objetoProyectos: res.data.proyectos.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.nombre}
                                </Option>
                            );
                        }),
                        objetoPrecios: res.data.precios.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.nombre}
                                </Option>
                            );
                        }),
                        objetoFormasDePago: res.data.formasDePago.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.descripcion}
                                </Option>
                            );
                        }),
                        objetoUsosDeCFDI: res.data.usosDeCFDI.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.descripcion}
                                </Option>
                            );
                        }),
                        objetoInsumos: res.data.insumosVenta.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.stringBuscar}
                                </Option>
                            );
                        }),
                        objetoUsuarios: res.data.usuariosEmpresa.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.nombre +
                                        " " +
                                        value.apellidoPaterno +
                                        " " +
                                        value.apellidoMaterno}
                                </Option>
                            );
                        }),
                        objetoMonedas: res.data.monedas.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.nombre + " - " + value.abreviacion}
                                </Option>
                            );
                        }),
                        objetoBancos: res.data.bancosYCajas.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.nombre}
                                </Option>
                            );
                        }),
                    });
                } else {
                    message.warning('No se encontro informacion de la venta')
                }
            });

        this.setState({ loading: false });

    }
    onFinishFailed(e) {
        ErrorItem(e);
    }
    onFinish(values) {
        if (!values.insumos || values.insumos?.length === 0)
            return message.warning("Agregue insumos para agregar la venta");

        let insumos = values.insumos.filter(valueInsumo => valueInsumo.precioUnitarioNC && valueInsumo.precioUnitarioNC > 0);

        if (!insumos || insumos?.length === 0)
            return message.warning("Agregue insumos para agregar la venta");

        this.setState({ loading: true });
        let total = 0;
        let subtotal = 0;
        let arrayImpuestos = [];
        let arrayRetenciones = [];
        let cuentasCliente = 0;
        insumos.map((value) => {
            total += value.subtotalNC;
            subtotal += value.subtotalNC;
            value.arrayImpuestosNC.map((valueImpuestos) => {
                let index = arrayImpuestos.findIndex(
                    (valueImpuestosAnterios) =>
                        valueImpuestosAnterios.descripcionFactura ===
                        valueImpuestos.descripcionFactura
                        &&
                        valueImpuestosAnterios.tasaCuota ===
                        valueImpuestos.tasaCuota
                        &&
                        valueImpuestosAnterios.impuesto ===
                        valueImpuestos.impuesto
                );
                if (index !== -1) {
                    let newItem = {
                        totalImpuestos:
                            arrayImpuestos[index].totalImpuestos +
                            valueImpuestos.totalImpuestos,
                        baseImpuesto:
                            arrayImpuestos[index].baseImpuesto +
                            valueImpuestos.baseImpuesto,
                        descripcionFactura:
                            valueImpuestos.descripcionFactura,
                        tasaCuota:
                            valueImpuestos.tasaCuota,
                        impuesto:
                            valueImpuestos.impuesto,
                        montoImpuesto:
                            valueImpuestos.montoImpuesto,
                        local: valueImpuestos.local,
                    };
                    arrayImpuestos.splice(index, 1, newItem);
                } else {
                    arrayImpuestos.push({
                        tasaCuota: valueImpuestos.tasaCuota,
                        local: valueImpuestos.local,
                        impuesto: valueImpuestos.impuesto,
                        montoImpuesto: valueImpuestos.montoImpuesto,
                        baseImpuesto: valueImpuestos.baseImpuesto,
                        descripcionFactura: valueImpuestos.descripcionFactura,
                        totalImpuestos: valueImpuestos.totalImpuestos,
                    });
                }
                return null;
            });
            if (value.arrayRetencionesNC) {
                value.arrayRetencionesNC.map((valueRetenciones) => {
                    let index = arrayRetenciones.findIndex(
                        (valueRetencionesAnterios) =>
                            valueRetencionesAnterios.descripcionFactura ===
                            valueRetenciones.descripcionFactura
                            &&
                            valueRetencionesAnterios.tasaCuota ===
                            valueRetenciones.tasaCuota
                            &&
                            valueRetencionesAnterios.impuesto ===
                            valueRetenciones.impuesto
                    );
                    cuentasCliente += round2Decimals(valueRetenciones.totalRetenciones);
                    if (index !== -1) {
                        let newItem = {
                            totalRetenciones:
                                arrayRetenciones[index].totalRetenciones +
                                valueRetenciones.totalRetenciones,
                            baseRetencion:
                                arrayRetenciones[index].baseRetencion +
                                valueRetenciones.baseRetencion,
                            descripcionFactura:
                                valueRetenciones.descripcionFactura,
                            tasaCuota:
                                valueRetenciones.tasaCuota,
                            impuesto:
                                valueRetenciones.impuesto,
                            montoRetencion:
                                valueRetenciones.montoRetencion,
                            local: valueRetenciones.local,
                        };
                        arrayRetenciones.splice(index, 1, newItem);
                    } else {
                        arrayRetenciones.push({
                            tasaCuota: valueRetenciones.tasaCuota,
                            impuesto: valueRetenciones.impuesto,
                            montoRetencion: valueRetenciones.montoRetencion,
                            baseRetencion: valueRetenciones.baseRetencion,
                            local: valueRetenciones.local,
                            descripcionFactura: valueRetenciones.descripcionFactura,
                            totalRetenciones: valueRetenciones.totalRetenciones,
                        });
                    }
                    return null;
                });
            }
            return null;
        });
        arrayImpuestos = arrayImpuestos.map(valueImpuesto => {
            total += round2Decimals(valueImpuesto.totalImpuestos)
            return {
                ...valueImpuesto,
                totalImpuestos: round2Decimals(valueImpuesto.totalImpuestos),
            }
        })

        let tipoDeCambioVenta = values.tipoDeCambioVenta || this.state.tipoDeCambioVenta
        let tipoDeCambioPago = values.tipoDeCambio
        const equivalencia = tipoDeCambioPago / tipoDeCambioVenta

        if (round2Decimals(subtotal * equivalencia) > round2Decimals(this.state.subtotalVenta) || round2Decimals(total * equivalencia) > round2Decimals(this.state.totalVenta) || round2Decimals((total - cuentasCliente) * equivalencia) > round2Decimals(this.state.totalPagarVenta)) {
            this.setState({ loading: false });
            return message.info('No se puede capturar un monto mayor a la venta')
        }

        axios
            .post(
                "notasDeCreditoVenta/agregarNotaDeCredito",
                {
                    ...values,
                    empresaId: this.props.empresaId,
                    total,
                    subtotal,
                    cuentasCliente: round2Decimals(cuentasCliente),
                    totalPagar: round2Decimals(total - cuentasCliente),
                    arrayImpuestos,
                    arrayRetenciones,
                    entregarInsumos: this.state.entregarInsumos,
                    ubicacionesEnvio: this.state.ubicacionesEnvio,
                    mercanciasEnvio: this.state.mercanciasEnvio,
                    ventaId: this.state.ventaId,
                },
                { headers: this.props.headersToken }
            )
            .then((res) => {
                this.setState({ loading: false });
                if (res.data.success === 2) {
                    message.success("Se ha registrado una nota de credito");
                    if (res.data.respuestaFactura.status === "success") {
                        notification.success({
                            description: "Haga click para descargar",
                            message: "Nota De Credito Emitida",
                            duration: 0,
                            style: { cursor: "pointer" },
                            onClick: () => {
                                var xmltext = res.data.respuestaFactura.data.cfdi;
                                const dataSat = res.data.respuestaFactura.dataSat
                                const nombreArchivo = (dataSat.Serie ? dataSat.Serie : 'NDC') + ' - ' + dataSat.Folio + ' ( ' + dataSat.Receptor.Rfc + ' - ' + dataSat.Receptor.Nombre + ' )'

                                var filename = nombreArchivo + ".xml";
                                var pom = document.createElement("a");
                                var bb = new Blob([xmltext], { type: "text/plain" });

                                pom.setAttribute("href", window.URL.createObjectURL(bb));
                                pom.setAttribute("download", filename);

                                pom.dataset.downloadurl = [
                                    "text/plain",
                                    pom.download,
                                    pom.href,
                                ].join(":");
                                pom.draggable = true;
                                pom.classList.add("dragout");

                                pom.click();

                                const pdf = notaDeCreditoVentaFPdf(res.data);

                                pdfMake.createPdf(pdf).download(nombreArchivo + '.pdf');
                            },
                        });
                        NotificacionPolizas(res.data.polizasId);
                        this.setState({
                            modalCorreo: true,
                            modalCorreoId: res.data.notaId,
                        });
                    } else {
                        notification.success({
                            description: "Haga click para descargar",
                            message: "Nota De Credito Emitida",
                            duration: 0,
                            style: { cursor: "pointer" },
                            onClick: () => {
                                const dataSat = res.data.respuestaFactura.dataSat
                                const nombreArchivo = (dataSat.Serie ? dataSat.Serie : 'NDC') + ' - ' + dataSat.Folio + ' ( ' + dataSat.Receptor.Nombre + ' )'

                                const pdf = notaDeCreditoVentaRPdf(res.data);

                                pdfMake.createPdf(pdf).download(nombreArchivo + '.pdf');
                            },
                        });
                        NotificacionPolizas(res.data.polizasId);
                        this.setState({
                            modalCorreo: true,
                            modalCorreoId: res.data.notaId,
                        });
                    }
                } else if (res.data.success === 1) {
                    message.error(res.data.message ? res.data.message : "No se pudo registrar la venta");
                    notification.warning({
                        description: res.data.description,
                        message: res.data.message,
                        duration: 0,
                    });
                } else {
                    message.error("Hubo un error y la venta no se registro");
                }
            });
    }

    atras() {
        this.props.history.goBack();
    }
    async onChangePrecioCantidadDescuentoNC(value, key, porcentajeGeneral) {
        let arrayValue = await this.formRef.current.getFieldValue("insumos");
        let clienteId = await this.formRef.current.getFieldValue("clienteId");
        let tipoDeCambio = await this.formRef.current.getFieldValue("tipoDeCambio");
        const insumo = this.state.todasInsumos.find(
            (valueInsumos) => valueInsumos._id === arrayValue[key].insumoId
        );
        if (!insumo) return;
        const precioUnitario = Number(arrayValue[key].precioUnitario)

        if (value === "porcentaje") {
            arrayValue[key].precioUnitarioNC = round2Decimals(precioUnitario * (arrayValue[key].procentajeNC / 100)) ? round2Decimals(precioUnitario * (arrayValue[key].procentajeNC / 100)) : undefined
        } else if (value === 'cantidad') {
            arrayValue[key].procentajeNC = round2Decimals(arrayValue[key].precioUnitarioNC / precioUnitario * 100) !== Infinity ? round2Decimals(arrayValue[key].precioUnitarioNC / precioUnitario * 100) : 0
        } else if (value === "porcentajeGeneral") {
            arrayValue[key].precioUnitarioNC = round2Decimals(precioUnitario * (porcentajeGeneral / 100)) ? round2Decimals(precioUnitario * (porcentajeGeneral / 100)) : undefined
            arrayValue[key].procentajeNC = porcentajeGeneral
        }

        const precioUnitarioNC = arrayValue[key].precioUnitarioNC;
        const procentajeNC = arrayValue[key].procentajeNC;
        const cantidad = arrayValue[key].cantidad;
        const descuento = arrayValue[key].descuento || 0;

        if (precioUnitario && precioUnitarioNC && procentajeNC && cantidad && insumo) {
            //1
            const subtotal = round2Decimals(cantidad * precioUnitarioNC);
            //2
            const totalDescuento = round2Decimals(subtotal * (descuento / 100));
            //3
            const totalEntrada = round2Decimals(subtotal - totalDescuento);
            arrayValue[key].subtotalNC = totalEntrada;

            let totalImpuestos = 0;
            let arrayImpuestos = insumo.impuestos.map(
                //4
                (valueImpuesto) => {
                    let totalImpuesto;
                    let baseImpuesto;
                    let montoImpuesto;
                    if (valueImpuesto.tasaCuota === 1) {
                        totalImpuesto = round6Decimals(
                            totalEntrada * (valueImpuesto.monto / 100)
                        );
                        baseImpuesto = totalEntrada
                        montoImpuesto = round6Decimals(valueImpuesto.monto / 100)
                    } else if (valueImpuesto.tasaCuota === 2) {
                        totalImpuesto = round6Decimals(
                            (totalEntrada + totalImpuestos) * (valueImpuesto.monto / 100)
                        );
                        baseImpuesto = (totalEntrada + totalImpuestos)
                        montoImpuesto = round6Decimals(valueImpuesto.monto / 100)
                    } else if (valueImpuesto.tasaCuota === 3) {
                        totalImpuesto = round6Decimals((cantidad * valueImpuesto.monto) / tipoDeCambio);
                        baseImpuesto = cantidad
                        montoImpuesto = round6Decimals(valueImpuesto.monto / tipoDeCambio)
                    } else if (valueImpuesto.tasaCuota === 5) {
                        totalImpuesto = 0
                        baseImpuesto = totalEntrada
                        montoImpuesto = 0
                    }
                    totalImpuestos += totalImpuesto;
                    return {
                        tasaCuota: valueImpuesto.tasaCuota,
                        impuesto: valueImpuesto.impuesto,
                        montoImpuesto: montoImpuesto,
                        local: valueImpuesto.local,
                        baseImpuesto: baseImpuesto,
                        descripcionFactura: valueImpuesto.descripcionFactura,
                        totalImpuestos: totalImpuesto,
                    };
                }
            );
            arrayValue[key].impuestosNC = totalImpuestos;
            arrayValue[key].arrayImpuestosNC = arrayImpuestos;
            //5
            arrayValue[key].totalNC = round2Decimals(
                arrayValue[key].subtotalNC + totalImpuestos
            );

            if (clienteId) {
                //Consultar cliente

                const cliente = this.state.todasClientes.find(
                    (valueP) => valueP._id === clienteId
                );
                //6
                let totalRetenciones = 0;
                let arrayRetenciones = []
                cliente.retenciones.map((valueRetencion) => {
                    //7
                    let totalRetencion;
                    let baseRetencion;
                    if (valueRetencion.tasaCuota === 1) {
                        totalRetencion = round6Decimals(totalEntrada * (valueRetencion.monto / 100));
                        baseRetencion = totalEntrada
                    } else if (valueRetencion.tasaCuota === 2) {
                        totalRetencion =
                            round6Decimals((totalEntrada + totalRetenciones) * (valueRetencion.monto / 100));
                        baseRetencion = (totalEntrada + totalImpuestos)
                    }
                    totalRetenciones += totalRetencion;
                    arrayRetenciones.push({
                        tasaCuota: valueRetencion.tasaCuota,
                        impuesto: valueRetencion.impuesto,
                        montoRetencion: valueRetencion.monto,
                        local: valueRetencion.local,
                        baseRetencion: baseRetencion,
                        descripcionFactura: valueRetencion.descripcionFactura,
                        totalRetenciones: totalRetencion,
                    });
                    return null
                })
                insumo.retenciones.map((valueRetencion) => {
                    if ((valueRetencion.aplicaVenta === 1 && cliente.persona === false) || (valueRetencion.aplicaVenta === 2 && cliente.persona === true) || valueRetencion.aplicaVenta === 3) {
                        //7
                        let totalRetencion;
                        let baseRetencion;
                        if (valueRetencion.tasaCuota === 1) {
                            totalRetencion = round6Decimals(totalEntrada * (valueRetencion.monto / 100));
                            baseRetencion = totalEntrada
                        } else if (valueRetencion.tasaCuota === 2) {
                            totalRetencion =
                                round6Decimals((totalEntrada + totalRetenciones) * (valueRetencion.monto / 100));
                            baseRetencion = (totalEntrada + totalImpuestos)
                        }
                        totalRetenciones += totalRetencion;
                        arrayRetenciones.push({
                            tasaCuota: valueRetencion.tasaCuota,
                            impuesto: valueRetencion.impuesto,
                            montoRetencion: valueRetencion.monto,
                            local: valueRetencion.local,
                            baseRetencion: baseRetencion,
                            descripcionFactura: valueRetencion.descripcionFactura,
                            totalRetenciones: totalRetencion,
                        });
                    }
                    return null
                })
                arrayValue[key].retencionesNC = totalRetenciones;
                arrayValue[key].arrayRetencionesNC = arrayRetenciones;
            }

        }
        this.formRef.current.setFieldsValue({
            insumos: arrayValue,
        });
    }
    onChangeCliente(valueId) {
        const contado = this.formRef.current.getFieldValue("contado");
        const cliente = this.state.todasClientes.find(
            (valueProv) => valueProv._id === valueId
        );
        if (!cliente.regimenFiscalId || !cliente.direcciones[0]?.coloniaId) {
            message.info('No se podra facturar a este cliente. Faltan datos')
        }
        if (!cliente.tipoClienteId) {
            const nombre = cliente.persona
                ? cliente.razonSocial
                : `${cliente.nombre} ${cliente.apellidoPaterno} ${cliente.apellidoMaterno}`;
            this.abrirElegirTipoDeCliente(cliente._id, nombre);
        }
        let objetoCambiar = {}
        if (cliente.usoDeCFDIID) {
            objetoCambiar.usoDeCfdiId = cliente.usoDeCFDIID
        }
        if (cliente.precioContadoId || cliente.precioCreditoId) {
            objetoCambiar.precioId = contado === 1 ? cliente.precioContadoId : cliente.precioCreditoId
        }
        if (cliente.proyectoId) {
            objetoCambiar.proyectoId = cliente.proyectoId
        }
        if (cliente.vendedorId) {
            objetoCambiar.vendedorId = cliente.vendedorId
        }
        this.setState({
            complementoDetallista: cliente.complementoDetallista,
            detallista: {
                orderIdentification: cliente.orderIdentification,
                referenceDateOrderIdentification: cliente.referenceDateOrderIdentification,
                additionalInformation: cliente.additionalInformation,
                deliveryNote: cliente.deliveryNote,
                referenceDateDeliveryNote: cliente.referenceDateDeliveryNote,
            }
        })
        this.formRef.current.setFieldsValue({
            diasCredito: cliente.diasCredito || 0,
            ...objetoCambiar
        });
    }
    abrirElegirTipoDeCliente(id, nombre) {
        this.setState({
            modalElegirTipoDeCliente: true,
            modalElegirTipoDeClienteId: id,
            modalElegirTipoDeClienteNombre: nombre,
        });
    }
    cerrarElegirTipoDeCliente(elegido) {
        if (!elegido) {
            this.formRef.current.setFieldsValue({ clienteId: undefined });
        }
        this.consultarTodo();
        this.setState({
            modalElegirTipoDeCliente: false,
            modalElegirTipoDeClienteId: null,
            modalElegirTipoDeClienteNombre: null,
        });
    }
    cerrarMandarCorreoNotaDeCreditoVenta() {
        this.setState({
            modalCorreo: false,
            modalCorreoId: null,
        });
        this.formRef.current.resetFields();
        this.atras();
    }
    abrirElegirMarbetes(value, marbetes, insumoId, proyectoId) {
        if (proyectoId) {
            this.setState({
                modalElegirMarbetes: true,
                modalElegirMarbetesIndex: value,
                modalElegirMarbetesMarbetes: marbetes || [],
                modalElegirMarbetesInsumoId: insumoId,
                modalElegirMarbetesProyectoId: proyectoId
            })
        } else {
            message.warning('Elegir un proyecto para elegir marbetes')
        }
    }
    cerrarElegirMarbetes(value) {
        const insumos = this.formRef.current.getFieldValue('insumos')
        insumos[this.state.modalElegirMarbetesIndex].marbetes = value
        this.formRef.current.setFieldsValue({
            insumos: insumos
        })
        this.setState({
            modalElegirMarbetes: false,
            modalElegirMarbetesIndex: null,
            modalElegirMarbetesMarbetes: null,
            modalElegirMarbetesInsumoId: null,
            modalElegirMarbetesProyectoId: null
        })
    }

    onChangePagarVenta(value) {
        if (value) {
            this.formRef.current.setFieldsValue({ formaDePagoId: '6125149afe07662acc100d6d', devolverDinero: false })
        } else {
            this.formRef.current.setFieldsValue({ formaDePagoId: '6125149afe07662acc100d64' })
        }

        //6125149afe07662acc100d6d -- definir
        //6125149afe07662acc100d64 -- Novacion
        //6125149afe07662acc100d58 -- Eectivo
    }

    onChangeCompensacion(value) {
        if (value) {
            this.formRef.current.setFieldsValue({ formaDePagoId: '6125149afe07662acc100d58', pagarVenta: false })
        } else {
            this.formRef.current.setFieldsValue({ formaDePagoId: '6125149afe07662acc100d64' })
        }
    }

    onChangePorcentajeGeneral(value) {

        let arrayValue = this.formRef.current.getFieldValue("insumos");
        arrayValue.map((valueInsumo, index) => {
            this.onChangePrecioCantidadDescuentoNC('porcentajeGeneral', index, value)
            return null
        })

    }

    render() {
        return (
            <>
                <PageHeader
                    onBack={this.atras.bind(this)}
                    title="Agregar Nota de Credito Venta"
                    style={{
                        border: "1px solid rgb(235, 237, 240)",
                        backgroundColor: "white",
                        marginBottom: 10,
                    }}
                />
                <div style={{ backgroundColor: "white", padding: 20 }}>
                    <Spin spinning={this.state.loading}>
                        <Form
                            ref={this.formRef}
                            name="agregarNotaDeCreditoVenta"
                            layout
                            bottom={
                                <ButtonItem
                                    type="primary"
                                    htmlType="submit"
                                    style={{ display: "block", margin: "0 0 0 auto" }}
                                >
                                    Agregar
                                </ButtonItem>
                            }
                            onFinish={(values) => this.setState({ modalConfirmacion: true, valuesForm: values })}
                            onFinishFailed={this.onFinishFailed}
                        >
                            <FormItem
                                noStyle
                                shouldUpdate={(prev, curr) =>
                                    prev.insumos?.length !== curr.insumos?.length ||
                                    prev.factura !== curr.factura
                                }
                            >
                                {({ getFieldValue }) => {
                                    const factura = getFieldValue("factura");
                                    return (
                                        <>
                                            <Row>
                                                <Col span={16}>
                                                    <HeaderItem>DATOS GENERALES</HeaderItem>
                                                    <BoxItem>
                                                        <Row>
                                                            <Col span={8}>
                                                                <FormItem
                                                                    required
                                                                    noStyle
                                                                    initialValue={2}
                                                                    name="factura"
                                                                    labelCol={{ span: 0 }}
                                                                    wrapperCol={{ span: 24 }}
                                                                >
                                                                    <SelectItem
                                                                        disabled
                                                                        width="80%"
                                                                        value={this.state.factura}
                                                                        onChange={(value) => {
                                                                            this.formRef.current.setFieldsValue({
                                                                                fechaNota: moment(),
                                                                            })
                                                                            this.setState({ factura: value })
                                                                        }}
                                                                    >
                                                                        <Option value={2}>Remisión</Option>
                                                                        {this.props.permisoFacturarVentas && this.props.certificada && (
                                                                            <Option value={1}>Factura</Option>
                                                                        )}
                                                                    </SelectItem>
                                                                </FormItem>
                                                            </Col>
                                                            <Col span={8}>
                                                                <FormItem
                                                                    label="Fecha"
                                                                    name="fechaNota"
                                                                    required
                                                                    margin
                                                                    initialValue={moment()}
                                                                    labelCol={{ span: 6 }}
                                                                    wrapperCol={{ span: 20 }}
                                                                >
                                                                    <DatePickerItem
                                                                        disabled={factura === 1}
                                                                        placeholder="Fecha de Nota"
                                                                        allowClear={false}
                                                                    />
                                                                </FormItem>
                                                            </Col>
                                                            <Col span={8} />
                                                        </Row>
                                                        <Row>
                                                            <Col span={24}>
                                                                <FormItem
                                                                    label="Cliente"
                                                                    name="clienteId"
                                                                    margin
                                                                    required
                                                                    labelCol={{ span: 2 }}
                                                                    wrapperCol={{ span: 22 }}
                                                                >
                                                                    <SelectItem
                                                                        disabled
                                                                        placeholder="Cliente"
                                                                        onChange={(valueId) => this.onChangeCliente(valueId)}
                                                                    >
                                                                        {this.state.objetoClientes}
                                                                    </SelectItem>
                                                                </FormItem>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col span={24}>
                                                                <FormItem
                                                                    label="Vendedor"
                                                                    name="vendedorId"
                                                                    margin
                                                                    required
                                                                    labelCol={{ span: 2 }}
                                                                    wrapperCol={{ span: 22 }}
                                                                >
                                                                    <SelectItem disabled placeholder="Vendedor">
                                                                        {this.state.objetoUsuarios}
                                                                    </SelectItem>
                                                                </FormItem>
                                                            </Col>
                                                        </Row>
                                                    </BoxItem>
                                                </Col>

                                                <Col span={8}>
                                                    <HeaderItem>MONEDA</HeaderItem>
                                                    <BoxItem>
                                                        <FormItem
                                                            noStyle
                                                            shouldUpdate={(prev, curr) =>
                                                                prev.insumos?.length !==
                                                                curr.insumos?.length
                                                            }
                                                        >
                                                            {({ getFieldValue }) => {
                                                                const insumos = getFieldValue("insumos");

                                                                //Por el momento

                                                                const disabled =
                                                                    insumos && insumos.length > 0
                                                                        ? true
                                                                        : false;
                                                                return (
                                                                    <FormItem
                                                                        required
                                                                        margin
                                                                        name="monedaId"
                                                                        label="Moneda"
                                                                        labelCol={{ span: 8 }}
                                                                        wrapperCol={{ span: 16 }}
                                                                        initialValue='60c7e4396465ad440c41c15a'
                                                                    >
                                                                        <SelectItem disabled={disabled} onChange={(value) => {
                                                                            if (value === '60c7e4396465ad440c41c15a') {
                                                                                this.formRef.current.setFieldsValue({ tipoDeCambio: 1 })
                                                                            }
                                                                        }} placeholder="Moneda">
                                                                            {this.state.objetoMonedas}
                                                                        </SelectItem>
                                                                    </FormItem>
                                                                )
                                                            }}
                                                        </FormItem>
                                                        <FormItem noStyle shouldUpdate={(prev, curr) => prev.monedaId !== curr.monedaId || prev.insumos?.length !== curr.insumos?.length}>
                                                            {({ getFieldValue }) => {

                                                                //Por el momento

                                                                const monedaId = getFieldValue('monedaId')
                                                                const insumos = getFieldValue("insumos");
                                                                const disabled =
                                                                    insumos && insumos.length > 0
                                                                        ? true
                                                                        : false;

                                                                return (
                                                                    <FormItem
                                                                        required
                                                                        number
                                                                        margin
                                                                        label="T. Cambio"
                                                                        name="tipoDeCambio"
                                                                        type="number"
                                                                        initialValue={1}
                                                                        labelCol={{ span: 8 }}
                                                                        wrapperCol={{ span: 16 }}
                                                                    >
                                                                        <InputNumberItem
                                                                            disabled={monedaId === '60c7e4396465ad440c41c15a' || disabled}
                                                                            dinero
                                                                            placeholder="T. Cambio"
                                                                        />
                                                                    </FormItem>
                                                                )
                                                            }}
                                                        </FormItem>
                                                        <FormItem shouldUpdate noStyle>
                                                            {({ getFieldValue }) => {
                                                                const pagarVenta = getFieldValue('pagarVenta')
                                                                const devolverDinero = getFieldValue('devolverDinero')

                                                                if (pagarVenta || devolverDinero) {
                                                                    return (
                                                                        <>
                                                                            <p style={{ textAlign: 'center' }}>Moneda de la venta: {this.state.datosVenta?.moneda?.abreviacion}</p>
                                                                            <FormItem
                                                                                required
                                                                                number
                                                                                margin
                                                                                label={`T. C. ${this.state.datosVenta?.moneda?.abreviacion ? this.state.datosVenta?.moneda?.abreviacion : ''}`}
                                                                                name="tipoDeCambioVenta"
                                                                                type="number"
                                                                                initialValue={1}
                                                                            >
                                                                                <InputNumberItem
                                                                                    disabled={this.state.datosVenta?.monedaId === '60c7e4396465ad440c41c15a'}
                                                                                    dinero
                                                                                    placeholder={`T. C. ${this.state.datosVenta?.moneda?.abreviacion ? this.state.datosVenta?.moneda?.abreviacion : ''}`}
                                                                                />
                                                                            </FormItem>
                                                                            <p style={{ textAlign: 'center' }}>Tipo de cambio venta: {dineroDisabledMask(this.state.datosVenta?.tipoDeCambio)}</p>
                                                                        </>
                                                                    )
                                                                }
                                                            }}
                                                        </FormItem>
                                                    </BoxItem>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col span={6}>
                                                    <HeaderItem>PAGO</HeaderItem>
                                                    <BoxItem>
                                                        <FormItem shouldUpdate noStyle>
                                                            {({ getFieldValue }) => {
                                                                const devolverDinero = getFieldValue('devolverDinero')
                                                                return (
                                                                    <>
                                                                        <FormItem
                                                                            name="pagarVenta"
                                                                            label="Pagar Venta"
                                                                            tooltip={{ title: "Pagar la venta existente con esta nota de credito" }}
                                                                            margin
                                                                            pattern
                                                                            valuePropName="checked"
                                                                            labelCol={{ span: 12 }}
                                                                            wrapperCol={{ span: 12 }}
                                                                        >
                                                                            <SwitchItem onChange={this.onChangePagarVenta.bind(this)} />
                                                                        </FormItem>
                                                                        <FormItem
                                                                            name="devolverDinero"
                                                                            label="Compensación"
                                                                            tooltip={{ title: "Regresar el dinero al cliente" }}
                                                                            margin
                                                                            pattern
                                                                            valuePropName="checked"
                                                                            labelCol={{ span: 12 }}
                                                                            wrapperCol={{ span: 12 }}
                                                                        >
                                                                            <SwitchItem onChange={this.onChangeCompensacion.bind(this)} />
                                                                        </FormItem>
                                                                        {devolverDinero &&
                                                                            <FormItem
                                                                                required
                                                                                margin
                                                                                name="bancoId"
                                                                                label="Banco"
                                                                                labelCol={{ span: 6 }}
                                                                                wrapperCol={{ span: 18 }}
                                                                            >
                                                                                <SelectItem placeholder="Banco" >
                                                                                    {this.state.objetoBancos}
                                                                                </SelectItem>
                                                                            </FormItem>
                                                                        }
                                                                    </>
                                                                )
                                                            }}
                                                        </FormItem>
                                                    </BoxItem>
                                                </Col>
                                                <Col span={18}>
                                                    <HeaderItem>FORMA DE PAGO</HeaderItem>
                                                    <BoxItem>
                                                        <FormItem
                                                            noStyle
                                                            shouldUpdate={(prev, curr) =>
                                                                prev.contado !== curr.contado ||
                                                                prev.factura !== curr.factura
                                                            }
                                                        >
                                                            {({ getFieldValue }) => {
                                                                const factura = getFieldValue("factura");
                                                                const usoCFDI =
                                                                    factura === 1 ? (
                                                                        <FormItem
                                                                            name="usoDeCfdiId"
                                                                            label="Uso de CFDI"
                                                                            initialValue="6123bd944518d23048736747"
                                                                            margin
                                                                            required
                                                                            labelCol={{ span: 8 }}
                                                                            wrapperCol={{ span: 16 }}
                                                                        >
                                                                            <SelectItem placeholder="Uso de CFDI">
                                                                                {this.state.objetoUsosDeCFDI}
                                                                            </SelectItem>
                                                                        </FormItem>
                                                                    ) : null;
                                                                return (
                                                                    <>
                                                                        <Row>
                                                                            <FormItem
                                                                                noStyle
                                                                                shouldUpdate={(prev, curr) =>
                                                                                    prev.insumos?.length !==
                                                                                    curr.insumos?.length
                                                                                }
                                                                            >
                                                                                {({ getFieldValue }) => {
                                                                                    const insumos =
                                                                                        getFieldValue("insumos");
                                                                                    const disabled =
                                                                                        insumos && insumos.length > 0
                                                                                            ? true
                                                                                            : false;
                                                                                    return (
                                                                                        <>
                                                                                            <Col span={8}>
                                                                                                <FormItem
                                                                                                    label="Proyecto"
                                                                                                    name="proyectoId"
                                                                                                    margin
                                                                                                    required
                                                                                                    labelCol={{ span: 8 }}
                                                                                                    wrapperCol={{ span: 16 }}
                                                                                                >
                                                                                                    <SelectItem
                                                                                                        placeholder="Proyecto"
                                                                                                        disabled
                                                                                                    >
                                                                                                        {this.state.objetoProyectos}
                                                                                                    </SelectItem>
                                                                                                </FormItem>
                                                                                            </Col>
                                                                                            <Col span={8}>
                                                                                                <FormItem
                                                                                                    name="precioId"
                                                                                                    label="Precio"
                                                                                                    margin
                                                                                                    required
                                                                                                    labelCol={{ span: 8 }}
                                                                                                    wrapperCol={{ span: 16 }}
                                                                                                >
                                                                                                    <SelectItem
                                                                                                        placeholder="Precio"
                                                                                                        disabled={disabled}
                                                                                                    >
                                                                                                        {this.state.objetoPrecios}
                                                                                                    </SelectItem>
                                                                                                </FormItem>
                                                                                            </Col>
                                                                                        </>
                                                                                    );
                                                                                }}
                                                                            </FormItem>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col span={8}>
                                                                                <FormItem noStyle shouldUpdate={(prev, curr) => prev.devolverDinero !== curr.devolverDinero}>
                                                                                    {({ getFieldValue }) => {
                                                                                        const devolverDinero = getFieldValue('devolverDinero')
                                                                                        return (
                                                                                            <FormItem
                                                                                                name="formaDePagoId"
                                                                                                label="F Pago"
                                                                                                initialValue="6125149afe07662acc100d64"
                                                                                                margin
                                                                                                required
                                                                                                labelCol={{ span: 8 }}
                                                                                                wrapperCol={{ span: 16 }}
                                                                                            >
                                                                                                <SelectItem disabled={!devolverDinero} placeholder="Forma de Pago">
                                                                                                    {this.state.objetoFormasDePago}
                                                                                                </SelectItem>
                                                                                            </FormItem>
                                                                                        )
                                                                                    }}
                                                                                </FormItem>
                                                                            </Col>
                                                                            <Col span={8}>
                                                                                {usoCFDI}
                                                                            </Col>
                                                                        </Row>
                                                                    </>
                                                                );
                                                            }}
                                                        </FormItem>
                                                    </BoxItem>
                                                </Col>
                                            </Row>
                                        </>
                                    );
                                }}
                            </FormItem>
                            <HeaderItem>INSUMOS</HeaderItem>
                            <BoxItem>
                                <Row>
                                    <Col span={18}></Col>
                                    <Col span={6}>
                                        <FormItem
                                            name={"porcentajeGeneral"}
                                            label='Porcentaje'
                                            numberCero
                                            margin
                                        >
                                            <InputNumberItem
                                                sinBorde
                                                placeholder="Porcentaje"
                                                porcentaje
                                                max={100}
                                                onChange={(value) => this.onChangePorcentajeGeneral(value)}
                                            />
                                        </FormItem>
                                    </Col>
                                </Row>
                                <FormInitial.List name="insumos">
                                    {(fields) => (
                                        <>
                                            <table className="table">
                                                {fields.length > 0 ? (
                                                    <thead>
                                                        <tr>
                                                            <th className="th-border" style={{ width: "18%" }}>
                                                                Insumo
                                                            </th>
                                                            <th className="th-border" style={{ width: "9%" }}>
                                                                PU
                                                            </th>
                                                            <th className="th-border" style={{ width: "8%" }}>
                                                                Cant.
                                                            </th>
                                                            <th className="th-border" style={{ width: "8%" }}>
                                                                Desc.
                                                            </th>
                                                            <th className="th-border" style={{ width: "8%" }}>
                                                                Subtotal
                                                            </th>
                                                            <th className="th-border" style={{ width: "8%" }}>
                                                                Impuestos
                                                            </th>
                                                            <th className="th-border" style={{ width: "8%" }}>
                                                                Total
                                                            </th>
                                                            <th className="th-border" style={{ width: "8%" }}>
                                                                DESC. % N.C.
                                                            </th>
                                                            <th className="th-border" style={{ width: "8%" }}>
                                                                DESC: $ N.C.
                                                            </th>
                                                            <th className="th-border" style={{ width: "8%" }}>
                                                                Impuestos N.C.
                                                            </th>
                                                            <th className="th-border" style={{ width: "9%" }}>
                                                                Total N.C.
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                ) : null}
                                                {fields.map(
                                                    ({ key, name, fieldKey, ...restField }, arrayKey) => (
                                                        <tbody key={arrayKey}>
                                                            <tr>
                                                                <td className="td-border">
                                                                    <FormItem
                                                                        {...restField}
                                                                        name={[name, "insumoId"]}
                                                                        required
                                                                        noStyle
                                                                    >
                                                                        <SelectItem
                                                                            sinBorde
                                                                            autoFocus
                                                                            disabled
                                                                            dropdownMatchSelectWidth={600}
                                                                            width="100%"
                                                                            placeholder="Insumo"
                                                                        >
                                                                            {this.state.objetoInsumos}
                                                                        </SelectItem>
                                                                    </FormItem>
                                                                </td>
                                                                <td className="td-border">
                                                                    <FormItem
                                                                        {...restField}
                                                                        name={[name, "precioUnitario"]}
                                                                        noStyle
                                                                        required
                                                                        numberCero
                                                                    >
                                                                        <InputNumberItem
                                                                            sinBorde
                                                                            disabled
                                                                            placeholder="Precio Unitario"
                                                                            dineroDisabled
                                                                        />
                                                                    </FormItem>
                                                                </td>
                                                                <td className="td-border">
                                                                    <FormItem
                                                                        {...restField}
                                                                        name={[name, "cantidad"]}
                                                                        number
                                                                        noStyle
                                                                        type="number"
                                                                    >
                                                                        <InputNumberItem
                                                                            sinBorde
                                                                            disabled
                                                                            placeholder={"Cantidad"}
                                                                        />
                                                                    </FormItem>
                                                                </td>
                                                                <td className="td-border">
                                                                    <FormItem
                                                                        {...restField}
                                                                        name={[name, "descuento"]}
                                                                        required
                                                                        numberCero
                                                                        noStyle
                                                                        initialValue={0}
                                                                    >
                                                                        <InputNumberItem
                                                                            sinBorde
                                                                            placeholder="Descuento"
                                                                            porcentaje
                                                                            disabled
                                                                        />
                                                                    </FormItem>
                                                                </td>
                                                                <td className="td-border">
                                                                    <FormItem
                                                                        {...restField}
                                                                        name={[name, "subtotal"]}
                                                                        required
                                                                        numberCero
                                                                        noStyle
                                                                    >
                                                                        <InputNumberItem
                                                                            sinBorde
                                                                            disabled
                                                                            placeholder="Subtotal"
                                                                            dineroDisabled
                                                                        />
                                                                    </FormItem>
                                                                </td>
                                                                <td className="td-border">
                                                                    <FormItem
                                                                        {...restField}
                                                                        name={[name, "impuestos"]}
                                                                        required
                                                                        numberCero
                                                                        noStyle
                                                                    >
                                                                        <InputNumberItem
                                                                            sinBorde
                                                                            disabled
                                                                            dineroDisabled
                                                                            placeholder="Impuestos"
                                                                        />
                                                                    </FormItem>
                                                                </td>
                                                                <td className="td-border">
                                                                    <FormItem
                                                                        {...restField}
                                                                        name={[name, "total"]}
                                                                        required
                                                                        numberCero
                                                                        noStyle
                                                                    >
                                                                        <InputNumberItem
                                                                            sinBorde
                                                                            dineroDisabled
                                                                            disabled
                                                                            placeholder="Total"
                                                                        />
                                                                    </FormItem>
                                                                </td>
                                                                <td className="td-border">
                                                                    <FormItem
                                                                        {...restField}
                                                                        name={[name, "procentajeNC"]}
                                                                        numberCero
                                                                        noStyle
                                                                        initialValue={0}
                                                                    >
                                                                        <InputNumberItem
                                                                            sinBorde
                                                                            placeholder="Porcentaje"
                                                                            porcentaje
                                                                            max={100}
                                                                            onChange={(value) =>
                                                                                this.onChangePrecioCantidadDescuentoNC(
                                                                                    'porcentaje',
                                                                                    arrayKey
                                                                                )
                                                                            }
                                                                        />
                                                                    </FormItem>
                                                                </td>
                                                                <td className="td-border">
                                                                    <FormItem
                                                                        noStyle
                                                                        shouldUpdate={(prev, curr) =>
                                                                            prev.insumos[arrayKey]?.precioUnitario !==
                                                                            curr.insumos[arrayKey]?.precioUnitario
                                                                        }
                                                                    >
                                                                        {({ getFieldValue }) => {
                                                                            const insumos = getFieldValue("insumos");
                                                                            const precioUnitario =
                                                                                insumos[arrayKey].precioUnitario;
                                                                            let max = 0;
                                                                            if (
                                                                                precioUnitario
                                                                            ) {
                                                                                max = precioUnitario;
                                                                            }
                                                                            return (
                                                                                <FormItem
                                                                                    {...restField}
                                                                                    name={[name, "precioUnitarioNC"]}
                                                                                    number
                                                                                    noStyle
                                                                                    type="number"
                                                                                >
                                                                                    <InputNumberItem
                                                                                        sinBorde
                                                                                        dinero
                                                                                        placeholder={
                                                                                            max && max !== Infinity
                                                                                                ? "Max: " + max
                                                                                                : "Cantidad"
                                                                                        }
                                                                                        onChange={(value) =>
                                                                                            this.onChangePrecioCantidadDescuentoNC(
                                                                                                'cantidad',
                                                                                                arrayKey
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </FormItem>
                                                                            );
                                                                        }}
                                                                    </FormItem>
                                                                </td>
                                                                <td className="td-border">
                                                                    <FormItem
                                                                        {...restField}
                                                                        name={[name, "impuestosNC"]}
                                                                        numberCero
                                                                        noStyle
                                                                    >
                                                                        <InputNumberItem
                                                                            sinBorde
                                                                            disabled
                                                                            dineroDisabled
                                                                            placeholder="Impuestos"
                                                                        />
                                                                    </FormItem>
                                                                </td>
                                                                <td className="td-border">
                                                                    <FormItem
                                                                        {...restField}
                                                                        name={[name, "totalNC"]}
                                                                        numberCero
                                                                        noStyle
                                                                    >
                                                                        <InputNumberItem
                                                                            sinBorde
                                                                            dineroDisabled
                                                                            disabled
                                                                            placeholder="Total"
                                                                        />
                                                                    </FormItem>
                                                                </td>
                                                            </tr>
                                                            <FormItem noStyle shouldUpdate={(prev, curr) => prev.insumos[arrayKey]?.leyenda !== curr.insumos[arrayKey]?.leyenda}>
                                                                {({ getFieldValue }) => {
                                                                    let arrayValue = getFieldValue("insumos");
                                                                    if (arrayValue[arrayKey]?.leyenda) {
                                                                        return (
                                                                            <tr>
                                                                                <td colSpan="11" className="td-border">
                                                                                    <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left' }}>
                                                                                        <div style={{ width: '90%', textAlign: 'left', paddingLeft: 20 }}>
                                                                                            <b>Leyenda:</b> {arrayValue[arrayKey].leyenda}
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                }}
                                                            </FormItem>
                                                        </tbody>
                                                    )
                                                )}
                                            </table>
                                        </>
                                    )}
                                </FormInitial.List>
                                <Row style={{ marginTop: 10 }}>
                                    <Col span={8}>
                                        <FormItem
                                            pattern
                                            name='observaciones'
                                            label='Observaciones'
                                        >
                                            <TextAreaItem />
                                        </FormItem>
                                    </Col>
                                    <Col span={4}>
                                        <FormItem
                                            label="Imprimir"
                                            name="imprimirObservaciones"
                                            valuePropName="checked"
                                        >
                                            <Checkbox></Checkbox>
                                        </FormItem>
                                    </Col>
                                    <Col span={4}>
                                        <table className="table" style={{ marginTop: 0 }}>
                                            <tbody>
                                                <tr>
                                                    <th className="th-totales" style={{ width: "50%" }}>
                                                        Subtotal
                                                    </th>
                                                    <td
                                                        className="td-totales-right"
                                                        style={{ width: "50%" }}
                                                    >
                                                        {dineroDisabledMask(this.state.subtotalVenta)}
                                                    </td>
                                                </tr>
                                            </tbody>
                                            {this.state.arrayImpuestosVenta && this.state.arrayImpuestosVenta.map((valueArray) => (
                                                <tbody>
                                                    <tr key={valueArray.descripcionFactura}>
                                                        <th className="th-totales">
                                                            {valueArray.descripcionFactura}
                                                        </th>
                                                        <td className="td-totales-right">
                                                            {dineroDisabledMask(valueArray.totalImpuestos)}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            ))}
                                            <tbody>
                                                <tr>
                                                    <th className="th-totales">Total</th>
                                                    <td className="td-totales-right">
                                                        {dineroDisabledMask(this.state.totalVenta)}
                                                    </td>
                                                </tr>
                                            </tbody>
                                            {this.state.arrayRetencionesVenta && this.state.arrayRetencionesVenta.map(
                                                (valueArrayRetenciones) => (
                                                    <tbody>
                                                        <tr key={valueArrayRetenciones.descripcionFactura}>
                                                            <th className="th-totales">
                                                                {valueArrayRetenciones.descripcionFactura}
                                                            </th>
                                                            <td className="td-totales-right">
                                                                {dineroDisabledMask(
                                                                    valueArrayRetenciones.totalRetenciones
                                                                )}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                )
                                            )}
                                            <tbody>
                                                <tr>
                                                    <th className="th-totales">Total Pagar</th>
                                                    <td className="td-totales-right">
                                                        {dineroDisabledMask(this.state.totalPagarVenta)}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Col>
                                    <Col span={8}>
                                        <FormItem shouldUpdate noStyle>
                                            {({ getFieldValue }) => {
                                                let arrayInsumos = getFieldValue("insumos") || [];
                                                let arrayRetenciones = [];
                                                let cuentasCliente = 0;
                                                let subtotal = 0;
                                                let arrayImpuestos = [];
                                                let total = 0;
                                                arrayInsumos.map((value) => {
                                                    if (value) {
                                                        if (
                                                            value.subtotalNC &&
                                                            value.totalNC &&
                                                            value.arrayImpuestosNC
                                                        ) {
                                                            subtotal += value.subtotalNC;
                                                            total += value.subtotalNC;
                                                            value.arrayImpuestosNC.map((valueImpuestos) => {
                                                                let index = arrayImpuestos.findIndex(
                                                                    (valueImpuestosAnterios) =>
                                                                        valueImpuestosAnterios.descripcionFactura ===
                                                                        valueImpuestos.descripcionFactura
                                                                        &&
                                                                        valueImpuestosAnterios.tasaCuota ===
                                                                        valueImpuestos.tasaCuota
                                                                        &&
                                                                        valueImpuestosAnterios.impuesto ===
                                                                        valueImpuestos.impuesto
                                                                );
                                                                if (index !== -1) {
                                                                    let newItem = {
                                                                        totalImpuestos:
                                                                            arrayImpuestos[index].totalImpuestos +
                                                                            valueImpuestos.totalImpuestos,
                                                                        baseImpuesto:
                                                                            arrayImpuestos[index].baseImpuesto +
                                                                            valueImpuestos.baseImpuesto,
                                                                        descripcionFactura:
                                                                            valueImpuestos.descripcionFactura,
                                                                        tasaCuota:
                                                                            valueImpuestos.tasaCuota,
                                                                        impuesto:
                                                                            valueImpuestos.impuesto,
                                                                        montoImpuesto:
                                                                            valueImpuestos.montoImpuesto,
                                                                        local: valueImpuestos.local,
                                                                    };
                                                                    arrayImpuestos.splice(index, 1, newItem);
                                                                } else {
                                                                    arrayImpuestos.push({
                                                                        tasaCuota: valueImpuestos.tasaCuota,
                                                                        impuesto: valueImpuestos.impuesto,
                                                                        local: valueImpuestos.local,
                                                                        montoImpuesto: valueImpuestos.montoImpuesto,
                                                                        baseImpuesto: valueImpuestos.baseImpuesto,
                                                                        descripcionFactura: valueImpuestos.descripcionFactura,
                                                                        totalImpuestos: valueImpuestos.totalImpuestos,
                                                                    });
                                                                }
                                                                return null;
                                                            });
                                                            if (value.arrayRetencionesNC) {
                                                                value.arrayRetencionesNC.map((valueRetenciones) => {
                                                                    let index = arrayRetenciones.findIndex(
                                                                        (valueRetencionesAnterios) =>
                                                                            valueRetencionesAnterios.descripcionFactura ===
                                                                            valueRetenciones.descripcionFactura
                                                                            &&
                                                                            valueRetencionesAnterios.tasaCuota ===
                                                                            valueRetenciones.tasaCuota
                                                                            &&
                                                                            valueRetencionesAnterios.impuesto ===
                                                                            valueRetenciones.impuesto
                                                                    );
                                                                    cuentasCliente += round2Decimals(valueRetenciones.totalRetenciones);
                                                                    if (index !== -1) {
                                                                        let newItem = {
                                                                            totalRetenciones:
                                                                                arrayRetenciones[index].totalRetenciones +
                                                                                valueRetenciones.totalRetenciones,
                                                                            baseRetencion:
                                                                                arrayRetenciones[index].baseRetencion +
                                                                                valueRetenciones.baseRetencion,
                                                                            descripcionFactura:
                                                                                valueRetenciones.descripcionFactura,
                                                                            tasaCuota:
                                                                                valueRetenciones.tasaCuota,
                                                                            impuesto:
                                                                                valueRetenciones.impuesto,
                                                                            montoRetencion:
                                                                                valueRetenciones.montoRetencion,
                                                                            local: valueRetenciones.local,
                                                                        };
                                                                        arrayRetenciones.splice(index, 1, newItem);
                                                                    } else {
                                                                        arrayRetenciones.push({
                                                                            tasaCuota: valueRetenciones.tasaCuota,
                                                                            impuesto: valueRetenciones.impuesto,
                                                                            montoRetencion: valueRetenciones.montoRetencion,
                                                                            local: valueRetenciones.local,
                                                                            baseRetencion: valueRetenciones.baseRetencion,
                                                                            descripcionFactura: valueRetenciones.descripcionFactura,
                                                                            totalRetenciones: valueRetenciones.totalRetenciones,
                                                                        });
                                                                    }
                                                                    return null;
                                                                });
                                                            }
                                                        }
                                                    }
                                                    return null;
                                                });
                                                arrayImpuestos = arrayImpuestos.map(valueImpuesto => {
                                                    total += round2Decimals(valueImpuesto.totalImpuestos)
                                                    return {
                                                        ...valueImpuesto,
                                                        totalImpuestos: round2Decimals(valueImpuesto.totalImpuestos),
                                                    }
                                                })
                                                if (this.state.totalPagar !== (total - cuentasCliente)) {
                                                    this.setState({ totalPagar: (total - cuentasCliente) });
                                                }
                                                return (
                                                    <table className="table" style={{ marginTop: 0 }}>
                                                        <tbody>
                                                            <tr>
                                                                <th
                                                                    className="th-totales"
                                                                    style={{ width: "50%" }}
                                                                >
                                                                    Subtotal N.C.
                                                                </th>
                                                                <td
                                                                    className="td-totales-right"
                                                                    style={{ width: "50%" }}
                                                                >
                                                                    {dineroDisabledMask(subtotal)}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        {arrayImpuestos.map((valueArray) => (
                                                            <tbody>
                                                                <tr key={valueArray.descripcionFactura}>
                                                                    <th className="th-totales">
                                                                        {valueArray.descripcionFactura + ' N.C.'}
                                                                    </th>
                                                                    <td className="td-totales-right">
                                                                        {dineroDisabledMask(
                                                                            valueArray.totalImpuestos
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        ))}
                                                        <tbody>
                                                            <tr>
                                                                <th className="th-totales">Total N.C.</th>
                                                                <td className="td-totales-right">
                                                                    {dineroDisabledMask(total)}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        {arrayRetenciones.map((value) => (
                                                            <tbody>
                                                                <tr>
                                                                    <th className="th-totales">
                                                                        {value.descripcionFactura + ' N.C.'}
                                                                    </th>
                                                                    <td className="td-totales-right">
                                                                        {dineroDisabledMask(value.totalRetenciones)}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        ))}
                                                        <tbody>
                                                            <tr>
                                                                <th className="th-totales">Total a Pagar N.C.</th>
                                                                <td className="td-totales-right">
                                                                    {dineroDisabledMask(total - cuentasCliente)}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                );
                                            }}
                                        </FormItem>
                                    </Col>
                                </Row>
                            </BoxItem>
                        </Form>
                    </Spin>
                </div>
                <MandarCorreoNotaDeCreditoVenta
                    visible={this.state.modalCorreo}
                    id={this.state.modalCorreoId}
                    cerrarMandarCorreoNotaDeCreditoVenta={this.cerrarMandarCorreoNotaDeCreditoVenta.bind(this)}
                />
                <ModalItem
                    title="Confirmacion"
                    visible={this.state.modalConfirmacion}
                    onCancel={() => this.setState({ modalConfirmacion: false })}
                    onOk={() => {
                        this.setState({ modalConfirmacion: false });
                        this.onFinish(this.state.valuesForm);
                    }}
                    spinning={false}
                    footer
                >
                    <h1 style={{ textAlign: 'center' }}>Desea continuar</h1>
                </ModalItem>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        headersToken: state.user.headersToken,
        empresaId: state.empresa.id,
        permisoFacturarVentas:
            state.user.tipo === 0 ||
            state.user.permisos.find(
                (valueTodos) =>
                    valueTodos.permisoId.toString() === "615ce36bf30e81b54183cfaf"
            )?.activado,
        certificada: state.empresa.certificada,
    };
};

export default connect(mapStateToProps)(AgregarNotaDeCreditoVenta);
