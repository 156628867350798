import { PageHeader, Select, Space, Tag } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { ButtonItem, SelectItem, TableItem } from "../../../Components/Items";

const { Option } = Select;

class InventarioConsignaciones extends Component {
  state = {
    loading: false,
    objetoClientes: [],
    todasClientes: [],
    todasInventario: [],
    clinete: undefined,
    clienteId: undefined,
  };

  async componentDidMount() {
    this.setState({ loading: true });
    await axios
      .post(
        "consignaciones/selectClientesConsignaciones",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasClientes: res.data });
          this.setState({
            objetoClientes: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.stringBuscar}
                </Option>
              );
            }),
          });
        }
      });
    axios
      .post(
        "consignaciones/selectInventarioConsignaciones",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({
            todasInsumos: res.data.map((value) => {
              return {
                ...value,
                codigo: value.insumo.codigo,
                descripcion: value.insumo.descripcion,
                accion: { _id: value._id, estado: value.estado },
              };
            }),
          });
        }
        this.setState({ loading: false });
      });
    this.setState({ loading: false });
  }

  onChangeCliente(value) {
    if (value === 0) {
      this.setState({ loading: true, cliente: undefined, clienteId: undefined });
      axios
        .post(
          "consignaciones/selectInventarioConsignaciones",
          { empresaId: this.props.empresaId, clienteId: value },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.status === 404) {
            this.setState({ todasInsumos: [] })
          } else {
            this.setState({
              todasInsumos: res.data.map((value) => {
                return {
                  ...value,
                  codigo: value.insumo.codigo,
                  descripcion: value.insumo.descripcion,
                  accion: { _id: value._id, estado: value.estado },
                };
              }),
            });
          }
          this.setState({ loading: false });
        });
    } else {
      const cliente = this.state.todasClientes.find(valueState => valueState._id.toString() === value.toString())
      this.setState({ loading: true, cliente: cliente.nombre, clienteId: cliente._id });
      axios
        .post(
          "consignaciones/selectInventarioConsignaciones",
          { empresaId: this.props.empresaId, clienteId: value },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.status === 404) {
            this.setState({ todasInsumos: [] })
          } else {
            this.setState({
              todasInsumos: res.data.map((value) => {
                return {
                  ...value,
                  codigo: value.insumo.codigo,
                  descripcion: value.insumo.descripcion,
                  accion: { _id: value._id, estado: value.estado },
                };
              }),
            });
          }
          this.setState({ loading: false });
        });

    }
  }

  agregarConsignacion(){
    this.props.history.push('/panel/agregarConsignacion')
  }

  bajaConsignacion(){
    this.props.history.push('/panel/agregarBajaConsignacion')
  }

  render() {
    const isMobile = window.innerWidth <= 1000;
    const columns = [
      {
        title: "Codigo",
        dataIndex: "codigo",
        responsive: ["md"],
        key: "codigo",
        sorter: (a, b) => {
          if (a.codigo < b.codigo) {
            return -1;
          }
          if (a.codigo > b.codigo) {
            return 1;
          }
          return 0;
        },
        buscar: "codigo",
        width: "20%",
      },
      {
        title: "Descripcion",
        dataIndex: "descripcion",
        key: "descripcion",
        sorter: (a, b) => {
          if (a.descripcion < b.descripcion) {
            return -1;
          }
          if (a.descripcion > b.descripcion) {
            return 1;
          }
          return 0;
        },
        defaultSortOrder: 'ascend',
        buscar: "descripcion",
        width: "40%",
      },
      {
        title: "Cantidad",
        dataIndex: "cantidad",
        key: "cantidad",
        sorter: (a, b) => {
          if (a.cantidad < b.cantidad) {
            return -1;
          }
          if (a.cantidad > b.cantidad) {
            return 1;
          }
          return 0;
        },
        width: "20%",
        render: (value) => value.toFixed(4),
      },
      {
        title: "Unidad",
        dataIndex: "unidad",
        responsive: ["md"],
        key: "unidad",
        render: (value) => value.nombre,
        width: "10%",
      },
      {
        title: "Estado",
        dataIndex: "estado",
        responsive: ["md"],
        key: "estado",
        render: (value, record, index) => {
          if (value === 2) {
            return (
              <Tag key={index.toString()} color="blue">
                Apartado
              </Tag>
            )
          }
        },
        width: "10%",
      },
    ];
    return (
      <>
        <PageHeader
          title={this.state.clientes ? "Consignaciones: " + this.state.clientes : "Consignaciones: Todos"}
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <Space style={{ width: "100%" }}>
              <SelectItem
                placeholder="Cliente"
                width={200}
                onChange={this.onChangeCliente.bind(this)}
                defaultValue={0}
              >
                <Option value={0}>Todos</Option>
                {this.state.objetoClientes}
              </SelectItem>
              {this.props.permisoAgregarConsignacionInventario && !isMobile ?
              <ButtonItem
                  key="agregarConsignacion"
                  onClick={this.agregarConsignacion.bind(this)}
                >
                  Agregar Consignacion
                </ButtonItem> : null}
               {this.props.permisoDarBajaConsignacionInventario && !isMobile ?
                <ButtonItem
                blanco
                  key="bajaConsignacion"
                  onClick={this.bajaConsignacion.bind(this)}
                >
                  Baja Consignacion
                </ButtonItem> : null}
            </Space>
          }
        />
        <TableItem
          size="small"
          dataSource={this.state.todasInsumos}
          columns={columns}
          pagination={false}
          loading={this.state.loading}
          onRow={(record, rowIndex) => {
            return {
              onDoubleClick: (event) => {
                this.props.history.push("/panel/inventarioInsumo", { id: record.insumo._id, clienteId: this.state.clienteId });
              },
            };
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    permisoAgregarConsignacionInventario:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6750938e798d4e0043687248"
      )?.activado,
    permisoDarBajaConsignacionInventario:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "675093ca798d4e0043687249"
      )?.activado,
  };
};

export default connect(mapStateToProps)(InventarioConsignaciones);
