import { Empty, PageHeader, Popconfirm, Space, message } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { TableItem, ButtonItem } from "../../../Components/Items";
import moment from "moment";
import "moment/locale/es";
import { CheckCircleOutlined, EditOutlined, StopOutlined } from "@ant-design/icons";
import AgregarPaciente from "./AgregarPaciente/AgregarPaciente";
import EditarPaciente from "./EditarPaciente/EditarPaciente";

moment.locale("es");

class Pacientes extends Component {
  state = {
    modalAgregar: false,
    modalEditar: false,
    todosPacientes: [],
    loading: false,
    pacienteId: null,
  };

  // Abrir modal para agregar
  abrirAgregarPaciente(){
    this.setState({ modalAgregar: true, pacienteId: null });
  };

  // Cerrar modal de agregar
  cerrarAgregarPaciente(){
    this.setState({ modalAgregar: false });
    this.consultarTodosPacientes();
  };

  // Abrir modal para editar
  abrirEditarPaciente(pacienteId){
    this.setState({ modalEditar: true, pacienteId: pacienteId });
  };

  // Cerrar modal de editar
  cerrarEditarPaciente(){
    this.setState({ modalEditar: false });
    this.consultarTodosPacientes();
  };

  componentDidMount() {
    this.consultarTodosPacientes();
  }

  consultarTodosPacientes() {
    this.setState({ loading: true });
    axios
      .post(
        "pacientes/selectTodosPacientes",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          this.setState({
            todosPacientes: res.data.pacientes
          });
        } else {
          message.error("Error no se encotraron paciente");
        }
      });
  }

  // Deshabilita un Paciente 
  deshabilitarPaciente(id) {
    axios
      .post(
        "pacientes/deshabilitarPaciente",
        { pacienteId: id },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Paciente deshabilitado exitosamente");
          this.consultarTodosPacientes();
        } else if (res.data.success === 1){
          message.error("Error al deshabilitar el doctor");
        }else{
          message.error("Error");
        }
      });
  }

  // Habilita un doctor
  habilitarPaciente(id) {
    axios
      .post(
        "pacientes/habilitarPaciente",
        { pacienteId: id },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Paciente habilitado exitosamente");
          this.consultarTodosPacientes();
        } else if (res.data.success === 1){
          message.error("Error al habilitar el doctor");
        }else{
          message.error("Error");
        }
      });
  }

  render() {
    const columns = [
      {
        title: "Código",
        dataIndex: "codigo",
        key: "codigo",
        sorter: (a, b) => {
          if (a.codigo < b.codigo) {
            return -1;
          }
          if (a.codigo > b.codigo) {
            return 1;
          }
          return 0;
        },
        buscar: "codigo",
      },
      {
        title: "Nombre del Paciente",
        dataIndex: "nombre",
        key: "nombre",
        sorter: (a, b) => {
          if (a.nombre < b.nombre) {
            return -1;
          }
          if (a.nombre > b.nombre) {
            return 1;
          }
          return 0;
        },
        buscar: "nombre",
      },
      {
        title: "Cirugia del Paciente",
        dataIndex: "cirugia",
        key: "cirugia",
        sorter: (a, b) => {
          if (a.cirugia < b.cirugia) {
            return -1;
          }
          if (a.cirugia > b.cirugia) {
            return 1;
          }
          return 0;
        },
        buscar: "cirugia",
      },
      {
        title: "Acción",
        key: "accion",
        render: (record) => (
          <Space size="middle">
            <EditOutlined
              onClick={() => this.abrirEditarPaciente(record._id)}
              style={{ color: "blue" }}
            />
           {
              // Eliminar la verificación de permiso por ahora
              <>
                {record.estado === 1 ? (
                  <Popconfirm
                    title="¿Seguro que quieres deshabilitar este doctor?"
                    onConfirm={() => this.deshabilitarPaciente(record._id)}
                    okText="Sí"
                    cancelText="No"
                  >
                    <CheckCircleOutlined style={{ color: "green" }} />
                  </Popconfirm>
                ) : (
                  <Popconfirm
                    title="¿Seguro que quieres habilitar este doctor?"
                    onConfirm={() => this.habilitarPaciente(record._id)}
                    okText="Sí"
                    cancelText="No"
                  >
                    <StopOutlined style={{ color: "red" }} />
                  </Popconfirm>
                )}
              </>
            }

          </Space>
        ),
      },
    ];

    return (
      <>
        <PageHeader
          title="Pacientes"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <ButtonItem
              type="primary"
              key="agregarPaciente"
              onClick={this.abrirAgregarPaciente.bind(this)}
            >
              Agregar Paciente
            </ButtonItem>
          }
        />
        <TableItem
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <>
                    <p>
                      No hay registros de paciente. Aquí puedes gestionar y
                      llevar un control de los paciente.
                    </p>
                  </>
                }
              />
            ),
          }}
          loading={this.state.loading}
          size="small"
          dataSource={this.state.todosPacientes}
          columns={columns}
          pagination={false}
        />
        <AgregarPaciente
          visible={this.state.modalAgregar}
          cerrarAgregarPaciente={this.cerrarAgregarPaciente.bind(this)}
        />
        <EditarPaciente
          visible={this.state.modalEditar}
          cerrarEditarPaciente={this.cerrarEditarPaciente.bind(this)}
          pacienteId={this.state.pacienteId}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(Pacientes);
