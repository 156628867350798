import { message, PageHeader, Popconfirm, Space } from "antd";
import axios from "axios";
import React, { Component } from "react";
import {ButtonItem, TableItem} from "../../../Components/Items";
import moment from "moment";
import 'moment/locale/es'
import { connect } from "react-redux";
import { CheckCircleOutlined, EditOutlined, StopOutlined } from "@ant-design/icons";
import AgregarUsuario from "./AgregarUsuario/AgregarUsuario";
import EditarUsuario from "./EditarUsuario/EditarUsuario";
import CambiarContrasena from "./CambiarContrasena/CambiarContrasena";

moment.locale('es')

class Usuarios extends Component {
  state = {
    todasUsuarios: [],
    modalAgregarUsuario: false,
    modalEditarUsuario: false,
    modalEditarUsuarioId: null,
    modalCambiarContrasena: false,
    modalCambiarContrasenaId: null
  }
  componentDidMount() {
    this.consultarUsuarios();
  }

  consultarUsuarios() {
    axios
    .post(
      "usuarios/selectUsuarios",
      { usuarioPrincipal: this.props.usuarioPrincipal },
      {
        headers: this.props.headersToken,
      }
    )
    .then((res) => {
      if (res.data.status === 404) {
      } else {
        this.setState({
          todasUsuarios: res.data.map(value => {
            return {...value, nombre: value.nombre+' '+value.apellidoPaterno+' '+value.apellidoMaterno, accion: { _id: value._id, estado: value.estado }}
          }),
        });
      }
    });
  }

  deshabilitarUsuario(value) {
    axios
      .post(
        "usuarios/deshabilitarUsuario",
        { usuarioId: value, usuarioPrincipal: this.props.usuarioPrincipal },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Usuario deshabilitado");
        } else {
          message.error("Sucedio un eror y no se pudo deshabilitar");
        }
        this.consultarUsuarios();
      });
  }
  habilitarUsuario(value) {
    axios
      .post(
        "usuarios/habilitarUsuario",
        { usuarioId: value, usuarioPrincipal: this.props.usuarioPrincipal },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Usuario habilitado");
        } else {
          message.error("Sucedio un eror y no se pudo habilitar");
        }
        this.consultarUsuarios();
      });
  }

  abrirAgregarUsuario() {
    this.setState({ modalAgregarUsuario: true });
  }
  cerrarAgregarUsuario() {
    this.setState({ modalAgregarUsuario: false });
    this.consultarUsuarios();
  }
  abrirEditarUsuario(id) {
    this.setState({ modalEditarUsuario: true, modalEditarUsuarioId: id });
  }
  cerrarEditarUsuario() {
    this.setState({ modalEditarUsuario: false });
    this.consultarUsuarios();
  }
  abrirCambiarContrasena(id) {
    this.setState({ modalCambiarContrasena: true, modalCambiarContrasenaId: id });
  }
  cerrarCambiarContrasena() {
    this.setState({ modalCambiarContrasena: false });
    this.consultarUsuarios();
  }
  render() {
    const columns = [
      {
        title: "Nombre",
        dataIndex: "nombre",
        key: "nombre",
        sorter: (a, b) => {
          if (a.nombre < b.nombre) {
            return -1;
          }
          if (a.nombre > b.nombre) {
            return 1;
          }
          return 0;
        },
      },
      {
        title: "Celular",
        dataIndex: "celular",
        key: "celular",
        sorter: (a, b) => {
          if (a.celular < b.celular) {
            return -1;
          }
          if (a.celular > b.celular) {
            return 1;
          }
          return 0;
        },
      },
      {
        title: "Fecha",
        dataIndex: "createdAt",
        key: "createdAt",
        responsive: ['md'],
        sorter: (a, b) => {
          if (moment(a.createdAt) < moment(b.createdAt)) {
            return -1;
          }
          if (moment(a.createdAt) > moment(b.createdAt)) {
            return 1;
          }
          return 0;
        },
        render: (value) => moment(value).locale('es').format('ll'),
      },
      {
        title: 'Acción',
        dataIndex: 'accion',
        key: 'accion',
        render: (value) => (
            <Space size="middle">
              <EditOutlined
                onClick={() => this.abrirEditarUsuario(value._id)}
                style={{ color: "blue" }}
              />
              {value.estado === 1 ? (
                <Popconfirm
                  title="Seguro que quieres deshabilitar este usuario"
                  onConfirm={() => this.deshabilitarUsuario(value._id)}
                  okText="Si"
                  cancelText="No"
                >
                  <CheckCircleOutlined style={{ color: "green" }} />
                </Popconfirm>
              ) : (
                <Popconfirm
                  title="Seguro que quieres habilitar este usuario"
                  onConfirm={() => this.habilitarUsuario(value._id)}
                  okText="Si"
                  cancelText="No"
                >
                  <StopOutlined style={{ color: "red" }} />
                </Popconfirm>
              )}
              <ButtonItem type='primary' size='small' onClick={() => this.abrirCambiarContrasena(value._id)}>Cambiar Contraseña</ButtonItem>
            </Space>
          ),
      }
    ]
    return (
      <>
        <PageHeader
          title="Usuarios"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={[
            <ButtonItem
              type="primary"
              key="agregarPerfil"
              onClick={this.abrirAgregarUsuario.bind(this)}
            >
              Agregar Usuario
            </ButtonItem>,
          ]}
        />
        <TableItem size='small' dataSource={this.state.todasUsuarios} columns={columns} pagination={false} />
        <AgregarUsuario
          visible={this.state.modalAgregarUsuario}
          cerrarAgregarUsuario={this.cerrarAgregarUsuario.bind(this)}
        />
                        <EditarUsuario
          visible={this.state.modalEditarUsuario}
          id={this.state.modalEditarUsuarioId}
          cerrarEditarUsuario={this.cerrarEditarUsuario.bind(this)}
        />
                                <CambiarContrasena
          visible={this.state.modalCambiarContrasena}
          id={this.state.modalCambiarContrasenaId}
          cerrarCambiarContrasena={this.cerrarCambiarContrasena.bind(this)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    usuarioPrincipal: state.user.usuarioPrincipal,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(Usuarios);