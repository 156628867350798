import { PageHeader } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { TableItem, ButtonItem } from "../../../Components/Items";
import moment from "moment";
import "moment/locale/es";
import { EyeOutlined } from "@ant-design/icons";
import VistaPrevia from "./VistaPrevia/VistaPrevia";

moment.locale("es");

class TraspasoEntreAlmacenes extends Component {
  state = {
    todasOrdenesDeTraspasoEntreAlmacenes: [],
    loading: false,
    modalVistaPrevia: false,
    modalVistaPreviaId: null,
  };
  componentDidMount() {
    this.consultarTraspasoEntreAlmacenes();
  }
  consultarTraspasoEntreAlmacenes() {
    this.setState({ loading: true });
    axios
      .post(
        "traspasoEntreAlmacenes/selectOrdenes",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.status === 404) {
        } else {
          this.setState({
            todasOrdenesDeTraspasoEntreAlmacenes: res.data.map(valueT => {
              return {
                ...valueT,
                usuario: valueT.usuario.nombre+' '+valueT.usuario.apellidoPaterno+' '+valueT.usuario.apellidoMaterno,
                proyectoOrigen: valueT.proyectoOrigen.nombre,
                proyectoDestino: valueT.proyectoDestino.nombre,
              }
            }),
          });
        }
      });
  }
  agregarOrdenDeTraspasoEntreAlmacenes() {
    this.props.history.push("/panel/agregarOrdenDeTraspasoEntreAlmacenes");
  }
  cerrarVistaPrevia() {
    this.setState({
      modalVistaPrevia: false,
      modalVistaPreviaId: null,
    });
  }
  render() {
    const isMobile = window.innerWidth <= 1000;
    const columns = [
      {
        title: "Numero",
        dataIndex: "numero",
        responsive: ["md"],
        key: "numero",
        sorter: (a, b) => {
          if (a.numero < b.numero) {
            return -1;
          }
          if (a.numero > b.numero) {
            return 1;
          }
          return 0;
        },
        buscar: "numero",
        width: "10%",
      },
      {
        title: "Origen",
        dataIndex: "proyectoOrigen",
        key: "proyectoOrigen",
        sorter: (a, b) => {
          if (a.proyectoOrigen < b.proyectoOrigen) {
            return -1;
          }
          if (a.proyectoOrigen > b.proyectoOrigen) {
            return 1;
          }
          return 0;
        },
        buscar: "proyectoOrigen",
        width: "20%",
      },
      {
        title: "Destino",
        dataIndex: "proyectoDestino",
        key: "proyectoDestino",
        sorter: (a, b) => {
          if (a.proyectoDestino < b.proyectoDestino) {
            return -1;
          }
          if (a.proyectoDestino > b.proyectoDestino) {
            return 1;
          }
          return 0;
        },
        buscar: "proyectoDestino",
        width: "20%",
      },
      {
        title: "Fecha",
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: (a, b) => {
          if (moment(a.createdAt) < moment(b.createdAt)) {
            return -1;
          }
          if (moment(a.createdAt) > moment(b.createdAt)) {
            return 1;
          }
          return 0;
        },
        render: (value) => moment(value).locale("es").format("ll"),
        wdith: "20%",
        ellipsis: true,
        align: "center",
      },
      {
        title: "Responsable",
        dataIndex: "usuario",
        responsive: ["md"],
        key: "usuario",
        sorter: (a, b) => {
          if (a.usuario < b.usuario) {
            return -1;
          }
          if (a.usuario > b.usuario) {
            return 1;
          }
          return 0;
        },
        buscar: "usuario",
        width: "20%",
      },
      {
        title: "Acción",
        dataIndex: "accion",
        render: (value, record, index) => (
          <EyeOutlined key={index.toString()} onClick={() => this.setState({modalVistaPrevia: true, modalVistaPreviaId: record._id})} />
        ),
        width: isMobile ? "20%": "10%",
      }
    ];
    return (
      <>
        <PageHeader
          title="Traspaso Entre Almacenes"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            this.props.permisoAgregarTraspasoEntreAlmacenes &&
              <ButtonItem
                type="primary"
                key="agregarOrdenDeTraspasoEntreAlmacenes"
                onClick={this.agregarOrdenDeTraspasoEntreAlmacenes.bind(this)}
              >
                Agregar Traspaso Entre Almacenes
              </ButtonItem>
          }
        />
        <TableItem
          loading={this.state.loading}
          size="small"
          dataSource={this.state.todasOrdenesDeTraspasoEntreAlmacenes}
          columns={columns}
          pagination={false}
          onRow={(record) => ({
            onDoubleClick: () => this.setState({modalVistaPrevia: true, modalVistaPreviaId: record._id}),
          })}
        />
                  <VistaPrevia
            visible={this.state.modalVistaPrevia}
            id={this.state.modalVistaPreviaId}
            cerrarVistaPrevia={this.cerrarVistaPrevia.bind(this)}
          />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    permisoAgregarTraspasoEntreAlmacenes: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "6478f2830d24b4b09c994352")?.activado,
  };
};

export default connect(mapStateToProps)(TraspasoEntreAlmacenes);
