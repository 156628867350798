import React, { Component } from 'react';
import Input from 'antd/es/input';
import './Styles.css'; // Importa el archivo CSS

class InputItem extends Component {
    render() {
        const { style, sinBorde, width, disabled, ...rest } = this.props;

        // Definir la clase CSS que se aplicará
        const className = `input-item ${sinBorde ? 'sin-borde' : ''} ${disabled ? 'custom-disabled' : ''}`;

        // Aplicar estilos en línea para forzar el color negro en el input deshabilitado
        const modifiedStyle = {
            ...style,
            width: width || style?.width || '100%',
            color: disabled ? '#757575' : style?.color, // Forzar color negro si está deshabilitado
            backgroundColor: disabled ? 'white' : style?.backgroundColor, // Asegurar que el fondo sea blanco
            WebkitTextFillColor: disabled ? '#757575' : style?.WebkitTextFillColor, // Forzar en Webkit
            opacity: disabled ? 1 : style?.opacity, // Evitar efecto desvanecido
        };

        return (
            <Input 
                {...rest} 
                className={className} 
                style={modifiedStyle} 
                bordered={false} 
                disabled={disabled} // Asegurar que realmente se deshabilite
            />
        );
    }
}

export default InputItem;


/*import React, {Component} from "react";
import {Input} from 'antd'

class InputItem extends Component{
    render(){
        const style = {
            ...this.props.style,
            borderBottom: this.props.sinBorde ? null : '1px solid #DADADA',
            borderRadius: 0,
            width: this.props.width ? this.props.width : '100%',
        }
        return(
            <Input {...this.props} style={style} bordered={false}/>
        )
    }
}

export default InputItem*/