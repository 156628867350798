import {
    PageHeader,
    Popconfirm,
    Select,
    Space,
    Tag,
    message,
  } from "antd";
  import axios from "axios";
  import React, { Component } from "react";
  import { connect } from "react-redux";
  import { NotificacionPolizas, SelectItem, TableItem, ButtonItem } from "../../../Components/Items";
  import { CloseCircleOutlined } from "@ant-design/icons";
  import moment from "moment";
import "moment/locale/es";

moment.locale("es");
  
  const { Option } = Select;
  
  class BajasConsignaciones extends Component {
    state = {
      loading: false,
      objetoProyectos: [],
      todasProyectos: [],
      todasBajasConsignaciones: [],
      proyecto: undefined,
    };
  
    async componentDidMount() {
      await this.consultarTodo();
    }
  
    async consultarTodo() {
      this.setState({ loading: true });
      await axios
        .post(
          "consignaciones/selectProyectosConsignaciones",
          { empresaId: this.props.empresaId },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          if (res.data.status === 404) {
          } else {
            this.setState({ todasProyectos: res.data.proyectos });
            this.setState({
              objetoProyectos: res.data.proyectos.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {value.nombre}
                  </Option>
                );
              }),
            });
          }
        });
      await axios
        .post(
          "consignaciones/selectTodasOrdenesBajas",
          { empresaId: this.props.empresaId },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.status === 404) {
          } else {
            this.setState({
                todasBajasConsignaciones: res.data.map((value) => {
                return {
                  ...value,
                  numero: value.bajaSerie+ ' - '+ value.bajaNumero,
                  proyectoNombre: value.proyecto.nombre,
                  accion: { _id: value._id, estado: value.estado },
                };
              }),
            });
          }
          this.setState({ loading: false });
        });
      this.setState({ loading: false });
    }
  
    onChangeProyecto(value) {
      if (value === 0) {
        this.setState({ loading: true, proyecto: undefined });
        axios
          .post(
            "consignaciones/selectTodasOrdenesBajas",
            { empresaId: this.props.empresaId },
            { headers: this.props.headersToken }
          )
          .then((res) => {
            if (res.data.status === 404) {
              this.setState({ todasBajasConsignaciones: [] });
            } else {
              this.setState({
                todasBajasConsignaciones: res.data.map((value) => {
                  return {
                    ...value,
                    numero: value.bajaSerie+ ' - '+ value.bajaNumero,
                    proyectoNombre: value.proyecto.nombre,
                    accion: { _id: value._id, estado: value.estado },
                  };
                }),
              });
            }
            this.setState({ loading: false });
          });
      } else {
        const proyecto = this.state.todasProyectos.find(
          (valueState) => valueState._id.toString() === value.toString()
        );
        this.setState({ loading: true, proyecto: proyecto.nombre });
        axios
          .post(
            "consignaciones/selectTodasOrdenesBajas",
            { empresaId: this.props.empresaId, proyectoId: value },
            { headers: this.props.headersToken }
          )
          .then((res) => {
            if (res.data.status === 404) {
              this.setState({ todasBajasConsignaciones: [] });
            } else {
              this.setState({
                todasBajasConsignaciones: res.data.map((value) => {
                  return {
                    ...value,
                    numero: value.bajaSerie+ ' - '+ value.bajaNumero,
                    proyectoNombre: value.proyecto.nombre,
                    accion: { _id: value._id, estado: value.estado },
                  };
                }),
              });
            }
            this.setState({ loading: false });
          });
      }
    }
    abrirAgregarOrdenBajaConsignacion() {
      this.props.history.push("/panel/agregarBajaConsignacion");
    }
    confirmarBajaConsignacion(value) {
      axios
        .post(
          "consignaciones/confirmarOrdenBajaConsignacion",
          { ordenId: value, empresaId: this.props.empresaId },
          { headers: this.props.headersToken }
        )
        .then((res) => {
            this.setState({ loading: false });
            if (res.data.success === 2) {
              message.success(
                "Se dio de baja productos a consignacion"
              );
              NotificacionPolizas(res.data.polizasId)
            } else if (res.data.success === 1) {
              message.error(res.data.message ? res.data.message : "No hay suficiente inventario");
            } else {
              message.error("Hubo un error y el inventario no se redujo");
            }
          this.consultarTodo();
        });
    }
    cancelarOrdenBajaConsignacion(value) {
      axios
        .post(
          "consignaciones/cancelarOrdenBajaConsignacion",
          { ordenId: value, empresaId: this.props.empresaId },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.success === 2) {
            message.success("Orden cancelada");
          } else {
            message.error("Sucedio un error y no se pudo cancelar");
          }
          this.consultarTodo();
        });
    }
    render() {
      const isMobile = window.innerWidth <= 1000;
      const columns = [
        {
          title: "Numero",
          dataIndex: "numero",
          key: "numero",
          sorter: (a, b) => {
            if (a.numero < b.numero) {
              return -1;
            }
            if (a.numero > b.numero) {
              return 1;
            }
            return 0;
          },
          buscar: "numero",
          width: isMobile ? "33" : "10%",
        },
        {
          title: "Fecha",
          dataIndex: "createdAt",
          responsive: ["md"],
          key: "createdAt",
          sorter: (a, b) => {
            if (moment(a.createdAt) < moment(b.createdAt)) {
              return -1;
            }
            if (moment(a.createdAt) > moment(b.createdAt)) {
              return 1;
            }
            return 0;
          },
          render: (value) => moment(value).locale('es').format('L'),
          width: '10%',
        },
        {
          title: "Referencia",
          dataIndex: "referencia",
          responsive: ["md"],
          key: "referencia",
          sorter: (a, b) => {
            if (a.referencia < b.referencia) {
              return -1;
            }
            if (a.referencia > b.referencia) {
              return 1;
            }
            return 0;
          },
          buscar: "referencia",
          width: "20%",
        },
        {
          title: "Cliente",
          dataIndex: "proyectoNombre",
          key: "proyectoNombre",
          sorter: (a, b) => {
            if (a.proyectoNombre < b.proyectoNombre) {
              return -1;
            }
            if (a.proyectoNombre > b.proyectoNombre) {
              return 1;
            }
            return 0;
          },
          buscar: "proyectoNombre",
          width:  isMobile ? "33" :"25%",
        },
        {
          title: "Estado",
          dataIndex: "estado",
          responsive: ["md"],
          key: "estado",
          render: (value) => {
            if (value === 0) {
              return <Tag color="red">Cancelada</Tag>;
            }
            if (value === 1) {
              return <Tag color="blue">Por confirmar</Tag>;
            }
            if (value === 3) {
              return <Tag color="green">Agregada</Tag>;
            }
          },
          width: "15%",
          ellipsis: true,
        },
        {
          title: "Acción",
          dataIndex: "accion",
          key: "accion",
          render: (value, index) => {
            if (value.estado === 0) return;
            if (value.estado === 3) return;
  
            return (
              <Space direction="horizontal">
                  <Popconfirm
                    title={
                      "Seguro que quieres confirmar esta orden de baja de consignacion"
                    }
                    onConfirm={() => this.confirmarBajaConsignacion(value._id)}
                    okText="Si"
                    cancelText="No"
                  >
                    <ButtonItem type="primary" size="small">
                      Confirmar
                    </ButtonItem>
                  </Popconfirm>
                  <Popconfirm
                    title={
                      "Seguro que quieres cancelar esta orden de baja de consignacion"
                    }
                    onConfirm={() => this.cancelarOrdenBajaConsignacion(value._id)}
                    okText="Si"
                    cancelText="No"
                  >
                    <CloseCircleOutlined style={{ color: "red" }} />
                  </Popconfirm>
              </Space>
            );
          },
        },
      ];
      return (
        <>
          <PageHeader
            title={
              this.state.proyecto
                ? "Ordenes Baja Consignacion: " + this.state.proyecto
                : "Ordenes Baja Consignacion: Todos"
            }
            style={{
              border: "1px solid rgb(235, 237, 240)",
              backgroundColor: "white",
              marginBottom: 10,
            }}
            extra={
              <Space style={{ width: "100%", minWidth: 300 }}>
                <SelectItem
                  placeholder="Proyecto"
                  width={200}
                  onChange={this.onChangeProyecto.bind(this)}
                  defaultValue={0}
                >
                  <Option value={0}>Todos</Option>
                  {this.state.objetoProyectos}
                </SelectItem>
                  <ButtonItem
                    type="primary"
                    key="agregarInventario"
                    onClick={this.abrirAgregarOrdenBajaConsignacion.bind(this)}
                  >
                    {window.innerWidth > 1000 ? "Baja Consignacion": "Baja Consigna" }
                  </ButtonItem>
              </Space>
            }
          />
          <TableItem
            size="small"
            dataSource={this.state.todasBajasConsignaciones}
            columns={columns}
            pagination={false}
            loading={this.state.loading}
          />
        </>
      );
    }
  }
  
  const mapStateToProps = (state) => {
    return {
      headersToken: state.user.headersToken,
      empresaId: state.empresa.id,
    };
  };
  
  export default connect(mapStateToProps)(BajasConsignaciones);
  