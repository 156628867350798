import { message, Space, Select, Popconfirm } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
    ModalItem,
    ButtonItem,
    Form,
    FormItem,
    SelectItem,
    DatePickerItem,
    InputItem
} from "../../../../Components/Items";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment";
import "moment/locale/es";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const { Option } = Select

class DuplicarPresupuestoPU extends Component {
    formRef = React.createRef();
    state = {
        loading: false,
        todasProyectos: [],
        objetoProyectos: null,
    };

    componentDidUpdate(prevProps) {
        if (this.props.visible !== prevProps.visible && this.props.visible)
            this.consultarTodo();
    }

    async consultarTodo() {
        this.setState({ loading: true });
        await axios
            .post(
                "presupuestosPU/datosDuplicarPresupuesto",
                { presupuestoId: this.props.id, empresaId: this.props.empresaId },
                { headers: this.props.headersToken }
            )
            .then((res) => {
                if (res.data.success === 2) {
                    this.setState({
                        todasProyectos: res.data.proyectos,
                        objetoProyectos: res.data.proyectos.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.nombre}
                                </Option>
                            );
                        }),
                    })

                    this.formRef.current.setFieldsValue({
                        proyectoId: res.data.proyectoId,
                        nombre: res.data.nombre,
                        fechaInicio: moment(res.data.fechaInicio),
                        fechaFin: moment(res.data.fechaFin),
                    })

                } else {
                    message.warning('No se cargaron bien los datos')
                }
            });
        this.setState({ loading: false });
    }

    onFinishFailed(e) {
        message.error("Porfavor ingrese bien los datos");
    }
    onFinish(values) {
        this.setState({ loading: true });
        axios
            .post(
                "presupuestosPU/duplicarPresupuesto",
                { ...values, presupuestoId: this.props.id, empresaId: this.props.empresaId },
                { headers: this.props.headersToken }
            )
            .then((res) => {
                if (res.data.success === 2) {
                    message.success("Se ha registrado un nuevo presupuesto");
                    this.formRef.current.resetFields();
                    this.cerrarDuplicarPresupuestoPU()
                } else if (res.data.success === 1) {
                    message.error(res.data.message ? res.data.message : "No se pudo registrar el presupuesto");
                } else {
                    message.error("Hubo un error y el presupuesto no se pudo registrar");
                }
                this.setState({ loading: false });
            });
    }

    cerrarDuplicarPresupuestoPU() {
        this.props.cerrarDuplicarPresupuestoPU();
    }

    render() {
        return (
            <>
                <ModalItem
                    title="Duplicar Presupuesto"
                    visible={this.props.visible}
                    spinning={this.state.loading}
                    onCancel={this.cerrarDuplicarPresupuestoPU.bind(this)}
                    paddingTop
                >
                    <Form
                        ref={this.formRef}
                        name="duplicarPresupuesto"
                        layout
                        width={700}
                        onCancel={this.cerrarDuplicarPresupuestoPU.bind(this)}
                        onFinish={this.onFinish.bind(this)}
                        onFinishFailed={this.onFinishFailed}
                    >

                        <FormItem
                            label="Nombre"
                            name="nombre"
                            required
                            margin
                            pattern
                        >
                            <InputItem
                                placeholder="Nombre"
                            />
                        </FormItem>
                        <FormItem
                            label="Fecha Inicio"
                            name="fechaInicio"
                            required
                            margin
                            initialValue={moment()}
                        >
                            <DatePickerItem allowClear={false} placeholder="Fecha Inicio" />
                        </FormItem>
                        <FormItem
                            label="Fecha Final"
                            name="fechaFinal"
                            required
                            margin
                            initialValue={moment()}
                        >
                            <DatePickerItem allowClear={false} placeholder="Fecha Final" />
                        </FormItem>
                        <FormItem label="Proyecto" name="proyectoId" margin required>
                            <SelectItem disabled={this.state.confirmado} placeholder="Proyecto">
                                {this.state.objetoProyectos}
                            </SelectItem>
                        </FormItem>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "right",
                                marginTop: 20,
                                marginBottom: 10,
                            }}
                        >
                            <Space>

                                <Popconfirm
                                    title={'¿Estás seguro de duplicar el presupuesto?'}
                                    onConfirm={() => this.formRef.current.formRef.current.submit()}
                                    okText="Ok"
                                    cancelText="Cancelar"
                                >
                                    <ButtonItem
                                        type="primary"
                                        style={{ display: "block", margin: "0 0 0 auto" }}
                                    >
                                        Duplicar Presupuesto
                                    </ButtonItem>
                                </Popconfirm>

                                <Popconfirm>


                                </Popconfirm>
                                <ButtonItem
                                    type="primary"
                                    danger
                                    onClick={this.cerrarDuplicarPresupuestoPU.bind(this)}
                                    style={{ display: "block", margin: "0 0 0 auto" }}
                                >
                                    Regresar
                                </ButtonItem>
                            </Space>
                        </div>
                    </Form>
                </ModalItem>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        headersToken: state.user.headersToken,
        empresaId: state.empresa.id,
    };
};

export default connect(mapStateToProps)(DuplicarPresupuestoPU);
