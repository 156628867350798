import { Badge, message, PageHeader, Popconfirm, Space, Tag } from "antd";
import axios from "axios";
import React, { Component } from "react";
import moment from "moment";
import "moment/locale/es";
import { connect } from "react-redux";
import { ButtonItem, SegmentedItem, TableItem, dineroDisabledMask } from "../../../Components/Items";
import { CheckCircleOutlined, EditOutlined, StopOutlined } from "@ant-design/icons";
import { contarVentasPPendientes } from "../../../store/actions/empresa";

moment.locale("es");

class VentasProgramadas extends Component {
    state = {
        todasVentasProgramadas: [],
        loading: false,
        modalCancelacion: false,
        modalCancelacionId: null,
        modalEnvios: false,
        modalEnviosId: null,
        modalGenerarVenta: false,
        modalGenerarVentaId: null,
        valor: 1,
        pendientes: 0,
    };
    componentDidMount() {
        this.consultarVentasProgramadas(this.state.valor);
    }

    consultarVentasProgramadas(valor) {
        this.setState({ loading: true });
        axios
            .post(
                "ventasProgramadas/selectVentasProgramadas",
                { empresaId: this.props.empresaId, valor: valor },
                {
                    headers: this.props.headersToken,
                }
            )
            .then((res) => {
                this.setState({ loading: false });
                if (res.data.status === 404) {
                    this.setState({ todasVentasProgramadas: [] });
                } else {
                    this.setState({
                        todasVentasProgramadas: res.data.map((value) => {
                            let numero = value.ventaProgramadaSerie + ' ' + value.ventaProgramadaNumero
                            return {
                                ...value,
                                fechaCotizacion: value.fechaCotizacion,
                                numero: numero,
                                cliente: (value.cliente.persona ? value.cliente.razonSocial : (value.cliente.nombre + ' ' + value.cliente.apellidoPaterno + ' ' + value.cliente.apellidoMaterno)) + " - " + value.cliente.rfc,
                                accion: {
                                    _id: value._id,
                                    estado: value.estado,
                                    servicios: value.servicios,
                                    createdAt: value.createdAt,
                                    terminada: value.terminada,
                                },
                            };
                        }),
                    });
                }
            });

        this.props.contarVentasPPendientes({ empresaId: this.props.empresaId }).then((response) => {
            if (response.success === 2) {
            } else {
                message.warning(response.err);
            }
        });
    }

    agregarVentaProgramada() {
        this.props.history.push("/panel/agregarVentaProgramada");
    }

    deshabilitarVentaProgramada(value) {
        axios
            .post(
                "ventasProgramadas/deshabilitarVentaProgramada",
                { ventaProgramadaId: value, empresaId: this.props.empresaId },
                { headers: this.props.headersToken }
            )
            .then((res) => {
                if (res.data.success === 2) {
                    message.success("Venta programada deshabilitada");
                } else if (res.data.success === 1) {
                    message.warn(res.data.message);
                } else {
                    message.error("Sucedio un error y no se pudo deshabilitar");
                }
                this.consultarVentasProgramadas(this.state.valor);
            });
    }

    habilitarVentaProgramada(value) {
        axios
            .post(
                "ventasProgramadas/habilitarVentaProgramada",
                { ventaProgramadaId: value, empresaId: this.props.empresaId },
                { headers: this.props.headersToken }
            )
            .then((res) => {
                if (res.data.success === 2) {
                    message.success("Venta programada habilitada");
                } else if (res.data.success === 1) {
                    message.warn(res.data.message);
                } else {
                    message.error("Sucedio un error y no se pudo habilitar");
                }
                this.consultarVentasProgramadas(this.state.valor);
            });
    }

    editarVentaProgramada(id) {
        this.props.history.push("/panel/editarVentaProgramada", { id });
    }

    pendientesVentasProgramadas() {
        this.props.history.push("/panel/pendientesVentasProgramadas");
    }

    render() {
        const columns = [
            {
                title: "Numero",
                dataIndex: "numero",
                key: "numero",
                buscar: 'numero',
                width: "7%",
            },
            {
                title: "Proyecto",
                dataIndex: "proyecto",
                responsive: ['md'],
                key: "proyecto",
                sorter: (a, b) => {
                    if (a.proyecto.nombre < b.proyecto.nombre) {
                        return -1;
                    }
                    if (a.proyecto.nombre > b.proyecto.nombre) {
                        return 1;
                    }
                    return 0;
                },
                render: (value) => value.nombre,
                width: "10%",
                ellipsis: true,
            },
            {
                title: "Cliente",
                dataIndex: "cliente",
                key: "cliente",
                sorter: (a, b) => {
                    if (a.cliente < b.cliente) {
                        return -1;
                    }
                    if (a.cliente > b.cliente) {
                        return 1;
                    }
                    return 0;
                },
                width: "16%",
                buscar: 'cliente',
                ellipsis: true,
            },
            {
                title: "Fecha Ultima",
                dataIndex: "fechaUltimaVenta",
                key: "fechaUltimaVenta",
                sorter: (a, b) => {
                    if (!a.fechaUltimaVenta) return 1;
                    if (!b.fechaUltimaVenta) return -1;
                    if (moment(a.fechaUltimaVenta) < moment(b.fechaUltimaVenta)) {
                        return -1;
                    }
                    if (moment(a.fechaUltimaVenta) > moment(b.fechaUltimaVenta)) {
                        return 1;
                    }
                    return 0;
                },
                render: (value) => value ? moment(value).locale("es").format("L") : "-",
                wdith: "16%",
                ellipsis: true,
                align: "center",
            },
            {
                title: "Fecha Proxima",
                dataIndex: "fechaProximaVenta",
                key: "fechaProximaVenta",
                sorter: (a, b) => {
                    if (moment(a.fechaProximaVenta) < moment(b.fechaProximaVenta)) {
                        return -1;
                    }
                    if (moment(a.fechaProximaVenta) > moment(b.fechaProximaVenta)) {
                        return 1;
                    }
                    return 0;
                },
                render: (value) => moment(value).locale("es").format("L"),
                wdith: "16%",
                ellipsis: true,
                align: "center",
            },
            {
                title: "Subtotal",
                dataIndex: "subtotal",
                key: "subtotal",
                responsive: ['md'],
                sorter: (a, b) => {
                    if (a.subtotal < b.subtotal) {
                        return -1;
                    }
                    if (a.subtotal > b.subtotal) {
                        return 1;
                    }
                    return 0;
                },
                render: (value) => dineroDisabledMask(value),
                align: "right",
                buscar: "subtotal",
                width: "8%",
                ellipsis: true,
            },
            {
                title: "Total",
                dataIndex: "total",
                key: "total",
                sorter: (a, b) => {
                    if (a.total < b.total) {
                        return -1;
                    }
                    if (a.total > b.total) {
                        return 1;
                    }
                    return 0;
                },
                render: (value) => dineroDisabledMask(value),
                buscar: "total",
                width: "8%",
                ellipsis: true,
                align: "right",
            },
            {
                title: "Estado",
                dataIndex: "estado",
                responsive: ['md'],
                key: "estado",
                render: (value, record) => {
                    if (value === 0) {
                        return <Tag color="red">Deshabilitada</Tag>;
                    }
                    if(record.terminada){
                        return <Tag color="green">Terminada</Tag>;
                    }
                    if (value === 1) {
                        return <Tag color="blue">Vigente</Tag>;
                    }
                },
                width: "10%",
                ellipsis: true,
            },
            {
                title: "Acción",
                dataIndex: "accion",
                key: "accion",
                render: (value, index) => {
                    let buttons = [];

                    if (value.estado === 1 || value.estado === 2) {
                        buttons.push(
                            <EditOutlined
                                key={`edit-${index}`}
                                onClick={() => this.editarVentaProgramada(value._id)}
                                style={{ color: "blue" }}
                            />
                        );
                    }

                    if (value.estado === 1) {
                        buttons.push(
                            <Popconfirm
                                title="Seguro que quieres deshabilitar esta venta programada"
                                onConfirm={() => this.deshabilitarVentaProgramada(value._id)}
                                okText="Si"
                                cancelText="No"
                            >
                                <CheckCircleOutlined style={{ color: "green" }} />
                            </Popconfirm>
                        )
                    } else {
                        buttons.push(
                            <Popconfirm
                                title="Seguro que quieres habilitar esta venta programada"
                                onConfirm={() => this.habilitarVentaProgramada(value._id)}
                                okText="Si"
                                cancelText="No"
                            >
                                <StopOutlined style={{ color: "red" }} />
                            </Popconfirm>
                        )
                    }

                    return <Space direction="horizontal">{buttons}</Space>;
                },
                width: "10%",
            },
        ];
        return (
            <>
                <PageHeader
                    title="Ventas Programadas"
                    style={{
                        border: "1px solid rgb(235, 237, 240)",
                        backgroundColor: "white",
                        marginBottom: 10,
                    }}
                    extra={
                        <>
                            {window.innerWidth > 1000 &&
                                <SegmentedItem
                                    options={[{ label: 'Vigente', value: 1 }, { label: 'Todos', value: 2 }, { label: 'Deshabilitadas', value: 3 }]}
                                    onChange={(value) => {
                                        this.consultarVentasProgramadas(value)
                                        this.setState({ valor: value })
                                    }}
                                    value={this.state.valor}
                                />
                            }
                            {this.props.permisoAgregarCotizaciones &&
                                <ButtonItem
                                    type="primary"
                                    key="agregarVentaProgramada"
                                    onClick={this.agregarVentaProgramada.bind(this)}
                                >
                                    Agregar Venta Programada
                                </ButtonItem>
                            }
                            {this.props.permisoAgregarCotizaciones &&
                                <Badge style={{ boxShadow: 'none' }} count={this.props.cuentaVentasPendientes}>
                                    <ButtonItem
                                        key="pendientesVentasProgramadas"
                                        naranja
                                        onClick={this.pendientesVentasProgramadas.bind(this)}
                                    >
                                        Pendientes
                                    </ButtonItem>
                                </Badge>
                            }
                        </>
                    }
                />
                <TableItem
                    onRow={(record, rowIndex) => {
                        return {
                            onDoubleClick: (event) => {
                                if (this.props.permisoVerCotizaciones) {
                                    this.props.history.push("/panel/editarVentaProgramada", { id: record._id });
                                }
                            },
                        };
                    }}
                    loading={this.state.loading}
                    size="small"
                    dataSource={this.state.todasVentasProgramadas}
                    columns={columns}
                    pagination={false}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        headersToken: state.user.headersToken,
        empresaId: state.empresa.id,
        cuentaVentasPendientes: state.empresa.cuentaVentasPendientes,
        permisoAgregarVenta:
            state.user.tipo === 0 ||
            state.user.permisos.find(
                (valueTodos) =>
                    valueTodos.permisoId.toString() === "615ccb6bf30e81b54183cf70"
            )?.activado,
        permisoAgregarCotizaciones:
            state.user.tipo === 0 ||
            state.user.permisos.find(
                (valueTodos) =>
                    valueTodos.permisoId.toString() === "6750922d798d4e0043687242"
            )?.activado,
        permisoCancelarCotizaciones:
            state.user.tipo === 0 ||
            state.user.permisos.find(
                (valueTodos) =>
                    valueTodos.permisoId.toString() === "6750922d798d4e0043687242"
            )?.activado,
        permisoVerCotizaciones:
            state.user.tipo === 0 ||
            state.user.permisos.find(
                (valueTodos) =>
                    valueTodos.permisoId.toString() === "6750925b798d4e0043687244"
            )?.activado,
        permisoGenerarVenta:
            state.user.tipo === 0 ||
            state.user.permisos.find(
                (valueTodos) =>
                    valueTodos.permisoId.toString() === "6750926d798d4e0043687245"
            )?.activado,
    };
};

export default connect(mapStateToProps, {contarVentasPPendientes})(VentasProgramadas);
