import {
  message,
  PageHeader,
  Spin,
  Select,
  Form as FormInitial,
  Divider,
  Row,
  Col,
} from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  FormItem,
  SelectItem,
  InputNumberItem,
  InputItem,
  ButtonItem
} from "../../../../Components/Items";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import ElegirMarbetes from "../../Marbetes/ElegirMarbetes/ElegirMarbetes";

const { Option } = Select;

class BajaConsignacion extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    objetoProyectos: null,
    todasProyectos: null,
    objetoProyectosConsignaciones: null,
    todasProyectosConsignaciones: null,
    todasInsumos: null,
    objetoInsumos: null,
    modalAgregarCuentaContable: false,
    modalElegirMarbetes: false,
    modalElegirMarbetesMarbetes: [],
    modalElegirMarbetesInsumoId: null,
    modalElegirMarbetesProyectoId: null
  };
  componentDidMount() {
    this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "consignaciones/selectProyectosConsignaciones",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasProyectosConsignaciones: res.data.proyectos });
          this.setState({
            objetoProyectosConsignaciones: res.data.proyectos.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "proyectos/selectProyectos",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasProyectos: res.data });
          this.setState({
            objetoProyectos: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "insumos/selectInsumosInventariables",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasInsumos: res.data });
          this.setState({
            objetoInsumos: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.stringBuscar}
                </Option>
              );
            }),
          });
        }
      });
    this.setState({ loading: false });
  }
  atras() {
    this.props.history.goBack();
  }
  onFinishFailed(e) {
    e.errorFields.map((value, index) => {
      if (e.errorFields[index].name[1] || e.errorFields[index].name[1] === 0) {
        message.error(
          "Error: " +
          e.errorFields[index].errors[0] +
          " en la fila " +
          (e.errorFields[index].name[1] + 1)
        );
      } else {
        message.error("Error: Falta un campo");
      }
      return null;
    });
  }
  onFinish(values) {
    if (!values.insumos || values.insumos?.length === 0)
      return message.warning("Agregue insumos para poder agregar la orden");
    this.setState({ loading: true });
    axios
      .post(
        "consignaciones/agregarOrdenBajaConsignacion",
        {
          ...values,
          empresaId: this.props.empresaId,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success(
            "Se ha registrado la orden"
          );
          this.formRef.current.resetFields();
          this.atras();
        } else if (res.data.success === 1) {
          message.error(res.data.message ? res.data.message : "No se puede registrar la orden");
        } else {
          message.error("Hubo un error y la orden no se registro");
        }
      });
  }
  async onChangeInsumo(value, key) {
    let arrayValue = await this.formRef.current.getFieldValue("insumos");
    let proyecto = await this.formRef.current.getFieldValue("proyectoOrigenId");
    const insumoId = arrayValue[key].insumoId;
    const insumo = this.state.todasInsumos.find(
      (valueInsumos) => valueInsumos._id === insumoId
    );
    if (!insumo) return
    await axios
      .post(
        "inventarios/inventarioInsumoProyecto",
        {
          proyectoId: proyecto,
          insumoId: insumoId,
          empresaId: this.props.empresaId,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        arrayValue[key].cantidadProyecto = res.data.cantidad;
      });
    this.formRef.current.setFieldsValue({
      insumos: arrayValue,
    });
  }
  abrirElegirMarbetes(value, marbetes, insumoId, proyectoId) {
    if (proyectoId) {
      this.setState({
        modalElegirMarbetes: true,
        modalElegirMarbetesIndex: value,
        modalElegirMarbetesMarbetes: marbetes || [],
        modalElegirMarbetesInsumoId: insumoId,
        modalElegirMarbetesProyectoId: proyectoId
      })
    } else {
      message.warning('Elegir un proyecto para elegir marbetes')
    }
  }

  cerrarElegirMarbetes(value) {
    const insumos = this.formRef.current.getFieldValue('insumos')
    insumos[this.state.modalElegirMarbetesIndex].marbetes = value
    this.formRef.current.setFieldsValue({
      insumos: insumos
    })
    this.setState({
      modalElegirMarbetes: false,
      modalElegirMarbetesIndex: null,
      modalElegirMarbetesMarbetes: null,
      modalElegirMarbetesInsumoId: null,
      modalElegirMarbetesProyectoId: null
    })
  }
  render() {
    const isMobile = window.innerWidth <= 1000;
    return (
      <>
        <PageHeader
          onBack={this.atras.bind(this)}
          title="Agregar Orden - Baja Consignacion"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
        />
        <div style={{ backgroundColor: "white", padding: 20 }}>
          <Spin spinning={this.state.loading}>
            <Form
              ref={this.formRef}
              name="productosAlCosto"
              layout
              bottom={
                <ButtonItem
                  type="primary"
                  htmlType="submit"
                  style={{ display: "block", margin: "0 0 0 auto" }}
                >
                  Agregar
                </ButtonItem>
              }
              onFinish={this.onFinish.bind(this)}
              onFinishFailed={this.onFinishFailed}
            >
              <Row>
                <Col span={8} xs={24} sm={24}  lg={8} xl={8}>
                  <FormItem shouldUpdate noStyle>
                    {({ getFieldValue }) => {
                      const insumos = getFieldValue('insumos')
                      const disabled = insumos?.length > 0
                      return (
                        <FormItem label="Cliente" name="proyectoOrigenId" margin required>
                          <SelectItem placeholder="Cliente" disabled={disabled}>
                            {this.state.objetoProyectosConsignaciones}
                          </SelectItem>
                        </FormItem>
                      )
                    }}
                  </FormItem>
                </Col>
                <Col span={8} xs={24} sm={24}  lg={8} xl={8}>
                  <FormItem label="Proyecto Destino" name="proyectoDestinoId" margin required>
                    <SelectItem placeholder="Proyecto Destino" >
                      {this.state.objetoProyectos}
                    </SelectItem>
                  </FormItem>
                </Col>
                <Col span={8} xs={24} sm={24}  lg={8} xl={8}>
                  <FormItem
                    name="referencia"
                    label="Referencia"
                    margin
                    pattern
                  >
                    <InputItem placeholder="Referencia" />
                  </FormItem>
                </Col>
              </Row>
              <Divider
                style={{ marginBottom: 10, marginTop: 5 }}
                children="Insumos"
              />
              <FormInitial.List name="insumos">
                {(fields, { add, remove }) => (
                  <>
                  <div style={{overflowX: 'auto'}} >
                    <table className="table" style={{ minWidth: isMobile ? "900px" : "100%" }}>
                      {fields.length > 0 ? (
                        <thead>
                          <tr>
                            <th className="th-border" style={{ width: '40%' }}>Insumo</th>
                            <th className="th-border" style={{ width: '20%' }}>Cantidad Proyecto</th>
                            <th className="th-border" style={{ width: '20%' }}>Cantidad</th>
                            <th className="th-border" style={{ width: '20%' }}>Accion</th>
                          </tr>
                        </thead>
                      ) : null}
                      {fields.map(
                        ({ key, name, fieldKey, ...restField }, arrayKey) => (
                          <tbody>
                            <tr>
                              <td className="td-border">
                                <FormItem
                                  {...restField}
                                  name={[name, "insumoId"]}
                                  fieldKey={[arrayKey, "insumoId"]}
                                  required
                                  noStyle
                                >
                                  <SelectItem
                                    sinBorde
                                    autoFocus
                                    width="100%"
                                    placeholder="Insumo"
                                    onChange={(value) =>
                                      this.onChangeInsumo(
                                        value,
                                        arrayKey
                                      )
                                    }
                                  >
                                    {
                                      this.state
                                        .objetoInsumos
                                    }
                                  </SelectItem>
                                </FormItem>
                              </td>
                              <td className="td-border">
                                <FormItem
                                  {...restField}
                                  name={[name, "cantidadProyecto"]}
                                  fieldKey={[arrayKey, "cantidadProyecto"]}
                                  number
                                  noStyle
                                  required
                                  initialValue={undefined}
                                >
                                  <InputNumberItem sinBorde disabled />
                                </FormItem>
                              </td>
                              <td className="td-border">
                                <FormItem
                                  noStyle
                                  shouldUpdate={(prev, curr) =>
                                    prev.insumos[arrayKey]?.cantidadProyecto !==
                                    curr.insumos[arrayKey]?.cantidadProyecto
                                  }
                                >
                                  {({ getFieldValue }) => {
                                    const insumos = getFieldValue("insumos");
                                    if (!insumos[arrayKey]) return
                                    const cantidadProyecto =
                                      insumos[arrayKey].cantidadProyecto;
                                    let max = cantidadProyecto;
                                    return (
                                      <FormItem
                                        {...restField}
                                        name={[name, "cantidad"]}
                                        fieldKey={[arrayKey, "cantidad"]}
                                        number
                                        noStyle
                                        required
                                        max={max}
                                        type='number'
                                        initialValue={undefined}
                                      >
                                        <InputNumberItem sinBorde />
                                      </FormItem>
                                    );
                                  }}
                                </FormItem>
                              </td>
                              <td className="td-border">
                                <Row>
                                  <Col span={16}>
                                    <FormItem
                                      noStyle
                                      shouldUpdate
                                    >
                                      {({ getFieldValue }) => {
                                        let arrayValue = getFieldValue("insumos");
                                        let proyectoOrigenId = getFieldValue("proyectoOrigenId");
                                        let mostrarMarbetes = false
                                        let insumoId
                                        if (arrayValue[arrayKey]?.insumoId && this.state.todasInsumos) {
                                          const insumo = this.state.todasInsumos.find(
                                            (valueInsumos) => valueInsumos._id === arrayValue[arrayKey].insumoId
                                          );
                                          mostrarMarbetes = insumo?.marbetes
                                          insumoId = insumo._id
                                        }
                                        if (mostrarMarbetes) {
                                          return (
                                            <ButtonItem
                                              type="primary"
                                              size='small'
                                              style={{ width: '80%', marginLeft: '10%' }}
                                              block
                                              onClick={() => {
                                                this.abrirElegirMarbetes(arrayKey, arrayValue[arrayKey].marbetes, insumoId, proyectoOrigenId)
                                              }}
                                            >
                                              Mar.
                                            </ButtonItem>
                                          )
                                        }
                                      }}
                                    </FormItem>
                                  </Col>
                                  <Col span={8}>
                                    <MinusCircleOutlined
                                      onClick={() => remove(name)}
                                      style={{
                                        alignSelf: "center",
                                        justifySelf: "center",
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </td>
                            </tr>
                          </tbody>
                        )
                      )}
                    </table>
                    </div>
                    <FormItem
                      noStyle
                      shouldUpdate={(prev, curr) =>
                        prev.proyectoOrigenId !== curr.proyectoOrigenId
                      }
                    >
                      {({ getFieldValue }) => {
                        const proyecto = getFieldValue("proyectoOrigenId");
                        return (
                          <FormItem noStyle>
                            <ButtonItem
                              style={{ marginTop: 10, marginBottom: 10 }}
                              type="dashed"
                              onClick={
                                proyecto
                                  ? () => add()
                                  : () =>
                                    message.info(
                                      "Agregue el proyecto primero"
                                    )
                              }
                              block
                              icon={<PlusOutlined />}
                            >
                              Agregar Campo
                            </ButtonItem>
                          </FormItem>
                        );
                      }}
                    </FormItem>
                  </>
                )}
              </FormInitial.List>
            </Form>
          </Spin>
        </div>
        <ElegirMarbetes
          visible={this.state.modalElegirMarbetes}
          cerrarElegirMarbetes={this.cerrarElegirMarbetes.bind(this)}
          marbetes={this.state.modalElegirMarbetesMarbetes}
          insumoId={this.state.modalElegirMarbetesInsumoId}
          proyectoId={this.state.modalElegirMarbetesProyectoId}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(BajaConsignacion);
