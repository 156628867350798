import { PageHeader, Space, Tag, message } from "antd";
import axios from "axios";
import React, { Component } from "react";
import {
  TableItem,
  dineroDisabledMask,
  ButtonItem,
  EditableTableDatePickerItem,
  SegmentedItem
} from "../../../Components/Items";
import moment from "moment";
import "moment/locale/es";
import { connect } from "react-redux";

moment.locale("es");

const {
  EditableRow,
  EditableCell
} = EditableTableDatePickerItem

class CuentasPorPagar extends Component {
  state = {
    todasCuentasPorPagar: [],
    todasCuentasPorPagarProveedor: [],
    agruparPorProveedor: false,
    total: 0,
    loading: false,
    valor: 3,
  };
  componentDidMount() {
    this.consultarCuentasPorPagar(this.state.valor);
  }

  async consultarCuentasPorPagar(valor) {
    this.setState({ loading: true });
    await axios
      .post(
        "cuentasporpagar/selectFacturas",
        { empresaId: this.props.empresaId, valor: valor },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.status === 404) {
          this.setState({ todasCuentasPorPagar: [] });
        } else {
          this.setState({
            todasCuentasPorPagar: res.data.facturas,
            total: dineroDisabledMask(res.data.saldoTotalPendienteMXN)
          });
        }
      });
  }
  async consultarCuentasPorPagarProveedor() {
    this.setState({ loading: true });
    await axios
      .post(
        "cuentasporpagar/selectCuentasPProveedor",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.status === 404) {
          this.setState({ todasCuentasPorPagarProveedor: [] });
        } else {
          this.setState({
            todasCuentasPorPagarProveedor: res.data.cuentas,
            total: dineroDisabledMask(res.data.saldoTotalPendienteMXN)
          });
        }
      });
  }
  pagarFactura(id, cheque) {
    this.props.history.push("/panel/pagarFactura", { id, cheque });
  }
  verProveedor(id, nombre, cheque) {
    this.props.history.push("/panel/verFacturasProveedor", {
      id,
      nombre,
      cheque,
    });
  }
  async agrupar() {
    const agrupar = !this.state.agruparPorProveedor;
    if (agrupar) {
      await this.consultarCuentasPorPagarProveedor();
    } else {
      await this.consultarCuentasPorPagar(this.state.valor);
    }
    this.setState({ agruparPorProveedor: agrupar });
  }

  async cambiarFechaPago(row) {
    await axios
      .post(
        "cuentasporpagar/editarFechaPago",
        {
          facturaId: row._id,
          fechaPago: row.fechaPago,
          empresaId: this.props.empresaId,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Fecha Editada");
        } else {
          message.error("Sucedio un error y no se pudo editar la fecha");
        }
        this.consultarCuentasPorPagar(this.state.valor);
      });
  }

  render() {

    const fechaVencimiento = this.props.permisoCambiarFecha ? 
    {
      title: "Fecha de Vencimiento",
      dataIndex: "fechaPago",
      key: "fechaPago",
      sorter: (a, b) => {
        if (moment(a.fechaPago) < moment(b.fechaPago)) {
          return -1;
        }
        if (moment(a.fechaPago) > moment(b.fechaPago)) {
          return 1;
        }
        return 0;
      },
      render: (value) => moment(value).locale("es").format("DD/MM/YYYY"),
      wdith: "13%",
      editable: true,
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: "fechaPago",
        title: "Fecha de Vencimiento",
        handleSave: this.cambiarFechaPago.bind(this),
      }),
    }
     :
     {
      title: "Fecha de Vencimiento",
      dataIndex: "fechaPago",
      key: "fechaPago",
      sorter: (a, b) => {
        if (moment(a.fechaPago) < moment(b.fechaPago)) {
          return -1;
        }
        if (moment(a.fechaPago) > moment(b.fechaPago)) {
          return 1;
        }
        return 0;
      },
      render: (value) => moment(value).locale("es").format("DD/MM/YYYY"),
      wdith: "10%",
    }

    const columns = this.state.agruparPorProveedor
      ? [
          {
            title: "Proveedor",
            dataIndex: "proveedorNombre",
            key: "proveedorNombre",
            responsive: ["md"],
            sorter: (a, b) => {
              if (a.proveedorNombre < b.proveedorNombre) {
                return -1;
              }
              if (a.proveedorNombre > b.proveedorNombre) {
                return 1;
              }
              return 0;
            },
            buscar: "proveedorNombre",
            width: "30%",
          },
          {
            title: "Fecha de Vencimiento",
            dataIndex: "fechaPago",
            key: "fechaPago",
            sorter: (a, b) => {
              if (moment(a.fechaPago) < moment(b.fechaPago)) {
                return -1;
              }
              if (moment(a.fechaPago) > moment(b.fechaPago)) {
                return 1;
              }
              return 0;
            },
            render: (value) => moment(value).locale("es").format("LL"),
            wdith: "15%",
            align: "center",
          },
          {
            title: "Total a Pagar",
            dataIndex: "totalPagar",
            key: "totalPagar",
            responsive: ["md"],
            sorter: (a, b) => {
              if (a.totalPagar < b.totalPagar) {
                return -1;
              }
              if (a.totalPagar > b.totalPagar) {
                return 1;
              }
              return 0;
            },
            render: (value) => dineroDisabledMask(value),
            width: "10%",
            align: "right",
          },
          {
            title: "Total Pagado",
            dataIndex: "totalPagado",
            key: "totalPagado",
            responsive: ["md"],
            sorter: (a, b) => {
              if (a.totalPagado < b.totalPagado) {
                return -1;
              }
              if (a.totalPagado > b.totalPagado) {
                return 1;
              }
              return 0;
            },
            render: (value) => dineroDisabledMask(value),
            width: "10%",
            align: "right",
          },
          {
            title: "Saldo Pendiente",
            dataIndex: "saldoPendiente",
            key: "saldoPendiente",
            sorter: (a, b) => {
              if (a.saldoPendiente < b.saldoPendiente) {
                return -1;
              }
              if (a.saldoPendiente > b.saldoPendiente) {
                return 1;
              }
              return 0;
            },
            render: (value) => dineroDisabledMask(value),
            width: "10%",
            align: "right",
          },
          {
            title: "Acción",
            dataIndex: "accion",
            key: "accion",
            render: (value, record, index) => {
              let buttons = [];
              if(!value.poderPagar) return
              if (this.props.permisoPagar) {
                buttons.push(
                  <ButtonItem
                    key={index.toString()}
                    type="primary"
                    size="small"
                    onClick={() =>
                      this.verProveedor(value._id, value.nombre, value.cheque)
                    }
                  >
                    Pagar
                  </ButtonItem>
                );
              }
              if (moment() > moment(value.fechaVencimiento)) {
                buttons.push(
                  <Tag key={index.toString()} color="red">
                    Vencido {moment().diff(moment(value.fechaVencimiento), 'days')} dias
                  </Tag>
                );
              }
              return <Space>{buttons}</Space>;
            },
            width: "25%",
          },
        ]
      : [
          {
            title: "Codigo",
            dataIndex: "codigoFactura",
            key: "codigoFactura",
            responsive: ["md"],
            sorter: (a, b) => {
              if (a.codigoFactura < b.codigoFactura) {
                return -1;
              }
              if (a.codigoFactura > b.codigoFactura) {
                return 1;
              }
              return 0;
            },
            buscar: "codigoFactura",
            width: "8%",
          },
          {
            title: "Proyecto",
            dataIndex: "proyectoNombre",
            key: "proyectoNombre",
            responsive: ["md"],
            sorter: (a, b) => {
              if (a.proyectoNombre < b.proyectoNombre) {
                return -1;
              }
              if (a.proyectoNombre > b.proyectoNombre) {
                return 1;
              }
              return 0;
            },
            buscar: "proyectoNombre",
            width: "10%",
          },
          {
            title: "Proveedor",
            dataIndex: "proveedorNombre",
            key: "proveedorNombre",
            responsive: ["md"],
            sorter: (a, b) => {
              if (a.proveedorNombre < b.proveedorNombre) {
                return -1;
              }
              if (a.proveedorNombre > b.proveedorNombre) {
                return 1;
              }
              return 0;
            },
            buscar: "proveedorNombre",
            width: "10%",
          },
          fechaVencimiento,
          {
            title: "Fecha Factura",
            dataIndex: "fechaFactura",
            key: "fechaFactura",
            responsive: ["md"],
            sorter: (a, b) => {
              if (moment(a.fechaFactura) < moment(b.fechaFactura)) {
                return -1;
              }
              if (moment(a.fechaFactura) > moment(b.fechaFactura)) {
                return 1;
              }
              return 0;
            },
            render: (value) => moment(value).locale("es").format("DD/MM/YYYY"),
            wdith: "7%",
            align: "center",
          },
          {
            title: "$",
            dataIndex: "moneda",
            key: "moneda",
            responsive: ["md"],
            width: "5%",
          },
          {
            title: "Total a Pagar",
            dataIndex: "totalPagar",
            key: "totalPagar",
            responsive: ["md"],
            sorter: (a, b) => {
              if (a.totalPagar < b.totalPagar) {
                return -1;
              }
              if (a.totalPagar > b.totalPagar) {
                return 1;
              }
              return 0;
            },
            render: (value) => dineroDisabledMask(value),
            width: "8%",
            align: "right",
          },
          {
            title: "Total Pagado",
            dataIndex: "totalPagado",
            key: "totalPagado",
            responsive: ["md"],
            sorter: (a, b) => {
              if (a.totalPagado < b.totalPagado) {
                return -1;
              }
              if (a.totalPagado > b.totalPagado) {
                return 1;
              }
              return 0;
            },
            render: (value) => dineroDisabledMask(value),
            width: "8%",
            align: "right",
          },
          {
            title: "Saldo Pendiente",
            dataIndex: "saldoPendiente",
            key: "saldoPendiente",
            sorter: (a, b) => {
              if (a.saldoPendiente < b.saldoPendiente) {
                return -1;
              }
              if (a.saldoPendiente > b.saldoPendiente) {
                return 1;
              }
              return 0;
            },
            render: (value) => dineroDisabledMask(value),
            width: "8%",
            align: "right",
          },
          {
            title: "Estado",
            dataIndex: "estadoPago",
            key: "estadoPago",
            responsive: ["md"],
            render: (value, record, index) => {
              if (value === 0) {
                return (
                  <Tag key={index.toString()} color="blue">
                    No pagada
                  </Tag>
                );
              }
              if (value === 2) {
                return (
                  <Tag key={index.toString()} color="orange">
                    Pagada parcialmente
                  </Tag>
                );
              }
            },
            width: "8%",
          },
          {
            title: "Acción",
            dataIndex: "accion",
            key: "accion",
            render: (value, record, index) => {
              if(!value.poderPagar) return
              let buttons = [];
              if (
                (value.estadoPago === 0 || value.estadoPago === 2) &&
                this.props.permisoPagar
              ) {
                buttons.push(
                  <ButtonItem
                    key={index.toString()}
                    type="primary"
                    size="small"
                    onClick={() => this.pagarFactura(value._id, value.cheque)}
                  >
                    Pagar
                  </ButtonItem>
                );
              }
              if (moment() > moment(value.fechaVencimiento)) {
                buttons.push(
                  <Tag key={index.toString()} color="red">
                    Vencido {moment().diff(moment(value.fechaVencimiento), 'days')} dias
                  </Tag>
                );
              }
              return <Space>{buttons}</Space>;
            },
            width: "15%",
          },
        ];
    return (
      <>
        <PageHeader
          title={"Cuentas por Pagar: " + this.state.total}
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <>
            {window.innerWidth > 1000 &&
              <SegmentedItem
                options={[{label: 'Todas', value: 3}, {label: 'Pagada Parcialmente', value: 2}, {label: 'Ordenes de Compra', value: 4}, {label: 'Agrupar por Proveedor', value: 5}]}
                onChange={(value) => {
                  if(value === 5){
                    this.consultarCuentasPorPagarProveedor()
                    this.setState({ agruparPorProveedor: true })
                  }else{
                    this.consultarCuentasPorPagar(value)
                    this.setState({ agruparPorProveedor: false })
                  }
                  this.setState({ valor: value })
                }}
                value={this.state.valor}
              />
          }
              {/* {<ButtonItem onClick={this.agrupar.bind(this)} type="primary">
                {this.state.agruparPorProveedor
                  ? "Facturas"
                  : "Agrupar por proveedor"}
              </ButtonItem>} */}
            </>
          }
        />
        <TableItem
          components={{
            body: {
              row: EditableRow,
              cell: EditableCell,
            },
          }}
          loading={this.state.loading}
          size="small"
          dataSource={
            this.state.agruparPorProveedor
              ? this.state.todasCuentasPorPagarProveedor
              : this.state.todasCuentasPorPagar
          }
          columns={columns}
          rowClassName={() => 'editable-row'}
          pagination={false}
          onRow={(record) => ({
            onDoubleClick: () => {
              if (
                (record.accion.estadoPago === 0 || record.accion.estadoPago === 2) &&
                this.props.permisoPagar
              ) {
                this.pagarFactura(record.accion._id, record.accion.cheque);
              }
            },
          })}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    permisoPagar:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "615ce2eff30e81b54183cfae"
      )?.activado,
    permisoCambiarFecha:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6472e6554b2da7c85c1a8d19"
      )?.activado,
  };
};

export default connect(mapStateToProps)(CuentasPorPagar);
