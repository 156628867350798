import { message, notification, PageHeader, Spin } from "antd";
import axios from "axios";
import React, { Component } from "react";
import moment from "moment";
import "moment/locale/es";
import { connect } from "react-redux";
import { ButtonItem, ModalItem, TableItem, dineroDisabledMask } from "../../../../Components/Items";
import { contarVentasPPendientes } from "../../../../store/actions/empresa";

moment.locale("es");

class PendientesVentasProgramadas extends Component {
    state = {
        todasVentasProgramadas: [],
        loading: false,
        objetoProyectos: null,
        todasProyectos: [],
        ventasProgramadasSeleccionadas: [],
        modalConfirmacion: false,
        filtrosProyectos: [],
        cuentaLoading: 0,
        loadingNumber: false,
    };
    componentDidMount() {
        this.consultarDatosPendientes();
    }

    consultarDatosPendientes() {
        this.setState({ loading: true });
        axios
            .post(
                "ventasProgramadas/selectDatosPendientes",
                { empresaId: this.props.empresaId },
                {
                    headers: this.props.headersToken,
                }
            )
            .then((res) => {
                this.setState({ loading: false });
                if (res.data.success === 2) {
                    let filtrosProyectos = res.data.proyectos.map(valueProyecto => {
                        return {
                            text: valueProyecto.nombre,
                            value: valueProyecto._id.toString()
                        }
                    })
                    this.setState({
                        todasVentasProgramadas: res.data.ventasProgramadas.map((value) => {
                            let numero = value.ventaProgramadaSerie + ' ' + value.ventaProgramadaNumero
                            return {
                                ...value,
                                numero: numero,
                                moneda: value.moneda.abreviacion,
                                cliente: (value.cliente.persona ? value.cliente.razonSocial : (value.cliente.nombre + ' ' + value.cliente.apellidoPaterno + ' ' + value.cliente.apellidoMaterno)) + " - " + value.cliente.rfc,
                            };
                        }),
                        filtrosProyectos
                    });
                } else {
                    message.error('Hubo un error y no se cargaron los datos')
                }
            });
    }

    atras() {
        this.props.history.goBack();
    }

    onSelectChange = (ventasProgramadasSeleccionadas) => {

        this.setState({ ventasProgramadasSeleccionadas });
        if (ventasProgramadasSeleccionadas.length === 1) {
            message.success(`Se ha seleccionado ${ventasProgramadasSeleccionadas.length} venta programada`)
        } else if (ventasProgramadasSeleccionadas.length > 1) {
            message.success(`Se han seleccionado ${ventasProgramadasSeleccionadas.length} ventas programadas`)
        }
    }

    abrirConfirmacion() {
        if (this.state.ventasProgramadasSeleccionadas.length > 0) {
            this.setState({ modalConfirmacion: true })
        } else {
            message.warning('Seleccione al menos una venta programada')
        }

    }

    cerrarDatosFacturaGlobal() {
        this.setState({ modalConfirmacion: false })
    }

    onFinishFailed(e) {
        e.errorFields.map((value, index) => {
            if (e.errorFields[index].name[1] || e.errorFields[index].name[1] === 0) {
                message.error(
                    "Error: " +
                    e.errorFields[index].errors[0] +
                    " en la fila " +
                    (e.errorFields[index].name[1] + 1)
                );
            } else {
                message.error("Error: Falta un campo");
            }
            return null;
        });
    }
    async onFinish() {
        await this.setState({ loading: true, cuentaLoading: 0, loadingNumber: true });

        //Consultar en seria las ventas para evitar duplicados de folio

        let resultados = [];

        for (const ventaPId of this.state.ventasProgramadasSeleccionadas) {
            await axios
            .post(
                "ventasProgramadas/generarVentaProgramada",
                {
                    ventasProgramadaId: ventaPId,
                    empresaId: this.props.empresaId,
                },
                { headers: this.props.headersToken }
            )
            .then((res) => {
                if (res.data.success === 2) {
                    resultados.push({ ventaPId, status: 'ok' });
                } else if (res.data.success === 1) {
                    resultados.push({ ventaPId, status: 'error' });
                    const ventaP = this.state.todasVentasProgramadas.find((value) => value._id === ventaPId);
                    message.error(res.data.message ? res.data.message : ("No se pudo registrar la venta " + ventaP.numero));
                    notification.warning({
                        description: res.data.description,
                        message: res.data.message + " - " + ventaP.numero,
                        duration: 0,
                    });
                } else {
                    resultados.push({ ventaPId, status: 'error' });
                    const ventaP = this.state.todasVentasProgramadas.find((value) => value._id === ventaPId);
                    message.error(`Hubo un error y la venta ${ventaP.numero} no se registro`);
                }
                this.setState({ cuentaLoading: this.state.cuentaLoading + 1 });
            })
            .catch((err) => {
                resultados.push({ ventaPId, status: 'error' });
                this.setState({ cuentaLoading: this.state.cuentaLoading + 1 });
            })
        }

        this.setState({ loadingNumber: false, ventasProgramadasSeleccionadas: [] });
        this.consultarDatosPendientes();
        const exitosas = resultados.filter(r => r.status === 'ok');
        message.success(`${exitosas.length} venta(s) fueron generadas correctamente.`);
        this.props.contarVentasPPendientes({ empresaId: this.props.empresaId }).then((response) => {
            if (response.success === 2) {
            } else {
                message.warning(response.err);
            }
        });
    }

    render() {
        const columns = [
            {
                title: "VP",
                dataIndex: "numero",
                key: "numero",
                buscar: 'numero',
                width: "7%",
            },
            {
                title: "Proyecto",
                dataIndex: "proyecto",
                key: "proyecto",
                filters: this.state.filtrosProyectos,
                sorter: (a, b) => {
                    if (a.proyecto.nombre < b.proyecto.nombre) {
                        return -1;
                    }
                    if (a.proyecto.nombre > b.proyecto.nombre) {
                        return 1;
                    }
                    return 0;
                },
                render: (value) => value.nombre,
                onFilter: (value, record) => record.proyectoId.toString().indexOf(value) === 0,
                width: "13%",
                ellipsis: true,
            },
            {
                title: "Cliente",
                dataIndex: "cliente",
                key: "cliente",
                sorter: (a, b) => {
                    if (a.cliente < b.cliente) {
                        return -1;
                    }
                    if (a.cliente > b.cliente) {
                        return 1;
                    }
                    return 0;
                },
                width: "19%",
                buscar: 'cliente',
                ellipsis: true,
            },
            {
                title: "Moneda",
                dataIndex: "moneda",
                key: "moneda",
                sorter: (a, b) => {
                    if (a.moneda < b.moneda) {
                        return -1;
                    }
                    if (a.moneda > b.moneda) {
                        return 1;
                    }
                    return 0;
                },
                width: "9%",
                buscar: 'moneda',
                ellipsis: true,
            },
            {
                title: "Fecha Ultima Venta",
                dataIndex: "fechaUltimaVenta",
                key: "fechaUltimaVenta",
                sorter: (a, b) => {
                    if (!a.fechaUltimaVenta) return 1;
                    if (!b.fechaUltimaVenta) return -1;
                    if (moment(a.fechaUltimaVenta) < moment(b.fechaUltimaVenta)) {
                        return -1;
                    }
                    if (moment(a.fechaUltimaVenta) > moment(b.fechaUltimaVenta)) {
                        return 1;
                    }
                    return 0;
                },
                render: (value) => value ? moment(value).locale("es").format("ll") : "-",
                wdith: "16%",
                ellipsis: true,
                align: "center",
            },
            {
                title: "Fecha Vencida",
                dataIndex: "fechaProximaVenta",
                key: "fechaProximaVenta",
                sorter: (a, b) => {
                    if (moment(a.fechaProximaVenta) < moment(b.fechaProximaVenta)) {
                        return -1;
                    }
                    if (moment(a.fechaProximaVenta) > moment(b.fechaProximaVenta)) {
                        return 1;
                    }
                    return 0;
                },
                render: (value) => moment(value).locale("es").format("ll"),
                wdith: "16%",
                ellipsis: true,
                align: "center",
            },
            {
                title: "Subtotal",
                dataIndex: "subtotal",
                key: "subtotal",
                sorter: (a, b) => {
                    if (a.subtotal < b.subtotal) {
                        return -1;
                    }
                    if (a.subtotal > b.subtotal) {
                        return 1;
                    }
                    return 0;
                },
                render: (value) => dineroDisabledMask(value),
                align: "right",
                buscar: "subtotal",
                width: "10%",
                ellipsis: true,
            },
            {
                title: "Total",
                dataIndex: "total",
                key: "total",
                sorter: (a, b) => {
                    if (a.total < b.total) {
                        return -1;
                    }
                    if (a.total > b.total) {
                        return 1;
                    }
                    return 0;
                },
                render: (value) => dineroDisabledMask(value),
                buscar: "total",
                width: "10%",
                ellipsis: true,
                align: "right",
            },
        ];
        return (
            <Spin spinning={this.state.loading} tip={this.state.loadingNumber ? ("Se han generado " + this.state.cuentaLoading + " ventas") : undefined} size="large">
                <PageHeader
                    title="Generar Ventas"
                    subTitle="Generar ventas a partir de las ventas programadas"
                    onBack={() => this.atras()}
                    style={{
                        border: "1px solid rgb(235, 237, 240)",
                        backgroundColor: "white",
                        marginBottom: 10,
                    }}
                    extra={
                        <ButtonItem
                            type="primary"
                            key="abrirConfirmacion"
                            onClick={this.abrirConfirmacion.bind(this)}
                        >
                            Generar Ventas
                        </ButtonItem>
                    }
                />
                <TableItem
                    onRow={(record, rowIndex) => {
                        return {
                            onDoubleClick: (event) => {
                                this.props.history.push("/panel/editarVentaProgramada", { id: record._id });
                            },
                        };
                    }}
                    size="small"
                    dataSource={this.state.todasVentasProgramadas}
                    rowSelection={{
                        selectedRowKeys: this.state.ventasProgramadasSeleccionadas,
                        onChange: this.onSelectChange.bind(this),
                    }}
                    columns={columns}
                    pagination={false}
                />
                <ModalItem
                    title="Confirmacion"
                    visible={this.state.modalConfirmacion}
                    onCancel={() => this.setState({ modalConfirmacion: false })}
                    onOk={() => {
                        this.setState({ modalConfirmacion: false });
                        this.onFinish(this.state.valuesForm);
                    }}
                    spinning={false}
                    footer
                >
                    <h1 style={{ textAlign: "center" }}>Desea continuar</h1>
                    <p style={{ marginBottom: 10, textAlign: "center" }}>
                        {this.state.ventasProgramadasSeleccionadas.length > 1 ? `Se generaran ${this.state.ventasProgramadasSeleccionadas.length} ventas` : 'Se generara 1 venta'}
                    </p>
                </ModalItem>
            </Spin>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        headersToken: state.user.headersToken,
        empresaId: state.empresa.id,
        certificada: state.empresa.certificada,
        envios: state.empresa.envios,
        permisoAgregarVenta:
            state.user.tipo === 0 ||
            state.user.permisos.find(
                (valueTodos) =>
                    valueTodos.permisoId.toString() === "615ccb6bf30e81b54183cf70"
            )?.activado,
    };
};

export default connect(mapStateToProps, { contarVentasPPendientes })(PendientesVentasProgramadas);
