import { message, PageHeader, Popconfirm, Space } from 'antd'
import React, { Component } from 'react'
import ButtonItem from '../../../Components/ButtonItem'
import TableItem from '../../../Components/TableItem'
import axios from 'axios'
import AgregarTipoDeInsumo from './AgregarTipoDeInsumo/AgregarTipoDeInsumo'
import EditarTipoDeInsumo from './EditarTipoDeInsumo/EditarTipoDeInsumo'
import { CheckCircleOutlined, EditOutlined, StopOutlined } from '@ant-design/icons'
import { connect } from "react-redux";


class TiposDeInsumos extends Component {
    state = {
        todasTiposDeInsumos: [],
        modalAgregarTipoDeInsumo: false,
        modalEditarTipoDeInsumo: false,
        modalEditarTipoDeInsumoId: null,
        loading: false
      }
      componentDidMount() {
        this.consultarTiposDeInsumos();
      }
    
      consultarTiposDeInsumos() {
        this.setState({loading: true})
        axios
        .post(
          "tiposDeInsumos/selectTodasTiposDeInsumos",
          { empresaId: this.props.empresaId },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          this.setState({loading: false})
          if (res.data.status === 404) {
          } else {
            this.setState({
                todasTiposDeInsumos: res.data.map(value => {
                return {...value, accion: { _id: value._id, estado: value.estado }}
              }),
            });
          }
        });
      }
      deshabilitarTipoDeInsumo(value) {
        axios
          .post(
            "tiposDeInsumos/deshabilitarTipoDeInsumo",
            { tipoId: value, empresaId: this.props.empresaId },
            { headers: this.props.headersToken }
          )
          .then((res) => {
            if (res.data.success === 2) {
              message.success("Tipo de Insumo deshabilitado");
            } else {
              message.error("Sucedio un eror y no se pudo deshabilitar");
            }
            this.consultarTiposDeInsumos();
          });
      }
      habilitarTipoDeInsumo(value) {
        axios
          .post(
            "tiposDeInsumos/habilitarTipoDeInsumo",
            { tipoId: value, empresaId: this.props.empresaId },
            { headers: this.props.headersToken }
          )
          .then((res) => {
            if (res.data.success === 2) {
              message.success("Tipo de Insumo habilitado");
            } else {
              message.error("Sucedio un eror y no se pudo habilitar");
            }
            this.consultarTiposDeInsumos();
          });
      }
      abrirAgregarTipoDeInsumo() {
        this.setState({ modalAgregarTipoDeInsumo: true });
      }
      cerrarAgregarTipoDeInsumo() {
        this.setState({ modalAgregarTipoDeInsumo: false });
        this.consultarTiposDeInsumos();
      }
      abrirEditarTipoDeInsumo(id) {
        this.setState({ modalEditarTipoDeInsumo: true, modalEditarTipoDeInsumoId: id });
      }
      cerrarEditarTipoDeInsumo() {
        this.setState({ modalEditarTipoDeInsumo: false });
        this.consultarTiposDeInsumos();
      }

    render() {
        const columns = [
            {
                title: "Numero",
                dataIndex: "numero",
                key: "numero",
                sorter: (a, b) => {
                  if (a.numero < b.numero) {
                    return -1;
                  }
                  if (a.numero > b.numero) {
                    return 1;
                  }
                  return 0;
                },
                buscar: "numero",
              },
            {
              title: "Nombre",
              dataIndex: "nombre",
              key: "nombre",
              sorter: (a, b) => {
                if (a.nombre < b.nombre) {
                  return -1;
                }
                if (a.nombre > b.nombre) {
                  return 1;
                }
                return 0;
              },
              buscar: "nombre",
            },
            {
              title: "Descripcion",
              dataIndex: "descripcion",
              key: "descripcion",
              sorter: (a, b) => {
                if (a.descripcion < b.descripcion) {
                  return -1;
                }
                if (a.descripcion > b.descripcion) {
                  return 1;
                }
                return 0;
              },
              buscar: "descripcion",
            },
            {
              title: "Accion",
              dataIndex: "accion",
              key: "accion",
              render: (value) => (
                <Space size="middle">
                    <EditOutlined
                      onClick={() => this.abrirEditarTipoDeInsumo(value._id)}
                      style={{ color: "blue" }}
                    />
                    {value.estado === 1 ? (
                      <Popconfirm
                        title="Seguro que quieres deshabilitar este tipo de insumo"
                        onConfirm={() => this.deshabilitarTipoDeInsumo(value._id)}
                        okText="Si"
                        cancelText="No"
                      >
                        <CheckCircleOutlined style={{ color: "green" }} />
                      </Popconfirm>
                    ) : (
                      <Popconfirm
                        title="Seguro que quieres habilitar este tipo de insumo"
                        onConfirm={() => this.habilitarTipoDeInsumo(value._id)}
                        okText="Si"
                        cancelText="No"
                      >
                        <StopOutlined style={{ color: "red" }} />
                      </Popconfirm>
                    )}
                </Space>
              ),
            },
          ]
        return (
            <>
                <PageHeader
                    title="Tipos de Insumos"
                    style={{
                        border: "1px solid rgb(235, 237, 240)",
                        backgroundColor: "white",
                        marginBottom: 10,
                    }}
                    extra={
                        <ButtonItem
                            type="primary"
                            key="agregarTipoDeInsumo"
                            onClick={this.abrirAgregarTipoDeInsumo.bind(this)}
                        >
                            Agregar Tipo de Insumo
                        </ButtonItem>
                    }
                />
                <TableItem 
                  loading={this.state.loading} 
                  size='small' 
                  dataSource={this.state.todasTiposDeInsumos} 
                  columns={columns} 
                  onRow={(record) => ({
                    onDoubleClick: () => this.abrirEditarTipoDeInsumo(record._id),
                  })}
                />
                <AgregarTipoDeInsumo
                    visible={this.state.modalAgregarTipoDeInsumo}
                    cerrarAgregarTipoDeInsumo={this.cerrarAgregarTipoDeInsumo.bind(this)}
                />
                <EditarTipoDeInsumo
                    visible={this.state.modalEditarTipoDeInsumo}
                    id={this.state.modalEditarTipoDeInsumoId}
                    cerrarEditarTipoDeInsumo={this.cerrarEditarTipoDeInsumo.bind(this)}
                />
            </>
        )
    }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(TiposDeInsumos);