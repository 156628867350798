import React, { useState } from 'react';

const IconTagStarred = ({ size = 16, stroke = 2, color = "currentColor", hoverColor = "#1890ff", marginRight = 8 }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            stroke={isHovered ? hoverColor : color}
            strokeWidth={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="scale(-1, 1)"
            style={{
                transition: "stroke 0.5s ease",
                marginRight: `${marginRight}px`, // Espacio a la derecha
                marginBottom: "-4px"
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <path d="M7.5 7.5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
            <path d="M3 6v5.172a2 2 0 0 0 .586 1.414l7.71 7.71a2.41 2.41 0 0 0 3.408 0l5.592 -5.592a2.41 2.41 0 0 0 0 -3.408l-7.71 -7.71a2 2 0 0 0 -1.414 -.586h-5.172a3 3 0 0 0 -3 3z" />
            <path d="M12.5 13.847l-1.5 1.153l.532 -1.857l-1.532 -1.143h1.902l.598 -1.8l.598 1.8h1.902l-1.532 1.143l.532 1.857z" />
        </svg>
    );
};

export default IconTagStarred;
