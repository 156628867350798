import moment from "moment";
import "moment/locale/es";

moment.locale("es");

const reportePresupuestoPUPdf = (values) => {
    const empresa = values.empresa
    const logoBiwo = values.logoBiwo;

    var dd = {
        footer: {
            image: 'logoBiwo', alignment: 'center', width: 50
        },
        content: [
            {
                style: 'tableExample',
                table: {
                    widths: ['18%', '52%', '30%'],
                    layout: {
                        defaultBorder: false,
                    },
                    body: [
                        [
                            {
                                border: [false, false, false, false],
                                image: 'logo',
                                fit: [80, 80]
                            },
                            {
                                border: [false, false, false, false],
                                table: {
                                    headerRows: 1,
                                    widths: ['100%'],

                                    body: [
                                        [
                                            {
                                                text: empresa.persona ? empresa.nombre : empresa.nombre + ' ' + empresa.apellidoPaterno + ' ' + empresa.apellidoMaterno,
                                                style: "tableHeader"
                                            }
                                        ],
                                        [
                                            {
                                                text: "R.F.C: " + empresa.rfc,
                                                style: "textoChico",
                                                alignment: "center",
                                            },
                                        ],
                                        [
                                            {
                                                text: empresa.calle + ' ' +
                                                    empresa.numeroCalle + ' ' +
                                                    (empresa.numeroInterior ? empresa.numeroInterior : '')
                                                ,
                                                style: "textoChico",
                                                alignment: "center",
                                            },
                                        ],
                                        [
                                            {
                                                text: empresa.colonia.tipoAsentamiento + ' ' +
                                                    empresa.colonia.nombre + ' C.P. ' +
                                                    empresa.colonia.cp + ' ' +
                                                    empresa.municipio.nombre + ' ' +
                                                    empresa.estado.abreviatura + ' ' +
                                                    empresa.pais.abreviatura + ' '
                                                ,
                                                style: "textoChico",
                                                alignment: "center",
                                            },
                                        ],
                                        [{ text: 'PRESUPUESTO ' + values.nombrePresupuesto, style: 'tableHeader' }],
                                    ]
                                },
                                layout: 'noBorders'
                            },
                            {
                                border: [false, false, false, false],
                                table: {
                                    headerRows: 1,
                                    widths: ['100%'],

                                    body: [
                                        [{ text: 'Fecha Inicial:', style: 'subheader', alignment: 'center' }],
                                        [{ text: moment(values.fechaInicial).locale('es').format('LL'), style: 'textoChico', alignment: 'center' }],
                                        [{ text: 'Fecha Final:', style: 'subheader', alignment: 'center' }],
                                        [{ text: moment(values.fechaFinal).locale('es').format('LL'), style: 'textoChico', alignment: 'center' }],
                                        [{ text: 'Fecha de Impresión:', style: 'subheader', alignment: 'center' }],
                                        [{ text: moment().locale('es').format('LL'), style: 'textoChico', alignment: 'center' }],
                                    ]
                                },
                                layout: 'noBorders'
                            },
                        ]

                    ]
                },
            },

            {
                style: 'tableExample',
                table: {
                    widths: ["10%", "32%", "10%", "12%", "12%", "14%", "10%"],
                    layout: {
                        defaultBorder: true,
                    },
                    body: [
                        [
                            { text: 'Código', style: 'tituloTabla' },
                            { text: 'Concepto', style: 'tituloTabla' },
                            { text: 'Unidad', style: 'tituloTabla' },
                            { text: 'Cantidad', style: 'tituloTabla' },
                            { text: 'P. Unitario', style: 'tituloTabla' },
                            { text: 'Importe', style: 'tituloTabla' },
                            { text: '%', style: 'tituloTabla' },
                        ],
                        ...values.presupuestos
                    ]
                },
                layout: {
                    fillColor: (rowIndex, node, columnIndex) => {
                        return rowIndex % 2 === 0 ? "#e8e8e8" : "#ffffff";
                    },
                    hLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.body.length) ? 1 : 1;
                    },
                    vLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.widths.length) ? 1 : 1;
                    },
                    hLineColor: function (i, node) {
                        return '#adaaaa';
                    },
                    vLineColor: function (i, node) {
                        return '#adaaaa';
                    },
                    hLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.body.length) {
                            return null;
                        }
                        return { dash: { length: 2, space: 1 } };
                    },
                    vLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.widths.length) {
                            return null;
                        }
                        return { dash: { length: 2 } };
                    },
                }
            },

        ],
        images: {
            logo: empresa.logo.base64Completa,
            logoBiwo: logoBiwo.base64Completa,
        },
        styles: {
            header: {
                fontSize: 9,
                bold: true,
            },
            subheader: {
                fontSize: 8,
                bold: true,
            },
            tableExample: {
                margin: [0, 5, 0, 5]
            },
            tablaSinMargen: {
                margin: [0, 0, 0, 0]
            },
            tableHeader: {
                bold: true,
                fontSize: 12,
                color: 'black',
                alignment: 'center'
            },
            textoChico: {
                fontSize: 8
            },
            tituloTabla: {
                fontSize: 10,
                bold: true,
                fillColor: '#cccccc',
                style: 'subheader',
                alignment: 'center',
                border: true
            },
            textoTablaBold: {
                fontSize: 8,
                bold: true,
                italics: false
            },
            textoTabla: {
                fontSize: 8,
                italics: false
            }
        },
        pageSize: 'LETTER',
        pageMargins: [20, 20, 20, 20],
    }
    return dd;
};


export default reportePresupuestoPUPdf;