import axios from "axios";
import React, { Component} from "react";
import { connect } from "react-redux";
import { ButtonItem, InputItem, ModalItem } from "../../../../../Components/Items";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Col, Form, Form as FormInitial, message, Row, Space } from "antd";
import FormItem from "antd/lib/form/FormItem";
import requisicionPdf from "../../../../../Pdf/requisicionPdf";
//import cancelarPagoPdf from "../../../../../Pdf/cancelacionPagoPdf";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class verRequisicionPdf extends Component {
  formRef = React.createRef();

  state = {
    loading: false,
    pdf: null,
    error: null, 
  };

  componentDidUpdate(prevProps) {
    if (this.props.visible && this.props.visible !== prevProps.visible) {
      this.consultarTodo();
    }
  }

  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "requisiciones/verRequisicionesPdf",
        { requisicionId: this.props.requisicionId, empresaId: this.props.empresaId},
        { headers: this.props.headersToken }
      )
      .then((res) => {
        const pdf = pdfMake.createPdf(requisicionPdf(res.data)); 
        this.setState(
          {
            loading: false,
            pdf: pdf,
          },
          () => {
            pdf.open({}, window.frames["verRequisicionPdf"]);
          }
        );
      })
      .catch((err) => {
        console.error(err);
        this.setState({ loading: false });
      });
  }

  cerrarModalVerPdf() {
    if (this.formRef.current) {
      this.formRef.current.resetFields(); // Reinicia los campos del formulario
    }
    this.setState({ pdf: null, error: null }); // Limpia el estado
    this.props.cerrarModalVerPdf(); // Llama al método recibido por props para cerrar el modal
  }

  onFinish = async (values) => {
    this.setState({ loading: true, error: null }); // Establecer estado de carga y limpiar errores previos
    try {
      await axios.post(
        "requisiciones/verRequisicionesMailPdf",
        {
          requisicionId: this.props.requisicionId,
          empresaId: this.props.empresaId,
          ...values
        },
        { headers: this.props.headersToken }
      ).then((res) => {
        this.setState({ loading: false });
        if (res.data.succes === 2) {
          message.success("Requisicion enviada");
          
          this.cerrarModalVerPdf();
        } else if (res.data.succes === 1) {
          message.error("No se pudo enviar la requisicion a ese correo");
        } else {
          message.error("Sucedió un error y no se pudo enviar");
        }
      })
      this.cerrarModalVerPdf();
    } catch (err) {
      this.setState({
        error: "Ocurrió un error al enviar el correo. Intenta nuevamente.",
      });
    } finally {
      this.setState({ loading: false }); // Finaliza el estado de carga
    }
  };
  

  render() {
    return (
      <ModalItem
        title="Ver Requisición"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarModalVerPdf.bind(this)} // Cierra el modal correctamente
        paddingTop
      >
        <Form
          ref={this.formRef}
          name="verRequisicionPdf"
          layout
          width = {700}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed} 
        >
          <FormInitial.List
            name="correosEnvio"
            initialValue={[{ correo: '' }]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ name }, arrayKey) => (
                  <Row key={name}>
                    <Col span={22}>
                      <FormItem
                        name={[name, "correo"]}
                        fieldKey={[arrayKey, "correo"]}
                        label="Correo"
                        required
                        margin
                        email
                        pattern
                      >
                        <InputItem style={{ width: '100%' }} placeholder="Correo" />
                      </FormItem>
                    </Col>
                    <Col span={2}>
                      <div style={{ textAlign: 'center' }}>
                        <MinusCircleOutlined
                          onClick={() => remove(name)}
                        />
                      </div>
                    </Col>
                  </Row>
                ))}
                {fields.length < 5 ? (
                  <div style={{ textAlign: 'center', marginTop: '5px' }}>
                    <ButtonItem
                      type="primary"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    >
                      Agregar
                    </ButtonItem>
                  </div>
                ) : null}
              </>
            )}
          </FormInitial.List>
          <div style={{ display: "flex", justifyContent: "right", marginTop: 20, marginBottom: 10 }}>
            <Space>
              <ButtonItem
                type="primary"
                htmlType="submit"
                style={{ display: "block", margin: "0 0 0 auto" }}
              >
                Mandar Correo
              </ButtonItem>
              <ButtonItem
                type="primary"
                danger
                onClick={this.cerrarModalVerPdf.bind(this)} 
                style={{ display: "block", margin: "0 0 0 auto" }}
              >
                Cancelar
              </ButtonItem>
            </Space>
          </div>
        </Form>
        {this.state.error ? ( // Muestra el error si ocurre
          <div style={{ color: "red", textAlign: "center" }}>
            {this.state.error}
          </div>
        ) : (
          <iframe
            title="Cancelar Venta PDF"
            style={{ width: "100%", height: "100vh" }}
            id="verRequisicionPdf"
            name="verRequisicionPdf"
          />
        )}
      </ModalItem>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(verRequisicionPdf);