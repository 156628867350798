import {
    message,
    Tabs,
    Select,
    PageHeader,
    Spin,
    Row,
    Col,
    Space,
    TreeSelect,
    Popconfirm,
    Tooltip,
    Form as FormInitial,
    Dropdown,
    Menu
} from "antd";
import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import {
    Form,
    FormItem,
    ButtonItem,
    InputItem,
    DatePickerItem,
    SelectItem,
    InputNumberItem,
    round10Decimals,
    round2Decimals,
    dineroDisabledMask,
    TableItem,
    round6Decimals,
    SwitchItem,
    round4Decimals,
} from "../../../../Components/Items";
import moment from "moment";
import { CalculatorOutlined, CheckOutlined, DownOutlined, ExclamationOutlined, FileExcelOutlined, FilePdfOutlined, MinusCircleOutlined, PlusCircleOutlined, PlusOutlined, } from "@ant-design/icons";
import { Gantt,/*Task, EventOption, StylingOption, ViewMode, DisplayOption*/ } from 'gantt-task-react';
import * as XLSXStyle from "xlsx-js-style";
import "gantt-task-react/dist/index.css";
import "moment/locale/es";
import numeroALetra from "../../../../Components/numeroALetra";
import reporteMatricesPdf from "../../../../Pdf/reporteMatricesPdf";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import reportePresupuestoPUPdf from "../../../../Pdf/reportePresupuestoPU";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

moment.locale('es');

const { TabPane } = Tabs;
const { Option } = Select;

class PresupuestoPU extends Component {
    formRef = React.createRef();
    state = {
        loading: false,
        buscarInfoCode: false,
        archivosPrincipal: [],
        archivosTecnica: [],
        archivosFotos: [],
        todasMatrices: null,
        objetoMatrices: null,
        loadingExplosion: false,
        loadingReportes: false,
        loadingTabla: false,
        todasInsumos: null,
        objetoInsumos: null,
        todasProyectos: null,
        objetoProyectos: null,
        insumosExplosionados: [],
        costoTotalIE: 0,
        partidasArbol: [],
        partidas: [],
        keyEditing: '',
        expandedRowKeys: [],
        confirmado: false,
        id: this.props?.location?.state?.id,
        valueExplosion: [],
        tasksDiagrama: [
            {
                id: '1',
                name: 'Cargando',
                start: moment().toDate(),
                end: moment().add(1, 'd').toDate(),
                progress: 50,
                type: 'project',
                hideChildren: false,
            },
        ],
        objectExplosiones: null,
        tipoInsumos: []
    };
    atras() {
        this.props.history.goBack();
    }
    componentDidMount() {
        this.consultarTodo();
    }
    async consultarTodo() {
        this.setState({ loading: true });
        await axios
            .post(
                "presupuestosPU/selectDatosParaPresupuestoPU",
                { empresaId: this.props.empresaId },
                {
                    headers: this.props.headersToken,
                }
            )
            .then((res) => {
                if (res.data.status === 404) {
                } else {
                    this.setState({
                        todasMatrices: res.data.matrices,
                        todasInsumos: res.data.insumos,
                        todasProyectos: res.data.proyectos,
                        tipoInsumos: res.data.tipoInsumos,
                        objetoMatrices: res.data.matrices.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.stringBuscar + ' ' + dineroDisabledMask(value.costo)}
                                </Option>
                            );
                        }),
                        objetoProyectos: res.data.proyectos.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.nombre}
                                </Option>
                            );
                        }),
                    })
                }
            });
        if (this.state.id) {
            await axios
                .post(
                    "presupuestosPU/datosPresupuesto",
                    { empresaId: this.props.empresaId, presupuestoId: this.state.id },
                    { headers: this.props.headersToken }
                )
                .then((res) => {
                    if (res.data.success === 2) {
                        this.formRef.current.setFieldsValue({
                            ...res.data.presupuesto,
                            fechaInicio: moment(res.data.presupuesto.fechaInicio),
                            fechaFinal: moment(res.data.presupuesto.fechaFinal)
                        });
                        const nuevasPartidas = res.data.presupuesto.confirmado ? res.data.presupuesto.partidas : this.calcularPresupuesto(res.data.presupuesto.partidas)
                        const partidasArbol = res.data.presupuesto.partidas ? res.data.presupuesto.partidas.map((valueP1, indexP1) => {
                            const nuevasPartidas2 = valueP1.children ? valueP1.children.map((valueP2, indexP2) => {
                                const nuevasPartidas3 = valueP2.children ? valueP2.children.map((valueP3, indexP3) => {
                                    const nuevasPartidas4 = valueP3.children ? valueP3.children.map((valueP4, indexP4) => {
                                        const nuevasPartidas5 = valueP4.children ? valueP4.children.map((valueP5, indexP5) => {

                                            const nuevasPartidas6 = valueP5.children ? valueP5.children.map((valueP6, indexP6) => {

                                                let title6 = ''

                                                if (valueP6.tipo === 0) {
                                                    title6 = 'P : ' + valueP6.nombre + ' ' + dineroDisabledMask(valueP6.costoTotal)
                                                } else if (valueP6.tipo === 1 && valueP6.nombre) {
                                                    const matriz6 = this.state.todasMatrices.find(valueM => valueM._id.toString() === valueP6?.nombre.toString())
                                                    title6 = 'M : ' + matriz6.descripcion + ' ' + dineroDisabledMask(valueP6.costoTotal)
                                                } else {
                                                    title6 = valueP6.nombre + ' ' + dineroDisabledMask(valueP6.costoTotal)
                                                }


                                                return {
                                                    ...valueP6,
                                                    title: title6
                                                }

                                            }) : []

                                            let title5 = ''

                                            if (valueP5.tipo === 0) {
                                                title5 = 'P : ' + valueP5.nombre + ' ' + dineroDisabledMask(valueP5.costoTotal)
                                            } else if (valueP5.tipo === 1 && valueP5.nombre) {
                                                const matriz5 = this.state.todasMatrices.find(valueM => valueM._id.toString() === valueP5?.nombre.toString())
                                                title5 = 'M : ' + matriz5.descripcion + ' ' + dineroDisabledMask(valueP5.costoTotal)
                                            } else {
                                                title5 = valueP5.nombre + ' ' + dineroDisabledMask(valueP5.costoTotal)
                                            }


                                            return {
                                                ...valueP5,
                                                children: nuevasPartidas6,
                                                title: title5
                                            }
                                        }) : []

                                        let title4 = ''

                                        if (valueP4.tipo === 0) {
                                            title4 = 'P : ' + valueP4.nombre + ' ' + dineroDisabledMask(valueP4.costoTotal)
                                        } else if (valueP4.tipo === 1 && valueP4.nombre) {
                                            const matriz4 = this.state.todasMatrices.find(valueM => valueM._id.toString() === valueP4?.nombre.toString())
                                            title4 = 'M : ' + matriz4.descripcion + ' ' + dineroDisabledMask(valueP4.costoTotal)
                                        } else {
                                            title4 = valueP4.nombre + ' ' + dineroDisabledMask(valueP4.costoTotal)
                                        }


                                        return {
                                            ...valueP4,
                                            children: nuevasPartidas5,
                                            title: title4
                                        }

                                    }) : []


                                    let title3 = ''

                                    if (valueP3.tipo === 0) {
                                        title3 = 'P : ' + valueP3.nombre + ' ' + dineroDisabledMask(valueP3.costoTotal)
                                    } else if (valueP3.tipo === 1 && valueP3.nombre) {
                                        const matriz3 = this.state.todasMatrices.find(valueM => valueM._id.toString() === valueP3?.nombre.toString())
                                        title3 = 'M : ' + matriz3.descripcion + ' ' + dineroDisabledMask(valueP3.costoTotal)
                                    } else {
                                        title3 = valueP3.nombre + ' ' + dineroDisabledMask(valueP3.costoTotal)
                                    }


                                    return {
                                        ...valueP3,
                                        children: nuevasPartidas4,
                                        title: title3
                                    }

                                }) : []

                                let title2 = ''

                                if (valueP2.tipo === 0) {
                                    title2 = 'P : ' + valueP2.nombre + ' ' + dineroDisabledMask(valueP2.costoTotal)
                                } else if (valueP2.tipo === 1 && valueP2.nombre) {
                                    const matriz2 = this.state.todasMatrices.find(valueM => valueM._id.toString() === valueP2?.nombre.toString())
                                    title2 = 'M : ' + matriz2.descripcion + ' ' + dineroDisabledMask(valueP2.costoTotal)
                                } else {
                                    title2 = valueP2.nombre + ' ' + dineroDisabledMask(valueP2.costoTotal)
                                }


                                return {
                                    ...valueP2,
                                    children: nuevasPartidas3,
                                    title: title2
                                }

                            }) : []

                            let title1 = ''

                            if (valueP1.tipo === 0) {
                                title1 = 'P : ' + valueP1.nombre + ' ' + dineroDisabledMask(valueP1.costoTotal)
                            } else if (valueP1.tipo === 1 && valueP1.nombre) {
                                const matriz1 = this.state.todasMatrices.find(valueM => valueM._id.toString() === valueP1?.nombre.toString())
                                title1 = 'M : ' + matriz1.descripcion + ' ' + dineroDisabledMask(valueP1.costoTotal)
                            } else {
                                title1 = valueP1.nombre + ' ' + dineroDisabledMask(valueP1.costoTotal)
                            }


                            return {
                                ...valueP1,
                                children: nuevasPartidas2,
                                title: title1
                            }

                        }) : []


                        this.setState({
                            partidas: nuevasPartidas,
                            confirmado: res.data.presupuesto.confirmado,
                            objectExplosiones: res.data.presupuesto.explosiones && res.data.presupuesto.explosiones?.length > 0 ? res.data.presupuesto.explosiones.map((valueEI, indexEI) => {

                                let nuevosInsumosGuardados = valueEI.insumosGuardados.map(valueIG => {
                                    const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === valueIG.insumoId.toString());
                                    return {
                                        ...valueIG,
                                        familiaNombre: insumoBuscar?.familia?.nombre,
                                        familiaId: insumoBuscar?.familiaId,
                                    }
                                }).sort((a, b) => {
                                    // Primero ordenamos por familia
                                    let familiaCompare

                                    if(a.familiaNombre && b.familiaNombre) {
                                        familiaCompare = a.familiaNombre.localeCompare(b.familiaNombre)
                                    } else if(a.familiaNombre) {
                                        familiaCompare = -1
                                    } else if(b.familiaNombre) {
                                        familiaCompare = 1
                                    } else {
                                        familiaCompare = 0
                                    }
                                    if (familiaCompare !== 0) return familiaCompare; // Si son diferentes, usamos este orden

                                    // Si las familias son iguales, ordenamos por nombre
                                    return a.descripcion.localeCompare(b.descripcion);
                                });

                                let familiaAnteriorId
                                let familiaAnteriorNombre

                                return (
                                    <TabPane tab={(
                                        <span>
                                            {valueEI.autorizado ?
                                                <CheckOutlined />
                                                :
                                                <ExclamationOutlined />
                                            }
                                            {'Explosion ' + (indexEI + 1)}
                                        </span>
                                    )} tabKey={valueEI._id} key={valueEI._id}>
                                        <Row>
                                            <Col span={24}>
                                                <TreeSelect
                                                    showSearch
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    // value={value}
                                                    dropdownStyle={{
                                                        maxHeight: 400,
                                                        overflow: 'auto',
                                                    }}
                                                    multiple
                                                    placeholder="Seleccionar"
                                                    allowClear
                                                    treeDefaultExpandAll
                                                    disabled
                                                    value={valueEI.valueExplosion}
                                                    treeData={partidasArbol}
                                                />
                                            </Col>
                                        </Row>
                                        <table className="table">
                                            {valueEI.insumosGuardados.length > 0 ?
                                                <thead>
                                                    <tr>
                                                        <th className="th-border" style={{ width: "30%" }}>
                                                            Insumo
                                                        </th>
                                                        <th className="th-border" style={{ width: "20%" }}>
                                                            Unidad
                                                        </th>
                                                        <th className="th-border" style={{ width: "10%" }}>
                                                            Explosion
                                                        </th>
                                                        <th className="th-border" style={{ width: "10%" }}>
                                                            Pedido
                                                        </th>
                                                        <th className="th-border" style={{ width: "15%" }}>
                                                            Costo
                                                        </th>
                                                        <th className="th-border" style={{ width: "15%" }}>
                                                            Costo Final
                                                        </th>
                                                    </tr>
                                                </thead>
                                                : null}
                                            {nuevosInsumosGuardados.map((valueInsumo, index) => {

                                                const mostrarFamilia = valueInsumo.familiaId !== familiaAnteriorId || index === 0;
                                                familiaAnteriorId = valueInsumo.familiaId;
                                                familiaAnteriorNombre = valueInsumo.familiaNombre;

                                                return (
                                                    <tbody key={index}>
                                                        {mostrarFamilia ?
                                                            <tr>
                                                                <td colSpan="6" style={{ textAlign: 'left', fontWeight: 'bold', fontSize: 20 }}>
                                                                    {familiaAnteriorNombre ? familiaAnteriorNombre : 'Sin Familia'}
                                                                </td>
                                                            </tr>
                                                            : null}
                                                        <tr>
                                                            <td className="td-border" style={{ textAlign: 'left', wordBreak: 'keep-all', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                                                <p style={{ margin: 5 }}>{valueInsumo.descripcion}</p>
                                                            </td>
                                                            <td className="td-border" style={{ textAlign: 'center' }}>
                                                                <p style={{ margin: 5 }}>{valueInsumo.unidadNombre}</p>
                                                            </td>
                                                            <td className="td-border" style={{ textAlign: 'right' }}>
                                                                <p style={{ margin: 5 }}>{round4Decimals(valueInsumo.cantidad)}</p>
                                                            </td>
                                                            <td className="td-border" style={{ textAlign: 'right' }}>
                                                                <p style={{ margin: 5 }}>{round4Decimals(valueInsumo.cantidadExplosion)}</p>
                                                            </td>
                                                            <td className="td-border" style={{ textAlign: 'right' }}>
                                                                <p style={{ margin: 5 }}>{dineroDisabledMask(valueInsumo.costo || 0)}</p>
                                                            </td>
                                                            <td className="td-border" style={{ textAlign: 'right' }}>
                                                                <p style={{ margin: 5 }}>{dineroDisabledMask(valueInsumo.costoFinal || 0)}</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                )
                                            })}

                                            <tbody key='T'>
                                                <tr>
                                                    <td className="td-border" style={{ textAlign: 'left' }}>
                                                        <p style={{ margin: 5 }}>Total:</p>
                                                    </td>
                                                    <td className="td-border" style={{ textAlign: 'center' }}>
                                                        <p style={{ margin: 5 }}></p>
                                                    </td>
                                                    <td className="td-border" style={{ textAlign: 'center' }}>
                                                        <p style={{ margin: 5 }}></p>
                                                    </td>
                                                    <td className="td-border" style={{ textAlign: 'center' }}>
                                                        <p style={{ margin: 5 }}></p>
                                                    </td>
                                                    <td className="td-border" style={{ textAlign: 'right' }}>
                                                        <p style={{ margin: 5 }}>{dineroDisabledMask(valueEI.costoTotal || 0)}</p>
                                                    </td>
                                                    <td className="td-border" style={{ textAlign: 'right' }}>
                                                        <p style={{ margin: 5 }}>{dineroDisabledMask(valueEI.costoFinalTotal || 0)}</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {!valueEI.autorizado ?
                                            <Popconfirm
                                                title="Quieres autorizar esta explosion y requierir los insumos?"
                                                onConfirm={() => this.autorizarExplosion(valueEI._id)}
                                                okText="Si"
                                                cancelText="No"
                                            >
                                                <ButtonItem
                                                    blanco
                                                    style={{ display: "block", margin: "10px 0px 10px auto" }}
                                                >
                                                    Autorizar
                                                </ButtonItem>
                                            </Popconfirm>
                                            : null}
                                    </TabPane>
                                )
                            }) : null
                        })
                    } else {
                        message.error("Error no se encontro informacion del presupuesto");
                    }
                });
        }
        this.setState({ loading: false });
    }

    async consultarPPresupuesto() {
        await axios
            .post(
                "presupuestosPU/selectDatosParaPresupuestoPU",
                { empresaId: this.props.empresaId },
                {
                    headers: this.props.headersToken,
                }
            )
            .then((res) => {
                if (res.data.status === 404) {
                } else {
                    this.setState({
                        todasMatrices: res.data.matrices,
                        todasInsumos: res.data.insumos,
                        todasProyectos: res.data.proyectos,
                        tipoInsumos: res.data.tipoInsumos,
                        objetoMatrices: res.data.matrices.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.stringBuscar + ' ' + dineroDisabledMask(value.costo)}
                                </Option>
                            );
                        }),
                        objetoProyectos: res.data.proyectos.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.nombre}
                                </Option>
                            );
                        }),
                    })
                }
            });
    }

    autorizarExplosion(id) {
        axios
            .post("presupuestosPU/autorizarExplosion", { presupuestoId: this.state.id, explosionId: id, empresaId: this.props.empresaId }, {
                headers: {
                    ...this.props.headersToken,
                },
            })
            .then((res) => {
                this.setState({ loading: false });
                if (res.data.success === 2) {
                    message.success("Se ha autorizado la explosion");
                    this.consultarTodo()
                } else if (res.data.success === 1) {
                    message.error("No se puede autorizar la explosion");
                } else {
                    message.error("Hubo un error y el la explosion no se autorizo");
                }
            });
    }

    onFinishFailed(e) {
        message.error("Porfavor ingrese bien los datos");
    }

    onFinish(values) {
        const partidas = [...this.state.partidas]
        if (partidas) {
            for (const [indexP1, valueP1] of partidas.entries()) {
                if (valueP1.children) {
                    for (const [indexP2, valueP2] of valueP1.children.entries()) {
                        if (valueP2.children) {
                            for (const [indexP3, valueP3] of valueP2.children.entries()) {
                                if (valueP3.children) {
                                    for (const [indexP4, valueP4] of valueP3.children.entries()) {
                                        if (valueP4.children) {
                                            for (const [indexP5, valueP5] of valueP4.children.entries()) {
                                                if (valueP5.children) {
                                                    for (const [indexP6, valueP6] of valueP5.children.entries()) {
                                                        if (!valueP6.nombre || !valueP6.cantidad || valueP6.cantidad === 0 || (valueP6.tipo !== 0 && valueP6.tipo !== 1 && !valueP6.insumoMatriz)) {
                                                            return message.warning('Falta un dato en la partida: ' + (indexP1 + 1) + '-' + (indexP2 + 1) + '-' + (indexP3 + 1) + '-' + (indexP4 + 1) + '-' + (indexP5 + 1) + '-' + (indexP6 + 1))
                                                        }
                                                    }
                                                }
                                                if (!valueP5.nombre || !valueP5.cantidad || valueP5.cantidad === 0 || (valueP5.tipo !== 0 && valueP5.tipo !== 1 && !valueP5.insumoMatriz)) {
                                                    return message.warning('Falta un dato en la partida: ' + (indexP1 + 1) + '-' + (indexP2 + 1) + '-' + (indexP3 + 1) + '-' + (indexP4 + 1) + '-' + (indexP5 + 1))
                                                }
                                            }
                                        }
                                        if (!valueP4.nombre || !valueP4.cantidad || valueP4.cantidad === 0 || (valueP4.tipo !== 0 && valueP4.tipo !== 1 && !valueP4.insumoMatriz)) {
                                            return message.warning('Falta un dato en la partida: ' + (indexP1 + 1) + '-' + (indexP2 + 1) + '-' + (indexP3 + 1) + '-' + (indexP4 + 1))
                                        }
                                    }
                                }
                                if (!valueP3.nombre || !valueP3.cantidad || valueP3.cantidad === 0 || (valueP3.tipo !== 0 && valueP3.tipo !== 1 && !valueP3.insumoMatriz)) {
                                    return message.warning('Falta un dato en la partida: ' + (indexP1 + 1) + '-' + (indexP2 + 1) + '-' + (indexP3 + 1))
                                }
                            }
                        }
                        if (!valueP2.nombre || !valueP2.cantidad || valueP2.cantidad === 0 || (valueP2.tipo !== 0 && valueP2.tipo !== 1 && !valueP2.insumoMatriz)) {
                            return message.warning('Falta un dato en la partida: ' + (indexP1 + 1) + '-' + (indexP2 + 1))
                        }
                    }
                }
                if (!valueP1.nombre || !valueP1.cantidad || valueP1.cantidad === 0 || (valueP1.tipo !== 0 && valueP1.tipo !== 1 && !valueP1.insumoMatriz)) {
                    return message.warning('Falta un dato en la partida: ' + (indexP1 + 1))
                }
            }
        }
        this.setState({ loading: true });
        if (this.state.id) {
            axios
                .post("presupuestosPU/editarPresupuesto", { ...values, partidas: this.state.partidas, presupuestoId: this.state.id, empresaId: this.props.empresaId }, {
                    headers: {
                        ...this.props.headersToken,
                    },
                })
                .then((res) => {
                    this.setState({ loading: false, id: res.data.presupuestoId });
                    if (res.data.success === 2) {
                        message.success("Se ha editado un presupuesto");
                        this.consultarTodo()
                    } else if (res.data.success === 1) {
                        message.error("No se puede editar el presupuesto");
                    } else {
                        message.error("Hubo un error y el presupuesto no se edito");
                    }
                });
        } else {
            axios
                .post("presupuestosPU/agregarPresupuesto", { ...values, partidas: this.state.partidas, presupuestoId: this.state.id, empresaId: this.props.empresaId }, {
                    headers: {
                        ...this.props.headersToken,
                    },
                })
                .then((res) => {
                    this.setState({ loading: false, id: res.data.presupuestoId });
                    if (res.data.success === 2) {
                        message.success("Se ha agregado un presupuesto");
                        this.consultarTodo()
                    } else if (res.data.success === 1) {
                        message.error("No se puede agregar el presupuesto");
                    } else {
                        message.error("Hubo un error y el presupuesto no se agrego");
                    }
                });
        }
    }

    async explosionInsumos() {
        await this.setState({ loadingExplosion: true })
        const partidas = [...this.state.partidas]

        let insumosExplosionados = []

        let costoTotalIE = 0

        const processInsumo = (insumo, cantidadTotal) => {
            const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === insumo.insumoId.toString());
            //Matriz sobre matriz
            if (insumo.children && insumo.children?.length > 0) {
                insumo.children.forEach(valueIU => {
                    processInsumo(valueIU, cantidadTotal * (valueIU.calculo ? (valueIU.cantidad / 100) : valueIU.cantidad))
                })
            } else {
                const indexIE = insumosExplosionados.findIndex(valueTIE => valueTIE.insumoId.toString() === insumo.insumoId.toString());
                const costo = insumo.calculo ? round2Decimals(insumo.costo * cantidadTotal) : (insumoBuscar.costo ? insumoBuscar.costo : insumo.costo);
                const costoTotal = round2Decimals((insumoBuscar.costo ? insumoBuscar.costo : insumo.costo) * cantidadTotal);
                const cantidadPedida = round10Decimals(insumo.cantidadPedida || 0);

                if (indexIE === -1) {
                    insumosExplosionados.push({
                        insumoId: insumo.insumoId,
                        nombre: insumoBuscar.descripcion,
                        descripcionInsumo: insumoBuscar.descripcion,
                        unidad: insumoBuscar.unidad.nombre,
                        familiaId: insumoBuscar.familiaId,
                        familiaNombre: insumoBuscar?.familia?.nombre,
                        costo,
                        catidadMinCompra: insumoBuscar?.catidadMinCompra,
                        costoTotal,
                        cantidadPedida,
                        cantidad: insumo.calculo ? 1 : cantidadTotal
                    });
                } else {
                    insumosExplosionados[indexIE].cantidad = insumo.calculo ? 1 : round10Decimals(insumosExplosionados[indexIE].cantidad + cantidadTotal);
                    insumosExplosionados[indexIE].costo = insumo.calculo ? round2Decimals(insumosExplosionados[indexIE].costo + costo) : insumosExplosionados[indexIE].costo;
                    insumosExplosionados[indexIE].costoTotal = round2Decimals(insumosExplosionados[indexIE].costoTotal + costoTotal);
                    insumosExplosionados[indexIE].cantidadPedida = insumo.calculo ? Math.max(insumosExplosionados[indexIE].cantidadPedida, cantidadPedida) : round10Decimals(insumosExplosionados[indexIE].cantidadPedida + cantidadPedida);
                }
                costoTotalIE += costoTotal;
            }

        };

        const traversePartidas = (partidas, keys = [], cantidadAcumulada = 1) => {
            for (const [index, partida] of partidas.entries()) {
                if (partida.children) {
                    traversePartidas(partida.children, [...keys, index], cantidadAcumulada * partida.cantidad);
                }

                if (partida.tipo === 1 && partida.nombre && partida.children) {
                    for (const insumo of partida.children) {
                        const cantidadTotal = cantidadAcumulada * partida.cantidad * (insumo.calculo ? (insumo.cantidad / 100) : insumo.cantidad);
                        processInsumo(insumo, round10Decimals(cantidadTotal));
                    }
                }
            }
        };

        if (partidas) {
            traversePartidas(partidas);
        }

        const partidasArbol = partidas ? partidas.map((valueP1) => {
            const mapPartidas = (partida, nivel = 1) => {

                let nuevasPartidas

                if (partida.children) {
                    nuevasPartidas = partida.children.map((child) => mapPartidas(child, nivel + 1));
                }

                let title = ''

                if (partida.tipo === 0) {
                    title = 'P : ' + partida.nombre + ' ' + dineroDisabledMask(partida.costoTotal)
                } else if (partida.tipo === 1 && partida.nombre) {
                    const matriz = this.state.todasMatrices.find(valueM => valueM._id.toString() === partida?.nombre?.toString())
                    title = 'M : ' + matriz.descripcion + ' ' + dineroDisabledMask(partida.costoTotal)
                } else {
                    title = partida.nombre + ' ' + dineroDisabledMask(partida.costoTotal)
                }

                return { ...partida, children: nuevasPartidas, title };
            };

            return mapPartidas(valueP1);
        }) : [];

        let nuevosInsumosExplosionados = []

        await axios
            .post(
                "inventarios/inventarioInsumosProyecto",
                {
                    proyectoId: this.formRef.current.getFieldValue('proyectoId'),
                    insumos: insumosExplosionados,
                    empresaId: this.props.empresaId,
                },
                { headers: this.props.headersToken }
            ).then((res) => {
                if (res.data.insumos) {
                    nuevosInsumosExplosionados = res.data.insumos
                } else {
                    nuevosInsumosExplosionados = res.data.insumos
                }
            })



        nuevosInsumosExplosionados.sort((a, b) => {
            // Primero ordenamos por familia
            let familiaCompare

            if(a.familiaNombre && b.familiaNombre) {
                familiaCompare = a.familiaNombre.localeCompare(b.familiaNombre)
            } else if(a.familiaNombre) {
                familiaCompare = -1
            } else if(b.familiaNombre) {
                familiaCompare = 1
            } else {
                familiaCompare = 0
            }
            if (familiaCompare !== 0) return familiaCompare; // Si son diferentes, usamos este orden

            // Si las familias son iguales, ordenamos por nombre
            return a.descripcionInsumo.localeCompare(b.descripcionInsumo);
        });


        await this.setState({ loadingExplosion: false, insumosExplosionados: nuevosInsumosExplosionados, costoTotalIE, partidasArbol })

        this.cambioPedir()

    }

    async onChangeExplosion(valuesKeys, consultar) {

        let newFielsValue = {}
        for (const insumoExplosion of this.state.insumosExplosionados) {
            newFielsValue['pedir' + insumoExplosion.insumoId] = undefined
        }

        this.formRef.current.setFieldsValue(newFielsValue)

        if (!consultar) return this.setState({ valueExplosion: valuesKeys })

        if (valuesKeys.length === 0) {
            this.explosionInsumos()
            await this.setState({ valueExplosion: valuesKeys })
        } else {

            await this.setState({ loadingExplosion: true })
            const partidas = [...this.state.partidas]

            let insumosExplosionados = []

            let costoTotalIE = 0


            const processInsumo = (insumo, cantidadTotal) => {
                const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === insumo.insumoId.toString());
                //Matriz sobre matriz
                if (insumo.children && insumo.children?.length > 0) {
                    insumo.children.forEach(valueIU => {
                        processInsumo(valueIU, cantidadTotal * (valueIU.calculo ? (valueIU.cantidad / 100) : valueIU.cantidad))
                    })
                } else {
                    const indexIE = insumosExplosionados.findIndex(valueTIE => valueTIE.insumoId.toString() === insumo.insumoId.toString());
                    const costo = insumo.calculo ? round2Decimals(insumo.costo * cantidadTotal) : (insumoBuscar.costo ? insumoBuscar.costo : insumo.costo);
                    const costoTotal = round2Decimals((insumoBuscar.costo ? insumoBuscar.costo : insumo.costo) * cantidadTotal);
                    const cantidadPedida = round10Decimals(insumo.cantidadPedida || 0);

                    if (indexIE === -1) {
                        insumosExplosionados.push({
                            insumoId: insumo.insumoId,
                            nombre: insumoBuscar.descripcion,
                            descripcionInsumo: insumoBuscar.descripcion,
                            unidad: insumoBuscar.unidad.nombre,
                            familiaId: insumoBuscar.familiaId,
                            familiaNombre: insumoBuscar?.familia?.nombre,
                            costo,
                            catidadMinCompra: insumoBuscar?.catidadMinCompra,
                            costoTotal,
                            cantidadPedida,
                            cantidad: insumo.calculo ? 1 : cantidadTotal
                        });
                    } else {
                        insumosExplosionados[indexIE].cantidad = insumo.calculo ? 1 : round10Decimals(insumosExplosionados[indexIE].cantidad + cantidadTotal);
                        insumosExplosionados[indexIE].costo = insumo.calculo ? round2Decimals(insumosExplosionados[indexIE].costo + costo) : insumosExplosionados[indexIE].costo;
                        insumosExplosionados[indexIE].costoTotal = round2Decimals(insumosExplosionados[indexIE].costoTotal + costoTotal);
                        insumosExplosionados[indexIE].cantidadPedida = insumo.calculo ? Math.max(insumosExplosionados[indexIE].cantidadPedida, cantidadPedida) : round10Decimals(insumosExplosionados[indexIE].cantidadPedida + cantidadPedida);
                    }
                    costoTotalIE += costoTotal;
                }

            };

            const traversePartidas = (partidas, keys = [], cantidadAcumulada = 1, keyExplosionAnterior) => {
                for (const [index, partida] of partidas.entries()) {
                    const key = [...keys, index].join('-');
                    const keyExplosion = valuesKeys.includes(key) || keyExplosionAnterior;

                    if (partida.tipo === 0 && partida.children) {
                        traversePartidas(partida.children, [...keys, index], cantidadAcumulada * partida.cantidad, keyExplosion);
                    }

                    if (partida.tipo === 1 && partida.nombre && partida.children) {
                        for (const [indexMatriz, insumo] of partida.children.entries()) {
                            const cantidadTotal = cantidadAcumulada * partida.cantidad * (insumo.calculo ? (insumo.cantidad / 100) : insumo.cantidad);
                            const keyMatriz = [...keys, index, indexMatriz].join('-');
                            const keyMatrizExplosion = valuesKeys.includes(keyMatriz);
                            if (keyExplosion || keyMatrizExplosion || keys.some(k => valuesKeys.includes(k))) {
                                processInsumo(insumo, round10Decimals(cantidadTotal));
                            }
                        }
                    }
                }
            };

            if (partidas) {
                traversePartidas(partidas);
            }

            let nuevosInsumosExplosionados = []

            await axios
                .post(
                    "inventarios/inventarioInsumosProyecto",
                    {
                        proyectoId: this.formRef.current.getFieldValue('proyectoId'),
                        insumos: insumosExplosionados,
                        empresaId: this.props.empresaId,
                    },
                    { headers: this.props.headersToken }
                ).then((res) => {
                    if (res.data.insumos) {
                        nuevosInsumosExplosionados = res.data.insumos
                    } else {
                        nuevosInsumosExplosionados = res.data.insumos
                    }
                })

            nuevosInsumosExplosionados.sort((a, b) => {
                // Primero ordenamos por familia

                let familiaCompare

                if(a.familiaNombre && b.familiaNombre) {
                    familiaCompare = a.familiaNombre.localeCompare(b.familiaNombre)
                } else if(a.familiaNombre) {
                    familiaCompare = -1
                } else if(b.familiaNombre) {
                    familiaCompare = 1
                } else {
                    familiaCompare = 0
                }
                if (familiaCompare !== 0) return familiaCompare; // Si son diferentes, usamos este orden

                // Si las familias son iguales, ordenamos por nombre
                return a.descripcionInsumo.localeCompare(b.descripcionInsumo);
            });



            await this.setState({ loadingExplosion: false, insumosExplosionados: nuevosInsumosExplosionados, costoTotalIE, valueExplosion: valuesKeys })
            this.cambioPedir()
        }
    }

    async confirmarPresupuesto() {
        this.formRef.current.validateFields().then((values) => {
            this.setState({ loading: true })
            axios
                .post("presupuestosPU/confirmarPresupuesto", { ...values, partidas: this.state.partidas, presupuestoId: this.state.id, empresaId: this.props.empresaId }, {
                    headers: {
                        ...this.props.headersToken,
                    },
                })
                .then((res) => {
                    this.setState({ loading: false, id: res.data.presupuestoId });
                    if (res.data.success === 2) {
                        message.success("Se ha confirmado el presupuesto");
                        this.consultarTodo()
                    } else if (res.data.success === 1) {
                        message.error("No se puede confirmar el presupuesto");
                    } else {
                        message.error("Hubo un error y el presupuesto no se confirmo");
                    }
                });
        }).catch(() => this.onFinishFailed())
    }

    agregarPartida(key) {
        let partidas = [...this.state.partidas]
        let expandedRowKeys = [...this.state.expandedRowKeys]
        if (key) {
            const arrayKey = key.split("-").map(value => Number(value))
            if (arrayKey.length === 1) {
                if (partidas[arrayKey[0]].children) {
                    partidas[arrayKey[0]].children.push({
                        tipo: 0,
                        nombre: "",
                        cantidad: 1,
                        costo: 0,
                        costoTotal: 0,
                        costoCImpuestos: 0,
                        costoTotalCImpuestos: 0,
                        key: key + '-' + partidas[arrayKey[0]].children.length
                    })
                } else {
                    partidas[arrayKey[0]].children = [{
                        tipo: 0,
                        nombre: "",
                        cantidad: 1,
                        costo: 0,
                        costoTotal: 0,
                        costoCImpuestos: 0,
                        costoTotalCImpuestos: 0,
                        key: key + '-0'
                    }]
                }
            } else if (arrayKey.length === 2) {
                if (partidas[arrayKey[0]].children[arrayKey[1]].children) {
                    partidas[arrayKey[0]].children[arrayKey[1]].children.push({
                        tipo: 0,
                        nombre: "",
                        cantidad: 1,
                        costo: 0,
                        costoTotal: 0,
                        costoCImpuestos: 0,
                        costoTotalCImpuestos: 0,
                        key: key + '-' + partidas[arrayKey[0]].children[arrayKey[1]].children.length
                    })
                } else {
                    partidas[arrayKey[0]].children[arrayKey[1]].children = [{
                        tipo: 0,
                        nombre: "",
                        cantidad: 1,
                        costo: 0,
                        costoTotal: 0,
                        costoCImpuestos: 0,
                        costoTotalCImpuestos: 0,
                        key: key + '-0'
                    }]
                }
            } else if (arrayKey.length === 3) {
                if (partidas[arrayKey[0]].children[arrayKey[1]].children[arrayKey[2]].children) {
                    partidas[arrayKey[0]].children[arrayKey[1]].children[arrayKey[2]].children.push({
                        tipo: 0,
                        nombre: "",
                        cantidad: 1,
                        costo: 0,
                        costoTotal: 0,
                        costoCImpuestos: 0,
                        costoTotalCImpuestos: 0,
                        key: key + '-' + partidas[arrayKey[0]].children[arrayKey[1]].children[arrayKey[2]].children.length
                    })
                } else {
                    partidas[arrayKey[0]].children[arrayKey[1]].children[arrayKey[2]].children = [{
                        tipo: 0,
                        nombre: "",
                        cantidad: 1,
                        costo: 0,
                        costoTotal: 0,
                        costoCImpuestos: 0,
                        costoTotalCImpuestos: 0,
                        key: key + '-0'
                    }]
                }
            } else if (arrayKey.length === 4) {
                if (partidas[arrayKey[0]].children[arrayKey[1]].children[arrayKey[2]].children[arrayKey[3]].children) {
                    partidas[arrayKey[0]].children[arrayKey[1]].children[arrayKey[2]].children[arrayKey[3]].children.push({
                        tipo: 0,
                        nombre: "",
                        cantidad: 1,
                        costo: 0,
                        costoTotal: 0,
                        costoCImpuestos: 0,
                        costoTotalCImpuestos: 0,
                        key: key + '-' + partidas[arrayKey[0]].children[arrayKey[1]].children[arrayKey[2]].children[arrayKey[3]].children.length
                    })
                } else {
                    partidas[arrayKey[0]].children[arrayKey[1]].children[arrayKey[2]].children[arrayKey[3]].children = [{
                        tipo: 0,
                        nombre: "",
                        cantidad: 1,
                        costo: 0,
                        costoTotal: 0,
                        costoCImpuestos: 0,
                        costoTotalCImpuestos: 0,
                        key: key + '-0'
                    }]
                }
            }
        } else {
            partidas.push({
                tipo: 0,
                nombre: "",
                cantidad: 1,
                costo: 0,
                costoTotal: 0,
                costoCImpuestos: 0,
                costoTotalCImpuestos: 0,
                key: partidas.length.toString()
            })
        }

        const keyExpand = expandedRowKeys.findIndex(value => value === key)

        if (keyExpand === -1 && key) {
            expandedRowKeys.push(key)
        }

        this.setState({ partidas: partidas, expandedRowKeys })
    }

    onChangeTipo(value, key) {
        const partidas = [...this.state.partidas]

        const arrayKeys = key.split('-').map(valueKey => Number(valueKey))

        if (arrayKeys.length === 1) {

            partidas[arrayKeys[0]].tipo = value
            partidas[arrayKeys[0]].costo = 0
            partidas[arrayKeys[0]].costoTotal = 0
            partidas[arrayKeys[0]].costoCImpuestos = 0
            partidas[arrayKeys[0]].costoTotalCImpuestos = 0


            partidas[arrayKeys[0]].children = undefined
            partidas[arrayKeys[0]].nombre = undefined

        } else if (arrayKeys.length === 2) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].tipo = value
            partidas[arrayKeys[0]].children[arrayKeys[1]].costo = 0
            partidas[arrayKeys[0]].children[arrayKeys[1]].costoTotal = 0
            partidas[arrayKeys[0]].children[arrayKeys[1]].costoCImpuestos = 0
            partidas[arrayKeys[0]].children[arrayKeys[1]].costoTotalCImpuestos = 0

            partidas[arrayKeys[0]].children[arrayKeys[1]].children = undefined
            partidas[arrayKeys[0]].children[arrayKeys[1]].nombre = undefined

        } else if (arrayKeys.length === 3) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].tipo = value
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].costo = 0
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].costoTotal = 0
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].costoCImpuestos = 0
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].costoTotalCImpuestos = 0

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children = undefined
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].nombre = undefined

        } else if (arrayKeys.length === 4) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].tipo = value
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].costo = 0
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].costoTotal = 0
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].costoCImpuestos = 0
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].costoTotalCImpuestos = 0

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children = undefined
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].nombre = undefined

        } else if (arrayKeys.length === 5) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].tipo = value
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].costo = 0
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].costoTotal = 0
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].costoCImpuestos = 0
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].costoTotalCImpuestos = 0

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].children = undefined
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].nombre = undefined

        }

        const nuevasPartidas = this.calcularPresupuesto(partidas)

        this.setState({ partidas: nuevasPartidas })
    }

    onChangeNombre(value, key) {
        const partidas = [...this.state.partidas]

        const arrayKeys = key.split('-').map(valueKey => Number(valueKey))

        if (arrayKeys.length === 1) {

            partidas[arrayKeys[0]].nombre = value.target.value

        } else if (arrayKeys.length === 2) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].nombre = value.target.value

        } else if (arrayKeys.length === 3) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].nombre = value.target.value

        } else if (arrayKeys.length === 4) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].nombre = value.target.value

        } else if (arrayKeys.length === 5) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].nombre = value.target.value

        }

        this.setState({ partidas: partidas })
    }

    calcularPresupuesto(partidas) {

        const calcularCostoYCantidad = (partida, keys = [], parentCantidad = 1) => {
            let costo = (partida.children && partida?.children?.length > 0) ? 0 : partida.costo;
            let costoCImpuestos = (partida.children && partida?.children?.length > 0) ? 0 : (partida.costoCImpuestos || 0);
            let cantidadTotal = partida.calculo ? (partida.cantidad / 100) : partida.cantidad;

            if (partida.children) {
                const newChildren = partida.children.map((child, index) => {
                    const childKeys = [...keys, index];
                    const result = calcularCostoYCantidad(child, childKeys, parentCantidad * cantidadTotal);
                    costo += round2Decimals(result.costo * (child.calculo ? (child.cantidad / 100) : child.cantidad));
                    costoCImpuestos += round2Decimals(result.costoCImpuestos * (child.calculo ? (child.cantidad / 100) : child.cantidad));
                    return result;
                });

                cantidadTotal = round10Decimals(parentCantidad * cantidadTotal);
                return {
                    ...partida,
                    key: keys.join('-'),
                    costo,
                    costoTotal: round2Decimals(costo * (partida.calculo ? (partida.cantidad / 100) : partida.cantidad)),
                    costoCImpuestos,
                    costoTotalCImpuestos: round2Decimals(costoCImpuestos * (partida.calculo ? (partida.cantidad / 100) : partida.cantidad)),
                    children: newChildren,
                    cantidadTotal
                };
            }

            return {
                ...partida,
                key: keys.join('-'),
                costo,
                costoTotal: round2Decimals(costo * (partida.calculo ? (partida.cantidad / 100) : partida.cantidad)),
                costoCImpuestos,
                costoTotalCImpuestos: round2Decimals(costoCImpuestos * (partida.calculo ? (partida.cantidad / 100) : partida.cantidad)),
                cantidadTotal: round10Decimals(parentCantidad * cantidadTotal)
            };
        };

        const nuevasPartidas = partidas ? partidas.map((partida, index) => calcularCostoYCantidad(partida, [index])) : [];

        return nuevasPartidas;
    }

    onChangeMatrizId(value, key) {
        const partidas = [...this.state.partidas]

        const arrayKeys = key.split('-').map(valueKey => Number(valueKey))

        let childrenMatriz = []
        let costoMatriz = 0
        let unidad = ''

        if (value) {
            const matriz = this.state.todasMatrices.find(valueM => valueM._id.toString() === value.toString())
            unidad = matriz.unidad.nombre

            const obtenerHijosMatriz = (valueMatriz, indexMatriz, keyMatriz = key) => {
                const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === valueMatriz.insumoId.toString())
                const costoTotal = round10Decimals((insumoBuscar.costo ? insumoBuscar.costo : valueMatriz.costo) * (insumoBuscar.calculo ? (valueMatriz.cantidad / 100) : valueMatriz.cantidad))
                costoMatriz += round10Decimals((insumoBuscar.costo ? insumoBuscar.costo : valueMatriz.costo) * (insumoBuscar.calculo ? (valueMatriz.cantidad / 100) : valueMatriz.cantidad))

                let costoCImpuestos

                if (!insumoBuscar.matriz) {

                    let totalImpuestos = 0;

                    if (insumoBuscar.impuestos && insumoBuscar.impuestos.length > 0) {

                        for (const valueImpuesto of insumoBuscar.impuestos) {
                            let totalImpuesto;
                            if (valueImpuesto.tasaCuota === 1) {
                                totalImpuesto = round6Decimals(insumoBuscar.costo * (valueImpuesto.monto / 100));
                            } else if (valueImpuesto.tasaCuota === 2) {
                                totalImpuesto =
                                    round6Decimals((insumoBuscar.costo + totalImpuestos) * (valueImpuesto.monto / 100));
                            } else if (valueImpuesto.tasaCuota === 3) {
                                totalImpuesto = round6Decimals(valueMatriz.cantidad * valueImpuesto.monto);
                            } else if (valueImpuesto.tasaCuota === 4) {
                                totalImpuesto = 0
                            } else if (valueImpuesto.tasaCuota === 5) {
                                totalImpuesto = 0
                            }

                            totalImpuestos += totalImpuesto;
                        }
                    }

                    costoCImpuestos = round2Decimals((insumoBuscar.costo ? insumoBuscar.costo : valueMatriz.costo) + totalImpuestos)
                } else {
                    costoCImpuestos = (insumoBuscar.costo ? insumoBuscar.costo : valueMatriz.costo)
                }

                return {
                    insumoMatriz: true,
                    calculo: insumoBuscar.calculo,
                    nombre: insumoBuscar?.descripcion,
                    unidad: insumoBuscar?.unidad?.nombre,
                    children: (insumoBuscar.matriz && insumoBuscar.insumosUtilizar) ? insumoBuscar.insumosUtilizar.map((valueHijo, indexHijo) => obtenerHijosMatriz(valueHijo, indexHijo, keyMatriz + '-' + indexMatriz)) : undefined,
                    costoTotal: costoTotal,
                    costo: (insumoBuscar.costo ? insumoBuscar.costo : valueMatriz.costo),
                    costoTotalCImpuestos: round2Decimals(costoCImpuestos * (insumoBuscar.calculo ? (valueMatriz.cantidad / 100) : valueMatriz.cantidad)),
                    costoCImpuestos,
                    cantidad: valueMatriz.cantidad,
                    insumoId: valueMatriz.insumoId,
                    key: keyMatriz + '-' + indexMatriz,
                }
            }

            childrenMatriz = matriz.insumosUtilizar.map((valueMatriz, indexMatriz) => obtenerHijosMatriz(valueMatriz, indexMatriz))
        }

        if (arrayKeys.length === 1) {

            partidas[arrayKeys[0]].nombre = value
            partidas[arrayKeys[0]].children = childrenMatriz
            partidas[arrayKeys[0]].costo = costoMatriz
            partidas[arrayKeys[0]].unidad = unidad
            partidas[arrayKeys[0]].costoTotal = round2Decimals(costoMatriz * (partidas[arrayKeys[0]].cantidad || 0))

        } else if (arrayKeys.length === 2) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].nombre = value
            partidas[arrayKeys[0]].children[arrayKeys[1]].children = childrenMatriz
            partidas[arrayKeys[0]].children[arrayKeys[1]].costo = costoMatriz
            partidas[arrayKeys[0]].children[arrayKeys[1]].unidad = unidad
            partidas[arrayKeys[0]].children[arrayKeys[1]].costoTotal = round2Decimals(costoMatriz * (partidas[arrayKeys[0]].children[arrayKeys[1]].cantidad || 0))

        } else if (arrayKeys.length === 3) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].nombre = value
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children = childrenMatriz
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].costo = costoMatriz
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].unidad = unidad
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].costoTotal = round2Decimals(costoMatriz * (partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].cantidad || 0))

        } else if (arrayKeys.length === 4) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].nombre = value
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children = childrenMatriz
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].costo = costoMatriz
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].unidad = unidad
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].costoTotal = round2Decimals(costoMatriz * (partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].cantidad || 0))

        } else if (arrayKeys.length === 5) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].nombre = value
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].children = childrenMatriz
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].costo = costoMatriz
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].unidad = unidad
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].costoTotal = round2Decimals(costoMatriz * (partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].cantidad || 0))

        }

        const nuevasPartidas = this.calcularPresupuesto(partidas)

        this.setState({ partidas: nuevasPartidas })
    }

    onChangeCantidad(value, key) {
        const partidas = [...this.state.partidas]

        const arrayKeys = key.split('-').map(valueKey => Number(valueKey))

        if (arrayKeys.length === 1) {

            partidas[arrayKeys[0]].cantidad = value.target.value

        } else if (arrayKeys.length === 2) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].cantidad = value.target.value

        } else if (arrayKeys.length === 3) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].cantidad = value.target.value

        } else if (arrayKeys.length === 4) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].cantidad = value.target.value

        } else if (arrayKeys.length === 5) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].cantidad = value.target.value

        }

        const nuevasPartidas = this.calcularPresupuesto(partidas)

        this.setState({ partidas: nuevasPartidas })
    }

    Cell(props) {
        if (props?.dataIndex === "tipo" && !props.record.insumoMatriz) {
            return (
                <td {...props} style={{ ...props.style, padding: 0 }}>
                    <SelectItem
                        sinBorde
                        style={{
                            backgroundColor: 'transparent'
                        }}
                        placeholder="Tipo"
                        value={props.record.tipo}
                        onChange={(value) => {
                            this.onChangeTipo(value, props.record.key)
                        }}
                    >
                        <Option value={0} key={0}>Partida</Option>
                        <Option value={1} key={1}>Matriz</Option>
                    </SelectItem>
                </td>
            )
        } else if (props?.dataIndex === "nombre" && !props.record.insumoMatriz) {
            if (props.record.tipo === 0) {
                return (
                    <td {...props} style={{ ...props.style, padding: 0 }}>
                        <InputItem
                            defaultValue={props.record.nombre}
                            style={{
                                backgroundColor: 'transparent'
                            }}
                            placeholder="Nombre"
                            sinBorde
                            onBlur={(value) => {
                                this.onChangeNombre(value, props.record.key)
                            }}
                        />
                    </td>
                )
            } else {
                return (
                    <td {...props} style={{ ...props.style, padding: 0 }}>
                        <SelectItem
                            sinBorde
                            style={{
                                backgroundColor: 'transparent'
                            }}
                            placeholder="Matriz"
                            width='100%'
                            value={props.record.nombre}
                            onChange={(value) => {
                                this.onChangeMatrizId(value, props.record.key)
                            }}
                        >
                            {
                                this.state
                                    .objetoMatrices
                            }
                        </SelectItem>
                    </td>
                )
            }
        } else if (props?.dataIndex === "cantidad" && !props.record.insumoMatriz) {
            return (
                <td {...props} style={{ ...props.style, padding: 0 }}>
                    <InputNumberItem
                        sinBorde
                        style={{
                            backgroundColor: 'transparent'
                        }}
                        placeholder="Cantidad"
                        defaultValue={props.record.cantidad}
                        onBlur={(value) => {
                            this.onChangeCantidad(value, props.record.key)
                        }}
                    />
                </td>
            )
        } else {
            return <td {...props} />
        }
    }

    quitarPartida(key) {
        let partidas = [...this.state.partidas]
        if (key) {
            const arrayKey = key.split("-").map(value => Number(value))
            if (arrayKey.length === 1) {
                partidas.splice(arrayKey[0], 1)
            } else if (arrayKey.length === 2) {
                partidas[arrayKey[0]].children.splice(arrayKey[1], 1)
            } else if (arrayKey.length === 3) {
                partidas[arrayKey[0]].children[arrayKey[1]].children.splice(arrayKey[2], 1)
            } else if (arrayKey.length === 4) {
                partidas[arrayKey[0]].children[arrayKey[1]].children[arrayKey[2]].children.splice(arrayKey[3], 1)
            }
        }

        const nuevasPartidas = this.calcularPresupuesto(partidas)

        this.setState({ partidas: nuevasPartidas })
    }

    onExpandedRowsChange(value) {
        this.setState({ expandedRowKeys: value })
    }

    requerirInsumos() {
        this.formRef.current.validateFields().then((values) => {
            this.setState({ loading: true })
            axios
                .post("presupuestosPU/requerirInsumos", { ...values, valueExplosion: this.state.valueExplosion, presupuestoId: this.state.id, empresaId: this.props.empresaId }, {
                    headers: {
                        ...this.props.headersToken,
                    },
                })
                .then(async (res) => {
                    if (res.data.success === 2) {
                        if (res.data.numeroRequisicion) {
                            message.success("Se ha generado la requisicion numero: " + res.data.numeroRequisicion);
                        } else {
                            message.success("Se ha generado la explosion por autorizar");
                        }
                        await this.consultarTodo()
                        await this.onChangeExplosion(this.state.valueExplosion)
                        this.setState({ loading: false });
                    } else if (res.data.success === 1) {
                        message.error(res.data.message);
                        this.setState({ loading: false });
                    } else {
                        message.error("Hubo un error y la requisicion no se genero");
                        this.setState({ loading: false });
                    }
                });
        }).catch((err) => {
            message.info('Esta explosion de insumos se guardara para una autorizacion posterior')

            if (err.errorFields && err.errorFields?.length > 0) {
                for (const error of err.errorFields) {
                    if (error.errors && error.errors?.length > 0) {
                        for (const errorFinal of error.errors) {
                            if (errorFinal === "El maximo es 0") {
                                return message.info('Si la cantidad maxima es 0 ya no puede pedir mas')
                            }
                        }
                    }

                }
            }

            axios
                .post("presupuestosPU/requerirInsumos", { ...err.values, valueExplosion: this.state.valueExplosion, sobresaleCantidades: true, presupuestoId: this.state.id, empresaId: this.props.empresaId }, {
                    headers: {
                        ...this.props.headersToken,
                    },
                })
                .then(async (res) => {
                    if (res.data.success === 2) {
                        message.success("Se ha guardado la explosion de insumos");
                        await this.consultarTodo()
                        await this.onChangeExplosion(this.state.valueExplosion)
                        this.setState({ loading: false });
                    } else if (res.data.success === 1) {
                        message.error(res.data.message);
                        this.setState({ loading: false });
                    } else {
                        message.error("Hubo un error y la requisicion no se genero");
                        this.setState({ loading: false });
                    }
                });
        })
    }

    pedirTotales() {
        let newFielsValue = {}
        for (const insumoExplosion of this.state.insumosExplosionados) {
            newFielsValue['pedir' + insumoExplosion.insumoId] = (insumoExplosion.cantidad - insumoExplosion.cantidadPedida) > 0 ? round4Decimals(insumoExplosion.cantidad - insumoExplosion.cantidadPedida) : 0
        }

        this.cambioPedir()

        this.formRef.current.setFieldsValue(newFielsValue)
    }

    async cambioPedir() {

        await this.setState({ loadingExplosion: true })

        let totalCostoPedir = 0

        const nuevosInsumosExplosionados = await Promise.all(this.state.insumosExplosionados.map(async (valueIE) => {

            const pedir = await this.formRef.current.getFieldValue('pedir' + valueIE.insumoId)

            totalCostoPedir = round2Decimals(totalCostoPedir + (valueIE.costo * (pedir || 0)))

            return {
                ...valueIE,
                costoPedir: round2Decimals(valueIE.costo * (pedir || 0))
            }
        }))

        await this.setState({ loadingExplosion: false, insumosExplosionados: nuevosInsumosExplosionados, totalCostoPedir })
    }

    async pedirMinCompraTotales() {
        let newFielsValue = {}
        for (const insumoExplosion of this.state.insumosExplosionados) {

            let cantidadPedir = round10Decimals(insumoExplosion.cantidad - insumoExplosion.cantidadPedida)

            if (insumoExplosion.catidadMinCompra) {
                const residuo = round10Decimals(cantidadPedir % insumoExplosion.catidadMinCompra)
                if (residuo !== 0) {
                    const divisionEntera = Math.floor(cantidadPedir / insumoExplosion.catidadMinCompra)

                    cantidadPedir = ((divisionEntera + 1) * insumoExplosion.catidadMinCompra) > 0 ? round10Decimals((divisionEntera + 1) * insumoExplosion.catidadMinCompra) : 0
                }
            }

            newFielsValue['pedir' + insumoExplosion.insumoId] = round4Decimals(cantidadPedir)
        }

        this.cambioPedir()

        this.formRef.current.setFieldsValue(newFielsValue)
    }

    pedirTotalesFamilia(familiaId) {
        let newFielsValue = {}
        for (const insumoExplosion of this.state.insumosExplosionados) {
            if (insumoExplosion.familiaId.toString() === familiaId.toString()) {
                newFielsValue['pedir' + insumoExplosion.insumoId] = (insumoExplosion.cantidad - insumoExplosion.cantidadPedida) > 0 ? round4Decimals(insumoExplosion.cantidad - insumoExplosion.cantidadPedida) : 0
            }
        }

        this.cambioPedir()

        this.formRef.current.setFieldsValue(newFielsValue)
    }


    async pedirMinCompraTotalesFamilia(familiaId) {
        let newFielsValue = {}
        for (const insumoExplosion of this.state.insumosExplosionados) {
            if (insumoExplosion.familiaId.toString() === familiaId.toString()) {

                let cantidadPedir = round10Decimals(insumoExplosion.cantidad - insumoExplosion.cantidadPedida)

                if (insumoExplosion.catidadMinCompra) {
                    const residuo = round10Decimals(cantidadPedir % insumoExplosion.catidadMinCompra)
                    if (residuo !== 0) {
                        const divisionEntera = Math.floor(cantidadPedir / insumoExplosion.catidadMinCompra)

                        cantidadPedir = ((divisionEntera + 1) * insumoExplosion.catidadMinCompra) > 0 ? round10Decimals((divisionEntera + 1) * insumoExplosion.catidadMinCompra) : 0
                    }
                }

                newFielsValue['pedir' + insumoExplosion.insumoId] = round4Decimals(cantidadPedir)
            }
        }

        this.cambioPedir()

        this.formRef.current.setFieldsValue(newFielsValue)
    }

    handleTaskChange(task) {
        let updatedTasks = this.state.tasksDiagrama.map((t) => (t.id === task.id ? task : t));

        let updatedPartidas = [...this.state.partidas]

        const taskKeyString = task.id.toString()

        const arrayKeys = task.id.split('-').map(valueKey => Number(valueKey))

        if (arrayKeys.length === 1) {

            updatedPartidas[arrayKeys[0]].start = task.start
            updatedPartidas[arrayKeys[0]].end = task.end

        } else if (arrayKeys.length === 2) {

            updatedPartidas[arrayKeys[0]].children[arrayKeys[1]].start = task.start
            updatedPartidas[arrayKeys[0]].children[arrayKeys[1]].end = task.end

        } else if (arrayKeys.length === 3) {

            updatedPartidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].start = task.start
            updatedPartidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].end = task.end

        } else if (arrayKeys.length === 4) {

            updatedPartidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].start = task.start
            updatedPartidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].end = task.end

        } else if (arrayKeys.length === 5) {

            updatedPartidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].start = task.start
            updatedPartidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].end = task.end

        }

        // Actualizar las fechas de la tarea padre según los hijos


        for (let tIndex = updatedTasks.length - 1; tIndex >= 0; tIndex--) {
            const t = updatedTasks[tIndex]
            if (t.type === 'project') {

                const keyString = t.id.toString()
                const lengthKey = keyString.length
                if (keyString === taskKeyString.slice(0, lengthKey)) {

                    let start
                    let end

                    const children = updatedTasks.filter((tC) => tC.project === t.id);

                    for (const valueC of children) {
                        if (moment(valueC.start) < start || !start) {
                            start = moment(valueC.start).clone()
                        }

                        if (moment(valueC.end) > end || !end) {
                            end = moment(valueC.end).clone()
                        }
                    }

                    updatedTasks[tIndex].start = start.toDate()
                    updatedTasks[tIndex].end = end.toDate()

                    const arrayKeys = t.id.split('-').map(valueKey => Number(valueKey))

                    if (arrayKeys.length === 1) {

                        updatedPartidas[arrayKeys[0]].start = start.toDate()
                        updatedPartidas[arrayKeys[0]].end = end.toDate()

                    } else if (arrayKeys.length === 2) {

                        updatedPartidas[arrayKeys[0]].children[arrayKeys[1]].start = start.toDate()
                        updatedPartidas[arrayKeys[0]].children[arrayKeys[1]].end = end.toDate()

                    } else if (arrayKeys.length === 3) {

                        updatedPartidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].start = start.toDate()
                        updatedPartidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].end = end.toDate()

                    } else if (arrayKeys.length === 4) {

                        updatedPartidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].start = start.toDate()
                        updatedPartidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].end = end.toDate()

                    } else if (arrayKeys.length === 5) {

                        updatedPartidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].start = task.start
                        updatedPartidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].end = task.end

                    }

                }
            }
        }


        this.setState({ tasksDiagrama: updatedTasks, partidas: updatedPartidas })
    };

    async hacerDiagramaGantt() {

        await this.setState({ loadingDiagrama: true })

        const partidas = [...this.state.partidas]

        let tasksDiagrama = []

        const stylesTask = {
            backgroundColor: "#00b5e2",
            backgroundSelectedColor: "#00b5e2",
            progressColor: "#00b5e2",
            progressSelectedColor: "#00b5e2"
        }

        const stylesProject = {
            backgroundColor: "#fac465",
            backgroundSelectedColor: "#fac465",
            progressColor: "#fac465",
            progressSelectedColor: "#fac465"
        }


        if (partidas) {

            for (const [indexP1, valueP1] of partidas.entries()) {
                const key1 = indexP1.toString()

                if (valueP1.children) {

                    for (const [indexP2, valueP2] of valueP1.children.entries()) {
                        const key2 = indexP1 + '-' + indexP2

                        if (valueP2.children) {
                            for (const [indexP3, valueP3] of valueP2.children.entries()) {
                                const key3 = indexP1 + '-' + indexP2 + '-' + indexP3

                                if (valueP3.children) {
                                    for (const [indexP4, valueP4] of valueP3.children.entries()) {
                                        const key4 = indexP1 + '-' + indexP2 + '-' + indexP3 + '-' + indexP4

                                        if (valueP4.children) {
                                            for (const [indexP5, valueP5] of valueP4.children.entries()) {
                                                const key5 = indexP1 + '-' + indexP2 + '-' + indexP3 + '-' + indexP4 + '-' + indexP5

                                                if (!valueP5.insumoMatriz) {

                                                    let proyecto = false
                                                    let nombre = valueP5.nombre

                                                    if (valueP5.children && valueP5?.children?.length > 0) {
                                                        const findPartida = valueP5.children.find(valueTC => !valueTC.insumoMatriz)
                                                        if (findPartida) {
                                                            proyecto = true
                                                        }
                                                    }

                                                    if (valueP5.tipo === 1 && valueP5.nombre) {
                                                        const matriz = this.state.todasMatrices.find(valueTM => valueTM._id.toString() === valueP5.nombre.toString())
                                                        nombre = matriz.descripcion
                                                    }

                                                    tasksDiagrama.push({
                                                        id: key5,
                                                        name: nombre,
                                                        start: moment(valueP5.start).toDate(),
                                                        end: valueP5.end ? moment(valueP5.end).toDate() : moment().add(1, 'd').toDate(),
                                                        progress: 0,
                                                        project: key4,
                                                        type: proyecto ? 'project' : undefined,
                                                        styles: proyecto ? stylesProject : stylesTask,
                                                        hideChildren: !proyecto,
                                                    })

                                                }
                                            }
                                        }

                                        if (!valueP4.insumoMatriz) {

                                            let proyecto = false
                                            let nombre = valueP4.nombre

                                            if (valueP4.children && valueP4?.children?.length > 0) {
                                                const findPartida = valueP4.children.find(valueTC => !valueTC.insumoMatriz)
                                                if (findPartida) {
                                                    proyecto = true
                                                }
                                            }

                                            if (valueP4.tipo === 1 && valueP4.nombre) {
                                                const matriz = this.state.todasMatrices.find(valueTM => valueTM._id.toString() === valueP4.nombre.toString())
                                                nombre = matriz.descripcion
                                            }

                                            tasksDiagrama.push({
                                                id: key4,
                                                name: nombre,
                                                start: moment(valueP4.start).toDate(),
                                                end: valueP4.end ? moment(valueP4.end).toDate() : moment().add(1, 'd').toDate(),
                                                progress: 0,
                                                project: key3,
                                                type: proyecto ? 'project' : undefined,
                                                styles: proyecto ? stylesProject : stylesTask,
                                                hideChildren: !proyecto,
                                            })

                                        }
                                    }
                                }

                                if (!valueP3.insumoMatriz) {

                                    let proyecto = false
                                    let nombre = valueP3.nombre

                                    if (valueP3.children && valueP3?.children?.length > 0) {
                                        const findPartida = valueP3.children.find(valueTC => !valueTC.insumoMatriz)
                                        if (findPartida) {
                                            proyecto = true
                                        }
                                    }

                                    if (valueP3.tipo === 1 && valueP3.nombre) {
                                        const matriz = this.state.todasMatrices.find(valueTM => valueTM._id.toString() === valueP3.nombre.toString())
                                        nombre = matriz.descripcion
                                    }

                                    tasksDiagrama.push({
                                        id: key3,
                                        name: nombre,
                                        start: moment(valueP3.start).toDate(),
                                        end: valueP3.end ? moment(valueP3.end).toDate() : moment().add(1, 'd').toDate(),
                                        progress: 0,
                                        project: key2,
                                        type: proyecto ? 'project' : undefined,
                                        styles: proyecto ? stylesProject : stylesTask,
                                        hideChildren: !proyecto,
                                    })

                                }
                            }

                        }

                        if (!valueP2.insumoMatriz) {

                            let proyecto = false
                            let nombre = valueP2.nombre

                            if (valueP2.children && valueP2?.children?.length > 0) {
                                const findPartida = valueP2.children.find(valueTC => !valueTC.insumoMatriz)
                                if (findPartida) {
                                    proyecto = true
                                }
                            }

                            if (valueP2.tipo === 1 && valueP2.nombre) {
                                const matriz = this.state.todasMatrices.find(valueTM => valueTM._id.toString() === valueP2.nombre.toString())
                                nombre = matriz.descripcion
                            }

                            tasksDiagrama.push({
                                id: key2,
                                name: nombre,
                                start: moment(valueP2.start).toDate(),
                                end: valueP2.end ? moment(valueP2.end).toDate() : moment().add(1, 'd').toDate(),
                                progress: 0,
                                project: key1,
                                type: proyecto ? 'project' : undefined,
                                styles: proyecto ? stylesProject : stylesTask,
                                hideChildren: !proyecto,
                            })

                        }
                    }

                }

                if (!valueP1.insumoMatriz) {

                    let proyecto = false
                    let nombre = valueP1.nombre

                    if (valueP1.children && valueP1?.children?.length > 0) {
                        const findPartida = valueP1.children.find(valueTC => !valueTC.insumoMatriz)
                        if (findPartida) {
                            proyecto = true
                        }
                    }

                    if (valueP1.tipo === 1 && valueP1.nombre) {
                        const matriz = this.state.todasMatrices.find(valueTM => valueTM._id.toString() === valueP1.nombre.toString())
                        nombre = matriz.descripcion
                    }

                    tasksDiagrama.push({
                        id: key1,
                        name: nombre,
                        start: moment(valueP1.start).toDate(),
                        end: valueP1.end ? moment(valueP1.end).toDate() : moment().add(1, 'd').toDate(),
                        progress: 0,
                        type: proyecto ? 'project' : undefined,
                        styles: proyecto ? stylesProject : stylesTask,
                        hideChildren: !proyecto,
                    })

                }
            }
        }

        if (tasksDiagrama.length === 0) {
            tasksDiagrama = [
                {
                    id: '1',
                    name: 'No cargo correctamente',
                    start: moment().toDate(),
                    end: moment().add(1, 'd').toDate(),
                    progress: 50,
                    type: 'project',
                    styles: stylesTask,
                    hideChildren: false,
                },
            ]
        } else {
            // tasksDiagrama = tasksDiagrama.map(valueD => {
            //     const arraykeys = valueD.id.split('-')
            //     let suma = 0

            //     for (const key of arraykeys) {
            //         suma = suma + key
            //     }

            //     return {
            //         ...valueD,
            //         suma
            //     }
            // }).sort((a, b) => a.suma - b.suma);

            tasksDiagrama.sort((a, b) => {
                const aParts = a.id.split('-').map(Number);
                const bParts = b.id.split('-').map(Number);

                for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
                    const aVal = aParts[i] !== undefined ? aParts[i] : -1;
                    const bVal = bParts[i] !== undefined ? bParts[i] : -1;

                    if (aVal !== bVal) {
                        return aVal - bVal;
                    }
                }

                return 0;
            });
        }

        await this.setState({ loadingDiagrama: false, tasksDiagrama })
    }

    onExpanderClick(task) {

        let newTasks = [...this.state.tasksDiagrama]

        const index = newTasks.findIndex(valueTT => valueTT.id === task.id)

        if (index !== -1) {
            newTasks[index].hideChildren = !newTasks[index].hideChildren
        }

        this.setState({ tasksDiagrama: newTasks })
    }

    descargarMatrices(tipo) {

        this.setState({ loadingReportes: true })

        const calcularConImpuestos = this.formRef.current.getFieldValue('calcularConImpuestos')
        const calcularConIndirectos = this.formRef.current.getFieldValue('calcularConIndirectos')
        const indirectos = this.formRef.current.getFieldValue('indirectos')
        const nombre = this.formRef.current.getFieldValue('nombre')
        const fechaInicio = this.formRef.current.getFieldValue('fechaInicio')
        const fechaFinal = this.formRef.current.getFieldValue('fechaFinal')

        if (tipo === 'pdf') {

            let lineasMatricesPdf = [];

            const partidas = [...this.state.partidas]

            const processMatriz = (matriz, cantidadTotal, nombrePartida, partidaKey) => {

                const insumosAgrupadosTipoInsumo = []

                let agregarCalculosFinales = []

                const matrizCostoDirecto = calcularConImpuestos ? matriz.costoCImpuestos : matriz.costo

                let matrizCostoTotal = calcularConImpuestos ? matriz.costoCImpuestos : matriz.costo

                let agregarPdfM = []

                const matrizBuscar = this.state.todasMatrices.find(valueTM => valueTM._id.toString() === matriz.nombre.toString());

                //Indirectos


                if (calcularConIndirectos && indirectos && indirectos.length > 0) {

                    for (const indirecto of indirectos) {

                        let costoIndirecto = 0

                        if (indirecto.calculo === 1) {

                            //Sobre Total

                            costoIndirecto = (matrizCostoDirecto * (indirecto.porcentaje / 100))

                        } else if (indirecto.calculo === 2) {

                            //Sobre Acumulado

                            costoIndirecto = (matrizCostoTotal * (indirecto.porcentaje / 100))

                        }

                        matrizCostoTotal = round2Decimals(matrizCostoTotal + costoIndirecto)

                        agregarCalculosFinales.push([
                            { text: "", style: 'textoTabla', alignment: 'center' },
                            { text: "(CI) " + indirecto.nombre, style: 'textoTabla', alignment: 'left' },
                            { text: "", style: 'textoTabla', alignment: 'center' },
                            { text: "", style: 'textoTabla', alignment: 'center' },
                            { text: "", style: 'textoTabla', alignment: 'center' },
                            { text: indirecto.porcentaje + '%', style: 'textoTabla', alignment: 'right' },
                            { text: dineroDisabledMask(costoIndirecto), style: 'textoTabla', alignment: 'right' },
                            { text: "", style: 'textoTabla', alignment: 'center' },
                        ]);


                    }


                }

                for (const insumo of matriz.children) {

                    const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === insumo.insumoId.toString());

                    if (insumoBuscar.matriz) {

                        const insumosMatriz2 = insumo.children.map(valueIM => {
                            const insumoBuscar2 = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === valueIM.insumoId.toString());

                            return {
                                ...valueIM,
                                ...insumoBuscar2,
                            }
                        })

                        insumosAgrupadosTipoInsumo.push({
                            tipoDeInsumoId: insumoBuscar._id.toString(),
                            matriz2: true,
                            nombre: insumo.nombre,
                            costoTotal: round2Decimals(insumo.costoTotal),
                            costoTotalCImpuestos: round2Decimals(insumo.costoTotalCImpuestos),
                            unidad: insumo.unidad,
                            cantidad: insumo.cantidad,
                            insumos: insumosMatriz2
                        })

                    } else {
                        const indexInsumosAgrupadosTipoInsumo = insumosAgrupadosTipoInsumo.findIndex(valueIATI => valueIATI?.tipoDeInsumoId?.toString() === insumoBuscar?.tipoDeInsumoId?.toString())

                        if (indexInsumosAgrupadosTipoInsumo === -1) {

                            let tipoDeInsumo

                            if (insumoBuscar.tipoDeInsumoId) {
                                tipoDeInsumo = this.state.tipoInsumos.find(valueTTI => valueTTI._id.toString() === insumoBuscar.tipoDeInsumoId.toString())
                            }

                            insumosAgrupadosTipoInsumo.push({
                                tipoDeInsumoId: insumoBuscar.tipoDeInsumoId,
                                nombre: tipoDeInsumo?.nombre,
                                insumos: [{ ...insumo, ...insumoBuscar }]
                            })
                        } else {
                            insumosAgrupadosTipoInsumo[indexInsumosAgrupadosTipoInsumo].insumos.push({ ...insumo, ...insumoBuscar })
                        }

                    }
                }

                agregarPdfM.push([
                    { text: "Partida:", style: 'textoTabla', alignment: 'left' },
                    { text: nombrePartida, style: 'textoTabla', alignment: 'left' },
                    { text: "Análisis No.:", style: 'textoTablaBold', alignment: 'center' },
                    { text: "", style: 'textoTabla', alignment: 'center' },
                    { text: cantidadTotal, style: 'textoTablaBold', alignment: 'center' },
                    { text: "", style: 'textoTabla', alignment: 'right' },
                    { text: "", style: 'textoTabla', alignment: 'right' },
                    { text: "", style: 'textoTabla', alignment: 'center' },
                ],
                    [
                        { text: "Análisis:", style: 'textoTablaBold', alignment: 'left' },
                        { text: "", style: 'textoTabla', alignment: 'left' },
                        { text: "", style: 'textoTablaBold', alignment: 'center' },
                        { text: matriz.unidad, style: 'textoTablaBold', alignment: 'center' },
                        { text: "", style: 'textoTablaBold', alignment: 'center' },
                        { text: matriz.cantidad, style: 'textoTablaBold', alignment: 'right' },
                        { text: dineroDisabledMask(matrizCostoTotal * matriz.cantidad), style: 'textoTablaBold', alignment: 'right' },
                        { text: "", style: 'textoTablaBold', alignment: 'center' },
                    ],
                    [
                        { text: matrizBuscar.descripcion, colSpan: 8, style: 'textoTabla', alignment: { horizontal: 'left', wrapText: true, vertical: 'top' } },
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                    ]
                );

                for (const insumoAgrupadoTipoInsumo of insumosAgrupadosTipoInsumo) {

                    agregarPdfM.push([
                        { text: insumoAgrupadoTipoInsumo.nombre ? insumoAgrupadoTipoInsumo.nombre : 'MATERIALES', colSpan: 5, style: 'textoTablaBold', alignment: 'left' },
                        {},
                        {},
                        {},
                        {},
                        { text: "", style: 'textoTablaBold', alignment: 'center' },
                        { text: "", style: 'textoTablaBold', alignment: 'center' },
                        { text: "", style: 'textoTablaBold', alignment: 'center' },
                    ]);

                    const costoTotalCOpciones = calcularConImpuestos ? insumoAgrupadoTipoInsumo.costoTotalCImpuestos : insumoAgrupadoTipoInsumo.costoTotal

                    let sumaPorcentajes = insumoAgrupadoTipoInsumo.matriz2 ? round2Decimals(costoTotalCOpciones / matrizCostoDirecto * 100) : 0
                    let sumaImportes = insumoAgrupadoTipoInsumo.matriz2 ? round2Decimals(costoTotalCOpciones) : 0
                    let sumaImportesInsumos = 0

                    for (const insumo of insumoAgrupadoTipoInsumo.insumos) {

                        const costoTotalInsumo = calcularConImpuestos ? insumo.costoTotalCImpuestos : insumo.costoTotal

                        agregarPdfM.push([
                            { text: insumo.clave ? insumo.clave : '', style: 'textoTabla', alignment: 'center' },
                            { text: insumo.nombre, style: 'textoTabla', alignment: 'left' },
                            { text: insumo.unidad.nombre, style: 'textoTabla', alignment: 'center' },
                            { text: dineroDisabledMask(insumo.costo), style: 'textoTabla', alignment: 'right' },
                            { text: "*", style: 'textoTabla', alignment: 'center' },
                            { text: insumo.cantidad, style: 'textoTabla', alignment: 'right' },
                            { text: dineroDisabledMask(costoTotalInsumo), style: 'textoTabla', alignment: 'right' },
                            { text: insumoAgrupadoTipoInsumo.matriz2 ? '0.00%' : round2Decimals(costoTotalInsumo / matrizCostoDirecto * 100) + '%', style: 'textoTabla', alignment: 'right' },

                        ]);

                        if (!insumoAgrupadoTipoInsumo.matriz2) {
                            sumaImportes = sumaImportes + round2Decimals(costoTotalInsumo)
                            sumaPorcentajes = sumaPorcentajes + round2Decimals(costoTotalInsumo / matrizCostoDirecto * 100)
                        } else {
                            sumaImportesInsumos = sumaImportesInsumos + round2Decimals(costoTotalInsumo)
                        }

                    }

                    if (insumoAgrupadoTipoInsumo.matriz2) {

                        agregarPdfM.push([
                            { text: "", style: 'textoTabla', alignment: 'center' },
                            { text: "Importe:", style: 'textoTabla', alignment: 'left' },
                            { text: "", style: 'textoTabla', alignment: 'center' },
                            { text: "", style: 'textoTabla', alignment: 'right' },
                            { text: "", style: 'textoTabla', alignment: 'center' },
                            { text: "", style: 'textoTabla', alignment: 'right' },
                            { text: dineroDisabledMask(sumaImportesInsumos), style: 'textoTabla', alignment: 'right' },
                            { text: "", style: 'textoTabla', alignment: 'right' },
                        ]);

                        agregarPdfM.push([
                            { text: "", style: 'textoTabla', alignment: 'center' },
                            { text: "Rendimiento: " + insumoAgrupadoTipoInsumo.unidad, style: 'textoTabla', alignment: 'left' },
                            { text: "", style: 'textoTabla', alignment: 'center' },
                            { text: "", style: 'textoTabla', alignment: 'right' },
                            { text: "", style: 'textoTabla', alignment: 'center' },
                            { text: insumoAgrupadoTipoInsumo.cantidad, style: 'textoTabla', alignment: 'right' },
                            { text: dineroDisabledMask(sumaImportes), style: 'textoTabla', alignment: 'right' },
                            { text: round2Decimals(sumaPorcentajes) + '%', style: 'textoTabla', alignment: 'right' },
                        ]);


                    }

                    agregarPdfM.push([{ text: "" }, { text: "" }, { text: "" }, { text: "" }, { text: "" }, { text: "" }, { text: "" }, { text: "" }]);

                    agregarPdfM.push([
                        { text: "", style: 'textoTabla', alignment: 'center' },
                        { text: "SUBTOTAL:", style: 'textoTablaBold', alignment: 'left' },
                        { text: insumoAgrupadoTipoInsumo.nombre ? insumoAgrupadoTipoInsumo.nombre : 'MATERIALES', colSpan: 4, style: 'textoTablaBold', alignment: 'left' },
                        {},
                        {},
                        {},
                        { text: dineroDisabledMask(sumaImportes), style: 'textoTablaBold', alignment: 'right' },
                        { text: round2Decimals(sumaPorcentajes) + '%', style: 'textoTablaBold', alignment: 'right' },
                    ]);

                }

                //Lineas de la matriz final

                //Costo directo

                agregarPdfM.push([
                    { text: "", style: 'textoTabla', alignment: 'center' },
                    { text: "(CD) Costo directo", style: 'textoTablaBold', alignment: 'left' },
                    { text: "", style: 'textoTabla', alignment: 'center' },
                    { text: "", style: 'textoTabla', alignment: 'center' },
                    { text: "", style: 'textoTabla', alignment: 'center' },
                    { text: "", style: 'textoTabla', alignment: 'center' },
                    { text: dineroDisabledMask(matrizCostoDirecto), style: 'textoTablaBold', alignment: 'right' },
                    { text: "100%", style: 'textoTablaBold', alignment: 'right' },
                ]);

                //Agregar Costos indirectos

                for (const calculoFinal of agregarCalculosFinales) {
                    agregarPdfM.push(calculoFinal)
                }


                agregarPdfM.push([
                    { text: "", style: 'textoTabla', alignment: 'center' },
                    { text: "PRECIO UNITARIO (CD+CI)", style: 'textoTabla', alignment: 'left' },
                    { text: "", style: 'textoTabla', alignment: 'center' },
                    { text: "", style: 'textoTabla', alignment: 'center' },
                    { text: "", style: 'textoTabla', alignment: 'center' },
                    { text: "", style: 'textoTabla', alignment: 'center' },
                    { text: dineroDisabledMask(matrizCostoTotal), style: 'textoTabla', alignment: 'right' },
                    { text: "", style: 'textoTabla', alignment: 'right' },
                ]);


                //Linea en blanco

                agregarPdfM.push([{ text: "" }, { text: "" }, { text: "" }, { text: "" }, { text: "" }, { text: "" }, { text: "" }, { text: "" }]);



                lineasMatricesPdf = [...lineasMatricesPdf, ...agregarPdfM]


            }

            const traversePartidas = (partidas, keys = [], cantidadAcumulada = 1, partidaNombre = '') => {
                for (const [index, partida] of partidas.entries()) {
                    if (partida.children && partida.tipo !== 1) {
                        traversePartidas(partida.children, [...keys, index], cantidadAcumulada * partida.cantidad, partida.nombre);
                    }

                    if (partida.tipo === 1 && partida.nombre && partida.children) {

                        processMatriz(partida, round10Decimals(cantidadAcumulada), partidaNombre, keys.join('-'));

                    }
                }
            };

            if (partidas) {
                traversePartidas(partidas);
            }

            axios
                .post(
                    "presupuestosPU/selectDatosParaPdf",
                    { empresaId: this.props.empresaId },
                    {
                        headers: this.props.headersToken,
                    }
                )
                .then((res) => {
                    if (res.data.success === 2) {
                        const pdf = reporteMatricesPdf({ fechaFinal, fechaInicio, matrices: lineasMatricesPdf, nombrePresupuesto: nombre, empresa: res.data.empresa, logoBiwo: res.data.logoBiwo });

                        pdfMake.createPdf(pdf).download(`MATRICES ${nombre ? nombre : ''}.pdf`);

                        this.setState({ loadingReportes: false })
                    } else {
                        message.warning('No se pudo descargar el reporte');
                    }
                });


        } else if (tipo === 'excel') {

            let mergesHoja = []

            let rowsHoja = []

            const allBorders = {
                top: { style: 'thin', color: { auto: 1 } },
                bottom: { style: 'thin', color: { auto: 1 } },
                left: { style: 'thin', color: { auto: 1 } },
                right: { style: 'thin', color: { auto: 1 } },
            }

            const topBorder = {
                top: { style: 'thin', color: { auto: 1 } },
            }

            // const noBorders = {
            //     top: { style: 'none' },
            //     bottom: { style: 'none' },
            //     left: { style: 'none' },
            //     right: { style: 'none' },
            // };

            let lineasDescargarHoja = [];

            //Encabezado

            lineasDescargarHoja.push([]);

            lineasDescargarHoja.push([
                { v: "ANALISIS DE PRECIOS UNITARIOS", t: 's', s: { font: { bold: true, sz: 14 }, alignment: { horizontal: 'center' } } },
                { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
            ]);

            mergesHoja.push(XLSXStyle.utils.decode_range("A2:H2"));

            lineasDescargarHoja.push([]);

            lineasDescargarHoja.push([
                { v: "Código", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' }, border: allBorders } },
                { v: "Concepto", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' }, border: allBorders } },
                { v: "Unidad", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' }, border: allBorders } },
                { v: "P. Unitario", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' }, border: allBorders } },
                { v: "Op.", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' }, border: allBorders } },
                { v: "Cantidad", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' }, border: allBorders } },
                { v: "Importe", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' }, border: allBorders } },
                { v: "%", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' }, border: allBorders } },
            ]);

            const partidas = [...this.state.partidas]

            const processMatriz = (matriz, cantidadTotal, nombrePartida, partidaKey) => {

                const insumosAgrupadosTipoInsumo = []

                let agregarCalculosFinales = []

                const matrizCostoDirecto = calcularConImpuestos ? matriz.costoCImpuestos : matriz.costo

                let matrizCostoTotal = calcularConImpuestos ? matriz.costoCImpuestos : matriz.costo

                let agregarExcelM = []

                const matrizBuscar = this.state.todasMatrices.find(valueTM => valueTM._id.toString() === matriz.nombre.toString());

                //Indirectos


                if (calcularConIndirectos && indirectos && indirectos.length > 0) {

                    for (const indirecto of indirectos) {

                        let costoIndirecto = 0

                        if (indirecto.calculo === 1) {

                            //Sobre Total

                            costoIndirecto = (matrizCostoDirecto * (indirecto.porcentaje / 100))

                        } else if (indirecto.calculo === 2) {

                            //Sobre Acumulado

                            costoIndirecto = (matrizCostoTotal * (indirecto.porcentaje / 100))

                        }

                        matrizCostoTotal = round2Decimals(matrizCostoTotal + costoIndirecto)

                        agregarCalculosFinales.push([
                            { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                            { v: "(CI) " + indirecto.nombre, t: 's', s: { alignment: { horizontal: 'left' }, } },
                            { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                            { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                            { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                            { v: indirecto.porcentaje + '%', t: 's', s: { alignment: { horizontal: 'right' } } },
                            { v: dineroDisabledMask(costoIndirecto), t: 's', s: { alignment: { horizontal: 'right' } } },
                            { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                        ]);


                    }


                }

                for (const insumo of matriz.children) {

                    const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === insumo.insumoId.toString());

                    if (insumoBuscar.matriz) {

                        const insumosMatriz2 = insumo.children.map(valueIM => {
                            const insumoBuscar2 = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === valueIM.insumoId.toString());

                            return {
                                ...valueIM,
                                ...insumoBuscar2,
                            }
                        })

                        insumosAgrupadosTipoInsumo.push({
                            tipoDeInsumoId: insumoBuscar._id.toString(),
                            matriz2: true,
                            nombre: insumo.nombre,
                            costoTotal: round2Decimals(insumo.costoTotal),
                            costoTotalCImpuestos: round2Decimals(insumo.costoTotalCImpuestos),
                            unidad: insumo.unidad,
                            cantidad: insumo.cantidad,
                            insumos: insumosMatriz2
                        })

                    } else {
                        const indexInsumosAgrupadosTipoInsumo = insumosAgrupadosTipoInsumo.findIndex(valueIATI => valueIATI?.tipoDeInsumoId?.toString() === insumoBuscar?.tipoDeInsumoId?.toString())

                        if (indexInsumosAgrupadosTipoInsumo === -1) {

                            let tipoDeInsumo

                            if (insumoBuscar.tipoDeInsumoId) {
                                tipoDeInsumo = this.state.tipoInsumos.find(valueTTI => valueTTI._id.toString() === insumoBuscar.tipoDeInsumoId.toString())
                            }

                            insumosAgrupadosTipoInsumo.push({
                                tipoDeInsumoId: insumoBuscar.tipoDeInsumoId,
                                nombre: tipoDeInsumo?.nombre,
                                insumos: [{ ...insumo, ...insumoBuscar }]
                            })
                        } else {
                            insumosAgrupadosTipoInsumo[indexInsumosAgrupadosTipoInsumo].insumos.push({ ...insumo, ...insumoBuscar })
                        }

                    }
                }

                agregarExcelM.push([
                    { v: "Partida:", t: 's', s: { alignment: { horizontal: 'left' }, } },
                    { v: nombrePartida, t: 's', s: { alignment: { horizontal: 'left' }, } },
                    { v: "Análisis No.:", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                    { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                    { v: cantidadTotal, t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                    { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                    { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                    { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                ],
                    [
                        { v: "Análisis:", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'left' }, } },
                        { v: "", t: 's', s: { alignment: { horizontal: 'left' }, } },
                        { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                        { v: matriz.unidad, t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                        { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                        { v: matriz.cantidad, t: 's', s: { font: { bold: true }, alignment: { horizontal: 'right' } } },
                        { v: dineroDisabledMask(matrizCostoTotal * matriz.cantidad), t: 's', s: { font: { bold: true }, alignment: { horizontal: 'right' } } },
                        { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                    ],
                    [
                        { v: matrizBuscar.descripcion, t: 's', s: { alignment: { horizontal: 'left', wrapText: true, vertical: 'top' }, } },
                        { v: "", t: 's', s: { alignment: { horizontal: 'left' }, } },
                        { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                        { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                        { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                        { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                        { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                        { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                    ]
                );

                for (const insumoAgrupadoTipoInsumo of insumosAgrupadosTipoInsumo) {

                    agregarExcelM.push([
                        { v: insumoAgrupadoTipoInsumo.nombre ? insumoAgrupadoTipoInsumo.nombre : 'MATERIALES', t: 's', s: { font: { bold: true }, alignment: { horizontal: 'left' }, } },
                        { v: "", t: 's', s: { alignment: { horizontal: 'left' }, } },
                        { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                        { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                        { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                        { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                        { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                        { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                    ]);

                    const costoTotalCOpciones = calcularConImpuestos ? insumoAgrupadoTipoInsumo.costoTotalCImpuestos : insumoAgrupadoTipoInsumo.costoTotal

                    let sumaPorcentajes = insumoAgrupadoTipoInsumo.matriz2 ? round2Decimals(costoTotalCOpciones / matrizCostoDirecto * 100) : 0
                    let sumaImportes = insumoAgrupadoTipoInsumo.matriz2 ? round2Decimals(costoTotalCOpciones) : 0
                    let sumaImportesInsumos = 0

                    for (const insumo of insumoAgrupadoTipoInsumo.insumos) {

                        const costoTotalInsumo = calcularConImpuestos ? insumo.costoTotalCImpuestos : insumo.costoTotal

                        agregarExcelM.push([
                            { v: insumo.clave ? insumo.clave : '', t: 's', s: { alignment: { horizontal: 'center' } } },
                            { v: insumo.nombre, t: 's', s: { alignment: { horizontal: 'left' } } },
                            { v: insumo.unidad.nombre, t: 's', s: { alignment: { horizontal: 'center' } } },
                            { v: dineroDisabledMask(insumo.costo), t: 's', s: { alignment: { horizontal: 'right' } } },
                            { v: "*", t: 's', s: { alignment: { horizontal: 'center' } } },
                            { v: insumo.cantidad, t: 's', s: { alignment: { horizontal: 'right' } } },
                            { v: dineroDisabledMask(costoTotalInsumo), t: 's', s: { alignment: { horizontal: 'right' } } },
                            { v: insumoAgrupadoTipoInsumo.matriz2 ? '0.00%' : round2Decimals(costoTotalInsumo / matrizCostoDirecto * 100) + '%', t: 's', s: { alignment: { horizontal: 'right' } } },
                        ]);

                        if (!insumoAgrupadoTipoInsumo.matriz2) {
                            sumaImportes = sumaImportes + round2Decimals(costoTotalInsumo)
                            sumaPorcentajes = sumaPorcentajes + round2Decimals(costoTotalInsumo / matrizCostoDirecto * 100)
                        } else {
                            sumaImportesInsumos = sumaImportesInsumos + round2Decimals(costoTotalInsumo)
                        }

                    }

                    if (insumoAgrupadoTipoInsumo.matriz2) {

                        agregarExcelM.push([
                            { v: "", t: 's', s: { alignment: { horizontal: 'center' } } },
                            { v: "Importe:", t: 's', s: { alignment: { horizontal: 'left' } } },
                            { v: "", t: 's', s: { alignment: { horizontal: 'center' } } },
                            { v: "", t: 's', s: { alignment: { horizontal: 'right' } } },
                            { v: "", t: 's', s: { alignment: { horizontal: 'center' } } },
                            { v: "", t: 's', s: { alignment: { horizontal: 'right' } } },
                            { v: dineroDisabledMask(sumaImportesInsumos), t: 's', s: { alignment: { horizontal: 'right' } } },
                            { v: "", t: 's', s: { alignment: { horizontal: 'right' } } },
                        ]);

                        agregarExcelM.push([
                            { v: "", t: 's', s: { alignment: { horizontal: 'center' } } },
                            { v: "Rendimiento: " + insumoAgrupadoTipoInsumo.unidad, t: 's', s: { alignment: { horizontal: 'left' } } },
                            { v: "", t: 's', s: { alignment: { horizontal: 'center' } } },
                            { v: "", t: 's', s: { alignment: { horizontal: 'right' } } },
                            { v: "", t: 's', s: { alignment: { horizontal: 'center' } } },
                            { v: insumoAgrupadoTipoInsumo.cantidad, t: 's', s: { alignment: { horizontal: 'right' } } },
                            { v: dineroDisabledMask(sumaImportes), t: 's', s: { alignment: { horizontal: 'right' } } },
                            { v: round2Decimals(sumaPorcentajes) + '%', t: 's', s: { alignment: { horizontal: 'right' } } },
                        ]);


                    }

                    agregarExcelM.push([])

                    agregarExcelM.push([
                        { v: "SUBTOTAL:", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'left' }, } },
                        { v: insumoAgrupadoTipoInsumo.nombre ? insumoAgrupadoTipoInsumo.nombre : 'MATERIALES', t: 's', s: { font: { bold: true }, alignment: { horizontal: 'left' }, } },
                        { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                        { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                        { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                        { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                        { v: dineroDisabledMask(sumaImportes), t: 's', s: { font: { bold: true }, border: topBorder, alignment: { horizontal: 'right' } } },
                        { v: round2Decimals(sumaPorcentajes) + '%', t: 's', s: { font: { bold: true }, border: topBorder, alignment: { horizontal: 'right' } } },
                    ]);

                }

                //Lineas de la matriz final

                //Costo directo

                agregarExcelM.push([
                    { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                    { v: "(CD) Costo directo", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'left' }, } },
                    { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                    { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                    { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                    { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                    { v: dineroDisabledMask(matrizCostoDirecto), t: 's', s: { font: { bold: true }, border: topBorder, alignment: { horizontal: 'right' } } },
                    { v: 100 + '%', t: 's', s: { font: { bold: true }, alignment: { horizontal: 'right' } } },
                ]);

                //Agregar Costos indirectos

                for (const calculoFinal of agregarCalculosFinales) {
                    agregarExcelM.push(calculoFinal)
                }


                agregarExcelM.push([
                    { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                    { v: "PRECIO UNITARIO (CD+CI)", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'left' }, } },
                    { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                    { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                    { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                    { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                    { v: dineroDisabledMask(matrizCostoTotal), t: 's', s: { border: topBorder, alignment: { horizontal: 'right' } } },
                    { v: "", t: 's', s: { border: topBorder, alignment: { horizontal: 'right' } } },
                ]);


                //Linea en blanco

                agregarExcelM.push([])



                mergesHoja.push(XLSXStyle.utils.decode_range("A" + (lineasDescargarHoja.length + 3) + ":H" + (lineasDescargarHoja.length + 3)))

                rowsHoja[lineasDescargarHoja.length + 2] = { hpx: 45 }

                lineasDescargarHoja = [...lineasDescargarHoja, ...agregarExcelM]


            }

            const traversePartidas = (partidas, keys = [], cantidadAcumulada = 1, partidaNombre = '') => {
                for (const [index, partida] of partidas.entries()) {
                    if (partida.children && partida.tipo !== 1) {
                        traversePartidas(partida.children, [...keys, index], cantidadAcumulada * partida.cantidad, partida.nombre);
                    }

                    if (partida.tipo === 1 && partida.nombre && partida.children) {

                        processMatriz(partida, round10Decimals(cantidadAcumulada), partidaNombre, keys.join('-'));

                    }
                }
            };

            if (partidas) {
                traversePartidas(partidas);
            }

            const worksheetMatrices = XLSXStyle.utils.aoa_to_sheet(lineasDescargarHoja);
            worksheetMatrices["!merges"] = mergesHoja
            worksheetMatrices["!rows"] = rowsHoja
            worksheetMatrices["!cols"] = [{ wpx: 70 }, { wpx: 220 }, { wpx: 70 }, { wpx: 100 }, { wpx: 70 }, { wpx: 70 }, { wpx: 100 }, { wpx: 70 }]
            const workbook = XLSXStyle.utils.book_new();
            XLSXStyle.utils.book_append_sheet(
                workbook,
                worksheetMatrices,
                "Matrices"
            );


            XLSXStyle.writeFile(
                workbook,
                `MATRICES ${nombre ? nombre : ''}.xlsx`
            );
            this.setState({ loadingReportes: false })

        }


    }

    descargarPresupuesto(tipo) {

        this.setState({ loadingReportes: true })

        const calcularConImpuestos = this.formRef.current.getFieldValue('calcularConImpuestos')
        const calcularConIndirectos = this.formRef.current.getFieldValue('calcularConIndirectos')
        const indirectos = this.formRef.current.getFieldValue('indirectos')
        const nombre = this.formRef.current.getFieldValue('nombre')
        const fechaInicio = this.formRef.current.getFieldValue('fechaInicio')
        const fechaFinal = this.formRef.current.getFieldValue('fechaFinal')

        if (tipo === 'pdf') {

            let lineasDescargarPdf = [];

            let totalPresupuesto = 0

            const traversePartidas = (partida, cantidadAcumulada = 1) => {
                if (partida.tipo === 0) {
                    lineasDescargarPdf.push([
                        { text: "", style: 'textoTabla', alignment: 'center' },
                        { text: partida.nombre, style: 'textoTablaBold', alignment: 'left' },
                        { text: "", style: 'textoTabla', alignment: 'center' },
                        { text: "", style: 'textoTabla', alignment: 'center' },
                        { text: "", style: 'textoTabla', alignment: 'center' },
                        { text: "", style: 'textoTabla', alignment: 'center' },
                        { text: "", style: 'textoTabla', alignment: 'center' },
                    ]);
                } else {

                    const matrizBuscar = this.state.todasMatrices.find(valueTM => valueTM._id.toString() === partida.nombre.toString());

                    const cantidadTotal = round10Decimals(cantidadAcumulada * partida.cantidad)

                    //Indirectos

                    let costoMatriz = calcularConImpuestos ? partida.costoCImpuestos : partida.costo


                    if (calcularConIndirectos && indirectos && indirectos.length > 0) {

                        for (const indirecto of indirectos) {

                            let costoIndirecto = 0

                            if (indirecto.calculo === 1) {

                                //Sobre Total

                                costoIndirecto = ((calcularConImpuestos ? partida.costoCImpuestos : partida.costo) * (indirecto.porcentaje / 100))

                            } else if (indirecto.calculo === 2) {

                                //Sobre Acumulado

                                costoIndirecto = (costoMatriz * (indirecto.porcentaje / 100))

                            }

                            costoMatriz = round2Decimals(costoMatriz + costoIndirecto)

                        }


                    }

                    lineasDescargarPdf.push([
                        { text: matrizBuscar.clave ? matrizBuscar.clave : '', style: 'textoTabla', alignment: 'center' },
                        { text: matrizBuscar.descripcion, style: 'textoTabla', alignment: 'left' },
                        { text: matrizBuscar.unidad.nombre, style: 'textoTabla', alignment: 'center' },
                        { text: cantidadTotal, style: 'textoTabla', alignment: 'right' },
                        { text: dineroDisabledMask(costoMatriz), style: 'textoTabla', alignment: 'right' },
                        { text: round2Decimals(costoMatriz * cantidadTotal), style: 'textoTabla', alignment: 'right' },
                        { text: "", style: 'textoTabla', alignment: 'right' },
                    ]);

                    totalPresupuesto += round2Decimals(costoMatriz * cantidadTotal)

                    return round2Decimals(costoMatriz * cantidadTotal)
                }

                let totalPartida = 0

                if (partida.children && partida.tipo !== 1) {
                    for (const partidaChildren of partida.children) {
                        const total = traversePartidas(partidaChildren, cantidadAcumulada * partida.cantidad);
                        totalPartida += total
                    }
                }

                if (partida.tipo === 0) {
                    lineasDescargarPdf.push([
                        { text: "", style: 'textoTabla', alignment: 'center' },
                        { text: "TOTAL " + partida.nombre, style: 'textoTablaBold', alignment: 'left' },
                        { text: "", style: 'textoTabla', alignment: 'center' },
                        { text: "", style: 'textoTabla', alignment: 'center' },
                        { text: "", style: 'textoTabla', alignment: 'center' },
                        { text: round2Decimals(totalPartida), style: 'textoTablaBold', alignment: 'right' },
                        { text: "", style: 'textoTabla', alignment: 'right' },
                    ]);
                }

                return totalPartida;
            }

            const partidas = [...this.state.partidas]

            if (partidas) {

                for (const partida of partidas) {
                    traversePartidas(partida, 1)
                }
            }

            for (const linea of lineasDescargarPdf) {

                if ((linea[5]?.text || linea[5]?.text === 0) && linea[6]?.text === "") {

                    const porcentaje = linea[5].text / totalPresupuesto * 100

                    linea[6].text = round2Decimals(porcentaje) + '%'
                    linea[5].text = dineroDisabledMask(linea[5].text)
                }
            }

            lineasDescargarPdf.push([{}, {}, {}, {}, {}, {}, {}]);


            lineasDescargarPdf.push([
                { text: "TOTAL DEL PRESUPUESTO", colSpan: 5, style: 'textoTablaBold', alignment: 'center' },
                {},
                {},
                {},
                {},
                { text: dineroDisabledMask(totalPresupuesto), colSpan: 2, style: 'textoTablaBold', alignment: 'center' },
                {},
            ]);


            lineasDescargarPdf.push([
                { text: numeroALetra(totalPresupuesto, 'MXN'), colSpan: 7, style: 'textoTablaBold', alignment: 'center' },
                {},
                {},
                {},
                {},
                {},
                {},
            ]);

            axios
                .post(
                    "presupuestosPU/selectDatosParaPdf",
                    { empresaId: this.props.empresaId },
                    {
                        headers: this.props.headersToken,
                    }
                )
                .then((res) => {
                    if (res.data.success === 2) {
                        const pdf = reportePresupuestoPUPdf({ fechaFinal, fechaInicio, presupuestos: lineasDescargarPdf, nombrePresupuesto: nombre, empresa: res.data.empresa, logoBiwo: res.data.logoBiwo });

                        pdfMake.createPdf(pdf).download(`PRESUPUESTO ${nombre ? nombre : ''}.pdf`);

                        this.setState({ loadingReportes: false })
                    } else {
                        message.warning('No se pudo descargar el reporte');
                    }
                });

        } else if (tipo === 'excel') {

            let mergesHoja = []

            let rowsHoja = []

            const allBorders = {
                top: { style: 'thin', color: { auto: 1 } },
                bottom: { style: 'thin', color: { auto: 1 } },
                left: { style: 'thin', color: { auto: 1 } },
                right: { style: 'thin', color: { auto: 1 } },
            }

            const topBorder = {
                top: { style: 'thin', color: { auto: 1 } },
            }

            // const noBorders = {
            //     top: { style: 'none' },
            //     bottom: { style: 'none' },
            //     left: { style: 'none' },
            //     right: { style: 'none' },
            // };

            let lineasDescargarHoja = [];

            //Encabezado

            lineasDescargarHoja.push([]);

            lineasDescargarHoja.push([
                { v: "PRESUPUESTO", t: 's', s: { font: { bold: true, sz: 14 }, alignment: { horizontal: 'center' } } },
                { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
            ]);

            mergesHoja.push(XLSXStyle.utils.decode_range("A2:G2"));

            lineasDescargarHoja.push([]);

            lineasDescargarHoja.push([
                { v: "Código", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' }, border: allBorders } },
                { v: "Concepto", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' }, border: allBorders } },
                { v: "Unidad", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' }, border: allBorders } },
                { v: "Cantidad", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' }, border: allBorders } },
                { v: "P. Unitario", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' }, border: allBorders } },
                { v: "Importe", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' }, border: allBorders } },
                { v: "%", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' }, border: allBorders } },
            ]);

            let totalPresupuesto = 0

            const traversePartidas = (partida, cantidadAcumulada = 1) => {
                if (partida.tipo === 0) {
                    lineasDescargarHoja.push([
                        { v: "", t: 's', s: { alignment: { horizontal: 'left' } } },
                        { v: partida.nombre, t: 's', s: { font: { bold: true }, alignment: { horizontal: 'left' } } },
                        { v: "", t: 's', s: { alignment: { horizontal: 'left' } } },
                        { v: "", t: 's', s: { alignment: { horizontal: 'left' } } },
                        { v: "", t: 's', s: { alignment: { horizontal: 'left' } } },
                        { v: "", t: 's', s: { alignment: { horizontal: 'left' } } },
                        { v: "", t: 's', s: { alignment: { horizontal: 'left' } } },
                    ]);
                } else {

                    const matrizBuscar = this.state.todasMatrices.find(valueTM => valueTM._id.toString() === partida.nombre.toString());

                    const cantidadTotal = round10Decimals(cantidadAcumulada * partida.cantidad)

                    rowsHoja[lineasDescargarHoja.length] = { hpx: 45 }

                    //Indirectos

                    let costoMatriz = calcularConImpuestos ? partida.costoCImpuestos : partida.costo


                    if (calcularConIndirectos && indirectos && indirectos.length > 0) {

                        for (const indirecto of indirectos) {

                            let costoIndirecto = 0

                            if (indirecto.calculo === 1) {

                                //Sobre Total

                                costoIndirecto = ((calcularConImpuestos ? partida.costoCImpuestos : partida.costo) * (indirecto.porcentaje / 100))

                            } else if (indirecto.calculo === 2) {

                                //Sobre Acumulado

                                costoIndirecto = (costoMatriz * (indirecto.porcentaje / 100))

                            }

                            costoMatriz = round2Decimals(costoMatriz + costoIndirecto)

                        }


                    }

                    lineasDescargarHoja.push([
                        { v: matrizBuscar.clave ? matrizBuscar.clave : '', t: 's', s: { alignment: { horizontal: 'left', wrapText: true, vertical: 'top' } } },
                        { v: matrizBuscar.descripcion, t: 's', s: { alignment: { horizontal: 'left', wrapText: true, vertical: 'top' }, } },
                        { v: matrizBuscar.unidad.nombre, t: 's', s: { alignment: { horizontal: 'left', vertical: 'top' } } },
                        { v: cantidadTotal, t: 's', s: { alignment: { horizontal: 'right', vertical: 'top' } } },
                        { v: dineroDisabledMask(costoMatriz), t: 's', s: { alignment: { horizontal: 'right', vertical: 'top' } } },
                        { v: round2Decimals(costoMatriz * cantidadTotal), t: 's', s: { alignment: { horizontal: 'right', vertical: 'top' } } },
                        { v: "", t: 's', s: { alignment: { horizontal: 'right', vertical: 'top' } } },
                    ]);

                    totalPresupuesto += round2Decimals(costoMatriz * cantidadTotal)

                    return round2Decimals(costoMatriz * cantidadTotal)
                }

                let totalPartida = 0

                if (partida.children && partida.tipo !== 1) {
                    for (const partidaChildren of partida.children) {
                        const total = traversePartidas(partidaChildren, cantidadAcumulada * partida.cantidad);
                        totalPartida += total
                    }
                }

                if (partida.tipo === 0) {
                    lineasDescargarHoja.push([
                        { v: "", t: 's', s: { alignment: { horizontal: 'left' } } },
                        { v: "TOTAL " + partida.nombre, t: 's', s: { font: { bold: true }, alignment: { horizontal: 'left' } } },
                        { v: "", t: 's', s: { alignment: { horizontal: 'left' } } },
                        { v: "", t: 's', s: { alignment: { horizontal: 'left' } } },
                        { v: "", t: 's', s: { alignment: { horizontal: 'left' } } },
                        { v: round2Decimals(totalPartida), t: 's', s: { font: { bold: true }, alignment: { horizontal: 'right' }, border: topBorder } },
                        { v: "", t: 's', s: { alignment: { horizontal: 'right' }, font: { bold: true }, border: topBorder } },
                    ]);
                }

                return totalPartida;
            }

            const partidas = [...this.state.partidas]

            if (partidas) {

                for (const partida of partidas) {
                    traversePartidas(partida, 1)
                }
            }

            for (const linea of lineasDescargarHoja) {

                if ((linea[5]?.v || linea[5]?.v === 0) && linea[6]?.v === "") {

                    const porcentaje = linea[5].v / totalPresupuesto * 100

                    linea[6].v = round2Decimals(porcentaje) + '%'
                    linea[5].v = dineroDisabledMask(linea[5].v)
                }
            }

            lineasDescargarHoja.push([]);


            lineasDescargarHoja.push([
                { v: "TOTAL DEL PRESUPUESTO", t: 's', s: { font: { bold: true, sz: 14 }, alignment: { horizontal: 'left' } } },
                { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                { v: dineroDisabledMask(totalPresupuesto), t: 's', s: { font: { bold: true, sz: 14 }, alignment: { horizontal: 'right' } } },
                { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
            ]);

            mergesHoja.push(XLSXStyle.utils.decode_range("A" + (lineasDescargarHoja.length) + ":E" + (lineasDescargarHoja.length)));

            lineasDescargarHoja.push([
                { v: numeroALetra(totalPresupuesto, 'MXN'), t: 's', s: { font: { bold: true, sz: 14 }, alignment: { horizontal: 'left' } } },
                { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
                { v: "", t: 's', s: { font: { bold: true, sz: 14 }, alignment: { horizontal: 'center' } } },
                { v: "", t: 's', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
            ]);

            mergesHoja.push(XLSXStyle.utils.decode_range("A" + (lineasDescargarHoja.length) + ":G" + (lineasDescargarHoja.length)));

            const worksheetMatrices = XLSXStyle.utils.aoa_to_sheet(lineasDescargarHoja);
            worksheetMatrices["!merges"] = mergesHoja
            worksheetMatrices["!rows"] = rowsHoja
            worksheetMatrices["!cols"] = [{ wpx: 70 }, { wpx: 220 }, { wpx: 70 }, { wpx: 70 }, { wpx: 100 }, { wpx: 100 }, { wpx: 70 }]
            const workbook = XLSXStyle.utils.book_new();
            XLSXStyle.utils.book_append_sheet(
                workbook,
                worksheetMatrices,
                "Presupuesto"
            );


            XLSXStyle.writeFile(
                workbook,
                `PRESUPUESTO ${nombre ? nombre : ''}.xlsx`
            );

        }

        this.setState({ loadingReportes: false })

    }

    async calcularPresupuestoManual() {
        if (!this.state.confirmado) {
            this.setState({ loading: true })

            await this.consultarPPresupuesto()


            const partidas = [...this.state.partidas]


            const traversePartidas = (partida) => {
                if (partida.tipo === 0) {
                    return {
                        ...partida,
                        children: partida?.children && partida?.children?.length > 0 ? partida.children.map((value, index) => traversePartidas(value)) : [],
                    }
                } else {

                    let childrenMatriz = []
                    let costoMatriz = 0
                    let unidad = ''

                    if (partida.nombre) {

                        const matriz = this.state.todasMatrices.find(valueM => valueM._id.toString() === partida.nombre.toString())
                        unidad = matriz.unidad.nombre

                        const obtenerHijosMatriz = (valueMatriz, indexMatriz, keyMatriz = partida.key) => {
                            const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === valueMatriz.insumoId.toString())
                            const costoTotal = round10Decimals((insumoBuscar.costo ? insumoBuscar.costo : valueMatriz.costo) * (insumoBuscar.calculo ? (valueMatriz.cantidad / 100) : valueMatriz.cantidad))
                            costoMatriz += round10Decimals((insumoBuscar.costo ? insumoBuscar.costo : valueMatriz.costo) * (insumoBuscar.calculo ? (valueMatriz.cantidad / 100) : valueMatriz.cantidad))

                            let costoCImpuestos

                            if (!insumoBuscar.matriz) {

                                let totalImpuestos = 0;

                                if (insumoBuscar.impuestos && insumoBuscar.impuestos.length > 0) {

                                    for (const valueImpuesto of insumoBuscar.impuestos) {
                                        let totalImpuesto;
                                        if (valueImpuesto.tasaCuota === 1) {
                                            totalImpuesto = round6Decimals(insumoBuscar.costo * (valueImpuesto.monto / 100));
                                        } else if (valueImpuesto.tasaCuota === 2) {
                                            totalImpuesto =
                                                round6Decimals((insumoBuscar.costo + totalImpuestos) * (valueImpuesto.monto / 100));
                                        } else if (valueImpuesto.tasaCuota === 3) {
                                            totalImpuesto = round6Decimals(valueMatriz.cantidad * valueImpuesto.monto);
                                        } else if (valueImpuesto.tasaCuota === 4) {
                                            totalImpuesto = 0
                                        } else if (valueImpuesto.tasaCuota === 5) {
                                            totalImpuesto = 0
                                        }

                                        totalImpuestos += totalImpuesto;
                                    }
                                }

                                costoCImpuestos = round2Decimals((insumoBuscar.costo ? insumoBuscar.costo : valueMatriz.costo) + totalImpuestos)
                            } else {
                                costoCImpuestos = (insumoBuscar.costo ? insumoBuscar.costo : valueMatriz.costo)
                            }

                            return {
                                insumoMatriz: true,
                                calculo: insumoBuscar.calculo,
                                nombre: insumoBuscar?.descripcion,
                                unidad: insumoBuscar?.unidad?.nombre,
                                children: (insumoBuscar.matriz && insumoBuscar.insumosUtilizar) ? insumoBuscar.insumosUtilizar.map((valueHijo, indexHijo) => obtenerHijosMatriz(valueHijo, indexHijo, keyMatriz + '-' + indexMatriz)) : undefined,
                                costoTotal: costoTotal,
                                costo: (insumoBuscar.costo ? insumoBuscar.costo : valueMatriz.costo),
                                costoTotalCImpuestos: round2Decimals(costoCImpuestos * (insumoBuscar.calculo ? (valueMatriz.cantidad / 100) : valueMatriz.cantidad)),
                                costoCImpuestos,
                                cantidad: valueMatriz.cantidad,
                                insumoId: valueMatriz.insumoId,
                                key: keyMatriz + '-' + indexMatriz,
                            }
                        }

                        childrenMatriz = matriz.insumosUtilizar.map((valueMatriz, indexMatriz) => obtenerHijosMatriz(valueMatriz, indexMatriz))
                    }

                    return {
                        ...partida,
                        children: childrenMatriz,
                        costo: costoMatriz,
                        costoTotal: round2Decimals(costoMatriz * (partida.cantidad || 0)),
                        unidad,
                    }
                }
            }



            const nuevasPartidas = partidas.map((partida) => {
                return traversePartidas(partida)
            })



            const nuevasPartidas2 = this.calcularPresupuesto(nuevasPartidas)

            this.setState({ partidas: nuevasPartidas2, loading: false })
        }


    }


    render() {

        const columns = [
            {
                title: "Clave",
                dataIndex: "key",
                key: "key",
                width: "15%",
                render: (value) => {
                    const arrayKey = value.split('-').map((value) => Number(value) + 1)
                    let texto = ''

                    for (const [index, key] of arrayKey.entries()) {
                        if (index === (arrayKey.length - 1)) {
                            texto += key
                        } else {
                            texto += key + '-'
                        }
                    }

                    return texto
                }
            },
            {
                title: "Tipo",
                dataIndex: "tipo",
                key: "tipo",
                render: (value) => {
                    if (this.state.confirmado) {
                        return value === 0 ? 'Partida' : 'Matriz'
                    } else {
                        return value
                    }
                },
                width: "10%",
            },
            {
                title: "Nombre",
                dataIndex: "nombre",
                key: "nombre",
                render: (value, record) => {
                    if (this.state.confirmado) {
                        if (record.tipo === 1) {
                            const matriz = this.state.todasMatrices.find(valueTM => valueTM._id.toString() === value.toString())
                            if (matriz) {
                                return matriz.descripcion
                            } else {
                                return ''
                            }
                        } else {
                            return value
                        }
                    } else {
                        return value
                    }
                },
                width: "25%",
            },
            {
                title: "Costo",
                dataIndex: "costo",
                key: "costo",
                sorter: (a, b) => {
                    if (a.costo < b.costo) {
                        return -1;
                    }
                    if (a.costo > b.costo) {
                        return 1;
                    }
                    return 0;
                },
                render: (value) => dineroDisabledMask(value),
                width: "8%",
                align: 'right'
            },
            {
                title: "Unidad",
                dataIndex: "unidad",
                key: "unidad",
                width: "8%",
                align: 'left'
            },
            {
                title: "Cantidad",
                dataIndex: "cantidad",
                key: "cantidad",
                width: "8%",
                align: 'left',
                render: (value, record) => {
                    if (record.calculo) {
                        return value + ' %'
                    } else {
                        return value
                    }
                }
            },
            {
                title: "Costo",
                dataIndex: "costoTotal",
                key: "costoTotal",
                sorter: (a, b) => {
                    if (a.costoTotal < b.costoTotal) {
                        return -1;
                    }
                    if (a.costoTotal > b.costoTotal) {
                        return 1;
                    }
                    return 0;
                },
                render: (value) => dineroDisabledMask(value),
                width: "8%",
                align: 'right'
            },
            {
                title: "Costo C/I",
                dataIndex: "costoTotalCImpuestos",
                key: "costoTotalCImpuestos",
                sorter: (a, b) => {
                    if (a.costoTotalCImpuestos < b.costoTotalCImpuestos) {
                        return -1;
                    }
                    if (a.costoTotalCImpuestos > b.costoTotalCImpuestos) {
                        return 1;
                    }
                    return 0;
                },
                render: (value) => dineroDisabledMask(value),
                width: "8%",
                align: 'right'
            },
            {
                title: "Accion",
                dataIndex: "key",
                key: "key",
                width: "10%",
                render: (value, record) => {
                    if (record.tipo === 0 && !record?.insumoMatriz && value.split('-').length < 5 && !this.state.confirmado) {
                        return (
                            <>
                                <PlusCircleOutlined
                                    onClick={() => this.agregarPartida(value)}
                                    style={{
                                        color: "green",
                                        marginRight: 20
                                    }}
                                />
                                <MinusCircleOutlined
                                    onClick={() => this.quitarPartida(value)}
                                    style={{
                                        color: "red"
                                    }}
                                />
                            </>
                        )
                    } else if (!record?.insumoMatriz && !this.state.confirmado) {
                        return (
                            <>
                                <MinusCircleOutlined
                                    onClick={() => this.quitarPartida(value)}
                                    style={{
                                        color: "red"
                                    }}
                                />
                            </>
                        )
                    } else {
                        return null
                    }
                }
            }
        ]


        const mergedColumns = columns.map((col) => {
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    dataIndex: col?.dataIndex,
                    title: col?.title,
                }),
            };
        });

        const menuMatrices = (
            <Menu>
                <Menu.Item key="1" onClick={() => this.descargarMatrices('excel')} icon={<FileExcelOutlined />}>
                    Excel
                </Menu.Item>
                <Menu.Item key="2" onClick={() => this.descargarMatrices('pdf')} icon={<FilePdfOutlined />}>
                    Pdf
                </Menu.Item>
            </Menu>
        );

        const menuPresupuesto = (
            <Menu>
                <Menu.Item key="1" onClick={() => this.descargarPresupuesto('excel')} icon={<FileExcelOutlined />}>
                    Excel
                </Menu.Item>
                <Menu.Item key="2" onClick={() => this.descargarPresupuesto('pdf')} icon={<FilePdfOutlined />}>
                    Pdf
                </Menu.Item>
            </Menu>
        );

        let familiaAnteriorId
        let familiaAnteriorNombre

        return (
            <>
                <PageHeader
                    onBack={this.atras.bind(this)}
                    title="Presupuesto"
                    style={{
                        border: "1px solid rgb(235, 237, 240)",
                        backgroundColor: "white",
                        marginBottom: 10,
                    }}
                    extra={
                        <ButtonItem
                            blanco
                            icon={<CalculatorOutlined />}
                            disabled={this.state.confirmado}
                            onClick={() => this.calcularPresupuestoManual()}
                        >
                            Calcular
                        </ButtonItem>
                    }
                />
                <div style={{ backgroundColor: "white", padding: 20, paddingTop: 5 }}>
                    <Spin spinning={this.state.loading}>
                        <Form
                            ref={this.formRef}
                            name="presupuestoPU"
                            layout
                            bottom={
                                <div style={{ display: "flex", justifyContent: "right" }}>
                                    <Space>
                                        {!this.state.confirmado && this.props.permisoConfirmarPresupuestoPU ?
                                            <Popconfirm
                                                title="Seguro que quieres confirmar este presupuesto, al confirmarlo no se podran editar los datos de las partidas"
                                                onConfirm={() => this.confirmarPresupuesto()}
                                                okText="Si"
                                                cancelText="No"
                                            >
                                                <ButtonItem
                                                    verde
                                                    style={{ display: "block", margin: "0 0 0 auto" }}
                                                >
                                                    Confirmar
                                                </ButtonItem>
                                            </Popconfirm>
                                            : null}
                                        <ButtonItem
                                            type="primary"
                                            htmlType="submit"
                                            style={{ display: "block", margin: "0 0 0 auto" }}
                                        >
                                            Guardar
                                        </ButtonItem>
                                    </Space>
                                </div>
                            }
                            onFinish={this.onFinish.bind(this)}
                            onFinishFailed={this.onFinishFailed}
                        // onFieldsChange={this.onFieldsChange.bind(this)}
                        >
                            <Tabs defaultActiveKey={1} onTabClick={(key) => {
                                if (key === '3') {
                                    this.arbolPresupuesto()
                                }
                                if (key === '4') {
                                    this.explosionInsumos()
                                    this.setState({ valueExplosion: [] })
                                }
                                if (key === '6') {
                                    this.hacerDiagramaGantt()
                                }
                                // if (key === '5') {
                                //     this.tablaPresupuesto()
                                // }
                            }}>
                                <TabPane tab="General" tabKey={1} key={1} forceRender>
                                    <Row>
                                        <Col span={12}>

                                            <FormItem
                                                label="Nombre"
                                                name="nombre"
                                                required
                                                margin
                                                pattern
                                            >
                                                <InputItem
                                                    placeholder="Nombre"
                                                />
                                            </FormItem>
                                            <FormItem
                                                label="Fecha Inicio"
                                                name="fechaInicio"
                                                required
                                                margin
                                                initialValue={moment()}
                                            >
                                                <DatePickerItem allowClear={false} placeholder="Fecha Inicio" />
                                            </FormItem>
                                            <FormItem
                                                label="Fecha Final"
                                                name="fechaFinal"
                                                required
                                                margin
                                                initialValue={moment()}
                                            >
                                                <DatePickerItem allowClear={false} placeholder="Fecha Final" />
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem label="Proyecto" name="proyectoId" margin required>
                                                <SelectItem disabled={this.state.confirmado} placeholder="Proyecto">
                                                    {this.state.objetoProyectos}
                                                </SelectItem>
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </TabPane>

                                <TabPane tab="Partidas" tabKey='5' key='5' forceRender>
                                    <Spin spinning={this.state.loadingExplosion}>
                                        {!this.state.confirmado ?
                                            <ButtonItem onClick={() => this.agregarPartida()} style={{ marginBottom: 10 }}>Agregar partida</ButtonItem>
                                            : undefined}
                                        <TableItem
                                            components={this.state.confirmado ? undefined : {
                                                body: {
                                                    cell: this.Cell.bind(this)
                                                },
                                            }}
                                            rowKey={(value) => value.key}
                                            size="small"
                                            expandable={{
                                                expandedRowKeys: this.state.expandedRowKeys,
                                                onExpandedRowsChange: this.onExpandedRowsChange.bind(this)
                                            }}
                                            dataSource={this.state.partidas}
                                            columns={mergedColumns}
                                        />
                                    </Spin>
                                </TabPane>

                                <TabPane tab='Indirectos' tabKey='2' key='2' forceRender>
                                    <Row>
                                        <Col span={24}>
                                            <FormInitial.List
                                                name="indirectos"
                                            >
                                                {(fields, { add, remove }) => (
                                                    <>
                                                        <table className="table">
                                                            {fields.length > 0 ? (
                                                                <thead>
                                                                    <tr>
                                                                        <th className="th-border" style={{ width: "30%" }}>
                                                                            Nombre
                                                                        </th>
                                                                        <th className="th-border" style={{ width: "30%" }}>
                                                                            Calculo
                                                                        </th>
                                                                        <th className="th-border" style={{ width: "30%" }}>
                                                                            Porcentaje
                                                                        </th>
                                                                        <th
                                                                            className="th-border-sin-right"
                                                                            style={{ width: "10%" }}
                                                                        >
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                            ) : null}
                                                            {fields.map(({ name }, arrayKey) => (
                                                                <tbody key={arrayKey}>
                                                                    <tr>
                                                                        <td className="td-border">
                                                                            <FormItem
                                                                                name={[name, "nombre"]}
                                                                                fieldKey={[arrayKey, "nombre"]}
                                                                                required
                                                                                margin
                                                                                noStyle
                                                                            >
                                                                                <InputItem placeholder="Nombre" />
                                                                            </FormItem>
                                                                        </td>
                                                                        <td className="td-border">
                                                                            <FormItem
                                                                                name={[name, "calculo"]}
                                                                                fieldKey={[arrayKey, "calculo"]}
                                                                                initialValue={1}
                                                                                required
                                                                                margin
                                                                                noStyle
                                                                            >
                                                                                <SelectItem width='100%' placeholder="Calculo">
                                                                                    <Option value={1}>Total</Option>
                                                                                    <Option value={2}>Acumulado</Option>
                                                                                </SelectItem>
                                                                            </FormItem>
                                                                        </td>
                                                                        <td className="td-border">
                                                                            <FormItem
                                                                                name={[name, "porcentaje"]}
                                                                                fieldKey={[arrayKey, "porcentaje"]}
                                                                                required
                                                                                margin
                                                                                noStyle
                                                                            >
                                                                                <InputNumberItem porcentaje />
                                                                            </FormItem>
                                                                        </td>
                                                                        <td className="td-border">
                                                                            <div style={{ textAlign: 'center' }}>
                                                                                <MinusCircleOutlined
                                                                                    onClick={() => remove(name)}
                                                                                />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            ))}
                                                        </table>
                                                        <div style={{ textAlign: 'center', marginTop: 10 }}>
                                                            <ButtonItem
                                                                type="primary"
                                                                onClick={() => add()}
                                                                icon={<PlusOutlined />}
                                                            >
                                                                Agregar
                                                            </ButtonItem>
                                                        </div>
                                                    </>
                                                )}

                                            </FormInitial.List>
                                        </Col>
                                    </Row>
                                </TabPane>

                                <TabPane tab='Explosion de insumos' tabKey='4' key='4'>
                                    <Spin spinning={this.state.loadingExplosion}>
                                        <Row>
                                            <Col span={24}>
                                                <TreeSelect
                                                    showSearch
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    // value={value}
                                                    dropdownStyle={{
                                                        maxHeight: 400,
                                                        overflow: 'auto',
                                                    }}
                                                    multiple
                                                    placeholder="Seleccionar"
                                                    allowClear
                                                    treeDefaultExpandAll
                                                    onDropdownVisibleChange={(v) => this.onChangeExplosion(this.state.valueExplosion, !v)}
                                                    onDeselect={(v, value) => {
                                                        let arrayKeys = [...this.state.valueExplosion]
                                                        let index = arrayKeys.indexOf(value.key);

                                                        // If the element is found, remove it
                                                        if (index !== -1) {
                                                            arrayKeys.splice(index, 1);
                                                        }
                                                        this.onChangeExplosion(arrayKeys, true)
                                                    }}
                                                    value={this.state.valueExplosion}
                                                    onChange={(value) => this.onChangeExplosion(value, false)}
                                                    treeData={this.state.partidasArbol}
                                                />
                                            </Col>
                                        </Row>
                                        <table className="table">
                                            {this.state.insumosExplosionados.length > 0 ?
                                                <thead>
                                                    <tr>
                                                        <th className="th-border" style={{ width: "30%" }}>
                                                            Insumo
                                                        </th>
                                                        <th className="th-border" style={{ width: "10%" }}>
                                                            Unidad
                                                        </th>
                                                        <th className="th-border" style={{ width: "10%" }}>
                                                            Costo Unitario
                                                        </th>
                                                        <th className="th-border" style={{ width: "10%" }}>
                                                            Inventario
                                                        </th>
                                                        <th className="th-border" style={{ width: "10%" }}>
                                                            Cantidad
                                                        </th>
                                                        <th className="th-border" style={{ width: "10%" }}>
                                                            Min. C
                                                        </th>
                                                        {this.state.confirmado ?
                                                            <>
                                                                <th className="th-border" style={{ width: "10%" }}>
                                                                    Pedido
                                                                </th>
                                                                <th className="th-border" style={{ width: "10%" }}>
                                                                    Pedir
                                                                    <Tooltip title='Calcular lo sobrante'>
                                                                        <CalculatorOutlined onClick={() => this.pedirTotales()} style={{ marginLeft: '5px', color: '#00b5e2' }} />
                                                                    </Tooltip>
                                                                    <Tooltip title='Calcular con minimos de compra'>
                                                                        <CalculatorOutlined onClick={() => this.pedirMinCompraTotales()} style={{ marginLeft: '5px', color: 'red' }} />
                                                                    </Tooltip>
                                                                </th>
                                                                <th className="th-border" style={{ width: "10%" }}>
                                                                    Costo
                                                                </th>
                                                            </>
                                                            : null}
                                                        <th className="th-border" style={{ width: "10%" }}>
                                                            Costo T
                                                        </th>
                                                    </tr>
                                                </thead>
                                                : null}
                                            {this.state.insumosExplosionados.map((valueInsumo, index) => {
                                                const mostrarFamilia = valueInsumo.familiaId !== familiaAnteriorId || index === 0;
                                                familiaAnteriorId = valueInsumo.familiaId;
                                                familiaAnteriorNombre = valueInsumo.familiaNombre;
                                                return (
                                                    <tbody key={index}>
                                                        {mostrarFamilia ?
                                                            <tr>
                                                                <td className="td-border" colSpan="7" style={{ textAlign: 'left', fontSize: 20, fontWeight: "bold" }}>
                                                                    <p style={{ margin: 5 }}>{familiaAnteriorNombre ? familiaAnteriorNombre : 'Sin Familia'}</p>
                                                                </td>
                                                                {this.state.confirmado ?
                                                                    <td className="th-border" style={{ width: "10%" }}>
                                                                        <Tooltip title='Calcular lo sobrante de la familia'>
                                                                            <CalculatorOutlined onClick={() => this.pedirTotalesFamilia(valueInsumo.familiaId)} style={{ marginLeft: '5px', color: '#00b5e2' }} />
                                                                        </Tooltip>
                                                                        <Tooltip title='Calcular con minimos de compra de la familia'>
                                                                            <CalculatorOutlined onClick={() => this.pedirMinCompraTotalesFamilia(valueInsumo.familiaId)} style={{ marginLeft: '5px', color: 'red' }} />
                                                                        </Tooltip>
                                                                    </td>
                                                                    : null}
                                                                {this.state.confirmado &&
                                                                    <td className="td-border" colSpan={2}>
                                                                    </td>
                                                                }
                                                            </tr>
                                                            : null}
                                                        <tr>
                                                            <td className="td-border" style={{ textAlign: 'left', wordBreak: 'keep-all', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                                                <p style={{ margin: 5 }}>{valueInsumo.nombre}</p>
                                                            </td>
                                                            <td className="td-border" style={{ textAlign: 'center' }}>
                                                                <p style={{ margin: 5 }}>{valueInsumo.unidad}</p>
                                                            </td>
                                                            <td className="td-border" style={{ textAlign: 'right' }}>
                                                                <p style={{ margin: 5 }}>{dineroDisabledMask(valueInsumo.costo)}</p>
                                                            </td>
                                                            <td className="td-border" style={{ textAlign: 'right' }}>
                                                                <p style={{ margin: 5 }}>{valueInsumo.cantidadProyecto}</p>
                                                            </td>
                                                            <td className="td-border" style={{ textAlign: 'right' }}>
                                                                <p style={{ margin: 5 }}>{round4Decimals(valueInsumo.calculo ? ((valueInsumo.cantidad * 100) + ' %') : valueInsumo.cantidad)}</p>
                                                            </td>
                                                            <td className="td-border" style={{ textAlign: 'right' }}>
                                                                <p style={{ margin: 5 }}>{valueInsumo.catidadMinCompra}</p>
                                                            </td>
                                                            {this.state.confirmado ?
                                                                <>
                                                                    <td className="td-border" style={{ textAlign: 'right' }}>
                                                                        <p style={{ margin: 5 }}>{round4Decimals(valueInsumo.cantidadPedida)}</p>
                                                                    </td>
                                                                    <td className="td-border" style={{ textAlign: 'right' }}>
                                                                        <FormItem
                                                                            name={"pedir" + valueInsumo.insumoId}
                                                                            noStyle
                                                                            numberCero
                                                                            type='number'
                                                                            max={round4Decimals((valueInsumo.cantidad - (valueInsumo.cantidadPedida || 0)) > 0 ? valueInsumo.cantidad - (valueInsumo.cantidadPedida || 0) : 0)}
                                                                        >
                                                                            <InputNumberItem
                                                                                onChange={this.cambioPedir.bind(this)}
                                                                                sinBorde
                                                                                style={{
                                                                                    backgroundColor: 'transparent'
                                                                                }}
                                                                                placeholder={"Max: " + round4Decimals((valueInsumo.cantidad - (valueInsumo.cantidadPedida || 0)) > 0 ? (valueInsumo.cantidad - (valueInsumo.cantidadPedida || 0)) : 0)}
                                                                            />
                                                                        </FormItem>
                                                                    </td>
                                                                    <td className="td-border" style={{ textAlign: 'right' }}>
                                                                        <p style={{ margin: 5 }}>{dineroDisabledMask(valueInsumo.costoPedir)}</p>
                                                                    </td>
                                                                </>
                                                                : null}
                                                            <td className="td-border" style={{ textAlign: 'right' }}>
                                                                <p style={{ margin: 5 }}>{dineroDisabledMask(valueInsumo.costoTotal)}</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                )
                                            })}

                                            <tbody key='T'>
                                                <tr>
                                                    <td className="td-border" style={{ textAlign: 'left' }}>
                                                        <p style={{ margin: 5 }}>Total:</p>
                                                    </td>
                                                    <td className="td-border" style={{ textAlign: 'center' }}>
                                                        <p style={{ margin: 5 }}></p>
                                                    </td>
                                                    <td className="td-border" style={{ textAlign: 'center' }}>
                                                        <p style={{ margin: 5 }}></p>
                                                    </td>
                                                    <td className="td-border" style={{ textAlign: 'center' }}>
                                                        <p style={{ margin: 5 }}></p>
                                                    </td>
                                                    <td className="td-border" style={{ textAlign: 'center' }}>
                                                        <p style={{ margin: 5 }}></p>
                                                    </td>
                                                    {this.state.confirmado ?
                                                        <>
                                                            <td className="td-border" style={{ textAlign: 'right' }}>
                                                                <p style={{ margin: 5 }}></p>
                                                            </td>
                                                            <td className="td-border" style={{ textAlign: 'right' }}>
                                                                <p style={{ margin: 5 }}></p>
                                                            </td>
                                                            <td className="td-border" style={{ textAlign: 'right' }}>
                                                                <p style={{ margin: 5 }}></p>
                                                            </td>
                                                        </>
                                                        : null}
                                                    <td className="td-border" style={{ textAlign: 'right' }}>
                                                        <p style={{ margin: 5 }}>{dineroDisabledMask(this.state.totalCostoPedir)}</p>
                                                    </td>
                                                    <td className="td-border" style={{ textAlign: 'right' }}>
                                                        <p style={{ margin: 5 }}>{dineroDisabledMask(this.state.costoTotalIE)}</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {this.state.confirmado && this.state.partidasArbol.length > 0 ?
                                            <Popconfirm
                                                title="Seguro que quieres generar una requisicion de estos insumos, si la cantidad sobresale del presupuesto se pedira una autorizacion extra"
                                                onConfirm={() => this.requerirInsumos()}
                                                okText="Si"
                                                cancelText="No"
                                            >
                                                {this.props.permisoRequerirInsPresupuestoPU &&
                                                    <ButtonItem
                                                        blanco
                                                        style={{ display: "block", margin: "10px 0px 0 auto" }}
                                                    >
                                                        Requerir insumos
                                                    </ButtonItem>}
                                            </Popconfirm>
                                            : null}
                                    </Spin>
                                </TabPane>

                                <TabPane tab='Diagrama de gantt' tabKey="6" key="6">
                                    <Gantt
                                        onDateChange={this.handleTaskChange.bind(this)}
                                        onExpanderClick={this.onExpanderClick.bind(this)}
                                        tasks={this.state.tasksDiagrama}
                                        columnWidth={20}
                                        rowHeight={35}
                                        fontSize={12}
                                        viewMode="Day"
                                        todayColor="#cadde1"
                                        TaskListHeader={({ headerHeight }) => (
                                            <div style={{ display: 'flex', fontFamily: 'Arial, Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue"', fontSize: '14px', height: headerHeight, border: "#e6e4e4 1px solid" }}>
                                                <div style={{ flex: 1, padding: 12 }}>Partida</div>
                                                <div style={{ flex: 1, padding: 12 }}>Inicio</div>
                                                <div style={{ flex: 1, padding: 12 }}>Fin</div>
                                            </div>
                                        )}
                                        locale="es"
                                    // TaskListTable={(props) => <CustomTaskListTable {...props} tasks={this.state.tasksDiagrama} />}

                                    />
                                </TabPane>

                                <TabPane tab='Reportes' tabKey='7' key='7'>
                                    <Spin spinning={this.state.loadingReportes}>
                                        <Row>
                                            <Col span={12}>
                                                <FormItem
                                                    label="Con Impuestos"
                                                    name="calcularConImpuestos"
                                                    margin
                                                    tooltip={{ title: "Calcular los reportes con impuestos del costo." }}
                                                    initialValue={false}
                                                    valuePropName="checked"
                                                >
                                                    <SwitchItem />
                                                </FormItem>
                                                <FormItem
                                                    label="Con Indirectos"
                                                    name="calcularConIndirectos"
                                                    margin
                                                    tooltip={{ title: "Calcular los reportes con los indirectos." }}
                                                    initialValue={true}
                                                    valuePropName="checked"
                                                >
                                                    <SwitchItem />
                                                </FormItem>
                                            </Col>
                                            <Col span={12}>
                                                <Space>
                                                    <Dropdown overlay={menuMatrices} trigger={['click']}>
                                                        <ButtonItem blanco type='primary'>
                                                            Descargar Matrices <DownOutlined />
                                                        </ButtonItem>
                                                    </Dropdown>
                                                    <Dropdown overlay={menuPresupuesto} trigger={['click']}>
                                                        <ButtonItem blanco type='primary'>
                                                            Descargar Presupuesto <DownOutlined />
                                                        </ButtonItem>
                                                    </Dropdown>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Spin>
                                </TabPane>



                                {this.state.objectExplosiones}
                            </Tabs>
                        </Form>
                    </Spin>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        headersToken: state.user.headersToken,
        empresaId: state.empresa.id,
        permisoConfirmarPresupuestoPU:
            state.user.tipo === 0 ||
            state.user.permisos.find(
                (valueTodos) =>
                    valueTodos.permisoId.toString() === "67509579798d4e004368724f"
            )?.activado,
        permisoRequerirInsPresupuestoPU:
            state.user.tipo === 0 ||
            state.user.permisos.find(
                (valueTodos) =>
                    valueTodos.permisoId.toString() === "6750958e798d4e0043687250"
            )?.activado,
    };
};

export default connect(mapStateToProps)(PresupuestoPU);
