import {
    message,
    Spin,
    Row,
    Col,
    Select,
    Form as FormInitial,
    Space,
    Checkbox,
    Popconfirm,
} from "antd";
import React, { Component } from "react";
import {
    Form,
    FormItem,
    InputNumberItem,
    SelectItem,
    round2Decimals,
    round6Decimals,
    dineroDisabledMask,
    ModalItem,
    DatePickerItem,
    BoxItem,
    HeaderItem,
    InputItem,
    ButtonItem,
    TextAreaItem,
} from "../../../../Components/Items";
import moment from "moment";
import axios from "axios";
import { connect } from "react-redux";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import MandarCorreoCotizacion from "../MandarCorreoCotizacion/MandarCorreoCotizacion";
import { SendOutlined } from "@ant-design/icons";
import GenerarVenta from "../GenerarVenta/GenerarVenta";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const { Option } = Select;

class VerCotizacion extends Component {
    formRef = React.createRef();
    state = {
        loading: false,
        objetoClientes: null,
        todasClientes: null,
        objetoProyectos: null,
        todasProyectos: null,
        objetoPrecios: null,
        todasPrecios: null,
        objetoInsumos: null,
        todasInsumos: null,
        objetoUsosDeCFDI: null,
        todasUsosDeCFDI: null,
        objetoBancos: null,
        todasBancos: null,
        objetoUsuarios: null,
        todasUsuarios: null,
        objetoMonedas: null,
        todasMonedas: null,
        impuestos: [],
        modalConfirmacion: false,
        modalCorreo: false,
        modalCorreoId: null,
        modalElegirTipoDeCliente: false,
        modalElegirTipoDeClienteNombre: null,
        modalElegirTipoDeClienteId: null,
        valuesForm: null,
        entregarInsumos: false,
        total: 0,
        subtotal: 0,
        arrayImpuestos: [],
        arrayRetenciones: [],
        totalPagar: 0,
        venderSinInventario: false,
        id: this.props.location.state.id,
        modalGenerarVenta: false,
    };
    componentDidMount() {
        this.consultarTodo();
    }
    async consultarTodo() {
        this.setState({ loading: true });
        await axios
            .post(
                "cotizaciones/selectDatosParaCotizacion",
                { empresaId: this.props.empresaId },
                {
                    headers: this.props.headersToken,
                }
            )
            .then((res) => {
                if (res.data.status === 404) {
                } else {
                    this.setState({
                        todasClientes: res.data.clientes,
                        todasProyectos: res.data.proyectos,
                        todasPrecios: res.data.precios,
                        todasUsosDeCFDI: res.data.usosDeCFDI,
                        todasInsumos: res.data.insumosVenta,
                        todasUsuarios: res.data.usuariosEmpresa,
                        todasMonedas: res.data.monedas,
                        venderSinInventario: res.data.venderSinInventario
                    });
                    this.setState({
                        objetoClientes: res.data.clientes.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.stringBuscar}
                                </Option>
                            );
                        }),
                        objetoProyectos: res.data.proyectos.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.nombre}
                                </Option>
                            );
                        }),
                        objetoPrecios: res.data.precios.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.nombre}
                                </Option>
                            );
                        }),
                        objetoUsosDeCFDI: res.data.usosDeCFDI.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.descripcion}
                                </Option>
                            );
                        }),
                        objetoInsumos: res.data.insumosVenta.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.stringBuscar}
                                </Option>
                            );
                        }),
                        objetoUsuarios: res.data.usuariosEmpresa.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.nombre +
                                        " " +
                                        value.apellidoPaterno +
                                        " " +
                                        value.apellidoMaterno}
                                </Option>
                            );
                        }),
                        objetoMonedas: res.data.monedas.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.nombre + " - " + value.abreviacion}
                                </Option>
                            );
                        }),
                    });
                }
            });

        if (this.props.equipoMedico) {

            await axios
                .post(
                    "ventas/selectDatosParaEquipoMedico",
                    { empresaId: this.props.empresaId },
                    {
                        headers: this.props.headersToken,
                    }
                )
                .then((res) => {
                    if (res.data.success === 2) {
                        this.setState({
                            todasDoctores: res.data.doctores,
                            todasPacientes: res.data.pacientes,
                            objetoDoctores: res.data.doctores.map((value) => {
                                return (
                                    <Option value={value._id} key={value._id}>
                                        {value.codigo + ' - ' + value.nombre}
                                    </Option>
                                );
                            }),
                            objetoPacientes: res.data.pacientes.map((value) => {
                                return (
                                    <Option value={value._id} key={value._id}>
                                        {value.codigo + ' - ' + value.nombre + ' - ' + value.cirugia}
                                    </Option>
                                );
                            }),
                        });
                    } else {
                        message.error('No se encontraron datos de equipo medico')
                    }
                });

        }

        await axios
            .post(
                "cotizaciones/datosCotizacion",
                { empresaId: this.props.empresaId, cotizacionId: this.state.id },
                { headers: this.props.headersToken }
            )
            .then((res) => {
                if (res.data.status === 404) {
                    message.error("Error no se encontro informacion de la cotizacion");
                } else {
                    this.formRef.current.setFieldsValue({
                        ...res.data,
                        factura: res.data.factura ? 1 : 2,
                        fechaCotizacion: res.data.fechaCotizacion
                            ? moment(res.data.fechaCotizacion)
                            : moment(res.data.createdAt),
                        fechaVigencia: res.data.fechaVigencia
                            ? moment(res.data.fechaVigencia)
                            : undefined,
                        insumos: res.data.insumos.map((insumo) => {
                            const precioUnitarioConImpuestos =
                                insumo.precioUnitario + insumo.impuestos / insumo.cantidad;
                            return { ...insumo, precioUnitarioConImpuestos };
                        }),
                    });
                    this.setState({
                        estado: res.data.estado,
                        factura: res.data.factura,
                        nombreCotizacion: 'Cotizacion: ' + res.data.numero,
                        remision: res.data.remision,
                        total: res.data.total,
                        subtotal: res.data.subtotal,
                        arrayImpuestos: res.data.arrayImpuestos,
                        totalPagar: res.data.totalPagar,
                        arrayRetenciones: res.data.arrayRetenciones,
                    });
                }
            });
        this.setState({ loading: false });
    }
    atras() {
        this.props.history.goBack();
    }
    async onChangeInsumoProyecto(value, key, tipo) {
        let arrayValue = await this.formRef.current.getFieldValue("insumos");
        let precioId = await this.formRef.current.getFieldValue("precioId");
        let clienteId = await this.formRef.current.getFieldValue("clienteId");
        let tipoDeCambio = await this.formRef.current.getFieldValue("tipoDeCambio");
        let proyectoId = arrayValue[key].proyectoId;
        const insumoId = arrayValue[key].insumoId;
        const insumo = this.state.todasInsumos.find(
            (valueInsumos) => valueInsumos._id === insumoId
        );
        // if(tipo === 'insumo'){
        //   function getAllIndexes(arr, val) {
        //     var indexes = [], i = -1;
        //     while ((i = arr.indexOf(val, i+1)) != -1){
        //         indexes.push(i);
        //     }
        //     return indexes;
        // }
        // }
        if (!insumo) return;
        if (insumo.inventariable || insumo.insumoCompuesto) {
            await axios
                .post(
                    "inventarios/inventarioInsumoProyecto",
                    {
                        proyectoId: proyectoId,
                        insumoId: insumoId,
                        noPrincipal: insumo.noPrincipal,
                        insumoCompuesto: insumo.insumoCompuesto,
                        empresaId: this.props.empresaId,
                    },
                    { headers: this.props.headersToken }
                )
                .then((res) => {
                    this.setState({ loading: false });
                    arrayValue[key].cantidadProyecto = res.data.cantidad;
                });
        } else {
            arrayValue[key].cantidadProyecto = "No inventariable";
        }
        const precio = insumo.precios.find(
            (valuePrecios) => valuePrecios.precioId?.toString() === precioId
        );
        if (precio) {
            arrayValue[key].precioUnitario = precio.precio;
        } else {
            message.info("Este insumo no tiene ese precio");
            arrayValue[key].precioUnitario = 0;
        }

        const precioUnitario = Number(arrayValue[key].precioUnitario)

        let totalImpuestosPrecioSinImpuestos = 0;
        insumo.impuestos.map((valueImpuesto) => {
            let totalImpuesto;
            if (valueImpuesto.tasaCuota === 1) {
                totalImpuesto = precioUnitario * (valueImpuesto.monto / 100);
            } else if (valueImpuesto.tasaCuota === 2) {
                totalImpuesto =
                    (precioUnitario + totalImpuestosPrecioSinImpuestos) *
                    (valueImpuesto.monto / 100);
            } else if (valueImpuesto.tasaCuota === 3) {
                totalImpuesto = (valueImpuesto.monto / tipoDeCambio);
            } else if (valueImpuesto.tasaCuota === 5) {
                totalImpuesto = 0
            }
            totalImpuestosPrecioSinImpuestos += totalImpuesto;
            return null;
        });
        arrayValue[key].precioUnitarioConImpuestos = round2Decimals(
            precioUnitario + totalImpuestosPrecioSinImpuestos
        );

        const cantidad = arrayValue[key].cantidad;
        const descuento = arrayValue[key].descuento || 0;

        if (precioUnitario && cantidad && insumo) {
            //1
            const subtotal = round2Decimals(cantidad * precioUnitario);
            //2
            const totalDescuento = round2Decimals(subtotal * (descuento / 100));
            //3
            const totalEntrada = round2Decimals(subtotal - totalDescuento);
            arrayValue[key].subtotal = totalEntrada;

            let totalImpuestos = 0;
            let arrayImpuestos = insumo.impuestos.map(
                //4
                (valueImpuesto) => {
                    let totalImpuesto;
                    let baseImpuesto;
                    let montoImpuesto;
                    if (valueImpuesto.tasaCuota === 1) {
                        totalImpuesto = round6Decimals(
                            totalEntrada * (valueImpuesto.monto / 100)
                        );
                        baseImpuesto = totalEntrada
                        montoImpuesto = round6Decimals(valueImpuesto.monto / 100)
                    } else if (valueImpuesto.tasaCuota === 2) {
                        totalImpuesto = round6Decimals(
                            (totalEntrada + totalImpuestos) * (valueImpuesto.monto / 100)
                        );
                        baseImpuesto = (totalEntrada + totalImpuestos)
                        montoImpuesto = round6Decimals(valueImpuesto.monto / 100)
                    } else if (valueImpuesto.tasaCuota === 3) {
                        totalImpuesto = round6Decimals((cantidad * valueImpuesto.monto) / tipoDeCambio);
                        baseImpuesto = cantidad
                        montoImpuesto = round6Decimals(valueImpuesto.monto / tipoDeCambio)
                    } else if (valueImpuesto.tasaCuota === 5) {
                        totalImpuesto = 0
                        baseImpuesto = totalEntrada
                        montoImpuesto = 0
                    }
                    totalImpuestos += totalImpuesto;
                    return {
                        tasaCuota: valueImpuesto.tasaCuota,
                        impuesto: valueImpuesto.impuesto,
                        montoImpuesto: montoImpuesto,
                        local: valueImpuesto.local,
                        baseImpuesto: baseImpuesto,
                        descripcionFactura: valueImpuesto.descripcionFactura,
                        totalImpuestos: totalImpuesto,
                    };
                }
            );
            arrayValue[key].impuestos = totalImpuestos;
            arrayValue[key].arrayImpuestos = arrayImpuestos;
            //5
            arrayValue[key].total = round2Decimals(
                arrayValue[key].subtotal + totalImpuestos
            );

            if (clienteId) {
                //Consultar cliente

                const cliente = this.state.todasClientes.find(
                    (valueP) => valueP._id === clienteId
                );
                //6
                let totalRetenciones = 0;
                let arrayRetenciones = []
                cliente.retenciones.map((valueRetencion) => {
                    //7
                    let totalRetencion;
                    let baseRetencion;
                    if (valueRetencion.tasaCuota === 1) {
                        totalRetencion = round6Decimals(totalEntrada * (valueRetencion.monto / 100));
                        baseRetencion = totalEntrada
                    } else if (valueRetencion.tasaCuota === 2) {
                        totalRetencion =
                            round6Decimals((totalEntrada + totalRetenciones) * (valueRetencion.monto / 100));
                        baseRetencion = (totalEntrada + totalImpuestos)
                    }
                    totalRetenciones += totalRetencion;
                    arrayRetenciones.push({
                        tasaCuota: valueRetencion.tasaCuota,
                        impuesto: valueRetencion.impuesto,
                        montoRetencion: valueRetencion.monto,
                        local: valueRetencion.local,
                        baseRetencion: baseRetencion,
                        descripcionFactura: valueRetencion.descripcionFactura,
                        totalRetenciones: totalRetencion,
                    });
                    return null
                })

                insumo.retenciones.map((valueRetencion) => {
                    if ((valueRetencion.aplicaVenta === 1 && cliente.persona === false) || (valueRetencion.aplicaVenta === 2 && cliente.persona === true) || valueRetencion.aplicaVenta === 3) {
                        //7
                        let totalRetencion;
                        let baseRetencion;
                        if (valueRetencion.tasaCuota === 1) {
                            totalRetencion = round6Decimals(totalEntrada * (valueRetencion.monto / 100));
                            baseRetencion = totalEntrada
                        } else if (valueRetencion.tasaCuota === 2) {
                            totalRetencion =
                                round6Decimals((totalEntrada + totalRetenciones) * (valueRetencion.monto / 100));
                            baseRetencion = (totalEntrada + totalImpuestos)
                        }
                        totalRetenciones += totalRetencion;
                        arrayRetenciones.push({
                            tasaCuota: valueRetencion.tasaCuota,
                            impuesto: valueRetencion.impuesto,
                            montoRetencion: valueRetencion.monto,
                            local: valueRetencion.local,
                            baseRetencion: baseRetencion,
                            descripcionFactura: valueRetencion.descripcionFactura,
                            totalRetenciones: totalRetencion,
                        });
                    }
                    return null
                })
                arrayValue[key].retenciones = totalRetenciones;
                arrayValue[key].arrayRetenciones = arrayRetenciones;
            }

        }
        this.formRef.current.setFieldsValue({
            insumos: arrayValue,
        });
    }
    async onChangePrecioCantidadDescuento(value, key) {
        let arrayValue = await this.formRef.current.getFieldValue("insumos");
        let clienteId = await this.formRef.current.getFieldValue("clienteId");
        let tipoDeCambio = await this.formRef.current.getFieldValue("tipoDeCambio");
        const insumo = this.state.todasInsumos.find(
            (valueInsumos) => valueInsumos._id === arrayValue[key].insumoId
        );
        if (!insumo) return;
        const precioUnitario = Number(arrayValue[key].precioUnitario)

        let totalImpuestosPrecioSinImpuestos = 0;
        insumo.impuestos.map((valueImpuesto) => {
            let totalImpuesto;
            if (valueImpuesto.tasaCuota === 1) {
                totalImpuesto = precioUnitario * (valueImpuesto.monto / 100);
            } else if (valueImpuesto.tasaCuota === 2) {
                totalImpuesto =
                    (precioUnitario + totalImpuestosPrecioSinImpuestos) *
                    (valueImpuesto.monto / 100);
            } else if (valueImpuesto.tasaCuota === 3) {
                totalImpuesto = (valueImpuesto.monto / tipoDeCambio);
            } else if (valueImpuesto.tasaCuota === 5) {
                totalImpuesto = 0
            }
            totalImpuestosPrecioSinImpuestos += totalImpuesto;
            return null;
        });
        arrayValue[key].precioUnitarioConImpuestos = round2Decimals(
            precioUnitario + totalImpuestosPrecioSinImpuestos
        );
        const cantidad = arrayValue[key].cantidad;
        const descuento = arrayValue[key].descuento || 0;

        if (precioUnitario && cantidad && insumo) {
            //1
            const subtotal = round2Decimals(cantidad * precioUnitario);
            //2
            const totalDescuento = round2Decimals(subtotal * (descuento / 100));
            //3
            const totalEntrada = round2Decimals(subtotal - totalDescuento);
            arrayValue[key].subtotal = totalEntrada;

            let totalImpuestos = 0;
            let arrayImpuestos = insumo.impuestos.map(
                //4
                (valueImpuesto) => {
                    let totalImpuesto;
                    let baseImpuesto;
                    let montoImpuesto;
                    if (valueImpuesto.tasaCuota === 1) {
                        totalImpuesto = round6Decimals(
                            totalEntrada * (valueImpuesto.monto / 100)
                        );
                        baseImpuesto = totalEntrada
                        montoImpuesto = round6Decimals(valueImpuesto.monto / 100)
                    } else if (valueImpuesto.tasaCuota === 2) {
                        totalImpuesto = round6Decimals(
                            (totalEntrada + totalImpuestos) * (valueImpuesto.monto / 100)
                        );
                        baseImpuesto = (totalEntrada + totalImpuestos)
                        montoImpuesto = round6Decimals(valueImpuesto.monto / 100)
                    } else if (valueImpuesto.tasaCuota === 3) {
                        totalImpuesto = round6Decimals((cantidad * valueImpuesto.monto) / tipoDeCambio);
                        baseImpuesto = cantidad
                        montoImpuesto = round6Decimals(valueImpuesto.monto / tipoDeCambio)
                    } else if (valueImpuesto.tasaCuota === 5) {
                        totalImpuesto = 0
                        baseImpuesto = totalEntrada
                        montoImpuesto = 0
                    }
                    totalImpuestos += totalImpuesto;
                    return {
                        tasaCuota: valueImpuesto.tasaCuota,
                        impuesto: valueImpuesto.impuesto,
                        montoImpuesto: montoImpuesto,
                        local: valueImpuesto.local,
                        baseImpuesto: baseImpuesto,
                        descripcionFactura: valueImpuesto.descripcionFactura,
                        totalImpuestos: totalImpuesto,
                    };
                }
            );
            arrayValue[key].impuestos = totalImpuestos;
            arrayValue[key].arrayImpuestos = arrayImpuestos;
            //5
            arrayValue[key].total = round2Decimals(
                arrayValue[key].subtotal + totalImpuestos
            );

            if (clienteId) {
                //Consultar cliente

                const cliente = this.state.todasClientes.find(
                    (valueP) => valueP._id === clienteId
                );
                //6
                let totalRetenciones = 0;
                let arrayRetenciones = []
                cliente.retenciones.map((valueRetencion) => {
                    //7
                    let totalRetencion;
                    let baseRetencion;
                    if (valueRetencion.tasaCuota === 1) {
                        totalRetencion = round6Decimals(totalEntrada * (valueRetencion.monto / 100));
                        baseRetencion = totalEntrada
                    } else if (valueRetencion.tasaCuota === 2) {
                        totalRetencion =
                            round6Decimals((totalEntrada + totalRetenciones) * (valueRetencion.monto / 100));
                        baseRetencion = (totalEntrada + totalImpuestos)
                    }
                    totalRetenciones += totalRetencion;
                    arrayRetenciones.push({
                        tasaCuota: valueRetencion.tasaCuota,
                        impuesto: valueRetencion.impuesto,
                        montoRetencion: valueRetencion.monto,
                        local: valueRetencion.local,
                        baseRetencion: baseRetencion,
                        descripcionFactura: valueRetencion.descripcionFactura,
                        totalRetenciones: totalRetencion,
                    });
                    return null
                })
                insumo.retenciones.map((valueRetencion) => {
                    if ((valueRetencion.aplicaVenta === 1 && cliente.persona === false) || (valueRetencion.aplicaVenta === 2 && cliente.persona === true) || valueRetencion.aplicaVenta === 3) {
                        //7
                        let totalRetencion;
                        let baseRetencion;
                        if (valueRetencion.tasaCuota === 1) {
                            totalRetencion = round6Decimals(totalEntrada * (valueRetencion.monto / 100));
                            baseRetencion = totalEntrada
                        } else if (valueRetencion.tasaCuota === 2) {
                            totalRetencion =
                                round6Decimals((totalEntrada + totalRetenciones) * (valueRetencion.monto / 100));
                            baseRetencion = (totalEntrada + totalImpuestos)
                        }
                        totalRetenciones += totalRetencion;
                        arrayRetenciones.push({
                            tasaCuota: valueRetencion.tasaCuota,
                            impuesto: valueRetencion.impuesto,
                            montoRetencion: valueRetencion.monto,
                            local: valueRetencion.local,
                            baseRetencion: baseRetencion,
                            descripcionFactura: valueRetencion.descripcionFactura,
                            totalRetenciones: totalRetencion,
                        });
                    }
                    return null
                })
                arrayValue[key].retenciones = totalRetenciones;
                arrayValue[key].arrayRetenciones = arrayRetenciones;
            }

        }
        this.formRef.current.setFieldsValue({
            insumos: arrayValue,
        });
    }
    onChangeCliente(valueId) {
        const contado = this.formRef.current.getFieldValue("contado");
        const cliente = this.state.todasClientes.find(
            (valueProv) => valueProv._id === valueId
        );
        if (!cliente.regimenFiscalId || !cliente.direcciones[0]?.coloniaId) {
            message.info('No se podra facturar a este cliente. Faltan datos')
        }
        if (!cliente.tipoClienteId) {
            const nombre = cliente.persona
                ? cliente.razonSocial
                : `${cliente.nombre} ${cliente.apellidoPaterno} ${cliente.apellidoMaterno}`;
            this.abrirElegirTipoDeCliente(cliente._id, nombre);
        }
        let objetoCambiar = {}
        if (cliente.usoDeCFDIID) {
            objetoCambiar.usoDeCfdiId = cliente.usoDeCFDIID
        }
        if (cliente.precioContadoId || cliente.precioCreditoId) {
            objetoCambiar.precioId = contado === 1 ? cliente.precioContadoId : cliente.precioCreditoId
        }
        if (cliente.proyectoId) {
            objetoCambiar.proyectoId = cliente.proyectoId
        }
        this.setState({
            complementoDetallista: cliente.complementoDetallista,
            detallista: {
                orderIdentification: cliente.orderIdentification,
                referenceDateOrderIdentification: cliente.referenceDateOrderIdentification,
                additionalInformation: cliente.additionalInformation,
                deliveryNote: cliente.deliveryNote,
                referenceDateDeliveryNote: cliente.referenceDateDeliveryNote,
            }
        })
        this.formRef.current.setFieldsValue({
            diasCredito: cliente.diasCredito || 0,
            ...objetoCambiar
        });
    }
    onChangeContado(value) {
        const clienteId = this.formRef.current.getFieldValue("clienteId");
        if (!clienteId) return;
        const cliente = this.state.todasClientes.find(
            (valueProv) => valueProv._id === clienteId
        );
        this.formRef.current.setFieldsValue({
            precioId: value === 1 ? cliente.precioContadoId : cliente.precioCreditoId,
        });
    }
    cerrarMandarCorreoCotizacion() {
        this.setState({
            modalCorreo: false,
            modalCorreoId: null,
        });
    }
    enviarCotizacion(value) {
        this.setState({ modalCorreo: true, modalCorreoId: value });
    }

    generarVentaDeCotizacion() {
        this.setState({ modalGenerarVenta: true });
    }

    cerrarGenerarVentaDeContizacion() {
        this.setState({
            modalGenerarVenta: false,
        });
        this.consultarTodo();
    }

    cancelarCotizacion(value) {
        axios
            .post(
                "cotizaciones/cancelarCotizacion",
                { cotizacionId: value, empresaId: this.props.empresaId },
                { headers: this.props.headersToken }
            )
            .then((res) => {
                if (res.data.success === 2) {
                    message.success("Cotizacion cancelada");
                } else if (res.data.success === 1) {
                    message.warn(res.data.message);
                } else {
                    message.error("Sucedio un error y no se pudo cancelar");
                }
                this.consultarCotizaciones();
            });
    }

    render() {
        const isMobile = window.innerWidth <= 1000;
        return (
            <>
                <div style={{ backgroundColor: "white", padding: 20, paddingTop: 20 }}>
                    <Spin
                        spinning={this.state.loading}
                        style={{ display: "block", margin: "0 0 0 auto" }}
                    >
                        <Form
                            ref={this.formRef}
                            name="VerCotizacion"
                            layout
                            bottom={
                                <div style={{ display: "flex", justifyContent: "right", flexWrap: "wrap", gap: "8px" }}>
                                    <Space style={{ display: "flex", justifyContent: "right", flexWrap: "wrap", width: "100%" }}>
                                        {this.state.estado === 1 ?
                                            <ButtonItem
                                                type="primary"
                                                onClick={() => this.generarVentaDeCotizacion()}
                                                style={{ marginTop: "10px" }}
                                            >
                                                Generar Venta
                                            </ButtonItem>
                                            : null}
                                        {this.state.estado !== 0 && this.state.estado !== 3 ?
                                            <Popconfirm
                                                title={'Seguro que quiere cancelar esta factura'}
                                                onConfirm={() => this.cancelarCotizacion(this.state.id)}
                                                okText="Ok"
                                                cancelText="Cancelar"
                                            >
                                                <ButtonItem
                                                    type="default"
                                                    size="small"
                                                    danger
                                                    style={{ marginTop: "10px" }}
                                                >
                                                    Cancelar
                                                </ButtonItem>
                                            </Popconfirm>
                                            : null}
                                        <ButtonItem
                                            type="primary"
                                            blanco
                                            style={{ marginTop: "10px" }}
                                            icon={<SendOutlined />}
                                            onClick={() => this.enviarCotizacion(this.state.id)}
                                        >
                                            Mandar Correo
                                        </ButtonItem>
                                        <ButtonItem
                                            type="primary"
                                            danger
                                            style={{ marginTop: "10px" }}
                                            onClick={this.atras.bind(this)}
                                        >
                                            Regresar
                                        </ButtonItem>
                                    </Space>
                                </div>
                            }
                            onFinish={(values) =>
                                this.setState({ modalConfirmacion: true, valuesForm: values })
                            }
                            onFinishFailed={this.onFinishFailed}
                        >
                            <FormItem
                                noStyle
                                shouldUpdate={(prev, curr) =>
                                    prev.insumos?.length !== curr.insumos?.length ||
                                    prev.factura !== curr.factura
                                }
                            >
                                {({ getFieldValue }) => {
                                    return (
                                        <>
                                            <Row>
                                                <Col span={16} xs={24} sm={24} lg={16} xl={16}>
                                                    <HeaderItem>DATOS GENERALES</HeaderItem>
                                                    <BoxItem>
                                                        <Row>
                                                            <Col span={8} xs={24} sm={24} lg={8} xl={8}>
                                                                <FormItem
                                                                    required
                                                                    noStyle
                                                                    name="factura"
                                                                    labelCol={{ span: 0 }}
                                                                    wrapperCol={{ span: 24 }}
                                                                >
                                                                    <SelectItem
                                                                        width="80%"
                                                                        disabled
                                                                        value={this.state.factura}
                                                                    >
                                                                        <Option value={2}>Remisión</Option>
                                                                        {this.props.permisoFacturarVentas &&
                                                                            this.props.certificada && (
                                                                                <Option value={1}>Factura</Option>
                                                                            )}
                                                                    </SelectItem>
                                                                </FormItem>
                                                            </Col>
                                                            <Col span={8} xs={24} sm={24} lg={8} xl={8}>
                                                                <FormItem
                                                                    label="Fecha"
                                                                    name="fechaCotizacion"
                                                                    required
                                                                    margin
                                                                    initialValue={moment()}
                                                                    labelCol={{ span: 6 }}
                                                                    wrapperCol={{ span: 20 }}
                                                                >
                                                                    <DatePickerItem
                                                                        disabled
                                                                        placeholder="Fecha de Cotizacion"
                                                                        allowClear={false}
                                                                    />
                                                                </FormItem>
                                                            </Col>
                                                            <Col span={8} xs={24} sm={24} lg={8} xl={8}>
                                                                <FormItem
                                                                    required
                                                                    label={isMobile ? "Método de pago" : undefined}
                                                                    initialValue={1}
                                                                    name="contado"
                                                                    labelCol={{ span: 0 }}
                                                                    wrapperCol={{ span: 24 }}
                                                                >
                                                                    <SelectItem
                                                                        width="100%"
                                                                        onChange={this.onChangeContado.bind(this)}
                                                                        disabled
                                                                    >
                                                                        <Option value={1}>
                                                                            Contado - PUE : Pago en una sola
                                                                            exhibición
                                                                        </Option>
                                                                        <Option value={2}>
                                                                            Crédito - PPD : Pago en
                                                                            parcialidades o diferido
                                                                        </Option>
                                                                        {/* <Option value={3} disabled>
                                                                            Crédito con enganche - PPD : Pago en
                                                                            parcialidades o diferido
                                                                        </Option> */}
                                                                    </SelectItem>
                                                                </FormItem>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col span={24} xs={24} sm={24} lg={24} xl={24}>
                                                                <FormItem
                                                                    noStyle
                                                                    shouldUpdate={(prev, curr) =>
                                                                        prev.insumos?.length !==
                                                                        curr.insumos?.length
                                                                    }
                                                                >
                                                                    {({ getFieldValue }) => {
                                                                        return (
                                                                            <FormItem
                                                                                label="Cliente"
                                                                                name="clienteId"
                                                                                margin
                                                                                required
                                                                                labelCol={{ span: 2 }}
                                                                                wrapperCol={{ span: 22 }}
                                                                            >
                                                                                <SelectItem
                                                                                    placeholder="Cliente"
                                                                                    disabled
                                                                                    onChange={(valueId) =>
                                                                                        this.onChangeCliente(valueId)
                                                                                    }
                                                                                >
                                                                                    {this.state.objetoClientes}
                                                                                </SelectItem>
                                                                            </FormItem>
                                                                        );
                                                                    }}
                                                                </FormItem>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col span={24} xs={24} sm={24} lg={24} xl={24}>
                                                                <FormItem
                                                                    label="Vendedor"
                                                                    name="vendedorId"
                                                                    margin
                                                                    required
                                                                    initialValue={this.props.usuarioId}
                                                                    labelCol={{ span: 2 }}
                                                                    wrapperCol={{ span: 22 }}
                                                                >
                                                                    <SelectItem
                                                                        placeholder="Vendedor"
                                                                        disabled
                                                                    >
                                                                        {this.state.objetoUsuarios}
                                                                    </SelectItem>
                                                                </FormItem>
                                                                {this.props.equipoMedico && (
                                                                    <>
                                                                        <FormItem
                                                                            label="Doctor"
                                                                            name="doctorId"
                                                                            margin
                                                                            labelCol={{ span: 2 }}
                                                                            wrapperCol={{ span: 22 }}
                                                                        >
                                                                            <SelectItem disabled placeholder="Doctor">
                                                                                {this.state.objetoDoctores}
                                                                            </SelectItem>
                                                                        </FormItem>
                                                                        <FormItem
                                                                            label="Paciente"
                                                                            name="pacienteId"
                                                                            margin
                                                                            labelCol={{ span: 2 }}
                                                                            wrapperCol={{ span: 22 }}
                                                                        >
                                                                            <SelectItem disabled placeholder="Paciente">
                                                                                {this.state.objetoPacientes}
                                                                            </SelectItem>
                                                                        </FormItem>
                                                                    </>

                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </BoxItem>
                                                </Col>
                                                <Col span={8} xs={24} sm={24} lg={8} xl={8}>
                                                    <HeaderItem>MONEDA</HeaderItem>
                                                    <BoxItem>
                                                        <FormItem
                                                            noStyle
                                                            shouldUpdate={(prev, curr) =>
                                                                prev.insumos?.length !==
                                                                curr.insumos?.length
                                                            }
                                                        >
                                                            {({ getFieldValue }) => {
                                                                return (
                                                                    <FormItem
                                                                        required
                                                                        margin
                                                                        name="monedaId"
                                                                        label="Moneda"
                                                                        labelCol={{ span: 6 }}
                                                                        wrapperCol={{ span: 18 }}
                                                                        initialValue='60c7e4396465ad440c41c15a'
                                                                    >
                                                                        <SelectItem disabled onChange={(value) => {
                                                                            if (value === '60c7e4396465ad440c41c15a') {
                                                                                this.formRef.current.setFieldsValue({ tipoDeCambio: 1 })
                                                                            }
                                                                        }} placeholder="Moneda">
                                                                            {this.state.objetoMonedas}
                                                                        </SelectItem>
                                                                    </FormItem>
                                                                )
                                                            }}
                                                        </FormItem>
                                                        <FormItem noStyle shouldUpdate={(prev, curr) => prev.monedaId !== curr.monedaId || prev.insumos?.length !== curr.insumos?.length}>
                                                            {({ getFieldValue }) => {
                                                                return (
                                                                    <FormItem
                                                                        required
                                                                        number
                                                                        margin
                                                                        label="T. Cambio"
                                                                        name="tipoDeCambio"
                                                                        type="number"
                                                                        initialValue={1}
                                                                        labelCol={{ span: 6 }}
                                                                        wrapperCol={{ span: 18 }}
                                                                    >
                                                                        <InputNumberItem
                                                                            disabled
                                                                            dinero
                                                                            placeholder="T. Cambio"
                                                                        />
                                                                    </FormItem>
                                                                )
                                                            }}
                                                        </FormItem>
                                                    </BoxItem>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col span={6} xs={24} sm={24} lg={6} xl={6}>
                                                    <HeaderItem>Vigencia</HeaderItem>
                                                    <BoxItem>
                                                        <FormItem
                                                            label="Fecha Vigencia"
                                                            name="fechaVigencia"
                                                            required
                                                            margin
                                                            labelCol={{ span: 6 }}
                                                            wrapperCol={{ span: 20 }}
                                                        >
                                                            <DatePickerItem
                                                                disabled
                                                                placeholder="Fecha de Vigencia"
                                                                allowClear={false}
                                                            />
                                                        </FormItem>
                                                    </BoxItem>
                                                </Col>
                                                <Col span={18} xs={24} sm={24} lg={18} xl={18}>
                                                    <HeaderItem>FORMA DE PAGO</HeaderItem>
                                                    <BoxItem>
                                                        <FormItem
                                                            noStyle
                                                            shouldUpdate={(prev, curr) =>
                                                                prev.contado !== curr.contado ||
                                                                prev.factura !== curr.factura
                                                            }
                                                        >
                                                            {({ getFieldValue }) => {
                                                                const contado = getFieldValue("contado");
                                                                const factura = getFieldValue("factura");
                                                                const diasCredito =
                                                                    contado !== 1 ? (
                                                                        <FormItem
                                                                            label="Días de crédito"
                                                                            name="diasCredito"
                                                                            numberCero
                                                                            margin
                                                                            required
                                                                            initialValue={0}
                                                                            labelCol={{ span: 8 }}
                                                                            wrapperCol={{ span: 16 }}
                                                                        >
                                                                            <InputNumberItem disabled placeholder="Días de Crédito" />
                                                                        </FormItem>
                                                                    ) : null;
                                                                const usoCFDI =
                                                                    factura === 1 ? (
                                                                        <FormItem
                                                                            name="usoDeCfdiId"
                                                                            label="Uso de CFDI"
                                                                            initialValue="6123bd944518d23048736747"
                                                                            margin
                                                                            required
                                                                            labelCol={{ span: 8 }}
                                                                            wrapperCol={{ span: 16 }}
                                                                        >
                                                                            <SelectItem disabled placeholder="Uso de CFDI">
                                                                                {this.state.objetoUsosDeCFDI}
                                                                            </SelectItem>
                                                                        </FormItem>
                                                                    ) : null;
                                                                return (
                                                                    <>
                                                                        <Row>
                                                                            <FormItem
                                                                                noStyle
                                                                                shouldUpdate={(prev, curr) =>
                                                                                    prev.insumos?.length !==
                                                                                    curr.insumos?.length
                                                                                }
                                                                            >
                                                                                {({ getFieldValue }) => {

                                                                                    return (
                                                                                        <>
                                                                                            <Col span={8} xs={24} sm={24} lg={8} xl={8}>
                                                                                                <FormItem
                                                                                                    label="Proyecto"
                                                                                                    name="proyectoId"
                                                                                                    margin
                                                                                                    required
                                                                                                    labelCol={{ span: 8 }}
                                                                                                    wrapperCol={{ span: 16 }}
                                                                                                >
                                                                                                    <SelectItem
                                                                                                        placeholder="Proyecto"
                                                                                                        disabled
                                                                                                    >
                                                                                                        {this.state.objetoProyectos}
                                                                                                    </SelectItem>
                                                                                                </FormItem>
                                                                                            </Col>
                                                                                            <Col span={8} xs={24} sm={24} lg={8} xl={8}>
                                                                                                <FormItem
                                                                                                    name="precioId"
                                                                                                    label="Precio"
                                                                                                    margin
                                                                                                    required
                                                                                                    labelCol={{ span: 8 }}
                                                                                                    wrapperCol={{ span: 16 }}
                                                                                                >
                                                                                                    <SelectItem
                                                                                                        placeholder="Precio"
                                                                                                        disabled
                                                                                                    >
                                                                                                        {this.state.objetoPrecios}
                                                                                                    </SelectItem>
                                                                                                </FormItem>
                                                                                            </Col>
                                                                                            <Col span={8} xs={24} sm={24} lg={8} xl={8}>
                                                                                                <FormItem
                                                                                                    name="ordenDeCompra"
                                                                                                    label="O Compra"
                                                                                                    margin
                                                                                                    pattern
                                                                                                    labelCol={{ span: 8 }}
                                                                                                    wrapperCol={{ span: 16 }}
                                                                                                >
                                                                                                    <InputItem disabled placeholder="Orden de Compra" />
                                                                                                </FormItem>
                                                                                            </Col>
                                                                                        </>
                                                                                    );
                                                                                }}
                                                                            </FormItem>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col span={8} xs={24} sm={24} lg={8} xl={8}>
                                                                                {usoCFDI || diasCredito}
                                                                            </Col>
                                                                            <Col span={8} xs={24} sm={24} lg={8} xl={8}>
                                                                                {usoCFDI ? diasCredito : null}
                                                                            </Col>
                                                                        </Row>
                                                                    </>
                                                                );
                                                            }}
                                                        </FormItem>
                                                    </BoxItem>
                                                </Col>
                                            </Row>
                                        </>
                                    );
                                }}
                            </FormItem>
                            <HeaderItem>INSUMOS</HeaderItem>
                            <BoxItem>
                                <FormInitial.List name="insumos">
                                    {(fields, { add, remove }) => (
                                        <>
                                            <div style={{ overflowX: 'auto' }} >
                                                <table className="table" style={{ minWidth: isMobile ? "900px" : "100%" }}>
                                                    {fields.length > 0 ? (
                                                        <thead>
                                                            <tr>
                                                                <th className="th-border" style={{ width: "30%" }}>
                                                                    Insumo
                                                                </th>
                                                                <th className="th-border" style={{ width: "10%" }}>
                                                                    Proyecto
                                                                </th>
                                                                <th className="th-border" style={{ width: "10%" }}>
                                                                    PU
                                                                </th>
                                                                <th className="th-border" style={{ width: "10%" }}>
                                                                    Precio Neto
                                                                </th>
                                                                <th className="th-border" style={{ width: "8%" }}>
                                                                    Cant.
                                                                </th>
                                                                <th className="th-border" style={{ width: "8%" }}>
                                                                    Desc.
                                                                </th>
                                                                <th className="th-border" style={{ width: "10%" }}>
                                                                    Subtotal
                                                                </th>
                                                                <th className="th-border" style={{ width: "10%" }}>
                                                                    Impuestos
                                                                </th>
                                                                <th className="th-border" style={{ width: "10%" }}>
                                                                    Total
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                    ) : null}
                                                    {fields.map(
                                                        ({ key, name, fieldKey, ...restField }, arrayKey) => (
                                                            <tbody key={arrayKey}>
                                                                <tr>
                                                                    <td className="td-border">
                                                                        <FormItem
                                                                            {...restField}
                                                                            name={[name, "insumoId"]}
                                                                            required
                                                                            noStyle
                                                                        >
                                                                            <SelectItem
                                                                                sinBorde
                                                                                autoFocus
                                                                                dropdownMatchSelectWidth={600}
                                                                                width="100%"
                                                                                placeholder="Insumo"
                                                                                disabled
                                                                                onChange={(value) =>
                                                                                    this.onChangeInsumoProyecto(
                                                                                        value,
                                                                                        arrayKey,
                                                                                        'insumo'
                                                                                    )
                                                                                }
                                                                            >
                                                                                {this.state.objetoInsumos}
                                                                            </SelectItem>
                                                                        </FormItem>
                                                                    </td>
                                                                    <td className="td-border">
                                                                        <FormItem
                                                                            shouldUpdate={(prev, curr) =>
                                                                                prev.proyectoId !== curr.proyectoId
                                                                            }
                                                                            noStyle
                                                                        >
                                                                            {({ getFieldValue }) => {
                                                                                const proyectoId =
                                                                                    getFieldValue("proyectoId");
                                                                                return (
                                                                                    <FormItem
                                                                                        {...restField}
                                                                                        name={[name, "proyectoId"]}
                                                                                        required
                                                                                        noStyle
                                                                                        initialValue={proyectoId}
                                                                                    >
                                                                                        <SelectItem
                                                                                            sinBorde
                                                                                            width="100%"
                                                                                            placeholder="Proyecto"
                                                                                            disabled
                                                                                            onChange={(value) =>
                                                                                                this.onChangeInsumoProyecto(
                                                                                                    value,
                                                                                                    arrayKey,
                                                                                                    'proyecto'
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            {this.state.objetoProyectos}
                                                                                        </SelectItem>
                                                                                    </FormItem>
                                                                                );
                                                                            }}
                                                                        </FormItem>
                                                                    </td>
                                                                    <td className="td-border">
                                                                        <FormItem
                                                                            {...restField}
                                                                            name={[name, "precioUnitario"]}
                                                                            noStyle
                                                                            required
                                                                            numberCero
                                                                        >
                                                                            <InputNumberItem
                                                                                sinBorde
                                                                                onChange={(value) =>
                                                                                    this.onChangePrecioCantidadDescuento(
                                                                                        value,
                                                                                        arrayKey
                                                                                    )
                                                                                }
                                                                                placeholder="Precio Unitario"
                                                                                disabled
                                                                                dinero
                                                                            />
                                                                        </FormItem>
                                                                    </td>
                                                                    <td className="td-border">
                                                                        <FormItem
                                                                            {...restField}
                                                                            name={[name, "precioUnitarioConImpuestos"]}
                                                                            noStyle
                                                                            required
                                                                        >
                                                                            <InputNumberItem
                                                                                sinBorde
                                                                                placeholder="Precio Unitario con Impuestos"
                                                                                disabled
                                                                                dineroDisabled
                                                                            />
                                                                        </FormItem>
                                                                    </td>
                                                                    <td className="td-border">
                                                                        <FormItem
                                                                            noStyle
                                                                            shouldUpdate={(prev, curr) =>
                                                                                prev.insumos[arrayKey]?.cantidadProyecto !==
                                                                                curr.insumos[arrayKey]?.cantidadProyecto
                                                                            }
                                                                        >
                                                                            {({ getFieldValue }) => {
                                                                                const insumos = getFieldValue("insumos");
                                                                                const cantidadProyecto =
                                                                                    insumos[arrayKey].cantidadProyecto;
                                                                                let max = 0;
                                                                                if (
                                                                                    cantidadProyecto === "No inventariable"
                                                                                ) {
                                                                                    max = Infinity;
                                                                                } else {
                                                                                    max = cantidadProyecto;
                                                                                }
                                                                                return (
                                                                                    <FormItem
                                                                                        {...restField}
                                                                                        name={[name, "cantidad"]}
                                                                                        number
                                                                                        noStyle
                                                                                        type="number"
                                                                                    >
                                                                                        <InputNumberItem
                                                                                            sinBorde
                                                                                            disabled
                                                                                            placeholder={
                                                                                                max && max !== Infinity
                                                                                                    ? "Max: " + max
                                                                                                    : "Cantidad"
                                                                                            }
                                                                                            onChange={(value) =>
                                                                                                this.onChangePrecioCantidadDescuento(
                                                                                                    value,
                                                                                                    arrayKey
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </FormItem>
                                                                                );
                                                                            }}
                                                                        </FormItem>
                                                                    </td>
                                                                    <td className="td-border">
                                                                        <FormItem
                                                                            {...restField}
                                                                            name={[name, "descuento"]}
                                                                            required
                                                                            numberCero
                                                                            noStyle
                                                                            initialValue={0}
                                                                        >
                                                                            <InputNumberItem
                                                                                sinBorde
                                                                                disabled
                                                                                placeholder="Descuento"
                                                                                porcentaje
                                                                                max={100}
                                                                                onChange={(value) =>
                                                                                    this.onChangePrecioCantidadDescuento(
                                                                                        value,
                                                                                        arrayKey
                                                                                    )
                                                                                }
                                                                            />
                                                                        </FormItem>
                                                                    </td>
                                                                    <td className="td-border">
                                                                        <FormItem
                                                                            {...restField}
                                                                            name={[name, "subtotal"]}
                                                                            required
                                                                            numberCero
                                                                            noStyle
                                                                        >
                                                                            <InputNumberItem
                                                                                sinBorde
                                                                                disabled
                                                                                placeholder="Subtotal"
                                                                                dineroDisabled
                                                                            />
                                                                        </FormItem>
                                                                    </td>
                                                                    <td className="td-border">
                                                                        <FormItem
                                                                            {...restField}
                                                                            name={[name, "impuestos"]}
                                                                            required
                                                                            numberCero
                                                                            noStyle
                                                                        >
                                                                            <InputNumberItem
                                                                                sinBorde
                                                                                disabled
                                                                                dineroDisabled
                                                                                placeholder="Impuestos"
                                                                            />
                                                                        </FormItem>
                                                                    </td>
                                                                    <td className="td-border">
                                                                        <FormItem
                                                                            {...restField}
                                                                            name={[name, "total"]}
                                                                            required
                                                                            numberCero
                                                                            noStyle
                                                                        >
                                                                            <InputNumberItem
                                                                                sinBorde
                                                                                dineroDisabled
                                                                                disabled
                                                                                placeholder="Total"
                                                                            />
                                                                        </FormItem>
                                                                    </td>
                                                                </tr>
                                                                <FormItem noStyle shouldUpdate={(prev, curr) => prev.insumos[arrayKey]?.leyenda !== curr.insumos[arrayKey]?.leyenda}>
                                                                    {({ getFieldValue }) => {
                                                                        let arrayValue = getFieldValue("insumos");
                                                                        if (arrayValue[arrayKey]?.leyenda) {
                                                                            return (
                                                                                <tr>
                                                                                    <td colSpan="9" className="td-border">
                                                                                        <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left' }}>
                                                                                            <div style={{ width: '90%', textAlign: 'left', paddingLeft: 20 }}>
                                                                                                <b>Leyenda:</b> {arrayValue[arrayKey].leyenda}
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                    }}
                                                                </FormItem>
                                                            </tbody>
                                                        )
                                                    )}
                                                </table>
                                            </div>
                                        </>
                                    )}
                                </FormInitial.List>
                                <Row>
                                    <Col span={12} xs={24} sm={24} lg={12} xl={12}>
                                        <FormItem
                                            pattern
                                            name='observaciones'
                                            label='Observaciones'
                                        >
                                            <TextAreaItem />
                                        </FormItem>
                                    </Col>
                                    <Col span={4} xs={24} sm={24} lg={4} xl={4}>
                                        <FormItem
                                            label="Imprimir"
                                            name="imprimirObservaciones"
                                            valuePropName="checked"
                                        >
                                            <Checkbox></Checkbox>
                                        </FormItem>
                                    </Col>
                                    <Col span={8} xs={24} sm={24} lg={8} xl={8}>
                                        <table className="table" style={{ marginTop: 0 }}>
                                            <tbody>
                                                <tr>
                                                    <th
                                                        className="th-totales"
                                                        style={{ width: "50%" }}
                                                    >
                                                        Subtotal
                                                    </th>
                                                    <td
                                                        className="td-totales-right"
                                                        style={{ width: "50%" }}
                                                    >
                                                        {dineroDisabledMask(this.state.subtotal)}
                                                    </td>
                                                </tr>
                                            </tbody>
                                            {this.state.arrayImpuestos.map((valueArray) => (
                                                <tbody>
                                                    <tr key={valueArray.descripcionFactura}>
                                                        <th className="th-totales">
                                                            {valueArray.descripcionFactura}
                                                        </th>
                                                        <td className="td-totales-right">
                                                            {dineroDisabledMask(
                                                                valueArray.totalImpuestos
                                                            )}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            ))}
                                            <tbody>
                                                <tr>
                                                    <th className="th-totales">Total</th>
                                                    <td className="td-totales-right">
                                                        {dineroDisabledMask(this.state.total)}
                                                    </td>
                                                </tr>
                                            </tbody>
                                            {this.state.arrayRetenciones.map((value) => (
                                                <tbody>
                                                    <tr>
                                                        <th className="th-totales">
                                                            {value.descripcionFactura}
                                                        </th>
                                                        <td className="td-totales-right">
                                                            {dineroDisabledMask(value.totalRetenciones)}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            ))}
                                            <tbody>
                                                <tr>
                                                    <th className="th-totales">Total a Pagar</th>
                                                    <td className="td-totales-right">
                                                        {dineroDisabledMask(this.state.totalPagar)}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                            </BoxItem>
                        </Form>
                    </Spin>
                </div>
                <ModalItem
                    title="Confirmacion"
                    visible={this.state.modalConfirmacion}
                    onCancel={() => this.setState({ modalConfirmacion: false })}
                    onOk={() => {
                        this.setState({ modalConfirmacion: false });
                        this.onFinish(this.state.valuesForm);
                    }}
                    spinning={false}
                    footer
                >
                    <h1 style={{ textAlign: "center" }}>Desea continuar</h1>
                </ModalItem>
                <MandarCorreoCotizacion
                    visible={this.state.modalCorreo}
                    id={this.state.modalCorreoId}
                    cerrarMandarCorreoCotizacion={this.cerrarMandarCorreoCotizacion.bind(this)}
                />
                <GenerarVenta
                    visible={this.state.modalGenerarVenta}
                    id={this.state.id}
                    cerrarGenerarVentaDeContizacion={this.cerrarGenerarVentaDeContizacion.bind(this)}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        headersToken: state.user.headersToken,
        empresaId: state.empresa.id,
        usuarioId: state.user.usuarioId,
        certificada: state.empresa.certificada,
        complementoDetallista: state.empresa.complementoDetallista,
        equipoMedico: state.empresa.equipoMedico,
        permisoFacturarVentas:
            state.user.tipo === 0 ||
            state.user.permisos.find(
                (valueTodos) =>
                    valueTodos.permisoId.toString() === "615ce36bf30e81b54183cfaf"
            )?.activado,
    };
};

export default connect(mapStateToProps)(VerCotizacion);
