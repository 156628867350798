import { message, PageHeader, Popconfirm, Space } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { TableItem, ButtonItem, SegmentedItem } from "../../../Components/Items";
import moment from "moment";
import "moment/locale/es";
import { CheckCircleOutlined, CopyOutlined, EditOutlined, StopOutlined } from "@ant-design/icons";
import DuplicarPesupuestoPU from "./DuplicarPesupuestoPU/DuplicarPesupuestoPU";

moment.locale("es");

class PresupuestosPU extends Component {
  state = {
    todasPresupuestosPU: [],
    loading: false,
    valor: 1,
    modalDuplicar: false,
    modalDuplicarId: null
  };
  componentDidMount() {
    this.consultarPresupuestosPU(this.state.valor);
  }
  consultarPresupuestosPU(valor) {
    this.setState({ loading: true });
    axios
      .post(
        "presupuestosPU/selectTodosPresupuestos",
        { empresaId: this.props.empresaId, valor: valor },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          this.setState({
            todasPresupuestosPU: res.data.presupuestos.map(valuePresupuesto => {
              return {
                ...valuePresupuesto,
                accion: {
                  _id: valuePresupuesto._id,
                  createdAt: valuePresupuesto.createdAt,
                  estado: valuePresupuesto.estado
                }
              }
            }),
          });
        } else {
          message.warning('No se pudieron obtener los datos')
        }
      });
  }
  deshabilitarPresupuesto(value) {
    axios
      .post(
        "presupuestosPU/deshabilitarPresupuesto",
        { presupuestoId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Presupuesto deshabilitado");
        } else {
          message.error("Sucedio un error y no se pudo deshabilitar");
        }
        this.consultarPresupuestosPU(this.state.valor);
      });
  }
  habilitarPresupuesto(value) {
    axios
      .post(
        "presupuestosPU/habilitarPresupuesto",
        { presupuestoId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Presupuesto habilitado");
        } else {
          message.error("Sucedio un error y no se pudo habilitar");
        }
        this.consultarPresupuestosPU(this.state.valor);
      });
  }

  presupuestoPU(id) {
    if (id) {
      this.props.history.push("/panel/presupuestoPU", { id });
    } else {
      this.props.history.push("/panel/presupuestoPU");
    }
  }

  duplicarPresupuesto(id) {
    this.setState({ modalDuplicar: true, modalDuplicarId: id })
  }

  cerrarDuplicarPresupuestoPU() {
    this.setState({ modalDuplicar: false, modalDuplicarId: null })
    this.consultarPresupuestosPU(this.state.valor)
  }

  render() {
    const columns = [
      {
        title: "Nombre",
        dataIndex: "nombre",
        key: "nombre",
        sorter: (a, b) => {
          if (a.nombre < b.nombre) {
            return -1;
          }
          if (a.nombre > b.nombre) {
            return 1;
          }
          return 0;
        },
        buscar: "nombre",
        width: "20%",
      },
      {
        title: "Fecha Inicio",
        dataIndex: "fechaInicio",
        key: "fechaInicio",
        sorter: (a, b) => {
          if (moment(a.fechaInicio) < moment(b.fechaInicio)) {
            return -1;
          }
          if (moment(a.fechaInicio) > moment(b.fechaInicio)) {
            return 1;
          }
          return 0;
        },
        render: (value) => moment(value).locale("es").format("ll"),
        wdith: "20%",
        ellipsis: true,
        align: "center",
      },
      {
        title: "Fecha Final",
        dataIndex: "fechaFinal",
        key: "fechaFinal",
        sorter: (a, b) => {
          if (moment(a.fechaFinal) < moment(b.fechaFinal)) {
            return -1;
          }
          if (moment(a.fechaFinal) > moment(b.fechaFinal)) {
            return 1;
          }
          return 0;
        },
        render: (value) => moment(value).locale("es").format("ll"),
        wdith: "20%",
        ellipsis: true,
        align: "center",
      },
      {
        title: "Accion",
        dataIndex: "accion",
        key: "accion",
        render: (value) => (
          <Space size="small">
            {this.props.permisoEditarPresupuestoPU &&
              <EditOutlined
                onClick={() => this.presupuestoPU(value._id)}
                style={{ color: "blue" }}
              />}
            {value.estado === 1 ? (
              <Popconfirm
                title="Seguro que quieres deshabilitar este presupuesto"
                onConfirm={() => this.deshabilitarPresupuesto(value._id)}
                okText="Si"
                cancelText="No"
              >
                <CheckCircleOutlined style={{ color: "green" }} />
              </Popconfirm>
            ) : (
              <Popconfirm
                title="Seguro que quieres habilitar este presupuesto"
                onConfirm={() => this.habilitarPresupuesto(value._id)}
                okText="Si"
                cancelText="No"
              >
                <StopOutlined style={{ color: "red" }} />
              </Popconfirm>
            )}
            <CopyOutlined
              onClick={() => this.duplicarPresupuesto(value._id)}
            />
          </Space>
        ),
        width: "6%",
      },
    ];

    let buttons = []

    buttons.push(
      <>
        {window.innerWidth > 1000 &&
          <SegmentedItem
            options={[{ label: 'Activo', value: 1 }, { label: 'Deshabilitado', value: 2 }, { label: 'Todos', value: 3 }]}
            onChange={(value) => {
              this.consultarPresupuestosPU(value)
              this.setState({ valor: value })
            }}
            value={this.state.valor}
          />
        }
        {this.props.permisoAgregarPresupuestoPU &&
          <ButtonItem
            type="primary"
            key="presupuestoPU"
            onClick={() => this.presupuestoPU()}
          >
            Agregar Presupuesto
          </ButtonItem>}
      </>
    )


    return (
      <>
        <PageHeader
          title="Presupuestos"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={buttons}
        />
        <TableItem
          loading={this.state.loading}
          size="small"
          dataSource={this.state.todasPresupuestosPU}
          columns={columns}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onDoubleClick: (event) => {
                this.presupuestoPU(record._id)
              },
            };
          }}
        />
        <DuplicarPesupuestoPU
          visible={this.state.modalDuplicar}
          id={this.state.modalDuplicarId}
          cerrarDuplicarPresupuestoPU={this.cerrarDuplicarPresupuestoPU.bind(this)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    permisoAgregarPresupuestoPU:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6750951f798d4e004368724d"
      )?.activado,
    permisoEditarPresupuestoPU:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6750955f798d4e004368724e"
      )?.activado,

  };
};

export default connect(mapStateToProps)(PresupuestosPU);
