import { Button } from "antd";
import React, { useState } from "react";

const ButtonItem = ({ children, style, noBuscar, ...props }) => {
  const [isHovered, setIsHovered] = useState(false);

  const baseStyle = {
    borderRadius: '6px',
    padding: '0 8px', // Reducir el padding lateral para hacer el botón más estrecho
    fontSize: '14px',
    fontWeight: '500',
    transition: 'background-color 0.3s ease, border-color 0.3s ease',
    textAlign: 'center',
    display: 'inline-flex',  // Para asegurar la alineación flexible
    alignItems: 'center',  // Alinea verticalmente
    justifyContent: 'center',  // Alinea horizontalmente
    height: props.size === "small" ? '25px' : '85%',
    maxHeight: '30px',
    border: '2px solid',
    cursor: 'pointer',
    ...style,
  };

  const getBackgroundColor = () => {
    if (props.disabled) return "#f5f5f5";
    if (props.danger) return isHovered ? "#fff" : "#ff4d4f";
    if (props.blanco) return isHovered ? "#f5f5f5" : "#FFFFFF";
    if (props.verde) return isHovered ? "#f5f5f5" : "green";
    if (props.naranja) return isHovered ? "#f5f5f5" : "#ff914d";
    if (props.rojo) return isHovered ? "#fff" : "#e84646";
    return isHovered ? "#fff" : "#00b5e2";
  };

  const getBorderColor = () => {
    if (props.disabled) return "#f5f5f5";
    if (props.danger) return "#ff4d4f";
    if (props.blanco) return "#c0c0c0";
    if (props.verde) return "green";
    if (props.naranja) return "#ff914d";
    if (props.rojo) return "#e84646";
    return isHovered ? "#00b5e2" : "#00b5e2";
  };

  const getColor = () => {
    if (props.disabled) return "#c0c0c0";
    if (props.danger) return isHovered ? "#ff4d4f" : "#fff";
    if (props.blanco) return "#000";
    if (props.verde) return isHovered ? "green" : "#fff";
    if (props.naranja) return isHovered ? "#ff914d" : "#fff";
    if (props.rojo) return isHovered ? "#e84646" : "#fff";
    return isHovered ? "#00b5e2" : "#fff";
  };

  const mergedStyle = {
    ...baseStyle,
    backgroundColor: getBackgroundColor(),
    borderColor: getBorderColor(),
    color: getColor(),
  };

  return (
    <Button
      {...props}
      style={mergedStyle}
      onMouseEnter={() => {
        setIsHovered(true)
        props.onMouseEnter && props.onMouseEnter()
      }}
      onMouseLeave={() => {
        setIsHovered(false)
        props.onMouseLeave && props.onMouseLeave()
      }}
    >
      {children}
    </Button>
  );
};

export default ButtonItem;




/*import { Button } from "antd";
import React, {Component} from "react";

class ButtonItem extends Component{
    render(){
        let transformedProperties = {};
        for (var key in this.props) {
          if (
            !(
              key.toString() === "noBuscar"
            )
          ) {
            transformedProperties = {
              ...transformedProperties,
              [key]: this.props[key],
            };
          }
        }
        const style = {
            backgroundColor: '#00b5e2', 
            borderColor: '#00b5e2',
            color: 'white',
            ...this.props.style
        }
        if(this.props.danger){
          style.backgroundColor = '#ff4d4f'
          style.borderColor = '#ff4d4f'
        }
        if(this.props.blanco){
          style.backgroundColor = '#fff'
          style.borderColor = '#d9d9d9'
          style.color = '#000000d9'
        }
        return(
            <Button {...transformedProperties} style={style}>{this.props.children}</Button>
        )
    }
}

export default ButtonItem*/