import React, { Component } from "react";
import {
    message,
    PageHeader,
    Spin,
    Row,
    Col,
    Select,
    Form as FormInitial,
    Checkbox,
    Space,
} from "antd";
import {
    dineroDisabledMask,
    Form,
    FormItem,
    InputNumberItem,
    SelectItem,
    round2Decimals,
    ButtonItem,
    round6Decimals,
    HeaderItem,
    BoxItem,
    DatePickerItem,
    SwitchItem,
    TextAreaItem,
} from "../../../../Components/Items";
import axios from "axios";
import moment from "moment";
import "moment/locale/es";
import { connect } from "react-redux";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import MandarCorreoDevolucion from "../MandarCorreoDevolucion/MandarCorreoDevolucion";
import { DollarOutlined, DownloadOutlined, SendOutlined } from "@ant-design/icons";
import PagosDevolucion from "../PagosDevolucion/PagosDevolucion";
import devolucionFPdf from "../../../../Pdf/devolucionFPdf";
import devolucionRPdf from "../../../../Pdf/devolucionRPdf";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const { Option } = Select;

moment.locale("es");

class VerDevolucion extends Component {
    formRef = React.createRef();
    state = {
        loading: false,
        objetoProveedores: null,
        todasProveedores: null,
        objetoProyectos: null,
        todasProyectos: null,
        objetoInsumos: (
            <Option disabled value={0} key={0}>
                Seleccione un proveedor primero
            </Option>
        ),
        todasInsumos: [],
        objetoMonedas: null,
        todasMonedas: null,
        impuestos: [],
        modalConfirmacion: false,
        valuesForm: null,
        id: this.props.location.state.id,
        datosVenta: null,
        tipoDeCambioVenta: 0,
        totalVenta: 0,
        totalPagarVenta: 0,
        subtotalVenta: 0,
        arrayImpuestosVenta: [],
        arrayRetencionesVenta: [],
        modalCorreo: false,
        modalCorreoId: null,
        total: 0,
        totalPagar: 0,
        totalPagado: 0,
        subtotal: 0,
        ajusteSubtotal: 0,
        arrayImpuestos: [],
        arrayRetenciones: [],
        modalPagos: false,
        estado: 0,
        objetoBancos: null,
        todasBancos: null,
    };
    async componentDidMount() {
        this.setState({ loading: true });
        await this.consultarTodo()
        if (this.props?.location?.state?.fileName) {
            this.leerXmlData(this.props.location.state)
        }
        this.setState({ loading: false });
    }

    promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));

    async consultarTodo() {

        this.setState({ loading: true });

        await axios
            .post(
                "devoluciones/selectDatosParaDevolucion",
                { empresaId: this.props.empresaId },
                {
                    headers: this.props.headersToken,
                }
            )
            .then((res) => {
                if (res.data.success === 2) {
                    this.setState({
                        todasClientes: res.data.clientes,
                        todasProyectos: res.data.proyectos,
                        todasPrecios: res.data.precios,
                        todasFormasDePago: res.data.formasDePago,
                        todasUsosDeCFDI: res.data.usosDeCFDI,
                        todasBancos: res.data.bancosYCajas,
                        todasInsumos: res.data.insumosVenta,
                        todasUsuarios: res.data.usuariosEmpresa,
                        todasMonedas: res.data.monedas,
                        venderSinInventario: res.data.venderSinInventario,
                        objetoClientes: res.data.clientes.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.stringBuscar}
                                </Option>
                            );
                        }),
                        objetoProyectos: res.data.proyectos.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.nombre}
                                </Option>
                            );
                        }),
                        objetoPrecios: res.data.precios.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.nombre}
                                </Option>
                            );
                        }),
                        objetoFormasDePago: res.data.formasDePago.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.descripcion}
                                </Option>
                            );
                        }),
                        objetoUsosDeCFDI: res.data.usosDeCFDI.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.descripcion}
                                </Option>
                            );
                        }),
                        objetoInsumos: res.data.insumosVenta.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.stringBuscar}
                                </Option>
                            );
                        }),
                        objetoUsuarios: res.data.usuariosEmpresa.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.nombre +
                                        " " +
                                        value.apellidoPaterno +
                                        " " +
                                        value.apellidoMaterno}
                                </Option>
                            );
                        }),
                        objetoMonedas: res.data.monedas.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.nombre + " - " + value.abreviacion}
                                </Option>
                            );
                        }),
                        objetoBancos: res.data.bancosYCajas.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.nombre}
                                </Option>
                            );
                        }),
                    });
                } else {
                    message.warning('No se encontro informacion de la venta')
                }
            });

        await axios
            .post(
                "devoluciones/datosDevolucion",
                { empresaId: this.props.empresaId, devolucionId: this.state.id },
                { headers: this.props.headersToken }
            )
            .then((res) => {
                if (res.data.status === 404) {
                    message.error("Error no se encontro informacion de la devolución");
                } else {
                    this.formRef.current.setFieldsValue({
                        ...res.data,
                        fechaDevolucion: moment(res.data.fechaDevolucion),
                        factura: res.data.factura ? 1 : 2,
                        insumos: res.data.insumos.map(insumo => {
                            const precioUnitarioConImpuestos = insumo.precioUnitario + (insumo.impuestos / insumo.cantidad)
                            return { ...insumo, precioUnitarioConImpuestos }
                        })
                    });

                    this.setState({ estado: res.data.estado, total: res.data.total, totalPagar: res.data.totalPagar, totalPagado: res.data.totalPagado, subtotal: res.data.subtotal, arrayImpuestos: res.data.arrayImpuestos, arrayRetenciones: res.data.arrayRetenciones, ajusteSubtotal: res.data.ajusteSubtotal })
                }
            });

        this.setState({ loading: false });

    }

    atras() {
        this.props.history.goBack();
    }
    async onChangeInsumoProyecto(value, key, tipo) {
        let arrayValue = await this.formRef.current.getFieldValue("insumos");
        let precioId = await this.formRef.current.getFieldValue("precioId");
        let clienteId = await this.formRef.current.getFieldValue("clienteId");
        let tipoDeCambio = await this.formRef.current.getFieldValue("tipoDeCambio");
        let proyectoId = arrayValue[key].proyectoId;
        const insumoId = arrayValue[key].insumoId;
        const insumo = this.state.todasInsumos.find(
            (valueInsumos) => valueInsumos._id === insumoId
        );
        // if(tipo === 'insumo'){
        //   function getAllIndexes(arr, val) {
        //     var indexes = [], i = -1;
        //     while ((i = arr.indexOf(val, i+1)) != -1){
        //         indexes.push(i);
        //     }
        //     return indexes;
        // }
        // }
        if (!insumo) return;
        if (insumo.inventariable || insumo.insumoCompuesto) {
            await axios
                .post(
                    "inventarios/inventarioInsumoProyecto",
                    {
                        proyectoId: proyectoId,
                        insumoId: insumoId,
                        noPrincipal: insumo.noPrincipal,
                        insumoCompuesto: insumo.insumoCompuesto,
                        empresaId: this.props.empresaId,
                    },
                    { headers: this.props.headersToken }
                )
                .then((res) => {
                    this.setState({ loading: false });
                    arrayValue[key].cantidadProyecto = res.data.cantidad;
                });
        } else {
            arrayValue[key].cantidadProyecto = "No inventariable";
        }
        const precio = insumo.precios.find(
            (valuePrecios) => valuePrecios.precioId?.toString() === precioId
        );
        if (precio) {
            arrayValue[key].precioUnitario = precio.precio;
        } else {
            message.info("Este insumo no tiene ese precio");
            arrayValue[key].precioUnitario = 0;
        }

        const precioUnitario = Number(arrayValue[key].precioUnitario)

        let totalImpuestosPrecioSinImpuestos = 0;
        insumo.impuestos.map((valueImpuesto) => {
            let totalImpuesto;
            if (valueImpuesto.tasaCuota === 1) {
                totalImpuesto = precioUnitario * (valueImpuesto.monto / 100);
            } else if (valueImpuesto.tasaCuota === 2) {
                totalImpuesto =
                    (precioUnitario + totalImpuestosPrecioSinImpuestos) *
                    (valueImpuesto.monto / 100);
            } else if (valueImpuesto.tasaCuota === 3) {
                totalImpuesto = (valueImpuesto.monto / tipoDeCambio);
            } else if (valueImpuesto.tasaCuota === 5) {
                totalImpuesto = 0
            }
            totalImpuestosPrecioSinImpuestos += totalImpuesto;
            return null;
        });
        arrayValue[key].precioUnitarioConImpuestos = round2Decimals(
            precioUnitario + totalImpuestosPrecioSinImpuestos
        );

        const cantidad = arrayValue[key].cantidad;
        const descuento = arrayValue[key].descuento || 0;

        if (precioUnitario && cantidad && insumo) {
            //1
            const subtotal = round2Decimals(cantidad * precioUnitario);
            //2
            const totalDescuento = round2Decimals(subtotal * (descuento / 100));
            //3
            const totalEntrada = round2Decimals(subtotal - totalDescuento);
            arrayValue[key].subtotal = totalEntrada;

            let totalImpuestos = 0;
            let arrayImpuestos = insumo.impuestos.map(
                //4
                (valueImpuesto) => {
                    let totalImpuesto;
                    let baseImpuesto;
                    let montoImpuesto;
                    if (valueImpuesto.tasaCuota === 1) {
                        totalImpuesto = round6Decimals(
                            totalEntrada * (valueImpuesto.monto / 100)
                        );
                        baseImpuesto = totalEntrada
                        montoImpuesto = round6Decimals(valueImpuesto.monto / 100)
                    } else if (valueImpuesto.tasaCuota === 2) {
                        totalImpuesto = round6Decimals(
                            (totalEntrada + totalImpuestos) * (valueImpuesto.monto / 100)
                        );
                        baseImpuesto = (totalEntrada + totalImpuestos)
                        montoImpuesto = round6Decimals(valueImpuesto.monto / 100)
                    } else if (valueImpuesto.tasaCuota === 3) {
                        totalImpuesto = round6Decimals((cantidad * valueImpuesto.monto) / tipoDeCambio);
                        baseImpuesto = cantidad
                        montoImpuesto = round6Decimals(valueImpuesto.monto / tipoDeCambio)
                    } else if (valueImpuesto.tasaCuota === 5) {
                        totalImpuesto = 0
                        baseImpuesto = totalEntrada
                        montoImpuesto = 0
                    }
                    totalImpuestos += totalImpuesto;
                    return {
                        tasaCuota: valueImpuesto.tasaCuota,
                        impuesto: valueImpuesto.impuesto,
                        montoImpuesto: montoImpuesto,
                        local: valueImpuesto.local,
                        baseImpuesto: baseImpuesto,
                        descripcionFactura: valueImpuesto.descripcionFactura,
                        totalImpuestos: totalImpuesto,
                    };
                }
            );
            arrayValue[key].impuestos = totalImpuestos;
            arrayValue[key].arrayImpuestos = arrayImpuestos;
            //5
            arrayValue[key].total = round2Decimals(
                arrayValue[key].subtotal + totalImpuestos
            );

            if (clienteId) {
                //Consultar cliente

                const cliente = this.state.todasClientes.find(
                    (valueP) => valueP._id === clienteId
                );
                //6
                let totalRetenciones = 0;
                let arrayRetenciones = []
                cliente.retenciones.map((valueRetencion) => {
                    //7
                    let totalRetencion;
                    let baseRetencion;
                    if (valueRetencion.tasaCuota === 1) {
                        totalRetencion = round6Decimals(totalEntrada * (valueRetencion.monto / 100));
                        baseRetencion = totalEntrada
                    } else if (valueRetencion.tasaCuota === 2) {
                        totalRetencion =
                            round6Decimals((totalEntrada + totalRetenciones) * (valueRetencion.monto / 100));
                        baseRetencion = (totalEntrada + totalImpuestos)
                    }
                    totalRetenciones += totalRetencion;
                    arrayRetenciones.push({
                        tasaCuota: valueRetencion.tasaCuota,
                        impuesto: valueRetencion.impuesto,
                        montoRetencion: valueRetencion.monto,
                        local: valueRetencion.local,
                        baseRetencion: baseRetencion,
                        descripcionFactura: valueRetencion.descripcionFactura,
                        totalRetenciones: totalRetencion,
                    });
                    return null
                })

                insumo.retenciones.map((valueRetencion) => {
                    if ((valueRetencion.aplicaVenta === 1 && cliente.persona === false) || (valueRetencion.aplicaVenta === 2 && cliente.persona === true) || valueRetencion.aplicaVenta === 3) {
                        //7
                        let totalRetencion;
                        let baseRetencion;
                        if (valueRetencion.tasaCuota === 1) {
                            totalRetencion = round6Decimals(totalEntrada * (valueRetencion.monto / 100));
                            baseRetencion = totalEntrada
                        } else if (valueRetencion.tasaCuota === 2) {
                            totalRetencion =
                                round6Decimals((totalEntrada + totalRetenciones) * (valueRetencion.monto / 100));
                            baseRetencion = (totalEntrada + totalImpuestos)
                        }
                        totalRetenciones += totalRetencion;
                        arrayRetenciones.push({
                            tasaCuota: valueRetencion.tasaCuota,
                            impuesto: valueRetencion.impuesto,
                            montoRetencion: valueRetencion.monto,
                            local: valueRetencion.local,
                            baseRetencion: baseRetencion,
                            descripcionFactura: valueRetencion.descripcionFactura,
                            totalRetenciones: totalRetencion,
                        });
                    }
                    return null
                })
                arrayValue[key].retenciones = totalRetenciones;
                arrayValue[key].arrayRetenciones = arrayRetenciones;
            }
        }

        this.formRef.current.setFieldsValue({
            insumos: arrayValue
        });
        this.setState({ flete: (!!insumo.flete || this.state.flete) })
    }
    async onChangePrecioCantidadDescuento(value, key) {
        let arrayValue = await this.formRef.current.getFieldValue("insumos");
        let clienteId = await this.formRef.current.getFieldValue("clienteId");
        let tipoDeCambio = await this.formRef.current.getFieldValue("tipoDeCambio");
        const insumo = this.state.todasInsumos.find(
            (valueInsumos) => valueInsumos._id === arrayValue[key].insumoId
        );
        if (!insumo) return;
        const precioUnitario = Number(arrayValue[key].precioUnitario)

        let totalImpuestosPrecioSinImpuestos = 0;
        insumo.impuestos.map((valueImpuesto) => {
            let totalImpuesto;
            if (valueImpuesto.tasaCuota === 1) {
                totalImpuesto = precioUnitario * (valueImpuesto.monto / 100);
            } else if (valueImpuesto.tasaCuota === 2) {
                totalImpuesto =
                    (precioUnitario + totalImpuestosPrecioSinImpuestos) *
                    (valueImpuesto.monto / 100);
            } else if (valueImpuesto.tasaCuota === 3) {
                totalImpuesto = (valueImpuesto.monto / tipoDeCambio);
            } else if (valueImpuesto.tasaCuota === 5) {
                totalImpuesto = 0
            }
            totalImpuestosPrecioSinImpuestos += totalImpuesto;
            return null;
        });
        arrayValue[key].precioUnitarioConImpuestos = round2Decimals(
            precioUnitario + totalImpuestosPrecioSinImpuestos
        );
        const cantidad = arrayValue[key].cantidad;
        const descuento = arrayValue[key].descuento || 0;

        if (precioUnitario && cantidad && insumo) {
            //1
            const subtotal = round2Decimals(cantidad * precioUnitario);
            //2
            const totalDescuento = round2Decimals(subtotal * (descuento / 100));
            //3
            const totalEntrada = round2Decimals(subtotal - totalDescuento);
            arrayValue[key].subtotal = totalEntrada;

            let totalImpuestos = 0;
            let arrayImpuestos = insumo.impuestos.map(
                //4
                (valueImpuesto) => {
                    let totalImpuesto;
                    let baseImpuesto;
                    let montoImpuesto;
                    if (valueImpuesto.tasaCuota === 1) {
                        totalImpuesto = round6Decimals(
                            totalEntrada * (valueImpuesto.monto / 100)
                        );
                        baseImpuesto = totalEntrada
                        montoImpuesto = round6Decimals(valueImpuesto.monto / 100)
                    } else if (valueImpuesto.tasaCuota === 2) {
                        totalImpuesto = round6Decimals(
                            (totalEntrada + totalImpuestos) * (valueImpuesto.monto / 100)
                        );
                        baseImpuesto = (totalEntrada + totalImpuestos)
                        montoImpuesto = round6Decimals(valueImpuesto.monto / 100)
                    } else if (valueImpuesto.tasaCuota === 3) {
                        totalImpuesto = round6Decimals((cantidad * valueImpuesto.monto) / tipoDeCambio);
                        baseImpuesto = cantidad
                        montoImpuesto = round6Decimals(valueImpuesto.monto / tipoDeCambio)
                    } else if (valueImpuesto.tasaCuota === 5) {
                        totalImpuesto = 0
                        baseImpuesto = totalEntrada
                        montoImpuesto = 0
                    }
                    totalImpuestos += totalImpuesto;
                    return {
                        tasaCuota: valueImpuesto.tasaCuota,
                        impuesto: valueImpuesto.impuesto,
                        montoImpuesto: montoImpuesto,
                        local: valueImpuesto.local,
                        baseImpuesto: baseImpuesto,
                        descripcionFactura: valueImpuesto.descripcionFactura,
                        totalImpuestos: totalImpuesto,
                    };
                }
            );
            arrayValue[key].impuestos = totalImpuestos;
            arrayValue[key].arrayImpuestos = arrayImpuestos;
            //5
            arrayValue[key].total = round2Decimals(
                arrayValue[key].subtotal + totalImpuestos
            );

            if (clienteId) {
                //Consultar cliente

                const cliente = this.state.todasClientes.find(
                    (valueP) => valueP._id === clienteId
                );
                //6
                let totalRetenciones = 0;
                let arrayRetenciones = []
                cliente.retenciones.map((valueRetencion) => {
                    //7
                    let totalRetencion;
                    let baseRetencion;
                    if (valueRetencion.tasaCuota === 1) {
                        totalRetencion = round6Decimals(totalEntrada * (valueRetencion.monto / 100));
                        baseRetencion = totalEntrada
                    } else if (valueRetencion.tasaCuota === 2) {
                        totalRetencion =
                            round6Decimals((totalEntrada + totalRetenciones) * (valueRetencion.monto / 100));
                        baseRetencion = (totalEntrada + totalImpuestos)
                    }
                    totalRetenciones += totalRetencion;
                    arrayRetenciones.push({
                        tasaCuota: valueRetencion.tasaCuota,
                        impuesto: valueRetencion.impuesto,
                        montoRetencion: valueRetencion.monto,
                        local: valueRetencion.local,
                        baseRetencion: baseRetencion,
                        descripcionFactura: valueRetencion.descripcionFactura,
                        totalRetenciones: totalRetencion,
                    });
                    return null
                })
                insumo.retenciones.map((valueRetencion) => {
                    if ((valueRetencion.aplicaVenta === 1 && cliente.persona === false) || (valueRetencion.aplicaVenta === 2 && cliente.persona === true) || valueRetencion.aplicaVenta === 3) {
                        //7
                        let totalRetencion;
                        let baseRetencion;
                        if (valueRetencion.tasaCuota === 1) {
                            totalRetencion = round6Decimals(totalEntrada * (valueRetencion.monto / 100));
                            baseRetencion = totalEntrada
                        } else if (valueRetencion.tasaCuota === 2) {
                            totalRetencion =
                                round6Decimals((totalEntrada + totalRetenciones) * (valueRetencion.monto / 100));
                            baseRetencion = (totalEntrada + totalImpuestos)
                        }
                        totalRetenciones += totalRetencion;
                        arrayRetenciones.push({
                            tasaCuota: valueRetencion.tasaCuota,
                            impuesto: valueRetencion.impuesto,
                            montoRetencion: valueRetencion.monto,
                            local: valueRetencion.local,
                            baseRetencion: baseRetencion,
                            descripcionFactura: valueRetencion.descripcionFactura,
                            totalRetenciones: totalRetencion,
                        });
                    }
                    return null
                })
                arrayValue[key].retenciones = totalRetenciones;
                arrayValue[key].arrayRetenciones = arrayRetenciones;
            }

        }
        this.formRef.current.setFieldsValue({
            insumos: arrayValue,
        });
    }
    onChangeCliente(valueId) {
        const contado = this.formRef.current.getFieldValue("contado");
        const cliente = this.state.todasClientes.find(
            (valueProv) => valueProv._id === valueId
        );
        if (!cliente.regimenFiscalId || !cliente.direcciones[0]?.coloniaId) {
            message.info('No se podra facturar a este cliente. Faltan datos')
        }
        if (!cliente.tipoClienteId) {
            const nombre = cliente.persona
                ? cliente.razonSocial
                : `${cliente.nombre} ${cliente.apellidoPaterno} ${cliente.apellidoMaterno}`;
            this.abrirElegirTipoDeCliente(cliente._id, nombre);
        }
        let objetoCambiar = {}
        if (cliente.usoDeCFDIID) {
            objetoCambiar.usoDeCfdiId = cliente.usoDeCFDIID
        }
        if (cliente.precioContadoId || cliente.precioCreditoId) {
            objetoCambiar.precioId = contado === 1 ? cliente.precioContadoId : cliente.precioCreditoId
        }
        if (cliente.proyectoId) {
            objetoCambiar.proyectoId = cliente.proyectoId
        }
        if (cliente.vendedorId) {
            objetoCambiar.vendedorId = cliente.vendedorId
        }
        this.setState({
            complementoDetallista: cliente.complementoDetallista,
            detallista: {
                orderIdentification: cliente.orderIdentification,
                referenceDateOrderIdentification: cliente.referenceDateOrderIdentification,
                additionalInformation: cliente.additionalInformation,
                deliveryNote: cliente.deliveryNote,
                referenceDateDeliveryNote: cliente.referenceDateDeliveryNote,
            }
        })
        this.formRef.current.setFieldsValue({
            diasCredito: cliente.diasCredito || 0,
            ...objetoCambiar
        });
    }
    onChangeContado(value) {
        if (value !== 1) {
            this.formRef.current.setFieldsValue({
                formaDePagoId: '6125149afe07662acc100d6d'
            })
        } else {
            this.formRef.current.setFieldsValue({
                formaDePagoId: '6125149afe07662acc100d58'
            })
        }
        const clienteId = this.formRef.current.getFieldValue("clienteId");
        if (!clienteId) return;
        const cliente = this.state.todasClientes.find(
            (valueProv) => valueProv._id === clienteId
        );
        this.formRef.current.setFieldsValue({
            precioId: value === 1 ? cliente.precioContadoId : cliente.precioCreditoId,
        });
    }
    abrirElegirTipoDeCliente(id, nombre) {
        this.setState({
            modalElegirTipoDeCliente: true,
            modalElegirTipoDeClienteId: id,
            modalElegirTipoDeClienteNombre: nombre,
        });
    }
    cerrarElegirTipoDeCliente(elegido) {
        if (!elegido) {
            this.formRef.current.setFieldsValue({ clienteId: undefined });
        }
        this.consultarTodo();
        this.setState({
            modalElegirTipoDeCliente: false,
            modalElegirTipoDeClienteId: null,
            modalElegirTipoDeClienteNombre: null,
        });
    }
    cerrarMandarCorreoDevolucion() {
        this.setState({
            modalCorreo: false,
            modalCorreoId: null,
        });
    }
    abrirElegirMarbetes(value, marbetes, insumoId, proyectoId) {
        if (proyectoId) {
            this.setState({
                modalElegirMarbetes: true,
                modalElegirMarbetesIndex: value,
                modalElegirMarbetesMarbetes: marbetes || [],
                modalElegirMarbetesInsumoId: insumoId,
                modalElegirMarbetesProyectoId: proyectoId
            })
        } else {
            message.warning('Elegir un proyecto para elegir marbetes')
        }
    }
    cerrarElegirMarbetes(value) {
        const insumos = this.formRef.current.getFieldValue('insumos')
        insumos[this.state.modalElegirMarbetesIndex].marbetes = value
        this.formRef.current.setFieldsValue({
            insumos: insumos
        })
        this.setState({
            modalElegirMarbetes: false,
            modalElegirMarbetesIndex: null,
            modalElegirMarbetesMarbetes: null,
            modalElegirMarbetesInsumoId: null,
            modalElegirMarbetesProyectoId: null
        })
    }

    descargarDevolucion(value) {
        axios
            .post(
                "devoluciones/datosPdf",
                { devolucionId: value, empresaId: this.props.empresaId },
                { headers: this.props.headersToken }
            )
            .then((res) => {
                if (res.data.factura) {
                    var xmltext = res.data.respuestaFactura.data.cfdi;
                    const dataSat = res.data.respuestaFactura.dataSat
                    const nombreArchivo = (dataSat.Serie ? dataSat.Serie : 'D') + ' - ' + dataSat.Folio + ' ( ' + dataSat.Receptor.Rfc + ' - ' + dataSat.Receptor.Nombre + ' )'

                    var filename = nombreArchivo + ".xml";
                    var pom = document.createElement("a");
                    var bb = new Blob([xmltext], { type: "text/plain" });

                    pom.setAttribute("href", window.URL.createObjectURL(bb));
                    pom.setAttribute("download", filename);

                    pom.dataset.downloadurl = ["text/plain", pom.download, pom.href].join(
                        ":"
                    );
                    pom.draggable = true;
                    pom.classList.add("dragout");

                    pom.click();

                    const pdf = devolucionFPdf(res.data);

                    pdfMake.createPdf(pdf).download(nombreArchivo + ".pdf");
                } else {
                    const dataSat = res.data.respuestaFactura.dataSat
                    const nombreArchivo = (dataSat.Serie ? dataSat.Serie : 'D') + ' - ' + dataSat.Folio + ' ( ' + dataSat.Receptor.Nombre + ' )'

                    const pdf = devolucionRPdf(res.data);

                    pdfMake.createPdf(pdf).download(nombreArchivo + ".pdf");
                }
            });
    }

    enviarDevolucion(value) {
        this.setState({ modalCorreo: true, modalCorreoId: value });
    }

    cerrarPagosDevolucion() {
        this.consultarTodo()
        this.setState({
            modalPagos: false,
        });
    }

    render() {
        return (
            <>
                <PageHeader
                    onBack={this.atras.bind(this)}
                    title="Ver Devolución"
                    style={{
                        border: "1px solid rgb(235, 237, 240)",
                        backgroundColor: "white",
                        marginBottom: 10,
                    }}
                />
                <div style={{ backgroundColor: "white", padding: 20 }}>
                    <Spin spinning={this.state.loading}>
                        <Form
                            ref={this.formRef}
                            name="verDevolucion"
                            layout
                            bottom={
                                <div style={{ display: "flex", justifyContent: "right" }}>
                                    <Space>
                                        {this.state.totalPagado > 0 &&
                                            <ButtonItem
                                                type="primary"
                                                icon={<DollarOutlined />}
                                                verde
                                                onClick={() => this.setState({ modalPagos: true })}
                                            >
                                                Pagos
                                            </ButtonItem>
                                        }
                                        <ButtonItem
                                            icon={<DownloadOutlined />}
                                            blanco={true}
                                            onClick={() => this.descargarDevolucion(this.state.id)}
                                        >
                                            Descargar
                                        </ButtonItem>
                                        <ButtonItem
                                            type="primary"
                                            icon={<SendOutlined />}
                                            onClick={() => this.enviarDevolucion(this.state.id)}
                                        >
                                            Mandar Correo
                                        </ButtonItem>
                                        <ButtonItem
                                            type="primary"
                                            danger
                                            onClick={this.atras.bind(this)}
                                        >
                                            Regresar
                                        </ButtonItem>
                                    </Space>
                                </div>
                            }
                        >
                            <Row>
                                <Col span={16}>
                                    <HeaderItem>DATOS GENERALES</HeaderItem>
                                    <BoxItem>
                                        <Row>
                                            <Col span={8}>
                                                <FormItem
                                                    required
                                                    noStyle
                                                    initialValue={2}
                                                    name="factura"
                                                    labelCol={{ span: 0 }}
                                                    wrapperCol={{ span: 24 }}
                                                >
                                                    <SelectItem
                                                        disabled
                                                        width="80%"
                                                        value={this.state.factura}
                                                        onChange={(value) => {
                                                            this.formRef.current.setFieldsValue({
                                                                fechaDevolucion: moment(),
                                                            })
                                                            this.setState({ factura: value })
                                                        }}
                                                    >
                                                        <Option value={2}>Remisión</Option>
                                                        {this.props.permisoFacturarVentas && this.props.certificada && (
                                                            <Option value={1}>Factura</Option>
                                                        )}
                                                    </SelectItem>
                                                </FormItem>
                                            </Col>
                                            <Col span={8}>
                                                <FormItem
                                                    label="Fecha"
                                                    name="fechaDevolucion"
                                                    required
                                                    margin
                                                    initialValue={moment()}
                                                    labelCol={{ span: 6 }}
                                                    wrapperCol={{ span: 20 }}
                                                >
                                                    <DatePickerItem
                                                        disabled
                                                        placeholder="Fecha de Devolucion"
                                                        allowClear={false}
                                                    />
                                                </FormItem>
                                            </Col>
                                            <Col span={8} />
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <FormItem
                                                    label="Cliente"
                                                    name="clienteId"
                                                    margin
                                                    required
                                                    labelCol={{ span: 2 }}
                                                    wrapperCol={{ span: 22 }}
                                                >
                                                    <SelectItem
                                                        disabled
                                                        placeholder="Cliente"
                                                        onChange={(valueId) => this.onChangeCliente(valueId)}
                                                    >
                                                        {this.state.objetoClientes}
                                                    </SelectItem>
                                                </FormItem>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <FormItem
                                                    label="Vendedor"
                                                    name="vendedorId"
                                                    margin
                                                    required
                                                    labelCol={{ span: 2 }}
                                                    wrapperCol={{ span: 22 }}
                                                >
                                                    <SelectItem disabled placeholder="Vendedor">
                                                        {this.state.objetoUsuarios}
                                                    </SelectItem>
                                                </FormItem>
                                            </Col>
                                        </Row>
                                    </BoxItem>
                                </Col>

                                <Col span={8}>
                                    <HeaderItem>MONEDA</HeaderItem>
                                    <BoxItem>
                                        <FormItem
                                            required
                                            margin
                                            name="monedaId"
                                            label="Moneda"
                                            labelCol={{ span: 8 }}
                                            wrapperCol={{ span: 16 }}
                                            initialValue='60c7e4396465ad440c41c15a'
                                        >
                                            <SelectItem disabled onChange={(value) => {
                                                if (value === '60c7e4396465ad440c41c15a') {
                                                    this.formRef.current.setFieldsValue({ tipoDeCambio: 1 })
                                                }
                                            }} placeholder="Moneda">
                                                {this.state.objetoMonedas}
                                            </SelectItem>
                                        </FormItem>
                                        <FormItem
                                            required
                                            number
                                            margin
                                            label="T. Cambio"
                                            name="tipoDeCambio"
                                            type="number"
                                            initialValue={1}
                                            labelCol={{ span: 8 }}
                                            wrapperCol={{ span: 16 }}
                                        >
                                            <InputNumberItem
                                                disabled
                                                dinero
                                                placeholder="T. Cambio"
                                            />
                                        </FormItem>
                                        <FormItem shouldUpdate noStyle>
                                            {({ getFieldValue }) => {
                                                const pagarVenta = getFieldValue('pagarVenta')
                                                const devolverDinero = getFieldValue('devolverDinero')

                                                if (pagarVenta || devolverDinero) {
                                                    return (
                                                        <>
                                                            <p style={{ textAlign: 'center' }}>Moneda de la venta: {this.state.datosVenta?.moneda?.abreviacion}</p>
                                                            <FormItem
                                                                required
                                                                number
                                                                margin
                                                                label={`T. C. ${this.state.datosVenta?.moneda?.abreviacion ? this.state.datosVenta?.moneda?.abreviacion : ''}`}
                                                                name="tipoDeCambioVenta"
                                                                type="number"
                                                                initialValue={1}
                                                            >
                                                                <InputNumberItem
                                                                    disabled
                                                                    dinero
                                                                    placeholder={`T. C. ${this.state.datosVenta?.moneda?.abreviacion ? this.state.datosVenta?.moneda?.abreviacion : ''}`}
                                                                />
                                                            </FormItem>
                                                            <p style={{ textAlign: 'center' }}>Tipo de cambio venta: {dineroDisabledMask(this.state.datosVenta?.tipoDeCambio)}</p>
                                                        </>
                                                    )
                                                }
                                            }}
                                        </FormItem>
                                    </BoxItem>
                                </Col>

                            </Row>
                            <Row>
                                <Col span={6}>
                                    <HeaderItem>PAGO</HeaderItem>
                                    <BoxItem>
                                        <FormItem shouldUpdate noStyle>
                                            {({ getFieldValue }) => {
                                                const devolverDinero = getFieldValue('devolverDinero')
                                                return (
                                                    <>
                                                        <FormItem
                                                            name="pagarVenta"
                                                            label="Pagar Venta"
                                                            tooltip={{ title: "Pagar la venta existente con esta devolucion" }}
                                                            margin
                                                            pattern
                                                            valuePropName="checked"
                                                            labelCol={{ span: 12 }}
                                                            wrapperCol={{ span: 12 }}
                                                        >
                                                            <SwitchItem disabled />
                                                        </FormItem>
                                                        <FormItem
                                                            name="devolverDinero"
                                                            label="Compensación"
                                                            tooltip={{ title: "Regresar el dinero al cliente" }}
                                                            margin
                                                            pattern
                                                            valuePropName="checked"
                                                            labelCol={{ span: 12 }}
                                                            wrapperCol={{ span: 12 }}
                                                        >
                                                            <SwitchItem disabled />
                                                        </FormItem>
                                                        {devolverDinero &&
                                                            <FormItem
                                                                required
                                                                margin
                                                                name="bancoId"
                                                                label="Banco"
                                                                labelCol={{ span: 6 }}
                                                                wrapperCol={{ span: 18 }}
                                                            >
                                                                <SelectItem disabled placeholder="Banco" >
                                                                    {this.state.objetoBancos}
                                                                </SelectItem>
                                                            </FormItem>
                                                        }
                                                    </>
                                                )
                                            }}
                                        </FormItem>
                                    </BoxItem>
                                </Col>
                                <Col span={18}>
                                    <HeaderItem>FORMA DE PAGO</HeaderItem>
                                    <BoxItem>
                                        <FormItem
                                            noStyle
                                            shouldUpdate={(prev, curr) =>
                                                prev.contado !== curr.contado ||
                                                prev.factura !== curr.factura
                                            }
                                        >
                                            {({ getFieldValue }) => {
                                                const factura = getFieldValue("factura");
                                                const usoCFDI =
                                                    factura === 1 ? (
                                                        <FormItem
                                                            name="usoDeCfdiId"
                                                            label="Uso de CFDI"
                                                            initialValue="6123bd944518d23048736747"
                                                            margin
                                                            required
                                                            labelCol={{ span: 8 }}
                                                            wrapperCol={{ span: 16 }}
                                                        >
                                                            <SelectItem disabled placeholder="Uso de CFDI">
                                                                {this.state.objetoUsosDeCFDI}
                                                            </SelectItem>
                                                        </FormItem>
                                                    ) : null;
                                                return (
                                                    <>
                                                        <Row>
                                                            <Col span={8}>
                                                                <FormItem
                                                                    label="Proyecto"
                                                                    name="proyectoId"
                                                                    margin
                                                                    required
                                                                    labelCol={{ span: 8 }}
                                                                    wrapperCol={{ span: 16 }}
                                                                >
                                                                    <SelectItem
                                                                        placeholder="Proyecto"
                                                                        disabled
                                                                    >
                                                                        {this.state.objetoProyectos}
                                                                    </SelectItem>
                                                                </FormItem>
                                                            </Col>
                                                            <Col span={8}>
                                                                <FormItem
                                                                    name="precioId"
                                                                    label="Precio"
                                                                    margin
                                                                    required
                                                                    labelCol={{ span: 8 }}
                                                                    wrapperCol={{ span: 16 }}
                                                                >
                                                                    <SelectItem
                                                                        placeholder="Precio"
                                                                        disabled
                                                                    >
                                                                        {this.state.objetoPrecios}
                                                                    </SelectItem>
                                                                </FormItem>
                                                            </Col>

                                                        </Row>
                                                        <Row>
                                                            <Col span={8}>
                                                                <FormItem noStyle shouldUpdate={(prev, curr) => prev.contado !== curr.contado}>
                                                                    {({ getFieldValue }) => {
                                                                        // const contado = getFieldValue('contado')
                                                                        return (
                                                                            <FormItem
                                                                                name="formaDePagoId"
                                                                                label="F Pago"
                                                                                initialValue="6125149afe07662acc100d58"
                                                                                margin
                                                                                required
                                                                                labelCol={{ span: 8 }}
                                                                                wrapperCol={{ span: 16 }}
                                                                            >
                                                                                <SelectItem disabled placeholder="Forma de Pago">
                                                                                    {this.state.objetoFormasDePago}
                                                                                </SelectItem>
                                                                            </FormItem>
                                                                        )
                                                                    }}
                                                                </FormItem>
                                                            </Col>
                                                            <Col span={8}>
                                                                {usoCFDI}
                                                            </Col>
                                                        </Row>
                                                    </>
                                                );
                                            }}
                                        </FormItem>
                                    </BoxItem>
                                </Col>
                            </Row>
                            <HeaderItem>INSUMOS</HeaderItem>
                            <BoxItem>
                                <FormInitial.List name="insumos">
                                    {(fields, { add, remove }) => (
                                        <>
                                            <table className="table">
                                                {fields.length > 0 ? (
                                                    <thead>
                                                        <tr>
                                                            <th className="th-border" style={{ width: "22%" }}>
                                                                Insumo
                                                            </th>
                                                            <th className="th-border" style={{ width: "12%" }}>
                                                                Proyecto
                                                            </th>
                                                            <th className="th-border" style={{ width: "12%" }}>
                                                                PU
                                                            </th>
                                                            <th className="th-border" style={{ width: "12%" }}>
                                                                Precio Neto
                                                            </th>
                                                            <th className="th-border" style={{ width: "10%" }}>
                                                                Cant.
                                                            </th>
                                                            <th className="th-border" style={{ width: "10%" }}>
                                                                Desc.
                                                            </th>
                                                            <th className="th-border" style={{ width: "10%" }}>
                                                                Subtotal
                                                            </th>
                                                            <th className="th-border" style={{ width: "10%" }}>
                                                                Impuestos
                                                            </th>
                                                            <th className="th-border" style={{ width: "10%" }}>
                                                                Total
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                ) : null}
                                                {fields.map(
                                                    ({ key, name, fieldKey, ...restField }, arrayKey) => (
                                                        <tbody key={arrayKey}>
                                                            <tr>
                                                                <td className="td-border">
                                                                    <FormItem
                                                                        {...restField}
                                                                        name={[name, "insumoId"]}
                                                                        required
                                                                        noStyle
                                                                    >
                                                                        <SelectItem
                                                                            sinBorde
                                                                            autoFocus
                                                                            disabled
                                                                            dropdownMatchSelectWidth={600}
                                                                            width="100%"
                                                                            placeholder="Insumo"
                                                                            onChange={(value) =>
                                                                                this.onChangeInsumoProyecto(
                                                                                    value,
                                                                                    arrayKey,
                                                                                    'insumo'
                                                                                )
                                                                            }
                                                                        >
                                                                            {this.state.objetoInsumos}
                                                                        </SelectItem>
                                                                    </FormItem>
                                                                </td>
                                                                <td className="td-border">
                                                                    <FormItem
                                                                        shouldUpdate={(prev, curr) =>
                                                                            prev.proyectoId !== curr.proyectoId
                                                                        }
                                                                        noStyle
                                                                    >
                                                                        {({ getFieldValue }) => {
                                                                            const proyectoId =
                                                                                getFieldValue("proyectoId");
                                                                            return (
                                                                                <FormItem
                                                                                    {...restField}
                                                                                    name={[name, "proyectoId"]}
                                                                                    required
                                                                                    noStyle
                                                                                    initialValue={proyectoId}
                                                                                >
                                                                                    <SelectItem
                                                                                        sinBorde
                                                                                        disabled
                                                                                        width="100%"
                                                                                        placeholder="Proyecto"
                                                                                        onChange={(value) =>
                                                                                            this.onChangeInsumoProyecto(
                                                                                                value,
                                                                                                arrayKey,
                                                                                                'proyecto'
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        {this.state.objetoProyectos}
                                                                                    </SelectItem>
                                                                                </FormItem>
                                                                            );
                                                                        }}
                                                                    </FormItem>
                                                                </td>
                                                                <td className="td-border">
                                                                    <FormItem
                                                                        {...restField}
                                                                        name={[name, "precioUnitario"]}
                                                                        noStyle
                                                                        required
                                                                        numberCero
                                                                    >
                                                                        <InputNumberItem
                                                                            sinBorde
                                                                            disabled
                                                                            onChange={(value) =>
                                                                                this.onChangePrecioCantidadDescuento(
                                                                                    value,
                                                                                    arrayKey
                                                                                )
                                                                            }
                                                                            placeholder="Precio Unitario"
                                                                            dinero
                                                                        />
                                                                    </FormItem>
                                                                </td>
                                                                <td className="td-border">
                                                                    <FormItem
                                                                        {...restField}
                                                                        name={[name, "precioUnitarioConImpuestos"]}
                                                                        noStyle
                                                                        required
                                                                    >
                                                                        <InputNumberItem
                                                                            sinBorde
                                                                            disabled
                                                                            placeholder="Precio Unitario con Impuestos"
                                                                            dineroDisabled
                                                                        />
                                                                    </FormItem>
                                                                </td>
                                                                <td className="td-border">
                                                                    <FormItem
                                                                        noStyle
                                                                        shouldUpdate={(prev, curr) =>
                                                                            prev.insumos[arrayKey]?.cantidadProyecto !==
                                                                            curr.insumos[arrayKey]?.cantidadProyecto
                                                                        }
                                                                    >
                                                                        {({ getFieldValue }) => {
                                                                            const insumos = getFieldValue("insumos");
                                                                            const cantidadProyecto =
                                                                                insumos[arrayKey].cantidadProyecto;
                                                                            let max = 0;
                                                                            if (
                                                                                cantidadProyecto === "No inventariable"
                                                                            ) {
                                                                                max = Infinity;
                                                                            } else {
                                                                                max = cantidadProyecto;
                                                                            }
                                                                            return (
                                                                                <FormItem
                                                                                    {...restField}
                                                                                    name={[name, "cantidad"]}
                                                                                    number
                                                                                    noStyle
                                                                                    type="number"
                                                                                >
                                                                                    <InputNumberItem
                                                                                        sinBorde
                                                                                        disabled
                                                                                        placeholder={
                                                                                            max && max !== Infinity
                                                                                                ? "Max: " + max
                                                                                                : "Cantidad"
                                                                                        }
                                                                                        onChange={(value) =>
                                                                                            this.onChangePrecioCantidadDescuento(
                                                                                                value,
                                                                                                arrayKey
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </FormItem>
                                                                            );
                                                                        }}
                                                                    </FormItem>
                                                                </td>
                                                                <td className="td-border">
                                                                    <FormItem
                                                                        {...restField}
                                                                        name={[name, "descuento"]}
                                                                        required
                                                                        numberCero
                                                                        noStyle
                                                                        initialValue={0}
                                                                    >
                                                                        <InputNumberItem
                                                                            sinBorde
                                                                            disabled
                                                                            placeholder="Descuento"
                                                                            porcentaje
                                                                            max={100}
                                                                            onChange={(value) =>
                                                                                this.onChangePrecioCantidadDescuento(
                                                                                    value,
                                                                                    arrayKey
                                                                                )
                                                                            }
                                                                        />
                                                                    </FormItem>
                                                                </td>
                                                                <td className="td-border">
                                                                    <FormItem
                                                                        {...restField}
                                                                        name={[name, "subtotal"]}
                                                                        required
                                                                        numberCero
                                                                        noStyle
                                                                    >
                                                                        <InputNumberItem
                                                                            sinBorde
                                                                            disabled
                                                                            placeholder="Subtotal"
                                                                            dineroDisabled
                                                                        />
                                                                    </FormItem>
                                                                </td>
                                                                <td className="td-border">
                                                                    <FormItem
                                                                        {...restField}
                                                                        name={[name, "impuestos"]}
                                                                        required
                                                                        numberCero
                                                                        noStyle
                                                                    >
                                                                        <InputNumberItem
                                                                            sinBorde
                                                                            disabled
                                                                            dineroDisabled
                                                                            placeholder="Impuestos"
                                                                        />
                                                                    </FormItem>
                                                                </td>
                                                                <td className="td-border">
                                                                    <FormItem
                                                                        {...restField}
                                                                        name={[name, "total"]}
                                                                        required
                                                                        numberCero
                                                                        noStyle
                                                                    >
                                                                        <InputNumberItem
                                                                            sinBorde
                                                                            dineroDisabled
                                                                            disabled
                                                                            placeholder="Total"
                                                                        />
                                                                    </FormItem>
                                                                </td>
                                                            </tr>
                                                            <FormItem noStyle shouldUpdate={(prev, curr) => prev.insumos[arrayKey]?.leyenda !== curr.insumos[arrayKey]?.leyenda}>
                                                                {({ getFieldValue }) => {
                                                                    let arrayValue = getFieldValue("insumos");
                                                                    if (arrayValue[arrayKey]?.leyenda) {
                                                                        return (
                                                                            <tr>
                                                                                <td colSpan="9" className="td-border">
                                                                                    <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left' }}>
                                                                                        <div style={{ width: '90%', textAlign: 'left', paddingLeft: 20 }}>
                                                                                            <b>Leyenda:</b> {arrayValue[arrayKey].leyenda}
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                }}
                                                            </FormItem>
                                                        </tbody>
                                                    )
                                                )}
                                            </table>
                                        </>
                                    )}
                                </FormInitial.List>
                                <Row style={{ marginTop: 10 }}>
                                    <Col span={12}>
                                        <FormItem
                                            pattern
                                            name='observaciones'
                                            label='Observaciones'
                                        >
                                            <TextAreaItem />
                                        </FormItem>
                                    </Col>
                                    <Col span={4}>
                                        <FormItem
                                            label="Imprimir"
                                            name="imprimirObservaciones"
                                            valuePropName="checked"
                                        >
                                            <Checkbox></Checkbox>
                                        </FormItem>
                                    </Col>
                                    <Col span={8}>
                                        <FormItem shouldUpdate noStyle>
                                            {({ getFieldValue }) => {
                                                return (
                                                    <table className="table" style={{ marginTop: 0 }}>
                                                        <tbody>
                                                            <tr>
                                                                <th
                                                                    className="th-totales"
                                                                    style={{ width: "50%" }}
                                                                >
                                                                    Subtotal
                                                                </th>
                                                                <td
                                                                    className="td-totales-right"
                                                                    style={{ width: "50%" }}
                                                                >
                                                                    {dineroDisabledMask(this.state.subtotal)}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        {this.state.arrayImpuestos.map((valueArray) => (
                                                            <tbody>
                                                                <tr key={valueArray.descripcionFactura}>
                                                                    <th className="th-totales">
                                                                        {valueArray.descripcionFactura}
                                                                    </th>
                                                                    <td className="td-totales-right">
                                                                        {dineroDisabledMask(
                                                                            valueArray.totalImpuestos
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        ))}
                                                        <tbody>
                                                            <tr>
                                                                <th className="th-totales">Total</th>
                                                                <td className="td-totales-right">
                                                                    {dineroDisabledMask(this.state.total)}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        {this.state.arrayRetenciones.map((value) => (
                                                            <tbody>
                                                                <tr>
                                                                    <th className="th-totales">
                                                                        {value.descripcionFactura}
                                                                    </th>
                                                                    <td className="td-totales-right">
                                                                        {dineroDisabledMask(value.totalRetenciones)}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        ))}
                                                        <tbody>
                                                            <tr>
                                                                <th className="th-totales">Total a Pagar</th>
                                                                <td className="td-totales-right">
                                                                    {dineroDisabledMask(this.state.totalPagar)}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                );
                                            }}
                                        </FormItem>
                                    </Col>
                                </Row>
                            </BoxItem>
                        </Form>
                    </Spin>
                </div>
                <MandarCorreoDevolucion
                    visible={this.state.modalCorreo}
                    id={this.state.modalCorreoId}
                    cerrarMandarCorreoDevolucion={this.cerrarMandarCorreoDevolucion.bind(this)}
                />
                <PagosDevolucion
                    visible={this.state.modalPagos}
                    id={this.state.id}
                    cerrarPagosDevolucion={this.cerrarPagosDevolucion.bind(this)}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        headersToken: state.user.headersToken,
        empresaId: state.empresa.id,
        permisoFacturarVentas:
            state.user.tipo === 0 ||
            state.user.permisos.find(
                (valueTodos) =>
                    valueTodos.permisoId.toString() === "615ce36bf30e81b54183cfaf"
            )?.activado,
        certificada: state.empresa.certificada,
    };
};

export default connect(mapStateToProps)(VerDevolucion);
