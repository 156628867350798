import { LockOutlined } from "@ant-design/icons";
import { Button, message, Input } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  ModalItem,
  FormItem,
  ErrorItem,
} from "../../../../Components/Items";

class CambiarContrasena extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    todasPerfiles: null,
    objetoPerfiles: null,
  };
  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible && this.props.visible)
      this.consultarTodo();
  }
  cerrarCambiarContrasena(codigo) {
    this.props.cerrarCambiarContrasena(codigo);
  }
  async consultarTodo() {
    this.setState({ loading: true });
    this.setState({ loading: false });
  }
  onFinishFailed(e) {
    ErrorItem(e);
  }
  onFinish(values) {
    this.setState({ loading: true });
    if (values.password === values.confirmarcontraseña) {
    axios
      .post(
        "usuarios/cambiarPassword",
        { ...values, usuarioPrincipal: this.props.usuarioPrincipal, usuarioId: this.props.id },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data?.success === 2) {
            message.success("Se ha cambiado la contraseña de un usuario");
            this.formRef.current.resetFields();
            this.cerrarCambiarContrasena();
          } else {
                message.error('Ha sucedido un error y no se pudo editar la contraseña')
          }
      });
    } else {
        this.setState({ loading: false });
        message.error("Las contraseñas no coinciden");
      }
  }
  render() {
    return (
      <>
        <ModalItem
          title="Cambiar Contraseña"
          visible={this.props.visible}
          spinning={this.state.loading}
          onCancel={this.cerrarCambiarContrasena.bind(this)}
          paddingTop
        >
          <Form
            ref={this.formRef}
            name="cambiarContraseña"
            layout
            bottom={
              <Button
                type="primary"
                htmlType="submit"
                style={{ display: "block", margin: "0 0 0 auto" }}
              >
                Guardar
              </Button>
            }
            onFinish={this.onFinish.bind(this)}
            onFinishFailed={this.onFinishFailed}
          >
                              <FormItem name="password" pattern required max={200} min={5} label='Contraseña'>
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="Contraseña"
                  />
                </FormItem>
                <FormItem
                  name="confirmarcontraseña"
                  pattern
                  required
                  max={200}
                  min={5}
                  label='Confirmar Contraseña'
                                  >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="Confirmar Contraseña"
                  />
                </FormItem>
          </Form>
        </ModalItem>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    usuarioPrincipal: state.user.usuarioPrincipal,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(CambiarContrasena);
