import {
  CheckCircleOutlined,
  CopyOutlined,
  EditOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { PageHeader, Space, Popconfirm, message } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { TableItem, ButtonItem, SegmentedItem } from "../../../Components/Items";
import DuplicarMatriz from "./DuplicarMatriz/DuplicarMatriz";

class Matrices extends Component {
  state = {
    idEditarInsumoCompuesto: null,
    todasMatrices: [],
    loading: false,
    valor: 1,
  };
  componentDidMount() {
    this.consultarMatrices(this.state.valor);
  }
  consultarMatrices(valor) {
    this.setState({ loading: true })
    axios
      .post(
        "matrices/selectTodasMatrices",
        { empresaId: this.props.empresaId, valor: valor },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false })
        if (res.data.status === 404) {
          this.setState({ todasMatrices: [] });
        } else {
          this.setState({
            todasMatrices: res.data
          });
        }
      });
  }
  deshabilitarMatriz(value) {
    axios
      .post(
        "matrices/deshabilitarMatriz",
        { insumoId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Matriz deshabilitado");
        } else {
          message.error("Sucedio un eror y no se pudo deshabilitar");
        }
        this.consultarMatrices(this.state.valor);
      });
  }
  habilitarMatriz(value) {
    axios
      .post(
        "matrices/habilitarMatriz",
        { insumoId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Matriz habilitado");
        } else {
          message.error("Sucedio un eror y no se pudo habilitar");
        }
        this.consultarMatrices(this.state.valor);
      });
  }
  agregarMatriz() {
    this.props.history.push("/panel/agregarMatriz");
  }
  editarMatriz(id) {
    this.props.history.push("/panel/editarMatriz", { id });
  }

  actualizarCostos() {
    this.setState({ loading: true })
    axios
      .post(
        "matrices/actualizarCostos",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.success === 2) {
          this.consultarMatrices(this.state.valor);
        } else {
          message.warning('No se pudo actualizar los costos')
          this.setState({ loading: false })
        }
      });
  }

  duplicarMatriz(id) {
    this.setState({ modalDuplicar: true, modalDuplicarId: id })
  }

  cerrarDuplicarMatriz() {
    this.setState({ modalDuplicar: false, modalDuplicarId: null })
    this.consultarMatrices(this.state.valor)
  }

  render() {
    const columns = [
      {
        title: "Codigo",
        dataIndex: "codigo",
        key: "codigo",
        defaultSortOrder: 'ascend',
        sorter: (a, b) => {
          if (a.codigo < b.codigo) {
            return -1;
          }
          if (a.codigo > b.codigo) {
            return 1;
          }
          return 0;
        },
        buscar: "codigo",
        width: "10%",
      },
      {
        title: "Clave",
        dataIndex: "clave",
        key: "clave",
        sorter: (a, b) => {
          if (a.clave < b.clave) {
            return -1;
          }
          if (a.clave > b.clave) {
            return 1;
          }
          return 0;
        },
        buscar: "clave",
        width: "10%",
      },
      {
        title: "Descripcion",
        dataIndex: "descripcion",
        key: "descripcion",
        sorter: (a, b) => {
          if (a.descripcion < b.descripcion) {
            return -1;
          }
          if (a.descripcion > b.descripcion) {
            return 1;
          }
          return 0;
        },
        buscar: "descripcion",
        width: "36%",
      },
      {
        title: "Costo",
        dataIndex: "costo",
        key: "costo",
        sorter: (a, b) => {
          if (a.costo < b.costo) {
            return -1;
          }
          if (a.costo > b.costo) {
            return 1;
          }
          return 0;
        },
        render: (value) => "$ " + value.toFixed(2),
        width: "6%",
        align: 'right'
      },
      {
        title: "Unidad",
        dataIndex: "unidad",
        key: "unidad",
        sorter: (a, b) => {
          if (a.unidad < b.unidad) {
            return -1;
          }
          if (a.unidad > b.unidad) {
            return 1;
          }
          return 0;
        },
        width: "6%",
      },
      {
        title: "Familia",
        dataIndex: "familia",
        key: "familia",
        sorter: (a, b) => {
          if (a.familia < b.familia) {
            return -1;
          }
          if (a.familia > b.familia) {
            return 1;
          }
          return 0;
        },
        width: "21%",
      },
      {
        title: "Accion",
        dataIndex: "accion",
        key: "accion",
        render: (value) => (
          <Space size="small">
            {this.props.permisoEditarMatriz &&
              <EditOutlined
                onClick={() => this.editarMatriz(value._id)}
                style={{ color: "blue" }}
              />
            }
            {this.props.permisoDeshabilitarMatriz &&
              <>
                {value.estado === 1 ? (
                  <Popconfirm
                    title="Seguro que quieres deshabilitar esta matriz"
                    onConfirm={() => this.deshabilitarMatriz(value._id)}
                    okText="Si"
                    cancelText="No"
                  >
                    <CheckCircleOutlined style={{ color: "green" }} />
                  </Popconfirm>
                ) : (
                  <Popconfirm
                    title="Seguro que quieres habilitar esta matriz"
                    onConfirm={() => this.habilitarMatriz(value._id)}
                    okText="Si"
                    cancelText="No"
                  >
                    <StopOutlined style={{ color: "red" }} />
                  </Popconfirm>
                )}
              </>
            }
            <CopyOutlined
              onClick={() => this.duplicarMatriz(value._id)}
            />
          </Space>
        ),
        width: "12%",
      },
    ];
    return (
      <>
        <PageHeader
          title="Matrices"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={this.props.permisoAgregarInsumoCompuesto &&
            <>
              {window.innerWidth > 1000 &&
                <SegmentedItem
                  options={[{ label: 'Activo', value: 1 }, { label: 'Deshabilitado', value: 2 }, { label: 'Todos', value: 3 }]}
                  onChange={(value) => {
                    this.consultarMatrices(value)
                    this.setState({ valor: value })
                  }}
                  value={this.state.valor}
                />
              }
              <Popconfirm
                title={
                  "Seguro que quieres actualizar todos los costos"
                }
                onConfirm={this.actualizarCostos.bind(this)}
                okText="Si"
                cancelText="No"
              >
                {this.props.permisoActualizarCostos &&
                  <ButtonItem
                    key="actualizarCostos"
                    blanco
                  >
                    Actualizar Costos
                  </ButtonItem>}
              </Popconfirm>
              {this.props.permisoAgregarMatriz &&
                <ButtonItem
                  type="primary"
                  key="agregarMatriz"
                  onClick={this.agregarMatriz.bind(this)}
                >
                  Agregar Matriz
                </ButtonItem>}
            </>
          }
        />
        <TableItem
          loading={this.state.loading}
          size="small"
          dataSource={this.state.todasMatrices}
          columns={columns}
          pagination={false}
          onRow={(record) => ({
            onDoubleClick: () => this.editarMatriz(record._id),
          })}
        />
        <DuplicarMatriz
          visible={this.state.modalDuplicar}
          id={this.state.modalDuplicarId}
          cerrarDuplicarMatriz={this.cerrarDuplicarMatriz.bind(this)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    permisoAgregarInsumoCompuesto: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "6472d8d84b2da7c85c1a8d0b")?.activado,
    permisoEditarInsumoCompuesto: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "6472d66d4b2da7c85c1a8d06")?.activado,
    permisoDeshabilitarInsumoCompuesto: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "6472d69a4b2da7c85c1a8d07")?.activado,
    permisoActualizarCostos: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "675095b5798d4e0043687251")?.activado,
    permisoAgregarMatriz: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "6750960a798d4e0043687252")?.activado,
    permisoEditarMatriz: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "67509631798d4e0043687253")?.activado,
    permisoDeshabilitarMatriz: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "67509647798d4e0043687254")?.activado,
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(Matrices);
