import React, {Component} from "react";
import {Input} from 'antd'

class TextAreaItem extends Component {
    render() {
        const { style, sinBorde, width, ...rest } = this.props;

        const customStyle = {
            borderBottom: sinBorde ? 'none' : '1px solid #E0FFFF',//controla el estilo del borde inferior
            borderRadius: '4px', // Pequeño redondeo para bordes
            padding: '6px 12px', // Añadir padding para hacerlo más cómodo
            width: width || '100%',
            backgroundColor: '#FFFFFF', // Fondo ligeramente gris
            transition: 'border-color 0.3s ease', // Transición suave para el borde
            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)', // Sombra suave
            ...style,
        };

        return (
            <Input.TextArea 
                {...rest} 
                style={customStyle} 
                bordered={false} 
            />
        );
    }
}

export default TextAreaItem;

/*import React, {Component} from "react";
import {Input} from 'antd'

class TextAreItem extends Component{
    state={
        value: ''
    }
    render(){
        const style = {
            ...this.props.style,
            borderBottom: this.props.sinBorde ? null : '1px solid #DADADA',
            borderRadius: 0,
            width: this.props.width ? this.props.width : '100%',
        }
        return(
            <Input.TextArea {...this.props} style={style} bordered={false}/>
        )
    }
}

export default TextAreItem*/