import {
    message,
    PageHeader,
    Spin,
    Select,
    Form as FormInitial,
    Divider,
    Row,
    Col,
  } from "antd";
  import axios from "axios";
  import React, { Component } from "react";
  import { connect } from "react-redux";
  import {
    Form,
    FormItem,
    SelectItem,
    InputNumberItem,
    NotificacionPolizas,
    ButtonItem,
    ErrorItem
  } from "../../../../Components/Items";
  import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import VistaPrevia from "../VistaPrevia/VistaPrevia";
  
  const { Option } = Select;
  
  class TraspasoEntreAlmacenes extends Component {
    formRef = React.createRef();
    state = {
      loading: false,
      objetoProyectos: null,
      todasProyectos: null,
      todasInsumos: null,
      objetoInsumos: null,
      modalVistaPrevia: false,
      modalVistaPreviaId: null,
    };
    componentDidMount() {
      this.consultarTodo();
    }
    async consultarTodo() {
      this.setState({ loading: true });
      await axios
        .post(
          "proyectos/selectProyectos",
          { empresaId: this.props.empresaId },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          if (res.data.status === 404) {
          } else {
            this.setState({ todasProyectos: res.data });
            this.setState({
              objetoProyectos: res.data.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {value.nombre}
                  </Option>
                );
              }),
            });
          }
        });
        await axios
        .post(
          "insumos/selectInsumosInventariables",
          { empresaId: this.props.empresaId },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          if (res.data.status === 404) {
          } else {
            this.setState({ todasInsumos: res.data });
            this.setState({
              objetoInsumos: res.data.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {value.stringBuscar}
                  </Option>
                );
              }),
            });
          }
        });
      this.setState({ loading: false });
    }
    atras() {
      this.props.history.goBack();
    }
    onFinishFailed(e) {
        ErrorItem(e);
    }
    onFinish(values) {
      this.setState({ loading: true });
      axios
        .post(
          "traspasoEntreAlmacenes/traspasoEntreAlmacenes",
          {
            ...values,
            empresaId: this.props.empresaId,
          },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          this.setState({ loading: false });
          if (res.data.success === 2) {
            message.success(
              "Se ha traspasado el inventario"
            );
            NotificacionPolizas(res.data.polizasId)
            this.setState({
              modalVistaPrevia: true,
              modalVistaPreviaId: res.data.traspasoId,
            });
          } else if (res.data.success === 1) {
            message.error("No se puede traspasar el inventario");
          } else {
            message.error("Hubo un error y el inventario no se traspaso");
          }
        });
    }
    async onChangeInsumo(value, key) {
        let arrayValue = await this.formRef.current.getFieldValue("insumos");
        let proyectoOrigen = await this.formRef.current.getFieldValue("proyectoOrigenId");
        const insumoId = arrayValue[key].insumoId;
        const insumo = this.state.todasInsumos.find(
          (valueInsumos) => valueInsumos._id === insumoId
        );
        if(!insumo) return
          await axios
            .post(
              "inventarios/inventarioInsumoProyecto",
              {
                proyectoId: proyectoOrigen,
                insumoId: insumoId,
                empresaId: this.props.empresaId,
              },
              { headers: this.props.headersToken }
            )
            .then((res) => {
              this.setState({ loading: false });
              arrayValue[key].cantidadProyecto = res.data.cantidad;
            });
        this.formRef.current.setFieldsValue({
          insumos: arrayValue,
        });
      }
    cerrarVistaPrevia() {
      this.setState({
        modalVistaPrevia: false,
        modalVistaPreviaId: null,
      });
      this.formRef.current.resetFields();
      this.atras();
    }
    render() {
      const isMobile = window.innerWidth <= 1000;
      return (
        <>
          <PageHeader
            onBack={this.atras.bind(this)}
            title="Traspaso Entre Almacenes"
            style={{
              border: "1px solid rgb(235, 237, 240)",
              backgroundColor: "white",
              marginBottom: 10,
            }}
          />
          <div style={{ backgroundColor: "white", padding: 20 }}>
            <Spin spinning={this.state.loading}>
              <Form
                ref={this.formRef}
                name="traspasoEntreAlmacenes"
                layout
                bottom={
                  <ButtonItem
                    type="primary"
                    htmlType="submit"
                    style={{ display: "block", margin: "0 0 0 auto" }}
                  >
                    Traspasar
                  </ButtonItem>
                }
                onFinish={this.onFinish.bind(this)}
                onFinishFailed={this.onFinishFailed}
              >
                <Row>
                <Col span={12} xs={24} sm={24}  lg={12} xl={12}>
                    <FormItem shouldUpdate noStyle>
                        {({getFieldValue}) => {
                            const insumos = getFieldValue('insumos')
                            const disabled = insumos?.length > 0
                            return (
                            <FormItem label="Proyecto Origen" name="proyectoOrigenId" margin required>
                                <SelectItem placeholder="Proyecto Origen" disabled={disabled}>
                                {this.state.objetoProyectos}
                                </SelectItem>
                            </FormItem>
                            )
                        }}
                    </FormItem>
                    </Col>
                    <Col span={12} xs={24} sm={24}  lg={12} xl={12}>
                    <FormItem label="Proyecto Destino" name="proyectoDestinoId" margin required>
                        <SelectItem placeholder="Proyecto Destino">
                        {this.state.objetoProyectos}
                        </SelectItem>
                    </FormItem>
                </Col>
                </Row>
                <Divider
                  style={{ marginBottom: 10, marginTop: 5 }}
                  children="Insumos"
                />
                   <FormInitial.List name="insumos">
                    {(fields, { add, remove }) => (
                      <>
                      <div style={{overflowX: 'auto'}} >
                        <table className="table" style={{ minWidth: isMobile ? "900px" : "100%" }}>
                          {fields.length > 0 ? (
                            <thead>
                              <tr>
                                <th className="th-border" style={{width: '60%'}}>Insumo</th>
                                <th className="th-border" style={{width: '20%'}}>Cantidad Proyecto</th>
                                <th className="th-border" style={{width: '20%'}}>Cantidad</th>
                                </tr>
                            </thead>
                          ) : null}
                          {fields.map(
                            ({ key, name, fieldKey, ...restField }, arrayKey) => (
                              <tbody>
                                <tr>
                                  <td className="td-border">
                                    <FormItem
                                      {...restField}
                                      name={[name, "insumoId"]}
                                      fieldKey={[arrayKey, "insumoId"]}
                                      required
                                      noStyle
                                    >
                                      <SelectItem
                                        sinBorde
                                       autoFocus
                                        width="100%"
                                        placeholder="Insumo"
                                        onChange={(value) =>
                                            this.onChangeInsumo(
                                              value,
                                              arrayKey
                                            )
                                          }
                                      >
                                        {
                                          this.state
                                            .objetoInsumos
                                        }
                                      </SelectItem>
                                    </FormItem>
                                  </td>
                                  <td className="td-border">
                                        <FormItem
                                        {...restField}
                                        name={[name, "cantidadProyecto"]}
                                        fieldKey={[arrayKey, "cantidadProyecto"]}
                                        number
                                        noStyle
                                        required
                                        initialValue={undefined}
                                        >
                                        <InputNumberItem sinBorde disabled/>
                                        </FormItem>
                                  </td>
                                  <td className="td-border">
                                  <FormItem
                                  noStyle
                                  shouldUpdate={(prev, curr) =>
                                    prev.insumos[arrayKey]?.cantidadProyecto !==
                                    curr.insumos[arrayKey]?.cantidadProyecto
                                  }
                                >
                                  {({ getFieldValue }) => {
                                    const insumos = getFieldValue("insumos");
                                    if(!insumos[arrayKey]) return
                                    const cantidadProyecto =
                                      insumos[arrayKey].cantidadProyecto;
                                    let max = cantidadProyecto;
                                    return (
                                        <FormItem
                                        {...restField}
                                        name={[name, "cantidad"]}
                                        fieldKey={[arrayKey, "cantidad"]}
                                        number
                                        noStyle
                                        required
                                        max={max}
                                        type='number'
                                        initialValue={undefined}
                                        >
                                        <InputNumberItem sinBorde />
                                        </FormItem>
                                        );
                                    }}
                                    </FormItem>
                                  </td>
                                  <td>
                                    <MinusCircleOutlined
                                      onClick={() => remove(name)}
                                      style={{
                                        alignSelf: "center",
                                        justifySelf: "center",
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            )
                          )}
                        </table>
                        </div>
                        <FormItem
                            noStyle
                            shouldUpdate={(prev, curr) =>
                                prev.proyectoOrigenId !== curr.proyectoOrigenId
                            }
                            >
                            {({ getFieldValue }) => {
                                const proyectoOrigen = getFieldValue("proyectoOrigenId");
                                return (
                                <FormItem noStyle>
                                    <ButtonItem
                                    style={{ marginTop: 10, marginBottom: 10 }}
                                    type="dashed"
                                    onClick={
                                        proyectoOrigen
                                        ? () => add()
                                        : () =>
                                            message.info(
                                                "Agregue el proyecto origen primero"
                                            )
                                    }
                                    block
                                    icon={<PlusOutlined />}
                                    >
                                    Agregar Campo
                                    </ButtonItem>
                                </FormItem>
                                );
                            }}
                            </FormItem>
                      </>
                    )}
                  </FormInitial.List>
              </Form>
            </Spin>
          </div>
          <VistaPrevia
            visible={this.state.modalVistaPrevia}
            id={this.state.modalVistaPreviaId}
            cerrarVistaPrevia={this.cerrarVistaPrevia.bind(this)}
          />
        </>
      );
    }
  }
  
  const mapStateToProps = (state) => {
    return {
      headersToken: state.user.headersToken,
      empresaId: state.empresa.id,
    };
  };
  
  export default connect(mapStateToProps)(TraspasoEntreAlmacenes);
  