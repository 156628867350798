import {
  CheckCircleOutlined,
  DownOutlined,
  EditOutlined,
  ImportOutlined,
  PlusOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { PageHeader, Space, Popconfirm, message, Checkbox, Menu, Dropdown } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { TableItem, ButtonItem, SegmentedItem } from "../../../Components/Items";

class Insumos extends Component {
  state = {
    idEditarInsumo: null,
    modalAgregarFamilia: false,
    todasInsumos: [],
    loading: false,
    valor: 3
  };
  componentDidMount() {
    this.consultarInsumos(this.state.valor);
  }
  consultarInsumos(valor) {
    this.setState({ loading: true })
    axios
      .post(
        "insumos/selectTodosInsumos",
        { empresaId: this.props.empresaId , valor: valor},
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false })
        if (res.data.status === 404) {
          this.setState({ todasInsumos: [] });
        } else {
          this.setState({
            todasInsumos: res.data.map((data) => {
              let compraVenta
              if (data.compra && data.venta) {
                compraVenta = 'Ambos'
              } else if (data.compra) {
                compraVenta = 'Compra'
              } else if (data.venta) {
                compraVenta = 'Venta'
              } else {
                compraVenta = 'Ninguno'
              }
              return {
                ...data,
                compraVenta
              }
            })
          });
        }
      });
  }
  deshabilitarInsumo(value) {
    axios
      .post(
        "insumos/deshabilitarInsumo",
        { insumoId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Insumo deshabilitado");
        } else {
          message.error("Sucedio un eror y no se pudo deshabilitar");
        }
        this.consultarInsumos(this.state.valor);
      });
  }
  habilitarInsumo(value) {
    axios
      .post(
        "insumos/habilitarInsumo",
        { insumoId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Insumo habilitado");
        } else {
          message.error("Sucedio un eror y no se pudo habilitar");
        }
        this.consultarInsumos(this.state.valor);
      });
  }
  agregarInsumo() {
    this.props.history.push("/panel/agregarInsumo");
  }
  editarInsumo(id) {
    this.props.history.push("/panel/editarInsumo", { id });
  }
  abrirAgregarFamilia() {
    this.setState({ modalAgregarFamilia: true });
  }
  cerrarAgregarFamilia() {
    this.setState({ modalAgregarFamilia: false });
    this.consultarInsumos();
  }

  importarInsumos(){
    this.props.history.push('/panel/importarInsumos')
  }

  handleMenuClick(e) {
    if (e.key === '1') {
      this.agregarInsumo()
    }
    if (e.key === '2') {
      this.importarInsumos()
    }
  }


  render() {
    const columns = [
      {
        title: "C / V",
        dataIndex: "compraVenta",
        key: "compraVenta",
        width: "10%",
      },
      /*{
        title: "Codigo",
        dataIndex: "codigo",
        key: "codigo",
        defaultSortOrder: 'ascend',
        sorter: (a, b) => {
          if (a.codigo < b.codigo) {
            return -1;
          }
          if (a.codigo > b.codigo) {
            return 1;
          }
          return 0;
        },
        buscar: "codigo",
        width: "5%",
      },*/
      {
        title: "Clave",
        dataIndex: "clave",
        key: "clave",
        defaultSortOrder: 'ascend',
        sorter: (a, b) => {
          if (Number(a.clave) < Number(b.clave)) {
            return -1;
          }
          if (Number(a.clave) > Number(b.clave)) {
            return 1;
          }
          return 0;
        },
        buscar: "clave",
        width: "5%",
      },
      {
        title: "Descripcion",
        dataIndex: "descripcion",
        key: "descripcion",
        sorter: (a, b) => {
          if (a.descripcion < b.descripcion) {
            return -1;
          }
          if (a.descripcion > b.descripcion) {
            return 1;
          }
          return 0;
        },
        buscar: "descripcion",
        width: "36%",
      },
      {
        title: "Inventariable",
        dataIndex: "inventariable",
        key: "inventariable",
        render: (value) => <Checkbox checked={value} />,
        align: 'center',
        width: "6%",
      },
      {
        title: "Costo",
        dataIndex: "costo",
        key: "costo",
        sorter: (a, b) => {
          if (a.costo < b.costo) {
            return -1;
          }
          if (a.costo > b.costo) {
            return 1;
          }
          return 0;
        },
        render: (value) => "$ " + value.toFixed(2),
        width: "8%",
      },
      {
        title: "Unidad",
        dataIndex: "unidad",
        key: "unidad",
        sorter: (a, b) => {
          if (a.unidad < b.unidad) {
            return -1;
          }
          if (a.unidad > b.unidad) {
            return 1;
          }
          return 0;
        },
        width: "6%",
      },
      {
        title: "Familia",
        dataIndex: "familia",
        key: "familia",
        sorter: (a, b) => {
          if (a.familia < b.familia) {
            return -1;
          }
          if (a.familia > b.familia) {
            return 1;
          }
          return 0;
        },
        width: "21%",
      },
      {
        title: "Accion",
        dataIndex: "accion",
        key: "accion",
        render: (value) => (
          <Space size="middle">
            {this.props.permisoEditarInsumo &&
              <EditOutlined
                onClick={() => this.editarInsumo(value._id)}
                style={{ color: "blue" }}
              />
            }
            {value.estado === 1 ? (
              <Popconfirm
                title="Seguro que quieres deshabilitar este insumo"
                onConfirm={() => this.deshabilitarInsumo(value._id)}
                okText="Si"
                cancelText="No"
              >
                <CheckCircleOutlined style={{ color: "green" }} />
              </Popconfirm>
            ) : (
              <Popconfirm
                title="Seguro que quieres habilitar este insumo"
                onConfirm={() => this.habilitarInsumo(value._id)}
                okText="Si"
                cancelText="No"
              >
                <StopOutlined style={{ color: "red" }} />
              </Popconfirm>
            )}
          </Space>
        ),
        width: "6%",
      },
    ];
    const menu = (
      <Menu onClick={this.handleMenuClick.bind(this)}>
        <Menu.Item key="1" icon={<PlusOutlined />}>
          Agregar Insumo
        </Menu.Item>
        {this.props.permisoImportarInsumo &&
        <Menu.Item disabled key="2" icon={<ImportOutlined />}>
          Importar Insumos
        </Menu.Item>}
      </Menu>
    );
    return (
      <>
        <PageHeader
          title="Insumos"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={this.props.permisoAgregarInsumo &&
            <>
            {window.innerWidth > 1000 &&
            <SegmentedItem
              options={[{label: 'Venta', value: 1}, {label: 'Compra', value: 2}, {label: 'Ambos', value: 3}, {label: 'Ninguno', value: 4}, {label: 'Todos', value: 5}, {label: 'Deshabilitados', value: 6}]}
              onChange={(value) => {
                this.consultarInsumos(value)
                this.setState({ valor: value })
              }}
              value={this.state.valor}
            />
          }
            <Dropdown overlay={menu} trigger={['click']}>
              <ButtonItem type='primary'>
                Agregar <DownOutlined />
              </ButtonItem>
            </Dropdown>
            </>
          }
        />
        <TableItem
          loading={this.state.loading}
          size="small"
          dataSource={this.state.todasInsumos}
          columns={columns}
          pagination={false}
          onRow={(record) => ({
            onDoubleClick: () => this.editarInsumo(record._id),
          })}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    permisoAgregarInsumo: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615ccd1cf30e81b54183cf75")?.activado,
    permisoEditarInsumo: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615ccd41f30e81b54183cf76")?.activado,
    permisoImportarInsumo: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "67509111798d4e004368723e")?.activado,
  };
};

export default connect(mapStateToProps)(Insumos);
