import { message, Checkbox, Row, Col } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  ModalItem,
  FormItem,
  InputItem,
  ButtonItem,
  ErrorItem,
} from "../../../../Components/Items";

class EditarTipoDeFlujo extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    tipoDeFlujo: null,
  };

  componentDidUpdate(prevProps) {
    if ((this.props.visible && !prevProps.visible) || (this.props.visible && this.props.idTipoDeFlujo !== prevProps.idTipoDeFlujo)) {
        this.consultarTipoDeFlujo();
      }
  }

  consultarTipoDeFlujo = () => {
    this.setState({ loading: true });
    axios
      .post("tiposdeflujos/consultarTipoDeFlujo", 
        { flujoId: this.props.idTipoDeFlujo }, 
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false, tipoDeFlujo: res.data });
        this.formRef.current?.setFieldsValue({
          nombre: res.data.nombre || "",
          descripcion: res.data.descripcion || "",
          ingreso: res.data.ingreso || false,
          considerarEnFlujo: res.data.considerarEnFlujo || false,
          generarDIOT: res.data.generarDIOT || false,
        });
      })
      .catch(() => {
        this.setState({ loading: false });
        message.error("Error al consultar el tipo de flujo");
      });
  };

  cerrarEditarTipoDeFlujo(codigo) {
    this.props.cerrarEditarTiposDeFlujos(codigo);
  }

  onFinishFailed(e) {
    ErrorItem(e);
  }

  onFinish = (values) => {
    this.setState({ loading: true });
    axios
      .post("tiposdeflujos/editarTipoDeFlujo", 
        { ...values, flujoId: this.props.idTipoDeFlujo }, 
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success) {
          message.success("Se editó el tipo de flujo correctamente");
          this.cerrarEditarTipoDeFlujo(res.data.id);
        } else {
          message.error("Ocurrió un error y no se guardó");
        }
      })
      .catch(() => {
        this.setState({ loading: false });
        message.error("Error al actualizar el tipo de flujo");
      });
  };

  render() {
    return (
      <ModalItem
        title="Editar Tipo de Flujo"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarEditarTipoDeFlujo.bind(this)}
        paddingTop
        width="50%"
      >
        <Form
          ref={this.formRef}
          name="editarTipoDeFlujo"
          layout
          bottom={
            <ButtonItem
              type="primary"
              htmlType="submit"
              style={{ display: "block", margin: "0 0 0 auto" }}
            >
              Editar
            </ButtonItem>
          }
          onFinish={this.onFinish.bind(this)}
          onFinishFailed={this.onFinishFailed}
        >
          <Row style={{ width: "100%" }}>
            <Col span={12}>
              <FormItem name="nombre" label="Nombre" required max={100} margin>
                <InputItem placeholder="Nombre" />
              </FormItem>
              <FormItem name="descripcion" label="Descripción" margin required>
                <InputItem placeholder="Descripción" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                name="ingreso"
                label="Ingreso"
                specialLayout
                margin
                valuePropName="checked"
              >
                <Checkbox />
              </FormItem>
              <FormItem
                name="considerarEnFlujo"
                label="Considerar en flujo"
                specialLayout
                margin
                valuePropName="checked"
              >
                <Checkbox />
              </FormItem>
              <FormItem
                name="generarDIOT"
                label="Generar DIOT"
                specialLayout
                margin
                valuePropName="checked"
              >
                <Checkbox />
              </FormItem>
            </Col>
          </Row>
        </Form>
      </ModalItem>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(EditarTipoDeFlujo);
