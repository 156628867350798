import React, { Component } from "react";
import { connect } from "react-redux";
import {
    Form,
    FormItem,
    ModalItem,
    ButtonItem,
    ErrorItem,
    AutoCompleteItem
} from "../../../../Components/Items";


class ElegirLeyenda extends Component {
    formRefLeyenda = React.createRef();
    state = {
        loading: false,
        optionsAutoComplete: [],
    };

    componentDidUpdate(prevProps) {
        if (this.props.visible !== prevProps.visible && this.props.visible) {
            if (this.formRefLeyenda.current) {
                this.formRefLeyenda.current.setFieldsValue({ leyenda: this.props.leyenda })
                if (this.props.leyendasPasadas) {
                    this.setState({
                        optionsAutoComplete: this.props.leyendasPasadas.map(leyenda => {
                            return { value: leyenda }
                        })
                    })
                }
            }
        }
    }

    cerrarElegirLeyenda() {
        const leyenda = this.formRefLeyenda.current.getFieldValue('leyenda')
        this.props.cerrarElegirLeyenda(leyenda);
        this.formRefLeyenda.current.resetFields()
    }

    onFinish() {
        this.cerrarElegirLeyenda()
    }

    onFinishFailed(e) {
        ErrorItem(e);
    }

    render() {
        return (
            <>
                <ModalItem
                    title="Leyenda"
                    visible={this.props.visible}
                    spinning={this.state.loading}
                    onCancel={this.cerrarElegirLeyenda.bind(this)}
                    paddingTop
                    width={700}
                >
                    <Form
                        ref={this.formRefLeyenda}
                        name="elegirLeyenda"
                        layout
                        bottom={
                            <ButtonItem
                                type="primary"
                                htmlType="submit"
                                style={{ display: "block", margin: "0 0 0 auto" }}
                            >
                                Guardar
                            </ButtonItem>
                        }
                        onFinish={this.onFinish.bind(this)}
                        onFinishFailed={this.onFinishFailed}
                    >
                        <FormItem
                            name='leyenda'
                            label="Leyenda"
                            pattern
                            margin
                        >
                            <AutoCompleteItem
                                options={this.state.optionsAutoComplete}
                                placeholder="Escribe una leyenda"
                                filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                            >
                            </AutoCompleteItem>
                        </FormItem>

                        {this.props.textoDinamicas && (
                            <div class="styled-list">
                                <h3>Textos dinámicos</h3>
                                <table>
                                    <tr><td><code>&lt;Mes&gt;</code></td><td>Mes de venta (Enero, Febrero, etc.)</td></tr>
                                    <tr><td><code>&lt;MesCo&gt;</code></td><td>Mes de venta corto (Ene, Feb, etc.)</td></tr>
                                    <tr><td><code>&lt;MesNum&gt;</code></td><td>Mes de venta en número (01, 02, etc.)</td></tr>
                                    <tr><td><code>&lt;MesAn&gt;</code></td><td>Mes anterior (Enero, Febrero, etc.)</td></tr>
                                    <tr><td><code>&lt;MesAnCo&gt;</code></td><td>Mes anterior corto (Ene, Feb, etc.)</td></tr>
                                    <tr><td><code>&lt;MesAnNum&gt;</code></td><td>Mes anterior en número (01, 02, etc.)</td></tr>
                                    <tr><td><code>&lt;MesSig&gt;</code></td><td>Mes siguiente (Enero, Febrero, etc.)</td></tr>
                                    <tr><td><code>&lt;MesSigCo&gt;</code></td><td>Mes siguiente corto (Ene, Feb, etc.)</td></tr>
                                    <tr><td><code>&lt;MesSigNum&gt;</code></td><td>Mes siguiente en número (01, 02, etc.)</td></tr>
                                    <tr><td><code>&lt;Anio&gt;</code></td><td>Año de venta (2025, 2026, etc.)</td></tr>
                                    <tr><td><code>&lt;Fecha&gt;</code></td><td>Fecha de venta (01/01/2025)</td></tr>
                                    <tr><td><code>&lt;Dia&gt;</code></td><td>Día de venta (01, 02, etc.)</td></tr>
                                    <tr><td><code>&lt;DiaSemana&gt;</code></td><td>Día de la semana (Lunes, Martes, etc.)</td></tr>
                                </table>
                            </div>
                        )}
                    </Form>
                </ModalItem>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        headersToken: state.user.headersToken,
        empresaId: state.empresa.id,
    };
};

export default connect(mapStateToProps)(ElegirLeyenda);
