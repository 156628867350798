import React, { Component } from "react";
import { InputNumber, ConfigProvider } from "antd";
import mask from "./phoneMask";
import quitmask from "./qPhoneMask";
import cuentaMask from './cuentaMask'
import quitCuentaMask from './qcuentaMask'
import { UpOutlined } from "@ant-design/icons";

class InputNumberItem extends Component {
  render() {
      let propsNuevas = {}
      if(this.props.celular){
        propsNuevas.formatter = (value) => mask(value)
        propsNuevas.parser = (value) => quitmask(value)
      }else if(this.props.dinero){
        propsNuevas.formatter = (value) => {
          let arrayString = value.toString().split('.')
          let primero = arrayString[0].replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,')
          let segundo = arrayString[1]
          if(segundo){
            return `$ ${primero}.${segundo}`
          }else{
            return `$ ${primero}`
          }
        };
        propsNuevas.parser = (value) => value.replace(/\$\s?|(,*)/g, '')
      }else if(this.props.dineroDisabled){
        propsNuevas.formatter = (value) => {
          let arrayString = Number(value).toFixed(2).toString().split('.')
          let primero = arrayString[0].replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,')
          let segundo = arrayString[1]
          if(segundo){
            return `$ ${primero}.${segundo}`
          }else{
            return `$ ${primero}`
          }
        };
        propsNuevas.parser = (value) => value.replace(/\$\s?|(,*)/g, '')
      }else if(this.props.numeroCalle){
        propsNuevas.formatter = (value) => `# ${value}`
        propsNuevas.parser = (value) => value.replace('#', '')
      }else if(this.props.masMenos){
        propsNuevas.formatter = (value) => `± ${value}`
        propsNuevas.parser = (value) => value.replace('±', '')
      }else if(this.props.porcentaje){
        propsNuevas.formatter = (value) => `${value}%`
        propsNuevas.parser = (value) => value.replace('%', '')
      }else if(this.props.cuentaContable){
        propsNuevas.formatter = (value) => cuentaMask(value)
        propsNuevas.parser = (value) => quitCuentaMask(value)
      }
      let transformedProperties = {};
      for (var key in this.props) {
        if (
          !(
            key.toString() === "celular" ||
            key.toString() === "dinero" ||
            key.toString() === "dineroDisabled" ||
            key.toString() === "cuentaContable" ||
            key.toString() === "numeroCalle" ||
            key.toString() === "masMenos" ||
            key.toString() === "sinWidth" ||
            key.toString() === "porcentaje"
          )
        ) {
          transformedProperties = {
            ...transformedProperties,
            [key]: this.props[key],
          };
        }
      }
      /*
      let style = {
        width: this.props.sinWidth ? '50%' : "100%" ,
        color: this.props.disabled ? 'black' : null,
        //'#F5F5F5'
        backgroundColor: this.props.disabled ? 'white' : null,
        borderBottom: this.props.sinBorde ? null : '1px solid #DADADA',
        borderRadius: 0,
        ...this.props.style
      }*/

        const style = {
          width: this.props.sinWidth ? '50%' : '100%',
          color: this.props.disabled ? '#757575' : undefined,
          backgroundColor: this.props.disabled ? '#FFFFFF' : undefined, // Fondo ligeramente gris
          borderBottom: this.props.sinBorde ? 'none' : '1px solid #E0FFFFFF',
          borderRadius: '4px', // Pequeño redondeo para bordes
          transition: 'border-color 0.3s ease', // Transición suave para el borde
          boxShadow: this.props.sinBorde ? 'none' : '0 1px 3px rgba(0, 0, 0, 0.1)', // Sombra suave
          ...this.props.style
        };
        
        
    return (
      <ConfigProvider direction={'rtl'}>
      <InputNumber
        bordered={false}
        style={style}
        {...propsNuevas}
        {...transformedProperties}
        placeholder={this.props.placeholder}
        controls={{upIcon: <UpOutlined style={{ color: "red" }} />}}
      />
      </ConfigProvider>
    );
  }
}

export default InputNumberItem;
