import React, { Component } from "react";
import { Switch, Route, NavLink } from "react-router-dom";
import { Layout, message, notification, Menu, Button, Spin, List, Badge } from "antd";
// import { IconBuild, IconCalendar, IconCalendarCog, IconFall, IconStack, IconBook, IconCash, IconFile } from "../../Icons/Icons"
import { IconAirTable, IconBuildingWarehouse, IconClipBoardHeart, IconFriends, IconStethoscope, IconTagStarred } from "../../Icons/Icons"
import {
  HomeOutlined,
  ContactsOutlined,
  // TeamOutlined,
  SettingOutlined,
  // UserOutlined,
  ShopOutlined,
  LogoutOutlined,
  //   SolutionOutlined,
  //   BookOutlined,
  BankOutlined,
  BankFilled,
  BookOutlined,
  InteractionOutlined,
  ShoppingCartOutlined,
  ProfileOutlined,
  DollarOutlined,
  ShopFilled,
  FundProjectionScreenOutlined,
  ProjectOutlined,
  ExpandOutlined,
  DollarCircleFilled,
  ContainerOutlined,
  ReconciliationOutlined,
  //  SolutionOutlined,
  ReconciliationFilled,
  TagsOutlined,
  ReadOutlined,
  BookFilled,
  ClusterOutlined,
  LayoutOutlined,
  WalletOutlined,
  FileDoneOutlined,
  DropboxOutlined,
  FileTextOutlined,
  ToolOutlined,
  GroupOutlined,
  IdcardOutlined,
  IdcardFilled,
  ToolFilled,
  SwapOutlined,
  // DropboxCircleFilled,
  // CloudDownloadOutlined,
  OrderedListOutlined,
  BarsOutlined,
  DropboxSquareFilled,
  DiffOutlined,
  DiffFilled,
  LineChartOutlined,
  FundFilled,
  CloudServerOutlined,
  TeamOutlined,
  UserOutlined,
  ControlOutlined,
  TagsFilled,
  CalculatorOutlined,
  CalculatorFilled,
  SnippetsOutlined,
  SnippetsFilled,
  CarOutlined,
  MenuOutlined,
  RollbackOutlined,
  BellOutlined,
  DownloadOutlined,
  CloseCircleOutlined,
  WarningOutlined,
  InfoCircleOutlined,
  // UsergroupAddOutlined,
  // ClockCircleOutlined
} from "@ant-design/icons";

import { connect } from "react-redux";
import logoHorizontal from "../../assets/Biwo_Horizontal.png";
import { contarVentasPPendientes, deseleccionarEmpresa } from "../../store/actions/empresa";
import { logout } from "../../store/actions/user";
import Inicio from "./Inicio/Inicio";
import Proveedores from "./Proveedores/Proveedores";
import Insumos from "./Insumos/Insumos";
import Configuracion from "./Configuracion/Configuracion";
import CuentasContables from "./CuentasContables/CuentasContables";
import AgregarProveedor from "./Proveedores/AgregarProveedor/AgregarProveedor";
import EditarProveedor from "./Proveedores/EditarProveedor/EditarProveedor";
import AgregarInsumo from "./Insumos/AgregarInsumo/AgregarInsumo";
import EditarInsumo from "./Insumos/EditarInsumo/EditarInsumo";
import TiposDeProveedores from "./TiposDeProveedores/TiposDeProveedores";
import AgregarTipoDeProveedor from "./TiposDeProveedores/AgregarTipoDeProveedor/AgregarTipoDeProveedor";
import Impuestos from "./Impuestos/Impuestos";
import AgregarImpuesto from "./Impuestos/AgregarImpuesto/AgregarImpuesto";
import TiposDePolizas from "./TiposDePolizas/TiposDePolizas";
import TiposDeFlujos from "./TiposDeFlujos/TiposDeFlujos";
import OrdenesDeCompras from "./OrdenesDeCompras/OrdenesDeCompras";
import AgregarOrdenDeCompra from "./OrdenesDeCompras/AgregarOrdenDeCompra/AgregarOrdenDeCompra";
import Familias from "./Familias/Familias";
import UnidadesDeNegocio from "./UnidadesDeNegocio/UnidadesDeNegocio";
import Proyectos from "./Proyectos/Proyectos";
import Bancos from "./Bancos/Bancos";
import RecibirInsumos from "./OrdenesDeCompras/RecibirInsumos/RecibirInsumos";
import CargosAbonos from "./CargosAbonos/CargosAbonos";
import RecibirFactura from "./OrdenesDeCompras/RecibirFactura/RecibirFactura";
import FacturasSinOrdenDeCompra from "./FacturasSinOrdenDeCompra/FacturasSinOrdenDeCompra";
import AgregarFactura from "./FacturasSinOrdenDeCompra/AgregarFactura/AgregarFactura";
import AgregarFacturaCajaChica from "./FacturasCajaChica/AgregarFactura/AgregarFactura";
import RecibirInsumosFactura from "./FacturasSinOrdenDeCompra/RecibirInsumosFactura/RecibirInsumosFactura";
import CuentasPorPagar from "./CuentasPorPagar/CuentasPorPagar";
import PagarFactura from "./CuentasPorPagar/PagarFactura/PagarFactura";
import VerFacturasProveedor from "./CuentasPorPagar/VerFacturasProveedor/VerFacturasProveedor";
import Clientes from "./Clientes/Clientes";
import AgregarCliente from "./Clientes/AgregarCliente/AgregarCliente";
import TiposDeClientes from "./TiposDeClientes/TiposDeClientes";
import AgregarTipoDeCliente from "./TiposDeClientes/AgregarTipoDeCliente/AgregarTipoDeCliente";
import Ventas from "./Ventas/Ventas";
import AgregarVenta from "./Ventas/AgregarVenta/AgregarVenta";
import EntregarInsumosVenta from "./Ventas/EntregarInsumosVenta/EntregarInsumosVenta";
import GenerarFactura from "./Ventas/GenerarFactura/GenerarFactura";
import CuentasPorCobrar from "./CuentasPorCobrar/CuentasPorCobrar";
import CobrarVenta from "./CuentasPorCobrar/CobrarVenta/CobrarVenta";
import VerVentasCliente from "./CuentasPorCobrar/VerVentasCliente/VerVentasCliente";
import Polizas from "./Polizas/Polizas";
import VerPoliza from "./Polizas/VerPoliza/VerPoliza";
import EditarCliente from "./Clientes/EditarCliente/EditarCliente";
import EditarTipoDeCliente from "./TiposDeClientes/EditarTipoDeCliente/EditarTipoDeCliente";
import Retenciones from "./Retenciones/Retenciones";
import AgregarRetencion from "./Retenciones/AgregarRetencion/AgregarRetencion";
import EditarImpuesto from "./Impuestos/EditarImpuesto/EditarImpuesto";
import EditarRetencion from "./Retenciones/EditarRetencion/EditarRetencion";
import EditarTipoDeProveedor from "./TiposDeProveedores/EditarTipoDeProveedor/EditarTipoDeProveedor";
import CajaEfectivo from "./CajaEfectivo/CajaEfectivo";
import FacturasCajaChica from "./FacturasCajaChica/FacturasCajaChica";
import ReponerCajaChica from "./CajaEfectivo/ReponerCajaChica/ReponerCajaChica";
import TraspasoEntreCuentas from "./Bancos/TraspasoEntreCuentas/TraspasoEntreCuentas";
import PolizaManual from "./Polizas/PolizaManual/PolizaManual";
import Inventario from "./Inventario/Inventario";
import ImportarClientes from "./Clientes/ImportarClientes/ImportarClientes";
import ImportarProveedores from "./Proveedores/ImportarProveedores/ImportarProveedores";
import LeerXml from "./LeerXml/LeerXml";
import Servicios from "./Servicios/Servicios";
import AgregarServicio from "./Servicios/AgregarServicio/AgregarServicio";
import EditarServicio from "./Servicios/EditarServicio/EditarServicio";
import InsumosCompuestos from "./InsumosCompuestos/InsumosCompuestos";
import AgregarInsumoCompuesto from "./InsumosCompuestos/AgregarInsumoCompuesto/AgregarInsumoCompuesto";
import EditarInsumoCompuesto from "./InsumosCompuestos/EditarInsumoCompuesto/EditarInsumoCompuesto";
import CostearVenta from "./Ventas/CostearVenta/CostearVenta";
import VerVenta from "./Ventas/VerVenta/VerVenta";
import VerFacturaSinOrdenDeCompra from "./FacturasSinOrdenDeCompra/VerFacturaSinOrdenDeCompra/VerFacturaSinOrdenDeCompra";
import VerFacturaCajaChica from "./FacturasCajaChica/VerFacturaCajaChica/VerFacturaCajaChica";
import PuntoDeVenta from "./Ventas/PuntoDeVenta/PuntoDeVenta";
import Produccion from "./Produccion/Produccion";
import AgregarOrdenDeProduccion from "./Produccion/AgregarOrdenDeProduccion/AgregarOrdenDeProduccion";
import TraspasoEntreAlmacenes from "./TraspasoEntreAlmacenes/TraspasoEntreAlmacenes";
import AgregarTraspasoEntreAlmacenes from "./TraspasoEntreAlmacenes/AgregarTraspasoEntreAlmacenes/AgregarTraspasoEntreAlmacenes";
import ScrollToTopItem from "../../Components/ScrollToTopItem";
import ReporteVentas from "./Reportes/ReporteVentas/ReporteVentas";
import EditarPolizaManual from "./Polizas/EditarPolizaManual/EditarPolizaManual";
import ConciliacionesCuenta from "./Bancos/ConciliacionesCuenta/ConciliacionesCuenta";
import Conciliacion from "./Bancos/ConciliacionesCuenta/Conciliacion/Conciliacion";
import DescargaMasiva from "./DescargaMasiva/DescargaMasiva";
import ListaDeMateriales from "./ListaDeMateriales/ListaDeMateriales";
import AgregarListaDeMateriales from "./ListaDeMateriales/AgregarListaDeMateriales/AgregarListaDeMateriales";
import EditarListaDeMateriales from "./ListaDeMateriales/EditarListaDeMateriales/EditarListaDeMateriales";
import AgregarOrdenDeProduccionConLista from "./Produccion/AgregarOrdenDeProduccionConLista/AgregarOrdenDeProduccionConLista";
import Requisiciones from "./Requisiciones/Requisiciones";
import AgregarRequisicion from "./Requisiciones/AgregarRequisicion/AgregarRequisicion";
import EditarRequisicion from "./Requisiciones/EditarRequisicion/EditarRequisicion";
import GenerarOrdenDeCompra from "./Requisiciones/GenerarOrdenDeCompra/GenerarOrdenDeCompra";
import EditarOrdenDeCompra from "./OrdenesDeCompras/EditarOrdenDeCompra/EditarOrdenDeCompra";
import AgregarInventario from "./AgregarInventario/AgregarInventario";
import AgregarOrdenAgregarInventario from "./AgregarInventario/AgregarOrdenAgregarInventario/AgregarOrdenAgregarInventario";
import VerOrdenAgregarInventario from "./AgregarInventario/VerOrdenAgregarInventario/VerOrdenAgregarInventario";
import AgregarOrdenProductosAlCosto from "./ProductosAlCosto/AgregarOrdenProductosAlCosto/AgregarOrdenProductosAlCosto";
import ProductosAlCosto from "./ProductosAlCosto/ProductosAlCosto";
import VerOrdenProductosAlCosto from "./ProductosAlCosto/VerOrdenProductosAlCosto/VerOrdenProductosAlCosto";
import VerOrdenDeCompra from "./OrdenesDeCompras/VerOrdenDeCompra/VerOrdenDeCompra";
import VerFacturaOrdenDeCompra from "./OrdenesDeCompras/VerFacturaOrdenDeCompra/VerFacturaOrdenDeCompra";
import Reportes from "./Reportes/Reportes";
import ReporteCompras from "./Reportes/ReporteCompras/ReporteCompras";
import InventarioProducto from "./Inventario/InventarioProducto/InventarioProducto";
import ReporteInventario from "./Reportes/ReporteInventario/ReporteInventario";
import ReporteCostoInventario from "./Reportes/ReporteCostoInventario/ReporteCostoInventario";
import ReporteCuentasPorCobrar from "./Reportes/ReporteCuentasPorCobrar/ReporteCuentasPorCobrar";
import ReporteCuentasPorPagar from "./Reportes/ReporteCuentasPorPagar/ReporteCuentasPorPagar";
import ReporteEstadoDePosicionFinanciera from "./Reportes/ReporteEstadoDePosicionFinanciera/ReporteEstadoDePosicionFinanciera";
import ReporteEstadoDeResultados from "./Reportes/ReporteEstadoDeResultados/ReporteEstadoDeResultados";
import ReporteEstadoDeCuenta from "./Reportes/ReporteEstadoDeCuenta/ReporteEstadoDeCuenta";
import ReporteBalanzaDeComprobacion from "./Reportes/ReporteBalanzaDeComprobacion/ReporteBalanzaDeComprobacion";
import ReporteFlujoDeEfectivo from "./Reportes/ReporteFlujoDeEfectivo/ReporteFlujoDeEfectivo";
import Presupuestos from "./Presupuestos/Presupuestos";
import AgregarPresupuesto from "./Presupuestos/AgregarPresupuesto/AgregarPresupuesto";
import EditarPresupuesto from "./Presupuestos/EditarPresupuesto/EditarPresupuesto";
import ReporteFlujoDeEfectivoPresupuestado from "./Reportes/ReporteFlujoDeEfectivoPresupuestado/ReporteFlujoDeEfectivoPresupuestado";
import AgregarNotaDeCredito from "./NotasDeCredito/AgregarNotaDeCredito/AgregarNotaDeCredito";
import VerNotaDeCredito from "./NotasDeCredito/VerNotaDeCredito/VerNotaDeCredito";
import ReportePagos from "./Reportes/ReportePagos/ReportePagos";
import ReporteIngresos from "./Reportes/ReporteIngresos/ReporteIngresos";
import ArchivosXml from "./ArchivosXml/ArchivosXml";
import VerConciliacion from "./Bancos/ConciliacionesCuenta/VerConciliacion/VerConciliacion";
import VerOrdenDeProduccion from "./Produccion/VerOrdenDeProduccion/VerOrdenDeProduccion";
import ReporteLibroDiario from "./Reportes/ReporteLibroDiario/ReporteLibroDiario";
import ReportePresupuestos from "./Reportes/ReportePresupuestos/ReportePresupuestos";
import ReporteReposicionCajaChica from "./Reportes/ReporteReposicionCajaChica/ReporteReposicionCajaChica";
import ControlInventario from "./ControlInventario/ControlInventario";
import ReporteCostoProduccion from "./Reportes/ReporteCostoProduccion/ReporteCostoProduccion";
import GenerarEnvio from "./Ventas/GenerarEnvio/GenerarEnvio";
import Empleados from "./Empleados/Empleados";
import AgregarEmpleado from "./Empleados/AgregarEmpleado/AgregarEmpleado";
import EditarEmpleado from "./Empleados/EditarEmpleado/EditarEmpleado";
import VerEnvio from "./Ventas/VerEnvio/VerEnvio";
import VerPagosCajaChica from "./CajaEfectivo/VerPagosCajaChica/VerPagosCajaChica";
import Cotizaciones from "./Cotizaciones/Cotizaciones";
import AgregarCotizacion from "./Cotizaciones/AgregarCotizacion/AgregarCotizacion";/*
import RegistroDeLotes from "./RegistroDeLotes/RegistroDeLotes"
import CorteDePiñas from "./CorteDePiñas/CorteDePiñas";
import Horneado from "./Horneado/Horneado";
import Fermentacion from "./Fermentacion/Fermentacion";
import PrimeraDestilacion from "./PrimeraDestilacion/PrimeraDestilacion";
import SegundaDestilacion from "./SegundaDestilacion/SegundaDestilacion";
import TerceraDestilacion from "./TerceraDestilacion/TerceraDestilacion";
import TanquesLlenado from "./TanquesLLenado/TanquesLlenado";*/
import Departamentos from "./Departamentos/Departamentos";
import Puestos from "./Puestos/Puestos";
import Turnos from "./Turnos/Turnos";
import TiposAcumulados from "./TiposAcumulados/TiposAcumulados";
import TiposIncidencias from "./TiposIncidencias/TiposIncidencias";
import TipoPeriodo from "./TipoPeriodo/TipoPeriodo";
import Periodos from "./Periodos/Periodos";
import Conceptos from "./Conceptos/Conceptos";
import AgregarConcepto from "./Conceptos/AgregarConcepto.js/AgregarConcepto";
import EditarConcepto from "./Conceptos/EditarConcepto/EditarConcepto";
import TipoPrestaciones from "./TipoPrestaciones/TipoPrestaciones";
import AgregarTipoPrestaciones from "./TipoPrestaciones/AgregarTipoPrestaciones/AgregarTipoPrestaciones";
import EditarTipoPrestaciones from "./TipoPrestaciones/EditarTipoPrestaciones/EditarTipoPrestaciones";
import RegistroPatronal from "./RegistroPatronal/RegistroPatronal";

import VerCotizacion from "./Cotizaciones/VerCotizacion/VerCotizacion";
import Matrices from "./Matrices/Matrices";
import AgregarMatriz from "./Matrices/AgregarMatriz/AgregarMatriz";
import FacturaGlobal from "./Ventas/FacturaGlobal/FacturaGlobal";
import AgregarConsignacion from "./InventarioConsignaciones/AgregarConsignacion/AgregarConsignacion";
import InventarioConsignaciones from "./InventarioConsignaciones/InventarioConsignaciones";
import Consignaciones from "./Consignaciones/Consignaciones";
import VentasConsignacion from "./VentasConsignacion/VentasConsignacion";
import VerVentaConsignacion from "./VentasConsignacion/VerVentaConsignacion/VerVentaConsignacion";
import FacturaGlobalConsignacion from "./VentasConsignacion/FacturaGlobalConsignacion/FacturaGlobalConsignacion";
import EntregarInsumosVentaConsignacion from "./VentasConsignacion/EntregarInsumosVentaConsignacion/EntregarInsumosVentaConsignacion";
import GenerarFacturaConsignacion from "./VentasConsignacion/GenerarFacturaConsignacion/GenerarFacturaConsignacion";
import AgregarVentaConsignacion from "./VentasConsignacion/AgregarVentaConsignacion/AgregarVentaConsignacion";
import BajasConsignaciones from "./BajasConsignaciones/BajasConsignaciones";
import BajaConsignacion from "./BajasConsignaciones/BajaConsignacion/BajaConsignacion";
import EditarMatriz from "./Matrices/EditarMatriz/EditarMatriz";
import PresupuestosPU from "./PresupuestosPU/PresupuestosPU";
import PresupuestoPU from "./PresupuestosPU/PresupuestoPU/PresupuestoPU";
import Remolques from "./Remolques/Remolques";
import ImportarInsumos from "./Insumos/ImportarInsumos/ImportarInsumos";
import TiposDeInsumos from "./TiposDeInsumos/TiposDeInsumos";
import AgregarNotaDeCreditoVenta from "./NotasDeCreditoVenta/AgregarNotaDeCreditoVenta/AgregarNotaDeCreditoVenta";
import VerNotaDeCreditoVenta from "./NotasDeCreditoVenta/VerNotaDeCreditoVenta/VerNotaDeCreditoVenta";
import AgregarDevolucion from "./Devoluciones/AgregarDevolucion/AgregarDevolucion";
import VerDevolucion from "./Devoluciones/VerDevolucion/VerDevolucion";
import Indirectos from "./Indirectos/Indirectos";
import AgregarIndirecto from "./Indirectos/AgregarIndirecto/AgregarIndirecto";
import EditarIndirecto from "./Indirectos/EditarIndirecto/EditarIndirecto";
import { Drawer } from "antd5";
import EditarCotizacion from "./Cotizaciones/EditarCotizacion/EditarCotizacion";
import Doctores from "./Doctores/Doctores";
import Pacientes from "./Pacientes/Pacientes";
import VerConsignacion from "./InventarioConsignaciones/VerConsignacion/VerConsignacion";
import ButtonItem from "../../Components/ButtonItem";
import moment from "moment";
import RequerirInsumosVenta from "./Ventas/RequerirInsumosVenta/RequerirInsumosVenta";
import RemisionesCompra from "./RemisionesCompra/RemisionesCompra";
import VentasProgramadas from "./VentasProgramadas/VentasProgramadas";
import AgregarVentaProgramada from "./VentasProgramadas/AgregarVentaProgramada/AgregarVentaProgramada";
import EditarVentaProgramada from "./VentasProgramadas/EditarVentaProgramada/EditarVentaProgramada";
import PendientesVentasProgramadas from "./VentasProgramadas/PendientesVentasProgramadas/PendientesVentasProgramadas";
// import RegistroDeLotes from "./RegistroDeLotes/RegistroDeLotes"
// import CorteDePiñas from "./CorteDePiñas/CorteDePiñas";
// import Horneado from "./Horneado/Horneado";
// import Fermentacion from "./Fermentacion/Fermentacion";
// import PrimeraDestilacion from "./PrimeraDestilacion/PrimeraDestilacion";
// import SegundaDestilacion from "./SegundaDestilacion/SegundaDestilacion";
// import TerceraDestilacion from "./TerceraDestilacion/TerceraDestilacion";
// import TanquesLlenado from "./TanquesLLenado/TanquesLlenado";


const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;

class Panel extends Component {
  state = {
    collapsed: false,
    openKeys: [],
    isMobile: window.innerWidth <= 1000,
    openNotificaciones: false,
    notifications: [],
    unreadCount: 0,
    button: true,
    loading: true,
    loadingTodas: false,
    socket: null,
    pendientes: 0,
  };

  componentDidMount() {
    if (this.props.empresa === null) {
      this.props.history.replace("/panelPrincipal");
    } else {
      this.abrirWebSocket();
      this.consultarVentasProgramadasPendientes();
    }
  }

  consultarVentasProgramadasPendientes() {
    this.props.contarVentasPPendientes({ empresaId: this.props.empresaId }).then((response) => {
      if (response.success === 2) {
      } else {
        message.warning(response.err);
      }
    });
  }

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  logout() {
    this.props.logout().then((response) => {
      if (response.success === 1) {
        this.props.history.push("/login");
        notification.info({
          message: "Se ha cerrado la sesion",
        });
      } else {
        message.warning(response.err);
      }
    });
  }

  deseleccionarEmpresa() {
    this.props.deseleccionarEmpresa().then(() => {
      this.props.history.push("/panelPrincipal");
    });
  }

  onOpenChange(keys) {
    const latestOpenKey = keys.find(
      (key) => this.state.openKeys.indexOf(key) === -1
    );
    this.setState({ openKeys: latestOpenKey ? [latestOpenKey] : [] });
  }

  toggleDrawer = () => {
    this.setState((prevState) => ({ drawerVisible: !prevState.drawerVisible }));
  };

  noLeidasaLeidas(id) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify({ type: "noLeidaALeida", notificacionId: id }));
    }
  }

  async consultaTodasNotificaciones() {
    this.setState({ loadingTodas: true });
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify({ type: "mostrarTodas" }));
    }
  }


  todasNoLeidasaLeidas() {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify({ type: "todasNoLeidasALeidas" }));
    }
  }

  getIconByNumber = (iconNumber) => {
    switch (iconNumber) {
      case 1:
        return <SettingOutlined />;
      case 2:
        return <DownloadOutlined />;
      case 3:
        return <CloudServerOutlined />;
      case 4:
        return <CloseCircleOutlined />;
      case 5:
        return <WarningOutlined />;
      case 6:
        return <InfoCircleOutlined />;
      case 35:
        return <UserOutlined />;
      default:
        return <BellOutlined />;
    }
  };


  formatNotifications = (notifications) => {
    return notifications.map((notification, index) => ({
      id: notification._id,
      title: notification.titulo || "Sin título",
      message: notification.descripcion || "No hay detalles disponibles",
      icon: this.getIconByNumber(notification.icono),
      fecha: moment(notification.fechaDespliegue ? notification.fechaDespliegue : notification.createdAt).fromNow(),
      read: notification.leido || false,
      ruta: notification.ruta || null,
    }));
  };

  onMarkAsRead = (id, ruta, estadoRuta) => {
    let notifications = [...this.state.notifications];
    let unreadCount = this.state.unreadCount;
    let index = notifications.findIndex((notification) => notification.id === id);

    if (index !== -1 && !notifications[index].read) {
      notifications[index].read = true;
      unreadCount--;
    }

    this.setState(() => ({
      notifications: notifications,
      unreadCount: unreadCount,
    }));
    if (ruta) {
      this.props.history.push(ruta, estadoRuta ? estadoRuta : null);
      this.abrirNotificaciones(false)
    }
  };

  onMarkAllAsRead = () => {
    this.setState((prevState) => ({
      notifications: prevState.notifications.map((notification) => ({
        ...notification,
        read: true,
      })),
      unreadCount: 0,
    }));
    this.todasNoLeidasaLeidas();
  };

  abrirNotificaciones = (value) => {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.setState({ openNotificaciones: value });
    } else if (!value) {
      this.setState({ openNotificaciones: value });
    }
  }

  abrirWebSocket() {
    //Variale para el websocket

    const WS_URL = process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_WS_URL_DEV
      : process.env.REACT_APP_WS_URL_PROD;

    // Conectar con el servidor WebSocket
    this.socket = new WebSocket(WS_URL);

    // Evento cuando la conexión se abre
    this.socket.onopen = () => {
      this.socket.send(JSON.stringify({ type: "datos", empresaId: this.props.empresaId, usuarioId: this.props.usuarioId, tipoUsuario: this.props.tipoUsuario }));
    };

    // Evento cuando se recibe un mensaje
    this.socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.success === 2) {
        if (data.type === 'new_notification') {
          this.setState((prevState) => ({
            notifications: [...prevState.notifications, {
              id: data.notification._id,
              title: data.notification.titulo || "Sin título",
              message: data.notification.descripcion || "No hay detalles disponibles",
              fecha: moment(data.notification.fechaDespliegue ? data.notification.fechaDespliegue : data.notification.createdAt).fromNow(),
              icon: this.getIconByNumber(data.notification.icono),
              read: data.notification.leido || false,
              ruta: data.notification.ruta || null,
            }],
            unreadCount: prevState.unreadCount + 1
          }));
        } else {
          this.setState(() => ({
            notifications: this.formatNotifications(data.notificaciones), unreadCount: data.unreadCount, loadingTodas: false, loading: false, button: !data.mostrarTodas
          }));
        }
      } else {
        this.setState(() => ({
          notifications: [], unreadCount: 0, loadingTodas: false, loading: false
        }));
      }
    };

    // Evento cuando la conexión se cierra
    this.socket.onclose = () => {
      this.setState({ notifications: [], unreadCount: 0 });
    };
  }

  componentWillUnmount() {
    // Cerrar la conexión WebSocket cuando el componente se desmonte
    if (this.socket) {
      this.socket.close();
    }
  }

  render() {
    const current = this.props.history.location.pathname;

    const menuItems = (
      <>
        {this.props.permisoAccesoInicio && (
          <Menu.Item
            key={this.props.match.url + "/inicio"}
            icon={<HomeOutlined />}
          >
            <NavLink to={this.props.match.url + "/inicio"}>
              Inicio
            </NavLink>
          </Menu.Item>
        )}
        {this.props.permisoAccesoBancos && (
          <Menu.Item
            key={this.props.match.url + "/bancos"}
            icon={<DollarCircleFilled />}
          >
            <NavLink to={this.props.match.url + "/bancos"}>
              Bancos
            </NavLink>
          </Menu.Item>
        )}
        {this.props.permisoAccesoCajaChica && (
          <Menu.Item
            key={this.props.match.url + "/cajaefectivo"}
            icon={<WalletOutlined />}
          >
            <NavLink to={this.props.match.url + "/cajaefectivo"}>
              Caja y Efectivo
            </NavLink>
          </Menu.Item>
        )}
        {this.props.permisoAccesoCuentasPorPagar && (
          <Menu.Item
            key={this.props.match.url + "/cuentasporpagar"}
            icon={<ReconciliationOutlined />}
          >
            <NavLink to={this.props.match.url + "/cuentasporpagar"}>
              Cuentas por Pagar
            </NavLink>
          </Menu.Item>
        )}
        {this.props.permisoAccesoCuentasPorCobrar && (
          <Menu.Item
            key={this.props.match.url + "/cuentasporcobrar"}
            icon={<ReadOutlined />}
          >
            <NavLink to={this.props.match.url + "/cuentasporcobrar"}>
              Cuentas por Cobrar
            </NavLink>
          </Menu.Item>
        )}
        {this.props.certificadaDM &&
          this.props.permisoAccesoArchivosXML ?
          (<Menu.Item
            key={this.props.match.url + "/archivosXml"}
            icon={<CloudServerOutlined />}
          >
            <NavLink to={this.props.match.url + "/archivosXml"}>
              Archivos XML
            </NavLink>
          </Menu.Item>) : null
        }
        {this.props.permisoAccesoOrdenesDeCompra ||
          this.props.permisoAccesoFacturasSinOrdenDeCompra ||
          this.props.permisoAccesoLeerXml ||
          this.props.permisoAccesoFacturasCajaChica ? (
          <SubMenu
            key={
              this.props.match.url + "/facturassinordendecompra" ||
              this.props.match.url + "/facturascajachica" ||
              this.props.match.url + "/leerXml" ||
              this.props.match.url + "/ordenesdecompra"
            }
            title="Compras"
            icon={<ShoppingCartOutlined />}
          >
            <Menu.Item
              key={this.props.match.url + "/requisiciones"}
              icon={<BarsOutlined />}
            >
              <NavLink to={this.props.match.url + "/requisiciones"}>
                Requisiciones
              </NavLink>
            </Menu.Item>
            {this.props.permisoAccesoOrdenesDeCompra && (
              <Menu.Item
                key={this.props.match.url + "/ordenesdecompra"}
                icon={<ProfileOutlined />}
              >
                <NavLink to={this.props.match.url + "/ordenesdecompra"}>
                  Ordenes de Compra
                </NavLink>
              </Menu.Item>
            )}
            {this.props.permisoAccesoFacturasSinOrdenDeCompra && (
              <Menu.Item
                key={this.props.match.url + "/facturassinordendecompra"}
                icon={<ContainerOutlined />}
              >
                <NavLink
                  to={
                    this.props.match.url + "/facturassinordendecompra"
                  }
                >
                  Facturas sin Orden de Compra
                </NavLink>
              </Menu.Item>
            )}
            {this.props.permisoAccesoFacturasCajaChica && (
              <Menu.Item
                key={this.props.match.url + "/facturascajachica"}
                icon={<FileDoneOutlined />}
              >
                <NavLink
                  to={this.props.match.url + "/facturascajachica"}
                >
                  Facturas Caja Chica
                </NavLink>
              </Menu.Item>
            )}
            {/* {this.props.permisoAccesoFacturasSinOrdenDeCompra && (
              <Menu.Item
                key={this.props.match.url + "/remisionesCompra"}
                icon={<IconClipBoard />}
              >
                <NavLink
                  to={
                    this.props.match.url + "/remisionesCompra"
                  }
                >
                  Remisiones
                </NavLink>
              </Menu.Item>
            )} */}
            {this.props.permisoAccesoLeerXml && (
              <Menu.Item
                key={this.props.match.url + "/leerXml"}
                icon={<FileTextOutlined />}
              >
                <NavLink to={this.props.match.url + "/leerXml"}>
                  Leer Xml
                </NavLink>
              </Menu.Item>
            )}
          </SubMenu>
        ) : null}
        {this.props.equipoMedico && (
          <SubMenu
            key={
              this.props.match.url + "/pacientes" ||
              this.props.match.url + "/doctores"
            }
            title="Medico"
            icon={<IconClipBoardHeart />}
          >
            <Menu.Item
              key={this.props.match.url + "/pacientes"}
              icon={<IconFriends />}
            >
              <NavLink to={this.props.match.url + "/pacientes"}>
                Pacientes
              </NavLink>
            </Menu.Item>
            <Menu.Item
              key={this.props.match.url + "/doctores"}
              icon={<IconStethoscope />}
            >
              <NavLink
                to={this.props.match.url + "/doctores"}
              >
                Doctores
              </NavLink>
            </Menu.Item>
          </SubMenu>
        )}
        {this.props.permisoAccesoVentas && (
          <SubMenu
            key={
              this.props.match.url + "/ventas" ||
              this.props.match.url + "/cotizaciones"
            }
            title={
              <Badge style={{ boxShadow: 'none' }} dot={this.props.cuentaVentasPendientes > 0} offset={[20, 0]}>
                <span style={{ color: "rgba(255, 255, 255, 0.65)" }}>
                  Ventas
                </span>
              </Badge>
            }
            icon={<TagsFilled />}
          >
            <Menu.Item
              key={this.props.match.url + "/ventas"}
              icon={<TagsOutlined />}
            >
              <NavLink to={this.props.match.url + "/ventas"}>
                Ventas
              </NavLink>
            </Menu.Item>
            {this.props.permisoAccesoCotizaciones &&
              <Menu.Item
                key={this.props.match.url + "/cotizaciones"}
                icon={<TagsFilled />}
              >
                <NavLink
                  to={this.props.match.url + "/cotizaciones"}
                >
                  Cotizaciones
                </NavLink>
              </Menu.Item>}
            <Menu.Item
              key={this.props.match.url + "/ventasProgramadas"}
              icon={<IconTagStarred />}
            >
              <NavLink to={this.props.match.url + "/ventasProgramadas"}>
              <Badge count={this.props.cuentaVentasPendientes} offset={[0, -5]} size="small" style={{ boxShadow: 'none' }}>
                <span style={{ color: "rgba(255, 255, 255, 0.65)" }}>
                  Ventas Programadas
                </span>
              </Badge>
              </NavLink>
            </Menu.Item>
          </SubMenu>
        )}
        {this.props.permisoAccesoInsumos ||
          this.props.permisoAccesoServicios ||
          this.props.permisoAccesoInsumosCompuetos ||
          this.props.permisoAccesoFamilias ? (
          <SubMenu
            key={
              this.props.match.url + "/insumos" ||
              this.props.match.url + "/tipodeinsumos"
            }
            title="Insumos"
            icon={<ShopOutlined />}
          >
            {this.props.permisoAccesoInsumos && (
              <Menu.Item
                key={this.props.match.url + "/insumos"}
                icon={<ShopOutlined />}
              >
                <NavLink to={this.props.match.url + "/insumos"}>
                  Insumos
                </NavLink>
              </Menu.Item>
            )}
            {this.props.permisoAccesoServicios ? (
              <Menu.Item
                key={this.props.match.url + "/servicios"}
                icon={<ToolOutlined />}
              >
                <NavLink to={this.props.match.url + "/servicios"}>
                  Servicios
                </NavLink>
              </Menu.Item>
            ) : null}
            {this.props.permisoAccesoInsumosCompuetos ? (
              <Menu.Item
                key={this.props.match.url + "/insumosCompuestos"}
                icon={<GroupOutlined />}
              >
                <NavLink
                  to={this.props.match.url + "/insumosCompuestos"}
                >
                  Insumos Compuestos
                </NavLink>
              </Menu.Item>
            ) : null}
            {this.props.permisoAccesoFamilias && (
              <Menu.Item
                key={this.props.match.url + "/familias"}
                icon={<ShopFilled />}
              >
                <NavLink to={this.props.match.url + "/familias"}>
                  Familias
                </NavLink>
              </Menu.Item>
            )}
            <Menu.Item
              key={this.props.match.url + "/tiposDeInsumos"}
              icon={<IconBuildingWarehouse />}
            >
              <NavLink to={this.props.match.url + "/tiposDeInsumos"}>
                Tipos De Insumos
              </NavLink>
            </Menu.Item>
          </SubMenu>
        ) : null}
        {this.props.permisoAccesoInventario ||
          this.props.permisoAccesoAgregarInventario ||
          this.props.permisoAccesoTraspasoAlmacenes ||
          this.props.permisoAccesoProductosAlCosto ? (
          <SubMenu
            key={
              this.props.match.url + "/inventario" ||
              this.props.match.url + "/agregarInventario" ||
              this.props.match.url + "/productosAlCosto" ||
              this.props.match.url + "/traspasoEntreAlmacenes"
            }
            title="Inventario"
            icon={<DropboxSquareFilled />}
          >
            {this.props.permisoAccesoInventario && (
              <Menu.Item
                key={this.props.match.url + "/inventario"}
                icon={<DropboxOutlined />}
              >
                <NavLink to={this.props.match.url + "/inventario"}>
                  Inventario
                </NavLink>
              </Menu.Item>
            )}
            {this.props.permisoAccesoTraspasoAlmacenes ? (
              <Menu.Item
                key={this.props.match.url + "/traspasoEntreAlmacenes"}
                icon={<SwapOutlined />}
              >
                <NavLink
                  to={this.props.match.url + "/traspasoEntreAlmacenes"}
                >
                  Traspaso Almacenes
                </NavLink>
              </Menu.Item>
            ) : null}

            {/*Activar en siguiente actualizacion*/}

            {this.props.permisoAccesoInventario && (
              <Menu.Item
                key={this.props.match.url + "/controlInventario"}
                icon={<ControlOutlined />}
              >
                <NavLink to={this.props.match.url + "/controlInventario"}>
                  Control Inventario
                </NavLink>
              </Menu.Item>
            )}
            {this.props.permisoAccesoAgregarInventario && (
              <Menu.Item
                key={this.props.match.url + "/agregarInventario"}
                icon={<DiffOutlined />}
              >
                <NavLink
                  to={this.props.match.url + "/agregarInventario"}
                >
                  Agregar Inventario
                </NavLink>
              </Menu.Item>
            )}
            {this.props.permisoAccesoProductosAlCosto && (
              <Menu.Item
                key={this.props.match.url + "/productosAlCosto"}
                icon={<DiffFilled />}
              >
                <NavLink
                  to={this.props.match.url + "/productosAlCosto"}
                >
                  Productos Al Costo
                </NavLink>
              </Menu.Item>
            )}
          </SubMenu>
        ) : null}

        {(this.props.permisoAccesoInventario ||
          this.props.permisoAccesoAgregarInventario ||
          this.props.permisoAccesoTraspasoAlmacenes ||
          this.props.permisoAccesoProductosAlCosto) && this.props.modulosConsignaciones ? (
          <SubMenu
            key={
              this.props.match.url + "/consignaciones"
            }
            title="Consignaciones"
            icon={<SnippetsOutlined />}
          >
            <Menu.Item
              key={this.props.match.url + "/consignaciones"}
              icon={<SnippetsOutlined />}
            >
              <NavLink to={this.props.match.url + "/consignaciones"}>
                Consignaciones
              </NavLink>
            </Menu.Item>
            <Menu.Item
              key={this.props.match.url + "/inventarioConsignaciones"}
              icon={<SnippetsFilled />}
            >
              <NavLink to={this.props.match.url + "/inventarioConsignaciones"}>
                Inventario Consignaciones
              </NavLink>
            </Menu.Item>
            <Menu.Item
              key={this.props.match.url + "/ventasConsignacion"}
              icon={<TagsOutlined />}
            >
              <NavLink to={this.props.match.url + "/ventasConsignacion"}>
                Ventas
              </NavLink>
            </Menu.Item>
            <Menu.Item
              key={this.props.match.url + "/bajasConsignaciones"}
              icon={<DiffFilled />}
            >
              <NavLink
                to={this.props.match.url + "/bajasConsignaciones"}
              >
                Bajas Consignaciones
              </NavLink>
            </Menu.Item>
          </SubMenu>
        ) : null}
        {this.props.permisoAccesoProduccion ||
          this.props.permisoAccesoListaDeMateriales ? (
          <SubMenu
            key={
              this.props.match.url + "/produccion" ||
              this.props.match.url + "/listaDeMateriales"
            }
            title="Produccion"
            icon={<ToolFilled />}
          >
            {this.props.permisoAccesoProduccion && (
              <Menu.Item
                key={this.props.match.url + "/produccion"}
                icon={<ToolOutlined />}
              >
                <NavLink to={this.props.match.url + "/produccion"}>
                  Produccion
                </NavLink>
              </Menu.Item>
            )}
            {this.props.permisoAccesoListaDeMateriales && (
              <Menu.Item
                key={this.props.match.url + "/listaDeMateriales"}
                icon={<OrderedListOutlined />}
              >
                <NavLink
                  to={this.props.match.url + "/listaDeMateriales"}
                >
                  Lista de Materiales
                </NavLink>
              </Menu.Item>
            )}
          </SubMenu>
        ) : null}
        <SubMenu
          key={
            this.props.match.url + "/matrices" ||
            this.props.match.url + "/presupuestosPU"
          }
          title="Precios Unitarios"
          icon={<CalculatorOutlined />}
        >
          <Menu.Item
            key={this.props.match.url + "/presupuestosPU"}
            icon={<CalculatorFilled />}
          >
            <NavLink to={this.props.match.url + "/presupuestosPU"}>
              Presupuestos
            </NavLink>
          </Menu.Item>
          {this.props.permisoAccesoInsumosCompuetos ? (
            <Menu.Item
              key={this.props.match.url + "/matrices"}
              icon={<CalculatorOutlined />}
            >
              <NavLink to={this.props.match.url + "/matrices"}>
                Matrices
              </NavLink>
            </Menu.Item>
          ) : null}
          <Menu.Item
            key={this.props.match.url + "/intangibles"}
            icon={<IconAirTable />}
          >
            <NavLink to={this.props.match.url + "/intangibles"}>
              Intangibles
            </NavLink>
          </Menu.Item>
        </SubMenu>
        {this.props.permisoAccesoClientes ||
          this.props.permisoAccesoTipoDeClientes ? (
          <SubMenu
            key={
              this.props.match.url + "/tipodeclientes" ||
              this.props.match.url + "/clientes"
            }
            title="Clientes"
            icon={<ContactsOutlined />}
          >
            {this.props.permisoAccesoClientes && (
              <Menu.Item
                key={this.props.match.url + "/clientes"}
                icon={<ContactsOutlined />}
              >
                <NavLink to={this.props.match.url + "/clientes"}>
                  Clientes
                </NavLink>
              </Menu.Item>
            )}
            {this.props.permisoAccesoTipoDeClientes && (
              <Menu.Item
                key={this.props.match.url + "/tipodeclientes"}
                icon={<ReconciliationFilled />}
              >
                <NavLink to={this.props.match.url + "/tipodeclientes"}>
                  Tipo de Clientes
                </NavLink>
              </Menu.Item>
            )}
          </SubMenu>
        ) : null}
        {this.props.permisoAccesoProveedores ||
          this.props.permisoAccesoTipoDeProveedores ? (
          <SubMenu
            key={
              this.props.match.url + "/tipodeproveedores" ||
              this.props.match.url + "/proveedores"
            }
            title="Proveedores"
            icon={<IdcardOutlined />}
          >
            {this.props.permisoAccesoProveedores && (
              <Menu.Item
                key={this.props.match.url + "/proveedores"}
                icon={<IdcardOutlined />}
              >
                <NavLink to={this.props.match.url + "/proveedores"}>
                  Proveedores
                </NavLink>
              </Menu.Item>
            )}
            {this.props.permisoAccesoTipoDeProveedores && (
              <Menu.Item
                key={this.props.match.url + "/tipodeproveedores"}
                icon={<IdcardFilled />}
              >
                <NavLink
                  to={this.props.match.url + "/tipodeproveedores"}
                >
                  Tipo de Proveedores
                </NavLink>
              </Menu.Item>
            )}
          </SubMenu>
        ) : null}












        <SubMenu
          key={
            this.props.match.url + "/empleados" ||
            this.props.match.url + "/Departamentos" ||
            this.props.match.url + "/Puestos" ||
            this.props.match.url + "/Turnos" ||
            this.props.match.url + "/TiposAcumulados" ||
            this.props.match.url + "/tiposIncidencias" ||
            this.props.match.url + "/tiposPeriodos" ||
            this.props.match.url + "/periodos" ||
            this.props.match.url + "/conceptos" ||
            this.props.match.url + "/tipoPrestaciones" ||
            this.props.match.url + "/registroPatronal"
          }
          title="Capital Humano"
          icon={<TeamOutlined />}
        >
          <Menu.Item
            key={this.props.match.url + "/empleados"}
            icon={<UserOutlined />}
          >
            <NavLink to={this.props.match.url + "/empleados"}>
              Empleados
            </NavLink>
          </Menu.Item>
          {/* <Menu.Item
            key={this.props.match.url + "/Departamentos"}
            icon={<IconBuild />}
          >
            <NavLink to={this.props.match.url + "/Departamentos"}>
              Departamentos
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key={this.props.match.url + "/Puestos"}
            icon={<UsergroupAddOutlined />}
          >
            <NavLink to={this.props.match.url + "/Puestos"}>
              Puestos
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key={this.props.match.url + "/Turnos"}
            icon={<ClockCircleOutlined />}
          >
            <NavLink to={this.props.match.url + "/Turnos"}>
              Turnos
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key={this.props.match.url + "/TiposAcumulados"}
            icon={<IconStack />}
          >
            <NavLink to={this.props.match.url + "/TiposAcumulados"}>
              Tipos Acumulados
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key={this.props.match.url + "/tiposIncidencias"}
            icon={<IconFall />}
          >
            <NavLink to={this.props.match.url + "/tiposIncidencias"}>
              Tipos de Incidencias
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key={this.props.match.url + "/tiposPeriodos"}
            icon={<IconCalendarCog />}
          >
            <NavLink to={this.props.match.url + "/tiposPeriodos"}>
              Tipos de Periodo
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key={this.props.match.url + "/periodos"}
            icon={<IconCalendar />}
          >
            <NavLink to={this.props.match.url + "/periodos"}>
              Periodos
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key={this.props.match.url + "/conceptos"}
            icon={<IconBook />}
          >
            <NavLink to={this.props.match.url + "/conceptos"}>
              Conceptos
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key={this.props.match.url + "/tipoPrestaciones"}
            icon={<IconCash />}
          >
            <NavLink to={this.props.match.url + "/tipoPrestaciones"}>
              Tipo de Prestaciones
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key={this.props.match.url + "/registroPatronal"}
            icon={<IconFile />}
          >
            <NavLink to={this.props.match.url + "/registroPatronal"}>
              Registro Patronal
            </NavLink>
          </Menu.Item> */}
        </SubMenu>
        {this.props.permisoAccesoCuentasContables ||
          this.props.permisoAccesoTipoDePolizas ||
          this.props.permisoAccesoTipoDeFlujos ||
          this.props.permisoAccesoImpuestos ||
          this.props.permisoAccesoRetenciones ? (
          <SubMenu
            key={
              this.props.match.url + "/cuentascontables" ||
              this.props.match.url + "/tipodeflujos" ||
              this.props.match.url + "/impuestos" ||
              this.props.match.url + "/retenciones" ||
              this.props.match.url + "/tipodepolizas"
            }
            title="Contabilidad"
            icon={<BankFilled />}
          >
            {this.props.permisoAccesoCuentasContables && (
              <Menu.Item
                key={this.props.match.url + "/cuentascontables"}
                icon={<BankOutlined />}
              >
                <NavLink
                  to={this.props.match.url + "/cuentascontables"}
                >
                  Cuentas Contables
                </NavLink>
              </Menu.Item>
            )}
            {this.props.permisoAccesoTipoDePolizas && (
              <Menu.Item
                key={this.props.match.url + "/tipodepolizas"}
                icon={<BookOutlined />}
              >
                <NavLink to={this.props.match.url + "/tipodepolizas"}>
                  Tipo de Polizas
                </NavLink>
              </Menu.Item>
            )}
            {/* <Menu.Item
          key={this.props.match.url + "/tipodeegresos"}
          icon={<WalletOutlined />}
        >
          <NavLink to={this.props.match.url + "/tipodeegresos"}>
            Tipo de Egresos
          </NavLink>
        </Menu.Item> */}
            {this.props.permisoAccesoTipoDeFlujos && (
              <Menu.Item
                key={this.props.match.url + "/tipodeflujos"}
                icon={<InteractionOutlined />}
              >
                <NavLink to={this.props.match.url + "/tipodeflujos"}>
                  Tipo de Flujos
                </NavLink>
              </Menu.Item>
            )}
            {this.props.permisoAccesoImpuestos && (
              <Menu.Item
                key={this.props.match.url + "/impuestos"}
                icon={<DollarOutlined />}
              >
                <NavLink to={this.props.match.url + "/impuestos"}>
                  Impuestos
                </NavLink>
              </Menu.Item>
            )}
            {this.props.permisoAccesoRetenciones && (
              <Menu.Item
                key={this.props.match.url + "/retenciones"}
                icon={<DollarCircleFilled />}
              >
                <NavLink to={this.props.match.url + "/retenciones"}>
                  Retenciones
                </NavLink>
              </Menu.Item>
            )}
          </SubMenu>
        ) : null}
        {this.props.permisoAccesoPolizas ||
          this.props.permisoAccesoCargosAbonos ? (
          <SubMenu
            key={
              this.props.match.url + "/polizas" ||
              this.props.match.url + "/presupuestos" ||
              this.props.match.url + "/cargosabonos"
            }
            title="Finanzas"
            icon={<FundProjectionScreenOutlined />}
          >
            <Menu.Item
              key={this.props.match.url + "/presupuestos"}
              icon={<FundFilled />}
            >
              <NavLink to={this.props.match.url + "/presupuestos"}>
                Presupuestos
              </NavLink>
            </Menu.Item>
            {this.props.permisoAccesoPolizas && (
              <Menu.Item
                key={this.props.match.url + "/polizas"}
                icon={<BookFilled />}
              >
                <NavLink to={this.props.match.url + "/polizas"}>
                  Polizas
                </NavLink>
              </Menu.Item>
            )}
            {this.props.permisoAccesoCargosAbonos && (
              <Menu.Item
                key={this.props.match.url + "/cargosabonos"}
                icon={<ClusterOutlined />}
              >
                <NavLink to={this.props.match.url + "/cargosabonos"}>
                  Cargo Abonos
                </NavLink>
              </Menu.Item>
            )}
          </SubMenu>
        ) : null}
        {this.props.permisoAccesoUnidadesDeNegocio ||
          this.props.permisoAccesoProyectos ? (
          <SubMenu
            key={
              this.props.match.url + "/unidadesdenegocio" ||
              this.props.match.url + "/proyectos"
            }
            title="Negocio"
            icon={<ExpandOutlined />}
          >
            {this.props.permisoAccesoUnidadesDeNegocio && (
              <Menu.Item
                key={this.props.match.url + "/unidadesdenegocio"}
                icon={<LayoutOutlined />}
              >
                <NavLink
                  to={this.props.match.url + "/unidadesdenegocio"}
                >
                  Unidades de Negocio
                </NavLink>
              </Menu.Item>
            )}
            {this.props.permisoAccesoProyectos && (
              <Menu.Item
                key={this.props.match.url + "/proyectos"}
                icon={<ProjectOutlined />}
              >
                <NavLink to={this.props.match.url + "/proyectos"}>
                  Proyectos / Almacenes
                </NavLink>
              </Menu.Item>
            )}
            {this.props.permisoAccesoProyectos && this.props.envios && (
              <Menu.Item
                key={this.props.match.url + "/remolques"}
                icon={<CarOutlined />}
              >
                <NavLink to={this.props.match.url + "/remolques"}>
                  Remolques
                </NavLink>
              </Menu.Item>
            )}
          </SubMenu>
        ) : null}









        {/*
      <SubMenu
        key={
          this.props.match.url + "/RegistroDeLotes" ||
          this.props.match.url + "/CorteDePinas"
        }
        title="Mezcal"
        icon={<ShoppingCartOutlined />}
      >
        <Menu.Item
          key={this.props.match.url + "/RegistroDeLotes"}
          icon={<BarsOutlined />}
        >
          <NavLink to={this.props.match.url + "/RegistroDeLotes"}>
            Registro de Lotes
          </NavLink>
        </Menu.Item>
        <Menu.Item
          key={this.props.match.url + "/CorteDePiñas"}
          icon={<BarsOutlined />}
        >
          <NavLink to={this.props.match.url + "/CorteDePiñas"}>
            Corte de Piñas
          </NavLink>
        </Menu.Item>
        <Menu.Item
          key={this.props.match.url + "/Horneado"}
          icon={<BarsOutlined />}
        >
          <NavLink to={this.props.match.url + "/Horneado"}>
            Horneado
          </NavLink>
        </Menu.Item>
        <Menu.Item
          key={this.props.match.url + "/Fermentacion"}
          icon={<BarsOutlined />}
        >
          <NavLink to={this.props.match.url + "/Fermentacion"}>
            Fermentacion
          </NavLink>
        </Menu.Item>
        <Menu.Item
          key={this.props.match.url + "/PrimeraDestilacion"}
          icon={<BarsOutlined />}
        >
          <NavLink to={this.props.match.url + "/PrimeraDestilacion"}>
            Primera Destilacion
          </NavLink>
        </Menu.Item>
        <Menu.Item
          key={this.props.match.url + "/SegundaDestilacion"}
          icon={<BarsOutlined />}
        >
          <NavLink to={this.props.match.url + "/SegundaDestilacion"}>
            Segunda Destilacion
          </NavLink>
        </Menu.Item>
        <Menu.Item
          key={this.props.match.url + "/TerceraDestilacion"}
          icon={<BarsOutlined />}
        >
          <NavLink to={this.props.match.url + "/TerceraDestilacion"}>
            Tercera Destilacion
          </NavLink>
        </Menu.Item>
        <Menu.Item
          key={this.props.match.url + "/TanquesLlenado"}
          icon={<BarsOutlined />}
        >
          <NavLink to={this.props.match.url + "/TanquesLlenado"}>
            Tanques de Llenado
          </NavLink>
        </Menu.Item>
      </SubMenu>*/
        }











        {this.props.permisoAccesoReportes ? (
          <Menu.Item
            key={this.props.match.url + "/reportes"}
            icon={<LineChartOutlined />}
          >
            <NavLink to={this.props.match.url + "/reportes"}>
              Reportes
            </NavLink>
          </Menu.Item>
        ) : null}
        {this.props.permisoAccesoConfiguracion && (
          <Menu.Item
            key={this.props.match.url + "/configuracion"}
            icon={<SettingOutlined />}
          >
            <NavLink to={this.props.match.url + "/configuracion"}>
              Configuracion
            </NavLink>
          </Menu.Item>
        )}
      </>
    )

    return (
      <Layout id="sinfondo">
        <Header id="header-fixed">
          <Menu
            mode="horizontal"
            selectedKeys={current}
            style={{ height: "100%" }}
            id="menu-centered"
          >
            {this.state.isMobile &&
              <Menu.Item
                key={10}
                icon={
                  <Badge count={this.state.unreadCount}>
                    <BellOutlined style={{ fontSize: 20 }} />
                  </Badge>
                }
                onClick={() => this.abrirNotificaciones(true)}
              />
            }
            <Menu.Item key={"/login"} id="menuitem-izquierda">
              <img src={logoHorizontal} alt="Logo" id="img-logo" />
            </Menu.Item>
            {this.state.isMobile ?
              <Menu.Item
                key={0}
                icon={<MenuOutlined style={{ fontSize: 20 }} />}
                onClick={this.toggleDrawer.bind(this)}
              />
              :
              <>
                <Menu.Item disabled id="menuitem-izquierda">
                  {this.props.empresa ? <h1>{this.props.empresa}</h1> : null}
                </Menu.Item>
                <Menu.Item
                  id="menuitem"
                  style={{ paddingLeft: "20px", paddingRight: "20px" }}
                  onClick={() => this.abrirNotificaciones(true)}
                >
                  <Badge count={this.state.unreadCount}>
                    <BellOutlined style={{ fontSize: '17px' }} />
                  </Badge>
                </Menu.Item>
                <SubMenu
                  key="1"
                  id="menuitem"
                  style={{ paddingLeft: "20px", paddingRight: "20px" }}
                  icon={<LogoutOutlined />}
                >
                  <Menu.Item key="2" onClick={this.deseleccionarEmpresa.bind(this)}>
                    Panel Principal
                  </Menu.Item>
                  <Menu.Item key="3" onClick={this.logout.bind(this)}>
                    Cerrar Sesion
                  </Menu.Item>
                </SubMenu>
                <Menu.Item disabled id="menuitem">
                  Panel
                </Menu.Item>
              </>
            }
          </Menu>
        </Header>
        <Layout>
          {this.state.isMobile ?
            <Drawer
              title="Menu"
              placement="left"
              rootClassName="ant-drawer-left"
              onClose={this.toggleDrawer}
              open={this.state.drawerVisible}
              bodyStyle={{ padding: 0 }}
              width={Math.min(300, window.innerWidth - 10)}
            >
              <Menu
                mode="inline"
                theme="light"
                openKeys={this.state.openKeys}
                selectedKeys={current}
                onOpenChange={this.onOpenChange.bind(this)}
              >
                <Menu.Item disabled>
                  {this.props.empresa ? <h1 style={{ fontSize: 10 }}>{this.props.empresa}</h1> : null}
                </Menu.Item>
                {menuItems}
                <Menu.Item icon={<RollbackOutlined />} style={{ marginTop: 20, color: 'blue' }} key="2" onClick={this.deseleccionarEmpresa.bind(this)}>
                  Panel Principal
                </Menu.Item>
                <Menu.Item icon={<LogoutOutlined />} style={{ color: 'red' }} key="3" onClick={this.logout.bind(this)}>
                  Cerrar Sesion
                </Menu.Item>
              </Menu>
            </Drawer>
            :
            <Sider
              collapsible={window.innerWidth > 1000 ? true : false}
              collapsed={window.innerWidth > 1000 ? this.state.collapsed : true}
              onCollapse={window.innerWidth > 1000 ? this.onCollapse : () => { }}
              style={{ marginTop: "64px", minHeight: window.innerHeight - 64 }}
            >
              <div
                style={{
                  overflowY: "scroll",
                  height: window.innerHeight - 64 - 48,
                  position: "sticky",
                  zIndex: 1,
                  top: '64px'
                }}
                className="hide-scrollbar"
              >
                <Menu
                  selectedKeys={current}
                  mode="vertical"
                  theme="dark"
                  openKeys={this.state.openKeys}
                  onOpenChange={this.onOpenChange.bind(this)}
                >
                  {menuItems}
                </Menu>
              </div>
            </Sider>
          }
          <Layout>
            <Content id="content">
              <ScrollToTopItem />
              <Switch>
                {this.props.permisoAccesoInicio && (
                  <Route
                    path={this.props.match.url + "/inicio"}
                    component={Inicio}
                  />
                )}
                {this.props.permisoAccesoLeerXml && (
                  <Route
                    path={this.props.match.url + "/leerXml"}
                    component={LeerXml}
                  />
                )}
                <Route
                  path={this.props.match.url + "/polizaManual"}
                  component={PolizaManual}
                />
                <Route
                  path={this.props.match.url + "/editarPolizaManual"}
                  component={EditarPolizaManual}
                />
                {this.props.permisoAccesoBancos && (
                  <Route
                    path={this.props.match.url + "/bancos"}
                    component={Bancos}
                  />
                )}
                {this.props.permisoAccesoBancos && (
                  <Route
                    path={this.props.match.url + "/conciliarCuenta"}
                    component={ConciliacionesCuenta}
                  />
                )}
                {this.props.permisoAccesoBancos && (
                  <Route
                    path={this.props.match.url + "/conciliacion"}
                    component={Conciliacion}
                  />
                )}
                {this.props.permisoAccesoBancos && (
                  <Route
                    path={this.props.match.url + "/verConciliacion"}
                    component={VerConciliacion}
                  />
                )}
                {this.props.permisoAccesoCajaChica && (
                  <Route
                    path={this.props.match.url + "/cajaefectivo"}
                    component={CajaEfectivo}
                  />
                )}
                {this.props.permisoAccesoVentas && (
                  <Route
                    path={this.props.match.url + "/ventas"}
                    component={Ventas}
                  />
                )}
                {this.props.permisoAccesoVentas && (
                  <Route
                    path={this.props.match.url + "/ventasProgramadas"}
                    component={VentasProgramadas}
                  />
                )}
                {this.props.permisoAccesoVentas && (
                  <Route
                    path={this.props.match.url + "/agregarVentaProgramada"}
                    component={AgregarVentaProgramada}
                  />
                )}
                {this.props.permisoAccesoVentas && (
                  <Route
                    path={this.props.match.url + "/editarVentaProgramada"}
                    component={EditarVentaProgramada}
                  />
                )}
                {this.props.permisoAccesoVentas && (
                  <Route
                    path={this.props.match.url + "/pendientesVentasProgramadas"}
                    component={PendientesVentasProgramadas}
                  />
                )}
                {this.props.permisoAccesoCotizaciones &&
                  <Route
                    path={this.props.match.url + "/cotizaciones"}
                    component={Cotizaciones}
                  />}
                {this.props.permisoAccesoAgregarCotizaciones &&
                  <Route
                    path={this.props.match.url + "/agregarCotizacion"}
                    component={AgregarCotizacion}
                  />}
                {this.props.permisoAccesoAgregarCotizaciones &&
                  <Route
                    path={this.props.match.url + "/editarCotizacion"}
                    component={EditarCotizacion}
                  />}
                {this.props.permisoAccesoVerCotizaciones &&
                  <Route
                    path={this.props.match.url + "/verCotizacion"}
                    component={VerCotizacion}
                  />}
                <Route
                  path={this.props.match.url + "/agregarVenta"}
                  component={AgregarVenta}
                />
                <Route
                  path={this.props.match.url + "/puntoDeVenta"}
                  component={PuntoDeVenta}
                />
                <Route
                  path={this.props.match.url + "/verVenta"}
                  component={VerVenta}
                />
                {this.props.permisoAccesoCuentasPorPagar && (
                  <Route
                    path={this.props.match.url + "/cuentasporpagar"}
                    component={CuentasPorPagar}
                  />
                )}
                {this.props.permisoAccesoCuentasPorCobrar && (
                  <Route
                    path={this.props.match.url + "/cuentasporcobrar"}
                    component={CuentasPorCobrar}
                  />
                )}
                {this.props.permisoCobrar && (
                  <Route
                    path={this.props.match.url + "/verVentasCliente"}
                    component={VerVentasCliente}
                  />
                )}
                {this.props.permisoCobrar && (
                  <Route
                    path={this.props.match.url + "/cobrarVenta"}
                    component={CobrarVenta}
                  />
                )}
                <Route
                  path={this.props.match.url + "/pagarFactura"}
                  component={PagarFactura}
                />
                <Route
                  path={this.props.match.url + "/verFacturasProveedor"}
                  component={VerFacturasProveedor}
                />
                {this.props.permisoAccesoClientes && (
                  <Route
                    path={this.props.match.url + "/clientes"}
                    component={Clientes}
                  />
                )}
                <Route
                  path={this.props.match.url + "/importarClientes"}
                  component={ImportarClientes}
                />
                {this.props.permisoAccesoProveedores && (
                  <Route
                    path={this.props.match.url + "/proveedores"}
                    component={Proveedores}
                  />
                )}
                <Route
                  path={this.props.match.url + "/empleados"}
                  component={Empleados}
                />
                <Route
                  path={this.props.match.url + "/agregarEmpleado"}
                  component={AgregarEmpleado}
                />
                <Route
                  path={this.props.match.url + "/editarEmpleado"}
                  component={EditarEmpleado}
                />
                <Route
                  path={this.props.match.url + "/importarProveedores"}
                  component={ImportarProveedores}
                />
                {this.props.permisoAccesoTipoDeProveedores && (
                  <Route
                    path={this.props.match.url + "/tipodeproveedores"}
                    component={TiposDeProveedores}
                  />
                )}
                {this.props.permisoAccesoTipoDeClientes && (
                  <Route
                    path={this.props.match.url + "/tipodeclientes"}
                    component={TiposDeClientes}
                  />
                )}
                {this.props.permisoAccesoInsumos && (
                  <Route
                    path={this.props.match.url + "/insumos"}
                    component={Insumos}
                  />
                )}
                {this.props.permisoAccesoInsumosCompuetos ? (
                  <Route
                    path={this.props.match.url + "/insumosCompuestos"}
                    component={InsumosCompuestos}
                  />
                ) : null}
                {this.props.permisoAccesoServicios ? (
                  <Route
                    path={this.props.match.url + "/servicios"}
                    component={Servicios}
                  />
                ) : null}
                {this.props.permisoAccesoInventario && (
                  <Route
                    path={this.props.match.url + "/inventarioInsumo"}
                    component={InventarioProducto}
                  />
                )}
                {this.props.permisoAccesoInventario && (
                  <Route
                    path={this.props.match.url + "/inventario"}
                    component={Inventario}
                  />
                )}
                {this.props.permisoAccesoInventario && (
                  <Route
                    path={this.props.match.url + "/ControlInventario"}
                    component={ControlInventario}
                  />
                )}
                {this.props.permisoAccesoProduccion && (
                  <Route
                    path={this.props.match.url + "/produccion"}
                    component={Produccion}
                  />
                )}
                {this.props.permisoAccesoListaDeMateriales && (
                  <Route
                    path={this.props.match.url + "/listaDeMateriales"}
                    component={ListaDeMateriales}
                  />
                )}
                <Route
                  path={this.props.match.url + "/agregarOrdenDeProduccion"}
                  component={AgregarOrdenDeProduccion}
                />
                <Route
                  path={
                    this.props.match.url + "/agregarOrdenDeProduccionConLista"
                  }
                  component={AgregarOrdenDeProduccionConLista}
                />
                <Route
                  path={this.props.match.url + "/verOrdenDeProduccion"}
                  component={VerOrdenDeProduccion}
                />
                <Route
                  path={this.props.match.url + "/agregarListaDeMateriales"}
                  component={AgregarListaDeMateriales}
                />
                <Route
                  path={this.props.match.url + "/editarListaDeMateriales"}
                  component={EditarListaDeMateriales}
                />
                {this.props.permisoAccesoTraspasoAlmacenes && (
                  <Route
                    path={this.props.match.url + "/traspasoEntreAlmacenes"}
                    component={TraspasoEntreAlmacenes}
                  />
                )}
                {this.props.permisoAccesoTraspasoAlmacenes && (
                  <Route
                    path={
                      this.props.match.url +
                      "/agregarOrdenDeTraspasoEntreAlmacenes"
                    }
                    component={AgregarTraspasoEntreAlmacenes}
                  />
                )}
                {this.props.permisoAccesoAgregarInventario && (
                  <Route
                    path={this.props.match.url + "/agregarInventario"}
                    component={AgregarInventario}
                  />
                )}
                <Route
                  path={this.props.match.url + "/agregarOrdenAgregarInventario"}
                  component={AgregarOrdenAgregarInventario}
                />
                <Route
                  path={this.props.match.url + "/verOrdenAgregarInventario"}
                  component={VerOrdenAgregarInventario}
                />
                {this.props.permisoAccesoProductosAlCosto && (
                  <Route
                    path={this.props.match.url + "/productosAlCosto"}
                    component={ProductosAlCosto}
                  />
                )}
                <Route
                  path={this.props.match.url + "/agregarOrdenProductosAlCosto"}
                  component={AgregarOrdenProductosAlCosto}
                />
                <Route
                  path={this.props.match.url + "/verOrdenProductosAlCosto"}
                  component={VerOrdenProductosAlCosto}
                />
                {this.props.permisoAccesoFamilias && (
                  <Route
                    path={this.props.match.url + "/familias"}
                    component={Familias}
                  />
                )}
                {this.props.permisoAccesoImpuestos && (
                  <Route
                    path={this.props.match.url + "/impuestos"}
                    component={Impuestos}
                  />
                )}
                {this.props.permisoAccesoRetenciones && (
                  <Route
                    path={this.props.match.url + "/retenciones"}
                    component={Retenciones}
                  />
                )}
                <Route
                  path={this.props.match.url + "/agregarRetencion"}
                  component={AgregarRetencion}
                />
                <Route
                  path={this.props.match.url + "/editarRetencion"}
                  component={EditarRetencion}
                />
                {this.props.permisoAccesoCargosAbonos && (
                  <Route
                    path={this.props.match.url + "/cargosabonos"}
                    component={CargosAbonos}
                  />
                )}
                {this.props.permisoAccesoPolizas && (
                  <Route
                    path={this.props.match.url + "/polizas"}
                    component={Polizas}
                  />
                )}
                <Route
                  path={this.props.match.url + "/presupuestos"}
                  component={Presupuestos}
                />
                <Route
                  path={this.props.match.url + "/agregarPresupuesto"}
                  component={AgregarPresupuesto}
                />
                <Route
                  path={this.props.match.url + "/editarPresupuesto"}
                  component={EditarPresupuesto}
                />
                <Route
                  path={this.props.match.url + "/verPoliza"}
                  component={VerPoliza}
                />
                {this.props.permisoAccesoCuentasContables && (
                  <Route
                    path={this.props.match.url + "/cuentascontables"}
                    component={CuentasContables}
                  />
                )}
                <Route
                  path={this.props.match.url + "/descargaMasiva"}
                  component={DescargaMasiva}
                />
                {this.props.permisoAccesoTipoDePolizas && (
                  <Route
                    path={this.props.match.url + "/tipodepolizas"}
                    component={TiposDePolizas}
                  />
                )}
                {/* <Route
                  path={this.props.match.url + "/tipodeegresos"}
                  component={TiposDeEgresos}
                /> */}
                {this.props.permisoAccesoTipoDeFlujos && (
                  <Route
                    path={this.props.match.url + "/tipodeflujos"}
                    component={TiposDeFlujos}
                  />
                )}
                {this.props.permisoAccesoOrdenesDeCompra && (
                  <Route
                    path={this.props.match.url + "/ordenesdecompra"}
                    component={OrdenesDeCompras}
                  />
                )}
                <Route
                  path={this.props.match.url + "/requisiciones"}
                  component={Requisiciones}
                />
                <Route
                  path={this.props.match.url + "/agregarRequisicion"}
                  component={AgregarRequisicion}
                />
                <Route
                  path={this.props.match.url + "/editarRequisicion"}
                  component={EditarRequisicion}
                />
                <Route
                  path={
                    this.props.match.url + "/generarOrdenDeCompraConRequisicion"
                  }
                  component={GenerarOrdenDeCompra}
                />
                {this.props.permisoAccesoFacturasSinOrdenDeCompra && (
                  <Route
                    path={this.props.match.url + "/facturassinordendecompra"}
                    component={FacturasSinOrdenDeCompra}
                  />
                )}
                {this.props.permisoAccesoFacturasCajaChica && (
                  <Route
                    path={this.props.match.url + "/facturascajachica"}
                    component={FacturasCajaChica}
                  />
                )}
                <Route
                  path={this.props.match.url + "/reponerCajaChica"}
                  component={ReponerCajaChica}
                />
                <Route
                  path={this.props.match.url + "/traspasoEntreCuentas"}
                  component={TraspasoEntreCuentas}
                />
                {this.props.permisoAccesoFacturasSinOrdenDeCompra && (
                  <Route
                    path={this.props.match.url + "/remisionesCompra"}
                    component={RemisionesCompra}
                  />
                )}
                <Route
                  path={
                    this.props.match.url + "/agregarFacturaSinOrdenDeCompra"
                  }
                  component={AgregarFactura}
                />
                <Route
                  path={this.props.match.url + "/verFacturaSinOrdenDeCompra"}
                  component={VerFacturaSinOrdenDeCompra}
                />
                <Route
                  path={this.props.match.url + "/agregarFacturaCajaChica"}
                  component={AgregarFacturaCajaChica}
                />
                <Route
                  path={this.props.match.url + "/verFacturaCajaChica"}
                  component={VerFacturaCajaChica}
                />
                <Route
                  path={this.props.match.url + "/recibirInsumosFactura"}
                  component={RecibirInsumosFactura}
                />
                <Route
                  path={this.props.match.url + "/entregarInsumosVenta"}
                  component={EntregarInsumosVenta}
                />
                <Route
                  path={this.props.match.url + "/requerirInsumosVenta"}
                  component={RequerirInsumosVenta}
                />
                <Route
                  path={this.props.match.url + "/costearVenta"}
                  component={CostearVenta}
                />
                <Route
                  path={this.props.match.url + "/generarFacturaVenta"}
                  component={GenerarFactura}
                />
                {this.props.permisoAccesoUnidadesDeNegocio && (
                  <Route
                    path={this.props.match.url + "/unidadesdenegocio"}
                    component={UnidadesDeNegocio}
                  />
                )}
                {this.props.permisoAccesoProyectos && (
                  <Route
                    path={this.props.match.url + "/proyectos"}
                    component={Proyectos}
                  />
                )}
                {this.props.permisoAccesoConfiguracion && (
                  <Route
                    path={this.props.match.url + "/configuracion"}
                    component={Configuracion}
                  />
                )}
                <Route
                  path={this.props.match.url + "/agregarProveedor"}
                  component={AgregarProveedor}
                />
                <Route
                  path={this.props.match.url + "/editarProveedor"}
                  component={EditarProveedor}
                />
                <Route
                  path={this.props.match.url + "/agregarCliente"}
                  component={AgregarCliente}
                />
                <Route
                  path={this.props.match.url + "/editarCliente"}
                  component={EditarCliente}
                />
                <Route
                  path={this.props.match.url + "/agregarOrdenDeCompra"}
                  component={AgregarOrdenDeCompra}
                />
                <Route
                  path={this.props.match.url + "/editarOrdenDeCompra"}
                  component={EditarOrdenDeCompra}
                />
                <Route
                  path={this.props.match.url + "/verOrdenDeCompra"}
                  component={VerOrdenDeCompra}
                />
                <Route
                  path={this.props.match.url + "/verFacturaOrdenDeCompra"}
                  component={VerFacturaOrdenDeCompra}
                />
                <Route
                  path={this.props.match.url + "/recibirInsumosOrdenDeCompra"}
                  component={RecibirInsumos}
                />
                <Route
                  path={this.props.match.url + "/recibirFacturaOrdenDeCompra"}
                  component={RecibirFactura}
                />
                <Route
                  path={this.props.match.url + "/agregarInsumo"}
                  component={AgregarInsumo}
                />
                <Route
                  path={this.props.match.url + "/importarInsumos"}
                  component={ImportarInsumos}
                />
                <Route
                  path={this.props.match.url + "/editarInsumo"}
                  component={EditarInsumo}
                />
                <Route
                  path={this.props.match.url + "/agregarInsumoCompuesto"}
                  component={AgregarInsumoCompuesto}
                />
                <Route
                  path={this.props.match.url + "/editarInsumoCompuesto"}
                  component={EditarInsumoCompuesto}
                />
                <Route
                  path={this.props.match.url + "/agregarServicio"}
                  component={AgregarServicio}
                />
                <Route
                  path={this.props.match.url + "/editarServicio"}
                  component={EditarServicio}
                />
                <Route
                  path={this.props.match.url + "/agregarTipoDeProveedor"}
                  component={AgregarTipoDeProveedor}
                />
                <Route
                  path={this.props.match.url + "/editarTipoDeProveedor"}
                  component={EditarTipoDeProveedor}
                />
                <Route
                  path={this.props.match.url + "/agregarTipoDeCliente"}
                  component={AgregarTipoDeCliente}
                />
                <Route
                  path={this.props.match.url + "/editarTipoDeCliente"}
                  component={EditarTipoDeCliente}
                />
                <Route
                  path={this.props.match.url + "/agregarImpuesto"}
                  component={AgregarImpuesto}
                />
                <Route
                  path={this.props.match.url + "/editarImpuesto"}
                  component={EditarImpuesto}
                />
                <Route
                  path={this.props.match.url + "/agregarNotaDeCredito"}
                  component={AgregarNotaDeCredito}
                />
                <Route
                  path={this.props.match.url + "/verNotaDeCredito"}
                  component={VerNotaDeCredito}
                />
                {this.props.permisoAccesoReporteVentasGenerales &&
                  <Route
                    path={this.props.match.url + "/reporteVentasGenerales"}
                    component={ReporteVentas}
                  />
                }
                {this.props.permisoAccesoReporteComprasGenerales &&
                  <Route
                    path={this.props.match.url + "/reporteComprasGenerales"}
                    component={ReporteCompras}
                  />
                }
                {this.props.permisoAccesoReporteInventarioTotal &&
                  <Route
                    path={this.props.match.url + "/reporteInventarioTotal"}
                    component={ReporteInventario}
                  />
                }
                {this.props.permisoAccesoReporteCostoInventario &&
                  <Route
                    path={this.props.match.url + "/reporteCostoInventario"}
                    component={ReporteCostoInventario}
                  />
                }
                {this.props.permisoAccesoReporteCuentasPorCobrar &&
                  <Route
                    path={this.props.match.url + "/reporteCuentasPorCobrar"}
                    component={ReporteCuentasPorCobrar}
                  />
                }
                {this.props.permisoAccesoReporteCuentasPorPagar &&
                  <Route
                    path={this.props.match.url + "/reporteCuentasPorPagar"}
                    component={ReporteCuentasPorPagar}
                  />
                }
                {this.props.permisoAccesoReporteEstadoDePosicionFinanciera &&
                  <Route
                    path={
                      this.props.match.url + "/reporteEstadoDePosicionFinanciera"
                    }
                    component={ReporteEstadoDePosicionFinanciera}
                  />
                }
                {this.props.permisoAccesoReporteEstadoDeResultados &&
                  <Route
                    path={this.props.match.url + "/reporteEstadoDeResultados"}
                    component={ReporteEstadoDeResultados}
                  />
                }
                {this.props.permisoAccesoReporteEstadoDeCuenta &&
                  <Route
                    path={this.props.match.url + "/reporteEstadoDeCuenta"}
                    component={ReporteEstadoDeCuenta}
                  />
                }
                {this.props.permisoAccesoReporteBalanzaDeComprabacion &&
                  <Route
                    path={this.props.match.url + "/reporteBalanzaDeComprobacion"}
                    component={ReporteBalanzaDeComprobacion}
                  />
                }
                {this.props.permisoAccesoReporteFlujoDeEfectivo &&
                  <Route
                    path={this.props.match.url + "/reporteFlujoDeEfectivo"}
                    component={ReporteFlujoDeEfectivo}
                  />
                }
                {this.props.permisoAccesoReporteFlujoDeEfectivo &&
                  <Route
                    path={this.props.match.url + "/reporteFlujoDeEfectivoPresupuestado"}
                    component={ReporteFlujoDeEfectivoPresupuestado}
                  />
                }
                <Route
                  path={this.props.match.url + "/reporteLibroDiario"}
                  component={ReporteLibroDiario}
                />
                <Route
                  path={this.props.match.url + "/reportePresupuestos"}
                  component={ReportePresupuestos}
                />
                <Route
                  path={this.props.match.url + "/reportePagos"}
                  component={ReportePagos}
                />
                <Route
                  path={this.props.match.url + "/reporteReposicionCajaChica"}
                  component={ReporteReposicionCajaChica}
                />
                <Route
                  path={this.props.match.url + "/reporteIngresos"}
                  component={ReporteIngresos}
                />
                <Route
                  path={this.props.match.url + "/reporteCostoProduccion"}
                  component={ReporteCostoProduccion}
                />
                {this.props.permisoAccesoReportes ? (
                  <Route
                    path={this.props.match.url + "/reportes"}
                    component={Reportes}
                  />
                ) : null}
                {this.props.certificadaDM && this.props.permisoAccesoArchivosXML ? (
                  <Route
                    path={this.props.match.url + "/archivosXml"}
                    component={ArchivosXml}
                  />) : null
                }
                {this.props.envios &&
                  <Route
                    path={this.props.match.url + "/generarEnvioVenta"}
                    component={GenerarEnvio}
                  />
                }
                {/* {this.props.envios &&
                  <Route
                    path={this.props.match.url + "/RegistroDeLotes"}
                    component={RegistroDeLotes}
                  />
                }
                {this.props.envios &&
                  <Route
                    path={this.props.match.url + "/CorteDePiñas"}
                    component={CorteDePiñas}
                  />
                }
                {this.props.envios &&
                  <Route
                    path={this.props.match.url + "/Horneado"}
                    component={Horneado}
                  />
                }
                {this.props.envios &&
                  <Route
                    path={this.props.match.url + "/Fermentacion"}
                    component={Fermentacion}
                  />
                }
                {this.props.envios &&
                  <Route
                    path={this.props.match.url + "/PrimeraDestilacion"}
                    component={PrimeraDestilacion}
                  />
                }
                {this.props.envios &&
                  <Route
                    path={this.props.match.url + "/SegundaDestilacion"}
                    component={SegundaDestilacion}
                  />
                }
                {this.props.envios &&
                  <Route
                    path={this.props.match.url + "/TerceraDestilacion"}
                    component={TerceraDestilacion}
                  />
                }
                {this.props.envios &&
                  <Route
                    path={this.props.match.url + "/TanquesLlenado"}
                    component={TanquesLlenado}
                  />
                } */}
                {this.props.envios &&
                  <Route
                    path={this.props.match.url + "/Departamentos"}
                    component={Departamentos}
                  />
                }
                {this.props.envios &&
                  <Route
                    path={this.props.match.url + "/Puestos"}
                    component={Puestos}
                  />
                }
                {this.props.envios &&
                  <Route
                    path={this.props.match.url + "/Turnos"}
                    component={Turnos}
                  />
                }
                {this.props.envios &&
                  <Route
                    path={this.props.match.url + "/TiposAcumulados"}
                    component={TiposAcumulados}
                  />
                }
                {this.props.envios &&
                  <Route
                    path={this.props.match.url + "/tiposIncidencias"}
                    component={TiposIncidencias}
                  />
                }
                {this.props.envios &&
                  <Route
                    path={this.props.match.url + "/tiposPeriodos"}
                    component={TipoPeriodo}
                  />
                }
                {this.props.envios &&
                  <Route
                    path={this.props.match.url + "/conceptos"}
                    component={Conceptos}
                  />
                }
                {this.props.envios &&
                  <Route
                    path={this.props.match.url + "/tipoPrestaciones"}
                    component={TipoPrestaciones}
                  />
                }
                {this.props.envios &&
                  <Route
                    path={this.props.match.url + "/registroPatronal"}
                    component={RegistroPatronal}
                  />
                }
                <Route
                  path={this.props.match.url + "/agregarConcepto"}
                  component={AgregarConcepto}
                />
                <Route
                  path={this.props.match.url + "/editarConcepto"}
                  component={EditarConcepto}
                />
                <Route
                  path={this.props.match.url + "/agregarTipoPrestaciones"}
                  component={AgregarTipoPrestaciones}
                />
                <Route
                  path={this.props.match.url + "/editarTipoPrestaciones"}
                  component={EditarTipoPrestaciones}
                />
                <Route
                  path={this.props.match.url + "/periodos"}
                  component={Periodos}
                />
                <Route
                  path={this.props.match.url + "/verEnvio"}
                  component={VerEnvio}
                />
                <Route
                  path={this.props.match.url + "/verPagosCajaChica"}
                  component={VerPagosCajaChica}
                />
                <Route
                  path={this.props.match.url + "/matrices"}
                  component={Matrices}
                />
                {this.props.permisoAgregarMatriz &&
                  <Route
                    path={this.props.match.url + "/agregarMatriz"}
                    component={AgregarMatriz}
                  />}
                <Route
                  path={this.props.match.url + "/editarMatriz"}
                  component={EditarMatriz}
                />
                <Route
                  path={this.props.match.url + "/presupuestosPU"}
                  component={PresupuestosPU}
                />
                <Route
                  path={this.props.match.url + "/presupuestoPU"}
                  component={PresupuestoPU}
                />
                <Route
                  path={this.props.match.url + "/facturaGlobal"}
                  component={FacturaGlobal}
                />
                <Route
                  path={this.props.match.url + "/consignaciones"}
                  component={Consignaciones}
                />
                {this.props.permisoAgregarConsignacion &&
                  <Route
                    path={this.props.match.url + "/agregarConsignacion"}
                    component={AgregarConsignacion}
                  />}
                <Route
                  path={this.props.match.url + "/verConsignacion"}
                  component={VerConsignacion}
                />
                <Route
                  path={this.props.match.url + "/inventarioConsignaciones"}
                  component={InventarioConsignaciones}
                />
                <Route
                  path={this.props.match.url + "/ventasConsignacion"}
                  component={VentasConsignacion}
                />
                <Route
                  path={this.props.match.url + "/agregarVentaConsignacion"}
                  component={AgregarVentaConsignacion}
                />
                <Route
                  path={this.props.match.url + "/verVentaConsignacion"}
                  component={VerVentaConsignacion}
                />
                <Route
                  path={this.props.match.url + "/facturaGlobalConsignacion"}
                  component={FacturaGlobalConsignacion}
                />
                <Route
                  path={this.props.match.url + "/entregarInsumosVentaConsignacion"}
                  component={EntregarInsumosVentaConsignacion}
                />
                <Route
                  path={this.props.match.url + "/generarFacturaVentaConsignacion"}
                  component={GenerarFacturaConsignacion}
                />
                {this.props.permisoDarBajaConsignacion &&
                  <Route
                    path={this.props.match.url + "/bajasConsignaciones"}
                    component={BajasConsignaciones}
                  />
                }
                {this.props.permisoDarBajaConsignacion &&
                  <Route
                    path={this.props.match.url + "/agregarBajaConsignacion"}
                    component={BajaConsignacion}
                  />
                }
                <Route
                  path={this.props.match.url + "/bajasConsignaciones"}
                  component={BajasConsignaciones}
                />
                <Route
                  path={this.props.match.url + "/agregarBajaConsignacion"}
                  component={BajaConsignacion}
                />
                <Route
                  path={this.props.match.url + "/remolques"}
                  component={Remolques}
                />
                {/* <Route
                  path={this.props.match.url + "/agregarTipoDeEgreso"}
                  component={AgregarTipoDeEgreso}
                /> */}
                <Route
                  path={this.props.match.url + "/tiposDeInsumos"}
                  component={TiposDeInsumos}
                />
                <Route
                  path={this.props.match.url + "/agregarNotaDeCreditoVenta"}
                  component={AgregarNotaDeCreditoVenta}
                />
                <Route
                  path={this.props.match.url + "/verNotaDeCreditoVenta"}
                  component={VerNotaDeCreditoVenta}
                />
                <Route
                  path={this.props.match.url + "/agregarDevolucion"}
                  component={AgregarDevolucion}
                />
                <Route
                  path={this.props.match.url + "/verDevolucion"}
                  component={VerDevolucion}
                />
                <Route
                  path={this.props.match.url + "/remolques"}
                  component={Remolques}
                />
                <Route
                  path={this.props.match.url + "/intangibles"}
                  component={Indirectos}
                />
                <Route
                  path={this.props.match.url + "/agregarIntangible"}
                  component={AgregarIndirecto}
                />
                <Route
                  path={this.props.match.url + "/editarIntangible"}
                  component={EditarIndirecto}
                />
                <Route
                  path={this.props.match.url + "/doctores"}
                  component={Doctores}
                />
                <Route
                  path={this.props.match.url + "/pacientes"}
                  component={Pacientes}
                />
              </Switch>
            </Content>
            {/* <Footer style={{ textAlign: "center" }}>Biwo ©2020</Footer> */}
          </Layout>
        </Layout>
        <Drawer
          title="Notificaciones"
          placement="right"
          rootClassName='ant-drawer-right'
          closable
          onClose={() => this.abrirNotificaciones(false)}
          open={this.state.openNotificaciones}
          destroyOnClose
          width={Math.min(400, window.innerWidth - 10)}
        >
          <Spin spinning={this.state.loading}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 16,
              }}
            >
              <span style={{ fontWeight: "bold" }}>Tus notificaciones</span>
              <Button type="link" onClick={this.onMarkAllAsRead}>
                Marcar todas como leídas
              </Button>
            </div>
            <List
              dataSource={this.state.notifications}
              renderItem={(item) => (
                <List.Item
                  style={{
                    padding: "10px 0",
                    cursor: "pointer",
                    backgroundColor: item.read ? undefined : "rgba(24, 144, 255, 0.1)",
                    borderBottom: "1px solid rgba(13, 71, 126, 0.25)",
                  }}
                  onClick={() => {
                    if (!item.read) {
                      this.noLeidasaLeidas(item.id);
                    }
                    this.onMarkAsRead(item.id, item.ruta, item.estadoRuta);
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <div style={{ marginRight: 15, marginLeft: 10, fontSize: 17 }}>{item.icon}</div>
                    <div style={{ width: "100%", paddingRight: 10 }}>
                      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <span style={{ fontWeight: "bold" }}>{item.title}</span>
                        <span style={{ fontSize: "12px", color: "#888" }}>{item.fecha}</span>
                      </div>
                      <div style={{ fontWeight: item.read ? "normal" : "bold" }}>
                        {item.message}
                      </div>
                    </div>
                  </div>
                </List.Item>
              )}
            />
            <div style={{ textAlign: "center", marginTop: 16 }}>

              {this.state.button &&
                <ButtonItem
                  type="primary"
                  block
                  blanco
                  loading={this.state.loadingTodas}
                  onClick={() => {
                    this.consultaTodasNotificaciones()
                  }}
                >
                  Mostrar todas
                </ButtonItem>}
            </div>
          </Spin>
        </Drawer>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    empresa: state.empresa.nombre,
    empresaId: state.empresa.id,
    empresaAutomatica: state.empresa.automatica,
    certificadaDM: state.empresa.certificadaDM,
    envios: state.empresa.envios,
    modulosConsignaciones: state.empresa.modulosConsignaciones,
    equipoMedico: state.empresa.equipoMedico,
    usuarioId: state.user.usuarioId,
    tipoUsuario: state.user.tipo,
    cuentaVentasPendientes: state.empresa.cuentaVentasPendientes,
    permisoAccesoBancos:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "615c96325a335e58746d240e"
      )?.activado,
    permisoAccesoCajaChica:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "615ca6b1f30e81b54183cf4f"
      )?.activado,
    permisoAccesoCuentasPorCobrar:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "615cbb4ff30e81b54183cf56"
      )?.activado,
    permisoAccesoOrdenesDeCompra:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "615cbd84f30e81b54183cf5d"
      )?.activado,
    permisoAccesoFacturasSinOrdenDeCompra:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "615cbec7f30e81b54183cf5f"
      )?.activado,
    permisoAccesoFacturasCajaChica:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "615cc550f30e81b54183cf65"
      )?.activado,
    permisoAccesoVentas:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "615ccb1cf30e81b54183cf6f"
      )?.activado,
    permisoAccesoInsumos:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "615ccce4f30e81b54183cf74"
      )?.activado,
    permisoAccesoFamilias:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "615cceadf30e81b54183cf78"
      )?.activado,
    permisoAccesoClientes:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "615cd417f30e81b54183cf87"
      )?.activado,
    permisoAccesoTipoDeClientes:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "615cd4e4f30e81b54183cf8a"
      )?.activado,
    permisoAccesoProveedores:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "615cd6c4f30e81b54183cf90"
      )?.activado,
    permisoAccesoTipoDeProveedores:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "615cd77cf30e81b54183cf93"
      )?.activado,
    permisoAccesoCuentasContables:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "615cda3ef30e81b54183cf9c"
      )?.activado,
    permisoAccesoTipoDePolizas:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "615cdbaaf30e81b54183cf9f"
      )?.activado,
    permisoAccesoTipoDeFlujos:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "615cdce1f30e81b54183cfa2"
      )?.activado,
    permisoAccesoImpuestos:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "615cddf1f30e81b54183cfa5"
      )?.activado,
    permisoAccesoRetenciones:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "615cde9af30e81b54183cfa8"
      )?.activado,
    permisoAccesoCuentasPorPagar:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "615ce296f30e81b54183cfad"
      )?.activado,
    permisoAccesoPolizas:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "615cea60f30e81b54183cfbe"
      )?.activado,
    permisoAccesoCargosAbonos:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "615ceb36f30e81b54183cfc1"
      )?.activado,
    permisoAccesoUnidadesDeNegocio:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "615cecdbf30e81b54183cfc6"
      )?.activado,
    permisoAccesoProyectos:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "615ced40f30e81b54183cfc8"
      )?.activado,
    permisoAccesoConfiguracion:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "615ceea4f30e81b54183cfcf"
      )?.activado,
    permisoAccesoInicio:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "615dd9e78b9901c35431f498"
      )?.activado,
    permisoCobrar:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "615cbbc8f30e81b54183cf57"
      )?.activado,
    permisoAccesoReportes:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "64908a33ce82367cc8068c2b"
      )?.activado,
    permisoAccesoReporteVentasGenerales:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6489ee6f6ebb47b9440df7b0"
      )?.activado,
    permisoAccesoReporteComprasGenerales:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6489ef026ebb47b9440df7b1"
      )?.activado,
    permisoAccesoReporteInventarioTotal:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6489ef186ebb47b9440df7b2"
      )?.activado,
    permisoAccesoReporteCostoInventario:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6489ef3a6ebb47b9440df7b3"
      )?.activado,
    permisoAccesoReporteCuentasPorCobrar:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6489ef556ebb47b9440df7b4"
      )?.activado,
    permisoAccesoReporteCuentasPorPagar:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6489ef796ebb47b9440df7b5"
      )?.activado,
    permisoAccesoReporteEstadoDePosicionFinanciera:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6489ef8f6ebb47b9440df7b6"
      )?.activado,
    permisoAccesoReporteEstadoDeResultados:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6489efb16ebb47b9440df7b7"
      )?.activado,
    permisoAccesoReporteEstadoDeCuenta:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6489efd56ebb47b9440df7b8"
      )?.activado,
    permisoAccesoReporteBalanzaDeComprabacion:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6489efeb6ebb47b9440df7b9"
      )?.activado,
    permisoAccesoReporteFlujoDeEfectivo:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6489f00b6ebb47b9440df7ba"
      )?.activado,
    permisoAccesoServicios:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6472d53f4b2da7c85c1a8d01"
      )?.activado,
    permisoAccesoInsumosCompuetos:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6472d63b4b2da7c85c1a8d05"
      )?.activado,
    permisoAccesoTraspasoAlmacenes:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6478f25a0d24b4b09c994351"
      )?.activado,
    permisoAccesoProduccion:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6472de194b2da7c85c1a8d10"
      )?.activado,
    permisoAccesoListaDeMateriales:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6472df204b2da7c85c1a8d13"
      )?.activado,
    permisoAccesoLeerXml:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "647a1df7bd6a695a9801702f"
      )?.activado,
    permisoAccesoInventario:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "615cd133f30e81b54183cf80"
      )?.activado,
    permisoAccesoAgregarInventario:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6472dc304b2da7c85c1a8d0e"
      )?.activado,
    permisoAccesoProductosAlCosto:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "647fc47d9dc9c6543b161b89"
      )?.activado,
    permisoAccesoArchivosXML:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "67508ec3798d4e0043687238"
      )?.activado,
    permisoAccesoCotizaciones:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "675091fa798d4e0043687241"
      )?.activado,
    permisoAccesoAgregarCotizaciones:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6750922d798d4e0043687242"
      )?.activado,
    permisoAccesoVerCotizaciones:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6750925b798d4e0043687244"
      )?.activado,
    permisoAgregarConsignacion:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "675092cc798d4e0043687246"
      )?.activado,
    permisoDarBajaConsignacion:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6750933f798d4e0043687247"
      )?.activado,
    permisoAgregarConsignacionInventario:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6750938e798d4e0043687248"
      )?.activado,
    permisoDarBajaConsignacionInventario:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "675093ca798d4e0043687249"
      )?.activado,
    permisoAgregarMatriz:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        valueTodos => valueTodos.permisoId.toString() === "6750960a798d4e0043687252")?.activado,

  };
};

export default connect(mapStateToProps, { logout, deseleccionarEmpresa, contarVentasPPendientes })(
  Panel
);
