import React, { Component } from "react";
import { message, Spin } from "antd";
import { ButtonItem, ErrorItem, Form, FormItem, SwitchItem, TextAreaItem } from "../../../../Components/Items";
import axios from "axios";
import { connect } from "react-redux";


class CorreoConfiguracion extends Component {
  formRef = React.createRef();
  state = {
    archivo: null,
    loading: false,
    habilitado: false
  };
  componentDidMount() {
    this.consultarConfiguracion();
  }
  onFinish(values) {
    axios
      .post(
        "empresas/editarConfiguracionCorreos",
        { ...values, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Se ha modificado la configuracion de la empresa");
          this.consultarConfiguracion();
        } else if (res.data.success === 1) {
          message.error("No se pudo modificar la configuracion");
        } else {
          message.error("Hubo un error y no se modifico la configuracion");
        }
      });
  }

  async consultarConfiguracion() {
    this.setState({ loading: true })
    await axios
      .post(
        "empresas/selectConfiguracionCorreos",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
          message.error("Error no se encontro configuracion de esta empresa");
        } else {
          this.formRef.current.setFieldsValue({
            ...res.data
          });
          this.setState({ habilitado: res.data.correo })
        }
      });
    this.setState({ loading: false })
  }

  onFinishFailed(e) {
    ErrorItem(e);
  }

  onValuesChange = (changedValues) => {
    if ("correo" in changedValues) {
      this.setState({ habilitado: changedValues.correo });
    }
  };

  render() {
    return (
      <>
        <Spin spinning={this.state.loading}>
          <Form
            name="correosConfiguracion"
            layout
            onFinish={this.onFinish.bind(this)}
            onFinishFailed={this.onFinishFailed}
            ref={this.formRef}
            loading={this.state.loading}
            onValuesChange={this.onValuesChange}
          >

            <FormItem
              label="Correo"
              name="correoEnvio"
              pattern
              margin
              valuePropName="checked"
            >
              <SwitchItem />
            </FormItem>

            <FormItem shouldUpdate noStyle>
              {({ getFieldValue }) => {
                return getFieldValue("correoEnvio") ? (
                  <FormItem
                    label="Mensaje en Correo"
                    name="textoCorreo"
                    pattern
                    margin

                  >
                    <TextAreaItem placeholder="Mensaje" style={{ width: "50%" }} />
                  </FormItem>
                ) : null;
              }}
            </FormItem>


            <ButtonItem
              type="primary"
              htmlType="submit"
              style={{ display: "block", margin: "0 0 0 auto" }}
            >
              Guardar Configuracion
            </ButtonItem>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(CorreoConfiguracion);
