import moment from "moment";
import "moment/locale/es";
import { dineroDisabledMask } from "../Components/Items";
import numeroALetra from "../Components/numeroALetra";

moment.locale("es");

const ventaProgramadaPdf = (values) => {
    const empresa = values.respuestaFactura.empresa;
    const dataSat = values.respuestaFactura.dataSat;
    const idKiosko = values.kioskoid;

    let agregarEquipoMedico = []

    if (values.doctor || values.paciente) {
        agregarEquipoMedico.push(
            {
                style: "tableExample",
                table: {
                    widths: ["50%", "50%"],
                    layout: {
                        defaultBorder: false,
                    },

                    body: [
                        [
                            {
                                table: {
                                    widths: ["40%", "60%"],
                                    layout: {
                                        defaultBorder: false,
                                    },
                                    body: [
                                        [
                                            { text: "Nombre del doctor :", style: "subheader" },
                                            { text: values?.doctor?.nombre ? values?.doctor?.nombre : '', style: "textoChico" },
                                        ],
                                    ],
                                },
                                layout: "noBorders",
                            },

                            {
                                table: {
                                    widths: ["40%", "60%"],
                                    layout: {
                                        defaultBorder: false,
                                    },
                                    body: [
                                        [
                                            { text: "Nombre del paciente :", style: "subheader" },
                                            { text: values?.paciente?.nombre ? values?.paciente?.nombre : '', style: "textoChico" },
                                        ],
                                        [
                                            { text: "Cirugia :", style: "subheader" },
                                            { text: values?.paciente?.cirugia ? values?.paciente?.cirugia : '', style: "textoChico" },
                                        ],
                                    ],
                                },
                                layout: "noBorders",
                            },
                        ],
                    ],
                },
                layout: {
                    hLineWidth: function (i, node) {
                        return i === 0 || i === node.table.body.length ? 1 : 1;
                    },
                    vLineWidth: function (i, node) {
                        return i === 0 || i === node.table.widths.length ? 1 : 1;
                    },
                    hLineColor: function (i, node) {
                        return "#adaaaa";
                    },
                    vLineColor: function (i, node) {
                        return "#adaaaa";
                    },
                    hLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.body.length) {
                            return null;
                        }
                        return { dash: { length: 2, space: 1 } };
                    },
                    vLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.widths.length) {
                            return null;
                        }
                        return { dash: { length: 2 } };
                    },
                },
            },
        )
    }

    let concurrencia

    if (dataSat.Concurrencia === 1) {
        concurrencia = "Diario"
    }else if (dataSat.Concurrencia === 2) {
        concurrencia = "Semanal"
    }else if (dataSat.Concurrencia === 3) {
        concurrencia = "Quincenal"
    }else if (dataSat.Concurrencia === 4) {
        concurrencia = "Mensual"
    }else if (dataSat.Concurrencia === 5) {
        concurrencia = "Anual"
    }


    let cartaPorte


    if (dataSat?.Complemento?.Any.find(item => item['cartaporte31:CartaPorte'])) {
        cartaPorte = dataSat.Complemento.Any.find(item => item['cartaporte31:CartaPorte'])['cartaporte31:CartaPorte']
    }

    if (dataSat?.Complemento?.Any.find(item => item['cartaporte30:CartaPorte'])) {
        cartaPorte = dataSat.Complemento.Any.find(item => item['cartaporte30:CartaPorte'])['cartaporte30:CartaPorte']
    }

    const conceptos = dataSat.Conceptos.map((valueConcepto) => {
        let iva = 0;
        let ieps = 0;
        valueConcepto.Impuestos?.Traslados?.map((valueImpuesto) => {
            if (valueImpuesto.Impuesto === "002") {
                iva += valueImpuesto.Importe;
            }
            if (valueImpuesto.Impuesto === "003") {
                ieps += valueImpuesto.Importe;
            }
            return null;
        });
        return [
            { style: "textoTablaChico", text: valueConcepto.Cantidad, alignment: "right" },
            {
                text: valueConcepto.ClaveUnidad,
                style: "textoTablaChico",
                alignment: "center",
            },
            {
                text: valueConcepto.ClaveProdServ,
                style: "textoTablaChico",
                alignment: "center",
            },
            {
                text: valueConcepto.Descripcion,
                style: "textoTablaChico",
                alignment: "left",
            },
            {
                text: dineroDisabledMask(iva),
                style: "textoTablaChico",
                alignment: "right",
            },
            {
                text: dineroDisabledMask(ieps),
                style: "textoTablaChico",
                alignment: "right",
            },
            {
                text: dineroDisabledMask(valueConcepto.ValorUnitario),
                style: "textoTablaChico",
                alignment: "right",
            },
            {
                text: dineroDisabledMask(valueConcepto.Importe),
                style: "textoTablaChico",
                alignment: "right",
            },
        ];
    });

    const nuevoArrayImpFL = Array.isArray(dataSat.Impuestos.Traslados) ?
        [...dataSat.Impuestos.Traslados, ...dataSat.Complemento.Any.find(item => item['implocal:ImpuestosLocales'])['implocal:ImpuestosLocales']["implocal:TrasladosLocales"]]
        :
        [...dataSat.Complemento.Any.find(item => item['implocal:ImpuestosLocales'])['implocal:ImpuestosLocales']["implocal:TrasladosLocales"]]

    const impuestosTotales = nuevoArrayImpFL.map(
        (valueImpuesto, indexImpuesto) => {
            let nombre = "";
            if (valueImpuesto.Impuesto === "002") {
                nombre = "IVA";
            } else if (valueImpuesto.Impuesto === "003") {
                nombre = "IEPS";
            } else {
                nombre = valueImpuesto["@ImpLocTrasladado"]
            }

            if (valueImpuesto.TipoFactor === "Tasa") {
                nombre = nombre + " " + Number(valueImpuesto.TasaOCuota * 100) + "%";
            } else if (valueImpuesto.TipoFactor === "Cuota") {
                nombre = nombre + " $" + Number(valueImpuesto.TasaOCuota);
            }

            let border = indexImpuesto + 1 === nuevoArrayImpFL.length;
            return [
                {
                    border: [true, false, false, border],
                    text: nombre,
                    style: "subheader",
                },
                {
                    border: [false, false, true, border],
                    text: dineroDisabledMask(valueImpuesto.Importe || valueImpuesto["@Importe"] || 0),
                    style: "textoChico",
                    alignment: "right",
                },
            ];
        }
    );

    const nuevoArrayRetFL = Array.isArray(dataSat.Impuestos.Retenciones) ?
        [...dataSat.Impuestos.Retenciones, ...dataSat.Complemento.Any.find(item => item['implocal:ImpuestosLocales'])['implocal:ImpuestosLocales']["implocal:RetencionesLocales"]]
        :
        [...dataSat.Complemento.Any.find(item => item['implocal:ImpuestosLocales'])['implocal:ImpuestosLocales']["implocal:RetencionesLocales"]]

    const retencionesTotales = nuevoArrayRetFL.map(
        (valueRetencion, indexRetencion) => {
            let nombre = "Retención ";
            if (valueRetencion.Impuesto === "001") {
                nombre = nombre + "ISR";
            } else if (valueRetencion.Impuesto === "002") {
                nombre = nombre + "IVA";
            } else {
                nombre = valueRetencion["@ImpLocRetenido"]
            }

            if (valueRetencion.TipoFactor === "Tasa") {
                nombre = nombre + " " + Number(valueRetencion.TasaOCuota * 100) + "%";
            } else if (valueRetencion.TipoFactor === "Cuota") {
                nombre = nombre + " " + Number(valueRetencion.TasaOCuota);
            }

            let border = indexRetencion + 1 === nuevoArrayRetFL.length;
            return [
                {
                    border: [true, false, false, border],
                    text: nombre,
                    style: "subheader",
                },
                {
                    border: [false, false, true, border],
                    text: dineroDisabledMask(valueRetencion.Importe || valueRetencion["@Importe"]),
                    style: "textoChico",
                    alignment: "right",
                },
            ];
        }
    )

    const totalImpFL = dataSat.Impuestos.TotalImpuestosTrasladados + dataSat.Complemento.Any.find(item => item['implocal:ImpuestosLocales'])['implocal:ImpuestosLocales']["@TotaldeTraslados"] || 0
    const totalRetFL = dataSat.Impuestos.TotalImpuestosRetenidos + dataSat.Complemento.Any.find(item => item['implocal:ImpuestosLocales'])['implocal:ImpuestosLocales']["@TotaldeRetenciones"] || 0

    let observaciones = {}
    if (values.imprimirObservaciones && values.observaciones) {
        observaciones = {
            table: {
                body: [
                    [
                        {
                            border: [true, true, true, false],
                            text: 'Observaciones:',
                            margin: [0, 0, 0, 5],
                            style: 'header',
                        },
                    ],
                    [
                        {
                            border: [true, false, true, true],
                            text: values.observaciones,
                            margin: [0, 0, 0, 5],
                            style: 'textoChico'
                        }
                    ]

                ],
                layout: {
                    defaultBorder: false,
                    hLineWidth: function (i, node) {
                        return i === 0 || i === node.table.body.length ? 1 : 1;
                    },
                    vLineWidth: function (i, node) {
                        return i === 0 || i === node.table.widths.length ? 1 : 1;
                    },
                    hLineColor: function (i, node) {
                        return "#adaaaa";
                    },
                    vLineColor: function (i, node) {
                        return "#adaaaa";
                    },
                    hLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.body.length) {
                            return null;
                        }
                        return { dash: { length: 2, space: 1 } };
                    },
                    vLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.widths.length) {
                            return null;
                        }
                        return { dash: { length: 2 } };
                    },
                },
            }
        }
    }



    //Datos carta porte

    let ubicaciones = []

    if (cartaPorte?.Ubicaciones) {
        for (const ubicacion of cartaPorte.Ubicaciones) {
            ubicaciones.push(
                [
                    {
                        style: 'textoTablaChico',
                        text: ubicacion.TipoUbicacion,
                        alignment: 'center'
                    },
                    {
                        style: 'textoTablaChico',
                        text: ubicacion.RFCRemitenteDestinatario,
                        alignment: 'center'
                    },
                    {
                        style: 'textoTablaChico',
                        text: ubicacion.NombreRemitenteDestinatario,
                        alignment: 'lefth'
                    },
                    {
                        text: 'MXN',
                        style: 'textoTablaChico',
                        alignment: 'center'
                    },
                    { text: '-', style: 'textoTablaChico', alignment: 'center' },
                    { text: ubicacion.FechaHoraSalidaLlegada, style: 'textoTablaChico', alignment: 'center' },
                ],
                [
                    { style: 'textoTablaChico', text: 'Domicilio:', alignment: 'lefth' },
                    { style: 'textoTablaChico', text: (ubicacion.Domicilio.Calle ? ubicacion.Domicilio.Calle : '') + (ubicacion.Domicilio.NumeroExterior ? (' #' + ubicacion.Domicilio.NumeroExterior) : '') + (ubicacion.Domicilio.NumeroInterior ? (' - ' + ubicacion.Domicilio.NumeroInterior) : '') + ' Col: ' + ubicacion.Domicilio.ColoniaNombre + ' CP: ' + ubicacion.Domicilio.CodigoPostal + (ubicacion.Domicilio.Referencia ? (' Referencia: ' + ubicacion.Domicilio.Referencia) : '') + ' - ' + (ubicacion.Domicilio.MunicipioNombre ? ubicacion.Domicilio.MunicipioNombre : '') + ' ' + ubicacion.Domicilio.Estado + ' ' + ubicacion.Domicilio.Pais, alignment: 'lefth', colSpan: 5 },
                    {},
                    {},
                    {},
                    {},
                ]
            )
        }
    }


    let figuras = []

    if (cartaPorte?.FiguraTransporte) {
        for (const figura of cartaPorte.FiguraTransporte) {
            let tipoFiguraTexto

            if (figura.TipoFigura === '01') {
                tipoFiguraTexto = "Operador"
            } else if (figura.TipoFigura === '02') {
                tipoFiguraTexto = "Propietario"
            } else if (figura.TipoFigura === '03') {
                tipoFiguraTexto = "Arrendador"
            } else if (figura.TipoFigura === '04') {
                tipoFiguraTexto = "Notificado"
            }

            figuras.push(
                [
                    {
                        style: 'textoTablaChico',
                        text: figura.TipoFigura + ' - ' + tipoFiguraTexto,
                        alignment: 'center'
                    },
                    {
                        style: 'textoTablaChico',
                        text: figura.RFCFigura,
                        alignment: 'center'
                    },
                    {
                        style: 'textoTablaChico',
                        text: figura.NombreFigura,
                        alignment: 'lefth'
                    },
                    {
                        text: figura.NumLicencia,
                        style: 'textoTablaChico',
                        alignment: 'center'
                    },
                    { text: figura.ResidenciaFiscalFigura ? figura.ResidenciaFiscalFigura : '-', style: 'textoTablaChico', alignment: 'center' },
                    { text: figura.NumRegIdTribFigura ? figura.NumRegIdTribFigura : '-', style: 'textoTablaChico', alignment: 'center' },
                ],
                [
                    { style: 'textoTablaChico', text: 'Domicilio:', alignment: 'lefth' },
                    { style: 'textoTablaChico', text: (figura.Domicilio.Calle ? figura.Domicilio.Calle : '') + (figura.Domicilio.NumeroExterior ? (' #' + figura.Domicilio.NumeroExterior) : '') + (figura.Domicilio.NumeroInterior ? (' - ' + figura.Domicilio.NumeroInterior) : '') + ' Col: ' + figura.Domicilio.ColoniaNombre + ' CP: ' + figura.Domicilio.CodigoPostal + (figura.Domicilio.Referencia ? (' Referencia: ' + figura.Domicilio.Referencia) : '') + ' - ' + (figura.Domicilio.MunicipioNombre ? figura.Domicilio.MunicipioNombre : '') + ' ' + figura.Domicilio.Estado + ' ' + figura.Domicilio.Pais, alignment: 'lefth', colSpan: 5 },
                    {},
                    {},
                    {},
                    {},
                ]
            )
        }
    }


    let mercancias = []

    if (cartaPorte?.Mercancias?.Mercancia) {


        for (const mercancia of cartaPorte.Mercancias.Mercancia) {

            let agregarFinalMercancias = []
            let cantidadTotal = 0

            if (mercancia.CantidadTransporta) {
                for (const cantidad of mercancia.CantidadTransporta) {

                    cantidadTotal += cantidad.Cantidad

                    const ubicacionOrigen = cartaPorte?.Ubicaciones.find(valueUbi => valueUbi.IDUbicacion === cantidad.IDOrigen)
                    const ubicacionDestino = cartaPorte?.Ubicaciones.find(valueUbi => valueUbi.IDUbicacion === cantidad.IDDestino)


                    agregarFinalMercancias.push(
                        [
                            { style: 'textoTablaChico', text: cantidad.Cantidad, alignment: 'right' },
                            { style: 'textoTablaChico', text: 'Origen: ' + ubicacionOrigen.IDUbicacion + ' - ' + (ubicacionOrigen.Domicilio.Calle ? ubicacionOrigen.Domicilio.Calle : '') + (ubicacionOrigen.Domicilio.NumeroExterior ? (' #' + ubicacionOrigen.Domicilio.NumeroExterior) : '') + (ubicacionOrigen.Domicilio.NumeroInterior ? (' - ' + ubicacionOrigen.Domicilio.NumeroInterior) : '') + ' Col: ' + ubicacionOrigen.Domicilio.ColoniaNombre + ' CP: ' + ubicacionOrigen.Domicilio.CodigoPostal + (ubicacionOrigen.Domicilio.Referencia ? (' Referencia: ' + ubicacionOrigen.Domicilio.Referencia) : '') + ' - ' + (ubicacionOrigen.Domicilio.MunicipioNombre ? ubicacionOrigen.Domicilio.MunicipioNombre : '') + ' ' + ubicacionOrigen.Domicilio.Estado + ' ' + ubicacionOrigen.Domicilio.Pais, alignment: 'lefth', colSpan: 3 },
                            {},
                            {},
                            { style: 'textoTablaChico', text: 'Destino: ' + ubicacionDestino.IDUbicacion + ' - ' + (ubicacionDestino.Domicilio.Calle ? ubicacionDestino.Domicilio.Calle : '') + (ubicacionDestino.Domicilio.NumeroExterior ? (' #' + ubicacionDestino.Domicilio.NumeroExterior) : '') + (ubicacionDestino.Domicilio.NumeroInterior ? (' - ' + ubicacionDestino.Domicilio.NumeroInterior) : '') + ' Col: ' + ubicacionDestino.Domicilio.ColoniaNombre + ' CP: ' + ubicacionDestino.Domicilio.CodigoPostal + (ubicacionDestino.Domicilio.Referencia ? (' Referencia: ' + ubicacionDestino.Domicilio.Referencia) : '') + ' - ' + (ubicacionDestino.Domicilio.MunicipioNombre ? ubicacionDestino.Domicilio.MunicipioNombre : '') + ' ' + ubicacionDestino.Domicilio.Estado + ' ' + ubicacionDestino.Domicilio.Pais, alignment: 'lefth', colSpan: 4 },
                            {},
                            {},
                            {},
                        ]
                    )

                }
            }

            mercancias.push(
                [
                    { style: 'textoTablaChico', text: cantidadTotal, alignment: 'right' },
                    {
                        text: mercancia.Unidad,
                        style: 'textoTablaChico',
                        alignment: 'center'
                    },
                    {
                        text: mercancia.BienesTransp + ' - ' + mercancia.Descripcion,
                        style: 'textoTablaChico',
                        alignment: 'center'
                    },
                    {
                        text: mercancia.CveMaterialPeligroso ? mercancia.CveMaterialPeligroso : 'No',
                        style: 'textoTablaChico',
                        alignment: 'left'
                    },
                    {
                        text: mercancia.Embalaje ? (mercancia.Embalaje + ' - ' + mercancia.DescripEmbalaje) : '-',
                        style: 'textoTablaChico',
                        alignment: 'right'
                    },
                    {
                        text: mercancia.PesoEnKg,
                        style: 'textoTablaChico',
                        alignment: 'right'
                    },
                    {
                        text: mercancia.FraccionArancelaria,
                        style: 'textoTablaChico',
                        alignment: 'right'
                    },
                    {
                        text: mercancia.PermisoImportacion ? mercancia.PermisoImportacion : '-',
                        style: 'textoTablaChico',
                        alignment: 'right'
                    }
                ],
            )

            if (mercancia.GuiasIdentificacion) {
                for (const guiaIdent of mercancia.GuiasIdentificacion) {
                    mercancia.push(
                        [
                            { text: 'No. de Guia', style: 'tituloTabla', colSpan: 2 },
                            {},
                            { text: guiaIdent.NumeroGuiaIdentificacion, style: 'textoTablaChico', alignment: 'right' },
                            { text: 'Descripción Guía', style: 'tituloTabla' },
                            { text: guiaIdent.DescripGuiaIdentificacion, style: 'textoTablaChico', alignment: 'right', colSpan: 2 },
                            {},
                            { text: 'Peso Guía', style: 'tituloTabla' },
                            { text: guiaIdent.PesoGuiaIdentificacion, style: 'textoTablaChico', alignment: 'right' }
                        ]
                    )
                }
            }

            for (const agregarFinalMercancia of agregarFinalMercancias) {
                mercancias.push(agregarFinalMercancia)
            }

        }


    }

    let remolques = []

    if (cartaPorte?.Mercancias?.Autotransporte?.Remolques) {

        let dataRemolques = cartaPorte?.Mercancias?.Autotransporte?.Remolques.map(valueR => [
            {
                text: valueR.SubTipoRem + ' - ' + valueR.DescripcionSubtipo,
                style: 'textoTablaChico',
                alignment: 'center'
            },
            {
                text: valueR.Placa,
                style: 'textoTablaChico',
                alignment: 'center'
            },
        ])



        remolques.push(
            { text: 'Remolques', style: 'tableHeader' },
            {
                style: 'tableExample',
                table: {
                    widths: ['50%', '50%'],
                    layout: { defaultBorder: true },
                    body: [
                        [
                            { text: 'Subtipo Remolque', style: 'tituloTabla' },
                            { text: 'Placa', style: 'tituloTabla' },
                        ],
                        ...dataRemolques,
                    ]
                },
                layout: {
                    fillColor: (rowIndex, node, columnIndex) => {
                        return rowIndex % 2 === 0 ? "#f5f5f5" : "#ffffff";
                    },
                    hLineWidth: function (i, node) {
                        return i === 0 || i === node.table.body.length ? 1 : 1;
                    },
                    vLineWidth: function (i, node) {
                        return i === 0 || i === node.table.widths.length ? 1 : 1;
                    },
                    hLineColor: function (i, node) {
                        return "#adaaaa";
                    },
                    vLineColor: function (i, node) {
                        return "#adaaaa";
                    },
                    hLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.body.length) {
                            return null;
                        }
                        return { dash: { length: 2, space: 1 } };
                    },
                    vLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.widths.length) {
                            return null;
                        }
                        return { dash: { length: 2 } };
                    },
                }
            },
        )
    }


    let agregarCartaPorte = []

    if (cartaPorte) {
        agregarCartaPorte.push(
            { text: 'Detalle Complemento Carta Porte', style: 'tableHeader' },
            {
                style: 'tableExample',
                table: {
                    widths: ['20%', '20%', '20%', '20%', '20%'],
                    layout: { defaultBorder: true },
                    body: [
                        [
                            { text: 'Transporte Internacional', style: 'tituloTabla' },
                            { text: 'Tipo de transporte', style: 'tituloTabla' },
                            { text: 'Via de Transporte', style: 'tituloTabla' },
                            { text: 'País de Orígen o Destino', style: 'tituloTabla' },
                            { text: 'Total distancia recorrida', style: 'tituloTabla' },
                        ],
                        [
                            {
                                style: 'textoTablaChico',
                                text: cartaPorte["TranspInternac"],
                                alignment: 'center'
                            },
                            {
                                text: '-',
                                style: 'textoTablaChico',
                                alignment: 'center'
                            },
                            { text: '-', style: 'textoTablaChico', alignment: 'center' },
                            { text: 'MXN', style: 'textoTablaChico', alignment: 'center' },
                            {
                                text: cartaPorte["TotalDistRec"],
                                style: 'textoTablaChico',
                                alignment: 'right'
                            },
                        ]
                    ]
                },
                layout: {
                    fillColor: (rowIndex, node, columnIndex) => {
                        return rowIndex % 2 === 0 ? "#f5f5f5" : "#ffffff";
                    },
                    hLineWidth: function (i, node) {
                        return i === 0 || i === node.table.body.length ? 1 : 1;
                    },
                    vLineWidth: function (i, node) {
                        return i === 0 || i === node.table.widths.length ? 1 : 1;
                    },
                    hLineColor: function (i, node) {
                        return "#adaaaa";
                    },
                    vLineColor: function (i, node) {
                        return "#adaaaa";
                    },
                    hLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.body.length) {
                            return null;
                        }
                        return { dash: { length: 2, space: 1 } };
                    },
                    vLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.widths.length) {
                            return null;
                        }
                        return { dash: { length: 2 } };
                    },
                }
            },
            { text: 'Detalle de Transporte', style: 'tableHeader' },
            {
                style: 'tableExample',
                table: {
                    widths: ['30%', '10%', '40%', '10%', '10%'],
                    layout: { defaultBorder: true },
                    body: [
                        [
                            { text: 'Permiso SCT', style: 'tituloTabla' },
                            { text: 'No. de permiso SCT', style: 'tituloTabla' },
                            { text: 'Configuración Vehicular / Tipo de remolque', style: 'tituloTabla' },
                            { text: 'Placa', style: 'tituloTabla' },
                            { text: 'Año', style: 'tituloTabla' },
                        ],
                        [
                            {
                                style: 'textoTablaChico',
                                text: cartaPorte.Mercancias.Autotransporte.tipoPermiso.Clave + ' - ' + cartaPorte.Mercancias.Autotransporte.tipoPermiso.Descripcion,
                                alignment: 'center'
                            },
                            {
                                text: cartaPorte.Mercancias.Autotransporte.NumPermisoSCT,
                                style: 'textoTablaChico',
                                alignment: 'center'
                            },
                            { text: cartaPorte.Mercancias.Autotransporte.configVehicular.Clave + ' - ' + cartaPorte.Mercancias.Autotransporte.configVehicular.Descripcion, style: 'textoTablaChico', alignment: 'center' },
                            { text: cartaPorte.Mercancias.Autotransporte.IdentificacionVehicular.PlacaVM, style: 'textoTablaChico', alignment: 'center' },
                            {
                                text: cartaPorte.Mercancias.Autotransporte.IdentificacionVehicular.AnioModeloVM,
                                style: 'textoTablaChico',
                                alignment: 'center'
                            },
                        ]
                    ]
                },
                layout: {
                    fillColor: (rowIndex, node, columnIndex) => {
                        return rowIndex % 2 === 0 ? "#f5f5f5" : "#ffffff";
                    },
                    hLineWidth: function (i, node) {
                        return i === 0 || i === node.table.body.length ? 1 : 1;
                    },
                    vLineWidth: function (i, node) {
                        return i === 0 || i === node.table.widths.length ? 1 : 1;
                    },
                    hLineColor: function (i, node) {
                        return "#adaaaa";
                    },
                    vLineColor: function (i, node) {
                        return "#adaaaa";
                    },
                    hLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.body.length) {
                            return null;
                        }
                        return { dash: { length: 2, space: 1 } };
                    },
                    vLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.widths.length) {
                            return null;
                        }
                        return { dash: { length: 2 } };
                    },
                }
            },
            {
                style: 'tableExample',
                table: {
                    widths: ['15%', '15%', '15%', '15%', '15%', '15%', '10%'],
                    layout: { defaultBorder: true },
                    body: [
                        [
                            { text: 'Seguro de Responsabilidad Civil', colSpan: 2, style: 'tituloTabla' },
                            {},
                            { text: 'Seguro de Medio Ambiente', colSpan: 2, style: 'tituloTabla' },
                            {},
                            { text: 'Seguro de Carga', colSpan: 2, style: 'tituloTabla' },
                            {},
                            { text: 'Prima Seguro', rowSpan: 2, style: 'tituloTabla' },
                        ],
                        [
                            { text: 'Aseguradora', style: 'tituloTabla' },
                            { text: 'Póliza', style: 'tituloTabla' },
                            { text: 'Aseguradora', style: 'tituloTabla' },
                            { text: 'Póliza', style: 'tituloTabla' },
                            { text: 'Aseguradora', style: 'tituloTabla' },
                            { text: 'Póliza', style: 'tituloTabla' },
                            {},
                        ],
                        [
                            {
                                style: 'textoTablaChico',
                                text: cartaPorte.Mercancias.Autotransporte.Seguros.AseguraRespCivil,
                                alignment: 'center'
                            },
                            {
                                style: 'textoTablaChico',
                                text: cartaPorte.Mercancias.Autotransporte.Seguros.PolizaRespCivil,
                                alignment: 'center'
                            },
                            {
                                style: 'textoTablaChico',
                                text: cartaPorte.Mercancias.Autotransporte.Seguros.AseguraMedAmbiente,
                                alignment: 'center'
                            },
                            {
                                text: cartaPorte.Mercancias.Autotransporte.Seguros.PolizaMedAmbiente,
                                style: 'textoTablaChico',
                                alignment: 'center'
                            },
                            { text: cartaPorte.Mercancias.Autotransporte.Seguros.AseguraCarga, style: 'textoTablaChico', alignment: 'center' },
                            { text: cartaPorte.Mercancias.Autotransporte.Seguros.PolizaCarga, style: 'textoTablaChico', alignment: 'center' },
                            {
                                text: cartaPorte.Mercancias.Autotransporte.Seguros.PrimaSeguro,
                                style: 'textoTablaChico',
                                alignment: 'center'
                            },
                        ]
                    ]
                },
                layout: {
                    fillColor: (rowIndex, node, columnIndex) => {
                        return rowIndex % 2 === 0 ? "#f5f5f5" : "#ffffff";
                    },
                    hLineWidth: function (i, node) {
                        return i === 0 || i === node.table.body.length ? 1 : 1;
                    },
                    vLineWidth: function (i, node) {
                        return i === 0 || i === node.table.widths.length ? 1 : 1;
                    },
                    hLineColor: function (i, node) {
                        return "#adaaaa";
                    },
                    vLineColor: function (i, node) {
                        return "#adaaaa";
                    },
                    hLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.body.length) {
                            return null;
                        }
                        return { dash: { length: 2, space: 1 } };
                    },
                    vLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.widths.length) {
                            return null;
                        }
                        return { dash: { length: 2 } };
                    },
                }
            },
            ...remolques,
            { text: 'Ubicaciones', style: 'tableHeader' },
            {
                style: 'tableExample',
                table: {
                    widths: ['10%', '10%', '30%', '15%', '15%', '20%'],
                    layout: { defaultBorder: true },
                    body: [
                        [
                            { text: 'Descripción', style: 'tituloTabla' },
                            { text: 'RFC', style: 'tituloTabla' },
                            { text: 'Nombre', style: 'tituloTabla' },
                            { text: 'Residencia Fiscal', style: 'tituloTabla' },
                            { text: 'Identidad Tributaria', style: 'tituloTabla' },
                            { text: 'Fecha de Salida / LLegada', style: 'tituloTabla' },
                        ],
                        ...ubicaciones
                    ]
                },
                layout: {
                    fillColor: (rowIndex, node, columnIndex) => {
                        return rowIndex % 2 === 0 ? "#f5f5f5" : "#ffffff";
                    },
                    hLineWidth: function (i, node) {
                        return i === 0 || i === node.table.body.length ? 1 : 1;
                    },
                    vLineWidth: function (i, node) {
                        return i === 0 || i === node.table.widths.length ? 1 : 1;
                    },
                    hLineColor: function (i, node) {
                        return "#adaaaa";
                    },
                    vLineColor: function (i, node) {
                        return "#adaaaa";
                    },
                    hLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.body.length) {
                            return null;
                        }
                        return { dash: { length: 2, space: 1 } };
                    },
                    vLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.widths.length) {
                            return null;
                        }
                        return { dash: { length: 2 } };
                    },
                }
            },
            { text: 'Figuras de Transporte', style: 'tableHeader' },
            {
                style: 'tableExample',
                table: {
                    widths: ['10%', '10%', '30%', '15%', '15%', '20%'],
                    layout: { defaultBorder: true },
                    body: [
                        [
                            { text: 'Descripción', style: 'tituloTabla' },
                            { text: 'RFC', style: 'tituloTabla' },
                            { text: 'Nombre', style: 'tituloTabla' },
                            { text: 'Licencia', style: 'tituloTabla' },
                            { text: 'Residencia Fiscal', style: 'tituloTabla' },
                            { text: 'Identidad Tributaria', style: 'tituloTabla' },

                        ],
                        ...figuras,

                    ]
                },
                layout: {
                    fillColor: (rowIndex, node, columnIndex) => {
                        return rowIndex % 2 === 0 ? "#f5f5f5" : "#ffffff";
                    },
                    hLineWidth: function (i, node) {
                        return i === 0 || i === node.table.body.length ? 1 : 1;
                    },
                    vLineWidth: function (i, node) {
                        return i === 0 || i === node.table.widths.length ? 1 : 1;
                    },
                    hLineColor: function (i, node) {
                        return "#adaaaa";
                    },
                    vLineColor: function (i, node) {
                        return "#adaaaa";
                    },
                    hLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.body.length) {
                            return null;
                        }
                        return { dash: { length: 2, space: 1 } };
                    },
                    vLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.widths.length) {
                            return null;
                        }
                        return { dash: { length: 2 } };
                    },
                }
            },
            { text: 'Detalle Mercancias', style: 'tableHeader' },
            {
                style: 'tableExample',
                table: {
                    widths: ['7%', '8%', '30%', '10%', '10%', '10%', '10%', '15%'],
                    layout: { defaultBorder: true },
                    body: [
                        [
                            { text: 'Cantidad', style: 'tituloTabla' },
                            { text: 'Unidad', style: 'tituloTabla' },
                            { text: 'Clave / Descripcion', style: 'tituloTabla' },
                            { text: 'Tipo Material peligroso', style: 'tituloTabla' },
                            { text: 'Embalaje', style: 'tituloTabla' },
                            { text: 'Peso en Kg', style: 'tituloTabla' },
                            { text: 'Fraccion Arancelaria', style: 'tituloTabla' },
                            { text: 'Pedimento', style: 'tituloTabla' }
                        ],
                        ...mercancias
                    ]
                },
                layout: {
                    fillColor: (rowIndex, node, columnIndex) => {
                        return rowIndex % 2 === 0 ? "#f5f5f5" : "#ffffff";
                    },
                    hLineWidth: function (i, node) {
                        return i === 0 || i === node.table.body.length ? 1 : 1;
                    },
                    vLineWidth: function (i, node) {
                        return i === 0 || i === node.table.widths.length ? 1 : 1;
                    },
                    hLineColor: function (i, node) {
                        return "#adaaaa";
                    },
                    vLineColor: function (i, node) {
                        return "#adaaaa";
                    },
                    hLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.body.length) {
                            return null;
                        }
                        return { dash: { length: 2, space: 1 } };
                    },
                    vLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.widths.length) {
                            return null;
                        }
                        return { dash: { length: 2 } };
                    },
                }
            },
        )
    }

    const arraySiHayRetenciones = totalRetFL > 0 ? [[
        {
            border: [true, false, false, false],
            text: "Retenciones:",
            style: "subheader",
        },
        {
            border: [false, false, true, false],
            text: dineroDisabledMask(
                totalRetFL
            ),
            style: "textoChico",
            alignment: "right",
        },
    ],
    [
        {
            border: [true, false, false, true],
            text: "TOTAL PAGO:",
            style: "header",
            fillColor: "#e8e8e8",
        },
        {
            border: [false, false, true, true],
            text: dineroDisabledMask(dataSat.Total),
            style: "header",
            fillColor: "#e8e8e8",
            alignment: "right",
        },
    ]] : []

    const total = dataSat.SubTotal - dataSat.Descuento + totalImpFL

    var dd = {
        watermark: { text: 'SIN VALIDEZ', color: 'GRAY', opacity: 0.3, bold: true, italics: false, angle: -50, fontSize: 110 },
        content: [
            {
                style: "tableExample",
                table: {
                    widths: ["18%", "52%", "30%"],
                    layout: {
                        defaultBorder: false,
                    },
                    body: [
                        [
                            {
                                border: [false, false, false, false],
                                image: "logo",
                                fit: [80, 80]
                            },
                            {
                                border: [false, false, false, false],
                                table: {
                                    headerRows: 1,
                                    widths: ["100%"],

                                    body: [
                                        [
                                            {
                                                text: empresa.persona
                                                    ? empresa.nombre
                                                    : empresa.nombre +
                                                    " " +
                                                    empresa.apellidoPaterno +
                                                    " " +
                                                    empresa.apellidoMaterno,
                                                style: "tableHeader",
                                            },
                                        ],
                                        [
                                            {
                                                text: "R.F.C: " + empresa.rfc,
                                                style: "textoChico",
                                                alignment: "center",
                                            },
                                        ],
                                        [
                                            {
                                                text:
                                                    empresa.calle +
                                                    " " +
                                                    empresa.numeroCalle +
                                                    " " +
                                                    (empresa.numeroInterior
                                                        ? empresa.numeroInterior
                                                        : ""),
                                                style: "textoChico",
                                                alignment: "center",
                                            },
                                        ],
                                        [
                                            {
                                                text:
                                                    empresa.colonia.tipoAsentamiento +
                                                    " " +
                                                    empresa.colonia.nombre +
                                                    " C.P. " +
                                                    empresa.colonia.cp +
                                                    " " +
                                                    empresa.municipio.nombre +
                                                    " " +
                                                    empresa.estado.abreviatura +
                                                    " " +
                                                    empresa.pais.abreviatura +
                                                    " ",
                                                style: "textoChico",
                                                alignment: "center",
                                            },
                                        ],
                                        // empresa.telefono ? [
                                        //     {
                                        //         text: 'Tel: '+phoneMask(empresa.telefono),
                                        //         style: "textoChico",
                                        //         alignment: "center",
                                        //       },
                                        // ] : [],
                                        [
                                            {
                                                text:
                                                    "Regimen Fiscal: (" +
                                                    empresa.regimenFiscal.c_RegimenFiscal +
                                                    ") " +
                                                    empresa.regimenFiscal.descripcion,
                                                style: "textoChico",
                                                alignment: "center",
                                            },
                                        ],
                                        [
                                            {
                                                text: "Tipo De Comprobante: (I) Ingreso",
                                                style: "textoChico",
                                                alignment: "center",
                                            },
                                        ],
                                    ],
                                },
                                layout: "noBorders",
                            },
                            {
                                border: [false, false, false, false],
                                table: {
                                    headerRows: 1,
                                    widths: ["100%"],

                                    body: [
                                        [
                                            {
                                                text: "N° Venta P.:",
                                                style: "subheader",
                                                alignment: "center",
                                            },
                                        ],
                                        [
                                            {
                                                text: (dataSat.Serie ? dataSat.Serie : 'VP') + ' - ' + dataSat.Folio,
                                                style: "textoChico",
                                                alignment: "center",
                                            },
                                        ],
                                        [
                                            {
                                                text: "Moneda:",
                                                style: "subheader",
                                                alignment: "center",
                                            },
                                        ],
                                        [
                                            {
                                                text: dataSat.Moneda,
                                                style: "textoChico",
                                                alignment: "center",
                                            },
                                        ],
                                        [
                                            {
                                                text: "Tipo de Cambio:",
                                                style: "subheader",
                                                alignment: "center",
                                            },
                                        ],
                                        [
                                            {
                                                text: dineroDisabledMask(dataSat.TipoCambio),
                                                style: "textoChico",
                                                alignment: "center",
                                            },
                                        ],
                                    ],
                                },
                                layout: "noBorders",
                            },
                        ],
                    ],
                },
            },
            { text: 'Venta Programada N° ' + (dataSat.Serie ? dataSat.Serie : 'R') + ' - ' + dataSat.Folio, style: 'tableHeaderIzquierda' },
            {
                style: "tableExample",
                table: {
                    widths: ["60%", "40%"],
                    layout: {
                        defaultBorder: false,
                    },

                    body: [
                        [
                            {
                                table: {
                                    widths: ["30%", "70%"],
                                    layout: {
                                        defaultBorder: false,
                                    },
                                    body: [
                                        [
                                            { text: "Nombre del cliente :", style: "subheader" },
                                            { text: dataSat.Receptor.Nombre, style: "textoChico" },
                                        ],
                                        [
                                            { text: "Fecha:", style: "subheader" },
                                            {
                                                text: moment(dataSat.Fecha).locale("es").format("LL"),
                                                style: "textoChico",
                                            },
                                        ],
                                        [
                                            { text: "Lugar de expedicion:", style: "subheader" },
                                            { text: dataSat.LugarExpedicion, style: "textoChico" },
                                        ],
                                        [
                                            { text: "Forma de pago :", style: "subheader" },
                                            {
                                                text:
                                                    "(" +
                                                    dataSat.formaDePago.c_FormaPago +
                                                    ") " +
                                                    dataSat.formaDePago.descripcion, style: "textoChico"
                                            },
                                        ],
                                        [
                                            { text: "Metodo de pago :", style: "subheader" },
                                            {
                                                text:
                                                    dataSat.MetodoPago === "PUE"
                                                        ? "PUE - Pago en una sola exhibición"
                                                        : "PPD - Pago en parcialidades o diferido",
                                                style: "textoChico"
                                            },
                                        ],
                                    ],
                                },
                                layout: "noBorders",
                            },

                            {
                                table: {
                                    widths: ["40%", "60%"],
                                    layout: {
                                        defaultBorder: false,
                                    },
                                    body: [
                                        [
                                            { text: "Pedido:", style: "subheader" },
                                            { text: dataSat.pedido, style: "textoChico" },
                                        ],
                                        [
                                            { text: "Codiciones de pago:", style: "subheader" },
                                            {
                                                text:
                                                    dataSat.condicion === 1
                                                        ? "Contado"
                                                        : dataSat.condicion === 2
                                                            ? "Credito"
                                                            : "Credito",
                                                style: "textoChico",
                                            },
                                        ],
                                        [
                                            { text: "Concurrencia:", style: "subheader" },
                                            { text: concurrencia, style: "textoChico" },
                                        ],
                                        [
                                            { text: "Fecha de inicio:", style: "subheader" },
                                            { text: moment(dataSat.FechaInicio).locale("es").format("LL"), style: "textoChico" },
                                        ],
                                        [
                                            { text: "Fecha de fin:", style: "subheader" },
                                            { text: dataSat.FechaFin ? moment(dataSat.FechaFin).locale("es").format("LL") : " - ", style: "textoChico" },
                                        ],
                                        [
                                            { text: "Correo Automatico:", style: "subheader" },
                                            { text: dataSat.CorreoAutomatico ? 'Si' : 'No', style: "textoChico" },
                                        ]
                                    ],
                                },
                                layout: "noBorders",
                            },
                        ],
                    ],
                },
                layout: {
                    hLineWidth: function (i, node) {
                        return i === 0 || i === node.table.body.length ? 1 : 1;
                    },
                    vLineWidth: function (i, node) {
                        return i === 0 || i === node.table.widths.length ? 1 : 1;
                    },
                    hLineColor: function (i, node) {
                        return "#adaaaa";
                    },
                    vLineColor: function (i, node) {
                        return "#adaaaa";
                    },
                    hLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.body.length) {
                            return null;
                        }
                        return { dash: { length: 2, space: 1 } };
                    },
                    vLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.widths.length) {
                            return null;
                        }
                        return { dash: { length: 2 } };
                    },
                },
            },
            ...agregarEquipoMedico,
            {
                style: "tableExample",
                table: {
                    widths: ["8%", "6%", "9%", "38%", "8%", "8%", "10.5%", "12.5%"],
                    layout: {
                        defaultBorder: true,
                    },
                    body: [
                        [
                            { text: "Cantidad", style: "tituloTabla" },
                            { text: "Unidad", style: "tituloTabla" },
                            { text: "Clave SAT", style: "tituloTabla" },
                            { text: "Descripción", style: "tituloTabla" },
                            { text: "IVA", style: "tituloTabla" },
                            { text: "IEPS", style: "tituloTabla" },
                            { text: "P/U", style: "tituloTabla" },
                            { text: "Importe", style: "tituloTabla" },
                        ],
                        ...conceptos,
                    ],
                },
                layout: {
                    fillColor: (rowIndex, node, columnIndex) => {
                        return rowIndex % 2 === 0 ? "#f5f5f5" : "#ffffff";
                    },
                    hLineWidth: function (i, node) {
                        return i === 0 || i === node.table.body.length ? 1 : 1;
                    },
                    vLineWidth: function (i, node) {
                        return i === 0 || i === node.table.widths.length ? 1 : 1;
                    },
                    hLineColor: function (i, node) {
                        return "#adaaaa";
                    },
                    vLineColor: function (i, node) {
                        return "#adaaaa";
                    },
                    hLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.body.length) {
                            return null;
                        }
                        return { dash: { length: 2, space: 1 } };
                    },
                    vLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.widths.length) {
                            return null;
                        }
                        return { dash: { length: 2 } };
                    },
                },
            },
            {
                style: "tablaSinMargen",
                table: {
                    widths: ["70%", "30%"],
                    layout: {
                        defaultBorder: true,
                    },
                    body: [
                        [
                            {
                                table: {
                                    widths: ["30%", "70%"],
                                    layout: {
                                        defaultBorder: false,
                                    },
                                    body: [
                                        [
                                            {
                                                border: [true, true, false, false],
                                                text: "Cantidad con letra:",
                                                style: "subheader",
                                            },
                                            {
                                                border: [false, true, true, false],
                                                text: "**( " + numeroALetra(dataSat.Total, dataSat.Moneda) + " )**",
                                                style: "textoChico",
                                                fontSize: 7,
                                            },
                                        ],
                                        [
                                            {
                                                border: [true, true, false, false],
                                                text: "Banco:",
                                                style: "subheader",
                                            },
                                            {
                                                border: [false, true, true, false],
                                                text: empresa.banco ? empresa.banco : "",
                                                style: "textoChico",
                                            },
                                        ],
                                        [
                                            {
                                                border: [true, false, false, false],
                                                text: "Cuenta:",
                                                style: "subheader",
                                            },
                                            {
                                                border: [false, false, true, false],
                                                text: empresa.cuentaBanco ? empresa.cuentaBanco : "",
                                                style: "textoChico",
                                            },
                                        ],
                                        [
                                            {
                                                border: [true, false, false, true],
                                                text: "Cuenta CLABE:",
                                                style: "subheader",
                                            },
                                            {
                                                border: [false, false, true, true],
                                                text: empresa.cuentaClabe ? empresa.cuentaClabe : "",
                                                style: "textoChico",
                                            },
                                        ],
                                    ],
                                },
                                layout: {
                                    hLineWidth: function (i, node) {
                                        return i === 0 || i === node.table.body.length ? 1 : 1;
                                    },
                                    vLineWidth: function (i, node) {
                                        return i === 0 || i === node.table.widths.length ? 1 : 1;
                                    },
                                    hLineColor: function (i, node) {
                                        return "#adaaaa";
                                    },
                                    vLineColor: function (i, node) {
                                        return "#adaaaa";
                                    },
                                    hLineStyle: function (i, node) {
                                        if (i === 0 || i === node.table.body.length) {
                                            return null;
                                        }
                                        return { dash: { length: 2, space: 1 } };
                                    },
                                    vLineStyle: function (i, node) {
                                        if (i === 0 || i === node.table.widths.length) {
                                            return null;
                                        }
                                        return { dash: { length: 2 } };
                                    },
                                },
                            },
                            {
                                table: {
                                    widths: ["35%", "65%"],
                                    layout: {
                                        defaultBorder: false,
                                    },
                                    body: [
                                        [
                                            {
                                                border: [true, true, false, false],
                                                text: "Subtotal:",
                                                style: "subheader",
                                            },
                                            {
                                                border: [false, true, true, false],
                                                text: dineroDisabledMask(dataSat.SubTotal),
                                                style: "textoChico",
                                                alignment: "right",
                                            },
                                        ],
                                        [
                                            {
                                                border: [true, false, false, false],
                                                text: "Descuento:",
                                                style: "subheader",
                                            },
                                            {
                                                border: [false, false, true, false],
                                                text: dineroDisabledMask(dataSat.Descuento),
                                                style: "textoChico",
                                                alignment: "right",
                                            },
                                        ],
                                        [
                                            {
                                                border: [true, false, false, false],
                                                text: "Impuestos:",
                                                style: "subheader",
                                            },
                                            {
                                                border: [false, false, true, false],
                                                text: dineroDisabledMask(
                                                    totalImpFL
                                                ),
                                                style: "textoChico",
                                                alignment: "right",
                                            },
                                        ],
                                        [
                                            {
                                                border: [true, false, false, true],
                                                text: "TOTAL:",
                                                style: "header",
                                                fillColor: "#e8e8e8",
                                            },
                                            {
                                                border: [false, false, true, true],
                                                text: dineroDisabledMask(total),
                                                style: "header",
                                                fillColor: "#e8e8e8",
                                                alignment: "right",
                                            },
                                        ],
                                        ...arraySiHayRetenciones,
                                        ...impuestosTotales,
                                        ...retencionesTotales
                                    ],
                                },
                                layout: {
                                    hLineWidth: function (i, node) {
                                        return i === 0 || i === node.table.body.length ? 1 : 1;
                                    },
                                    vLineWidth: function (i, node) {
                                        return i === 0 || i === node.table.widths.length ? 1 : 1;
                                    },
                                    hLineColor: function (i, node) {
                                        return "#adaaaa";
                                    },
                                    vLineColor: function (i, node) {
                                        return "#adaaaa";
                                    },
                                    hLineStyle: function (i, node) {
                                        if (i === 0 || i === node.table.body.length) {
                                            return null;
                                        }
                                        return { dash: { length: 2, space: 1 } };
                                    },
                                    vLineStyle: function (i, node) {
                                        if (i === 0 || i === node.table.widths.length) {
                                            return null;
                                        }
                                        return { dash: { length: 2 } };
                                    },
                                },
                            },
                        ],
                    ],
                },
                layout: "noBorders",
            },
            observaciones,
            {
                stack: [
                    ...(idKiosko
                        ? [
                            {
                                qr: `https://biwo.online/kiosko?kiosko_id=${idKiosko}`,
                                fit: 115,
                                alignment: "center",
                                eccLevel: "Q",
                                margin: [0, 10, 0, 3],
                            },
                            {
                                text: idKiosko ? `Link: https://biwo.online/kiosko?kiosko_id=${idKiosko}` : "",
                                style: "text",
                                alignment: "center",
                                fontSize: 6,
                            },
                            {
                                text: idKiosko ? `Folio: ${idKiosko}` : "",
                                style: "text",
                                alignment: "center",
                                fontSize: 6,
                            },
                            {
                                text: idKiosko ? "Escane para realizar su factura." : "",
                                style: "text",
                                alignment: "center",
                            },
                        ]
                        : []),
                ],
            },
        ],
        images: {
            logo: empresa.logo.base64Completa,
        },
        styles: {
            header: {
                fontSize: 9,
                bold: true,
            },
            subheader: {
                fontSize: 8,
                bold: true,
            },
            tableExample: {
                margin: [0, 5, 0, 5],
            },
            tablaSinMargen: {
                margin: [0, 0, 0, 0],
            },
            tableHeaderIzquierda: { bold: true, fontSize: 18, color: "black" },
            tableHeader: {
                bold: true,
                fontSize: 12,
                color: "black",
                alignment: "center",
            },
            textoChico: {
                fontSize: 8,
            },
            textoChicoData: {
                fontSize: 6,
            },
            tituloTabla: {
                fontSize: 8,
                bold: true,
                fillColor: "#cccccc",
                style: "subheader",
                alignment: "center",
                border: true,
            },
            textoTabla: {
                fontSize: 8,
                italics: false,
            },
            textoTablaChico: {
                fontSize: 6,
                italics: false,
            },
        },
        pageSize: "LETTER",
    };
    return dd;
};

export default ventaProgramadaPdf;
