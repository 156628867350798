import React, { Component } from "react";
import { PageHeader } from "antd";
import { Tabs } from "antd5";
import InsumosConfiguracion from "./InsumosConfiguracion/InsumosConfiguracion";
import ContabilidadConfiguracion from "./ContabilidadConfiguracion/ContabilidadConfiguracion";
import FiscalConfiguracion from "./FiscalConfiguracion/FiscalConfiguracion";
import GeneralConfiguracion from "./GeneralConfiguracion/GeneralConfiguracion";
import AvanzadoConfiguracion from "./AvanzadoConfiguracion/AvanzadoConfiguracion";
import DescargaMasivaConfiguracion from "./DescargaMasivaConfiguracion/DescargaMasivaConfiguracion";
import {
  CloudDownloadOutlined,
  SafetyCertificateOutlined,
  BankOutlined,
  HomeFilled,
  ShopFilled,
  SettingFilled,
  TruckOutlined,
  ApiOutlined,
  MailOutlined
} from "@ant-design/icons";
import LogisticaConfiguracion from "./LogisticaConfiguracion/LogisticaConfiguracion";
import ComplementosConfiguracion from "./ComplementosConfiguracion/ComplementosConfiguracion";
import CorreoConfiguracion from "./CorreoConfiguracion/CorreoConfiguracion";
// import TimbradoConfiguracion from "./TimbradoConfiguracion/TimbradoConfiguracion";

class Configuracion extends Component {
  render() {

    const tabItems = [
      {
        label: (
          <span>
            <HomeFilled />
            General
          </span>
        ),
        key: "1",
        children: <GeneralConfiguracion/>
      },
      // {
      //   label: (
      //     <span>
      //       <EnvironmentOutlined />
      //       Direccion
      //     </span>
      //   ),
      //   key: "4",
      //   children: <DireccionConfiguracion/>
      // },
      // {
      //   label: (
      //     <span>
      //       <NumberOutlined />
      //       Numeracion
      //     </span>
      //   ),
      //   key: "8",
      //   children: <NumeracionConfiguracion/>
      // },
      {
        label: (
          <span>
            <BankOutlined />
            Contabilidad
          </span>
        ),
        key: "2",
        children: <ContabilidadConfiguracion/>
      },
      // {
      //   label: (
      //     <span>
      //       <RocketOutlined />
      //       Envio
      //     </span>
      //   ),
      //   key: "10",
      //   children: <EnvioConfiguracion/>
      // },
      {
        label: (
          <span>
            <SafetyCertificateOutlined />
            Fiscal
          </span>
        ),
        key: "6",
        children: <FiscalConfiguracion/>
      },
      {
        label: (
          <span>
            <CloudDownloadOutlined />
            Descarga Masiva
          </span>
        ),
        key: "7",
        children: <DescargaMasivaConfiguracion/>
      },
      {
        label: (
          <span>
            <ShopFilled />
            Insumos
          </span>
        ),
        key: "3",
        children: <InsumosConfiguracion/>
      },
      // {
      //   label: (
      //     <span>
      //       <BarcodeOutlined />
      //       Detallista
      //     </span>
      //   ),
      //   key: "11",
      //   children: <ComplementoDetallistaConfiguracion/>
      // },
      {
        label: (
          <span>
            <ApiOutlined />
            Complementos
          </span>
        ),
        key: "11",
        children: <ComplementosConfiguracion/>
      },
      {
        label: (
          <span>
            <TruckOutlined />
            Logistica
          </span>
        ),
        key: "12",
        children: <LogisticaConfiguracion/>
      },
      {
        label: (
          <span>
            <SettingFilled />
            Avanzado
          </span>
        ),
        key: "5",
        children: <AvanzadoConfiguracion/>
      },
      {
        label: (
          <span>
            <MailOutlined />
            Correo
          </span>
        ),
        key: "13",
        children: <CorreoConfiguracion/>
      },
    ]

    return (
      <>
        <PageHeader
          title="Configuracion"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
        />
        <div style={{backgroundColor: 'white', padding: 20, paddingTop: 5}}>
          <Tabs defaultActiveKey="1" items={tabItems} />
        </div>
      </>
    );
  }
}

export default Configuracion;
