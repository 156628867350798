import React, { Component } from "react";
import { connect } from "react-redux";
import {
    HeaderItem,
    BoxItem,
    FormItem,
    ButtonItem,
    SelectItem,
    InputItem,
} from "../../../../../Components/Items";
import {
    Form as FormInitial,
    Row,
    Col,
    Select,
} from "antd"
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const { Option } = Select;


class TabRelaciones extends Component {
    render() {
        return (
            <>
                <HeaderItem>Relaciones CFDI</HeaderItem>
                <BoxItem>
                    <FormInitial.List
                        name="relacionesCFDI"
                    >
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ name }, arrayKey) => (
                                    <Row>
                                        <Col span={11}>
                                            <FormItem
                                                name={[name, "tipoRelacion"]}
                                                fieldKey={[arrayKey, "tipoRelacion"]}
                                                required
                                                margin
                                                noStyle
                                            >
                                                <SelectItem disabled={this.props.disabled} width='100%' placeholder="Tipo de Relación">
                                                    <Option value="01">Nota de crédito de los documentos relacionados</Option>
                                                    <Option value="02">Nota de débito de los documentos relacionados</Option>
                                                    <Option value="03">Devolución de mercancía sobre facturas o traslados previos</Option>
                                                    <Option value="04">Sustitución de los CFDI previos</Option>
                                                    <Option value="05">Traslados de mercancias facturados previamente</Option>
                                                    <Option value="06">Factura generada por los traslados previos</Option>
                                                    <Option value="07">CFDI por aplicación de anticipo</Option>
                                                </SelectItem>
                                            </FormItem>
                                        </Col>
                                        <Col span={11}>
                                            <FormItem
                                                name={[name, "uuid"]}
                                                fieldKey={[arrayKey, "uuid"]}
                                                pattern
                                                required
                                                margin
                                                noStyle
                                            >
                                                <InputItem
                                                    disabled={this.props.disabled}
                                                    placeholder="UUID"
                                                />
                                            </FormItem>
                                        </Col>
                                        <Col span={2}>
                                            <div style={{ textAlign: 'center' }}>
                                                <MinusCircleOutlined
                                                    disabled={this.props.disabled}
                                                    onClick={() => remove(name)}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                ))}
                                <div style={{ textAlign: 'center', marginTop: 10 }}>
                                    <ButtonItem
                                        type="primary"
                                        onClick={() => add()}
                                        icon={<PlusOutlined />}
                                        disabled={this.props.disabled}
                                    >
                                        Agregar
                                    </ButtonItem>
                                </div>
                            </>
                        )}
                    </FormInitial.List>
                </BoxItem>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        headersToken: state.user.headersToken,
        empresaId: state.empresa.id,
        usuarioId: state.user.usuarioId,
    };
};

export default connect(mapStateToProps)(TabRelaciones);