import React, { Component } from "react";
import { message, Row, Col, Input } from "antd";
import { Form, FormItem, InputNumberItem, InputItem, TextAreaItem, ButtonItem, ErrorItem } from "../../../../Components/Items";
import axios from "axios";
import { connect } from "react-redux";
import { UserOutlined } from "@ant-design/icons";

class GeneralConfiguracion extends Component {
  formRef = React.createRef();
  state = {
    archivo: null,
    base64: null,
    archivosLogo: [],
  };
  componentDidMount() {
    this.consultarConfiguracion();
  }
  onFinish(values) {
    if(this.props.tipo !== 0){
        values = {tiempoSesion : values.tiempoSesion}
    }
    axios
      .post(
        "usuario/editarConfiguracionGeneral",
        values,
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Se ha modificado la configuracion del usuario");
          if(res.data.tiempoCambiado){
            message.info('Para tener el tiempo de sesion, cierre y abra la sesion nuevamente.')
          }
          this.consultarConfiguracion();
        } else if (res.data.success === 1) {
          message.error("No se pudo modificar la configuracion");
          if(res.data.nombre){
            message.info('El nombre de usuario ya existe')
          }
        } else {
          message.error("Hubo un error y no se modifico la configuracion");
        }
      });
  }
  async consultarConfiguracion() {
    await axios
      .get(
        "usuario/selectConfiguracionGeneral",
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
          message.error("Error no se encontro configuracion de este usuario");
        } else {
          this.formRef.current.setFieldsValue({
            ...res.data
          });
        }
      });
  }
  onFinishFailed(e) {
    ErrorItem(e);
  }
  render() {

    const principal = this.props.tipo !== 0

    return (
      <>
        <Form
          name="generalConfiguracion"
          layout="vertical"
          onFinish={this.onFinish.bind(this)}
          onFinishFailed={this.onFinishFailed}
          ref={this.formRef}
        >
          <Row gutter={[16, 16]}>
            {/* Columna 1 */}
            <Col xs={24} sm={24} md={12} lg={12}>
              <FormItem label="Nombre" margin>
                <Input.Group compact>
                  <FormItem
                    margin
                    name="nombre"
                    noStyle
                    pattern
                    required
                    max={200}
                  >
                    <InputItem
                      disabled={principal}
                      style={{ width: "33%", marginTop: "8px" }}
                      placeholder="Nombre"
                      prefix={<UserOutlined className="site-form-item-icon" />}
                    />
                  </FormItem>
                  <FormItem
                    name="apellidoPaterno"
                    noStyle
                    pattern
                    margin
                    required
                    max={200}
                  >
                    <InputItem
                      disabled={principal}
                      style={{ width: "33%", marginTop: "8px" }}
                      placeholder="Apellido P."
                    />
                  </FormItem>
                  <FormItem
                    name="apellidoMaterno"
                    noStyle
                    pattern
                    required
                    margin
                    max={200}
                  >
                    <InputItem
                      disabled={principal}
                      style={{ width: "33%", marginTop: "8px" }}
                      placeholder="Apellido M."
                    />
                  </FormItem>
                </Input.Group>
              </FormItem>
              <FormItem
                name="nombreUsuario"
                label="Nombre Usuario"
                pattern
                required
                margin
                max={200}
                min={5}
              >
                <InputItem
                  disabled={principal}
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Nombre de usuario"
                />
              </FormItem>
              <FormItem
                label="Tiempo Sesion (min.)"
                name="tiempoSesion"
                number
                margin
                type="number"
                min={5}
                max={60}
              >
                <InputNumberItem placeholder="Tiempo de termino de sesion (min.)" />
              </FormItem>
            </Col>

            {/* Columna 2 */}
            <Col xs={24} sm={24} md={12} lg={12}>
              <FormItem label="Rfc" name="rfc" rfc margin>
                <InputItem
                  disabled={principal}
                  placeholder="Registro Federal de Contribuyentes"
                />
              </FormItem>
              <FormItem label="Curp" name="curp" curp margin>
                <InputItem
                  disabled={principal}
                  placeholder="Clave Unica de Registro de Poblacion"
                />
              </FormItem>
              <FormItem label="Celular" name="celular" number margin>
                <InputNumberItem
                  disabled={principal}
                  placeholder="Celular"
                  celular
                />
              </FormItem>
              <FormItem label="Telefono" name="telefono" number margin>
                <InputNumberItem
                  disabled={principal}
                  placeholder="Telefono"
                  celular
                />
              </FormItem>
              <FormItem label="Nss" name="nss" margin>
                <InputItem
                  disabled={principal}
                  placeholder="Numero seguro social"
                />
              </FormItem>
              <FormItem label="Observaciones" name="observaciones" margin pattern>
                <TextAreaItem
                  disabled={principal}
                  placeholder="Observaciones"
                />
              </FormItem>
            </Col>
          </Row>
          <Row justify="end">
            <Col>
              <ButtonItem
                type="primary"
                htmlType="submit"
                style={{ display: "block" }}
              >
                Guardar Configuracion
              </ButtonItem>
            </Col>
          </Row>
        </Form>
      </>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    tipo: state.user.tipo,
  };
};

export default connect(mapStateToProps)(GeneralConfiguracion);
