import { message, Row, Col, Select } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  ModalItem,
  FormItem,
  InputItem,
  ButtonItem,
  ErrorItem,
  SelectItem,
} from "../../../../Components/Items";

const { Option } = Select;

class EditarTipoDePoliza extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    tipoDePoliza: null,
  };

  componentDidUpdate(prevProps) {
    if ((this.props.visible && !prevProps.visible) || (this.props.visible && this.props.polizaId !== prevProps.polizaId)) {
      this.consultarTipoDePoliza();
    }
  }

  consultarTipoDePoliza = () => {
    this.setState({ loading: true });
    axios
      .post("tiposdepolizas/consultarTipoDePoliza", 
        { polizaid: this.props.tipoDePolizaId }, 
        {
        headers: this.props.headersToken,
      })
      .then((res) => {
        this.setState({ loading: false, tipoDePoliza: res.data });
        this.formRef.current?.setFieldsValue({
          nombre: res.data.nombre || "",
          descripcion: res.data.descripcion || "",
          tipoDePolizaId: res.data.tipoDePolizaId || "",
        });
      })
      .catch(() => {
        this.setState({ loading: false });
        message.error("Error al consultar el tipo de póliza");
      });
  };

  cerrarEditarTipoDePoliza = (codigo) => {
    this.props.cerrarEditarTipoDePoliza(codigo);
  };

  onFinishFailed = (e) => {
    ErrorItem(e);
  };

  onFinish = (values) => {
    this.setState({ loading: true });
    axios
      .post(
        "tiposdepolizas/editarTipoDePoliza",
        { ...values, id: this.props.tipoDePolizaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        console.log(res);
        this.setState({ loading: false });
        if (res.data) {
          message.success("Se editó el tipo de póliza correctamente");
          this.formRef.current.resetFields();
          this.cerrarEditarTipoDePoliza(res.data.id);
        } else {
          message.error("Ocurrió un error y no se guardaron los cambios");
        }
      });
  };

  render() {
    return (
      <ModalItem
        title="Editar Tipo de Póliza"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarEditarTipoDePoliza.bind(this)}
        paddingTop
      >
        <Form
          ref={this.formRef}
          name="editarTipoDePoliza"
          layout
          bottom={
            <ButtonItem
              type="primary"
              htmlType="submit"
              style={{ display: "block", margin: "0 0 0 auto" }}
            >
              Guardar Cambios
            </ButtonItem>
          }
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
        >
          <Row style={{ width: "100%" }}>
            <Col span={24}>
              <FormItem name="nombre" label="Nombre" required max={100} margin>
                <InputItem placeholder="Nombre" />
              </FormItem>
              <FormItem name="descripcion" label="Descripción" margin required>
                <InputItem placeholder="Descripción" />
              </FormItem>
              <FormItem label="Tipo de Póliza" margin name="tipoDePolizaId" required>
                <SelectItem placeholder="Tipo de Póliza" disabled>
                  <Option value={1}>Ingreso</Option>
                  <Option value={2}>Egreso</Option>
                  <Option value={3}>Traslado</Option>
                  <Option value={4}>Nomina</Option>
                  <Option value={5}>Pago</Option>
                  <Option value={6}>Diario</Option>
                </SelectItem>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </ModalItem>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
  };
};

export default connect(mapStateToProps)(EditarTipoDePoliza);
