import React, { useState } from 'react';

const IconFileXml = ({ size = 16, stroke = 2, color = "currentColor", hoverColor = "#1890ff", marginRight = 8 }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            stroke={isHovered ? hoverColor : color}
            strokeWidth={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{
                transition: "stroke 0.5s ease",
                marginRight: `${marginRight}px`, // Espacio a la derecha
                marginBottom: "-4px"
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <path d="M14 3v4a1 1 0 0 0 1 1h4" />
            <path d="M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4" />
            <path d="M4 15l4 6" />
            <path d="M4 21l4 -6" />
            <path d="M19 15v6h3" />
            <path d="M11 21v-6l2.5 3l2.5 -3v6" />
        </svg>
    );
};

export default IconFileXml;
