import { PageHeader, Select, Space } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { SelectItem, TableItem, round10Decimals } from "../../../../Components/Items";
import moment from "moment";
import "moment/locale/es";

moment.locale("es");

const { Option } = Select;

class Inventario extends Component {
  state = {
    loading: false,
    objetoProyectos: [],
    todasProyectos: [],
    objetoInsumos: [],
    todasInsumos: [],
    todasEntradasSalidas: [],
    proyectoId: this.props.location.state.proyectoId ? this.props.location.state.proyectoId : 0,
    insumoId: this.props.location.state.id
  };

  async componentDidMount() {
    this.setState({ loading: true });
    await axios
    .post(
      "proyectos/selectProyectos",
      { empresaId: this.props.empresaId },
      {
        headers: this.props.headersToken,
      }
    )
    .then((res) => {
      if (res.data.status === 404) {
      } else {
        this.setState({ todasProyectos: res.data });
        this.setState({
          objetoProyectos: res.data.map((value) => {
            return (
              <Option value={value._id} key={value._id}>
                {value.nombre}
              </Option>
            );
          }),
        });
      }
    });
    await axios
    .post(
      "insumos/selectInsumosInventariables",
      { empresaId: this.props.empresaId },
      {
        headers: this.props.headersToken,
      }
    )
    .then((res) => {
      if (res.data.status === 404) {
      } else {
        this.setState({ todasInsumos: res.data });
        this.setState({
          objetoInsumos: res.data.map((value) => {
            return (
              <Option value={value._id} key={value._id}>
                {value.stringBuscar}
              </Option>
            );
          }),
        });
      }
    });
        axios
        .post(
          "inventarios/selectEntradasSalidasProducto",
          { empresaId: this.props.empresaId, insumoId: this.state.insumoId, proyectoId: this.state.proyectoId },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.status === 404) {
            this.setState({todasEntradasSalidas: []})
          } else {
            this.setState({
              todasEntradasSalidas: res.data
            });
          }
          this.setState({ loading: false });
        });
        this.setState({ loading: false });
  }

  onChangeProyectoInsumo(insumoId, proyectoId) {
    this.setState({loading: true})
        axios
          .post(
            "inventarios/selectEntradasSalidasProducto",
            { empresaId: this.props.empresaId, insumoId, proyectoId },
            { headers: this.props.headersToken }
          )
          .then((res) => {
            if (res.data.status === 404) {
              this.setState({todasEntradasSalidas: []})
            } else {
              this.setState({
                todasEntradasSalidas: res.data
              });
            }
            this.setState({ loading: false, insumoId, proyectoId });
          });
        
  }

  handleMenuClick(e) {
    if(e.key === '1'){
      this.abrirAgregarInventario()
    }
    if(e.key === '2'){
      this.abrirTraspasoEntreAlmacenes()
    }
    if(e.key === '3'){
      this.abrirProductosAlCosto()
    }
  }

  render() {
    const columns = [
        {
          title: "E/S",
          dataIndex: "salida",
          key: "salida",
          render: (value) => value ? 'Salida' : 'Entrada',
          width: "20%",
        },
        {
          title: "Fecha",
          dataIndex: "createdAt",
          responsive: ["md"],
          key: "createdAt",
          render: (value) => moment(value).locale("es").format("ll"),
          width: "20%",
        },
        {
            title: "Proyecto",
            dataIndex: "proyecto",
            responsive: ["md"],
            key: "proyecto",
            render: (value) => value.nombre,
            width: "10%",
        },
        {
          title: "Concepto",
          dataIndex: "concepto",
          responsive: ["md"],
          key: "concepto",
          buscar: "concepto",
        },
        {
          title: "Cantidad",
          dataIndex: "cantidad",
          key: "cantidad",
          sorter: (a, b) => {
            if (a.cantidad < b.cantidad) {
              return -1;
            }
            if (a.cantidad > b.cantidad) {
              return 1;
            }
            return 0;
          },
          render: (value) => round10Decimals(value).toFixed(4),
          width: "20%",
        },
        {
          title: "Saldo",
          dataIndex: "saldo",
          key: "saldo",
          sorter: (a, b) => {
            if (a.saldo < b.saldo) {
              return -1;
            }
            if (a.saldo > b.saldo) {
              return 1;
            }
            return 0;
          },
          width: "20%",
        },
        {
          title: "Usuario",
          dataIndex: "usuario",
          responsive: ["md"],
          key: "usuario",
          render: (value) => value.nombre,
          width: "10%",
        },
      ];
    return (
      <>
        <PageHeader
          title={'Inventario - Insumo'}
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <Space style={{ width: "100%", minWidth: 700 }}>
              <SelectItem
                placeholder="Insumo"
                width={550}
                onChange={(value) => this.onChangeProyectoInsumo(value, this.state.proyectoId)}
                value={this.state.insumoId}
              >
                {this.state.objetoInsumos}
              </SelectItem>
              <SelectItem
                placeholder="Proyecto"
                width={150}
                onChange={(value) => this.onChangeProyectoInsumo(this.state.insumoId, value)}
                value={this.state.proyectoId}
              >
                  <Option value={0}>Todos</Option>
                {this.state.objetoProyectos}
              </SelectItem>
            </Space>
          }
        />
        <TableItem
          size="small"
          dataSource={this.state.todasEntradasSalidas}
          columns={columns}
          pagination={false}
          loading={this.state.loading}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(Inventario);
