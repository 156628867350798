import { PageHeader, Space, Tag, message, Popconfirm } from "antd";
import axios from "axios";
import React, { Component } from "react";
import {TableItem, dineroDisabledMask, ButtonItem, SegmentedItem} from "../../../Components/Items";
import moment from "moment";
import 'moment/locale/es'
import { connect } from "react-redux";
import { CloseCircleOutlined, FileExcelOutlined } from "@ant-design/icons";

moment.locale('es')

class RemisionesCompra extends Component {
  state = {
    todasRemisionCompra: [],
    valor: 4,
  }
  componentDidMount() {
    this.consultarRemisionesCompra(this.state.valor);
  }

  consultarRemisionesCompra(valor) {
    this.setState({loading: true})
    axios
    .post(
      "remisionesCompra/selectRemisiones",
      { empresaId: this.props.empresaId, valor: valor },
      {
        headers: this.props.headersToken,
      }
    )
    .then((res) => {
      this.setState({loading: false})
      if (res.data.status === 404) {
        this.setState({todasRemisionCompra: []});
      } else {
        this.setState({
          todasRemisionCompra: res.data.map(value => {
            return {...value, 
              proveedorNombre: value.proveedor.nombre ? value.proveedor.nombre + " " + value.proveedor.apellidoPaterno + " " + value.proveedor.apellidoMaterno
              : value.proveedor.razonSocial,
              accion: { _id: value._id, estado: value.estado, estadoPagoCajaChica: value.estadoPagoCajaChica,  xml: value.xml }}
          }),
        });
      }
    });
  }
  agregarRemisionCompra() {
    this.props.history.push('/panel/agregarRemisionCompra')
  }
  cancelarRemisionCompra(value) {
    axios
      .post(
        "remisionesCompra/cancelarRemision",
        { remisionId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Remision cancelada");
        } else if (res.data.success === 1) {
          message.warn(res.data.message);
        }else {
          message.error("Sucedio un error y no se pudo cancelar");
        }
        this.consultarRemisionesCompra(this.state.valor);
      });
  }
  render() {
    const columns = [
        {
          title: "Proyecto",
          dataIndex: "proyecto",
          key: "proyecto",
          sorter: (a, b) => {
            if (a.proyecto.nombre < b.proyecto.nombre) {
              return -1;
            }
            if (a.proyecto.nombre > b.proyecto.nombre) {
              return 1;
            }
            return 0;
          },
          render: (value) => value.nombre,
          width: '8%',
          ellipsis: true,
        },
        {
          title: "Proveedor",
          dataIndex: "proveedorNombre",
          key: "proveedorNombre",
          sorter: (a, b) => {
            if (a.proveedorNombre < b.proveedorNombre) {
              return -1;
            }
            if (a.proveedorNombre > b.proveedorNombre) {
              return 1;
            }
            return 0;
          },
          buscar: 'proveedorNombre',
          width: '37%',
          ellipsis: true,
        },
        {
          title: "Fecha",
          dataIndex: "fechaRemision",
          key: "fechaRemision",
          defaultSortOrder: 'descend',
          sorter: (a, b) => {
            if (moment(a.fechaRemision) < moment(b.fechaRemision)) {
              return -1;
            }
            if (moment(a.fechaRemision) > moment(b.fechaRemision)) {
              return 1;
            }
            return 0;
          },
          render: (value) => moment(value).locale('es').format('ll'),
          wdith: '15%',
          ellipsis: true,
        },
        {
          title: 'Subtotal',
          dataIndex: 'subtotal',
          key: 'subtotal',
          sorter: (a, b) => {
            if (a.subtotal < b.subtotal) {
              return -1;
            }
            if (a.subtotal > b.subtotal) {
              return 1;
            }
            return 0;
          },
          render: value => dineroDisabledMask(value),
          buscar: 'subtotal',
          width: '8%',
          ellipsis: true,
        },
        {
          title: 'Total',
          dataIndex: 'totalPagar',
          key: 'totalPagar',
          sorter: (a, b) => {
            if (a.totalPagar < b.totalPagar) {
              return -1;
            }
            if (a.totalPagar > b.totalPagar) {
              return 1;
            }
            return 0;
          },
          render: value => dineroDisabledMask(value),
          buscar: 'totalPagar',
          width: '8%',
          ellipsis: true,
        },
        {
          title: 'Estado',
          dataIndex: 'estado',
          key: 'estado',
          render: (value, record, index) => {
            if(value === 0){
              return <Tag key={index.toString()} color='red'>Cancelada</Tag>
            }
            if(value === 1){
              return <Tag key={index.toString()} color='blue'>Sin recibir</Tag>
            }
            if(value === 2){
              return <Tag key={index.toString()} color='orange'>Recibido parcialmente</Tag>
            }
            if(value === 3){
              return <Tag key={index.toString()} color='green'>Recibido</Tag>
            }
          },
          width: '10%',
          ellipsis: true,
        },
        {
          title: 'Acción',
          dataIndex: 'accion',
          key: 'accion',
          render: (value, record, index) => {
            if(value.estado === 0) return

            let buttons = []
            if(value.estado !== 0 && value.xml){
              buttons.push(<FileExcelOutlined key={index.toString()} onClick={() => this.descargarXml(value._id)} />)
            }
            if(value.estado !== 0 && value.estadoPagoCajaChica === 0){
              buttons.push(<Popconfirm
                title="Seguro que quieres cancelar esta remision"
                onConfirm={() => this.cancelarRemisionCompra(value._id)}
                okText="Si"
                cancelText="No"
                key={index.toString()}
              >
                <CloseCircleOutlined style={{ color: "red" }} />
              </Popconfirm>)
            }
            return (
              <Space>
                {buttons}
              </Space>
            )
          },
          width:'6%',
          ellipsis: true,
        }
      ]
    return (
      <>
        <PageHeader
          title="Remisiones de Compra"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <>
            {window.innerWidth > 1000 &&
              <SegmentedItem
                options={[{label: 'Todos', value: 4}, {label: 'Cancelada', value: 0}]}
                onChange={(value) => {
                  this.consultarRemisionesCompra(value)
                  this.setState({ valor: value })
                }}
                value={this.state.valor}
              />
          }
              <ButtonItem
              type="primary"
              key="agregarRemisionCompra"
              onClick={this.agregarRemisionCompra.bind(this)}
              >
              Agregar Remision
            </ButtonItem>
            </>
          }
        />
        <TableItem onRow={(record, rowIndex) => {
          return {
            onDoubleClick: event => {
              this.props.history.push('/panel/verRemisionCompra', {id: record._id})
            },
          };
        }} loading={this.state.loading} size='small' dataSource={this.state.todasRemisionCompra} columns={columns} pagination={false} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(RemisionesCompra);