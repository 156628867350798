import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import {PrivRoute} from "./Components/Items";
import LogIn from "./Pages/LogIn/LogIn";
import PagoExitoso from "./Pages/Pagos/PagoExitoso";
import './App.css'
import Registro from "./Pages/LogIn/Registro/Registro";
import Panel from "./Pages/Panel/Panel";
import PanelPrincipal from "./Pages/PanelPrincipal/PanelPrincipal";
import { message, notification } from "antd";
import { login, hasSigned, logout } from "./store/actions/user";
import { seleccionarEmpresaHasSigned } from "./store/actions/empresa";
import { connect } from "react-redux";
import RecuperarContrasena from './Pages/RecuperarContrasena/RecuperarContrasena';
import CambiarContrasena from './Pages/CambiarContrasena/CambiarContrasena';
import axios from "axios";
import kiosko from "./Pages/Kiosko/Kiosko";

class App extends Component {
  state = {
    redirect: null,
    firstPathname: window.location.pathname,
    loading: true
  }
  componentDidMount(){
    notification.config({
      placement: 'topRight',
      duration: 30,
    });
    const firstPathname = window.location.pathname
    if(firstPathname.slice(0,12) === '/pagoExitoso' || firstPathname.slice(0,9) === '/registro' || firstPathname.slice(0,20) === '/recuperarContrasena' || firstPathname.slice(0,18) === '/cambiarContrasena' || firstPathname.slice(0,12) === '/pagoFallido' || firstPathname.slice(0,7) === '/kiosko'){
      const redirect = (
        <Redirect to={window.location.href.split('/', 4)[3]}/>
      )
      this.setState({redirect , loading: false})
    }else{
      this.props
      .hasSigned()
      .then((data) => {
        if (data.success === 1) {
          if(data.data.empresa){
            this.props.seleccionarEmpresaHasSigned({
              id: data.data.empresa._id, 
              nombre: data.data.empresa.nombre,
              rfc:  data.data.empresa.rfc,
              certificada: data.data.empresa.certificada, 
              certificadaDM: data.data.empresa.certificadaDM,
              envios: data.data.empresa.envios,
              fletes: data.data.empresa.fletes,
              marbetes: data.data.empresa.marbetes,
              complementoDetallista: data.data.empresa.complementoDetallista,
              cargarXmlPrevio: data.data.empresa.cargarXmlPrevio,
              modulosMezcal: data.data.empresa.modulosMezcal,
              modulosConsignaciones: data.data.empresa.modulosConsignaciones,
              equipoMedico: data.data.empresa.equipoMedico,
              lotesCaducidades: data.data.empresa.lotesCaducidades,
            })
          }
          const redirect = (
            <Redirect to={firstPathname === '/' || firstPathname === '/login' ? '/panelPrincipal' : firstPathname}/>
          )
          this.setState({redirect, loading: false})
          // window.location.replace(firstPathname === '/' ? '/panelPrincipal' : firstPathname);
        } else {
          const redirect = (
            <Redirect to='/login'/>
          )
          this.setState({redirect , loading: false})
          if (firstPathname !== '/' && firstPathname !== '/login') {
            if(firstPathname === '/logout1'){
              notification.info({
                message: 'Se ha cerrado la sesion por inactividad'
              })
            }else if(firstPathname === '/logout2'){
              notification.info({
                message: 'Se ha cerrado la sesion por falta de pago'
              })
            }else{
              message.warning(data.err);
            }
          }
        }
      })
      .catch((err) => {
        if (firstPathname !== '/' && firstPathname !== '/login') {
          notification.error({
            message: "Adventencia",
            description: "No se puede acceder a la pagina sin iniciar sesión",
          });
          const redirect = (
            <Redirect to='/login'/>
          )
          this.setState({redirect , loading: false})
        }
      });
    }
    let cookieEnds
    let cookieEnds2
    axios.interceptors.response.use(async (responsePeticion) => {
      const loggedIn = this.props.isUserLoggedIn
      if(loggedIn){
        if(cookieEnds !== undefined){
        clearTimeout(cookieEnds)
        }
        cookieEnds = setTimeout(() => {
          clearTimeout(cookieEnds)
          notification.info({
            message: 'Su sesion se cerrara en 1 minuto si no tiene actividad'
          })
        }, (this.props.tiempoSesion - 60000))
        if(cookieEnds2 !== undefined){
          clearTimeout(cookieEnds2)
        }
        cookieEnds2 = setTimeout(async () => {
          clearTimeout(cookieEnds2)
          clearTimeout(cookieEnds)
          await this.props.hasSigned().then(async (responseSigned) => {
            if (responseSigned.success === 1) {
              if(responseSigned.data.empresa){
                await this.props.seleccionarEmpresaHasSigned({
                  id: responseSigned.data.empresa._id, 
                  nombre: responseSigned.data.empresa.nombre,
                  rfc:  responseSigned.data.empresa.rfc,
                  certificadaDM: responseSigned.data.empresa.certificadaDM,
                  envios: responseSigned.data.empresa.envios,
                  fletes: responseSigned.data.empresa.fletes,
                  marbetes: responseSigned.data.empresa.marbetes,
                  complementoDetallista: responseSigned.data.empresa.complementoDetallista,
                  cargarXmlPrevio: responseSigned.data.empresa.cargarXmlPrevio,
                  modulosMezcal: responseSigned.data.empresa.modulosMezcal,
                  modulosConsignaciones: responseSigned.data.empresa.modulosConsignaciones,
                  equipoMedico: responseSigned.data.empresa.equipoMedico,
                  lotesCaducidades: responseSigned.data.empresa.lotesCaducidades,
                })
              }
            } else {
              this.props.logout().then((responseLogout) => {
                if(responseLogout.success === 1){
                  window.location.replace("/logout1");
                }else{
                  message.warning(responseLogout.err);
                }
              })
            }
          })
        }, this.props.tiempoSesion)
      }

      //Checar para que no se regrese

      if(Number(responsePeticion?.status) === 204){
        window.location.replace("/logout1");

      }else if(Number(responsePeticion?.status) === 205){
        window.location.replace("/panelPrincipal");
        notification.info({
          message: responsePeticion?.data ? responsePeticion?.data : "No puede utilizar esta empresa por falta de pago"
        })
      }else{
        return responsePeticion;
      }
      // Do something with response data
    }, function (error) {
      // Do something with response error
      return Promise.reject(error);
    });
  }
  render() {
    if(this.state.loading){
      return null
    }
    return (
      <BrowserRouter>
        {this.state.redirect}
        <Switch>
          <Route path='/kiosko' component={kiosko} />
          <Route path='/pagoExitoso' component={PagoExitoso} />
          <Route path='/login' component={LogIn} />
          <Route path='/registro' component={Registro} />
          <Route path='/recuperarContrasena' component={RecuperarContrasena} />
          <Route path='/cambiarContrasena' component={CambiarContrasena} />
          <PrivRoute path='/panelPrincipal' component={PanelPrincipal} />
          <PrivRoute path='/panel' component={Panel} />
        </Switch>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isUserLoggedIn: state.user.isUserLoggedIn,
    tiempoSesion: state.user.tiempoSesion,
  };
};

export default connect(mapStateToProps, { login, hasSigned, seleccionarEmpresaHasSigned, logout })(App);
