import { CloudServerOutlined, FileExcelOutlined, EyeOutlined, FilePdfOutlined, InfoOutlined } from "@ant-design/icons";
import { PageHeader, Space, Tag, Empty, Tooltip, message, Popconfirm } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { ButtonItem, TableItem, dineroDisabledMask, SegmentedItem } from "../../../Components/Items";
import moment from "moment";
import VistaPrevia from "./VistaPrevia/VistaPrevia";
import facturaXmlPdf from "../../../Pdf/facturaXmlPdf";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import complementoXmlPdf from "../../../Pdf/complementoXmlPdf";
import RelacionarPago from "./RelacionarPago/RelacionarPago";
import AgregarAOrdenDeCompra from "./AgregarAOrdenDeCompra/AgregarAOrdenDeCompra";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class ArchivosXml extends Component {
  state = {
    todasArchivosXml: [],
    loading: false,
    emitido: 1,
    modalVistaPrevia: false,
    modalVistaPreviaId: null,
    modalRelacionarPago: false,
    modalRelacionarPagoId: null,
    modalOrdenDeCompra: false,
    modalOrdenDeCompraId: null,
    valor: 1
  };
  componentDidMount() {
    this.consultarArchivosXml(this.state.valor);
  }
  consultarArchivosXml(val) {
    this.setState({ loading: true })
    axios
      .post(
        "descargaMasiva/selectTodosXml",
        { empresaId: this.props.empresaId, valor: val },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false })
        if (res.data.status === 404) {
          this.setState({ todasArchivosXml: [] });
        } else {
          this.setState({
            todasArchivosXml: res.data.data.map(value => {
              return {
                ...value, 
                formaDePago: value?.formaDePago?.c_FormaPago ? (value.formaDePago.c_FormaPago + ' - ' + value.formaDePago.descripcion) : '',
                accion: {
                  tipoDeComprobante: value.tipoDeComprobante,
                  _id: value._id,
                  contabilizada: value.contabilizada,
                  nombre: value.nombre,
                  cancelable: value.cancelable,
                  estadoCancelacion: value.estadoCancelacion,
                  fechaUltimaVerificacion: value.fechaUltimaVerificacion,
                  estado: value.estado
                }
              }
            }),
          });
        }
      });
  }

  descargarXml(id) {
    axios
      .post(
        "descargaMasiva/descargarXml",
        { xmlId: id, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          var xmltext = res.data.xml;

          var filename = res.data.name;
          var pom = document.createElement("a");
          var bb = new Blob([xmltext], { type: "text/plain" });

          pom.setAttribute("href", window.URL.createObjectURL(bb));
          pom.setAttribute("download", filename);

          pom.dataset.downloadurl = ["text/plain", pom.download, pom.href].join(
            ":"
          );
          pom.draggable = true;
          pom.classList.add("dragout");

          pom.click();
        } else {
          message.error("Hubo un error y no se pudo descargar");
        }
      });
  }

  agregarFacturaSinOrdenDeCompra(id) {
    this.setState({ loading: true });
    axios
      .post(
        "descargaMasiva/datosXml",
        { xmlId: id, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          this.props.history.push('/panel/agregarFacturaSinOrdenDeCompra', { fileName: res.data.uuid, fileData: JSON.stringify(res.data.dataXml) })
        } else {
          message.error('Hubo un error y no se pudo redigir')
        }
      });
    this.setState({ loading: false });
  }

  agregarFacturaCajaChica(id) {
    this.setState({ loading: true });
    axios
      .post(
        "descargaMasiva/datosXml",
        { xmlId: id, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          this.props.history.push('/panel/agregarFacturaCajaChica', { fileName: res.data.uuid, fileData: JSON.stringify(res.data.dataXml) })
        } else {
          message.error('Hubo un error y no se pudo redigir')
        }
      });
    this.setState({ loading: false });
  }

  abrirVistaPrevia(id) {
    this.setState({
      modalVistaPrevia: true,
      modalVistaPreviaId: id,
    });
  }

  cerrarVistaPrevia() {
    this.setState({
      modalVistaPrevia: false,
      modalVistaPreviaId: null,
    });
  }

  descargarPdf(id) {
    this.setState({ loading: true });
    axios
      .post(
        "descargaMasiva/datosXml",
        { xmlId: id, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          if (res.data.tipoDeComprobante === 'I' || res.data.tipoDeComprobante === 'E') {
            pdfMake.createPdf(facturaXmlPdf(res.data)).download(res.data.uuid);
          } else if (res.data.tipoDeComprobante === 'P') {
            pdfMake.createPdf(complementoXmlPdf(res.data)).download(res.data.uuid);
          }
        }
      });
    this.setState({ loading: false });
  }

  abrirRelacionarPago(id) {
    this.setState({
      modalRelacionarPago: true,
      modalRelacionarPagoId: id,
    });
  }

  cerrarRelacionarPago() {
    this.setState({
      modalRelacionarPago: false,
      modalRelacionarPagoId: null,
    });
    this.consultarArchivosXml(this.state.valor)
  }

  abrirModalOrdenDeCompra(id) {
    this.setState({
      modalOrdenDeCompra: true,
      modalOrdenDeCompraId: id,
    });
  }

  cerrarModalOrdenDeCompra() {
    this.setState({
      modalOrdenDeCompra: false,
      modalOrdenDeCompraId: null,
    });
    this.consultarArchivosXml(this.state.valor)
  }

  aceptarCancelacion(id) {
    this.setState({ loading: true });
    axios
      .post(
        "descargaMasiva/aceptarCancelacion",
        { xmlId: id, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success('Se acepto la cancelacion')
          this.consultarArchivosXml(this.state.valor)
        } else {
          message.error('Hubo un error y no se pudo aceptar la cancelacion')
        }
      });
    this.setState({ loading: false });
  }

  rechazarCancelacion(id) {
    this.setState({ loading: true });
    axios
      .post(
        "descargaMasiva/rechazarCancelacion",
        { xmlId: id, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success('Se rechazo la cancelacion')
          this.consultarArchivosXml(this.state.valor)
        } else {
          message.error('Hubo un error y no se pudo rechazar la cancelacion')
        }
      });
    this.setState({ loading: false });
  }

  render() {
    let columns = []
    columns =
      this.state.emitido === 2
        ? [
          {
            title: "Receptor",
            dataIndex: "receptorNombre",
            key: "receptorNombre",
            sorter: (a, b) => {
              if (a.receptorNombre < b.receptorNombre) {
                return -1;
              }
              if (a.receptorNombre > b.receptorNombre) {
                return 1;
              }
              return 0;
            },
            buscar: "receptorNombre",
          },
          {
            title: "Tipo",
            dataIndex: "tipoDeComprobante",
            key: "tipoDeComprobante",
            render: (value) => {
              if (value === "I") {
                return "I - Ingreso";
              } else if (value === "P") {
                return "P - Pago";
              } else if (value === "E") {
                return "E - Egreso";
              } else if (value === "N") {
                return "N - Nomina";
              } else if (value === "T") {
                return "T - Traslado";
              }
            },
          },
          {
            title: "Tipo Pago",
            dataIndex: "formaDePago",
            key: "formaDePago",
            sorter: (a, b) => {
              if (a.formaDePago < b.formaDePago) {
                return -1;
              }
              if (a.formaDePago > b.formaDePago) {
                return 1;
              }
              return 0;
            },
          },
          {
            title: "Dia",
            dataIndex: "fecha",
            key: "fecha",
            sorter: (a, b) => {
              if (a.fecha < b.fecha) {
                return -1;
              }
              if (a.fecha > b.fecha) {
                return 1;
              }
              return 0;
            },
            render: (value) => {
              const fecha = moment(value).format("L");
              const arr = fecha.split(" ");

              //loop through each element of the array and capitalize the first letter.

              for (var i = 0; i < arr.length; i++) {
                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
              }

              //Join all the elements of the array back into a string
              //using a blankspace as a separator
              const str2 = arr.join(" ");
              return str2;
            },
            buscar: "fecha",
          },
          {
            title: "Subtotal",
            dataIndex: "subtotal",
            key: "subtotal",
            render: (value) => dineroDisabledMask(value),
            align: "right",
          },
          {
            title: "Total",
            dataIndex: "total",
            key: "total",
            render: (value) => dineroDisabledMask(value),
            align: "right",
          },
          {
            title: "Accion",
            dataIndex: "accion",
            key: "accion",
            render: (value) => {
              return (
                <Space>
                  {value.estado === 0 &&
                    <Tag color="red" >Cancelada</Tag>
                  }
                </Space>
              );
            },
          },
        ]
        : [
          {
            title: "Emisor",
            dataIndex: "emisorNombre",
            key: "emisorNombre",
            sorter: (a, b) => {
              if (a.emisorNombre < b.emisorNombre) {
                return -1;
              }
              if (a.emisorNombre > b.emisorNombre) {
                return 1;
              }
              return 0;
            },
            buscar: "emisorNombre",
            width: 200,
          },
          {
            title: "Tipo",
            dataIndex: "tipoDeComprobante",
            key: "tipoDeComprobante",
            render: (value) => {
              if (value === "I") {
                return "I - Ingreso";
              } else if (value === "P") {
                return "P - Pago";
              } else if (value === "E") {
                return "E - Egreso";
              } else if (value === "N") {
                return "N - Nomina";
              } else if (value === "T") {
                return "T - Traslado";
              }
            },
            width: "12%",
          },
          {
            title: "Tipo Pago",
            dataIndex: "formaDePago",
            key: "formaDePago",
            sorter: (a, b) => {
              if (a.formaDePago < b.formaDePago) {
                return -1;
              }
              if (a.formaDePago > b.formaDePago) {
                return 1;
              }
              return 0;
            },
            width: "12%",
          },
          {
            title: "Dia",
            dataIndex: "fecha",
            key: "fecha",
            sorter: (a, b) => {
              if (a.fecha < b.fecha) {
                return -1;
              }
              if (a.fecha > b.fecha) {
                return 1;
              }
              return 0;
            },
            render: (value) => {
              const fecha = moment(value).format("L");
              const arr = fecha.split(" ");

              //loop through each element of the array and capitalize the first letter.

              for (var i = 0; i < arr.length; i++) {
                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
              }

              //Join all the elements of the array back into a string
              //using a blankspace as a separator
              const str2 = arr.join(" ");
              return str2;
            },
            buscar: "fecha",
            width: "12%",
          },
          // {
          //   title: "Subtotal",
          //   dataIndex: "subtotal",
          //   key: "subtotal",
          //   render: (value) => dineroDisabledMask(value),
          //   align: "right",
          //   width: "12%",
          // },
          {
            title: "Total",
            dataIndex: "total",
            key: "total",
            render: (value) => dineroDisabledMask(value),
            align: "right",
            width: "12%",
          },
          {
            title: "Estado",
            dataIndex: "estado",
            key: "estado",
            render: (value) => {
              if (value === 0) {
                return <Tag color="red">Cancelada</Tag>;
              } else {
                return <Tag color="green">Vigente</Tag>;
              }
            },
            align: "center",
            width: "8%",
          },
          {
            title: "Accion",
            dataIndex: "accion",
            key: "accion",
            render: (value, record, index) => {

              let buttons = []

              if (value.estadoCancelacion === 1) {
                buttons.push(
                  <Tooltip title="Aceptar cancelacion factura">
                    <Popconfirm
                      title={`¿Estas seguro de aceptar la cancelacion de la factura?`}
                      onConfirm={() => this.aceptarCancelacion(value._id)}
                      okText="Ok"
                      cancelText="Cancelar"
                    >
                      <ButtonItem danger size='small'>Aceptar</ButtonItem>
                    </Popconfirm>
                  </Tooltip>,
                  <Tooltip title="Rechazar cancelacion factura">
                    <Popconfirm
                      title={`¿Estas seguro de rechazar la cancelacion de la factura?`}
                      onConfirm={() => this.rechazarCancelacion(value._id)}
                      okText="Ok"
                      cancelText="Cancelar"
                    >
                      <ButtonItem blanco size='small'>Rechazar</ButtonItem>
                    </Popconfirm>
                  </Tooltip>,
                )
              }



              if (value.contabilizada === 1 && value.tipoDeComprobante === 'I' && value.estado === 1 && value.estadoCancelacion !== 1) {
                buttons.push(
                  <Tooltip title="Agregar factura sin orden de compra con este xml">
                    <ButtonItem onClick={() => this.agregarFacturaSinOrdenDeCompra(value._id)} size='small'>F. S/O</ButtonItem>
                  </Tooltip>,
                  <Tooltip title="Agregar factura de caja chica con este xml">
                    <ButtonItem onClick={() => this.agregarFacturaCajaChica(value._id)} blanco size='small'>F. C/C</ButtonItem>
                  </Tooltip>,
                  <Tooltip title="Agregar factura a orden de compra con este xml">
                    <ButtonItem onClick={() => this.abrirModalOrdenDeCompra(value._id)} size='small'>O. C.</ButtonItem>
                  </Tooltip>,
                )
              } else if (value.contabilizada === 1 && value.tipoDeComprobante === 'P' && value.estado === 1 && value.estadoCancelacion !== 1) {
                buttons.push(
                  <Tooltip title="Relacionar a un pago existente">
                    <ButtonItem onClick={() => this.abrirRelacionarPago(value._id)} blanco size='small'>Relacionar</ButtonItem>
                  </Tooltip>,
                );
              } else if (value.estado === 1 && value.contabilizada === 1 && value.estadoCancelacion !== 1) {
                buttons.push(
                  <Tag color="red">No Contabilizada</Tag>,
                )
              }

              if (value.estado === 0) {
                buttons.push(
                  <Tag color="red">Cancelada</Tag>
                )
              }

              if (value.contabilizada === 2 && value.estadoCancelacion !== 1) {
                buttons.push(
                  <Tag color="green">Ya Contabilizada</Tag>,
                )
              }

              if (value.tipoDeComprobante === 'P' || value.tipoDeComprobante === 'I' || value.tipoDeComprobante === 'E') {
                buttons.push(
                  <Tooltip title="Vista Previa">
                    <EyeOutlined
                      size="small"
                      style={{ color: '#00b5e2' }}
                      key={index.toString()}
                      onClick={() => this.abrirVistaPrevia(value._id)}
                    />
                  </Tooltip>,
                  <Tooltip title="Descargar Pdf">
                    <FilePdfOutlined style={{ color: 'red' }} key={index.toString()} onClick={() => this.descargarPdf(value._id)} />
                  </Tooltip>,
                  <Tooltip title="Descargar Xml">
                    <FileExcelOutlined key={index.toString()} onClick={() => this.descargarXml(value._id)} />
                  </Tooltip>
                )
              }

              let mensajeInfo = ''

              //1 - Cancelable con aceptación
              //2 - No cancelable
              //3 - Cancelable sin aceptación


              //1 - En proceso
              //2 - Plazo vencido
              //3 - Solicitud rechazada
              //4 - Cancelado sin aceptación
              //5 - Cancelado con aceptación
              //0 - (null)

              if (value.estado !== 0) {
                if (value.cancelable === 1) {
                  mensajeInfo += '- Cancelable con aceptación'
                } else if (value.cancelable === 2) {
                  mensajeInfo += '- No cancelable'
                } else if (value.cancelable === 3) {
                  mensajeInfo += '- Cancelable sin aceptación'
                }
              }


              if (value.estadoCancelacion === 1) {
                mensajeInfo += (mensajeInfo.length > 0 ? '\n' : '') + '- En proceso'
              } else if (value.estadoCancelacion === 2) {
                mensajeInfo += (mensajeInfo.length > 0 ? '\n' : '') + '- Plazo vencido'
              } else if (value.estadoCancelacion === 3) {
                mensajeInfo += (mensajeInfo.length > 0 ? '\n' : '') + '- Solicitud rechazada'
              } else if (value.estadoCancelacion === 4) {
                mensajeInfo += (mensajeInfo.length > 0 ? '\n' : '') + '- Cancelado sin aceptación'
              } else if (value.estadoCancelacion === 5) {
                mensajeInfo += (mensajeInfo.length > 0 ? '\n' : '') + '- Cancelado con aceptación'
              }

              if (value.fechaUltimaVerificacion) {
                mensajeInfo += (mensajeInfo.length > 0 ? '\n' : '') + '- Checado ' + moment(value.fechaUltimaVerificacion).fromNow()
              } else {
                mensajeInfo += (mensajeInfo.length > 0 ? '\n' : '') + '- No ha sido checado'
              }


              if (mensajeInfo) {
                buttons.push(
                  <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{mensajeInfo}</span>}>
                    <InfoOutlined
                      size="small"
                      style={{ color: '#00b5e2' }}
                      key={index.toString()}
                    />
                  </Tooltip>
                );
              }

              return (
                <Space>
                  {buttons}
                </Space>
              );
            },
          },
        ];
    return (
      <>
        <PageHeader
          title={(
            <span>
              Archivos Xml
              &nbsp;
              <CloudServerOutlined />
            </span>
          )}
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            window.innerWidth > 1000 &&
            <SegmentedItem
              options={[{ label: 'No Contabilizado', value: 1 }, { label: 'Contabilizado', value: 2 }, { label: 'Todas', value: 3 }]}
              onChange={(value) => {
                this.consultarArchivosXml(value)
                this.setState({ valor: value })
              }}
              value={this.state.valor}
            />
          }
        />
        <TableItem locale={{
          emptyText: (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={(<>
            <p>Todavía no se obtiene ningun xml. Recuerde que la descarga se actualiza cada dia por la mañana.</p>
          </>)} />)
        }} size='small' loading={this.state.loading} dataSource={this.state.todasArchivosXml} columns={columns} />
        <VistaPrevia
          visible={this.state.modalVistaPrevia}
          id={this.state.modalVistaPreviaId}
          cerrarVistaPrevia={this.cerrarVistaPrevia.bind(this)}
        />
        <RelacionarPago
          visible={this.state.modalRelacionarPago}
          id={this.state.modalRelacionarPagoId}
          cerrarRelacionarPago={this.cerrarRelacionarPago.bind(this)}
        />
        <AgregarAOrdenDeCompra
          visible={this.state.modalOrdenDeCompra}
          id={this.state.modalOrdenDeCompraId}
          history={this.props.history}
          cerrarModalOrdenDeCompra={this.cerrarModalOrdenDeCompra.bind(this)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(ArchivosXml);
