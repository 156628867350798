import moment from "moment";
import "moment/locale/es";
import { dineroDisabledMask } from "../Components/Items";
import numeroALetra from "../Components/numeroALetra";
// import { dineroDisabledMask } from "../Components/Items";
// import xmlToJson from "../Components/xmlToJson";
moment.locale("es");

const ticketPdf =  (data) => {
  const empresa = data.respuestaFactura.empresa
  const cliente = data.cliente
  const dataSat = data.respuestaFactura.dataSat
  const logo = empresa.logo.base64Completa;
  const conceptos = dataSat.Conceptos;
  let qrSat = null;
  if (data.respuestaFactura.data) {
    qrSat = "data:image/jpeg;base64," + data.respuestaFactura.data.qrCode;
  }
  

  


// Genera dinámicamente las filas para la tabla de conceptos
const filasConceptos = conceptos.map((concepto) => [
  {
    text: concepto.Descripcion,
    style: "tProductsBody",
    fontSize: 8,
  },
  {
    text: concepto.Cantidad,
    style: "tProductsBody",
    alignment: "center",
    fontSize: 7,
  },
  {
    text: concepto.Unidad,
    style: "tProductsBody",
    alignment: "center",
    fontSize: 7,
  },
  {
    text: parseFloat(concepto.ValorUnitario).toFixed(2),
    style: "tProductsBody",
    alignment: "right",
    fontSize: 7,
  },
  {
    text: parseFloat(concepto.Importe).toFixed(2),
    style: "tProductsBody",
    alignment: "right",
    fontSize: 7,
  },
]);

const nuevoArrayImpFL = Array.isArray(dataSat.Impuestos.Traslados) ? 
    [...dataSat.Impuestos.Traslados, ...dataSat.Complemento.Any.find(item => item['implocal:ImpuestosLocales'])['implocal:ImpuestosLocales']["implocal:TrasladosLocales"]]
    :
    [...dataSat.Complemento.Any.find(item => item['implocal:ImpuestosLocales'])['implocal:ImpuestosLocales']["implocal:TrasladosLocales"]]

  const impuestosTotales = nuevoArrayImpFL.map(
    (valueImpuesto, indexImpuesto) => {
      let nombre = "";
      if (valueImpuesto.Impuesto === "002") {
        nombre = "IVA";
      }else if (valueImpuesto.Impuesto === "003") {
        nombre = "IEPS";
      }else{
        nombre = valueImpuesto["@ImpLocTrasladado"]
      }

      if (valueImpuesto.TipoFactor === "Tasa") {
        nombre = nombre + " " + Number(valueImpuesto.TasaOCuota * 100) + "%";
      }else if (valueImpuesto.TipoFactor === "Cuota") {
        nombre = nombre + " $" + Number(valueImpuesto.TasaOCuota);
      }

      return [
        { text: nombre, style: "tTotals", colSpan: 2, fontSize: 8, },
                {},
                { text: dineroDisabledMask(valueImpuesto.Importe || valueImpuesto["@Importe"] || 0), style: "tTotals", colSpan: 2, fontSize: 8,},
                {},
      ];
    }
  );

  if (data.factura) {
    return {
      pageSize: { width: 206, height: "auto" }, // 80 mm de ancho, altura ajustable
      pageMargins: [10, 10, 10, 10],
      content: [
        {
          image: logo,
          fit: [141.73, 56.692],
          alignment: "center",
        },
        {
          text: empresa.persona ? empresa.nombre : empresa.nombre + " " + empresa.apellidoPaterno + " " + empresa.apellidoMaterno ,
          style: "header",
          margin: [0, 10, 0, 0],
          alignment: "center",
          fontSize: 12,
        },
        {
          text: empresa.rfc,
          style: "header",
          alignment: "center",
          fontSize: 10,
        },
        {
          text: empresa.calle + " " + empresa.numeroCalle + " " + (empresa.numeroInterior ? empresa.numeroInterior : " ") ,
          style: "header",
          alignment: "center",
          fontSize: 10,
        },
        {
          text: empresa.colonia.nombre + " " + empresa.colonia.cp + " " + empresa.municipio.nombre + " " + empresa.municipio.nombre + " " + empresa.pais.nombre ,
          style: "header",
          alignment: "center",
          fontSize: 10,
        },
        {
          text: `Regimen Fiscal: (${empresa.regimenFiscal.c_RegimenFiscal}) ${empresa.regimenFiscal.descripcion}`, 
          style: "header",
          alignment: "center",
          fontSize: 10,
        },
        {
          text: "Tipo De Comprobante: (I) Ingreso", 
          style: "header",
          alignment: "center",
          fontSize: 10,
        },
  
        { text: `Nº Factura - ${dataSat.Serie} - ${dataSat.Folio}`, style: "header", margin: [0, 10, 0, 2.25],fontSize: 10, },
  
        {
          margin: [0, 10, 0, 0],
          table: {
            widths: ["100%" ], // Solo dos columnas
            body: [
              [
                { text: cliente.nombre + " " + cliente.apellidoPaterno + " " + cliente.apellidoMaterno, style: "tHeaderValue",fontSize: 10, },
              ],
              [
                { text: cliente.rfc, style: "tHeaderValue", fontSize: 10, },
              ],
              [
                { text: `(${dataSat.usoDeCFDI.c_UsoCFDI}) ${dataSat.usoDeCFDI.descripcion} `, style: "tHeaderValue", fontSize: 10, },
              ],
              [
                { text: `(${dataSat.formaDePago.c_FormaPago}) ${dataSat.formaDePago.descripcion} `, style: "tHeaderValue", fontSize: 10, },
              ],
              [
                {
                  text:  dataSat.MetodoPago === "PUE" ? "PUE - Pago en una sola exhibición" : "PPD - Pago en parcialidades o diferido ",
                  style: "tHeaderValue",
                  fontSize: 10,
                },
              ],
              [
                { text: moment(dataSat.Fecha).format('D [de] MMMM [de] YYYY'), style: "tHeaderValue", fontSize: 10, },
              ],
              [
                { text: dataSat.LugarExpedicion, style: "tHeaderValue", fontSize: 10, },
              ],
            ],
          },
          layout: "noBorders",
        },
  
        {
          margin: [0, 10, 0, 0],
          table: {
            widths: ["32%", "16%", "12%", "20%", "20%"], // Ajuste de proporciones
            headerRows: 2,
            body: [
              // Encabezado de la tabla
              [
                {
                  text: "TICKET DE COMPRA",
                  colSpan: 5,
                  style: "tTitleHeader",
                  alignment: "center",
                  fontSize: 12,
                  margin: [0, 5, 0, 5],
                },
                {},
                {},
                {},
                {},
              ],
              [
                {
                  text: "CÓDIGO - DESCRIPCIÓN",
                  colSpan: 5,
                  style: "tProductsHeader",
                  alignment: "center",
                  fontSize: 12,
                  fillColor: "#f2f2f2", // Color de fondo
                  margin: [0, 5, 0, 5],
                },
                {},
                {},
                {},
                {},
              ],
              [
                {
                  text: "DESCRIPCIÓN",
                  style: "tProductsHeader",
                  alignment: "center",
                  fontSize: 7,
                },
                {
                  text: "CANT",
                  style: "tProductsHeader",
                  alignment: "center",
                  fontSize: 7,
                },
                {
                  text: "UM",
                  style: "tProductsHeader",
                  alignment: "center",
                  fontSize: 7,
                },
                {
                  text: "PRECIO",
                  style: "tProductsHeader",
                  alignment: "right",
                  fontSize: 7,
                },
                {
                  text: "TOTAL",
                  style: "tProductsHeader",
                  alignment: "right",
                  fontSize: 7,
                },
              ],
             
              ...filasConceptos,
  
              // Línea final o total
              [
                { text: "", colSpan: 1, border: [false, false, false, false] },
                {
                  text: "Total:",
                  style: "tTotalLabel",
                  alignment: "right",
                  bold: true,
                  fontSize: 7,
                  colSpan: 2,
                },
                {},
                {
                  text: dataSat.Total,
                  style: "tTotalValue",
                  alignment: "right",
                  bold: true,
                  fontSize: 7,
                  colSpan: 2,
                },
                {},
              ],
            ],
          },
  
          layout: {
            hLineWidth: function (i, node) {
              return i === 2 ? 0.5 : 0;
            },
            vLineWidth: function (i, node) {
              return 0;
            },
            hLineColor: function () {
              return "#f2f0f0";
            },
            paddingTop: function (i, node) {
              return i % 2 === 0 ? 10 : 1;
            },
          },
        },
  
        {
          margin: [0, 10, 0, 0],
          table: {
            widths: ["25%", "35%", "15%", "25%"],
            body: [
              [
                { text: "SUBTOTAL: ", style: "tTotals", colSpan: 2, fontSize: 8, },
                {},
                { text: dineroDisabledMask(dataSat.SubTotal), style: "tTotals", colSpan: 2, fontSize: 8, },
                {},
              ],
              ...impuestosTotales,
              [
                { text: "TOTAL: ", style: "tTotals", colSpan: 2, fontSize: 8, },
                {},
                { text: dineroDisabledMask(dataSat.Total), style: "tTotals", colSpan: 2, fontSize: 8, },
                {},
              ],
              [
                {},
                {},
                {},
                {},
              ],
              [
                {
                  text: numeroALetra(dataSat.Total, dataSat.Moneda),
                  style: "tProductsBody",
                  colSpan: 4,
                  fontSize: 8,
                },
                {},
                {},
                {},
              ],
            ],
          },
          layout: "noBorders",
        },

        {
          stack: [
            ...(data.idKiosko
              ? [
                  {
                    qr: `https://biwo.online/kiosko?kiosko_id=${data.idKiosko}`,
                    fit: 115,
                    alignment: "center",
                    eccLevel: "Q",
                    margin: [0, 10, 0, 3],
                  },
                ]
              : []),
              {
                text: data.idKiosko ? `Link: https://biwo.online/kiosko?kiosko_id=${data.idKiosko}` : "",
                style: "text",
                alignment: "center",
                fontSize: 6,
              },
              {
                text: data.idKiosko ? `Folio: ${data.idKiosko}` : "",
                style: "text",
                alignment: "center",
                fontSize: 6,
              },
              {
                text: data.idKiosko ? "Escane para ver su factura." : "",
                style: "text",
                alignment: "center",
              },
          ],
        },
        
  
        {
          text: "Folio Fiscal:",
          style: "text",
          alignment: "justify",
          fontSize: 8,
        },
        {
          text: data.respuestaFactura.data.uuid,
          style: "text",
          fontSize: 6,
        },
        {
          text: " ",
        },
        {
          text: "Sello digital CFDI:",
          style: "text",
          alignment: "justify",
          fontSize: 8,
        },
        {
          text: data.respuestaFactura.data.selloCFDI,
          style: "text",
          fontSize: 6,
        },
        {
          text: " ",
        },
        {
          text: "Numero de serie del Certificado de Sello digital:",
          style: "text",
          alignment: "justify",
          fontSize: 8,
        },
        {
          text: data.respuestaFactura.data.noCertificadoCFDI,
          style: "text",
          fontSize: 6,
        },
        {
          text: " ",
        },
        {
          text: "Numero de serie del Certificado de Sello digital del SAT:",
          style: "text",
          alignment: "justify",
          fontSize: 8,
        },
        {
          text: data.respuestaFactura.data.noCertificadoSAT,
          style: "text",
          fontSize: 6,
        },
        {
          text: " ",
        },
        {
          text: "Cadena Original del complemento de certificación Digital SAT:",
          style: "text",
          alignment: "justify",
          fontSize: 8,
        },
        {
          text: data.respuestaFactura.data.cadenaOriginalSAT,
          style: "text",
          fontSize: 6,
        },
        {
          text: " ",
        },
        {
          text: "Sello Digital del SAT:",
          style: "text",
          alignment: "justify",
          fontSize: 8,
        },
        {
          text: data.respuestaFactura.data.selloSAT,
          style: "text",
          fontSize: 6,
        },
        {
          text: " ",
        },
        {
          text: "Sello Digital del CFDI:",
          style: "text",
          alignment: "justify",
          fontSize: 8,
        },
        {
          text: data.respuestaFactura.data.selloCFDI,
          style: "text",
          fontSize: 6,
        },
        {
          stack: [
            {
              image: qrSat,
              fit: [115, 115],
              alignment: "center",
              margin: [0, 10, 0, 3],
            },
            {
              text: "",
              style: "text",
              alignment: "center",
            },
          ],
        },
      ],
      styles: {
        header: {
          bold: true,
          fontSize: 15,
        },
      },
      defaultStyle: {
        fontSize: 12,
      },
    };
  }else if (!data.factura) {
    return {
      pageSize: { width: 206, height: "auto" }, // 80 mm de ancho, altura ajustable
      pageMargins: [10, 10, 10, 10],
      content: [
        {
          image: logo,
          fit: [141.73, 56.692],
          alignment: "center",
        },
        {
          text: empresa.persona ? empresa.nombre : empresa.nombre + " " + empresa.apellidoPaterno + " " + empresa.apellidoMaterno ,
          style: "header",
          margin: [0, 10, 0, 0],
          alignment: "center",
          fontSize: 12,
        },
        {
          text: empresa.rfc,
          style: "header",
          alignment: "center",
          fontSize: 10,
        },
        {
          text: empresa.calle + " " + empresa.numeroCalle + " " + (empresa.numeroInterior ? empresa.numeroInterior : " ") ,
          style: "header",
          alignment: "center",
          fontSize: 10,
        },
        {
          text: empresa.colonia.nombre + " " + empresa.colonia.cp + " " + empresa.municipio.nombre + " " + empresa.municipio.nombre + " " + empresa.pais.nombre ,
          style: "header",
          alignment: "center",
          fontSize: 10,
        },
        {
          text: `Regimen Fiscal: (${empresa.regimenFiscal.c_RegimenFiscal}) ${empresa.regimenFiscal.descripcion}`, 
          style: "header",
          alignment: "center",
          fontSize: 10,
        },
        {
          text: "Tipo De Comprobante: (I) Ingreso", 
          style: "header",
          alignment: "center",
          fontSize: 10,
        },
  
        { text: `Nº Remision - ${dataSat.Serie} - ${dataSat.Folio}`, style: "header", margin: [0, 10, 0, 2.25], fontSize: 10, },
        {
          margin: [0, 10, 0, 0],
          table: {
            widths: ["100%"], // Solo dos columnas
            body: [
              [
                { text: cliente.nombre + " " + cliente.apellidoPaterno + " " + cliente.apellidoMaterno, style: "tHeaderValue", fontSize: 10, },
              ],
              [
                { text: cliente.rfc, style: "tHeaderValue", fontSize: 10, },
              ],
              [
                { text: `(${dataSat.formaDePago.c_FormaPago}) ${dataSat.formaDePago.descripcion} `, style: "tHeaderValue", fontSize: 10, },
              ],
              [
                {
                  text:  dataSat.MetodoPago === "PUE" ? "PUE - Pago en una sola exhibición" : "PPD - Pago en parcialidades o diferido ",
                  style: "tHeaderValue",
                  fontSize: 10,
                },
              ],
              [
                { text: moment(dataSat.Fecha).format('D [de] MMMM [de] YYYY'), style: "tHeaderValue", fontSize: 10, },
              ],
            ],
          },
          layout: "noBorders",
        },
  
        {
          margin: [0, 10, 0, 0],
          table: {
            widths: ["32%", "16%", "12%", "20%", "20%"], // Ajuste de proporciones
            headerRows: 2,
            body: [
              // Encabezado de la tabla
              [
                {
                  text: "TICKET DE COMPRA",
                  colSpan: 5,
                  style: "tTitleHeader",
                  alignment: "center",
                  fontSize: 12,
                  margin: [0, 5, 0, 5],
                },
                {},
                {},
                {},
                {},
              ],
              [
                {
                  text: "CÓDIGO - DESCRIPCIÓN",
                  colSpan: 5,
                  style: "tProductsHeader",
                  alignment: "center",
                  fontSize: 12,
                  fillColor: "#f2f2f2", // Color de fondo
                  margin: [0, 5, 0, 5],
                },
                {},
                {},
                {},
                {},
              ],
              [
                {
                  text: "DESCRIPCIÓN",
                  style: "tProductsHeader",
                  alignment: "center",
                  fontSize: 7,
                },
                {
                  text: "CANT",
                  style: "tProductsHeader",
                  alignment: "center",
                  fontSize: 7,
                },
                {
                  text: "UM",
                  style: "tProductsHeader",
                  alignment: "center",
                  fontSize: 7,
                },
                {
                  text: "PRECIO",
                  style: "tProductsHeader",
                  alignment: "right",
                  fontSize: 7,
                },
                {
                  text: "TOTAL",
                  style: "tProductsHeader",
                  alignment: "right",
                  fontSize: 7,
                },
              ],
              // Primera fila de datos
              ...filasConceptos,
              [
                { text: "", colSpan: 1, border: [false, false, false, false] },
                {
                  text: "Total:",
                  style: "tTotalLabel",
                  alignment: "right",
                  bold: true,
                  fontSize: 7,
                  colSpan: 2,
                },
                {},
                {
                  text: dataSat.Total,
                  style: "tTotalValue",
                  alignment: "right",
                  bold: true,
                  fontSize: 7,
                  colSpan: 2,
                },
                {},
              ],
            ],
          },
  
          layout: {
            hLineWidth: function (i, node) {
              return i === 2 ? 0.5 : 0;
            },
            vLineWidth: function (i, node) {
              return 0;
            },
            hLineColor: function () {
              return "#f2f0f0";
            },
            paddingTop: function (i, node) {
              return i % 2 === 0 ? 10 : 1;
            },
          },
        },
  
        {
          margin: [0, 10, 0, 0],
          table: {
            widths: ["25%", "35%", "15%", "25%"],
            body: [
              [
                { text: "SUBTOTAL: ", style: "tTotals", colSpan: 2, fontSize: 8, },
                {},
                { text: dineroDisabledMask(dataSat.SubTotal), style: "tTotals", colSpan: 2, fontSize: 8, },
                {},
              ],
              ...impuestosTotales,
              [
                { text: "TOTAL: ", style: "tTotals", colSpan: 2, fontSize: 8, },
                {},
                { text: dineroDisabledMask(dataSat.Total), style: "tTotals", colSpan: 2, fontSize: 8, },
                {},
              ],
              [
                {},
                {},
                {},
                {},
              ],
              [
                {
                  text: numeroALetra(dataSat.Total, dataSat.Moneda),
                  style: "tProductsBody",
                  colSpan: 4,
                  fontSize: 8,
                },
                {},
                {},
                {},
              ],
            ],
          },
          layout: "noBorders",
        },
        {
          text: " ",
        },
        
        {
          stack: [
            ...(data.idKiosko
              ? [
                  {
                    qr: `https://biwo.online/kiosko?kiosko_id=${data.idKiosko}`,
                    fit: 115,
                    alignment: "center",
                    eccLevel: "Q",
                    margin: [0, 10, 0, 3],
                  },
                  
                ]
              : []),
            {
              text: data.idKiosko ? `Link: https://biwo.online/kiosko?kiosko_id=${data.idKiosko}` : "",
              style: "text",
              alignment: "center",
              fontSize: 6,
            },
            {
              text: data.idKiosko ? `Folio: ${data.idKiosko}` : "",
              style: "text",
              alignment: "center",
              fontSize: 6,
            },
            {
              text: data.idKiosko ? "Escane para reales su factura." : "",
              style: "text",
              alignment: "center",
            },
          ],
        }
        
      ],
      styles: {
        header: {
          bold: true,
          fontSize: 15,
        },
      },
      defaultStyle: {
        fontSize: 12,
      },
    };
  }
  
};

export default ticketPdf;
