import React, { Component } from "react";
import {
  message,
  PageHeader,
  Spin,
  Row,
  Col,
  Divider,
  Select,
  Form as FormInitial,
  notification,
  Space,
  Upload,
} from "antd";
import {
  dineroDisabledMask,
  Form,
  FormItem,
  InputNumberItem,
  SelectItem,
  DatePickerItem,
  round2Decimals,
  ButtonItem,
  InputItem,
  NotificacionPolizas,
  round6Decimals,
  ErrorItem
} from "../../../../Components/Items";
import axios from "axios";
import moment from "moment";
import "moment/locale/es";
import { connect } from "react-redux";
import { UploadOutlined } from "@ant-design/icons";
import xmlToJson from "../../../../Components/xmlToJson";
import decodeHTMLEntities from "../../../../Components/decodeHtmlEntities";

const { Option } = Select;

moment.locale("es");

class RecibirFactura extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    datosOrdenDeCompra: undefined,
    objetoInsumos: (
      <Option disabled value={0} key={0}>
      Seleccione un proveedor primero
    </Option>
    ),
    todasInsumos: [],
    id: this.props.location.state.id,
    empresaRfc: null,
  };
  componentDidMount() {
    this.consultarTodo();
    if (this.props?.location?.state?.fileName && this.props?.location?.state?.fileData) {
      this.leerXmlData(this.props.location.state);
    }
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "proveedores/selectProveedores",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasProveedores: res.data });
          this.setState({
            objetoProveedores: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.stringBuscar}
                </Option>
              );
            }),
          });
        }
      });
      let proveedorId
    await axios
      .post(
        "ordenesdecompra/datosOrdenPRecibirP",
        {
          empresaId: this.props.empresaId,
          ordenDeCompraId: this.state.id,
        },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
          message.error(
            "Error no se encontro informacion de la orden de compra"
          );
        } else {
          this.setState({ datosOrdenDeCompra: res.data[0] });
          this.formRef.current.setFieldsValue({
            insumos: res.data[0].insumos.map((value) => {
              let totalDescuento = value.precioUnitario * (value.descuento / 100)
              return {
                ...value,
                recibidoFacturas: value.recibidoFacturas ? value.recibidoFacturas : 0,
                precioUnitarioAjustado:
                  value.precioUnitario - totalDescuento,
              };
            }),
          });
          proveedorId = res.data[0].proveedorId
          this.formRef.current.setFieldsValue({diasCredito: res.data[0].proveedor.diasCredito || 0, fechaPago: moment().add(res.data[0].proveedor.diasCredito,'days'), fechaFactura: moment()})
        }
      });

      await axios
      .post(
        "insumos/selectInsumosCompraYRelacionesProveedor",
        { empresaId: this.props.empresaId, proveedorId },
        {
          headers: this.props.headersToken,
        }
        )
        .then((res) => {
          if (res.data.status === 404) {
          } else {
            this.setState({ todasInsumos: res.data });
            this.setState({
              objetoInsumos: res.data.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                  {value.stringBuscar}
                </Option>
              );
            }),
          });
        }
      });

      const responseConfiguracion = axios
      .post(
        "empresas/selectConfiguracionGeneralParaFacturas",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )

      const responses = await Promise.all([
        responseConfiguracion,
      ])
  
      let newState = {}
  
      if (responses[0].data.status === 404) {
        message.error("Error no se encontro configuracion de esta empresa");
      } else {
        newState.empresaRfc = responses[0].data.rfc;
        newState.ajusteMax = responses[0].data.ajusteMax
      }
    this.setState({ loading: false, ...newState });
  }
  onFinishFailed(e) {
    ErrorItem(e);
  }
  onFinish(values) {
    let recibirTodos = 0;
    values.insumos = values.insumos.map(value => {
      let recibir = value.recibir ? value.recibir : 0
      recibirTodos += recibir
      return {...value, recibir}
    })
    if(recibirTodos === 0) return message.info('Se neceitan cantidades para poder hacer esta operación')
    this.setState({ loading: true });
    let arrayInsumos = values.insumos;
    let proveedorId =
      this.state.datosOrdenDeCompra.proveedorId;
    let cuentasProveedor = 0;
    let subtotal = 0;
    let arrayImpuestos = [];
    let arrayRetenciones = [];
    let total = 0;
    let arrayInsumosFactura = arrayInsumos.map((valueInsumo, indexInsumo) => {
      const insumo = this.state.todasInsumos.find(
        (valueIn) => valueIn._id === valueInsumo.insumoId
      );
      let valuePrecioUnitario = valueInsumo.precioUnitario;
      let valueCantidad = valueInsumo.recibir;
      let valueDescuento = valueInsumo.descuento;

      //1
      let subtotalInsumoSD = round2Decimals(valuePrecioUnitario * valueCantidad)
      //2
      let totalDescuento = round2Decimals(subtotalInsumoSD * (valueDescuento / 100))
      //3
      let subtotalInsumo = round2Decimals(subtotalInsumoSD - totalDescuento)
      subtotal += subtotalInsumo;
      let totalImpuestosInsumo = 0;
      insumo.impuestos.map(
        //4
        (valueImpuesto) => {
          let descripcionFacturaImpuesto = valueImpuesto.descripcionFactura;
          let totalImpuesto
          if(valueImpuesto.tasaCuota === 1){
            totalImpuesto = round6Decimals(arrayInsumos[indexInsumo].recibir *arrayInsumos[indexInsumo].precioUnitarioAjustado*(valueImpuesto.monto/100))
          }else if(valueImpuesto.tasaCuota === 2){
            totalImpuesto = round6Decimals((arrayInsumos[indexInsumo].recibir *arrayInsumos[indexInsumo].precioUnitarioAjustado+totalImpuestosInsumo)*(valueImpuesto.monto/100))
          }else if(valueImpuesto.tasaCuota === 3){
            totalImpuesto	= round6Decimals(arrayInsumos[indexInsumo].recibir*valueImpuesto.monto)
          }else if(valueImpuesto.tasaCuota === 4){
            totalImpuesto	= round6Decimals(arrayInsumos[indexInsumo].impuestos*(arrayInsumos[indexInsumo].recibir/arrayInsumos[indexInsumo].cantidad))
          }else if(valueImpuesto.tasaCuota === 5){
            totalImpuesto = 0
          }
          let totalImpuestosImpuesto = totalImpuesto;
          let index = arrayImpuestos.findIndex(
            (valueImpuestosAnterios) =>
              valueImpuestosAnterios.descripcionFactura ===
              descripcionFacturaImpuesto
          );
          totalImpuestosInsumo += totalImpuestosImpuesto;
          if (index !== -1) {
            let newItem = {
              totalImpuestos:
                arrayImpuestos[index].totalImpuestos +
                totalImpuestosImpuesto,
              descripcionFactura:
                descripcionFacturaImpuesto,
            };
            arrayImpuestos.splice(index, 1, newItem);
          } else {
            arrayImpuestos.push({
              totalImpuestos: totalImpuestosImpuesto,
              descripcionFactura:
                descripcionFacturaImpuesto,
            });
          }
          return null;
        }
      );
      if (proveedorId) {
        const proveedor = this.state.todasProveedores.find(
          (valueP) => valueP._id === proveedorId
        );
        let totalRetencionesInsumo = 0;

        proveedor.retenciones.map((valueRetencion) => {
              //7
              let totalRetencion;
              let descripcionFacturaRetencion =
              valueRetencion.descripcionFactura;

              if(valueRetencion.tasaCuota === 1){
                totalRetencion = round6Decimals(arrayInsumos[indexInsumo].recibir *arrayInsumos[indexInsumo].precioUnitarioAjustado*(valueRetencion.monto/100))
              }else if(valueRetencion.tasaCuota === 2){
                totalRetencion = round6Decimals((arrayInsumos[indexInsumo].recibir *arrayInsumos[indexInsumo].precioUnitarioAjustado+totalRetencionesInsumo)*(valueRetencion.monto/100))
              }
              let index = arrayRetenciones.findIndex(
                (valueretencionesAnterios) =>
                  valueretencionesAnterios.descripcionFactura ===
                  descripcionFacturaRetencion
              );
              totalRetencionesInsumo += totalRetencion;
              cuentasProveedor += totalRetencion;
              if (index !== -1) {
                let newItem = {
                  totalRetenciones:
                    arrayRetenciones[index].totalRetenciones +
                    totalRetencion,
                  descripcionFactura:
                  descripcionFacturaRetencion,
                };
                arrayRetenciones.splice(index, 1, newItem);
              } else {
                arrayRetenciones.push({
                  totalRetenciones: totalRetencion,
                  descripcionFactura:
                  descripcionFacturaRetencion,
                });
              }
            return null
    })

        insumo.retenciones.map((valueRetencion) => {
          if((valueRetencion.aplicaCompra === 1 && proveedor.persona === false) || (valueRetencion.aplicaCompra === 2 && proveedor.persona === true) || valueRetencion.aplicaCompra === 3){
              //7
                let totalRetencion;
                let descripcionFacturaRetencion =
                valueRetencion.descripcionFactura;

                if(valueRetencion.tasaCuota === 1){
                  totalRetencion = round6Decimals(arrayInsumos[indexInsumo].recibir *arrayInsumos[indexInsumo].precioUnitarioAjustado*(valueRetencion.monto/100))
                }else if(valueRetencion.tasaCuota === 2){
                  totalRetencion = round6Decimals((arrayInsumos[indexInsumo].recibir *arrayInsumos[indexInsumo].precioUnitarioAjustado+totalRetencionesInsumo)*(valueRetencion.monto/100))
                }
                let index = arrayRetenciones.findIndex(
                  (valueretencionesAnterios) =>
                    valueretencionesAnterios.descripcionFactura ===
                    descripcionFacturaRetencion
                );
                totalRetencionesInsumo += totalRetencion;
                cuentasProveedor += totalRetencion;
                if (index !== -1) {
                  let newItem = {
                    totalRetenciones:
                      arrayRetenciones[index].totalRetenciones +
                      totalRetencion,
                    descripcionFactura:
                    descripcionFacturaRetencion,
                  };
                  arrayRetenciones.splice(index, 1, newItem);
                } else {
                  arrayRetenciones.push({
                    totalRetenciones: totalRetencion,
                    descripcionFactura:
                    descripcionFacturaRetencion,
                  });
                }
              }
              return null
        })
      }
      //5
      let totalInsumo =
        round2Decimals(subtotalInsumo + totalImpuestosInsumo);
      total += subtotalInsumo;
      return {...valueInsumo, cantidad: valueInsumo.recibir, subtotal: subtotalInsumo, total: totalInsumo};
    });
    subtotal += values.ajusteSubtotal
    total += values.ajusteSubtotal
    arrayImpuestos = arrayImpuestos.map(valueImpuesto => {
      let ajusteImpuesto = values['ajuste'+valueImpuesto.descripcionFactura]
      total += round2Decimals(valueImpuesto.totalImpuestos + ajusteImpuesto)
      return {
        ...valueImpuesto,
        ajusteImpuesto,
        totalImpuestos: round2Decimals(valueImpuesto.totalImpuestos + ajusteImpuesto),
      }
    })
    arrayRetenciones = arrayRetenciones.map(valueRetencion => {
      let ajusteRetencion = values['ajuste'+valueRetencion.descripcionFactura]
      cuentasProveedor += ajusteRetencion
      return {
        ...valueRetencion,
        ajusteRetencion,
        totalRetenciones: (valueRetencion.totalRetenciones + ajusteRetencion),
      }
    })
    axios
      .post(
        "ordenesdecompra/recibirFactura",
        {
          ...values,
          insumos: arrayInsumosFactura,
          empresaId: this.props.empresaId,
          ordenDeCompraId: this.state.id,
          total: round2Decimals(total),
          subtotal: round2Decimals(subtotal),
          cuentasProveedor: round2Decimals(cuentasProveedor),
          totalPagar: round2Decimals(total - cuentasProveedor),
          arrayImpuestos,
          arrayRetenciones
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success(
            "Se ha recibido una factura de la orden de compra: " + res.data.codigo
          );
          NotificacionPolizas(res.data.polizasId)
          this.formRef.current.resetFields();
          this.atras(res.data.codigo);
        } else if (res.data.success === 1) {
          message.error("No se puede recibir la factura");
        } else {
          message.error("Hubo un error y no se recibio la factura");
        }
      });
  }
  atras() {
    this.props.history.goBack();
  }
  onChangeUUID(value){
    axios
    .post(
      "facturas/checarUUID",
      { uuid: value, empresaId: this.props.empresaId },
      { headers: this.props.headersToken }
    )
    .then((res) => {
      if (res.data.existeUUID === 1) {
        const key = `open${Date.now()}`
        notification.warning({
          message: 'Este uuid ya existe en una factura previa',
          description: '¿Quieres continuar?',
          key: key,
          btn: (
            <Space>
              <ButtonItem style={{marginRight: 10}} type='primary' onClick={() => notification.close(key)}>Si</ButtonItem>
              <ButtonItem style={{marginRight: 10}} type='primary' onClick={() => {
                this.props.history.goBack()
                notification.close(key)
              }} danger>No</ButtonItem>
            </Space>
          )
        })
      } else if (res.data.existeUUID === 0) {
      }
    });
  }

  async leerXml(e) {
    this.setState({ loading: true });
    if (e.file.type === "text/xml") {
      var reader = new FileReader();
      reader.onload = async (event) => {
        let insumosSinRegistrar = 0;
        let insumos = [];
        let proveedorId;
        let fechaFactura;
        let uuid;
        let codigoFactura;
        const hola = new xmlToJson().parseFromString(event.target.result);
        const comprobante = hola?.attributes;
        const emisor = hola.children.find(
          (valueXml) => valueXml.name === "cfdi:Emisor"
        )?.attributes;
        const complemento = hola.children.find(
          (valueXml) => valueXml.name === "cfdi:Complemento"
        );
        const timbre = complemento.children.find(
          (valueXml) => valueXml.name === "tfd:TimbreFiscalDigital"
        )?.attributes;
        const receptor = hola.children.find(
          (valueXml) => valueXml.name === "cfdi:Receptor"
        )?.attributes;
        const conceptos = hola.children.find(
          (valueXml) => valueXml.name === "cfdi:Conceptos"
        )?.children;

        if (
          receptor.Rfc?.toLowerCase() === this.state.empresaRfc?.toLowerCase()
        ) {

          if(this.state.datosOrdenDeCompra.proveedor.rfc.toLowerCase() !== emisor.Rfc?.toLowerCase()){
            this.setState({
              loading: false,
            });
            return notification.warning({
              message: "El proveedor del xml no es el mismo que el de la orden de compra",
            });
          }else{
            proveedorId = this.state.datosOrdenDeCompra.proveedorId
          }

        } else {
          this.setState({
            archivosXml: [
              {
                ...e.file,
                name: e.file.name,
                status: "success",
              },
            ],
            archivo: e.file,
            loading: false,
          });
          return message.error(
            "El rfc del receptor no es igual al de la empresa"
          );
        }

        await Promise.all(
          conceptos.map(async (valueConcepto) => {
            const attr = valueConcepto.attributes;
            let totalImpuestos = 0;
            valueConcepto.children
              .find((valueTodos) => valueTodos.name === "cfdi:Impuestos")
              ?.children.find(
                (valueTodos) => valueTodos.name === "cfdi:Traslados"
              )
              ?.children.map((valueImpuesto) => {
                const attrImpuestos = valueImpuesto.attributes;
                totalImpuestos += attrImpuestos.Importe;
                return null;
              });
            await axios
              .post(
                "insumos/buscarRelacion",
                {
                  empresaId: this.props.empresaId,
                  proveedorId: proveedorId,
                  descripcion: decodeHTMLEntities(attr.Descripcion),
                  claveProdServ: decodeHTMLEntities(attr.ClaveProdServ),
                  claveUnidad: decodeHTMLEntities(attr.ClaveUnidad),
                  codigo: decodeHTMLEntities(attr.NoIdentificacion),
                },
                {
                  headers: this.props.headersToken,
                }
              )
              .then((res) => {
                if (res.data.status === 404) {
                  insumosSinRegistrar += 1;
                  insumos.push({
                    precioUnitario: Number(attr.ValorUnitario),
                    cantidad: Number(attr.Cantidad),
                    descuento: attr.Descuento
                      ? Math.round((attr.Descuento / attr.Importe) * 10000) /
                        100
                      : 0,
                    impuestos: totalImpuestos,
                  });
                } else {
                  insumos.push({
                    insumoId: res.data._id,
                    precioUnitario: Number(attr.ValorUnitario),
                    cantidad: Number(attr.Cantidad),
                    descuento: attr.Descuento
                      ? Math.round((attr.Descuento / attr.Importe) * 10000) /
                        100
                      : 0,
                    impuestos: totalImpuestos,
                  });
                }
              });
            return null;
          })
        );

        
        if (insumosSinRegistrar > 0) {
          notification.warning({
            message: insumosSinRegistrar + " insumos no estan registrados",
          });
          return this.props.history.push("/panel/leerXml", {
            fileName: e.file.name,
            fileData: JSON.stringify(hola),
            origin: "/panel/recibirFacturaOrdenDeCompra",
            state: {id: this.state.id}
          });
        }

        //Checar si todos los insumos estan en la orden de compra

        let nuevosInsumosRecibir = await this.formRef.current.getFieldValue('insumos')

        for (const valueInsumo of insumos) {
          const indexInsumoODC = nuevosInsumosRecibir.findIndex((valueI) => valueI.insumoId.toString() === valueInsumo.insumoId.toString())
          if(indexInsumoODC === -1){
            this.setState({
              archivosXml: [
                {
                  ...e.file,
                  name: e.file.name,
                  status: "success",
                },
              ],
              archivo: e.file,
              loading: false,
            });
            return notification.warning({
              message: "Los insumos nos coinciden con la orden de compra",
            });
          }else{
            if(round2Decimals(nuevosInsumosRecibir[indexInsumoODC].precioUnitario) === round2Decimals(valueInsumo.precioUnitario)){
              const porRecibir = round2Decimals(nuevosInsumosRecibir[indexInsumoODC].cantidad - nuevosInsumosRecibir[indexInsumoODC].recibidoFacturas)
              if(porRecibir < round2Decimals(valueInsumo.cantidad)){
                this.setState({
                  archivosXml: [
                    {
                      ...e.file,
                      name: e.file.name,
                      status: "success",
                    },
                  ],
                  archivo: e.file,
                  loading: false,
                });
                return notification.warning({
                  message: "La cantidad de la factura excede la cantidad de la orden de compra",
                });
              }else{
                nuevosInsumosRecibir[indexInsumoODC].recibir = valueInsumo.cantidad
              }
            }else{
              this.setState({
                archivosXml: [
                  {
                    ...e.file,
                    name: e.file.name,
                    status: "success",
                  },
                ],
                archivo: e.file,
                loading: false,
              });
              return notification.warning({
                message: "Los precios de los insumos nos coinciden con la orden de compra",
              });
            }
          }
        }

        fechaFactura = moment(comprobante.Fecha);
        codigoFactura =
          (comprobante.Serie ? comprobante.Serie : "") +
          " - " +
          (comprobante.Folio ? comprobante.Folio : "");
        uuid = timbre.UUID;

        if (uuid) {
          this.onChangeUUID(uuid);
        }

        await this.formRef.current.setFieldsValue({
          insumos: nuevosInsumosRecibir,
          fechaFactura,
          codigoFactura,
          uuid,
        });

        this.setState({
          archivosXml: [
            {
              ...e.file,
              name: e.file.name,
              status: "success",
            },
          ],
          archivo: e.file,
          loading: false,
        });
      };

      reader.readAsText(e.file);
    } else {
      message.error("El archivo debe ser un archivo de xml");
      this.setState({ loading: false });
    }
  }

  async leerXmlData(state) {
    await this.consultarTodo();
    this.setState({ loading: true });
    let insumosSinRegistrar = 0;
    let insumos = [];
    let proveedorId;
    let fechaFactura;
    let uuid;
    let codigoFactura;
    const hola = JSON.parse(state.fileData);
    const comprobante = hola?.attributes;
    const emisor = hola.children.find(
      (valueXml) => valueXml.name === "cfdi:Emisor"
    )?.attributes;
    const complemento = hola.children.find(
      (valueXml) => valueXml.name === "cfdi:Complemento"
    );
    const timbre = complemento.children.find(
      (valueXml) => valueXml.name === "tfd:TimbreFiscalDigital"
    )?.attributes;
    const receptor = hola.children.find(
      (valueXml) => valueXml.name === "cfdi:Receptor"
    )?.attributes;
    const conceptos = hola.children.find(
      (valueXml) => valueXml.name === "cfdi:Conceptos"
    )?.children;

    if (
      receptor.Rfc?.toLowerCase() === this.state.empresaRfc?.toLowerCase()
    ) {

      if(this.state.datosOrdenDeCompra.proveedor.rfc.toLowerCase() !== emisor.Rfc?.toLowerCase()){
        this.setState({
          loading: false,
        });
        return notification.warning({
          message: "El proveedor del xml no es el mismo que el de la orden de compra",
        });
      }else{
        proveedorId = this.state.datosOrdenDeCompra.proveedorId
      }

    } else {
      this.setState({
        archivosXml: [
          {
            name: state.fileName,
            status: "success",
          },
        ],
        archivo: state.fileData,
        loading: false,
      });
      return message.error(
        "El rfc del receptor no es igual al de la empresa"
      );
    }

    await Promise.all(
      conceptos.map(async (valueConcepto) => {
        const attr = valueConcepto.attributes;
        let totalImpuestos = 0;
        valueConcepto.children
          .find((valueTodos) => valueTodos.name === "cfdi:Impuestos")
          ?.children.find(
            (valueTodos) => valueTodos.name === "cfdi:Traslados"
          )
          ?.children.map((valueImpuesto) => {
            const attrImpuestos = valueImpuesto.attributes;
            totalImpuestos += attrImpuestos.Importe;
            return null;
          });
        await axios
          .post(
            "insumos/buscarRelacion",
            {
              empresaId: this.props.empresaId,
              proveedorId: proveedorId,
              descripcion: decodeHTMLEntities(attr.Descripcion),
              claveProdServ: decodeHTMLEntities(attr.ClaveProdServ),
              claveUnidad: decodeHTMLEntities(attr.ClaveUnidad),
              codigo: decodeHTMLEntities(attr.NoIdentificacion),
            },
            {
              headers: this.props.headersToken,
            }
          )
          .then((res) => {
            if (res.data.status === 404) {
              insumosSinRegistrar += 1;
              insumos.push({
                precioUnitario: Number(attr.ValorUnitario),
                cantidad: Number(attr.Cantidad),
                descuento: attr.Descuento
                  ? Math.round((attr.Descuento / attr.Importe) * 10000) /
                    100
                  : 0,
                impuestos: totalImpuestos,
              });
            } else {
              insumos.push({
                insumoId: res.data._id,
                precioUnitario: Number(attr.ValorUnitario),
                cantidad: Number(attr.Cantidad),
                descuento: attr.Descuento
                  ? Math.round((attr.Descuento / attr.Importe) * 10000) /
                    100
                  : 0,
                impuestos: totalImpuestos,
              });
            }
          });
        return null;
      })
    );

    
    if (insumosSinRegistrar > 0) {
      notification.warning({
        message: insumosSinRegistrar + " insumos no estan registrados",
      });
      return this.props.history.push("/panel/leerXml", {
        fileName: state.fileName,
        fileData: JSON.stringify(hola),
        origin: "/panel/recibirFacturaOrdenDeCompra",
        state: {id: this.state.id}
      });
    }

    //Checar si todos los insumos estan en la orden de compra

    let nuevosInsumosRecibir = await this.formRef.current.getFieldValue('insumos')

    for (const valueInsumo of insumos) {
      const indexInsumoODC = nuevosInsumosRecibir.findIndex((valueI) => valueI.insumoId.toString() === valueInsumo.insumoId.toString())
      if(indexInsumoODC === -1){
        this.setState({
          archivosXml: [
            {
              name: state.fileName,
              status: "success",
            },
          ],
          archivo: state.fileData,
          loading: false,
        });
        return notification.warning({
          message: "Los insumos nos coinciden con la orden de compra",
        });
      }else{
        if(round2Decimals(nuevosInsumosRecibir[indexInsumoODC].precioUnitario) === round2Decimals(valueInsumo.precioUnitario)){
          const porRecibir = round2Decimals(nuevosInsumosRecibir[indexInsumoODC].cantidad - nuevosInsumosRecibir[indexInsumoODC].recibidoFacturas)
          if(porRecibir < valueInsumo.cantidad){
            this.setState({
              archivosXml: [
                {
                  name: state.fileName,
                  status: "success",
                },
              ],
              archivo: state.fileData,
              loading: false,
            });
            return notification.warning({
              message: "La cantidad de la factura excede la cantidad de la orden de compra",
            });
          }else{
            nuevosInsumosRecibir[indexInsumoODC].recibir = valueInsumo.cantidad
          }
        }else{
          this.setState({
            archivosXml: [
              {
                name: state.fileName,
                status: "success",
              },
            ],
            archivo: state.fileData,
            loading: false,
          });
          return notification.warning({
            message: "Los precios de los insumos nos coinciden con la orden de compra",
          });
        }
      }
    }

    fechaFactura = moment(comprobante.Fecha);
    codigoFactura =
      (comprobante.Serie ? comprobante.Serie : "") +
      " - " +
      (comprobante.Folio ? comprobante.Folio : "");
    uuid = timbre.UUID;

    if (uuid) {
      this.onChangeUUID(uuid);
    }

    await this.formRef.current.setFieldsValue({
      insumos: nuevosInsumosRecibir,
      fechaFactura,
      codigoFactura,
      uuid,
    });

    this.setState({
      archivosXml: [
        {
          name: state.fileName,
          status: "success",
        },
      ],
      archivo: state.fileData,
      loading: false,
    });
  }

  render() {
    return (
      <>
        <PageHeader
          onBack={this.atras.bind(this)}
          title={"Recibir Factura sobre Orden de Compra "+((this.state?.datosOrdenDeCompra?.serieOrdenCompra ? (this.state.datosOrdenDeCompra.serieOrdenCompra + ' - ') : '') + this?.state?.datosOrdenDeCompra?.numero)}
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={[
            <h1>
              Fecha:{" "}
              {moment(this.state.datosOrdenDeCompra?.createdAt).format("LL")}
            </h1>
          ,
          <Upload
            showUploadList={{
              showRemoveIcon: false,
              showPreviewIcon: false,
            }}
            fileList={this.state.archivosXml}
            customRequest={this.leerXml.bind(this)}
            accept=".xml"
          >
            <ButtonItem blanco 
              type="primary" icon={<UploadOutlined />}>
              Leer Xml
            </ButtonItem>
          </Upload>
        ]}
        />
        <div style={{ backgroundColor: "white", padding: 20 }}>
          <Spin spinning={this.state.loading}>
            <Form
              ref={this.formRef}
              name="recibirInsumosOrdenDeCompra"
              layout
              bottom={
                <ButtonItem
                  type="primary"
                  htmlType="submit"
                  style={{ display: "block", margin: "0 0 0 auto" }}
                >
                  Recibir
                </ButtonItem>
              }
              onFinish={this.onFinish.bind(this)}
              onFinishFailed={this.onFinishFailed}
            >
              <Row>
                <Col span={12}>
                  <FormItem
                    name="codigoFactura"
                    label="Codigo Factura"
                    margin
                    pattern
                  >
                    <InputItem placeholder="Codigo Factura" />
                  </FormItem>
                  <FormItem
                    name="descripcion"
                    label="Descripcion"
                    margin
                    pattern
                  >
                    <InputItem placeholder="Descripcion" />
                  </FormItem>
                  <FormItem name="uuid" label="UUID" margin pattern required>
                    <InputItem onChange={(e) => this.onChangeUUID(e.target.value)} placeholder="UUID" />
                  </FormItem>
                </Col>
                <Col span={12}>
                <FormItem
                    label="Fecha de Factura"
                    name="fechaFactura"
                    required
                    margin
                  >
                    <DatePickerItem
                      placeholder="Fecha de Factura"
                      allowClear={false}
                      onChange={(value) => {
                        const fechaFactura = moment(value)
                        const diasCredito = this.formRef.current.getFieldValue('diasCredito')
                        this.formRef.current.setFieldsValue({fechaPago: fechaFactura.add(diasCredito, 'days')})
                      }}
                    />
                  </FormItem>
                  <FormItem
                        label="Dias de credito"
                        name="diasCredito"
                        required
                        numberCero
                        margin
                      >
                        <InputNumberItem placeholder="Dias de Credito" onChange={(value) => {
                          const fechaFactura = this.formRef.current.getFieldValue('fechaFactura')
                          this.formRef.current.setFieldsValue({fechaPago: moment(fechaFactura).add(value, 'days')})
                        }} />
                      </FormItem>
                  <FormItem
                    label="Fecha probable Pago"
                    name="fechaPago"
                    required
                    margin
                  >
                    <DatePickerItem
                      placeholder="Fecha probable Pago"
                      allowClear={false}
                      onChange={(value) => {
                        const fechaFactura = moment(this.formRef.current.getFieldValue('fechaFactura'))
                        let fecha = value
                        let diferencia = fecha.diff(fechaFactura, 'days')+1
                        this.formRef.current.setFieldsValue({diasCredito: diferencia})
                      }}
                    />
                  </FormItem>
                </Col>
              </Row>
              <Divider style={{ marginBottom: 10, marginTop: 5 }} />
              <Row>
                <Col span={6}>
                  <h1 style={{ margin: 0 }}>
                    Proyecto: {this.state.datosOrdenDeCompra?.proyecto.nombre}
                  </h1>
                  <p>
                    Descripcion:{" "}
                    {this.state.datosOrdenDeCompra?.proyecto.descripcion}
                  </p>
                </Col>
                <Col span={6}>
                  <h1 style={{ margin: 0 }}>Dirección de envio</h1>
                  {this.state.datosOrdenDeCompra?.proyecto.calle ? (
                    <>
                      <p style={{ margin: 0 }}>
                        Calle: {this.state.datosOrdenDeCompra?.proyecto.calle}
                      </p>
                      <p style={{ margin: 0 }}>
                        Numero:{" "}
                        {this.state.datosOrdenDeCompra?.proyecto.numeroCalle}
                      </p>
                    </>
                  ) : (
                    <>
                      <p style={{ margin: 0 }}>
                        Marca: {this.state.datosOrdenDeCompra?.proyecto.marca}
                      </p>
                      <p style={{ margin: 0 }}>
                        Modelo: {this.state.datosOrdenDeCompra?.proyecto.modelo}
                      </p>
                      <p style={{ margin: 0 }}>
                        Placas: {this.state.datosOrdenDeCompra?.proyecto.placas}
                      </p>
                    </>
                  )}
                </Col>
                <Col span={6}>
                  <h1 style={{ margin: 0 }}>
                    Proveedor:{" "}
                    {this.state.datosOrdenDeCompra?.proveedor.nombre
                      ? this.state.datosOrdenDeCompra?.proveedor.nombre +
                        " " +
                        this.state.datosOrdenDeCompra?.proveedor
                          .apellidoPaterno +
                        " " +
                        this.state.datosOrdenDeCompra?.proveedor.apellidoMaterno
                      : this.state.datosOrdenDeCompra?.proveedor.razonSocial}
                  </h1>
                  <p style={{ margin: 0 }}>
                    Rfc: {this.state.datosOrdenDeCompra?.proveedor.rfc}
                  </p>
                  <p style={{ margin: 0 }}>
                    Correo: {this.state.datosOrdenDeCompra?.proveedor.correo}
                  </p>
                  <p style={{ margin: 0 }}>
                    Telefono:{" "}
                    {this.state.datosOrdenDeCompra?.proveedor.telefono}
                  </p>
                  <p style={{ margin: 0 }}>
                    Celular: {this.state.datosOrdenDeCompra?.proveedor.celular}
                  </p>
                </Col>
                <Col span={6}>
                  <p style={{ margin: 0 }}>
                    Contacto:{" "}
                    {this.state.datosOrdenDeCompra?.proveedor.nombreContacto}
                  </p>
                  <p style={{ margin: 0 }}>
                    Tel. Contacto:{" "}
                    {this.state.datosOrdenDeCompra?.proveedor.telefonoContacto}
                  </p>
                  <p style={{ margin: 0 }}>
                    Correo Contacto:{" "}
                    {this.state.datosOrdenDeCompra?.proveedor.correoContacto}
                  </p>
                  <p style={{ margin: 0 }}>
                    Dias de crédito:{" "}
                    {this.state.datosOrdenDeCompra?.proveedor.diasCredito}
                  </p>
                </Col>
              </Row>
              <Divider style={{ marginBottom: 10, marginTop: 5 }} />
              <FormInitial.List name="insumos">
                {(fields) => (
                  <>
                    <table className="table">
                      {fields.length > 0 ? (
                        <thead>
                          <tr>
                            <th className="th-border" style={{ width: "34%" }}>
                              Insumo
                            </th>
                            <th className="th-border" style={{ width: "16%" }}>
                              Precio Unitario
                            </th>
                            <th className="th-border" style={{ width: "16%" }}>
                              Cantidad Pedida
                            </th>
                            <th className="th-border" style={{ width: "16%" }}>
                              Cantidad ya Facturada
                            </th>
                            <th className="th-border" style={{ width: "18%" }}>
                              Cantidad a Facturar
                            </th>
                          </tr>
                        </thead>
                      ) : null}
                      {fields.map(({ key, name, fieldKey, ...restField }) => (
                        <tbody key={fieldKey}>
                          <tr>
                            <td className="td-border">
                              <FormItem
                                {...restField}
                                name={[name, "insumoId"]}
                                fieldKey={[fieldKey, "insumoId"]}
                                required
                                noStyle
                              >
                                <SelectItem
                                  sinBorde
                                  autoFocus
                                  style={{ width: "100%" }}
                                  placeholder="Insumo"
                                  disabled
                                >
                                  {this.state.objetoInsumos}
                                </SelectItem>
                              </FormItem>
                            </td>
                            <td className="td-border">
                              <FormItem
                                {...restField}
                                name={[name, "precioUnitarioAjustado"]}
                                fieldKey={[fieldKey, "precioUnitarioAjustado"]}
                                noStyle
                              >
                                <InputNumberItem
                                  sinBorde
                                  placeholder="Precio Unitario"
                                  disabled
                                  dineroDisabled
                                />
                              </FormItem>
                            </td>
                            <td className="td-border">
                              <FormItem
                                {...restField}
                                name={[name, "cantidad"]}
                                fieldKey={[fieldKey, "cantidad"]}
                                required
                                number
                                noStyle
                              >
                                <InputNumberItem
                                  sinBorde
                                  placeholder="Cantidad Pedida"
                                  disabled
                                />
                              </FormItem>
                            </td>
                            <td className="td-border">
                              <FormItem
                                {...restField}
                                name={[name, "recibidoFacturas"]}
                                fieldKey={[fieldKey, "recibidoFacturas"]}
                                required
                                numberCero
                                noStyle
                                initialValue={0}
                              >
                                <InputNumberItem
                                  sinBorde
                                  placeholder="Cantidad ya Facturada"
                                  disabled
                                />
                              </FormItem>
                            </td>
                            <td className="td-border">
                              <FormItem shouldUpdate noStyle>
                                {() => {
                                  let cantidad =
                                    this.state.datosOrdenDeCompra.insumos[
                                      fieldKey
                                    ].cantidad;
                                  let recibido =
                                    this.state.datosOrdenDeCompra.insumos[
                                      fieldKey
                                    ].recibidoFacturas;
                                  let max;
                                  if (recibido) {
                                    max = cantidad - recibido;
                                  } else {
                                    max = cantidad;
                                  }
                                  return (
                                    <FormItem
                                      {...restField}
                                      name={[name, "recibir"]}
                                      fieldKey={[fieldKey, "recibir"]}
                                      number
                                      noStyle
                                      max={max}
                                      type="number"
                                    >
                                      <InputNumberItem
                                        sinBorde
                                        placeholder={"Max: " + max}
                                      />
                                    </FormItem>
                                  );
                                }}
                              </FormItem>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  </>
                )}
              </FormInitial.List>
              <Divider />
              <Row>
                <Col span={8}>
                  <h1 style={{ textAlign: "center" }}>Total Orden de Compra</h1>
                  {this.state.datosOrdenDeCompra && (
                    <table className="table">
                      <tbody>
                        <tr>
                          <th className="th-totales" style={{ width: "50%" }}>
                            Subtotal
                          </th>
                          <td
                            className="td-totales-right"
                            style={{ width: "50%" }}
                          >
                            {dineroDisabledMask(
                              this.state.datosOrdenDeCompra.subtotal
                            )}
                          </td>
                        </tr>
                      </tbody>
                      {this.state.datosOrdenDeCompra.arrayImpuestos &&
                        this.state.datosOrdenDeCompra.arrayImpuestos.map(
                          (valueArray) => (
                            <tbody key={valueArray.descripcionFactura}>
                              <tr key={valueArray.descripcionFactura}>
                                <th className="th-totales">
                                  {valueArray.descripcionFactura}
                                </th>
                                <td className="td-totales-right">
                                  {dineroDisabledMask(
                                    valueArray.totalImpuestos
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          )
                        )}
                      <tbody>
                        <tr>
                          <th className="th-totales">Total</th>
                          <td className="td-totales-right">
                            {dineroDisabledMask(
                              this.state.datosOrdenDeCompra.total
                            )}
                          </td>
                        </tr>
                      </tbody>
                      {this.state.datosOrdenDeCompra.arrayRetenciones &&
                        this.state.datosOrdenDeCompra.arrayRetenciones.map(
                          (value) => (
                            <tbody key={value.descripcionFactura}>
                              <tr>
                                <th className="th-totales">
                                  {value.descripcionFactura}
                                </th>
                                <td className="td-totales-right">
                                  {dineroDisabledMask(value.totalRetenciones)}
                                </td>
                              </tr>
                            </tbody>
                          )
                        )}
                      <tbody>
                        <tr>
                          <th className="th-totales">Total a pagar</th>
                          <td className="td-totales-right">
                            {dineroDisabledMask(
                              this.state.datosOrdenDeCompra.totalPagar
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </Col>
                <Col span={6}></Col>
                <Col span={10}>
                  {this.state.datosOrdenDeCompra && (
                    <>
                      <h1 style={{ textAlign: "center" }}>
                        Total Factura Actual
                      </h1>
                      <FormItem shouldUpdate noStyle>
                        {({ getFieldValue }) => {
                          let arrayInsumos = getFieldValue("insumos");
                          let ajusteSubtotal = getFieldValue('ajusteSubtotal') || 0
                          if (!arrayInsumos) return null;
                          let proveedorId =
                            this.state.datosOrdenDeCompra.proveedorId;
                          let cuentasProveedor = 0;
                          let subtotal = 0;
                          let subtotalVI = 0
                          let arrayImpuestos = [];
                          let arrayRetenciones = [];
                          let total = 0;
                          arrayInsumos.map((valueInsumo, indexInsumo) => {
                            if (
                              !valueInsumo.recibir ||
                              !valueInsumo.precioUnitario ||
                              valueInsumo.descuento === undefined ||
                              !valueInsumo.insumoId
                            )
                              return null;
                            const insumo = this.state.todasInsumos.find(
                              (valueIn) => valueIn._id === valueInsumo.insumoId
                            );
                            let valuePrecioUnitario =
                              valueInsumo.precioUnitario;
                            let valueCantidad = valueInsumo.recibir;
                            let valueDescuento = valueInsumo.descuento;
                            //1
                            let subtotalInsumoSD = round2Decimals(valuePrecioUnitario * valueCantidad)
                            //2
                            let totalDescuento = round2Decimals(subtotalInsumoSD * (valueDescuento / 100))
                            //3
                            let subtotalInsumo = round2Decimals(subtotalInsumoSD - totalDescuento)
                            subtotal += subtotalInsumo;
                            subtotalVI += subtotalInsumo;
                            let totalImpuestosInsumo = 0;
                            insumo.impuestos.map(
                              //4
                              (valueImpuesto) => {
                                let descripcionFacturaImpuesto =
                                  valueImpuesto.descripcionFactura;
                                  let totalImpuesto
                                  if(valueImpuesto.tasaCuota === 1){
                                    totalImpuesto = round6Decimals(arrayInsumos[indexInsumo].recibir *arrayInsumos[indexInsumo].precioUnitarioAjustado*(valueImpuesto.monto/100))
                                  }else if(valueImpuesto.tasaCuota === 2){
                                    totalImpuesto = round6Decimals((arrayInsumos[indexInsumo].recibir*arrayInsumos[indexInsumo].precioUnitarioAjustado+totalImpuestosInsumo)*(valueImpuesto.monto/100))
                                  }else if(valueImpuesto.tasaCuota === 3){
                                    totalImpuesto	= round6Decimals(arrayInsumos[indexInsumo].recibir*valueImpuesto.monto)
                                  }else if(valueImpuesto.tasaCuota === 4){
                                    totalImpuesto	= round6Decimals(arrayInsumos[indexInsumo].impuestos*(arrayInsumos[indexInsumo].recibir/arrayInsumos[indexInsumo].cantidad))
                                  }else if(valueImpuesto.tasaCuota === 5){
                                    totalImpuesto = 0
                                  }
                                  let totalImpuestosImpuesto = totalImpuesto;
                                let index = arrayImpuestos.findIndex(
                                  (valueImpuestosAnterios) =>
                                    valueImpuestosAnterios.descripcionFactura ===
                                    descripcionFacturaImpuesto
                                );
                                totalImpuestosInsumo += totalImpuestosImpuesto;
                                if (index !== -1) {
                                  let newItem = {
                                    totalImpuestos:
                                      arrayImpuestos[index].totalImpuestos +
                                      totalImpuestosImpuesto,
                                    descripcionFactura:
                                      descripcionFacturaImpuesto,
                                  };
                                  arrayImpuestos.splice(index, 1, newItem);
                                } else {
                                  arrayImpuestos.push({
                                    totalImpuestos: totalImpuestosImpuesto,
                                    descripcionFactura:
                                      descripcionFacturaImpuesto,
                                  });
                                }
                                return null;
                              }
                            );
                            let totalRetencionesInsumo = 0;
                            if (proveedorId) {
                              const proveedor = this.state.todasProveedores.find(
                                (valueP) => valueP._id === proveedorId
                              );

                              proveedor.retenciones.map((valueRetencion) => {
                                    //7
                                    let totalRetencion;
                                    let descripcionFacturaRetencion =
                                    valueRetencion.descripcionFactura;
                                    if(valueRetencion.tasaCuota === 1){
                                      totalRetencion = round6Decimals(arrayInsumos[indexInsumo].recibir *arrayInsumos[indexInsumo].precioUnitarioAjustado*(valueRetencion.monto/100))
                                    }else if(valueRetencion.tasaCuota === 2){
                                      totalRetencion = round6Decimals((arrayInsumos[indexInsumo].recibir*arrayInsumos[indexInsumo].precioUnitarioAjustado+totalRetencionesInsumo)*(valueRetencion.monto/100))
                                    }
                                    let index = arrayRetenciones.findIndex(
                                      (valueretencionesAnterios) =>
                                        valueretencionesAnterios.descripcionFactura ===
                                        descripcionFacturaRetencion
                                    );
                                    totalRetencionesInsumo += totalRetencion;
                                    cuentasProveedor += totalRetencion;
                                    if (index !== -1) {
                                      let newItem = {
                                        totalRetenciones:
                                          arrayRetenciones[index].totalRetenciones +
                                          totalRetencion,
                                        descripcionFactura:
                                        descripcionFacturaRetencion,
                                      };
                                      arrayRetenciones.splice(index, 1, newItem);
                                    } else {
                                      arrayRetenciones.push({
                                        totalRetenciones: totalRetencion,
                                        descripcionFactura:
                                        descripcionFacturaRetencion,
                                      });
                                    }
                                  return null
                          })

                              insumo.retenciones.map((valueRetencion) => {
                                if((valueRetencion.aplicaCompra === 1 && proveedor.persona === false) || (valueRetencion.aplicaCompra === 2 && proveedor.persona === true) || valueRetencion.aplicaCompra === 3){
                                    //7
                                      let totalRetencion;
                                      let descripcionFacturaRetencion =
                                      valueRetencion.descripcionFactura;
                                      if(valueRetencion.tasaCuota === 1){
                                        totalRetencion = round6Decimals(arrayInsumos[indexInsumo].recibir *arrayInsumos[indexInsumo].precioUnitarioAjustado*(valueRetencion.monto/100))
                                      }else if(valueRetencion.tasaCuota === 2){
                                        totalRetencion = round6Decimals((arrayInsumos[indexInsumo].recibir*arrayInsumos[indexInsumo].precioUnitarioAjustado+totalRetencionesInsumo)*(valueRetencion.monto/100))
                                      }
                                      let index = arrayRetenciones.findIndex(
                                        (valueretencionesAnterios) =>
                                          valueretencionesAnterios.descripcionFactura ===
                                          descripcionFacturaRetencion
                                      );
                                      totalRetencionesInsumo += totalRetencion;
                                      cuentasProveedor += totalRetencion;
                                      if (index !== -1) {
                                        let newItem = {
                                          totalRetenciones:
                                            arrayRetenciones[index].totalRetenciones +
                                            totalRetencion,
                                          descripcionFactura:
                                          descripcionFacturaRetencion,
                                        };
                                        arrayRetenciones.splice(index, 1, newItem);
                                      } else {
                                        arrayRetenciones.push({
                                          totalRetenciones: totalRetencion,
                                          descripcionFactura:
                                          descripcionFacturaRetencion,
                                        });
                                      }
                                    }
                                    return null
                              })
                            }
                            //5
                            total += subtotalInsumo;
                            return null;
                          });
                          subtotal += ajusteSubtotal
                          total += ajusteSubtotal
                          arrayImpuestos = arrayImpuestos.map(valueImpuesto => {
                            let ajusteImpuesto = getFieldValue('ajuste'+valueImpuesto.descripcionFactura) || 0
                            total += round2Decimals(valueImpuesto.totalImpuestos + ajusteImpuesto)
                            return {
                              ...valueImpuesto,
                              totalImpuestos: round2Decimals(valueImpuesto.totalImpuestos + ajusteImpuesto),
                              totalImpuestosVI: valueImpuesto.totalImpuestos
                            }
                          })
                          arrayRetenciones = arrayRetenciones.map(valueRetencion => {
                            let ajusteRetencion = getFieldValue('ajuste'+valueRetencion.descripcionFactura) || 0
                            cuentasProveedor += ajusteRetencion
                            return {
                              ...valueRetencion,
                              totalRetenciones: (valueRetencion.totalRetenciones + ajusteRetencion),
                              totalRetencionesVI: valueRetencion.totalRetenciones
                            }
                          })
                          return (
                            <table className="table">
                              <tbody>
                              <tr style={{marginBottom: 10}}>
                              <th
                                  className="td-totales-center"
                                  style={{ width: "50%" }}
                                >
                                </th>
                                <td
                                  className="td-totales-center"
                                  style={{ width: "20%" }}
                                >
                                  Ajuste
                                </td>
                                <td
                                  className="td-totales-center"
                                  style={{ width: "30%" }}
                                >
                                </td>
                              </tr>
                                <tr>
                                  <th
                                    className="th-totales"
                                    style={{ width: "50%" }}
                                  >
                                    Subtotal
                                  </th>
                                  <td
                                  className="td-totales-right"
                                  style={{ width: "20%" }}
                                >
                                <FormItem
                                  name='ajusteSubtotal'
                                  numberNegativo
                                  noStyle
                                  max={this.state.ajusteMax}
                                  min={Math.max(-this.state.ajusteMax, -subtotalVI)}
                                  type="number"
                                  required
                                  initialValue={0}
                                >
                                  <InputNumberItem
                                    step={.01}
                                    sinBorde
                                    dinero
                                    precision={2}
                                    placeholder={"Max: ±"+this.state.ajusteMax}
                                  />
                                </FormItem>
                                </td>
                                  <td
                                    className="td-totales-right"
                                    style={{ width: "50%" }}
                                  >
                                    {dineroDisabledMask(subtotal)}
                                  </td>
                                </tr>
                              </tbody>
                              {arrayImpuestos.map((valueArray) => (
                                <tbody key={valueArray.descripcionFactura}>
                                  <tr key={valueArray.descripcionFactura}>
                                    <th className="th-totales">
                                      {valueArray.descripcionFactura}
                                    </th>
                                    <td
                                  className="td-totales-right"
                                  style={{ width: "20%" }}
                                >
                                    <FormItem
                                      name={'ajuste'+valueArray.descripcionFactura}
                                      numberNegativo
                                      noStyle
                                      max={this.state.ajusteMax}
                                      min={Math.max(-this.state.ajusteMax, -valueArray.totalImpuestosVI)}
                                      type="number"
                                      required
                                      initialValue={0}
                                      precision={2}
                                    >
                                      <InputNumberItem
                                        step={.01}
                                        sinBorde
                                        dinero
                                        placeholder={"Max: ±"+this.state.ajusteMax}
                                      />
                                    </FormItem>
                                    </td>
                                    <td className="td-totales-right">
                                      {dineroDisabledMask(
                                        valueArray.totalImpuestos
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              ))}
                              <tbody>
                                <tr>
                                  <th className="th-totales">Total Factura</th>
                                  <td
                                  className="td-totales-right"
                                  style={{ width: "20%" }}
                                ></td>
                                  <td className="td-totales-right">
                                    {dineroDisabledMask(total)}
                                  </td>
                                </tr>
                              </tbody>
                              {arrayRetenciones.map((value) => (
                                <tbody key={value.descripcionFactura}>
                                  <tr>
                                    <th className="th-totales">
                                      {value.descripcionFactura}
                                    </th>
                                    <td
                                  className="td-totales-right"
                                  style={{ width: "20%" }}
                                >
                                <FormItem
                                  name={'ajuste'+value.descripcionFactura}
                                  numberNegativo
                                  noStyle
                                  max={this.state.ajusteMax}
                                  min={Math.max(-this.state.ajusteMax, -value.totalRetencionesVI)}
                                  type="number"
                                  required
                                  initialValue={0}
                                  precision={2}
                                >
                                  <InputNumberItem
                                    step={.01}
                                    sinBorde
                                    dinero
                                    placeholder={"Max: ±"+this.state.ajusteMax}
                                  />
                                </FormItem>
                                </td>
                                    <td className="td-totales-right">
                                      {dineroDisabledMask(value.totalRetenciones)}
                                    </td>
                                  </tr>
                                </tbody>
                              ))}
                                    <tbody>
                                      <tr>
                                        <th className="th-totales">
                                          Total a pagar
                                        </th>
                                        <td
                                  className="td-totales-right"
                                  style={{ width: "20%" }}
                                ></td>
                                        <td className="td-totales-right">
                                          {dineroDisabledMask(
                                            total - cuentasProveedor
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                            </table>
                          );
                        }}
                      </FormItem>
                    </>
                  )}
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(RecibirFactura);
