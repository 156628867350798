import { CheckCircleOutlined, EditOutlined, StopOutlined } from "@ant-design/icons";
import { PageHeader, Space, Popconfirm, message } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { TableItem, ButtonItem, dineroDisabledMask, SegmentedItem } from "../../../Components/Items";
import AgregarCajaEfectivo from "./AgregarCajaEfectivo/AgregarCajaEfectivo";
import EditarCajaEfectivo from "./EditarCajaEfectivo/EditarCajaEfectivo";

class CajaEfectivo extends Component {
  state = {
    todasCajaEfectivo: [],
    modalAgregarCajaEfectivo: false,
    modalEditarCajaEfectivo: false,
    modalEditarCajaEfectivoId: null,
    loading: false,
    valor: 1,
  };
  componentDidMount() {
    this.consultarCajaEfectivo(this.state.valor);
  }

  consultarCajaEfectivo(valor) {
    this.setState({ loading: true })
    axios
      .post(
        "cajaefectivo/selectTodosCajaEfectivo",
        { empresaId: this.props.empresaId, valor: valor },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false })
        if (res.data.status === 404) {
          this.setState({ todasCajaEfectivo: [] });
        } else {
          this.setState({
            todasCajaEfectivo: res.data.map(value => {
              return { ...value, accion: { _id: value._id, estado: value.estado, nombre: value.nombre } }
            }),
          });
        }
      });
  }

  abrirAgregarCajaEfectivo() {
    this.setState({ modalAgregarCajaEfectivo: true });
  }
  cerrarAgregarCajaEfectivo() {
    this.setState({ modalAgregarCajaEfectivo: false });
    this.consultarCajaEfectivo(this.state.valor);
  }
  abrirEditarCajaEfectivo(id) {
    this.setState({ modalEditarCajaEfectivo: true, modalEditarCajaEfectivoId: id });
  }
  cerrarEditarCajaEfectivo() {
    this.setState({ modalEditarCajaEfectivo: false });
    this.consultarCajaEfectivo(this.state.valor);
  }
  deshabilitarCajaEfectivo(value) {
    axios
      .post(
        "cajaefectivo/deshabilitarCaja",
        { cajaId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Caja efectivo deshabilitado");
        } else {
          message.error("Sucedio un eror y no se pudo deshabilitar");
        }
        this.consultarCajaEfectivo(this.state.valor);
      });
  }
  habilitarCajaEfectivo(value) {
    axios
      .post(
        "cajaefectivo/habilitarCaja",
        { cajaId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Caja efectivo habilitado");
        } else {
          message.error("Sucedio un eror y no se pudo habilitar");
        }
        this.consultarCajaEfectivo(this.state.valor);
      });
  }
  abrirReponerCajaChica(id, nombre) {
    this.props.history.push('/panel/reponerCajaChica', { id, nombre })
  }
  render() {
    const columns = [
      {
        title: "Nombre",
        dataIndex: "nombre",
        key: "nombre",
        sorter: (a, b) => {
          if (a.nombre < b.nombre) {
            return -1;
          }
          if (a.nombre > b.nombre) {
            return 1;
          }
          return 0;
        },
        buscar: "nombre",
      },
      {
        title: "Banco",
        dataIndex: "banco",
        key: "banco",
        responsive: ["md"],
        sorter: (a, b) => {
          if (a.banco < b.banco) {
            return -1;
          }
          if (a.banco > b.banco) {
            return 1;
          }
          return 0;
        },
        buscar: "banco",
      },
      {
        title: "Cuenta Contable",
        dataIndex: "cuentacontable",
        key: "cuentacontable",
        responsive: ["md"],
        render: (value) => value.descripcion
      },
      {
        title: "Moneda",
        dataIndex: "moneda",
        key: "moneda",
        responsive: ["md"],
        render: (value) => value.nombre
      },
      {
        title: "Saldo",
        dataIndex: "saldo",
        key: "saldo",
        render: (value) => dineroDisabledMask(value)
      },
      {
        title: "Accion",
        dataIndex: "accion",
        key: "accion",
        render: (value, record, index) => (
          <Space size="middle" key={index.toString()}>
            {this.props.permisoEditarCajasChicas &&
              <EditOutlined
                onClick={() => this.abrirEditarCajaEfectivo(value._id)}
                style={{ color: "blue" }}
                key={index.toString()}
              />
            }
            {this.props.permisoDeshabilitarCajaChica &&
              <>
                {value.estado === 1 ? (
                  <Popconfirm
                    title="Seguro que quieres deshabilitar esta caja"
                    onConfirm={() => this.deshabilitarCajaEfectivo(value._id)}
                    okText="Si"
                    cancelText="No"
                    key={index.toString()}
                  >
                    <CheckCircleOutlined style={{ color: "green" }} />
                  </Popconfirm>
                ) : (
                  <Popconfirm
                    title="Seguro que quieres habilitar esta caja"
                    onConfirm={() => this.habilitarCajaEfectivo(value._id)}
                    okText="Si"
                    cancelText="No"
                    key={index.toString()}
                  >
                    <StopOutlined style={{ color: "red" }} />
                  </Popconfirm>
                )}
              </>
            }
            {this.props.permisoReponerCajaChica &&
              <ButtonItem key={index.toString()} type='primary' onClick={() => this.abrirReponerCajaChica(value._id, value.nombre)} size='small'>
                Reponer
              </ButtonItem>
            }
          </Space>
        ),
      },
    ]
    return (
      <>
        <PageHeader
          title="Caja y Efectivo"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={

            <>
            {window.innerWidth > 1000 &&
              <SegmentedItem
                options={[{label: 'Activo', value: 1}, {label: 'Deshabilitado', value: 2}, {label: 'Todos', value: 3}]}
                onChange={(value) => {
                  this.consultarCajaEfectivo(value)
                  this.setState({ valor: value })
                }}
                value={this.state.valor}
              />
          }
              {this.props.permisoAgregarCajasChicas && (
                <ButtonItem
                  type="primary"
                  key="agregarCajaEfectivo"
                  onClick={this.abrirAgregarCajaEfectivo.bind(this)}
                >
                  Agregar Caja
                </ButtonItem>
              )}

            </>
          }
        />
        <TableItem
          size='small'
          onRow={(record, rowIndex) => {
            return {
              onDoubleClick: (event) => {
                this.props.history.push("/panel/verPagosCajaChica", { cajaChicaId: record._id });
              },
            };
          }}
          loading={this.state.loading}
          dataSource={this.state.todasCajaEfectivo}
          columns={columns}
        />
        {this.props.permisoAgregarCajasChicas &&
          <AgregarCajaEfectivo
            visible={this.state.modalAgregarCajaEfectivo}
            cerrarAgregarCajaEfectivo={this.cerrarAgregarCajaEfectivo.bind(
              this
            )}
          />
        }
        {this.props.permisoEditarCajasChicas &&
          <EditarCajaEfectivo
            id={this.state.modalEditarCajaEfectivoId}
            visible={this.state.modalEditarCajaEfectivo}
            cerrarEditarCajaEfectivo={this.cerrarEditarCajaEfectivo.bind(
              this
            )}
          />
        }
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    permisoAgregarCajasChicas: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615ca6f5f30e81b54183cf50")?.activado,
    permisoEditarCajasChicas: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615ca733f30e81b54183cf51")?.activado,
    permisoReponerCajaChica: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615cb9e4f30e81b54183cf53")?.activado,
    permisoDeshabilitarCajaChica: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615cb951f30e81b54183cf52")?.activado,
  };
};

export default connect(mapStateToProps)(CajaEfectivo);
