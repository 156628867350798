import {
  Checkbox,
  message,
  Tabs,
  Select,
  PageHeader,
  Col,
  Row,
  Spin,
  Form as FormInitial,
  Upload,
  Input,
} from "antd";
import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Form,
  FormItem,
  InputNumberItem,
  SelectItem,
  cuentaMask,
  InputItem,
  HeaderItem,
  BoxItem,
  TextAreaItem,
  ButtonItem,
  SwitchItem,
  ErrorItem,
  calcularSimilitud,
  extraerDatosConstanciaFiscal,
} from "../../../../Components/Items";
import AgregarUnidad from "../../Insumos/AgregarInsumo/AgregarUnidad/AgregarUnidad";

const { TabPane } = Tabs;
const { Option } = Select;

class EditarCliente extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    todasMonedas: null,
    objetoMonedas: null,
    todasPaises: null,
    objetoPaises: null,
    todasEstados: null,
    objetoEstados: null,
    todasMunicipios: null,
    objetoMunicipios: [],
    todasColonias: null,
    objetoColonias: [],
    todasCuentaContable: null,
    objetoCuentaContable: null,
    todasBancosSat: null,
    objetoBancosSat: null,
    todasTiposDeOperacion: null,
    objetoTiposDeOperacion: null,
    todasTiposDeTerceros: null,
    objetoTiposDeTerceros: null,
    todasTipoDeCliente: null,
    objetoTipoDeCliente: null,
    todasPrecios: null,
    objetoPrecios: null,
    todasUsosDeCFDI: null,
    objetoUsosDeCFDI: null,
    todasProyectos: null,
    objetoProyectos: null,
    todasRegimenFiscal: null,
    objetoRegimenFiscal: null,
    todasRetenciones: null,
    objetoRetenciones: null,
    utilizado: false,
    id: this.props.location.state.id,
    mercanciasEnvio: false,
    modalAgregarUnidad: false,
    todasUnidades: false,
    todasMaterialesPeligrosos: false,
    todasEmbalajes: false,
    unidadPeso: null,
    todasSectoresCOF: false,
    todasFormasFarmaceuticas: false,
    todasCondicionesEspeciales: false,
    todasTiposMaterias: false,
    objetoUnidades: null,
    objetoMaterialesPeligrosos: null,
    objetoEmbalajes: null,
    objetoSectoresCOF: null,
    objetoFormasFarmaceuticas: null,
    objetoCondicionesEspeciales: null,
    objetoTiposMaterias: null,
    objetoCodigosSat: [],
    loadingMercancias: false,
    buscarInfoCodes: []
  };
  atras() {
    this.props.history.goBack();
  }
  componentDidMount() {
    this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post("clientes/selectDatosParaCliente",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        })
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasVendedores: res.data.vendedores, todasRetenciones: res.data.retenciones, todasRegimenFiscal: res.data.regimenesFiscales, todasProyectos: res.data.proyectos, todasUsosDeCFDI: res.data.usosDeCFDI, todasPrecios: res.data.precios, todasBancosSat: res.data.bancosSat, todasTipoDeCliente: res.data.tipoDeClientes, todasCuentaContable: res.data.cuentasContables, todasMonedas: res.data.monedas, todasPaises: res.data.paises, todasEstados: res.data.estados });
          this.setState({
            objetoMonedas: res.data.monedas.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre + " - " + value.abreviacion}
                </Option>
              );
            }),
            objetoPaises: res.data.paises.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre + " - " + value.abreviatura}
                </Option>
              );
            }),
            objetoEstados: res.data.estados.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre + " - " + value.abreviatura}
                </Option>
              );
            }),
            objetoCuentaContable: res.data.cuentasContables.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {cuentaMask(
                    value.nivel1 +
                    value.nivel2 +
                    value.nivel3 +
                    value.nivel4 +
                    value.nivel5
                  ) +
                    " - " +
                    value.descripcion}
                </Option>
              );
            }),
            objetoTipoDeCliente: res.data.tipoDeClientes.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre + " - " + value.descripcion}
                </Option>
              );
            }),
            objetoBancosSat: res.data.bancosSat.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.clave + " - " + value.nombreCorto}
                </Option>
              );
            }),
            objetoPrecios: res.data.precios.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
            objetoUsosDeCFDI: res.data.usosDeCFDI.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.descripcion}
                </Option>
              );
            }),
            objetoProyectos: res.data.proyectos.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
            objetoRegimenFiscal: res.data.regimenesFiscales.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.c_RegimenFiscal + " - " + value.descripcion}
                </Option>
              );
            }),
            objetoRetenciones: res.data.retenciones.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
            objetoVendedores: res.data.vendedores.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre +
                    " " +
                    value.apellidoPaterno +
                    " " +
                    value.apellidoMaterno}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "clientes/datosCliente",
        { empresaId: this.props.empresaId, clienteId: this.state.id },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
          message.error("Error no se encontro informacion del cliente");
        } else {
          if (res.data.utilizado) {
            message.info(
              "No se puede modificar toda la informacion ya que el cliente ya fue utilizado"
            );
          }
          res.data.direcciones.map((valueDireccion, index) => {
            if (valueDireccion.estadoId) {
              this.onChangeEstado(valueDireccion.estadoId, index, true);
            }
            if (valueDireccion.municipioId) {
              this.onChangeMunicipio(valueDireccion.municipioId, index, true);
            }
            return null;
          });
          if (res.data.tipoClienteId) {
            const tipoDeCliente = this.state.todasTipoDeCliente.find(
              (valueTodos) =>
                valueTodos._id.toString() === res.data.tipoClienteId.toString()
            );
            if (tipoDeCliente.generarCuentasAutomatico) {
              this.formRef.current.setFieldsValue({
                ...res.data,
                cuentaClienteId: res.data.cuentaClienteId,
                cuentaAnticipoId: res.data.cuentaAnticipoId,
                cuentaFonGarId: res.data.cuentaFonGarId,
                cuentaDeudorId: res.data.cuentaDeudorId,
              });
            } else {
              this.formRef.current.setFieldsValue({
                ...res.data,
                cuentaClienteId: tipoDeCliente.cuentaClienteId,
                cuentaAnticipoId: tipoDeCliente.cuentaAnticipoId,
                cuentaFonGarId: tipoDeCliente.cuentaFonGarId,
                cuentaDeudorId: tipoDeCliente.cuentaDeudorId,
              });
            }
          } else {
            this.formRef.current.setFieldsValue({
              ...res.data,
            });
          }
          this.setState({
            utilizado: res.data.utilizado,
            mercanciasEnvio: res.data.mercanciasEnvio
          });
        }
      });
    this.setState({ loading: false });
  }
  onFinishFailed(e) {
    ErrorItem(e);
  }

  onFinish(values) {
    this.setState({ loading: true });
    axios
      .post(
        "clientes/editarCliente",
        {
          ...values,
          empresaId: this.props.empresaId,
          clienteId: this.state.id,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success("Se ha editado un cliente");
          this.formRef.current.resetFields();
          this.atras();
        } else if (res.data.success === 1) {
          message.warning(res.data.message ? res.data.message : "No se puede editar el cliente");
        } else {
          message.error("Hubo un error y el cliente no se edito");
        }
      });
  }
  onChangeEstado(value, fieldKey, inicial) {
    if (!inicial) {
      let direcciones = this.formRef.current.getFieldValue("direcciones");
      if (typeof direcciones[fieldKey] === 'undefined') {
        direcciones[fieldKey] = {}
      }
      direcciones[fieldKey].municipioId = undefined;
      direcciones[fieldKey].coloniaId = undefined;
      this.formRef.current.setFieldsValue({ direcciones: direcciones });
      let objetoColonias = this.state.objetoColonias;
      objetoColonias[fieldKey] = null;
      this.setState({ objetoColonias: objetoColonias });
    }
    axios
      .post(
        "municipios/selectMunicipios",
        { estadoId: value },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasMunicipios: res.data });
          let objetoMunicipios = this.state.objetoMunicipios;
          objetoMunicipios[fieldKey] = res.data.map((value) => {
            return (
              <Option value={value._id} key={value._id}>
                {value.nombre}
              </Option>
            );
          });
          this.setState({
            objetoMunicipios: objetoMunicipios,
          });
        }
      });
  }
  onChangeMunicipio(value, fieldKey, inicial) {
    if (!inicial) {
      let direcciones = this.formRef.current.getFieldValue("direcciones");
      if (typeof direcciones[fieldKey] === 'undefined') {
        direcciones[fieldKey] = {}
      }
      direcciones[fieldKey].coloniaId = undefined;
      this.formRef.current.setFieldsValue({ direcciones: direcciones });
    }
    axios
      .post(
        "colonias/selectColonias",
        { municipioId: value },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasColonias: res.data });
          let objetoColonias = this.state.objetoColonias;
          objetoColonias[fieldKey] = res.data.map((value) => {
            return (
              <Option value={value._id} key={value._id}>
                {value.cp + " - " + value.nombre}
              </Option>
            );
          });
          this.setState({
            objetoColonias: objetoColonias,
          });
        }
      });
  }
  onChangeTipoDeCliente(value) {
    const tipoDeCliente = this.state.todasTipoDeCliente.find(
      (valueTodos) => valueTodos._id.toString() === value.toString()
    );
    if (
      tipoDeCliente.generarCuentasAutomatico &&
      value !== this.state.dataInicial?.tipoProveedorId &&
      !this.state.dataInicial?.cuentaProveedorId
    ) {
      this.formRef.current.setFieldsValue({
        cuentaClienteId: "Se generara al guardarlo",
        cuentaAnticipoId: "Se generara al guardarlo",
        generarCuentaAnticipoId: false,
        cuentaFonGarId: "Se generara al guardarlo",
        generarCuentaFonGarId: false,
        cuentaDeudorId: "Se generara al guardarlo",
        generarCuentaDeudorId: false,
      });
    } else {
      this.formRef.current.setFieldsValue({
        cuentaClienteId: tipoDeCliente.cuentaClienteId,
        cuentaAnticipoId: tipoDeCliente.cuentaAnticipoId,
        generarCuentaAnticipoId: false,
        cuentaFonGarId: tipoDeCliente.cuentaFonGarId,
        generarCuentaFonGarId: false,
        cuentaDeudorId: tipoDeCliente.cuentaDeudorId,
        generarCuentaDeudorId: false,
      });
    }
  }
  onSearchColonia(value, fieldKey, ponerColoniaId) {
    if (value.toString().length === 5) {
      axios
        .post(
          "colonias/buscarCP",
          { cp: value },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          if (res.data.status === 404) {
            message.info("No se encontro el codigo postal");
          } else {
            let direcciones = this.formRef.current.getFieldValue("direcciones");
            if (typeof direcciones[fieldKey] === 'undefined') {
              direcciones[fieldKey] = {}
            }
            if (ponerColoniaId && res.data._id) {
              direcciones[fieldKey].coloniaId = res.data._id
            }
            direcciones[fieldKey].paisId = res.data.paisId
            direcciones[fieldKey].estadoId = res.data.estadoId
            direcciones[fieldKey].municipioId = res.data.municipioId
            this.formRef.current.setFieldsValue({
              direcciones: direcciones,
            });
            this.onChangeEstado(res.data.estadoId, fieldKey, true);
            this.onChangeMunicipio(res.data.municipioId, fieldKey, true);
          }
        });
    }
  }
  async onSelectColoniaId(coloniaId, fieldKey) {
    const direcciones = this.formRef.current.getFieldValue('direcciones')
    if (typeof direcciones[fieldKey] === 'undefined') {
      direcciones[fieldKey] = {}
    }
    if (!direcciones[fieldKey].colonia) {
      const nombreColonia = this.state.todasColonias.find((todasColonia) => todasColonia._id.toString() === coloniaId.toString())?.nombre
      direcciones[fieldKey].colonia = nombreColonia
      await this.formRef.current.setFieldsValue({
        direcciones: direcciones
      })
    }
  }
  async onChangeRfc(e) {
    this.formRef.current.validateFields(['rfc']).then(() => {
      if (e.target.value) {
        if (e.target.value.length === 13) {
          //Persona Fisica
          this.formRef.current.setFieldsValue({ persona: false })
        } else if (e.target.value.length === 12) {
          //Persona Moral
          this.formRef.current.setFieldsValue({ persona: true })
        }
      }
    }).catch((err) => undefined)
  }
  onChangeRegimenFiscal(regimenFiscalId) {
    if (!this.state.utilizado) {
      const retencionAgregar = this.state.todasRetenciones.find(todasRetenciones => todasRetenciones.regimenFiscalId.toString() === regimenFiscalId.toString())
      const retenciones = this.formRef.current.getFieldValue('retenciones')
      if (retencionAgregar) {
        if (retenciones) {
          const findRetencion = retenciones.find(todasRetencionesProv => todasRetencionesProv.retencionId.toString() === retencionAgregar._id.toString())
          if (!findRetencion) {
            this.formRef.current.setFieldsValue({
              retenciones: [...retenciones, { retencionId: retencionAgregar._id }]
            })
          }
        } else {
          this.formRef.current.setFieldsValue({
            retenciones: [{ retencionId: retencionAgregar._id }]
          })
        }
      }
    }
  }

  async onChangeArchivo(e) {
    this.setState({ loading: true });
    if (e.file.type === "application/pdf") {
      const datosConstancia = await extraerDatosConstanciaFiscal(e.file)
      if (datosConstancia.persona === 1) {


        if (datosConstancia.rfc && datosConstancia.nombre && datosConstancia.apellidoPaterno && datosConstancia.codigoPostal) {
          const findRegimen = this.state.todasRegimenFiscal.find(valueTRe => calcularSimilitud(valueTRe.descripcion, datosConstancia.regimenFiscal) > 65)
          this.onSearchColonia(datosConstancia.codigoPostal, 0, true)
          this.formRef.current.setFieldsValue({
            rfc: datosConstancia.rfc,
            curp: datosConstancia.curp,
            nombre: datosConstancia.nombre,
            regimenFiscalId: findRegimen ? findRegimen._id : undefined,
            apellidoPaterno: datosConstancia.apellidoPaterno,
            apellidoMaterno: datosConstancia.apellidoMaterno,
            nombreComercial: datosConstancia.nombreComercial,

            direcciones: [
              {
                calle: datosConstancia.calle,
                numeroCalle: datosConstancia.numeroExterior,
                numeroInterior: datosConstancia.numeroInterior,
                colonia: datosConstancia.colonia,
                codigoPostal: datosConstancia.codigoPostal,
              }
            ],
            persona: false
          })

        } else {
          message.error('No se pudo leer bien el archivo')
        }

      } else if (datosConstancia.persona === 2) {

        if (datosConstancia.rfc && datosConstancia.razonSocial && datosConstancia.codigoPostal) {
          this.onSearchColonia(datosConstancia.codigoPostal, 0, true)
          const findRegimen = this.state.todasRegimenFiscal.find(valueTRe => calcularSimilitud(valueTRe.descripcion, datosConstancia.regimenFiscal) > 65)
          this.formRef.current.setFieldsValue({
            razonSocial: datosConstancia.razonSocial,
            rfc: datosConstancia.rfc,
            regimenFiscalId: findRegimen ? findRegimen._id : undefined,
            curp: datosConstancia.curp,
            nombreComercial: datosConstancia.nombreComercial,

            direcciones: [
              {
                calle: datosConstancia.calle,
                numeroCalle: datosConstancia.numeroExterior,
                numeroInterior: datosConstancia.numeroInterior,
                colonia: datosConstancia.colonia,
                codigoPostal: datosConstancia.codigoPostal,
              }
            ],
            persona: true
          })
        } else {
          message.error('No se pudo leer bien el archivo')
        }

      } else {
        message.error('No se pudo leer bien el archivo')
      }
    } else {
      message.error("El archivo debe ser un archivo pdf");
    }
    this.setState({ loading: false });
  }

  onChangeCodigo(value, fieldKey) {

    let buscarInfoCodes = this.state.buscarInfoCodes

    buscarInfoCodes[fieldKey] = true

    this.setState({ buscarInfoCodes })

    axios
      .post(
        "codigoinsumos/buscarCodigoLista",
        { empresaId: this.props.empresaId, codigo: value },
        { headers: this.props.headersToken, }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          let objetoCodigosSat = this.state.objetoCodigosSat;
          objetoCodigosSat[fieldKey] = res.data.map((value) => {
            return (
              <Option value={value._id} key={value._id}>
                {value.c_ClaveProdServ + " - " + value.descripcion}
              </Option>
            );
          });

          buscarInfoCodes[fieldKey] = false

          this.setState({
            buscarInfoCodes,
            objetoCodigosSat
          });
        }
      })
  }


  abrirAgregarUnidad() {
    this.setState({ modalAgregarUnidad: true });
  }

  cerrarAgregarUnidad(codigo) {
    this.setState({ modalAgregarUnidad: false });
    this.consultarTodo().then(() => {
      if (typeof codigo === "string") {
        this.formRef.current.setFieldsValue({ unidadId: codigo });
      }
    });
  }

  render() {
    return (
      <>
        <PageHeader
          onBack={this.atras.bind(this)}
          title="Editar Cliente"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={(
            <Upload
              showUploadList={{
                showRemoveIcon: false,
                showPreviewIcon: false,
              }}
              fileList={[]}
              customRequest={this.onChangeArchivo.bind(this)}
              accept=".pdf"
            >
              <ButtonItem blanco icon={<UploadOutlined />}>Leer Constancia</ButtonItem>
            </Upload>
          )}
        />
        <div style={{ backgroundColor: "white", padding: 20, paddingTop: 5 }}>
          <Spin spinning={this.state.loading}>
            <Form
              ref={this.formRef}
              name="agregarCliente"
              layout
              bottom={
                <ButtonItem
                  type="primary"
                  htmlType="submit"
                  style={{ display: "block", margin: "0 0 0 auto" }}
                >
                  Guardar Cliente
                </ButtonItem>
              }
              onFinish={this.onFinish.bind(this)}
              onFinishFailed={this.onFinishFailed}
              initialValues={{
                monedaId: "60c7e4396465ad440c41c15a",
                direcciones: [""],
              }}
            >
              <Tabs defaultActiveKey={1} onTabClick={async (key) => {
                if (key === '8') {
                  await this.setState({ loadingMercancias: true })

                  await axios
                    .post(
                      "envios/datosMercanciaFlete",
                      { empresaId: this.props.empresaId },
                      {
                        headers: this.props.headersToken,
                      }
                    )
                    .then((res) => {
                      if (res.data.success === 2) {
                        this.setState({
                          todasUnidades: res.data.unidades,
                          todasMaterialesPeligrosos: res.data.materialesPeligrosos,
                          todasEmbalajes: res.data.embalajes,
                          unidadPeso: res.data.unidadPeso,
                          todasSectoresCOF: res.data.sectoresCOF,
                          todasFormasFarmaceuticas: res.data.formasFarmaceuticas,
                          todasCondicionesEspeciales: res.data.condicionesEspeciales,
                          todasTiposMaterias: res.data.tiposMaterias,
                          objetoUnidades: res.data.unidades.map((value) => {
                            return (
                              <Option value={value._id} key={value._id}>
                                {value.unidadSat.c_ClaveUnidad + " - " + value.nombre}
                              </Option>
                            );
                          }),
                          objetoMaterialesPeligrosos: res.data.materialesPeligrosos.map((value) => {
                            return (
                              <Option value={value._id} key={value._id}>
                                {value.Clave + " - " + value.Descripcion}
                              </Option>
                            );
                          }),
                          objetoEmbalajes: res.data.embalajes.map((value) => {
                            return (
                              <Option value={value._id} key={value._id}>
                                {value.Clave + " - " + value.Descripcion}
                              </Option>
                            );
                          }),
                          objetoSectoresCOF: res.data.sectoresCOF.map((value) => {
                            return (
                              <Option value={value._id} key={value._id}>
                                {value.Clave + " - " + value.Descripcion}
                              </Option>
                            );
                          }),
                          objetoFormasFarmaceuticas: res.data.formasFarmaceuticas.map((value) => {
                            return (
                              <Option value={value._id} key={value._id}>
                                {value.Clave + " - " + value.Descripcion}
                              </Option>
                            );
                          }),
                          objetoCondicionesEspeciales: res.data.condicionesEspeciales.map((value) => {
                            return (
                              <Option value={value._id} key={value._id}>
                                {value.Clave + " - " + value.Descripcion}
                              </Option>
                            );
                          }),
                          objetoTiposMaterias: res.data.tiposMaterias.map((value) => {
                            return (
                              <Option value={value._id} key={value._id}>
                                {value.Clave + " - " + value.Descripcion}
                              </Option>
                            );
                          }),
                        });
                      } else {
                        message.info('No se cargaron bien los datos de las mercancias')
                      }
                    });

                  const objetoCodigosSat = await Promise.all(this.state.mercanciasEnvio.map(async (valueMer, kerMer) => {

                    const codigos = await axios
                      .post(
                        "codigoinsumos/buscarCodigoLista",
                        { empresaId: this.props.empresaId, subClaseId: valueMer.codigoSatId },
                        { headers: this.props.headersToken, }
                      )

                    if (codigos.data.status === 404) {
                      return null
                    } else {
                      return codigos.data.map((value) => {
                        return (
                          <Option value={value._id} key={value._id}>
                            {value.c_ClaveProdServ + " - " + value.descripcion}
                          </Option>

                        );
                      })

                    }


                  }))


                  const buscarInfoCodes = this.state.mercanciasEnvio.map((valueMer) => false)

                  await this.setState({ loadingMercancias: false, buscarInfoCodes, objetoCodigosSat })
                }
              }}>
                <TabPane tab="General" tabKey={1} key={1} forceRender>
                  <Row>
                    <Col span={12} xs={24} sm={24}  lg={12} xl={12}>
                      <HeaderItem>DATOS GENERALES</HeaderItem>
                      <BoxItem>
                        <Row>
                          <Col span={12} xs={24} sm={24}  lg={12} xl={12}>
                            <FormItem
                              name="codigo"
                              label="Codigo"
                              margin
                              labelCol={{ span: 6 }}
                              wrapperCol={{ span: 18 }}
                            >
                              <InputItem disabled placeholder="Codigo" />
                            </FormItem>
                          </Col>
                          <Col span={12} xs={24} sm={24}  lg={12} xl={12}>
                            <FormItem
                              name="clave"
                              label="Clave"
                              margin
                              labelCol={{ span: 8 }}
                              wrapperCol={{ span: 16 }}
                              pattern
                            >
                              <InputItem placeholder="Clave" />
                            </FormItem>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24} xs={24} sm={24}  lg={24} xl={24}>
                            <FormItem
                              name="persona"
                              label="P. Moral"
                              valuePropName="checked"
                              initialValue={false}
                              margin
                              labelCol={{ span: 6 }}
                              wrapperCol={{ span: 10 }}
                            >
                              <Checkbox />
                            </FormItem>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={12} xs={24} sm={24}  lg={12} xl={12}>
                            <FormItem
                              label="RFC"
                              name="rfc"
                              required
                              margin
                              rfc
                              labelCol={{ span: 6 }}
                              wrapperCol={{ span: 18 }}
                            >
                              <InputItem onChange={this.onChangeRfc.bind(this)} placeholder="RFC" />
                            </FormItem>
                          </Col>
                          <Col span={12} xs={24} sm={24}  lg={12} xl={12}>
                            <FormItem shouldUpdate noStyle>
                              {({ getFieldValue }) => {
                                if (!getFieldValue("persona")) {
                                  return (
                                    <FormItem
                                      label="CURP"
                                      margin
                                      pattern
                                      max={20}
                                      name="curp"
                                      labelCol={{ span: 8 }}
                                      wrapperCol={{ span: 16 }}
                                    >
                                      <InputItem placeholder="CURP" />
                                    </FormItem>
                                  );
                                }
                              }}
                            </FormItem>
                          </Col>
                        </Row>
                        <FormItem shouldUpdate noStyle>
                          {({ getFieldValue }) => {
                            if (getFieldValue("persona")) {
                              return (
                                <FormItem
                                  label="Razon Social"
                                  margin
                                  pattern
                                  required
                                  max={200}
                                  name="razonSocial"
                                  labelCol={{ span: 3 }}
                                  wrapperCol={{ span: 21 }}
                                >
                                  <InputItem placeholder="Razón Social" />
                                </FormItem>
                              );
                            }
                          }}
                        </FormItem>
                        <Row>
                          <Col span={10} xs={24} sm={24}  lg={10} xl={10}>
                            <FormItem shouldUpdate noStyle>
                              {({ getFieldValue }) => {
                                if (!getFieldValue("persona")) {
                                  return (
                                    <FormItem
                                      label="Nombre"
                                      margin
                                      pattern
                                      required
                                      name="nombre"
                                      labelCol={{ span: 7 }}
                                      wrapperCol={{ span: 17 }}
                                    >
                                      <InputItem
                                        placeholder="Nombre persona física"
                                        width="98%"
                                      />
                                    </FormItem>
                                  );
                                }
                              }}
                            </FormItem>
                          </Col>
                          <Col span={7} xs={24} sm={24}  lg={7} xl={7}>
                            <FormItem shouldUpdate noStyle>
                              {({ getFieldValue }) => {
                                if (!getFieldValue("persona")) {
                                  return (
                                    <FormItem
                                      margin
                                      pattern
                                      name="apellidoPaterno"
                                      labelCol={{ span: 0 }}
                                      wrapperCol={{ span: 24 }}
                                    >
                                      <InputItem
                                        placeholder="Apelido Paterno"
                                        width="98%"
                                      />
                                    </FormItem>
                                  );
                                }
                              }}
                            </FormItem>
                          </Col>
                          <Col span={7} xs={24} sm={24}  lg={7} xl={7}>
                            <FormItem shouldUpdate noStyle>
                              {({ getFieldValue }) => {
                                if (!getFieldValue("persona")) {
                                  return (
                                    <FormItem
                                      margin
                                      pattern
                                      name="apellidoMaterno"
                                      labelCol={{ span: 0 }}
                                      wrapperCol={{ span: 24 }}
                                    >
                                      <InputItem placeholder="Apellido Materno" />
                                    </FormItem>
                                  );
                                }
                              }}
                            </FormItem>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24} xs={24} sm={24}  lg={24} xl={24}>
                            <FormItem
                              label="Nombre Comercial"
                              margin
                              pattern
                              max={200}
                              name="nombreComercial"
                              labelCol={{ span: 3 }}
                              wrapperCol={{ span: 21 }}
                            >
                              <InputItem placeholder="Nombre Comercial" />
                            </FormItem>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={12} xs={24} sm={24}  lg={12} xl={12}>
                            <FormItem
                              label="Celular"
                              name="celular"
                              number
                              margin
                              labelCol={{ span: 6 }}
                              wrapperCol={{ span: 14 }}
                            >
                              <InputNumberItem placeholder="Celular" celular />
                            </FormItem>
                            <FormItem
                              label="Teléfono"
                              name="telefono"
                              number
                              margin
                              labelCol={{ span: 6 }}
                              wrapperCol={{ span: 14 }}
                            >
                              <InputNumberItem placeholder="Teléfono" celular />
                            </FormItem>
                          </Col>
                          <Col span={12} xs={24} sm={24}  lg={12} xl={12}>
                            <FormItem
                              label="Página Web"
                              name="paginaWeb"
                              pattern
                              max={200}
                              margin
                              labelCol={{ span: 8 }}
                              wrapperCol={{ span: 16 }}
                            >
                              <InputItem placeholder="Página Web" />
                            </FormItem>
                            <FormItem
                              label="Correo"
                              name="correo"
                              pattern
                              max={200}
                              email
                              margin
                              labelCol={{ span: 8 }}
                              wrapperCol={{ span: 16 }}
                            >
                              <InputItem placeholder="Correo" />
                            </FormItem>
                          </Col>
                        </Row>
                        <Col span={24} xs={24} sm={24}  lg={24} xl={24}>
                          <FormItem
                            name="observaciones"
                            label="Observaciones"
                            margin
                            pattern
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 20 }}
                          >
                            <TextAreaItem placeholder="Observaciones" />
                          </FormItem>
                        </Col>
                      </BoxItem>
                    </Col>
                    <Col span={12} xs={24} sm={24}  lg={12} xl={12}>
                      <HeaderItem>DATOS VENTA</HeaderItem>
                      <BoxItem>
                        <Row>
                          <Col span={24} xs={24} sm={24}  lg={24} xl={24}>
                            <FormItem
                              label="Tipo Cliente"
                              margin
                              name="tipoClienteId"
                              required
                              labelCol={{ span: 5 }}
                              wrapperCol={{ span: 19 }}
                            >
                              <SelectItem
                                //width='120%'
                                disabled={this.state.utilizado}
                                placeholder="Tipo de Cliente"
                                onChange={this.onChangeTipoDeCliente.bind(this)}

                              >
                                {this.state.objetoTipoDeCliente}
                              </SelectItem>
                            </FormItem>
                            <FormItem
                              name="regimenFiscalId"
                              label="R. Fiscal"
                              margin
                              tooltip={{ title: "Campo necesario para facturación" }}
                              labelCol={{ span: 5 }}
                              wrapperCol={{ span: 19 }}
                            >
                              <SelectItem onChange={this.onChangeRegimenFiscal.bind(this)} placeholder="Regimen Fiscal">
                                {this.state.objetoRegimenFiscal}
                              </SelectItem>
                            </FormItem>
                            <FormItem
                              name="usoDeCFDIID"
                              label="Uso de CFDI"
                              margin
                              labelCol={{ span: 5 }}
                              wrapperCol={{ span: 19 }}
                            >
                              <SelectItem placeholder="Uso de CFDI">
                                {this.state.objetoUsosDeCFDI}
                              </SelectItem>
                            </FormItem>
                            <FormItem
                              name="proyectoId"
                              fieldKey="proyectoId"
                              label="Proyecto"
                              margin
                              labelCol={{ span: 5 }}
                              wrapperCol={{ span: 19 }}
                            >
                              <SelectItem placeholder="Proyecto">
                                {this.state.objetoProyectos}
                              </SelectItem>
                            </FormItem>
                            <FormItem
                              name="vendedorId"
                              fieldKey="vendedorId"
                              label="Vendedor"
                              margin
                              labelCol={{ span: 5 }}
                              wrapperCol={{ span: 19 }}
                            >
                              <SelectItem placeholder="Vendedor">
                                {this.state.objetoVendedores}
                              </SelectItem>
                            </FormItem>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={8} xs={24} sm={24}  lg={8} xl={8}>
                            <FormItem
                              label="Días crédito"
                              name="diasCredito"
                              numberCero
                              margin
                              labelCol={{ span: 15 }}
                              wrapperCol={{ span: 8 }}
                            >
                              <InputNumberItem />
                            </FormItem>
                          </Col>
                          <Col span={8} xs={24} sm={24}  lg={8} xl={8}>
                            <FormItem
                              label="Crédito"
                              name="limiteCredito"
                              numberCero
                              margin
                              labelCol={{ span: 10 }}
                              wrapperCol={{ span: 12 }}
                            >
                              <InputNumberItem dinero />
                            </FormItem>
                          </Col>
                          <Col span={8} xs={24} sm={24}  lg={8} xl={8}>
                            <FormItem
                              label="Desc. Máx"
                              name="limiteDescuento"
                              numberCero
                              margin
                              labelCol={{ span: 16 }}
                              wrapperCol={{ span: 8 }}
                            >
                              <InputNumberItem porcentaje />
                            </FormItem>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={12} xs={24} sm={24}  lg={12} xl={12}>
                            <FormItem
                              name={"precioContadoId"}
                              fieldKey={"precioContadoId"}
                              label="P. Contado"
                              margin
                              labelCol={{ span: 10 }}
                              wrapperCol={{ span: 12 }}
                            >
                              <SelectItem placeholder="Precio Contado">
                                {this.state.objetoPrecios}
                              </SelectItem>
                            </FormItem>
                          </Col>
                          <Col span={12} xs={24} sm={24}  lg={12} xl={12}>
                            <FormItem
                              name={"precioCreditoId"}
                              fieldKey={"precioCreditoId"}
                              label="P. Credito"
                              margin
                              labelCol={{ span: 12 }}
                              wrapperCol={{ span: 12 }}
                            >
                              <SelectItem placeholder="Precio Credito">
                                {this.state.objetoPrecios}
                              </SelectItem>
                            </FormItem>
                          </Col>
                        </Row>
                      </BoxItem>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Contacto" tabKey={2} key={2} forceRender>
                  <Row>
                    <Col span={12} xs={24} sm={24}  lg={12} xl={12}>
                      <HeaderItem>CORREOS ADICIONALES</HeaderItem>
                      <BoxItem>
                        <FormInitial.List
                          name="correosAdicionales"
                        >
                          {(fields, { add, remove }) => (
                            <>
                              {fields.map(({ name }, arrayKey) => (
                                <Row>
                                  <Col span={22} xs={22} sm={22}  lg={22} xl={22}>
                                    <FormItem
                                      name={[name, "correo"]}
                                      fieldKey={[arrayKey, "correo"]}
                                      required
                                      margin
                                      noStyle
                                      email
                                      pattern
                                    >
                                      <InputItem placeholder="Correo" />
                                    </FormItem>
                                  </Col>
                                  <Col span={2} xs={2} sm={2}  lg={2} xl={2}>
                                    <div style={{ textAlign: 'center' }}>
                                      <MinusCircleOutlined
                                        onClick={() => remove(name)}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              ))}
                              {fields.length < 5 ?
                                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                  <ButtonItem
                                    type="primary"
                                    onClick={() => add()}
                                    icon={<PlusOutlined />}
                                  >
                                    Agregar
                                  </ButtonItem>
                                </div>

                                : null}
                            </>
                          )}
                        </FormInitial.List>
                      </BoxItem>
                    </Col>
                    <Col span={12} xs={24} sm={24}  lg={12} xl={12}>
                      <HeaderItem>DATOS DE CONTACTO</HeaderItem>
                      <BoxItem>
                        <Row>
                          <Col span={24} xs={24} sm={24}  lg={24} xl={24}>
                            <FormItem
                              label="Nombre"
                              name="nombreContacto"
                              pattern
                              max={200}
                              margin
                              labelCol={{ span: 3 }}
                              wrapperCol={{ span: 21 }}
                            >
                              <InputItem placeholder="Nombre de Contacto" />
                            </FormItem>
                            <Row>
                              <Col span={12} xs={24} sm={24}  lg={12} xl={12}>
                                <FormItem
                                  label="Teléfono"
                                  name="telefonoContacto"
                                  number
                                  margin
                                  labelCol={{ span: 6 }}
                                  wrapperCol={{ span: 18 }}
                                >
                                  <InputNumberItem
                                    placeholder="Teléfono de Contacto"
                                    celular
                                  />
                                </FormItem>
                              </Col>
                              <Col span={12} xs={24} sm={24}  lg={12} xl={12}>
                                <FormItem
                                  label="Correo"
                                  name="correoContacto"
                                  pattern
                                  max={200}
                                  email
                                  margin
                                  labelCol={{ span: 6 }}
                                  wrapperCol={{ span: 18 }}
                                >
                                  <InputItem placeholder="Correo de Contacto" />
                                </FormItem>
                              </Col>
                            </Row>
                            <FormItem
                              label="Personas pedidos"
                              name="personasPedidos"
                              pattern
                              max={200}
                              margin
                              labelCol={{ span: 5 }}
                              wrapperCol={{ span: 19 }}
                            >
                              <InputItem placeholder="Personas que atienden pedidos" />
                            </FormItem>
                          </Col>
                        </Row>
                      </BoxItem>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Retenciones" tabKey={3} key={3} forceRender>
                  <HeaderItem>RETENCIONES</HeaderItem>
                  <BoxItem>
                  <FormInitial.List
                      name="retenciones"
                    >
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ name }, arrayKey) => (
                            <Row>
                              <Col span={22} xs={22} sm={22}  lg={22} xl={22}>
                                <FormItem
                                  name={[name, "retencionId"]}
                                  fieldKey={[arrayKey, "retencionId"]}
                                  required
                                  margin
                                  noStyle
                                >
                                  <SelectItem disabled={this.state.utilizado} width='100%' placeholder="Retención">
                                    {this.state.objetoRetenciones}
                                  </SelectItem>
                                </FormItem>
                              </Col>
                              <Col span={2} xs={2} sm={2}  lg={2} xl={2}>
                                <div style={{ textAlign: 'center' }}>
                                  {!this.state.utilizado &&
                                    <MinusCircleOutlined
                                      onClick={() => remove(name)}
                                    />
                                  }
                                </div>
                              </Col>
                            </Row>
                          ))}
                          <div style={{ textAlign: 'center' }}>
                            {!this.state.utilizado &&
                              <ButtonItem
                                type="primary"
                                onClick={() => add()}
                                icon={<PlusOutlined />}
                              >
                                Agregar
                              </ButtonItem>
                            }
                          </div>
                        </>
                      )}
                    </FormInitial.List>
                  </BoxItem>
                </TabPane>
                <TabPane tab="Direcciones" tabKey={4} key={4} forceRender>
                  <FormInitial.List name="direcciones">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, fieldKey, ...restField }, arrayKey) => (
                          <>
                            {arrayKey === 0 && <HeaderItem>DIRECCION DE FACTURACION</HeaderItem>}
                            {arrayKey === 0 && <p style={{ textAlign: 'center' }}>Campos necesarios para facturación</p>}
                            <Row>
                              <Col span={12} xs={24} sm={24}  lg={12} xl={12}>
                                <HeaderItem>DIRECCION</HeaderItem>
                                <BoxItem>
                                  <Row>
                                    <Col span={16} xs={24} sm={24}  lg={16} xl={16}>
                                      <FormItem
                                        {...restField}
                                        name={[name, "calle"]}
                                        fieldKey={[fieldKey, "calle"]}
                                        label="Dirección"
                                        max={200}
                                        margin
                                        pattern
                                        labelCol={{ span: 3 }}
                                        wrapperCol={{ span: 21 }}
                                      >
                                        <InputItem
                                          placeholder="Calle"
                                          width="98%"
                                        />
                                      </FormItem>
                                    </Col>
                                    <Col span={4} xs={24} sm={24}  lg={4} xl={4}>
                                      <FormItem
                                        {...restField}
                                        name={[name, "numeroCalle"]}
                                        fieldKey={[fieldKey, "numeroCalle"]}
                                        margin
                                        number
                                        labelCol={{ span: 0 }}
                                        wrapperCol={{ span: 24 }}
                                      >
                                        <InputItem
                                          placeholder="Número"
                                          numeroCalle
                                          width="98%"
                                        />
                                      </FormItem>
                                    </Col>
                                    <Col span={4} xs={24} sm={24}  lg={4} xl={4}>
                                      <FormItem
                                        name={[name, "numeroInterior"]}
                                        fieldKey={[fieldKey, "numeroInterior"]}
                                        margin
                                        pattern
                                        labelCol={{ span: 0 }}
                                        wrapperCol={{ span: 24 }}
                                      >
                                        <InputItem
                                          placeholder="Interior"
                                          width="98%"
                                        />
                                      </FormItem>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col span={8} xs={24} sm={24}  lg={8} xl={8}>
                                      <FormItem
                                        {...restField}
                                        name={[name, "coloniaId"]}
                                        fieldKey={[fieldKey, "coloniaId"]}
                                        label="C.P."
                                        margin
                                        required={arrayKey === 0}
                                        labelCol={{ span: 6 }}
                                        wrapperCol={{ span: 18 }}
                                      >
                                        <SelectItem placeholder="Código Postal"
                                          onSearch={(coloniaId) => {
                                            this.onSearchColonia(coloniaId, arrayKey)
                                          }}
                                          onSelect={(coloniaId) => {
                                            this.onSelectColoniaId(coloniaId, arrayKey)
                                          }}
                                        >
                                          {!this.state.objetoColonias[
                                            fieldKey
                                          ] ? (
                                            <Option disabled>
                                              Eliga primero un municipio
                                            </Option>
                                          ) : (
                                            this.state.objetoColonias[fieldKey]
                                          )}
                                          <Option disabled>Busque por codigo postal</Option>
                                        </SelectItem>
                                      </FormItem>
                                    </Col>
                                    <Col span={16} xs={24} sm={24}  lg={16} xl={16}>
                                      <FormItem
                                        {...restField}
                                        name={[name, "colonia"]}
                                        fieldKey={[fieldKey, "colonia"]}
                                        label="Colonia"
                                        margin
                                        pattern
                                        labelCol={{ span: 3 }}
                                        wrapperCol={{ span: 21 }}
                                      >
                                        <InputItem placeholder="Colonia" />
                                      </FormItem>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col span={8} xs={24} sm={24}  lg={8} xl={8}>
                                      <FormItem
                                        {...restField}
                                        name={[name, "municipioId"]}
                                        fieldKey={[fieldKey, "municipioId"]}
                                        margin
                                        labelCol={{ span: 0 }}
                                        wrapperCol={{ span: 24 }}
                                      >
                                        <SelectItem
                                          placeholder="Municipio"
                                          width="98%"
                                          onChange={(e) =>
                                            this.onChangeMunicipio(e, fieldKey)
                                          }
                                        >
                                          {!this.state.objetoMunicipios[
                                            fieldKey
                                          ] ? (
                                            <Option disabled>
                                              Eliga primero un estado
                                            </Option>
                                          ) : (
                                            this.state.objetoMunicipios[
                                            fieldKey
                                            ]
                                          )}
                                        </SelectItem>
                                      </FormItem>
                                    </Col>
                                    <Col span={8} xs={24} sm={24}  lg={8} xl={8}>
                                      <FormItem
                                        {...restField}
                                        name={[name, "estadoId"]}
                                        fieldKey={[fieldKey, "estadoId"]}
                                        margin
                                        labelCol={{ span: 0 }}
                                        wrapperCol={{ span: 24 }}
                                      >
                                        <SelectItem
                                          placeholder="Estado"
                                          width="98%"
                                          onChange={(e) =>
                                            this.onChangeEstado(e, fieldKey)
                                          }
                                        >
                                          {this.state.objetoEstados}
                                        </SelectItem>
                                      </FormItem>
                                    </Col>
                                    <Col span={8} xs={24} sm={24}  lg={8} xl={8}>
                                      <FormItem
                                        {...restField}
                                        name={[name, "paisId"]}
                                        fieldKey={[fieldKey, "paisId"]}
                                        margin
                                        labelCol={{ span: 0 }}
                                        wrapperCol={{ span: 24 }}
                                      >
                                        <SelectItem
                                          placeholder="Pais"
                                          width="98%"
                                        >
                                          {this.state.objetoPaises}
                                        </SelectItem>
                                      </FormItem>
                                    </Col>
                                  </Row>
                                </BoxItem>
                              </Col>
                              <Col span={12} xs={24} sm={24}  lg={12} xl={12}>
                                <HeaderItem>CONTACTO EN DIRECCION</HeaderItem>
                                <BoxItem>
                                  <Row>
                                    <Col span={24} xs={24} sm={24}  lg={24} xl={24}>
                                      <FormItem
                                        {...restField}
                                        name={[name, "descripcion"]}
                                        fieldKey={[fieldKey, "descripcion"]}
                                        label="Contacto"
                                        margin
                                        pattern
                                        labelCol={{ span: 3 }}
                                        wrapperCol={{ span: 21 }}
                                      >
                                        <InputItem placeholder="Contacto" />
                                      </FormItem>
                                      <Row>
                                        <Col span={8} xs={24} sm={24}  lg={8} xl={8}>
                                          <FormItem
                                            label="Telefono"
                                            name={[name, "telefono"]}
                                            fieldKey={[fieldKey, "telefono"]}
                                            number
                                            margin
                                            labelCol={{ span: 9 }}
                                            wrapperCol={{ span: 17 }}
                                          >
                                            <InputNumberItem
                                              placeholder="Teléfono"
                                              celular
                                            />
                                          </FormItem>
                                        </Col>
                                        <Col span={16} xs={24} sm={24}  lg={16} xl={16}>
                                          <FormItem
                                            label="Correo"
                                            name={[name, "correo"]}
                                            fieldKey={[fieldKey, "correo"]}
                                            pattern
                                            max={200}
                                            email
                                            margin
                                            labelCol={{ span: 5 }}
                                            wrapperCol={{ span: 19 }}
                                          >
                                            <InputItem placeholder="Correo" />
                                          </FormItem>
                                        </Col>
                                      </Row>
                                      <FormItem
                                        {...restField}
                                        name={[name, "observaciones"]}
                                        fieldKey={[fieldKey, "observaciones"]}
                                        label="Observaciones"
                                        margin
                                        pattern
                                        labelCol={{ span: 3 }}
                                        wrapperCol={{ span: 21 }}
                                      >
                                        <InputItem placeholder="Observaciones Dirección" />
                                      </FormItem>
                                    </Col>
                                  </Row>
                                  <FormItem noStyle>
                                    <div style={{ display: 'flex', justifyContent: 'right', width: '100%' }}>
                                      <ButtonItem
                                        type="primary"
                                        danger
                                        onClick={() => remove(name)}
                                      // block
                                      // icon={<MinusCircleOutlined />}
                                      >
                                        Eliminar Dirección
                                      </ButtonItem>
                                    </div>
                                  </FormItem>
                                </BoxItem>
                              </Col>
                            </Row>
                          </>
                        ))}
                        <FormItem noStyle>
                          <ButtonItem
                            style={{ marginTop: 10, marginBottom: 10 }}
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Agregar Dirección
                          </ButtonItem>
                        </FormItem>
                      </>
                    )}
                  </FormInitial.List>
                </TabPane>
                {this.state.mercanciasEnvio || this.props.fletes ?
                  <TabPane tab="Mercancias" tabKey="8" key="8" forceRender>
                    <Spin spinning={this.state.loadingMercancias}>
                      <FormInitial.List name="mercanciasEnvio">
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name, fieldKey, ...restField }, arrayKey) => (
                              <>
                                <HeaderItem>INSUMO {arrayKey + 1}</HeaderItem>
                                <BoxItem color='green'>
                                  <HeaderItem>GENERALES</HeaderItem>
                                  <BoxItem>
                                    <Row>
                                      <Col span={12} xs={24} sm={24}  lg={12} xl={12}>
                                        <FormItem
                                          label="Descripción"
                                          {...restField}
                                          name={[name, "descripcion"]}
                                          fieldKey={[arrayKey, "descripcion"]}
                                          required
                                          margin
                                          labelCol={{ span: 8 }}
                                          wrapperCol={{ span: 16 }}
                                          pattern
                                        >
                                          <InputItem
                                            placeholder='Descripción'
                                          />
                                        </FormItem>
                                      </Col>
                                      <Col span={12} xs={24} sm={24}  lg={12} xl={12}>
                                        <FormItem
                                          label="Unidad"
                                          margin
                                          labelCol={{ span: 8 }}
                                          wrapperCol={{ span: 16 }}
                                        >
                                          <Input.Group compact>
                                            <FormItem
                                              {...restField}
                                              name={[name, "unidadId"]}
                                              fieldKey={[arrayKey, "unidadId"]}
                                              required noStyle>
                                              <SelectItem placeholder="Unidad" width="70%">
                                                {this.state.objetoUnidades}
                                              </SelectItem>
                                            </FormItem>
                                            <ButtonItem

                                              icon={<PlusOutlined />}
                                              style={{ width: "15%" }}
                                              onClick={this.abrirAgregarUnidad.bind(this)}
                                            />
                                          </Input.Group>
                                        </FormItem>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col span={12} xs={24} sm={24}  lg={12} xl={12}>
                                        <FormItem
                                          label="Valor"
                                          {...restField}
                                          name={[name, "valorMercancia"]}
                                          fieldKey={[arrayKey, "valorMercancia"]}
                                          required
                                          margin
                                          labelCol={{ span: 8 }}
                                          wrapperCol={{ span: 16 }}
                                          numberCero
                                        >
                                          <InputNumberItem

                                            placeholder="Valor Mercancia"
                                            dinero
                                          />
                                        </FormItem>
                                      </Col>
                                      <Col span={12} xs={24} sm={24}  lg={12} xl={12}>
                                        <FormItem
                                          label="Peso en KGM"
                                          {...restField}
                                          name={[name, "pesoEnKGM"]}
                                          fieldKey={[arrayKey, "pesoEnKGM"]}
                                          margin
                                          numberCero
                                        >
                                          <InputNumberItem placeholder="Peso en KGM" />
                                        </FormItem>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col span={24} xs={24} sm={24}  lg={24} xl={24}>
                                        <FormItem
                                          label="Codigo Sat"
                                          {...restField}
                                          name={[name, "codigoSatId"]}
                                          fieldKey={[arrayKey, "codigoSatId"]}
                                          required
                                          margin
                                          labelCol={{ span: 4 }}
                                          wrapperCol={{ span: 20 }}
                                        >
                                          <SelectItem

                                            mostrarCarga
                                            loading={this.state.buscarInfoCodes[arrayKey] ? this.state.buscarInfoCodes[arrayKey] : false}
                                            placeholder="Codigo Sat" onSearch={(value) => { this.onChangeCodigo(value, arrayKey) }
                                            }>
                                            <Option disabled>Escriba para buscar el codigo</Option>
                                            {this.state.objetoCodigosSat[arrayKey] ? this.state.objetoCodigosSat[arrayKey] : null}
                                          </SelectItem>
                                        </FormItem>
                                      </Col>
                                    </Row>
                                    <Row style={{ textAlign: 'center', justifyContent: 'center' }}>
                                      <ButtonItem

                                        style={{ width: "50%", marginTop: "10px" }}
                                        onClick={() => window.open("http://pys.sat.gob.mx/PyS/catPyS.aspx", "_blank")} // Abre en una nueva pestaña
                                      >Conoce tu codigo sat</ButtonItem>
                                    </Row>
                                  </BoxItem>
                                  <HeaderItem>DATOS MERCANCIA</HeaderItem>
                                  <BoxItem>
                                    <Row>
                                      <Col span={12} xs={24} sm={24}  lg={12} xl={12}>
                                        <FormItem
                                          {...restField}
                                          name={[name, "dimensiones"]}
                                          fieldKey={[arrayKey, "dimensiones"]}
                                          label="Dimensiones"
                                          margin
                                          labelCol={{ span: 8 }}
                                          wrapperCol={{ span: 16 }}
                                          pattern
                                        >
                                          <InputItem placeholder="Ejemplo:  59/40/36plg" />
                                        </FormItem>
                                        <FormItem
                                          label="Descripcion Materia"
                                          {...restField}
                                          name={[name, "descripcionMateria"]}
                                          fieldKey={[arrayKey, "descripcionMateria"]}
                                          margin
                                          pattern
                                          labelCol={{ span: 8 }}
                                          wrapperCol={{ span: 16 }}
                                          tooltip={{ title: "Atributo opcional para expresar la clave de producto de la STCC (por sus siglas en inglés, Standard Transportation Commodity Code), cuando el medio de transporte utilizado para el traslado de los bienes y/o mercancías sea ferroviario." }}
                                        >
                                          <InputItem placeholder="Descripcion Materia" />
                                        </FormItem>
                                        <FormItem
                                          label="Material Peligroso"
                                          {...restField}
                                          name={[name, "materialPeligroso"]}
                                          fieldKey={[arrayKey, "materialPeligroso"]}
                                          margin
                                          initialValue={false}
                                          valuePropName="checked"
                                        >
                                          <SwitchItem />
                                        </FormItem>
                                        <FormItem shouldUpdate noStyle>
                                          {({ getFieldValue }) => {
                                            if (getFieldValue('materialPeligroso')) {
                                              return (
                                                <>
                                                  <FormItem
                                                    label="Material Peligroso"
                                                    {...restField}
                                                    name={[name, "materialPeligrosoId"]}
                                                    fieldKey={[arrayKey, "materialPeligrosoId"]}
                                                    margin
                                                    required
                                                  >
                                                    <SelectItem placeholder="Material Peligroso" width="114%">
                                                      {this.state.objetoMaterialesPeligrosos}
                                                    </SelectItem>
                                                  </FormItem>
                                                  <FormItem
                                                    label="Numero CAS"
                                                    {...restField}
                                                    name={[name, "numCAS"]}
                                                    fieldKey={[arrayKey, "numCAS"]}
                                                    margin
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    pattern
                                                    tooltip={{ title: "Atributo condicional para expresar el número Chemical Abstracts Service (CAS) con el que se identifica el compuesto químico de la sustancia tóxica." }}
                                                  >
                                                    <InputItem placeholder="Numero CAS" />
                                                  </FormItem>
                                                </>
                                              )
                                            }
                                          }}
                                        </FormItem>
                                      </Col>
                                      <Col span={12} xs={24} sm={24}  lg={12} xl={12}>
                                        <FormItem
                                          label="Clave STCC"
                                          {...restField}
                                          name={[name, "claveSTCC"]}
                                          fieldKey={[arrayKey, "claveSTCC"]}
                                          margin
                                          numberCero
                                          tooltip={{ title: "Atributo opcional para expresar la clave de producto de la STCC (por sus siglas en inglés, Standard Transportation Commodity Code), cuando el medio de transporte utilizado para el traslado de los bienes y/o mercancías sea ferroviario." }}
                                        >
                                          <InputNumberItem placeholder="Clave STCC" />
                                        </FormItem>
                                        <FormItem
                                          label="Tipo Materia"
                                          {...restField}
                                          name={[name, "tipoMateriaId"]}
                                          fieldKey={[arrayKey, "tipoMateriaId"]}
                                          margin
                                          tooltip={{ title: "Atributo condicional para expresar el estado de la materia o producto al realizar una operación de comercio exterior a través de los distintos medios de transporte." }}
                                        >
                                          <SelectItem placeholder="Tipo Materia" width="100%">
                                            {this.state.objetoTiposMaterias}
                                          </SelectItem>
                                        </FormItem>
                                        <FormItem shouldUpdate noStyle>
                                          {({ getFieldValue }) => {
                                            if (getFieldValue('materialPeligroso')) {
                                              return (
                                                <>
                                                  <FormItem
                                                    label="Embalaje"
                                                    {...restField}
                                                    name={[name, "embalajeId"]}
                                                    fieldKey={[arrayKey, "embalajeId"]}
                                                    margin
                                                    required
                                                  >
                                                    <SelectItem placeholder="Embalaje" width="100%">
                                                      {this.state.objetoEmbalajes}
                                                    </SelectItem>
                                                  </FormItem>
                                                  <FormItem
                                                    label="Descripcion Embalaje"
                                                    {...restField}
                                                    name={[name, "descripcionEmbalaje"]}
                                                    fieldKey={[arrayKey, "descripcionEmbalaje"]}
                                                    margin
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    pattern
                                                    required
                                                  >
                                                    <InputItem placeholder="Ejemplo: Cajas de madera contrachapada" />
                                                  </FormItem>
                                                </>
                                              )
                                            }
                                          }}
                                        </FormItem>
                                      </Col>
                                    </Row>
                                  </BoxItem>
                                  <HeaderItem>SECTOR COFEPRIS</HeaderItem>
                                  <BoxItem>
                                    <Row>
                                      <Col span={12} xs={24} sm={24}  lg={12} xl={12}>
                                        <FormItem
                                          label="Sector COFEPRIS"
                                          {...restField}
                                          name={[name, "sectorCOFEPRIS"]}
                                          fieldKey={[arrayKey, "sectorCOFEPRIS"]}
                                          margin
                                          initialValue={false}
                                          valuePropName="checked"
                                        >
                                          <SwitchItem />
                                        </FormItem>
                                        <FormItem shouldUpdate noStyle>
                                          {({ getFieldValue }) => {
                                            if (getFieldValue('sectorCOFEPRIS')) {
                                              return (
                                                <>
                                                  <FormItem
                                                    label="Sector COFEPRIS"
                                                    {...restField}
                                                    name={[name, "sectorCOFEPRISId"]}
                                                    fieldKey={[arrayKey, "sectorCOFEPRISId"]}
                                                    margin
                                                    required
                                                  >
                                                    <SelectItem placeholder="Sector COFEPRIS" width="100%">
                                                      {this.state.objetoSectoresCOF}
                                                    </SelectItem>
                                                  </FormItem>
                                                  <FormItem
                                                    label="Ingrediente Activo"
                                                    {...restField}
                                                    name={[name, "nombreIngredienteActivo"]}
                                                    fieldKey={[arrayKey, "nombreIngredienteActivo"]}
                                                    margin
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    pattern
                                                    tooltip={{ title: "Atributo condicional para expresar el nombre común del ingrediente activo de los precursores, químicos de uso dual, plaguicidas o fertilizantes que se trasladan a través de los distintos medios de transporte" }}
                                                  >
                                                    <InputItem placeholder="Ingrediente Activo" />
                                                  </FormItem>
                                                  <FormItem
                                                    label="Nombre Quimico"
                                                    {...restField}
                                                    name={[name, "nombreQuimico"]}
                                                    fieldKey={[arrayKey, "nombreQuimico"]}
                                                    margin
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    pattern
                                                    tooltip={{ title: "Atributo condicional para expresar el nombre de la sustancia activa de los precursores, químicos de uso dual o sustancias tóxicas que se traslada a través de los distintos medios de transporte." }}
                                                  >
                                                    <InputItem placeholder="Nombre Quimico" />
                                                  </FormItem>
                                                  <FormItem
                                                    label="Denominacion Generica"
                                                    {...restField}
                                                    name={[name, "denominacionGenericaProd"]}
                                                    fieldKey={[arrayKey, "denominacionGenericaProd"]}
                                                    margin
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    pattern
                                                    tooltip={{ title: "Atributo condicional para expresar el fármaco o la sustancia activa del medicamento, psicotrópico o estupefaciente que se traslada a través de los distintos medios de transporte." }}
                                                  >
                                                    <InputItem placeholder="Denominacion Generica" />
                                                  </FormItem>
                                                  <FormItem
                                                    label="Denominacion Distintiva"
                                                    {...restField}
                                                    name={[name, "denominacionDistintivaProd"]}
                                                    fieldKey={[arrayKey, "denominacionDistintivaProd"]}
                                                    margin
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    pattern
                                                    tooltip={{ title: "Atributo condicional para expresar la marca con la que se comercializa el producto o nombre que le asigna el laboratorio o fabricante a sus especialidades farmacéuticas con el fin de distinguirlas de otras similares del medicamento, psicotrópico o estupefaciente que se traslada a través de los distintos medios de transporte." }}
                                                  >
                                                    <InputItem placeholder="Denominacion Distintiva" />
                                                  </FormItem>
                                                </>
                                              )
                                            }
                                          }}
                                        </FormItem>
                                      </Col>
                                      <Col span={12} xs={24} sm={24}  lg={12} xl={12}>
                                        <FormItem shouldUpdate noStyle>
                                          {({ getFieldValue }) => {
                                            if (getFieldValue('sectorCOFEPRIS')) {
                                              return (
                                                <>
                                                  <FormItem
                                                    label="Fabricante"
                                                    {...restField}
                                                    name={[name, "fabricante"]}
                                                    fieldKey={[arrayKey, "fabricante"]}
                                                    margin
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    pattern
                                                    tooltip={{ title: "Atributo condicional para expresar el nombre o razón social del establecimiento que realiza la fabricación o manufactura del medicamento, precursor, químico de uso dual, psicotrópico o estupefaciente que se traslada a través de los distintos medios de transporte." }}
                                                  >
                                                    <InputItem placeholder="Fabricante" />
                                                  </FormItem>
                                                  <FormItem
                                                    label="Forma Farmaceutica"
                                                    {...restField}
                                                    name={[name, "formaFarmaceuticaId"]}
                                                    fieldKey={[arrayKey, "formaFarmaceuticaId"]}
                                                    margin
                                                  >
                                                    <SelectItem placeholder="Forma Farmaceutica" width="100%">
                                                      {this.state.objetoFormasFarmaceuticas}
                                                    </SelectItem>
                                                  </FormItem>
                                                  <FormItem
                                                    label="Condiciones Especiales"
                                                    tooltip={{ title: "Atributo condicional para expresar la condición en la cual es necesario mantener el medicamento, precursor, químico de uso dual, psicotrópicos o estupefacientes durante el traslado y almacenamiento." }}
                                                    {...restField}
                                                    name={[name, "condicionEspecialId"]}
                                                    fieldKey={[arrayKey, "condicionEspecialId"]}
                                                    margin
                                                  >
                                                    <SelectItem placeholder="Condiciones Especiales" width="100%">
                                                      {this.state.objetoCondicionesEspeciales}
                                                    </SelectItem>
                                                  </FormItem>
                                                  <FormItem
                                                    label="Registro Sanitario"
                                                    {...restField}
                                                    name={[name, "registroSanitarioFolioAutorizacion"]}
                                                    fieldKey={[arrayKey, "registroSanitarioFolioAutorizacion"]}
                                                    margin
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    pattern
                                                    tooltip={{ title: "Atributo condicional para expresar el registro sanitario o folio de autorización con el que cuenta la empresa para el traslado del medicamento, psicotrópico o estupefaciente." }}
                                                  >
                                                    <InputItem placeholder="Registro Sanitario" />
                                                  </FormItem>
                                                  <FormItem
                                                    label="Numero Registro"
                                                    {...restField}
                                                    name={[name, "numRegSanPlagCOFEPRIS"]}
                                                    fieldKey={[arrayKey, "numRegSanPlagCOFEPRIS"]}
                                                    margin
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    pattern
                                                    tooltip={{ title: "Atributo condicional para expresar el número de registro sanitario para plaguicidas o fertilizantes cuya importación, comercialización y uso están permitidos en México, mismo que emite la Comisión Intersecretarial para el Control del Proceso y Uso de Plaguicidas, Fertilizantes y Sustancias Tóxicas (CICLOPLAFEST)." }}
                                                  >
                                                    <InputItem placeholder="Numero Registro" />
                                                  </FormItem>
                                                  <FormItem
                                                    label="Datos Fabricante"
                                                    {...restField}
                                                    name={[name, "datosFabricante"]}
                                                    fieldKey={[arrayKey, "datosFabricante"]}
                                                    margin
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    pattern
                                                    tooltip={{ title: "Atributo condicional para registrar el país y nombre o razón social de quien produce o fabrica el ingrediente activo del plaguicida o fertilizante." }}
                                                  >
                                                    <InputItem placeholder="Datos Fabricante" />
                                                  </FormItem>
                                                  <FormItem
                                                    label="Datos Formulador"
                                                    {...restField}
                                                    name={[name, "datosFormulador"]}
                                                    fieldKey={[arrayKey, "datosFormulador"]}
                                                    margin
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    pattern
                                                    tooltip={{ title: "Atributo condicional para registrar el país y nombre o razón social de quien formula el ingrediente activo del plaguicida o fertilizante." }}
                                                  >
                                                    <InputItem placeholder="Datos Formulador" />
                                                  </FormItem>
                                                  <FormItem
                                                    label="Datos Maquilador"
                                                    {...restField}
                                                    name={[name, "datosMaquilador"]}
                                                    fieldKey={[arrayKey, "datosMaquilador"]}
                                                    margin
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    pattern
                                                    tooltip={{ title: "Atributo condicional para registrar el país y nombre o razón social de quien maquila el ingrediente activo del plaguicida o fertilizante." }}
                                                  >
                                                    <InputItem placeholder="Datos Maquilador" />
                                                  </FormItem>
                                                  <FormItem
                                                    label="Uso Autorizado"
                                                    {...restField}
                                                    name={[name, "usoAutorizado"]}
                                                    fieldKey={[arrayKey, "usoAutorizado"]}
                                                    margin
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    pattern
                                                    tooltip={{ title: "Atributo condicional para registrar el uso autorizado del plaguicida o fertilizante de acuerdo a la regulación del país." }}
                                                  >
                                                    <InputItem placeholder="Uso Autorizado" />
                                                  </FormItem>
                                                </>
                                              )
                                            }
                                          }}
                                        </FormItem>
                                      </Col>
                                    </Row>
                                  </BoxItem>
                                  <HeaderItem>DETALLE MERCANCIA</HeaderItem>
                                  <BoxItem>
                                    {this.state.unidadPeso ?
                                      <p style={{ textAlign: 'center' }}>Unidad de peso: {this.state.unidadPeso.nombre} - {this.state.unidadPeso.unidadSat.c_ClaveUnidad}</p>
                                      : null}
                                    <Row>
                                      <Col span={12} xs={24} sm={24}  lg={12} xl={12}>
                                        <FormItem
                                          label="Peso Bruto"
                                          {...restField}
                                          name={[name, "pesoBruto"]}
                                          fieldKey={[arrayKey, "pesoBruto"]}
                                          margin
                                          numberCero
                                        >
                                          <InputNumberItem placeholder="Peso Bruto" />
                                        </FormItem>
                                        <FormItem
                                          label="Peso Neto"
                                          {...restField}
                                          name={[name, "pesoNeto"]}
                                          fieldKey={[arrayKey, "pesoNeto"]}
                                          margin
                                          numberCero
                                        >
                                          <InputNumberItem placeholder="Peso Neto" />
                                        </FormItem>
                                      </Col>
                                      <Col span={12} xs={24} sm={24}  lg={12} xl={12}>
                                        <FormItem
                                          label="Peso Tara"
                                          {...restField}
                                          name={[name, "pesoTara"]}
                                          fieldKey={[arrayKey, "pesoTara"]}
                                          margin
                                          numberCero
                                        >
                                          <InputNumberItem placeholder="Peso Tara" />
                                        </FormItem>
                                        <ButtonItem
                                          type="primary"
                                          danger
                                          onClick={() => remove(name)}
                                        // block
                                        // icon={<MinusCircleOutlined />}
                                        >
                                          Eliminar Mercancia
                                        </ButtonItem>
                                      </Col>
                                    </Row>
                                  </BoxItem>
                                </BoxItem>
                              </>
                            ))}
                            <FormItem noStyle>
                              <ButtonItem
                                style={{ marginTop: 10, marginBottom: 10 }}
                                type="dashed"
                                onClick={() => add()}
                                block
                                icon={<PlusOutlined />}
                              >
                                Agregar Mercancia
                              </ButtonItem>
                            </FormItem>
                          </>
                        )}
                      </FormInitial.List>
                    </Spin>
                  </TabPane>
                  : null}
                <TabPane tab="Contabilidad" tabKey={5} key={5} forceRender>
                  <FormItem shouldUpdate noStyle>
                    {({ getFieldValue }) => {
                      const tipoDeClienteId = getFieldValue("tipoClienteId");
                      const tipoDeCliente = tipoDeClienteId
                        ? this.state.todasTipoDeCliente.find(
                          (valueTodos) =>
                            valueTodos._id.toString() ===
                            tipoDeClienteId.toString()
                        )
                        : null;
                      const disabled =
                        tipoDeCliente?.generarCuentasAutomatico &&
                          tipoDeClienteId !==
                          this.state.dataInicial?.tipoProveedorId &&
                          !this.state.dataInicial?.cuentaProveedorId
                          ? false
                          : true;
                      return (
                        <Row>
                          <Col span={12} xs={24} sm={24}  lg={12} xl={12}>
                            <Row>
                              <Col span={22} xs={24} sm={24}  lg={22} xl={22}>
                                <FormItem
                                  label="Cuenta Cliente"
                                  name="cuentaClienteId"
                                  margin
                                  tooltip="Selecciona primero un tipo de cliente"
                                >
                                  <SelectItem
                                    disabled
                                    placeholder="Cuenta Cliente"
                                    width="100%"
                                  >
                                    {this.state.objetoCuentaContable}
                                  </SelectItem>
                                </FormItem>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={22} xs={22} sm={22}  lg={22} xl={22}>
                                <FormItem
                                  name="cuentaAnticipoId"
                                  label="Cuenta Anticipo"
                                  margin
                                  tooltip="Selecciona primero un tipo de cliente"
                                >
                                  <SelectItem
                                    disabled
                                    placeholder="Cuenta Anticipo"
                                    width="100%"
                                  >
                                    {this.state.objetoCuentaContable}
                                  </SelectItem>
                                </FormItem>
                              </Col>
                              <Col span={2} xs={2} sm={2}  lg={2} xl={2} style={{ display: 'flex', flexDirection: 'column' }}>
                                <FormItem
                                  noStyle
                                  name="generarCuentaAnticipoId"
                                  valuePropName="checked"
                                  initialValue={true}
                                >
                                  <Checkbox disabled={disabled} style={{ marginTop: 'auto' }}></Checkbox>
                                </FormItem>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={12} xs={24} sm={24}  lg={12} xl={12}>
                            <Row>
                              <Col span={22} xs={22} sm={22}  lg={22} xl={22}>
                                <FormItem
                                  label="Cuenta Fon. Gar."
                                  tooltip="Selecciona primero un tipo de cliente"
                                  margin
                                  name="cuentaFonGarId"
                                >
                                  <SelectItem
                                    disabled
                                    placeholder="Cuenta Fondo de Gararantia"
                                    width="100%"
                                  >
                                    {this.state.objetoCuentaContable}
                                  </SelectItem>
                                </FormItem>
                              </Col>
                              <Col span={2} xs={2} sm={2}  lg={2} xl={2} style={{ display: 'flex', flexDirection: 'column' }}>
                                <FormItem
                                  noStyle
                                  name="generarCuentaFonGarId"
                                  valuePropName="checked"
                                  initialValue={true}
                                >
                                  <Checkbox disabled={disabled} style={{ marginTop: 'auto' }}></Checkbox>
                                </FormItem>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={22} xs={22} sm={22}  lg={22} xl={22}>
                                <FormItem
                                  label="Cuenta Deudor"
                                  name="cuentaDeudorId"
                                  margin
                                  tooltip="Selecciona primero un tipo de cliente"
                                >
                                  <SelectItem
                                    disabled
                                    placeholder="Cuenta Deudor"
                                    width="100%"
                                  >
                                    {this.state.objetoCuentaContable}
                                  </SelectItem>
                                </FormItem>
                              </Col>
                              <Col span={2} xs={2} sm={2}  lg={2} xl={2} style={{ display: 'flex', flexDirection: 'column' }}>
                                <FormItem
                                  noStyle
                                  name="generarCuentaDeudorId"
                                  valuePropName="checked"
                                  initialValue={true}
                                >
                                  <Checkbox disabled={disabled} style={{ marginTop: 'auto' }}></Checkbox>
                                </FormItem>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      );
                    }}
                  </FormItem>
                </TabPane>
                {this.props.complementoDetallista ? (
                  <TabPane tab="Detallista" tabKey={7} key={7} forceRender>
                    <FormItem
                      label="Activar Complemento Detallista"
                      name="complementoDetallista"
                      margin
                      initialValue={false}
                      valuePropName="checked"
                      tooltip='Al activar esta casilla se activa el complemento detallista para este cliente.'
                    >
                      <SwitchItem />
                    </FormItem>
                    <FormItem shouldUpdate noStyle>
                      {({ getFieldValue }) => {
                        const complementoDetallista = getFieldValue('complementoDetallista')
                        if (complementoDetallista) {
                          return (
                            <Row>
                              <Col span={18} xs={24} sm={24}  lg={18} xl={18}>
                                <FormItem
                                  name="type"
                                  label="Type"
                                  margin
                                  labelCol={{ span: 6 }}
                                  wrapperCol={{ span: 18 }}
                                >
                                  <InputItem placeholder="Type" />
                                </FormItem>
                                <FormItem
                                  name="contentVersion"
                                  label="ContentVersion"
                                  margin
                                  labelCol={{ span: 6 }}
                                  wrapperCol={{ span: 18 }}
                                >
                                  <InputItem placeholder="ContentVersion" />
                                </FormItem>
                                <FormItem
                                  name="documentStrucutreVersion"
                                  label="DocumentStrucutreVersion"
                                  margin
                                  labelCol={{ span: 6 }}
                                  wrapperCol={{ span: 18 }}
                                >
                                  <InputItem placeholder="DocumentStrucutreVersion" />
                                </FormItem>
                                <FormItem
                                  name="documentStatus"
                                  label="DocumentStatus"
                                  margin
                                  labelCol={{ span: 6 }}
                                  wrapperCol={{ span: 18 }}
                                  valuePropName="checked"
                                  tooltip='Se pondra la funcion del mensaje: ORIGINAL DELETE COPY REEMPLAZA'
                                >
                                  <SwitchItem />
                                </FormItem>
                                <FormItem
                                  name="requestForPaymentIdentification"
                                  label="RequestForPaymentIdentification"
                                  margin
                                  labelCol={{ span: 6 }}
                                  wrapperCol={{ span: 18 }}
                                  valuePropName="checked"
                                  tooltip='Se pondra el tipo de transaccion en entityType: INVOICE DEBIT_NOTE CREDIT_NOTE HONORARY_RECEIPT TRANSPORT_DOCUMENT'
                                >
                                  <SwitchItem />
                                </FormItem>
                                <FormItem
                                  label="SpecialInstruction"
                                  margin
                                  name="specialInstruction"
                                  labelCol={{ span: 6 }}
                                  wrapperCol={{ span: 18 }}
                                >
                                  <SelectItem
                                    placeholder="SpecialInstruction"
                                  >
                                    <Option value={undefined}>Nada</Option>
                                    <Option value="ZZZ">ZZZ - Importe con letra</Option>
                                    <Option disabled value="AAB">AAB - Condiciones de pago</Option>
                                    <Option disabled value="DUT">DUT - Informacion de impuestos (Pedimentos)</Option>
                                    <Option disabled value="PUR">PUR - Informacion de compras</Option>
                                  </SelectItem>
                                </FormItem>
                                <FormItem
                                  name="orderIdentification"
                                  label="OrderIdentification"
                                  margin
                                  labelCol={{ span: 6 }}
                                  wrapperCol={{ span: 18 }}
                                  valuePropName="checked"
                                  tooltip='Se pondra el numero de pedido: ON - Orden de compra'
                                >
                                  <SwitchItem onChange={(value) => !value ? this.formRef.current.setFieldsValue({ referenceDateOrderIdentification: false }) : null} />
                                </FormItem>
                                <FormItem shouldUpdate noStyle>
                                  {({ getFieldValue }) => {
                                    const orderIdentification = getFieldValue('orderIdentification')
                                    return (
                                      <FormItem
                                        name="referenceDateOrderIdentification"
                                        label="ReferenceDate"
                                        margin
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}
                                        valuePropName="checked"
                                        tooltip='Se pedira la fecha de referencia'
                                      >
                                        <SwitchItem disabled={!orderIdentification} />
                                      </FormItem>
                                    )
                                  }}
                                </FormItem>
                                <FormItem
                                  name="additionalInformation"
                                  label="AdditionalInformation"
                                  margin
                                  labelCol={{ span: 6 }}
                                  wrapperCol={{ span: 18 }}
                                  valuePropName="checked"
                                  tooltip='Se eligira la referencia adicional y el tipo'
                                >
                                  <SwitchItem />
                                </FormItem>
                                <FormItem
                                  name="deliveryNote"
                                  label="DeliveryNote"
                                  margin
                                  labelCol={{ span: 6 }}
                                  wrapperCol={{ span: 18 }}
                                  valuePropName="checked"
                                  tooltip='Se pedira el numero de folio'
                                >
                                  <SwitchItem onChange={(value) => !value ? this.formRef.current.setFieldsValue({ referenceDateDeliveryNote: false }) : null} />
                                </FormItem>
                                <FormItem shouldUpdate noStyle>
                                  {({ getFieldValue }) => {
                                    const deliveryNote = getFieldValue('deliveryNote')
                                    return (
                                      <FormItem
                                        name="referenceDateDeliveryNote"
                                        label="ReferenceDate"
                                        margin
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}
                                        valuePropName="checked"
                                        tooltip='Se pedira la fecha de referencia'
                                      >
                                        <SwitchItem disabled={!deliveryNote} />
                                      </FormItem>
                                    )
                                  }}
                                </FormItem>
                                <FormItem
                                  name="buyer"
                                  label="Buyer"
                                  margin
                                  labelCol={{ span: 6 }}
                                  wrapperCol={{ span: 18 }}
                                  valuePropName="checked"
                                  tooltip='Datos del comprador'
                                >
                                  <SwitchItem onChange={(value) => !value ? this.formRef.current.setFieldsValue({ glnBuyer: undefined, textBuyer: undefined }) : null} />
                                </FormItem>
                                <FormItem shouldUpdate noStyle>
                                  {({ getFieldValue }) => {
                                    const buyer = getFieldValue('buyer')
                                    return (
                                      <>
                                        <FormItem
                                          name="glnBuyer"
                                          label="Gln"
                                          margin
                                          labelCol={{ span: 8 }}
                                          wrapperCol={{ span: 16 }}
                                          tooltip='Codigo del comprador'
                                        >
                                          <InputItem disabled={!buyer} />
                                        </FormItem>
                                        <FormItem
                                          name="textBuyer"
                                          label="PersonOrDepartmentName"
                                          margin
                                          labelCol={{ span: 8 }}
                                          wrapperCol={{ span: 16 }}
                                          tooltip='Contacto de compras'
                                        >
                                          <InputItem disabled={!buyer} />
                                        </FormItem>
                                      </>
                                    )
                                  }}
                                </FormItem>
                                <FormItem
                                  name="seller"
                                  label="Seller"
                                  margin
                                  labelCol={{ span: 6 }}
                                  wrapperCol={{ span: 18 }}
                                  valuePropName="checked"
                                  tooltip='Datos del proveedor'
                                >
                                  <SwitchItem onChange={(value) => !value ? this.formRef.current.setFieldsValue({ glnSeller: undefined, alternatePartyIdentificationType: undefined, alternatePartyIdentification: undefined }) : null} />
                                </FormItem>
                                <FormItem shouldUpdate noStyle>
                                  {({ getFieldValue }) => {
                                    const seller = getFieldValue('seller')
                                    return (
                                      <>
                                        <FormItem
                                          name="glnSeller"
                                          label="Gln"
                                          margin
                                          labelCol={{ span: 8 }}
                                          wrapperCol={{ span: 16 }}
                                          tooltip='Codigo del proveedor'
                                        >
                                          <InputItem disabled={!seller} />
                                        </FormItem>
                                        <FormItem
                                          label="AlternatePartyIdentificationType"
                                          margin
                                          name="alternatePartyIdentificationType"
                                          labelCol={{ span: 8 }}
                                          wrapperCol={{ span: 16 }}
                                        >
                                          <SelectItem
                                            placeholder="AlternatePartyIdentificationType"
                                            disabled={!seller}
                                          >
                                            <Option value={undefined}>Nada</Option>
                                            <Option value="SELLER_ASSIGNED_IDENTIFIER_FOR_A_PARTY">SELLER_ASSIGNED_IDENTIFIER_FOR_A_PARTY - Número interno del proveedor</Option>
                                            <Option value="IEPS_REFERENCE">IEPS_REFERENCE - Referencia signada para el IEPS</Option>
                                          </SelectItem>
                                        </FormItem>
                                        <FormItem
                                          name="alternatePartyIdentification"
                                          label="AlternatePartyIdentification"
                                          margin
                                          labelCol={{ span: 8 }}
                                          wrapperCol={{ span: 16 }}
                                          tooltip='Identificacion secundaria del proveedor'
                                        >
                                          <InputItem disabled={!seller} />
                                        </FormItem>
                                      </>
                                    )
                                  }}
                                </FormItem>
                              </Col>
                            </Row>




                          )
                        }
                      }}
                    </FormItem>
                  </TabPane>
                ) : null}
              </Tabs>
            </Form>
          </Spin>
        </div>
        <AgregarUnidad
          visible={this.state.modalAgregarUnidad}
          cerrarAgregarUnidad={this.cerrarAgregarUnidad.bind(this)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    complementoDetallista: state.empresa.complementoDetallista,
    fletes: state.empresa.fletes,
  };
};

export default connect(mapStateToProps)(EditarCliente);
