import {SELECCIONAR_EMPRESA, DESELECCIONAR_EMPRESA, CONTAR_VENTAS_PENDIENTES} from '../actions/empresa'

const initialState = {
    id: null,
    nombre: null,
    rfc: null,
    certificada: null,
    certificadaDM: null,
    automatica: null,
    envios: null,
    fletes: null,
    marbetes: null,
    complementoDetallista: null,
    modulosMezcal: null,
    modulosConsignaciones: null,
    equipoMedico: null,
    lotesCaducidades: null,
    cargarXmlPrevio: null,
    cuentaVentasPendientes: 0
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SELECCIONAR_EMPRESA:
            return {
                id: action.data.id,
                nombre: action.data.nombre,
                rfc: action.data.rfc,
                certificada: action.data.certificada ? action.data.certificada : false,
                certificadaDM: action.data.certificadaDM ? action.data.certificadaDM : false,
                automatica: action.data.automatica ? action.data.automatica : false,
                envios: action.data.envios ? action.data.envios : false,
                fletes: action.data.fletes ? action.data.fletes : false,
                marbetes: action.data.marbetes ? action.data.marbetes : false,
                complementoDetallista: action.data.complementoDetallista ? action.data.complementoDetallista : false,
                cargarXmlPrevio: action.data.cargarXmlPrevio ? action.data.cargarXmlPrevio : false,
                modulosMezcal: action.data.modulosMezcal ? action.data.modulosMezcal : false,
                modulosConsignaciones: action.data.modulosConsignaciones ? action.data.modulosConsignaciones : false,
                equipoMedico: action.data.equipoMedico ? action.data.equipoMedico : false,
                lotesCaducidades: action.data.lotesCaducidades ? action.data.lotesCaducidades : false,
            }
            case DESELECCIONAR_EMPRESA:
                return {
                    id: null,
                    nombre: null,
                    rfc: null,
                    certificada: null,
                    certificadaDM: null,
                    automatica: null,
                    envios: null,
                    fletes: null,
                    marbetes: null,
                    complementoDetallista: null,
                    cargarXmlPrevio: null,
                    modulosMezcal: null,
                    modulosConsignaciones: null,
                    equipoMedico: null,
                    lotesCaducidades: null,
                }
        
        case CONTAR_VENTAS_PENDIENTES:
            return {
                ...state,
                cuentaVentasPendientes: action.data.pendientes
            }

        default:
            break;
    }
    return state
}

export default reducer