import { message, PageHeader, Popconfirm, Space } from "antd";
import axios from "axios";
import React, { Component } from "react";
import {ButtonItem, TableItem} from "../../../Components/Items";
import moment from "moment";
import 'moment/locale/es'
import { connect } from "react-redux";
import { CheckCircleOutlined, EditOutlined, StopOutlined } from "@ant-design/icons";
import AgregarPerfil from "./AgregarPerfil/AgregarPerfil";
import EditarPerfil from "./EditarPerfil/EditarPerfil";

moment.locale('es')

class Perfiles extends Component {
  state = {
    todasPerfiles: [],
    modalAgregarPerfil: false,
    modalEditarPerfil: false,
    modalEditarPerfilId: null
  }
  componentDidMount() {
    this.consultarPerfiles();
  }

  consultarPerfiles() {
    axios
    .post(
      "perfiles/selectTodosPerfiles",
      { usuarioPrincipal: this.props.usuarioPrincipal },
      {
        headers: this.props.headersToken,
      }
    )
    .then((res) => {
      if (res.data.status === 404) {
      } else {
        this.setState({
          todasPerfiles: res.data.map(value => {
            return {...value, accion: { _id: value._id, estado: value.estado }}
          }),
        });
      }
    });
  }

  deshabilitarPerfil(value) {
    axios
      .post(
        "perfiles/deshabilitarPerfil",
        { perfilId: value, usuarioPrincipal: this.props.usuarioPrincipal },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Perfil deshabilitado");
        } else {
          message.error("Sucedio un eror y no se pudo deshabilitar");
        }
        this.consultarPerfiles();
      });
  }
  habilitarPerfil(value) {
    axios
      .post(
        "perfiles/habilitarPerfil",
        { perfilId: value, usuarioPrincipal: this.props.usuarioPrincipal },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Perfil habilitado");
        } else {
          message.error("Sucedio un eror y no se pudo habilitar");
        }
        this.consultarPerfiles();
      });
  }
  abrirAgregarPerfil() {
    this.setState({ modalAgregarPerfil: true });
  }
  cerrarAgregarPerfil() {
    this.setState({ modalAgregarPerfil: false });
    this.consultarPerfiles();
  }
  abrirEditarPerfil(id) {
    this.setState({ modalEditarPerfil: true, modalEditarPerfilId: id });
  }
  cerrarEditarPerfil() {
    this.setState({ modalEditarPerfil: false });
    this.consultarPerfiles();
  }

  render() {
    const columns = [
      {
        title: "Nombre",
        dataIndex: "nombre",
        key: "nombre",
        sorter: (a, b) => {
          if (a.nombre < b.nombre) {
            return -1;
          }
          if (a.nombre > b.nombre) {
            return 1;
          }
          return 0;
        },
      },
      {
        title: "Descripcion",
        dataIndex: "descripcion",
        key: "descripcion",
        sorter: (a, b) => {
          if (a.descripcion < b.descripcion) {
            return -1;
          }
          if (a.descripcion > b.descripcion) {
            return 1;
          }
          return 0;
        },
      },
      {
        title: "Fecha",
        dataIndex: "createdAt",
        key: "createdAt",
        responsive: ["md"],
        sorter: (a, b) => {
          if (moment(a.createdAt) < moment(b.createdAt)) {
            return -1;
          }
          if (moment(a.createdAt) > moment(b.createdAt)) {
            return 1;
          }
          return 0;
        },
        render: (value) => moment(value).locale('es').format('ll'),
      },
      {
        title: 'Acción',
        dataIndex: 'accion',
        key: 'accion',
        render: (value) => (
            <Space size="middle">
              <EditOutlined
                onClick={() => this.abrirEditarPerfil(value._id)}
                style={{ color: "blue" }}
              />
              {value.estado === 1 ? (
                <Popconfirm
                  title="Seguro que quieres deshabilitar este perfil"
                  onConfirm={() => this.deshabilitarPerfil(value._id)}
                  okText="Si"
                  cancelText="No"
                >
                  <CheckCircleOutlined style={{ color: "green" }} />
                </Popconfirm>
              ) : (
                <Popconfirm
                  title="Seguro que quieres habilitar este perfil"
                  onConfirm={() => this.habilitarPerfil(value._id)}
                  okText="Si"
                  cancelText="No"
                >
                  <StopOutlined style={{ color: "red" }} />
                </Popconfirm>
              )}
            </Space>
          ),
      }
    ]
    return (
      <>
        <PageHeader
          title="Perfiles"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={[
            <ButtonItem
              type="primary"
              key="agregarPerfil"
              onClick={this.abrirAgregarPerfil.bind(this)}
            >
              Agregar Perfil
            </ButtonItem>,
          ]}
        />
        <TableItem size='small' dataSource={this.state.todasPerfiles} columns={columns} pagination={false} />
        <AgregarPerfil
          visible={this.state.modalAgregarPerfil}
          cerrarAgregarPerfil={this.cerrarAgregarPerfil.bind(this)}
        />
                        <EditarPerfil
          visible={this.state.modalEditarPerfil}
          id={this.state.modalEditarPerfilId}
          cerrarEditarPerfil={this.cerrarEditarPerfil.bind(this)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    usuarioPrincipal: state.user.usuarioPrincipal,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(Perfiles);