import moment from "moment";
import "moment/locale/es";
import { dineroDisabledMask } from "../Components/Items";
import numeroALetra from "../Components/numeroALetra";

moment.locale("es");

const cobroFernandoPdf = (values) => {
  const empresa = values.respuestaComplemento.empresa;
  const dataSat = values.respuestaComplemento.dataSat;
  const logoBiwo = values.logoBiwo?.base64Completa || "";
  let dd
  let articulo
  let diaPago
  let concepto
  let pagoSemana
  let recargoSemana
  let parcialidadVigente
  let parcialidades
  let pagos = dataSat.Complemento.Any[0]["Pago20:Pagos"].Pago[0].DoctoRelacionado.map((valuePago) => {


    if(valuePago.articulo) articulo = valuePago.articulo
    if(valuePago.diaPago) diaPago = valuePago.diaPago
    if(valuePago.concepto) concepto = valuePago.concepto
    if(valuePago.pagoSemana) pagoSemana = valuePago.pagoSemana
    if(valuePago.recargoSemana) recargoSemana = valuePago.recargoSemana
    if(valuePago.parcialidadVigente) parcialidadVigente = valuePago.parcialidadVigente
    if(valuePago.parcialidades) parcialidades = valuePago.parcialidades


    return [
      { text: concepto || 'Recargo por días atrasados', style: "textoTabla", alignment: "left" },
      { text: dineroDisabledMask(valuePago.ImpPagado), style: "textoTabla", alignment: "right" },
    ]
  })

  pagos.push([
    { text: "TOTAL PAGADO", style: "textoTabla", alignment: "right" },
    { text: dineroDisabledMask(dataSat.Complemento.Any[0]["Pago20:Pagos"].Totales.MontoTotalPagos), style: "textoTabla", alignment: "right" }
  ])

  dd = {
    footer: { image: "logoBiwo", alignment: "center", width: 50, margin: [0, -30, 0, 0] },
    content: [
      {
        style: "tableExample",
        table: {
          widths: ["18%", "52%", "30%"],
          layout: { defaultBorder: false },
          body: [
            [
              {
                border: [false, false, false, false],
                image: "logo",
                fit: [80, 80]
              },
              {
                border: [false, false, false, false],
                table: {
                  headerRows: 1,
                  widths: ["100%"],
                  body: [
                    [{ text: empresa.persona
                        ? empresa.nombre
                        : empresa.nombre +
                          " " +
                          empresa.apellidoPaterno +
                          " " +
                          empresa.apellidoMaterno, style: "tableHeader" }],
                    [
                      {
                        text: "R.F.C: "+ empresa.rfc,
                        style: "textoChico",
                        alignment: "center",
                      },
                    ],
                    [
                      {
                        text: empresa.calle +
                        " " +
                        empresa.numeroCalle +
                        " " +
                        (empresa.numeroInterior
                          ? empresa.numeroInterior
                          : ""),
                        style: "textoChico",
                        alignment: "center",
                      },
                    ],
                    [
                      {
                        text: empresa.colonia.tipoAsentamiento +
                        " " +
                        empresa.colonia.nombre +
                        " C.P. " +
                        empresa.colonia.cp +
                        " " +
                        empresa.municipio.nombre +
                        " " +
                        empresa.estado.abreviatura +
                        " " +
                        empresa.pais.abreviatura +
                        " ",
                        style: "textoChico",
                        alignment: "center",
                      },
                    ],
                  ],
                },
                layout: "noBorders",
              },
              {
                border: [false, false, false, false],
                table: {
                  headerRows: 1,
                  widths: ["100%"],
                  body: [
                    [
                      {
                        text: "N° Recibo:",
                        style: "subheader",
                        alignment: "center"
                      }
                    ],
                    [{ text: dataSat.Serie + ' - ' + dataSat.Folio, style: "textoChico", alignment: "center" }],
                    [
                      {
                        text: "Fecha:",
                        style: "subheader",
                        alignment: "center"
                      }
                    ],
                    [
                      {
                        text: moment(dataSat.Fecha).locale("es").format("LL"),
                        style: "textoChico",
                        alignment: "center"
                      }
                    ],
                  ]
                },
                layout: "noBorders"
              }
            ]
          ]
        }
      },
      {
        style: "tableExample",
        table: {
          widths: ["70%", "30%"],
          layout: { defaultBorder: false },
          body: [
            [
              {
                table: {
                  widths: ["35%", "65%"],
                  layout: { defaultBorder: false },
                  body: [
                    [
                      { text: "Cliente :", style: "subheader" },
                      { text: dataSat.Receptor.Nombre, style: "textoChico" },
                    ],
                    [
                      { text: "Concepto:", style: "subheader" },
                      { text: concepto || '', style: "textoChico" }
                    ],
                    [
                      { text: "Articulo:", style: "subheader" },
                      { text: articulo || '', style: "textoChico" }
                    ],
                    [
                      { text: "Pago semana por semana:", style: "subheader" },
                      { text: dineroDisabledMask(pagoSemana || 0), style: "textoChico" }
                    ],
                    [
                      { text: "Recargo por día en caso de atraso:", style: "subheader" },
                      { text: dineroDisabledMask(recargoSemana || 0), style: "textoChico" }
                    ]
                  ]
                },
                layout: "noBorders"
              },
              {
                table: {
                  widths: ["37%", "63%"],
                  layout: { defaultBorder: false },
                  body: [
                    [
                      { text: "Dia de pago:", style: "subheader" },
                      { text: diaPago || '', style: "textoChico" }
                    ],
                    [
                      { text: "Forma de pago:", style: "subheader" },
                      { text: dataSat.Complemento.Any[0]["Pago20:Pagos"].Pago[0].FormaDePagoP === '01' ? 'Efectivo' : 'Transferencia', style: "textoChico" }
                    ],
                    [
                      { text: "Pago:", style: "subheader" },
                      { text: parcialidadVigente+'/'+parcialidades, style: "textoChico" }
                    ]
                  ]
                },
                layout: "noBorders"
              }
            ]
          ]
        },
        layout: {}
      },
      { text: "Descripción del pago" },
      {
        style: "tableExample",
        table: {
          widths: ["70%", "30%"],
          layout: { defaultBorder: true },
          body: [
            [
              { text: "Descripción", style: "tituloTabla" },
              { text: "Total", style: "tituloTabla" }
            ],
            ...pagos
          ]
        },

        layout: {}
      },
      {
        style: "tableExample",
        table: {
          widths: ["30%", "70%"],
          layout: { defaultBorder: true },
          body: [
            [
              { text: "TOTAL PAGADO", style: "tituloTabla" },
              { text: numeroALetra(dataSat.Complemento.Any[0]["Pago20:Pagos"].Totales.MontoTotalPagos || 0, 'MXN'), style: "tituloTabla" },
            ],
          ]
        },
        layout: {}
      },
    ],
    images: {
      logo: empresa.logo.base64Completa,
      logoBiwo: logoBiwo

    },
    styles: {
      header: { fontSize: 9, bold: true },
      subheader: { fontSize: 8, bold: true },
      tableExample: { margin: [0, 5, 0, 5] },
      tablaSinMargen: { margin: [0, 0, 0, 0] },
      tableHeader: {
        bold: true,
        fontSize: 12,
        color: "black",
        alignment: "center"
      },
      textoChico: { fontSize: 8 },
      tituloTabla: {
        fontSize: 8,
        bold: true,
        fillColor: "#cccccc",
        style: "subheader",
        alignment: "center",
        border: true
      },
      textoTabla: { fontSize: 8, italics: false }
    },
    pageSize: "LETTER",
    pageMargins: [20, 20, 20, 20]
  }

  return dd;
};

export default cobroFernandoPdf;