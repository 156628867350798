import { message, Space, Select, Popconfirm, Input, Row, Col } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
    ModalItem,
    ButtonItem,
    Form,
    FormItem,
    SelectItem,
    InputItem,
    TextAreaItem,
    HeaderItem,
    BoxItem,
    cuentaMask
} from "../../../../Components/Items";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import "moment/locale/es";
import { PlusOutlined } from "@ant-design/icons";
import AgregarFamilia from "../../Familias/AgregarFamilia/AgregarFamilia";
import AgregarCuentaContable from "../../CuentasContables/AgregarCuentaContable/AgregarCuentaContable";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const { Option } = Select

class DuplicarMatriz extends Component {
    formRef = React.createRef();
    state = {
        loading: false,
        todasProyectos: [],
        objetoProyectos: null,
        todasFamilias: [],
        objetoFamilia: null,
        todasTipoDeInsumo: [],
        objetoTipoDeInsumo: null,
        todasCuentasContablesFinales: [],
        objetoCuentasContablesFinales: null,
        modalAgregarFamilia: false,
        modalAgregarCuentaContable: false,
        venta: false
    };

    componentDidUpdate(prevProps) {
        if (this.props.visible !== prevProps.visible && this.props.visible)
            this.consultarTodo();
    }

    async consultarTodo() {
        this.setState({ loading: true });
        await axios
            .post(
                "matrices/datosDuplicarMatriz",
                { matrizId: this.props.id, empresaId: this.props.empresaId },
                { headers: this.props.headersToken }
            )
            .then((res) => {
                if (res.data.success === 2) {
                    this.setState({
                        todasFamilia: res.data.familias,
                        todasTipoDeInsumo: res.data.tiposInsumos,
                        objetoFamilia: res.data.familias.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.nombre}
                                </Option>
                            );
                        }),
                        objetoTipoDeInsumo: res.data.tiposInsumos.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.nombre}
                                </Option>
                            );
                        }),
                        venta: res.data.venta
                    })

                    this.formRef.current.setFieldsValue({
                        codigo: res.data.sigNumero,
                        clave: res.data.clave,
                        descripcion: res.data.descripcion,
                        descripcionLarga: res.data.descripcionLarga,
                        familiaId: res.data.familiaId,
                        tipoDeInsumoId: res.data.tipoDeInsumoId,
                    })

                } else {
                    message.warning('No se cargaron bien los datos')
                }
            });
        await axios
            .post(
                "cuentascontables/selectCuentasContablesFinales",
                { empresaId: this.props.empresaId },
                { headers: this.props.headersToken }
            )
            .then((res) => {
                if (res.data.status === 404) {
                } else {
                    this.setState({ todasCuentasContablesFinales: res.data });
                    this.setState({
                        objetoCuentasContablesFinales: res.data.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {cuentaMask(
                                        value.nivel1 +
                                        value.nivel2 +
                                        value.nivel3 +
                                        value.nivel4 +
                                        value.nivel5
                                    ) +
                                        " - " +
                                        value.descripcion}
                                </Option>
                            );
                        }),
                    });
                }
            });
        this.setState({ loading: false });
    }

    onFinishFailed(e) {
        message.error("Porfavor ingrese bien los datos");
    }
    onFinish(values) {
        this.setState({ loading: true });
        axios
            .post(
                "matrices/duplicarMatriz",
                { ...values, matrizId: this.props.id, empresaId: this.props.empresaId },
                { headers: this.props.headersToken }
            )
            .then((res) => {
                if (res.data.success === 2) {
                    message.success("Se ha registrado una nueva matriz");
                    this.formRef.current.resetFields();
                    this.cerrarDuplicarMatriz()
                } else if (res.data.success === 1) {
                    message.error(res.data.message ? res.data.message : "No se pudo registrar la matriz");
                } else {
                    message.error("Hubo un error y la matriz no se pudo registrar");
                }
                this.setState({ loading: false });
            });
    }

    cerrarDuplicarMatriz() {
        this.props.cerrarDuplicarMatriz();
    }

    onBlurDescripcion() {
        if (!this.formRef.current.getFieldValue("descripcionLarga")) {
            let value = this.formRef.current.getFieldValue("descripcion");
            this.formRef.current.setFieldsValue({
                descripcionLarga: value,
            });
        }
    }


    abrirAgregarFamilia() {
        this.setState({ modalAgregarFamilia: true });
    }
    cerrarAgregarFamilia(codigo) {
        this.setState({ modalAgregarFamilia: false });
        this.consultarTodo().then(() => {
            if (typeof codigo === "string") {
                this.formRef.current.setFieldsValue({ familiaId: codigo });
            }
        });
    }

    abrirAgregarCuentaContable() {
        this.setState({ modalAgregarCuentaContable: true });
    }
    cerrarAgregarCuentaContable(codigo) {
        this.setState({ modalAgregarCuentaContable: false });
        this.consultarTodo()
    }

    render() {
        return (
            <>
                <ModalItem
                    title="Duplicar Matriz"
                    visible={this.props.visible}
                    spinning={this.state.loading}
                    onCancel={this.cerrarDuplicarMatriz.bind(this)}
                    paddingTop
                >
                    <Form
                        ref={this.formRef}
                        name="duplicarMatriz"
                        layout
                        width={700}
                        onCancel={this.cerrarDuplicarMatriz.bind(this)}
                        onFinish={this.onFinish.bind(this)}
                        onFinishFailed={this.onFinishFailed}
                    >
                        <FormItem
                            name="codigo"
                            label="Código"
                            margin
                            pattern
                        >
                            <InputItem disabled placeholder="Código" />
                        </FormItem>
                        <FormItem
                            name="clave"
                            label="Clave"
                            margin
                            pattern
                        >
                            <InputItem placeholder="Clave" />
                        </FormItem>
                        <FormItem
                            label="Descripción"
                            name="descripcion"
                            required
                            margin
                            pattern
                        >
                            <InputItem
                                placeholder="Descripción"
                                onBlur={this.onBlurDescripcion.bind(this)}
                            />
                        </FormItem>
                        <FormItem
                            label="Desc. Larga"
                            name="descripcionLarga"
                            required
                            margin
                            pattern
                        >
                            <TextAreaItem placeholder="Descripción larga" />
                        </FormItem>
                        <FormItem
                            label="Familia"
                            margin
                        >
                            <Input.Group compact>
                                <FormItem name="familiaId" required noStyle>
                                    <SelectItem
                                        placeholder="Familia"
                                        width="90%"
                                    >
                                        {this.state.objetoFamilia}
                                    </SelectItem>
                                </FormItem>
                                <ButtonItem
                                    icon={<PlusOutlined />}
                                    style={{ width: "10%" }}
                                    onClick={this.abrirAgregarFamilia.bind(this)}
                                />
                            </Input.Group>
                        </FormItem>
                        <FormItem
                            label="Tipo Ins."
                            margin
                            name="tipoDeInsumoId"
                        >
                            <SelectItem
                                placeholder="Tipo de Insumo"
                                width="90%"
                            >
                                {this.state.objetoTipoDeInsumo}
                            </SelectItem>
                        </FormItem>
                        <FormItem noStyle shouldUpdate={(prev, curr) => prev.familiaId !== curr.familiaId} >
                            {({ getFieldValue }) => {
                                let familiaId = getFieldValue('familiaId')
                                let familia
                                if (familiaId) {
                                    familia = this.state?.todasFamilia?.find(valueTF => valueTF._id.toString() === familiaId.toString())
                                }
                                if (this.state.venta && (!familia?.cuentaIngresoContadoId || !familia?.cuentaIngresoCreditoId)) {
                                    return (
                                        <Row>
                                            <Col span={14}>
                                                <HeaderItem>CUENTAS DE INGRESO</HeaderItem>
                                                <BoxItem>
                                                    <Row>
                                                        <Col span={24}>
                                                            <FormItem label="Cuenta Contado" margin>
                                                                <Input.Group compact>
                                                                    <FormItem name="cuentaIngresoContadoId" required noStyle>
                                                                        <SelectItem placeholder="Cuenta Ingreso Contado" width="80%">
                                                                            {this.state.objetoCuentasContablesFinales}
                                                                        </SelectItem>
                                                                    </FormItem>
                                                                    <ButtonItem
                                                                        icon={<PlusOutlined />}
                                                                        style={{ width: "20%" }}
                                                                        blanco
                                                                        onClick={this.abrirAgregarCuentaContable.bind(this)}
                                                                    />
                                                                </Input.Group>
                                                            </FormItem>
                                                            <FormItem label="Cuenta Credito" margin>
                                                                <Input.Group compact>
                                                                    <FormItem name="cuentaIngresoCreditoId" required noStyle>
                                                                        <SelectItem placeholder="Cuenta Ingreso Credito" width="80%">
                                                                            {this.state.objetoCuentasContablesFinales}
                                                                        </SelectItem>
                                                                    </FormItem>
                                                                    <ButtonItem
                                                                        icon={<PlusOutlined />}
                                                                        style={{ width: "20%" }}
                                                                        blanco
                                                                        onClick={this.abrirAgregarCuentaContable.bind(this)}
                                                                    />
                                                                </Input.Group>
                                                            </FormItem>
                                                        </Col>
                                                    </Row>
                                                </BoxItem>
                                            </Col>
                                        </Row>)
                                } else {
                                    return null
                                }
                            }}
                        </FormItem>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "right",
                                marginTop: 20,
                                marginBottom: 10,
                            }}
                        >
                            <Space>

                                <Popconfirm
                                    title={'¿Estás seguro de duplicar la matriz?'}
                                    onConfirm={() => this.formRef.current.formRef.current.submit()}
                                    okText="Ok"
                                    cancelText="Cancelar"
                                >
                                    <ButtonItem
                                        type="primary"
                                        style={{ display: "block", margin: "0 0 0 auto" }}
                                    >
                                        Duplicar Matriz
                                    </ButtonItem>
                                </Popconfirm>

                                <Popconfirm>


                                </Popconfirm>
                                <ButtonItem
                                    type="primary"
                                    danger
                                    onClick={this.cerrarDuplicarMatriz.bind(this)}
                                    style={{ display: "block", margin: "0 0 0 auto" }}
                                >
                                    Regresar
                                </ButtonItem>
                            </Space>
                        </div>
                    </Form>
                </ModalItem>
                <AgregarFamilia
                    visible={this.state.modalAgregarFamilia}
                    cerrarAgregarFamilia={this.cerrarAgregarFamilia.bind(this)}
                />
                <AgregarCuentaContable
                    visible={this.state.modalAgregarCuentaContable}
                    cerrarAgregarCuentaContable={this.cerrarAgregarCuentaContable.bind(this)}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        headersToken: state.user.headersToken,
        empresaId: state.empresa.id,
    };
};

export default connect(mapStateToProps)(DuplicarMatriz);
