import React, { Component } from "react";
import {
  Card,
  Col,
  Layout,
  Menu,

  message,

  notification,

  Result,
  Row,
  Select,
  Spin,
  Upload,
} from "antd";
import logoHorizontal from "../../assets/Biwo_Horizontal.png";
import { NavLink } from "react-router-dom";
import {
  Form, FormItem, SelectItem, ButtonItem, InputItem, ErrorItem,
  SwitchItem,
  extraerDatosConstanciaFiscal,
  calcularSimilitud
} from "../../Components/Items";
import axios from "axios";
import pdfMake from "pdfmake/build/pdfmake";
import facturaPdf from "../../Pdf/facturaPdf";
import remisionPdf from "../../Pdf/remisionPdf";
import { BookOutlined, DownloadOutlined, SearchOutlined, SendOutlined, UploadOutlined } from "@ant-design/icons";
import moment from "moment";

const { Header, Footer, Content } = Layout;
const { Option } = Select

class Kiosko extends Component {
  formRefBuscarVenta = React.createRef();
  formRefDatosCliente = React.createRef();
  formRef = React.createRef();

  state = {
    status: "info",
    loading: false,
    idKiosko: null,
    error: null,
    factura: false,
    objetoRegimenFiscal: null,
    todasRegimenFiscal: [],
    objetoCfdi: [],
    todasCfdi: [],
    objetoColonias: null,
    todasColonias: [],
    objetoMunicipios: null,
    todasMunicipios: [],
    objetoEstados: null,
    todasEstados: [],
    objetoPaises: null,
    todasPaises: [],
    loadingColonia: false,
    isMobile: window.innerWidth <= 1000,
  }


  onFinishFailed(e) {
    ErrorItem(e);
  }

  descargarVenta(value) {
    axios
      .post(
        "kiosko/descargaFactura",
        { kioskoId: value },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.factura) {
          var xmltext = res.data.respuestaFactura.data.cfdi;

          const dataSat = res.data.respuestaFactura.dataSat
          const nombreArchivo = (dataSat.Serie ? dataSat.Serie : 'F') + ' - ' + dataSat.Folio + ' ( ' + dataSat.Receptor.Rfc + ' - ' + dataSat.Receptor.Nombre + ' )'

          var filename = nombreArchivo + ".xml";
          var pom = document.createElement("a");
          var bb = new Blob([xmltext], { type: "text/plain" });

          pom.setAttribute("href", window.URL.createObjectURL(bb));
          pom.setAttribute("download", filename);

          pom.dataset.downloadurl = ["text/plain", pom.download, pom.href].join(
            ":"
          );
          pom.draggable = true;
          pom.classList.add("dragout");

          pom.click();

          const pdf = facturaPdf(res.data);

          pdfMake.createPdf(pdf).download(nombreArchivo + ".pdf");
        } else {

          const dataSat = res.data.respuestaFactura.dataSat
          const nombreArchivo = (dataSat.Serie ? dataSat.Serie : 'R') + ' - ' + dataSat.Folio + ' ( ' + dataSat.Receptor.Nombre + ' )'

          const pdf = remisionPdf(res.data);

          pdfMake.createPdf(pdf).download(nombreArchivo + ".pdf");
        }
      });
  }

  enviarVenta(value) {
    axios
      .post(
        "kiosko/enviarFactura",
        { kioskoId: value, correo: this.formRefDatosCliente.current.getFieldValue('correo') },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Se ha enviado la factura");
        } else {
          message.error("No se pudo enviar la factura");
        }
      });
  }

  async onFinishFolio(values) {

    this.setState({ loading: true });

    let cargarDatos = false

    let changeEstado

    let changeMunicipio

    await axios
      .post(
        "kiosko/kioskoPdf",
        { idKiosko: values.folio },
        {
          headers: this.props.headersToken,
        }
      )
      .then(async (res) => {

        if (res.data.success === 2) {

          cargarDatos = true

          this.setState({ idKiosko: values.folio }, async () => {

            const pdf = res.data.factura ? pdfMake.createPdf(facturaPdf(res.data)) : pdfMake.createPdf(remisionPdf(res.data));

            this.setState(
              {
                pdf: pdf,
                factura: res.data.factura ? res.data.factura : null,
              },
              () => {
                pdf.open({}, window.frames["kioskoPdf"]);
              });

            if (!res.data.factura) {
              let fechaKiosko = moment(res.data.venta.fechaKiosko)

              if (fechaKiosko.isBefore(moment())) {
                message.info('Ya expriró el tiempo para facturar')
                this.setState({ facturable: false });
              } else {
                this.setState({ facturable: true });
              }

            }

            if (res.data.cliente.rfc !== "XAXX010101000" && res.data.cliente.rfc !== "XEXX010101000") {
              if (res.data.factura) {
                this.setState({ tipo: "factura" });
              }

              this.formRefDatosCliente.current.setFieldsValue({
                rfc: res.data.cliente?.rfc ? res.data.cliente.rfc : "",
                nombre: res.data.cliente?.nombre ? res.data.cliente.nombre : "",
                apellidoPaterno: res.data.cliente?.apellidoPaterno ? res.data.cliente.apellidoPaterno : "",
                apellidoMaterno: res.data.cliente?.apellidoMaterno ? res.data.cliente.apellidoMaterno : "",
                regimenFiscalId: res.data?.cliente?.regimenFiscalId ? res.data.cliente.regimenFiscalId : "",
                usoDeCfdiId: res.data?.cliente?.usoDeCFDIID ? res.data.cliente.usoDeCFDIID : "",
                correo: res.data.cliente.correo ? res.data.cliente.correo : "",
                coloniaId: res.data.cliente.direcciones[0]?.coloniaId ? res.data.cliente.direcciones[0].coloniaId : "",
                municipioId: res.data.cliente.direcciones[0]?.municipioId ? res.data.cliente.direcciones[0].municipioId : "",
                estadoId: res.data.cliente.direcciones[0]?.estadoId ? res.data.cliente.direcciones[0].estadoId : "",
                paisId: res.data.cliente.direcciones[0]?.paisId ? res.data.cliente.direcciones[0].paisId : "",
              });

              if (res.data.cliente.direcciones[0]?.municipioId) {
                changeEstado = res.data.cliente.direcciones[0].estadoId
                if (res.data.cliente.direcciones[0]?.coloniaId) {
                  changeMunicipio = res.data.cliente.direcciones[0].municipioId
                }
              }


            }
          })

        } else if (res.data.success === 1) {
          message.info(res.data.message ? res.data.message : "No se encontro el folio")
        } else if (res.data.success === 0) {
          message.error("El folio no existe");
        } else {
          message.error("Hubo un error al obtener la información del folio");
        }



      })
      .catch((err) => {
        console.error("Error en la solicitud:", err);
      });

    if (changeEstado) {
      await this.onChangeEstado(changeEstado, true)
    }

    if (changeMunicipio) {
      await this.onChangeMunicipio(changeMunicipio, true)
    }

    if (cargarDatos) {

      axios
        .post(
          "kiosko/todasRegimesCfdi",
          { idKiosko: this.state.idKiosko },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          if (res.data.success === 2) {
            this.setState({
              loading: false,
              todasRegimenFiscal: res.data.regimens,
              todasCfdi: res.data.cfdi,
              todasEstados: res.data.estado,
              todasPaises: res.data.pais,
              objetoRegimenFiscal: res.data.regimens.map((value) => (
                <Option value={value._id} key={value._id}>
                  {value.c_RegimenFiscal + " - " + value.descripcion}
                </Option>
              )),
              objetoCfdi: res.data.cfdi.map((value) => (
                <Option value={value._id} key={value._id}>
                  {value.c_UsoCFDI + " - " + value.descripcion}
                </Option>
              )),
              objetoEstados: res.data.estado.map((value) => (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              )),
              objetoPaises: res.data.pais.map((value) => (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              )),
            });
          } else {
            this.setState({ loading: false });
            console.log("Error al obtener los Regimen Fiscal y CFDI");
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log(error);
        });
    } else {
      this.setState({ loading: false });
    }
  }

  async onChangeEstado(value, inicial) {
    if (!inicial) {
      this.formRefDatosCliente.current.setFieldsValue({
        municipioId: undefined,
        coloniaId: undefined,
      });
      this.setState({ objetoMunicipios: null, objetoColonias: null });
    }
    await axios
      .post(
        "municipios/selectMunicipios",
        { estadoId: value },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasMunicipios: res.data });
          this.setState({
            objetoMunicipios: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
          });
        }
      });
  }
  async onChangeMunicipio(value, inicial) {
    if (!inicial) {
      this.formRefDatosCliente.current.setFieldsValue({ coloniaId: undefined });
      this.setState({ objetoColonias: null });
    }
    await axios
      .post(
        "colonias/selectColonias",
        { municipioId: value },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasColonias: res.data });
          this.setState({
            objetoColonias: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.cp + " - " + value.nombre}
                </Option>
              );
            }),
          });
        }
      });
  }
  async onSearchColonia(value, ponerColoniaId) {
    if (value.toString().length === 5) {
      this.setState({ loadingColonia: true });
      await axios
        .post(
          "colonias/buscarCP",
          { cp: value },
          {
            headers: this.props.headersToken,
          }
        )
        .then(async (res) => {
          if (res.data.status === 404) {
            message.info("No se encontro el codigo postal");
            this.setState({ loadingColonia: false });
          } else {
            this.formRefDatosCliente.current.setFieldsValue({
              paisId: res.data.paisId,
              estadoId: res.data.estadoId,
              municipioId: res.data.municipioId,
              coloniaId: ponerColoniaId ? res.data._id : undefined,
            });
            await this.onChangeEstado(res.data.estadoId, true);
            await this.onChangeMunicipio(res.data.municipioId, true);
            this.setState({ loadingColonia: false });
          }
        });
    }
  }

  onSelectColoniaId(coloniaId) {
    const colonia = this.formRefDatosCliente.current.getFieldValue('colonia')
    if (!colonia) {
      const nombreColonia = this.state.todasColonias.find((todasColonia) => todasColonia._id.toString() === coloniaId.toString())?.nombre
      this.formRefDatosCliente.current.setFieldsValue({
        colonia: nombreColonia
      })
    }
  }

  async onChangeRfc(e) {
    this.formRefDatosCliente.current.validateFields(['rfc']).then(() => {
      if (e.target.value) {
        if (e.target.value.length === 13) {
          //Persona Fisica

          this.setState({ loading: true });

          axios
            .post(
              "kiosko/buscarClienteRfc",
              { rfc: e.target.value, idKiosko: this.state.idKiosko },
              {
                headers: this.props.headersToken,
              }
            )
            .then(async (res) => {
              if (res.data.success === 2) {
                this.formRefDatosCliente.current.setFieldsValue({
                  nombre: res.data.cliente.nombre,
                  apellidoPaterno: res.data.cliente.apellidoPaterno,
                  apellidoMaterno: res.data.cliente.apellidoMaterno,
                  regimenFiscalId: res.data.cliente.regimenFiscalId,
                  usoDeCfdiId: res.data.cliente.usoDeCFDIID,
                  correo: res.data.cliente.correo,
                  coloniaId: res.data.cliente?.direcciones[0]?.coloniaId ? res.data.cliente.direcciones[0].coloniaId : undefined,
                  municipioId: res.data.cliente?.direcciones[0]?.municipioId ? res.data.cliente.direcciones[0].municipioId : undefined,
                  estadoId: res.data.cliente?.direcciones[0]?.estadoId ? res.data.cliente.direcciones[0].estadoId : undefined,
                  paisId: res.data.cliente?.direcciones[0]?.paisId ? res.data.cliente.direcciones[0].paisId : undefined,
                })
                if (res.data.cliente.direcciones[0]?.municipioId) {
                  await this.onChangeEstado(res.data.cliente.direcciones[0].estadoId, true)
                  if (res.data.cliente.direcciones[0]?.coloniaId) {
                    await this.onChangeMunicipio(res.data.cliente.direcciones[0].municipioId, true)
                  }
                }
              } else {
                this.formRefDatosCliente.current.setFieldsValue({
                  nombre: '',
                  apellidoPaterno: '',
                  apellidoMaterno: '',
                  regimenFiscalId: undefined,
                  usoDeCfdiId: undefined,
                  correo: '',
                  coloniaId: undefined,
                  municipioId: undefined,
                  estadoId: undefined,
                  paisId: undefined,
                })
              }
              this.setState({ loading: false });
            });

          this.formRefDatosCliente.current.setFieldsValue({ persona: false })
        } else if (e.target.value.length === 12) {
          //Persona Moral

          this.setState({ loading: true });

          console.log('Persona Moral')

          axios
            .post(
              "kiosko/buscarClienteRfc",
              { rfc: e.target.value, idKiosko: this.state.idKiosko },
              {
                headers: this.props.headersToken,
              }
            )
            .then(async (res) => {
              if (res.data.success === 2) {
                this.formRefDatosCliente.current.setFieldsValue({
                  razonSocial: res.data.cliente.razonSocial,
                  regimenFiscalId: res.data.cliente.regimenFiscalId,
                  usoDeCfdiId: res.data.cliente.usoDeCFDIID,
                  correo: res.data.cliente.correo,
                  coloniaId: res.data.cliente?.direcciones[0]?.coloniaId ? res.data.cliente.direcciones[0].coloniaId : undefined,
                  municipioId: res.data.cliente?.direcciones[0]?.municipioId ? res.data.cliente.direcciones[0].municipioId : undefined,
                  estadoId: res.data.cliente?.direcciones[0]?.estadoId ? res.data.cliente.direcciones[0].estadoId : undefined,
                  paisId: res.data.cliente?.direcciones[0]?.paisId ? res.data.cliente.direcciones[0].paisId : undefined,
                })
                if (res.data.cliente.direcciones[0]?.municipioId) {
                  await this.onChangeEstado(res.data.cliente.direcciones[0].estadoId, true)
                  if (res.data.cliente.direcciones[0]?.coloniaId) {
                    await this.onChangeMunicipio(res.data.cliente.direcciones[0].municipioId, true)
                  }
                }
              } else {
                this.formRefDatosCliente.current.setFieldsValue({
                  razonSocial: '',
                  regimenFiscalId: undefined,
                  usoDeCfdiId: undefined,
                  correo: '',
                  coloniaId: undefined,
                  municipioId: undefined,
                  estadoId: undefined,
                  paisId: undefined,
                })
              }
              this.setState({ loading: false });
            });

          this.formRefDatosCliente.current.setFieldsValue({ persona: true })
        }
      }
    }).catch((err) => undefined)
  }

  async onChangeArchivo(e) {
    this.setState({ loading: true });
    if (e.file.type === "application/pdf") {
      const datosConstancia = await extraerDatosConstanciaFiscal(e.file)
      if (datosConstancia.persona === 1) {


        if (datosConstancia.rfc && datosConstancia.nombre && datosConstancia.apellidoPaterno && datosConstancia.codigoPostal) {
          const findRegimen = this.state.todasRegimenFiscal.find(valueTRe => calcularSimilitud(valueTRe.descripcion, datosConstancia.regimenFiscal) > 65)
          await this.onSearchColonia(datosConstancia.codigoPostal, true)
          this.formRefDatosCliente.current.setFieldsValue({
            rfc: datosConstancia.rfc,
            nombre: datosConstancia.nombre,
            regimenFiscalId: findRegimen ? findRegimen._id : undefined,
            apellidoPaterno: datosConstancia.apellidoPaterno,
            apellidoMaterno: datosConstancia.apellidoMaterno,
            persona: false
          })

        } else {
          message.error('No se pudo leer bien el archivo')
        }

      } else if (datosConstancia.persona === 2) {

        if (datosConstancia.rfc && datosConstancia.razonSocial && datosConstancia.codigoPostal) {
          console.log()
          await this.onSearchColonia(datosConstancia.codigoPostal, true)
          const findRegimen = this.state.todasRegimenFiscal.find(valueTRe => calcularSimilitud(valueTRe.descripcion, datosConstancia.regimenFiscal) > 65)
          this.formRefDatosCliente.current.setFieldsValue({
            razonSocial: datosConstancia.razonSocial,
            rfc: datosConstancia.rfc,
            regimenFiscalId: findRegimen ? findRegimen._id : undefined,
            persona: true
          })
        } else {
          message.error('No se pudo leer bien el archivo')
        }

      } else {
        message.error('No se pudo leer bien el archivo')
      }
    } else {
      message.error("El archivo debe ser un archivo pdf");
    }
    this.setState({ loading: false });
  }

  onFinish(values) {

    this.setState({ loading: true });
    const data = {
      ...values,
      kioskoId: this.state.idKiosko,
    };

    axios
      .post(
        "kiosko/generarFactura", data,
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.success === 2) {
          const pdf = pdfMake.createPdf(facturaPdf(res.data));

          message.success("Se ha enviado la factura");

          notification.success({
            description: "Haga click para descargar",
            message: "Factura Emitida",
            duration: 10,
            style: { cursor: "pointer" },
            onClick: () => {
              var xmltext = res.data.respuestaFactura.data.cfdi;

              const dataSat = res.data.respuestaFactura.dataSat
              const nombreArchivo = (dataSat.Serie ? dataSat.Serie : 'F') + ' - ' + dataSat.Folio + ' ( ' + dataSat.Receptor.Rfc + ' - ' + dataSat.Receptor.Nombre + ' )'

              var filename = nombreArchivo + ".xml";
              var pom = document.createElement("a");
              var bb = new Blob([xmltext], { type: "text/plain" });

              pom.setAttribute("href", window.URL.createObjectURL(bb));
              pom.setAttribute("download", filename);

              pom.dataset.downloadurl = [
                "text/plain",
                pom.download,
                pom.href,
              ].join(":");
              pom.draggable = true;
              pom.classList.add("dragout");

              pom.click();

              const pdf = facturaPdf(res.data);

              pdfMake.createPdf(pdf).download(nombreArchivo + ".pdf");
            },
          });

          this.setState(
            {
              pdf: pdf,
              factura: true,
            },
            () => {
              pdf.open({}, window.frames["kioskoPdf"]);
            });
        } else if (res.data.success === 1) {
          message.error(res.data.message ? res.data.message : "No se pudo registrar la factura");
          notification.warning({
            description: res.data.description,
            message: res.data.message,
            duration: 10,
          });
        } else {
          message.error("Hubo un error y la factura no se registro");
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.error("Error en la solicitud:", err);
        this.setState({ loading: false });
      });

  }

  render() {
    const current = this.props.history.location.pathname;
    const url = new URL(window.location.href)
    const kioskoId = url.searchParams.get('kiosko_id')
    return (
      <>
        <Spin
          size="large"
          spinning={this.state.loading}
        >
          <Layout>
            <Header id="header-fixed">
              <Menu
                mode="horizontal"
                selectedKeys={current}
                style={{ height: "100%" }}
                id="menu-centered"
              >
                <Menu.Item key={"/login"} id='menuitem-izquierda'>
                  <NavLink to={"/login"}>
                    <img src={logoHorizontal} alt='Logo' id="img-logo" />
                  </NavLink>
                </Menu.Item>
              </Menu>
            </Header>
            {this.state.idKiosko ? (
              <Content style={{ marginTop: 70, padding: '20px' }}>
                <Row>
                  <Col xs={24} sm={24} lg={12} xl={12}>
                    <Card id="kiosko-card" >
                      <Result
                        key="kiosko"
                        title="Facturación Electrónica"
                        subTitle={this.state.factura ? "Factura emitida" : "Ingresa los datos necesarios para generar tu factura"}
                        status={this.state.factura ? 'success' : 'info'}
                      />

                      <Form
                        ref={this.formRefDatosCliente}
                        spinning={this.state.loading}
                        name="buscarVenta"
                        layout
                        onFinish={this.onFinish.bind(this)}
                        onFinishFailed={this.onFinishFailed}
                      >

                        <div style={{ textAlign: 'center', marginBottom: 20 }}>
                          <Upload
                            showUploadList={{
                              showRemoveIcon: false,
                              showPreviewIcon: false,
                            }}
                            fileList={[]}
                            customRequest={this.onChangeArchivo.bind(this)}
                            accept=".pdf"
                          >
                            <ButtonItem disabled={this.state.factura || !this.state.facturable} blanco icon={<UploadOutlined />}>Leer Constancia</ButtonItem>
                          </Upload>
                        </div>
                        <FormItem
                          label="RFC"
                          name="rfc"
                          required
                          margin
                          rfc
                        >
                          <InputItem disabled={this.state.factura || !this.state.facturable} onChange={this.onChangeRfc.bind(this)} placeholder="RFC" />
                        </FormItem>

                        <FormItem
                          name="persona"
                          label="P. Moral"
                          valuePropName="checked"
                          initialValue={false}
                          margin
                        >
                          <SwitchItem disabled />
                        </FormItem>


                        <FormItem noStyle shouldUpdate>
                          {({ getFieldValue }) => {
                            const persona = getFieldValue('persona')

                            if (persona) {
                              return (
                                <FormItem
                                  label="Razón Social"
                                  name="razonSocial"
                                  required
                                  pattern
                                  margin
                                >
                                  <InputItem placeholder="Razón Social" disabled={this.state.factura || !this.state.facturable} />
                                </FormItem>
                              )
                            } else {
                              return (
                                <>
                                  <FormItem
                                    label="Nombre"
                                    name="nombre"
                                    required
                                    pattern
                                    margin
                                  >
                                    <InputItem placeholder="Nombre" disabled={this.state.factura || !this.state.facturable} />
                                  </FormItem>

                                  <FormItem
                                    label="Apellido Paterno"
                                    name="apellidoPaterno"
                                    required
                                    pattern
                                    margin
                                  >
                                    <InputItem placeholder="Apellido Paterno" disabled={this.state.factura || !this.state.facturable} />
                                  </FormItem>

                                  <FormItem
                                    label="Apellido Materno"
                                    name="apellidoMaterno"
                                    required
                                    pattern
                                    margin
                                  >
                                    <InputItem placeholder="Apellido Materno" disabled={this.state.factura || !this.state.facturable} />
                                  </FormItem>
                                </>
                              )
                            }
                          }}
                        </FormItem>


                        <FormItem
                          label="Régimen Fiscal"
                          name="regimenFiscalId"
                          required
                          margin
                        >
                          <SelectItem
                            width="100%"
                            placeholder="Selecciona el Régimen Fiscal"
                            disabled={this.state.factura || !this.state.facturable}
                          >
                            {this.state.objetoRegimenFiscal}
                          </SelectItem>
                        </FormItem>


                        <FormItem
                          label="Uso de CFDI"
                          name="usoDeCfdiId"
                          required
                          margin
                        >
                          <SelectItem
                            width="100%"
                            placeholder="Selecciona el Uso de CFDI"
                            disabled={this.state.factura || !this.state.facturable}
                          >
                            {this.state.objetoCfdi}
                          </SelectItem>
                        </FormItem>



                        {/* Campo para correo electrónico */}
                        <FormItem
                          label="Correo Electrónico"
                          name="correo"
                          required
                          margin
                        >
                          <InputItem placeholder="Ingresa tu correo" />
                        </FormItem>
                        <FormItem
                          label="Código Postal"
                          name="coloniaId"
                          margin
                          required
                        >
                          <SelectItem
                            disabled={this.state.factura || !this.state.facturable}
                            placeholder="Colonia"
                            onSearch={this.onSearchColonia.bind(this)}
                            onSelect={this.onSelectColoniaId.bind(this)}
                            loading={this.state.loadingColonia}
                            mostrarCarga={this.state.loadingColonia}
                          >
                            {this.state.objetoColonias === null ? (
                              <Option disabled>
                                Eliga primero un municipio
                              </Option>
                            ) : (
                              this.state.objetoColonias
                            )}
                            <Option disabled>Busque por codigo postal</Option>
                          </SelectItem>
                        </FormItem>
                        <FormItem
                          label="Municipio"
                          name="municipioId"
                          margin
                          required
                        >
                          <SelectItem
                            disabled={this.state.factura || !this.state.facturable}
                            placeholder="Municipio"
                            onChange={this.onChangeMunicipio.bind(this)}
                            loading={this.state.loadingColonia}
                            mostrarCarga={this.state.loadingColonia}
                          >
                            {this.state.objetoMunicipios === null ? (
                              <Option disabled>Eliga primero un estado</Option>
                            ) : (
                              this.state.objetoMunicipios
                            )}
                          </SelectItem>
                        </FormItem>
                        <FormItem label="Estado" name="estadoId" required margin>
                          <SelectItem
                            disabled={this.state.factura || !this.state.facturable}
                            placeholder="Estado"
                            onChange={this.onChangeEstado.bind(this)}
                            loading={this.state.loadingColonia}
                            mostrarCarga={this.state.loadingColonia}
                          >
                            {this.state.objetoEstados}
                          </SelectItem>
                        </FormItem>
                        <FormItem label="Pais" name="paisId" required margin>
                          <SelectItem
                            disabled={this.state.factura}
                            placeholder="Pais"
                            loading={this.state.loadingColonia}
                            mostrarCarga={this.state.loadingColonia}
                          >
                            {this.state.objetoPaises}
                          </SelectItem>
                        </FormItem>
                        {(!this.state.factura && this.state.facturable) ?
                          <FormItem noStyle>
                            <ButtonItem icon={<BookOutlined />} style={{ marginTop: 10 }} type="primary" htmlType="submit" block>
                              Generar Factura
                            </ButtonItem>
                          </FormItem>
                          :
                          <FormItem noStyle>
                            <ButtonItem icon={<SendOutlined />} style={{ marginTop: 10 }} type="primary" block onClick={() => this.enviarVenta(this.state.idKiosko)}>
                              Enviar Nuevamente
                            </ButtonItem>
                            <ButtonItem style={{ marginTop: 10 }} icon={<DownloadOutlined />} type="primary" block blanco onClick={() => this.descargarVenta(this.state.idKiosko)}>
                              Descargar Factura
                            </ButtonItem>
                          </FormItem>
                        }
                      </Form>
                    </Card>
                  </Col>
                  <Col xs={24} sm={24} lg={12} xl={12}>
                    {this.state.error ? ( // Muestra el error si ocurre
                      <div style={{ color: "red", textAlign: "center" }}>
                        {this.state.error}
                      </div>
                    ) : (
                      <iframe
                        title="Factura PDF"
                        style={{ width: "100%", height: "100vh", marginTop: 40 }}
                        id="kioskoPdf"
                        name="kioskoPdf"
                      />
                    )}
                  </Col>
                </Row>
              </Content>
            )
              :
              (
                <Content style={{ marginTop: 68, padding: "20px", width: '100%', }}>

                  <div >
                    <Card id="kiosko-card" >
                      <Result
                        key="kiosko"
                        title={this.state.tipo === "factura" ? "Facturación Electrónica" : "Generar Factura"}
                        subTitle="Ingresa los datos necesarios para generar tu factura"
                        status={this.state.tipo === 'factura' ? 'success' : 'info'}
                      />
                      <Form
                        ref={this.formRefBuscarVenta}
                        name="buscarVenta"
                        layout
                        onFinish={this.onFinishFolio.bind(this)}
                        onFinishFailed={this.onFinishFailed}
                      >
                        <FormItem
                          label="Folio"
                          name="folio"
                          pattern
                          required
                          initialValue={kioskoId}
                        >
                          <InputItem placeholder="Ingresa el Folio" />
                        </FormItem>
                        <FormItem noStyle>
                          <ButtonItem icon={<SearchOutlined />} style={{ marginTop: 10 }} type="primary" htmlType="submit" block disabled={this.state.factura}>
                            Buscar
                          </ButtonItem>
                        </FormItem>

                      </Form>
                    </Card>
                  </div>

                </Content>
              )}
            <Footer id="content-background" style={{ textAlign: "center" }}>
              Biwo ©2020
            </Footer>
          </Layout>
        </Spin>
      </>
    );
  }
}



export default Kiosko;
