import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Form as FormInitial,
  message,
  Select,
  PageHeader,
  Spin,
  Upload,
  Space,
  Row,
  Col,
  Checkbox,
  Input,
  Switch,
  Popconfirm,
  Divider,
} from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import xmlToJson from "../../../Components/xmlToJson";
import {
  BoxItem,
  cuentaMask,
  Form,
  FormItem,
  HeaderItem,
  InputItem,
  InputNumberItem,
  SelectItem,
  ButtonItem,
  dineroDisabledMask,
  ErrorItem,
} from "../../../Components/Items";
import AgregarFamilia from "../Familias/AgregarFamilia/AgregarFamilia";
import AgregarUnidad from "../Insumos/AgregarInsumo/AgregarUnidad/AgregarUnidad";
import AgregarCuentaContable from "../CuentasContables/AgregarCuentaContable/AgregarCuentaContable";
import decodeHTMLEntities from "../../../Components/decodeHtmlEntities";

const { Option } = Select;

class LeerXml extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    archivosXml: [],
    proveedorId: null,
    conceptos: [],
    modalAgregarFamilia: false,
    modalAgregarUnidad: false,
    todasProveedores: null,
    objetoUsosDeCFDI: null,
    todasUsosDeCFDI: null,
    objetoTipoDeProveedor: null,
    todasTipoDeProveedor: null,
    todasMonedas: null,
    objetoMonedas: null,
    objetoUnidades: null,
    todasUnidades: null,
    objetoFamilia: null,
    objetoImpuestos: null,
    objetoRetenciones: null,
    todasFamilia: null,
    todasImpuestos: null,
    todasRetenciones: null,
    todasGrupoSat: null,
    todasClaseSat: null,
    todasCodigoSat: null,
    objetoGrupoSat: null,
    objetoClaseSat: null,
    objetoCodigoSat: null,
    todasInsumos: null,
    objetoInsumos: null,
    comprobante: null,
    agregar: {},
    insumo: null,
    impuestoCombustible: false,
    insumoYaExisteRelacion: false,
    sigNumero: 0,
    modalAgregarCuentaContable: false,
    todasCuentasContablesFinales: null,
    objetoCuentasContablesFinales: null,
    proveedorHeader: '',
    familiaGastoNoDeducible: false
  };

  async componentDidMount() {
    this.setState({ loading: true })
    await this.consultarImportantes();
    await this.consultarTodo();
    if (this.props?.location?.state) {
      this.leerXmlData(this.props.location.state)
    }
    this.setState({ loading: false })
  }

  async leerXmlData(state) {
    this.setState({ loading: true });
    if (!state.fileData) {
      return this.setState({ loading: false })
    }
    const hola = JSON.parse(state.fileData);
    const comprobante = hola?.attributes;
    const emisor = hola.children.find(
      (valueXml) => valueXml.name === "cfdi:Emisor"
    )?.attributes;
    const receptor = hola.children.find(
      (valueXml) => valueXml.name === "cfdi:Receptor"
    )?.attributes;
    const conceptos = hola.children.find(
      (valueXml) => valueXml.name === "cfdi:Conceptos"
    )?.children;
    let agregar = {};

    if (
      receptor.Rfc?.toLowerCase() === this.state.empresaRfc?.toLowerCase()
    ) {
      this.setState({ conceptos }, () => {
        if (
          !this.state.todasProveedores?.find(
            (valueTodos) =>
              valueTodos.rfc?.toLowerCase() === emisor.Rfc?.toLowerCase()
          )
        ) {
          agregar = {
            moneda: comprobante.Moneda,
            agregar: "proveedor",
            rfc: emisor.Rfc,
            nombre: emisor.Nombre,
            regimenFiscal: emisor.RegimenFiscal,
          };
        } else {
          const proveedor = this.state.todasProveedores?.find(
            (valueTodos) =>
              valueTodos.rfc?.toLowerCase() === emisor.Rfc?.toLowerCase()
          );
          this.setState({ proveedorId: proveedor._id, proveedorHeader: (proveedor.persona ? proveedor.razonSocial : (proveedor.nombre + ' ' + proveedor.apellidoPaterno + ' ' + proveedor.apellidoMaterno)) + ' - ' + proveedor.rfc }, () => {
            this.sigAgregar()
          });
        }
      });
    } else {
      return message.error(
        "El rfc del receptor no es igual al de la empresa"
      );
    }

    this.setState({ agregar, comprobante, loading: false });
    this.setState({
      archivosXml: [
        {
          name: state.fileName,
          status: "success",
        },
      ],
      archivo: state.fileData,
    });
  }

  promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));

  async consultarImportantes() {
    this.setState({ loading: true });

    const responseProveedores = await axios
      .post(
        "proveedores/selectProveedores",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )

    if (responseProveedores.data.status === 404) {
    } else {
      await this.promisedSetState({
        todasProveedores: responseProveedores.data,
      });
    }
    this.setState({ loading: false });
  }

  async consultarTodo() {
    const responseConfiguracion = axios
      .post(
        "empresas/selectConfiguracionGeneral",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )

    const responseTipoDeProveedores = axios
      .post(
        "tiposdeproveedores/selectTiposDeProveedores",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )

    const responseMonedas = axios
      .post(
        "monedas/selectMonedas",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )

    const responseUsoCFDIS = axios
      .post(
        "usodecfdi/selectUsosDeCFDI",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )

    const responseDatosParaInsumo = axios
      .post(
        "insumos/selectDatosParaInsumo",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )

    const responseCuentasContables = axios
      .post(
        "cuentascontables/selectCuentasContablesFinales",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )

    const responses = await Promise.all([
      responseConfiguracion,
      responseTipoDeProveedores,
      responseMonedas,
      responseUsoCFDIS,
      responseDatosParaInsumo,
      responseCuentasContables
    ])

    let newState = {}

    if (responses[0].status === 404) {
      message.error("Error no se encontro configuracion de esta empresa");
    } else {
      newState.empresaRfc = responses[0].data.rfc
    }


    if (responses[1].status === 404) {
    } else {
      newState.todasTipoDeProveedor = responses[1].data
      newState.objetoTipoDeProveedor = responses[1].data.length > 0 ? responses[1].data.map((value) => {
        return (
          <Option value={value._id} key={value._id}>
            {value.nombre + " - " + value.descripcion}
          </Option>
        );
      }) : []
    }


    if (responses[2].status === 404) {
    } else {
      newState.todasMonedas = responses[2].data
      newState.objetoMonedas = responses[2].data.map((value) => {
        return (
          <Option value={value._id} key={value._id}>
            {value.nombre + " - " + value.abreviacion}
          </Option>
        );
      })
    }


    if (responses[3].status === 404) {
    } else {
      newState.todasUsosDeCFDI = responses[3].data
      newState.objetoUsosDeCFDI = responses[3].data.map((value) => {
        return (
          <Option value={value._id} key={value._id}>
            {value.descripcion}
          </Option>
        );
      })
    }


    if (responses[4].data.status === 404) {
    } else {
      newState.sigNumero = responses[4].data.sigNumero
      newState.todasGrupoSat = responses[4].data.subclases
      newState.todasRetenciones = responses[4].data.retenciones
      newState.todasImpuestos = responses[4].data.impuestos
      newState.todasFamilia = responses[4].data.familias
      newState.todasMonedas = responses[4].data.monedas
      newState.todasUnidades = responses[4].data.unidades
      newState.calcularPreciosAutomaticamente =
        responses[4].data.configuracion.calcularPreciosAutomaticamente
      newState.calcularPreciosSobre = responses[4].data.configuracion.calcularPreciosSobre
      newState.precios = responses[4].data.configuracion.precios.map((value) => {
        return {
          porcentaje: value.porcentaje,
          nombre: value.nombre,
          precioId: value._id,
        };
      })


      newState.objetoUnidades = responses[4].data.unidades.map((value) => {
        return (
          <Option value={value._id} key={value._id}>
            {value.unidadSat.c_ClaveUnidad +
              " - " +
              (value.descripcion
                ? value.nombre + " - " + value.descripcion
                : value.nombre)}
          </Option>
        );
      })
      newState.objetoMonedas = responses[4].data.monedas.map((value) => {
        return (
          <Option value={value._id} key={value._id}>
            {value.nombre + " - " + value.abreviacion}
          </Option>
        );
      })
      newState.objetoFamilia = responses[4].data.familias.map((value) => {
        return (
          <Option value={value._id} key={value._id}>
            {value.nombre}
          </Option>
        );
      })
      newState.objetoImpuestos = responses[4].data.impuestos.map((value) => {
        return (
          <Option value={value._id} key={value._id}>
            {value.nombre}
          </Option>
        );
      })
      newState.objetoRetenciones = responses[4].data.retenciones.map((value) => {
        return (
          <Option value={value._id} key={value._id}>
            {value.nombre}
          </Option>
        );
      })
      newState.objetoGrupoSat = responses[4].data.subclases.map((value) => {
        return (
          <Option value={value._id} key={value._id}>
            {value.codigo + " - " + value.descripcion}
          </Option>
        );
      })
    }

    if (responses[5].status === 404) {
    } else {
      newState.todasCuentasContablesFinales = responses[5].data
      newState.objetoCuentasContablesFinales = responses[5].data.map((value) => {
        return (
          <Option value={value._id} key={value._id}>
            {cuentaMask(
              value.nivel1 +
              value.nivel2 +
              value.nivel3 +
              value.nivel4 +
              value.nivel5
            ) +
              " - " +
              value.descripcion}
          </Option>
        );
      })
    }

    //SetState

    await this.promisedSetState(newState)

  }

  async onChangeArchivo(e) {
    this.setState({ loading: true, agregar: {}, insumos: null, impuestoCombustible: false, sigNumero: 0, conceptos: [] });
    this.props.history.replace({ state: {} })
    if (e.file.type === "text/xml") {
      var reader = new FileReader();
      let hola
      reader.onload = (event) => {
        hola = new xmlToJson().parseFromString(event.target.result);
        const comprobante = hola?.attributes;
        const emisor = hola.children.find(
          (valueXml) => valueXml.name === "cfdi:Emisor"
        )?.attributes;
        const receptor = hola.children.find(
          (valueXml) => valueXml.name === "cfdi:Receptor"
        )?.attributes;
        const conceptos = hola.children.find(
          (valueXml) => valueXml.name === "cfdi:Conceptos"
        )?.children;
        let agregar = {};

        if (
          receptor.Rfc?.toLowerCase() === this.state.empresaRfc?.toLowerCase()
        ) {
          this.setState({ conceptos }, () => {
            if (
              !this.state.todasProveedores?.find(
                (valueTodos) =>
                  valueTodos.rfc?.toLowerCase() === emisor.Rfc?.toLowerCase()
              )
            ) {
              agregar = {
                moneda: decodeHTMLEntities(comprobante.Moneda),
                agregar: "proveedor",
                rfc: decodeHTMLEntities(emisor.Rfc),
                nombre: decodeHTMLEntities(emisor.Nombre),
                regimenFiscal: decodeHTMLEntities(emisor.RegimenFiscal),
              };
            } else {
              const proveedor = this.state.todasProveedores?.find(
                (valueTodos) =>
                  valueTodos.rfc?.toLowerCase() === emisor.Rfc?.toLowerCase()
              );
              this.setState({ proveedorId: proveedor._id, proveedorHeader: (proveedor.persona ? proveedor.razonSocial : (proveedor.nombre + ' ' + proveedor.apellidoPaterno + ' ' + proveedor.apellidoMaterno)) + ' - ' + proveedor.rfc }, () => {
                this.sigAgregar()
              });
            }
          });
        } else {
          this.setState({ loading: false });
          return message.error(
            "El rfc del receptor no es igual al de la empresa"
          );
        }

        this.setState({ agregar, comprobante, loading: false });
      };
      reader.readAsText(e.file);
      this.setState({
        archivosXml: [
          {
            ...e.file,
            name: e.file.name,
            status: "success",
          },
        ],
        archivo: hola,
      });
    } else {
      message.error("El archivo debe ser un archivo de xml");
      this.setState({ loading: false });
    }
  }

  agregarProveedorXml(values) {
    this.setState({ loading: true });
    axios
      .post(
        "proveedores/agregarProveedor",
        { ...values, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          this.setState({ proveedorId: res.data.codigo, proveedorHeader: (values.persona ? values.razonSocial : (values.nombre + ' ' + values.apellidoPaterno + ' ' + values.apellidoMaterno)) + ' - ' + values.rfc }, () => {
            message.success("Se ha registrado un proveedor");
            this.formRef.current.resetFields();
            this.sigAgregar();
          });
        } else if (res.data.success === 1) {
          message.error("No se puede registrar el proveedor");
        } else {
          message.error("Hubo un error y el proveedor no se registro");
        }
      });
  }

  async sigAgregar() {
    this.setState({ loading: true });
    let agregar = {};

    const conceptos = [...this.state.conceptos];
    const valueConcepto = conceptos[0];

    if (valueConcepto) {
      const attrInsumo = valueConcepto.attributes;
      await this.consultarTodo()
      const impuestos = valueConcepto.children
        .find((valueTodos) => valueTodos.name === "cfdi:Impuestos")
        ?.children.find((valueTodos) => valueTodos.name === "cfdi:Traslados")
        ?.children.map((valueImpuesto) => {
          const attrImpuesto = valueImpuesto.attributes;


          let impuesto
          let monto
          let tasaCuota

          if (attrImpuesto.Impuesto === '002') {
            impuesto = 2
          } else if (attrImpuesto.Impuesto === '003') {
            impuesto = 3
          }

          if (attrImpuesto.TipoFactor === 'Tasa') {

            let totalInsumo = Number(attrInsumo.Importe)

            if (attrInsumo.Descuento) {
              totalInsumo -= Number(attrInsumo.Descuento)
            }

            if (Number(attrImpuesto.Base) === totalInsumo) {
              tasaCuota = 1
            } else {
              tasaCuota = 2
            }

            monto = (Number(attrImpuesto.TasaOCuota) * 100)
          } else if (attrImpuesto.TipoFactor === 'Cuota') {

            tasaCuota = 3
            monto = Number(attrImpuesto.TasaOCuota)

          } else if (attrImpuesto.TipoFactor === 'Exento') {

            tasaCuota = 5
            monto = 0

          }

          let impuestoId


          if (impuesto && (monto || monto === 0) && tasaCuota) {

            if (tasaCuota === 2) {
              impuestoId = this.state.todasImpuestos.find((todosImpuestos) => {
                return todosImpuestos.impuesto === impuesto
                  && todosImpuestos.monto === monto
                  && (todosImpuestos.tasaCuota === 2 || todosImpuestos.tasaCuota === 4)
              })
            } if (tasaCuota === 1) {
              impuestoId = this.state.todasImpuestos.find((todosImpuestos) => {
                return todosImpuestos.impuesto === impuesto
                  && todosImpuestos.monto === monto
                  && todosImpuestos.tasaCuota === 1
              })
              if (!impuestoId) {
                impuestoId = this.state.todasImpuestos.find((todosImpuestos) => {
                  return todosImpuestos.impuesto === impuesto
                    && todosImpuestos.monto === monto
                    && todosImpuestos.tasaCuota === 2
                })
              }
            } else {
              impuestoId = this.state.todasImpuestos.find((todosImpuestos) => {
                return todosImpuestos.impuesto === impuesto
                  && todosImpuestos.monto === monto
                  && todosImpuestos.tasaCuota === tasaCuota
              })
            }

          }

          if (impuestoId) {
            return {
              impuesto: attrImpuesto.Impuesto === "002" ? "IVA" : "IEPS",
              tasaCuota: attrImpuesto.TasaOCuota,
              tipoFactor: attrImpuesto.TipoFactor,
              impuestoId: impuestoId._id
            };
          } else {
            return {
              impuesto: attrImpuesto.Impuesto === "002" ? "IVA" : "IEPS",
              tasaCuota: attrImpuesto.TasaOCuota,
              tipoFactor: attrImpuesto.TipoFactor,
            };
          }




        });
      await axios
        .post(
          "insumos/buscarRelacion",
          {
            empresaId: this.props.empresaId,
            proveedorId: this.state.proveedorId,
            descripcion: decodeHTMLEntities(attrInsumo.Descripcion),
            claveProdServ: decodeHTMLEntities(attrInsumo.ClaveProdServ),
            claveUnidad: decodeHTMLEntities(attrInsumo.ClaveUnidad),
            codigo: decodeHTMLEntities(attrInsumo.NoIdentificacion),
          },
          {
            headers: this.props.headersToken,
          }
        )
        .then(async (res) => {
          if (res.data.status === 404) {
            const encontrarUnidad = this.state.todasUnidades.find(
              (valueTodos) =>
                valueTodos.unidadSat.c_ClaveUnidad ===
                attrInsumo.ClaveUnidad
            )
            if (encontrarUnidad) {
              agregar = {
                moneda: this.state.comprobante.Moneda,
                agregar: "insumo",
                codigoSat: attrInsumo.ClaveProdServ,
                unidadSat: encontrarUnidad._id,
                impuestos: impuestos,
                unidad: attrInsumo.ClaveUnidad,
                descripcion: decodeHTMLEntities(attrInsumo.Descripcion),
                claveProdServ: decodeHTMLEntities(attrInsumo.ClaveProdServ),
                claveUnidad: decodeHTMLEntities(attrInsumo.ClaveUnidad),
                codigo: decodeHTMLEntities(attrInsumo.NoIdentificacion),
                precioUnitario: attrInsumo.ValorUnitario,
              };
            } else {
              await axios.post('unidades/agregarUnidadPorSAT',
                {
                  unidadSat: attrInsumo.ClaveUnidad,
                  empresaId: this.props.empresaId,
                },
                {
                  headers: this.props.headersToken,
                }
              ).then(async (res) => {
                if (res.data.id) {
                  agregar = {
                    actualizar: true,
                    moneda: this.state.comprobante.Moneda,
                    agregar: "insumo",
                    codigoSat: attrInsumo.ClaveProdServ,
                    unidadSat: res.data.id,
                    unidad: attrInsumo.ClaveUnidad,
                    impuestos: impuestos,
                    descripcion: decodeHTMLEntities(attrInsumo.Descripcion),
                    claveProdServ: decodeHTMLEntities(attrInsumo.ClaveProdServ),
                    claveUnidad: decodeHTMLEntities(attrInsumo.ClaveUnidad),
                    codigo: decodeHTMLEntities(attrInsumo.NoIdentificacion),
                    precioUnitario: attrInsumo.ValorUnitario,
                  };
                } else {
                  agregar = {
                    moneda: this.state.comprobante.Moneda,
                    agregar: "insumo",
                    codigoSat: attrInsumo.ClaveProdServ,
                    unidadSat: undefined,
                    unidad: attrInsumo.ClaveUnidad,
                    impuestos: impuestos,
                    descripcion: decodeHTMLEntities(attrInsumo.Descripcion),
                    claveProdServ: decodeHTMLEntities(attrInsumo.ClaveProdServ),
                    claveUnidad: decodeHTMLEntities(attrInsumo.ClaveUnidad),
                    codigo: decodeHTMLEntities(attrInsumo.NoIdentificacion),
                    precioUnitario: attrInsumo.ValorUnitario,
                  };
                }
              }).catch((err) => {
                agregar = {
                  moneda: this.state.comprobante.Moneda,
                  agregar: "insumo",
                  codigoSat: attrInsumo.ClaveProdServ,
                  unidadSat: undefined,
                  unidad: attrInsumo.ClaveUnidad,
                  impuestos: impuestos,
                  descripcion: decodeHTMLEntities(attrInsumo.Descripcion),
                  claveProdServ: decodeHTMLEntities(attrInsumo.ClaveProdServ),
                  claveUnidad: decodeHTMLEntities(attrInsumo.ClaveUnidad),
                  codigo: decodeHTMLEntities(attrInsumo.NoIdentificacion),
                  precioUnitario: attrInsumo.ValorUnitario,
                };
              })
            }
          } else {
            conceptos.shift();
            this.setState({ conceptos }, () => {
              this.sigAgregar()
            });
          }
        });

      if (agregar.actualizar) {
        await this.consultarTodo()
      }

      conceptos.shift();
      this.setState({ agregar, conceptos, loading: false, insumo: null });
    } else {
      conceptos.shift();
      this.setState({ loading: false, agregar: {}, conceptos, insumo: null });
      message.info('La lectura del xml se ha acabado')
      if (this.props?.location?.state) {
        this.props.history.push(this.props.location.state.origin, { fileName: this.state.archivosXml[0].name, fileData: this.state.archivo, ...this.props.location.state.state })
      }
    }
  }

  abrirAgregarFamilia() {
    this.setState({ modalAgregarFamilia: true });
  }
  cerrarAgregarFamilia(codigo) {
    this.setState({ modalAgregarFamilia: false });
    this.consultarTodo();
  }

  abrirAgregarUnidad() {
    this.setState({ modalAgregarUnidad: true });
  }
  cerrarAgregarUnidad(codigo) {
    this.setState({ modalAgregarUnidad: false });
    this.consultarTodo();
  }

  opcionAgregarInsumo() {
    this.setState({ insumo: 'agregar' }, () => {
      const valueDato = this.state.agregar
      if (valueDato) {
        if (this.formRef.current && valueDato.codigoSat) {
          this.formRef.current.resetFields()
          axios
            .post(
              "codigoinsumos/buscarCodigo",
              { empresaId: this.props.empresaId, codigo: valueDato.codigoSat },
              {
                headers: this.props.headersToken,
              }
            )
            .then((res) => {
              if (res.data.status === 404) {
              } else {
                const grupoId = this.state.todasGrupoSat?.find(valueTodos => valueTodos.codigo.toString() === valueDato.codigoSat.toString().slice(0, 4))?._id
                this.formRef.current.setFieldsValue({
                  claseSatId: res.data.claseId,
                  codigoSatId: res.data._id,
                  grupoSatId: grupoId
                });
                this.onChangeGrupo(grupoId, 'true')
                this.onChangeClase(res.data.claseId, 'true')
              }
            })
        }
      }
    })
  }

  async opcionRelacionarInsumo() {
    this.setState({ insumo: 'relacionar' })
    axios
      .post(
        "insumos/selectInsumosCompra",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false })
        if (res.data.status === 404) {
        } else {
          this.setState({
            todasInsumos: res.data,
            objetoInsumos: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.stringBuscar}
                </Option>
              );
            }),
          });
        }
      });
  }

  agregarInsumoXml(values) {
    this.setState({ loading: true });
    let fd = new FormData();
    fd.append("empresaId", this.props.empresaId);
    fd.append("proveedorId", this.state.proveedorId);
    fd.append("descripcionProveedor", this.state.agregar.descripcion);
    fd.append("codigoProveedorRelacion", this.state.agregar.codigo);
    fd.append("claveProdServ", this.state.agregar.claveProdServ);
    fd.append("claveUnidad", this.state.agregar.claveUnidad);
    fd.append("precioUnitario", this.state.agregar.precioUnitario);
    for (var key in values) {
      if (values[key] === undefined || values[key] === "") {
      } else {
        if (key === "precios") {
          fd.append(key, JSON.stringify(values[key]));
        } else if (key === "equivalencias") {
          fd.append(key, JSON.stringify(values[key]));
        } else if (key === "impuestos") {
          fd.append(key, JSON.stringify(values[key]));
        } else if (key === "retenciones") {
          fd.append(key, JSON.stringify(values[key]));
        } else {
          fd.append(key, values[key]);
        }
      }
    }
    axios
      .post(
        "insumos/agregarInsumoConRelacion",
        fd,
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success("Se ha registrado un insumo");
          this.formRef.current.resetFields();
          this.sigAgregar()
        } else if (res.data.success === 1) {
          message.error("No se puede registrar el insumo");
        } else {
          message.error("Hubo un error y el insumo no se registro");
        }
      });
  }

  relacionarInsumoXml(values) {
    this.setState({ loading: true });
    axios
      .post(
        "insumos/relacionarInsumo",
        { ...values, precioUnitario: this.state.agregar.precioUnitario, proveedorId: this.state.proveedorId, descripcionProveedor: this.state.agregar.descripcion, codigoProveedorRelacion: this.state.agregar.codigo, claveProdServ: this.state.agregar.claveProdServ, claveUnidad: this.state.agregar.claveUnidad, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success("Se ha relacionado un insumo");
          this.formRef.current.resetFields();
          this.sigAgregar()
        } else if (res.data.success === 1) {
          message.error("No se puede relacionar el insumo");
        } else {
          message.error("Hubo un error y el insumo no se relaciono");
        }
      });
  }

  onFinishFailed(e) {
    ErrorItem(e);
  }

  onChangeCosto() {
    if (!this.formRef.current) return
    const valueCosto = this.formRef.current.getFieldValue("costo")
    if (!valueCosto) return
    let arrayValues = this.formRef.current.getFieldValue("precios");
    let arrayNuevo = arrayValues.map((value, index) => {
      let precio;
      if (this.state.calcularPreciosSobre) {
        precio = ((valueCosto * (100 + value.porcentaje)) / 100).toFixed(2);
      } else {
        precio = (valueCosto / ((100 - value.porcentaje) / 100)).toFixed(2);
      }
      return { ...value, precio };
    });
    this.formRef.current.setFieldsValue({
      precios: arrayNuevo,
    });
  }

  onChangeGrupo(e, conservar) {
    if (!this.formRef.current) return
    if (conservar !== 'true') {
      this.formRef.current.setFieldsValue({
        claseSatId: undefined,
        codigoSatId: undefined,
      });
    }
    axios
      .post(
        "codigoinsumos/selectClases",
        { empresaId: this.props.empresaId, grupoId: e },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasClaseSat: res.data });
          this.setState({
            objetoClaseSat: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.codigo + " - " + value.descripcion}
                </Option>
              );
            }),
          });
        }
      });
  }

  onChangeClase(e, conservar) {
    if (!this.formRef.current) return
    if (conservar !== 'true') { this.formRef.current.setFieldsValue({ codigoSatId: undefined }); }
    axios
      .post(
        "codigoinsumos/selectSubclases",
        { empresaId: this.props.empresaId, claseId: e },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasCodigoSat: res.data });
          this.setState({
            objetoCodigoSat: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.c_ClaveProdServ + " - " + value.descripcion}
                </Option>
              );
            }),
          });
        }
      })
  }

  onChangeImpuesto() {
    const impuestos = this.formRef.current.getFieldValue('impuestos')
    let combustible = false
    let impuestoIdCombustible
    impuestos.map((valueImpuesto) => {
      if (valueImpuesto?.impuestoId) {
        const impuesto = this.state.todasImpuestos.find((valueTodasImpuesto) => valueTodasImpuesto._id.toString() === valueImpuesto.impuestoId.toString())
        if (impuesto.combustible) {
          combustible = true
          impuestoIdCombustible = impuesto._id
        }
      }
      return null
    })
    if (combustible) {
      message.info('Si el impuesto es especial solamente se puede elegir uno y no se puede vender')
      this.setState({ impuestoCombustible: combustible })
      this.formRef.current.setFieldsValue({ impuestos: [{ impuestoId: impuestoIdCombustible }], venta: false })
    } else {
      this.setState({ impuestoCombustible: combustible })
    }
  }

  abrirAgregarCuentaContable() {
    this.setState({ modalAgregarCuentaContable: true });
  }
  cerrarAgregarCuentaContable(codigo) {
    this.setState({ modalAgregarCuentaContable: false });
    this.consultarTodo()
  }

  onChangeInsumoARelacionar(value) {
    this.setState({ loading: true });
    axios
      .post(
        "insumos/checarExistenciaRelacion",
        { insumoId: value, proveedorId: this.state.proveedorId, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          this.setState({ loading: false, insumoYaExisteRelacion: res.data.yaExisteRelacion });

          if (value) {
            const insumo = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === value.toString())
            if (insumo.insumoPrincipalId) {
              this.formRef.current.setFieldsValue({ conversion: 1 })
            }
          }
        } else if (res.data.success === 1) {
          message.error("No se encontro informacion del insumo");
          this.setState({ loading: false });
        } else {
          message.error("No se encontro informacion del insumo");
          this.setState({ loading: false });
        }
      });
  }

  onChangeFamlia(familiaId) {
    const familia = this.state.todasFamilia.find((valueTodasFamilia) => valueTodasFamilia._id.toString() === familiaId.toString())
    if (familia.gastoNoDeducible) {
      message.info('Si la familia es gasto no deducible no se puede inventariar ni vender')
      this.setState({ familiaGastoNoDeducible: true })
      this.formRef.current.setFieldsValue({ inventariable: false, venta: false })
    } else {
      this.setState({ familiaGastoNoDeducible: false })
    }
  }

  render() {
    return (
      <>
        <Spin spinning={this.state.loading}>
          <PageHeader
            key="leerXml"
            title={"Leer Xml" + (this.state.proveedorHeader ? (' -  Proveedor: ' + this.state.proveedorHeader) : '')}
            style={{
              border: "1px solid rgb(235, 237, 240)",
              backgroundColor: "white",
              marginBottom: 10,
            }}
            extra={(
              <Upload
                showUploadList={{
                  showRemoveIcon: false,
                  showPreviewIcon: false,
                }}
                fileList={this.state.archivosXml}
                customRequest={this.onChangeArchivo.bind(this)}
                accept=".xml"
              >
                <ButtonItem blanco icon={<UploadOutlined />}>Subir</ButtonItem>
              </Upload>
            )}
          />
          {this.state.agregar !== null
            ? (() => {
              const valueDato = this.state.agregar;
              if (valueDato.agregar === "proveedor") {
                return (
                  <div
                    style={{
                      backgroundColor: "white",
                      padding: 20,
                      paddingTop: 5,
                    }}
                  >
                    <Spin spinning={this.state.loading}>
                      <Form
                        ref={this.formRef}
                        name="agregarProveedorXml"
                        layout
                        bottom={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "right",
                            }}
                          >
                            <Space>
                              <ButtonItem
                                type="primary"
                                size="small"
                                htmlType="submit"
                              >
                                Agregar
                              </ButtonItem>
                            </Space>
                          </div>
                        }
                        onFinish={this.agregarProveedorXml.bind(this)}
                        onFinishFailed={this.onFinishFailed}
                      >
                        <h1
                          style={{
                            width: "100%",
                            textAlign: "center",
                            margin: "20px 0px",
                          }}
                        >
                          Encontramos un proveedor nuevo, eliga los siguientes
                          datos para poder agregarlo
                        </h1>
                        <Row>
                          <Col span={12}>
                            <FormItem
                              name="persona"
                              label="Persona Moral"
                              valuePropName="checked"
                              initialValue={true}
                              margin
                            >
                              <Checkbox />
                            </FormItem>
                            <FormItem shouldUpdate noStyle>
                              {({ getFieldValue }) => {
                                if (getFieldValue("persona")) {
                                  return (
                                    <FormItem
                                      label="Razon Social"
                                      margin
                                      pattern
                                      required
                                      max={200}
                                      name="razonSocial"
                                      initialValue={valueDato.nombre}
                                    >
                                      <InputItem placeholder="Razon Social" />
                                    </FormItem>
                                  );
                                }
                              }}
                            </FormItem>
                            <FormItem shouldUpdate noStyle>
                              {({ getFieldValue }) => {
                                if (!getFieldValue("persona")) {
                                  return (
                                    <FormItem
                                      label="Nombre"
                                      margin
                                      pattern
                                      required
                                      name="nombre"
                                      initialValue={valueDato.nombre}
                                    >
                                      <InputItem placeholder="Nombre" />
                                    </FormItem>
                                  );
                                }
                              }}
                            </FormItem>
                            <FormItem shouldUpdate noStyle>
                              {({ getFieldValue }) => {
                                if (!getFieldValue("persona")) {
                                  return (
                                    <FormItem
                                      label="Appelido Paterno"
                                      margin
                                      pattern
                                      name="apellidoPaterno"
                                    >
                                      <InputItem placeholder="Appelido Paterno" />
                                    </FormItem>
                                  );
                                }
                              }}
                            </FormItem>
                            <FormItem shouldUpdate noStyle>
                              {({ getFieldValue }) => {
                                if (!getFieldValue("persona")) {
                                  return (
                                    <FormItem
                                      label="Appellido Materno"
                                      margin
                                      pattern
                                      name="apellidoMaterno"
                                    >
                                      <InputItem placeholder="Appellido Materno" />
                                    </FormItem>
                                  );
                                }
                              }}
                            </FormItem>
                            <FormItem
                              name="pagoElectronico"
                              label="Es pago electronico"
                              valuePropName="checked"
                              initialValue={true}
                              margin
                            >
                              <Checkbox />
                            </FormItem>
                            <FormItem
                              label="Tipo de Operación"
                              name="tipoOperacion"
                              required
                              margin
                              initialValue={3}
                            >
                              <SelectItem placeholder="Tipo de Operación">
                                <Option value={1}>
                                  03 - Prestacion de servicios
                                </Option>
                                <Option value={2}>
                                  06 - Arrendamiento de inmuebles
                                </Option>
                                <Option value={3}>85 - Otros</Option>
                                <Option value={4}>NA - No asignados</Option>
                              </SelectItem>
                            </FormItem>
                            <FormItem
                              label="Tipo de Tercero"
                              name="tipoTercero"
                              required
                              margin
                              initialValue={1}
                            >
                              <SelectItem placeholder="Tipo de Tercero">
                                <Option value={1}>
                                  04 - Proveedor Nacional
                                </Option>
                                <Option value={2}>
                                  05 - Proveedor Extranjero
                                </Option>
                                <Option value={3}>
                                  15 - Proveedor Global
                                </Option>
                              </SelectItem>
                            </FormItem>
                          </Col>
                          <Col span={12}>
                            <FormItem
                              label="Tipo de Proveedor"
                              margin
                              name="tipoProveedorId"
                              required
                            >
                              <SelectItem placeholder="Tipo de Proveedor">
                                {this.state.objetoTipoDeProveedor}
                              </SelectItem>
                            </FormItem>
                            <FormItem
                              label="RFC"
                              name="rfc"
                              required
                              margin
                              rfc
                              initialValue={valueDato.rfc}
                            >
                              <InputItem placeholder="RFC" />
                            </FormItem>
                            <FormItem
                              label="Tipo de Moneda"
                              name="monedaId"
                              required
                              margin
                              initialValue={
                                this.state.todasMonedas?.find(
                                  (valueTodos) =>
                                    valueTodos.abreviacion ===
                                    valueDato.moneda
                                )?._id
                              }
                            >
                              <SelectItem placeholder="Tipo de Moneda">
                                {this.state.objetoMonedas}
                              </SelectItem>
                            </FormItem>
                          </Col>
                        </Row>
                      </Form>
                    </Spin>
                  </div>
                );
              }
              if (valueDato.agregar === "insumo") {
                if (this.state.insumo === null) {
                  return (
                    <div
                      style={{
                        backgroundColor: "white",
                        padding: 20,
                        paddingTop: 5,
                      }}
                    >
                      <Row>
                        <Col span={12}>
                          <BoxItem>
                            <Row>
                              <Col span={12}>
                                <h1 style={{ margin: 0 }}>
                                  Codigo: {this.state.agregar.codigo}
                                </h1>
                              </Col>
                              <Col span={12}>
                                <h1 style={{ margin: 0 }}>
                                  Descripcion: {this.state.agregar.descripcion}
                                </h1>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={12}>
                                <p style={{ margin: 0 }}>
                                  Codigo SAT:{" "}
                                  {this.state.agregar.codigoSat}
                                </p>
                              </Col>
                              <Col span={12}>
                                <p style={{ margin: 0 }}>
                                  Impuestos: {this.state.agregar.impuestos?.map((valueImpuesto) => {
                                    let porcentajeoCuotoa
                                    if (valueImpuesto.tipoFactor === "Tasa") {
                                      porcentajeoCuotoa = valueImpuesto.tasaCuota * 100 + " %"
                                    } else if (valueImpuesto.tasaCuota) {
                                      porcentajeoCuotoa = valueImpuesto.tasaCuota
                                    } else if (valueImpuesto.tipoFactor) {
                                      porcentajeoCuotoa = valueImpuesto.tipoFactor
                                    }
                                    return (
                                      valueImpuesto.impuesto +
                                      " " +
                                      porcentajeoCuotoa +
                                      " "
                                    );
                                  })}
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={12}>
                                <p style={{ margin: 0 }}>
                                  Precio Unitario: {this.state.agregar.precioUnitario}
                                </p>
                              </Col>
                              <Col span={12}>
                                <p style={{ margin: 0 }}>
                                  Unidad SAT: {this.state.agregar.unidad}
                                </p>
                              </Col>
                            </Row>
                          </BoxItem>
                        </Col>
                        <Col span={12}>
                          <h1
                            style={{
                              width: "100%",
                              textAlign: "center",
                              margin: "20px 0px",
                            }}
                          >
                            Encontramos un insumo nuevo que desea hacer
                          </h1>
                          <div style={{ display: "flex", justifyContent: 'center', width: '100%' }}>
                            <Space>
                              {this.props.permisoAgregarInsumo &&
                                <ButtonItem
                                  type="primary"
                                  onClick={this.opcionAgregarInsumo.bind(this)}
                                >
                                  Agregar Insumo
                                </ButtonItem>
                              }
                              <ButtonItem
                                blanco
                                type="primary"
                                onClick={this.opcionRelacionarInsumo.bind(this)}
                              >
                                Relacionar a Insumo Existente
                              </ButtonItem>
                            </Space>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )
                } else if (this.state.insumo === 'agregar') {
                  return (
                    <div
                      style={{
                        backgroundColor: "white",
                        padding: 20,
                        paddingTop: 5,
                      }}
                    >
                      <Spin spinning={this.state.loading}>
                        <Form
                          ref={this.formRef}
                          name="agregarInsumoXml"
                          layout
                          bottom={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "right",
                              }}
                            >
                              <Space>
                                <ButtonItem
                                  type="primary"
                                  size='small'
                                  danger
                                  onClick={() => this.setState({ insumo: null })}
                                >
                                  Atras
                                </ButtonItem>
                                <ButtonItem
                                  type="primary"
                                  size="small"
                                  htmlType="submit"
                                >
                                  Agregar
                                </ButtonItem>
                              </Space>
                            </div>
                          }
                          onFinish={this.agregarInsumoXml.bind(this)}
                          onFinishFailed={this.onFinishFailed}
                        >
                          <Row>
                            <Col span={12}>
                              <FormItem
                                label="Permitir Venta"
                                name="venta"
                                margin
                                initialValue={false}
                                valuePropName="checked"
                              >
                                <Switch disabled={this.state.impuestoCombustible || this.state.familiaGastoNoDeducible} />
                              </FormItem>
                              <FormItem
                                label="Permitir Compra"
                                name="compra"
                                margin
                                initialValue={true}
                                valuePropName="checked"
                              >
                                <Switch disabled />
                              </FormItem>
                              <FormItem
                                name="codigo"
                                label="Codigo"
                                margin
                                pattern
                                initialValue={this.state.sigNumero}
                              >
                                <InputItem disabled placeholder="Codigo" />
                              </FormItem>
                              <FormItem
                                name="clave"
                                label="Clave"
                                margin
                                pattern
                                initialValue={this.state.sigNumero}
                              >
                                <InputItem placeholder="Clave" />
                              </FormItem>
                              <FormItem
                                name="codigoProveedor"
                                label="Codigo Proveedor"
                                margin
                                pattern
                                initialValue={valueDato.codigo}
                              >
                                <InputItem placeholder="Codigo Proveedor" />
                              </FormItem>
                              <FormItem
                                label="Descripción"
                                name="descripcion"
                                required
                                margin
                                pattern
                                initialValue={valueDato.descripcion}
                              >
                                <InputItem placeholder="Descripción" />
                              </FormItem>
                              <FormItem
                                label="Descripción Larga"
                                name="descripcionLarga"
                                required
                                margin
                                pattern
                                initialValue={valueDato.descripcion}
                              >
                                <InputItem placeholder="Descripción larga" />
                              </FormItem>
                              <h1 style={{ textAlign: "center" }}>
                                Unidad en Factura: {valueDato.unidad}
                              </h1>
                              <FormItem label="Unidad" margin>
                                <Input.Group compact>
                                  <FormItem
                                    name="unidadId"
                                    required
                                    noStyle
                                    initialValue={
                                      valueDato.unidadSat
                                    }
                                  >
                                    <SelectItem
                                      placeholder="Unidad"
                                      width="80%"
                                    >
                                      {this.state.objetoUnidades}
                                    </SelectItem>
                                  </FormItem>
                                  <ButtonItem
                                    blanco
                                    icon={<PlusOutlined />}
                                    style={{ width: "20%" }}
                                    onClick={this.abrirAgregarUnidad.bind(this)}
                                  />
                                </Input.Group>
                              </FormItem>
                              <h1 style={{ textAlign: "center" }}>
                                Impuestos en factura:{" "}
                                {valueDato.impuestos?.map((valueImpuesto) => {
                                  let porcentajeoCuotoa
                                  if (valueImpuesto.tipoFactor === "Tasa") {
                                    porcentajeoCuotoa = valueImpuesto.tasaCuota * 100 + " %"
                                  } else if (valueImpuesto.tasaCuota) {
                                    porcentajeoCuotoa = valueImpuesto.tasaCuota
                                  } else if (valueImpuesto.tipoFactor) {
                                    porcentajeoCuotoa = valueImpuesto.tipoFactor
                                  }
                                  return (
                                    valueImpuesto.impuesto +
                                    " " +
                                    porcentajeoCuotoa +
                                    " "
                                  );
                                })}
                              </h1>
                              <HeaderItem>Impuestos y Retenciones</HeaderItem>
                              <BoxItem>
                                <Row>
                                  <Col span={12}>
                                    <h1 style={{ textAlign: 'center' }}>Impuestos</h1>
                                    <FormInitial.List
                                      name="impuestos"
                                      initialValue={valueDato.impuestos}
                                    >
                                      {(fields, { add, remove }) => (
                                        <>
                                          {fields.map(({ name }, arrayKey) => (
                                            <Row key={arrayKey.toString()}>
                                              <Col span={22}>
                                                <FormItem
                                                  name={[name, "impuestoId"]}
                                                  fieldKey={[arrayKey, "impuestoId"]}
                                                  required
                                                  margin
                                                  noStyle
                                                >
                                                  <SelectItem width='100%' placeholder="Impuesto" onChange={this.onChangeImpuesto.bind(this)}>
                                                    {this.state.objetoImpuestos}
                                                  </SelectItem>
                                                </FormItem>
                                              </Col>
                                              <Col span={2}>
                                                <div style={{ textAlign: 'center' }}>
                                                  <MinusCircleOutlined
                                                    onClick={() => {
                                                      remove(name)
                                                      this.onChangeImpuesto()
                                                    }}
                                                  />
                                                </div>
                                              </Col>
                                            </Row>
                                          ))}
                                          {!this.state.impuestoCombustible ?
                                            <div style={{ textAlign: 'center' }}>
                                              <ButtonItem
                                                type="primary"
                                                onClick={() => add()}
                                                icon={<PlusOutlined />}
                                              >
                                                Agregar
                                              </ButtonItem>
                                            </div>
                                            : null}
                                        </>
                                      )}
                                    </FormInitial.List>
                                  </Col>
                                  <Col span={12}>
                                    <h1 style={{ textAlign: 'center' }}>Retenciones</h1>
                                    <FormInitial.List
                                      name="retenciones"
                                    >
                                      {(fields, { add, remove }) => (
                                        <>
                                          {fields.map(({ name }, arrayKey) => (
                                            <Row key={arrayKey.toString()}>
                                              <Col span={22}>
                                                <FormItem
                                                  name={[name, "retencionId"]}
                                                  fieldKey={[arrayKey, "retencionId"]}
                                                  required
                                                  margin
                                                  noStyle
                                                >
                                                  <SelectItem width='100%' placeholder="Retención">
                                                    {this.state.objetoRetenciones}
                                                  </SelectItem>
                                                </FormItem>
                                              </Col>
                                              <Col span={2}>
                                                <div style={{ textAlign: 'center' }}>
                                                  <MinusCircleOutlined
                                                    onClick={() => remove(name)}
                                                  />
                                                </div>
                                              </Col>
                                            </Row>
                                          ))}
                                          <div style={{ textAlign: 'center' }}>
                                            <ButtonItem
                                              type="primary"
                                              onClick={() => add()}
                                              icon={<PlusOutlined />}
                                            >
                                              Agregar
                                            </ButtonItem>
                                          </div>
                                        </>
                                      )}
                                    </FormInitial.List>
                                  </Col>
                                </Row>
                              </BoxItem>
                              <FormItem noStyle shouldUpdate={(prev, curr) => prev.venta !== curr.venta} >
                                {({ getFieldValue }) => {
                                  let venta = getFieldValue('venta')
                                  let familiaId = getFieldValue('familiaId')
                                  let familia
                                  if (familiaId) {
                                    familia = this.state?.todasFamilia?.find(valueTF => valueTF._id.toString() === familiaId.toString())
                                  }
                                  if (venta && (!familia?.cuentaIngresoContadoId || !familia?.cuentaIngresoCreditoId)) {
                                    return (
                                      <>
                                        <HeaderItem>Cuentas de Ingreso</HeaderItem>
                                        <BoxItem>
                                          <Row>
                                            <Col span={24}>
                                              <FormItem label="Cuenta Contado" margin>
                                                <Input.Group compact>
                                                  <FormItem name="cuentaIngresoContadoId" required noStyle>
                                                    <SelectItem placeholder="Cuenta Ingreso Contado" width="80%">
                                                      {this.state.objetoCuentasContablesFinales}
                                                    </SelectItem>
                                                  </FormItem>
                                                  <ButtonItem
                                                    icon={<PlusOutlined />}
                                                    style={{ width: "20%" }}
                                                    onClick={this.abrirAgregarCuentaContable.bind(this)}
                                                  />
                                                </Input.Group>
                                              </FormItem>
                                              <FormItem label="Cuenta Credito" margin>
                                                <Input.Group compact>
                                                  <FormItem name="cuentaIngresoCreditoId" required noStyle>
                                                    <SelectItem placeholder="Cuenta Ingreso Credito" width="80%">
                                                      {this.state.objetoCuentasContablesFinales}
                                                    </SelectItem>
                                                  </FormItem>
                                                  <ButtonItem
                                                    icon={<PlusOutlined />}
                                                    style={{ width: "20%" }}
                                                    onClick={this.abrirAgregarCuentaContable.bind(this)}
                                                  />
                                                </Input.Group>
                                              </FormItem>
                                            </Col>
                                          </Row>
                                        </BoxItem>
                                      </>
                                    )
                                  } else {
                                    return null
                                  }
                                }}
                              </FormItem>
                            </Col>
                            <Col span={12}>
                              <h1 style={{ textAlign: "center" }}>
                                Precio en Factura: {valueDato.precioUnitario}
                              </h1>
                              <FormItem
                                label="Costo"
                                name="costo"
                                required
                                margin
                                numberCero
                                initialValue={valueDato.precioUnitario}
                              >
                                <InputNumberItem
                                  placeholder="Costo"
                                  dinero
                                  onChange={this.onChangeCosto.bind(this)}
                                />
                              </FormItem>
                              <FormItem label="Familia" margin>
                                <Input.Group compact>
                                  <FormItem name="familiaId" required noStyle>
                                    <SelectItem
                                      onChange={this.onChangeFamlia.bind(this)}
                                      placeholder="Familia"
                                      width="80%"
                                    >
                                      {this.state.objetoFamilia}
                                    </SelectItem>
                                  </FormItem>
                                  <ButtonItem
                                    icon={<PlusOutlined />}
                                    style={{ width: "20%" }}
                                    onClick={this.abrirAgregarFamilia.bind(
                                      this
                                    )}
                                  />
                                </Input.Group>
                              </FormItem>
                              <FormItem
                                name="inventariable"
                                label="Inventariable"
                                margin
                                valuePropName="checked"
                                initialValue={false}
                              >
                                <Switch disabled={this.state.familiaGastoNoDeducible}></Switch>
                              </FormItem>
                              {/* <FormItem
                                        label="Es subinsumo"
                                        name="subinsumo"
                                        margin
                                        valuePropName="checked"
                                        initialValue={false}
                                      >
                                        <Checkbox></Checkbox>
                                      </FormItem> */}
                              <FormItem noStyle shouldUpdate={(prev, curr) => prev.venta !== curr.venta} >
                                {({ getFieldValue }) => {
                                  let venta = getFieldValue('venta')
                                  if (venta) {
                                    return (
                                      <FormInitial.List
                                        name="precios"
                                        initialValue={this.state.precios.map(
                                          (value) => {
                                            return {
                                              porcentaje: value.porcentaje,
                                              nombre: value.nombre,
                                              precioId: value.precioId,
                                            };
                                          }
                                        )}
                                      >
                                        {(fields, { add, remove }) => (
                                          <>
                                            {fields.map(
                                              (
                                                { key, name, fieldKey, ...restField },
                                                otro
                                              ) => (
                                                <FormItem
                                                  key={key}
                                                  label={
                                                    "Precio " +
                                                    this.state.precios[key].nombre
                                                  }
                                                  margin
                                                  shouldUpdate
                                                >
                                                  <Input.Group compact>
                                                    <FormItem
                                                      name={[name, "porcentaje"]}
                                                      required
                                                      numberCero
                                                      noStyle
                                                    >
                                                      <InputNumberItem
                                                        sinWidth
                                                        porcentaje
                                                        onChange={(value) => {
                                                          let arrayValue =
                                                            this.formRef.current.getFieldValue(
                                                              "precios"
                                                            );
                                                          let valueCosto =
                                                            this.formRef.current.getFieldValue(
                                                              "costo"
                                                            );
                                                          if (!valueCosto) return;
                                                          if (
                                                            this.state
                                                              .calcularPreciosSobre
                                                          ) {
                                                            arrayValue[key].precio = (
                                                              (valueCosto *
                                                                (100 + value)) /
                                                              100
                                                            ).toFixed(2);
                                                          } else {
                                                            arrayValue[key].precio = (
                                                              valueCosto /
                                                              ((100 - value) / 100)
                                                            ).toFixed(2);
                                                          }
                                                          this.formRef.current.setFieldsValue(
                                                            {
                                                              precios: arrayValue,
                                                            }
                                                          );
                                                        }}
                                                      />
                                                    </FormItem>
                                                    <FormItem
                                                      name={[name, "precio"]}
                                                      required
                                                      numberCero
                                                      noStyle
                                                    >
                                                      <InputNumberItem
                                                        sinWidth
                                                        placeholder={
                                                          "Precio de Venta " +
                                                          this.state.precios[key].nombre
                                                        }
                                                        dinero
                                                        onChange={(value) => {
                                                          let arrayValue =
                                                            this.formRef.current.getFieldValue(
                                                              "precios"
                                                            );
                                                          let valueCosto =
                                                            this.formRef.current.getFieldValue(
                                                              "costo"
                                                            );
                                                          if (!valueCosto) return;
                                                          if (
                                                            this.state
                                                              .calcularPreciosSobre
                                                          ) {
                                                            arrayValue[key].porcentaje =
                                                              (
                                                                ((value - valueCosto) /
                                                                  valueCosto) *
                                                                100
                                                              ).toFixed(2);
                                                          } else {
                                                            arrayValue[key].porcentaje =
                                                              (
                                                                ((value - valueCosto) /
                                                                  value) *
                                                                100
                                                              ).toFixed(2);
                                                          }
                                                          this.formRef.current.setFieldsValue(
                                                            {
                                                              precios: arrayValue,
                                                            }
                                                          );
                                                        }}
                                                      />
                                                    </FormItem>
                                                  </Input.Group>
                                                </FormItem>
                                              )
                                            )}
                                          </>
                                        )}
                                      </FormInitial.List>
                                    )
                                  } else {
                                    return null
                                  }
                                }}
                              </FormItem>





                              {/*<FormItem
                                label="Grupo Sat"
                                name="grupoSatId"
                                required
                                margin
                              >
                                <SelectItem
                                  placeholder="Grupo Sat"
                                  onChange={this.onChangeGrupo.bind(this)}
                                >
                                  {this.state.objetoGrupoSat}
                                </SelectItem>
                              </FormItem>
                              <FormItem
                                label="Clase Sat"
                                name="claseSatId"
                                required
                                margin
                              >
                                <SelectItem
                                  placeholder="Clase Sat"
                                  onChange={this.onChangeClase.bind(this)}
                                >
                                  {this.state.objetoClaseSat === null ? (
                                    <Option disabled>
                                      Eliga primero un grupo
                                    </Option>
                                  ) : (
                                    this.state.objetoClaseSat
                                  )}
                                </SelectItem>
                              </FormItem>*/}
                              <FormItem
                                label="Codigo Sat"
                                name="codigoSatId"
                                required
                                margin
                              >
                                <SelectItem disabled placeholder="Codigo Sat">
                                  {this.state.objetoCodigoSat === null ? (
                                    <Option disabled>
                                      Eliga primero una clase
                                    </Option>
                                  ) : (
                                    this.state.objetoCodigoSat
                                  )}
                                </SelectItem>
                              </FormItem>
                            </Col>
                          </Row>
                        </Form>
                      </Spin>
                    </div>
                  );
                } else if (this.state.insumo === 'relacionar') {
                  return (
                    <div
                      style={{
                        backgroundColor: "white",
                        padding: 20,
                        paddingTop: 30,
                      }}
                    >
                      <Spin spinning={this.state.loading}>
                        <Form
                          ref={this.formRef}
                          name="relacionarInsumoXml"
                          layout
                          bottom={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "right",
                              }}
                            >
                              <Space>
                                <ButtonItem
                                  blanco
                                  type="primary"
                                  size='small'
                                  danger
                                  onClick={() => this.setState({ insumo: null })}
                                >
                                  Atras
                                </ButtonItem>
                                {this.state.insumoYaExisteRelacion ?
                                  <Popconfirm
                                    title={'Se borrara la relación anterior con este proveedor'}
                                    onConfirm={() => this.formRef.current.formRef.current.submit()}
                                    okText="Ok"
                                    cancelText="Cancelar"
                                  >
                                    <ButtonItem
                                      type="primary"
                                      size="small"
                                    >
                                      Relacionar
                                    </ButtonItem>
                                  </Popconfirm>
                                  :
                                  <ButtonItem
                                    type="primary"
                                    size="small"
                                    htmlType="submit"
                                  >
                                    Relacionar
                                  </ButtonItem>
                                }
                              </Space>
                            </div>
                          }
                          onFinish={this.relacionarInsumoXml.bind(this)}
                          onFinishFailed={this.onFinishFailed}
                        >
                          <Row>
                            <Col span={10}>
                              <BoxItem>
                                <Row>
                                  <Col span={12}>
                                    <h1 style={{ margin: 0 }}>
                                      Codigo: {this.state.agregar.codigo}
                                    </h1>
                                  </Col>
                                  <Col span={12}>
                                    <h1 style={{ margin: 0 }}>
                                      Descripcion: {this.state.agregar.descripcion}
                                    </h1>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={12}>
                                    <p style={{ margin: 0 }}>
                                      Codigo SAT:{" "}
                                      {this.state.agregar.codigoSat}
                                    </p>
                                  </Col>
                                  <Col span={12}>
                                    <p style={{ margin: 0 }}>
                                      Impuestos: {this.state.agregar.impuestos?.map((valueImpuesto) => {
                                        let porcentajeoCuotoa
                                        if (valueImpuesto.tipoFactor === "Tasa") {
                                          porcentajeoCuotoa = valueImpuesto.tasaCuota * 100 + " %"
                                        } else if (valueImpuesto.tasaCuota) {
                                          porcentajeoCuotoa = valueImpuesto.tasaCuota
                                        } else if (valueImpuesto.tipoFactor) {
                                          porcentajeoCuotoa = valueImpuesto.tipoFactor
                                        }
                                        return (
                                          valueImpuesto.impuesto +
                                          " " +
                                          porcentajeoCuotoa +
                                          " "
                                        );
                                      })}
                                    </p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={12}>
                                    <p style={{ margin: 0 }}>
                                      Precio Unitario: {this.state.agregar.precioUnitario}
                                    </p>
                                  </Col>
                                  <Col span={12}>
                                    <p style={{ margin: 0 }}>
                                      Unidad SAT: {this.state.agregar.unidad}
                                    </p>
                                  </Col>
                                </Row>
                              </BoxItem>
                            </Col>
                            <Col span={14}>
                              <Row>
                                <Col span={24}>
                                  <FormItem
                                    label="Insumo a Relacionar"
                                    name="insumoId"
                                    required
                                    margin
                                  >
                                    <SelectItem onChange={(value) => this.onChangeInsumoARelacionar(value)} placeholder="Insumo a Relacionar">
                                      {this.state.objetoInsumos}
                                    </SelectItem>
                                  </FormItem>

                                  <FormItem
                                    label="Conversion"
                                    name="conversion"
                                    margin
                                    number
                                  >
                                    <InputNumberItem placeholder="Conversion" />
                                  </FormItem>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Form>
                      </Spin>
                    </div>
                  );
                }
              }
            })()
            : null}

          {this.state.conceptos.length > 0 ?
            <div
              style={{
                backgroundColor: "white",
                padding: 20,
                paddingTop: 5,
              }}
            >
              <Divider style={{ margin: 0 }} >Insumos Restantes</Divider>
              <table className="table">
                <thead>
                  <tr>
                    <th className="th-border" style={{ width: "10%" }}>
                      Cantidad
                    </th>
                    <th className="th-border" style={{ width: "10%" }}>
                      Unidad
                    </th>
                    <th className="th-border" style={{ width: "20%" }}>
                      Clave SAT
                    </th>
                    <th className="th-border" style={{ width: "30%" }}>
                      Descripcion
                    </th>
                    <th className="th-border" style={{ width: "15%" }}>
                      Valor Unitario
                    </th>
                    <th className="th-border" style={{ width: "15%" }}>
                      Total
                    </th>
                  </tr>
                </thead>
                {this.state.conceptos.map(
                  (valueConcepto, arrayKey) => (
                    <tbody key={arrayKey}>
                      <tr>
                        <td className="td-border" style={{ textAlign: 'right' }}>
                          {Number(valueConcepto?.attributes?.Cantidad).toFixed(2)}
                        </td>
                        <td className="td-border">
                          {valueConcepto?.attributes?.ClaveUnidad}
                        </td>
                        <td className="td-border">
                          {valueConcepto?.attributes?.ClaveProdServ}
                        </td>
                        <td className="td-border">
                          {valueConcepto?.attributes?.Descripcion}
                        </td>
                        <td className="td-border" style={{ textAlign: 'right' }}>
                          {dineroDisabledMask(valueConcepto?.attributes?.ValorUnitario)}
                        </td>
                        <td className="td-border" style={{ textAlign: 'right' }}>
                          {dineroDisabledMask(valueConcepto?.attributes?.Importe)}
                        </td>
                      </tr>
                    </tbody>
                  ))}
              </table>
            </div>
            : null}

          <AgregarUnidad
            visible={this.state.modalAgregarUnidad}
            cerrarAgregarUnidad={this.cerrarAgregarUnidad.bind(this)}
          />
          <AgregarFamilia
            visible={this.state.modalAgregarFamilia}
            cerrarAgregarFamilia={this.cerrarAgregarFamilia.bind(this)}
          />
          <AgregarCuentaContable
            visible={this.state.modalAgregarCuentaContable}
            cerrarAgregarCuentaContable={this.cerrarAgregarCuentaContable.bind(this)}
          />
          {/* <TableItem loading={this.state.loading} size='small' dataSource={this.state.todasVentas} columns={columns} pagination={false} /> */}
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    permisoAgregarInsumo: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615ccd1cf30e81b54183cf75")?.activado,
  };
};

export default connect(mapStateToProps)(LeerXml);
